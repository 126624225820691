<template>
  <wiskInput :label="translations.txtGenericUnits" v-model="localMeasurement.quantity" inputType="number" decimalsAsNeeded :decimals="4" :minDecimals="2"
    @blur="onBlur" @update:modelValue="emitUpdateModel" :disabled="disabled" />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'InventoryEntryInputUnit',
  emits: ['update:modelValue', 'blur', 'valid', 'change'],
  components: {},
  props: { modelValue: Object, disabled: Boolean, emitFast: Boolean },
  data() {
    return {
      localMeasurement: { quantity: 0, type: 'unit', unit_of_measurement: 'unit' }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onBlur(event) {
      this.$emit('blur', event)
      this.$emit('valid', !!this.localMeasurement.quantity)

      if (!this.modelValue || this.modelValue.quantity !== this.localMeasurement.quantity) {
        this.$emit('update:modelValue', { ...this.localMeasurement })
        this.$emit('change', { ...this.localMeasurement })
      }
    },
    emitUpdateModel() {
      if (this.emitFast) {
        this.$emit('update:modelValue', { ...this.localMeasurement })
        this.$emit('valid', !!this.localMeasurement.quantity)
      }
    }
  },
  mounted() {
    this.$emit('valid', !!this.localMeasurement.quantity)
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler() {
        if (this.modelValue && this.modelValue.quantity !== this.localMeasurement.quantity) {
          this.localMeasurement.quantity = this.modelValue.quantity
        }
        this.localMeasurement.unit_of_measurement = 'unit'
      }
    }
  }
}
</script>

<style lang="scss"></style>
