<template>
  <component :is="fieldSetTag" class="form-group">
    <component :is="labelTag" :for="labelFor || null" v-if="hasLabel || isHorizontal" :class="labelClassList" :labelCols="isHorizontal ? labelCols : undefined">
      <slot name="label">{{ label }}</slot>
    </component>
    <div>
      <slot></slot>
      <small v-if="description || !!$slots['description']" class="form-text text-muted">
        <slot name="description">{{ description }}</slot>
      </small>
    </div>
  </component>
</template>

<script>
export default {
  name: 'BFormGroup',
  props: {
    // label-cols, label, label-for, description
    description: { type: String, default: '' },
    label: { type: String, default: '' },
    labelClass: { type: [String, Array], default: '' },
    labelFor: { type: String, default: '' },
    labelCols: { type: [String, Number], default: '' }
  },
  computed: {
    hasLabel() {
      return this.$slots.label || this.label
    },
    labelTag() {
      return this.isHorizontal ? 'b-col' : this.labelFor ? 'label' : 'legend'
    },
    fieldSetTag() {
      return this.labelFor ? 'div' : 'fieldset'
    },
    labelClassList() {
      const labelClassList = Array.isArray(this.labelClass) ? this.labelClass : String(this.labelClass).split(' ')
      if (this.isHorizontal || !this.labelFor) labelClassList.push('col-form-label')
      if (!this.isHorizontal && !this.labelFor) labelClassList.push('pt-0')
      if (!this.isHorizontal && this.labelFor) labelClassList.push('d-block')
      return labelClassList
    },
    isHorizontal() {
      return !!this.labelCols
    }
  },
  methods: {}
}
</script>
