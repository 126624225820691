<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemVariationsMerge', action: null })" size="lg" extraLarge>
    <template v-slot:modal-title>
      <h5 class="modal-title d-inline-block me-3"> {{ translations.txtBottlesVariantMergeTitle }} </h5>
      <infoTooltip inline class="" :scale="1.2" helpKey="itemVariationsMergeInfoTooltip" />
    </template>
    <template v-slot:modal-header-extra>
      <div class="d-flex align-items-center">
        <h5 class="text-capitalize mb-0"> {{ translations.txtBottlesVariantMergeStartingBottles }}: </h5>
        <b-form-radio-group buttons v-model="selectedItemVariationId" button-variant="outline-primary" class="ms-2">
          <b-form-radio v-for="itemId in itemVariationIds" :value="itemId" :key="itemId" class="text-wrap ">
            {{ (itemVariantsById[itemId] && itemVariantsById[itemId].title) || '' }}
            <b-badge> {{ itemId }} </b-badge>
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </template>
    <b-container fluid class="items-merge-container">
      <b-row>
        <b-col class="text-primary text-bold">
          {{ translations.txtBottlesVariantMergeInfoResulted }}
        </b-col>
        <b-col>
          {{ translations.txtBottlesVariantMergeInfoHowTo1 }}
          <icon class="text-primary" name="wisk-clone" />
          {{ translations.txtBottlesVariantMergeInfoHowTo2 }}
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <itemVariant v-if="previewItemVariation" emitOperationNoSave @operation="handleVariantOperation({ id: previewItemVariation.item_distributor_id, operations: $event })"
            :item="bottlesById[previewItemVariation.item_id]" :itemVariant="previewItemVariation" usePriceFromVariant @value="updateItemData" :triggerInputOnLoad="false" />
          <loading :loading="previewItemVariationLoading" />
        </b-col>
        <b-col>
          <itemVariant v-if="selectedItemVariation" emitOperationNoSave :key="selectedItemVariationId" @value="updateItemData"
            :item="bottlesById[selectedItemVariation.item_id]" :itemVariant="selectedItemVariation" disabled operationTriggerButton :triggerInputOnLoad="false" />
        </b-col>
      </b-row>
      <confirm ref="confirmDialog" />
    </b-container>
    <template v-slot:modal-footer>
      <editorButtons :save="save" :loading="saveLoading" :editorValid="!previewItemVariationLoading" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import api from '@/api'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import loading from '@/components/common/WiskLoading'
import itemVariant from '@/components/bottles/ItemVariantEdit'

export default {
  name: 'ItemVariationsMerge',
  components: { loading, editorButtons, itemVariant },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      variantOperations: [],
      previewItemVariationLoading: false,
      editActionCopy: null,
      selectedItemVariationId: null,
      itemVariationIds: [],
      previewKey: 1,
      previewItemVariation: null,
      previewItemVariationCopy: null,
      selectedItemVariation: null,
      saveLoading: false
    }
  },
  computed: {
    ...mapState(['translations', 'itemVariantsById', 'bottlesById']),
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    updateItemData(updated) {
      if (this.previewItemVariation) {
        this.previewItemVariation = merge({}, this.previewItemVariation, updated)
      }
    },
    handleVariantOperation(payload) {
      this.variantOperations = payload.operations
    },
    save() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.saveLoading = true
            api.variationsMerge({
              operations: this.variantOperations,
              preview_item_distributor: this.previewItemVariationCopy,
              item_distributor_ids: this.editAction.itemVariationIds,
            }).then(() => {
              this.notify({ type: 'success', message: this.translations.txtBottlesMergeWaitingMessage })
              this.setGlobalAction({ type: 'itemVariationsMerge', action: null })
            }).catch(() => {
              this.setGlobalAction({ type: 'itemVariationsMerge', action: null })
            }).finally(() => {
              this.saveLoading = false
            })
          },
          cancelCallback: () => {
            this.saveLoading = false
          },
          message: this.translations.confirmBottlesMergeText,
          title: this.translations.txtGenericConfirmation
        })
      }
    }
  },
  watch: {
    editAction: {
      handler() {
        if (!isEqual(this.editAction, this.editActionCopy)) {
          this.selectedItemVariationId = null
          this.itemVariationIds = []
          this.previewItemVariation = null
          this.selectedItemVariation = null

          if (this.editAction && this.editAction.itemVariationIds && this.editAction.itemVariationIds.length) {
            this.itemVariationIds = this.editAction.itemVariationIds
            this.selectedItemVariationId = this.editAction.itemVariationIds[0]
            this.previewItemVariationLoading = true
            api.variationsMergePreview({ item_distributor_ids: this.editAction.itemVariationIds }).then(previewItemVariation => {
              this.previewItemVariation = previewItemVariation
              this.previewItemVariationCopy = merge({}, this.previewItemVariation)
              this.previewItemVariationLoading = false
            }).catch(() => {
              this.setGlobalAction({ type: 'itemVariationsMerge', action: null })
            })
          }
          this.editActionCopy = merge({}, this.editAction)
        }
      },
      immediate: true
    },
    selectedItemVariationId: {
      handler(selectedItemVariationId) {
        this.selectedItemVariation = this.itemVariantsById && selectedItemVariationId ? this.itemVariantsById[selectedItemVariationId] : null
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.items-merge-container {
  .item-operations-main-column {
    min-width: 400px;
  }
}
</style>
