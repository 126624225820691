<template>
  <div>
    <!-- <portal to="global-header">
      <div class="d-flex justify-content-end w-100">
        <b-button @click="editFamily(0)" size="sm" class="mb-1 mb-md-0 flex-grow-1 flex-md-grow-0" variant="primary"> {{ translations.txtFamiliesCategoriesNewFamily }} </b-button>
      </div>
    </portal> -->
    <wiskGrid :gridOptions="gridOptions" :gridStyle="{}" :columnDefs="columns" :rowData="rowData" :loadingOverlay="loading" checkBoxSelection
      v-if="gridOptions" :key="gridKey" :defaultFilter="defaultFilter" :customFilter="customFilterSelected" showFoundArchived @requestFilter="requestFilter" v-bind="$attrs"
      :excel="{ fileName: 'Families' }" parentGridName="Families" :header="{}" @gridApi="gridApiAvailable" :selectedRowsActions="selectedRowsActions">

      <template v-slot:additional-header-controls>
        <wiskGridCustomFilterDropdown :customFilters="customFilters" v-model="customFilterSelected" class="float-end" />
        <b-button @click="editFamily(0)" size="sm" class="me-2" variant="primary"> {{ translations.txtFamiliesCategoriesNewFamily }} </b-button>
      </template>
    </wiskGrid>

    <confirm ref="confirmDialog" />

    <wiskModal :title="translations.translate('tplFamiliesCategoriesBottlesUsingCategory', { '{a}': selectedCategoryTitle })" :okText="translations.txtGenericRetry"
      extraLarge :visible="!!usedCategoryForArchive" @hide="usedCategoryForArchive = null" size="lg"
      @ok="retryArchiveCategory(usedCategoryForArchive && usedCategoryForArchive.id)">

      <div v-if="!!usedCategoryForArchive">
        <h4 class="text-danger mb-4"> {{ translations.txtFamiliesCategoriesBottlesUsingCategoryMessage }} </h4>

        <wiskItemsGrid gridAutoHeight :columns="bottlesUsedByCategoryColumns" :items="bottlesUsedByCategory"
          :excel="{ fileName: 'bottlesUsedByCategory' }" parentGridName="bottlesUsedByCategory" />

      </div>

    </wiskModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import get from 'lodash.get'
import wiskGrid from '@/components/grids/WiskGrid'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import getGridColumns from './gridColumns'

const getDummyCategory = family => ({
  archived: false,
  density: 0,
  excluded_from_variance: false,
  partial: 'scale',
  family_id: family.id,
  family,
  id: Math.floor(Math.random() * 100000000),
  wiskRowHidden: true,
  title: '',
  venue_id: family.venue_id
})

export default {
  name: 'FamiliesGrid',
  components: { wiskGrid, wiskItemsGrid },
  props: {},
  data() {
    return {
      gridApi: null,
      customFilterSelected: null,
      gridOptions: null,
      columns: [],
      selectedCategories: [],
      gridKey: 1,
      loading: false,
      initDone: false,
      usedCategoryForArchive: null
    }
  },
  computed: {
    ...mapGetters(['activeFamilies', 'categoryPartialMeasureTypes', 'categoriesByFamilyId']),
    ...mapState(['families', 'familiesById', 'translations', 'categories', 'categoriesById', 'bottles', 'currentPermissionsByType', 'user']),
    selectedRowsActions() {
      let showingArchived = this.showingArchived,
        actions = []

      if (this.currentPermissionsByType.family_category_view) {
        actions.push({
          key: 'archive',
          type: 'button',
          variant: 'danger',
          hide: showingArchived,
          action: rows => {
            this.updateCategories({ type: 'archive', value: true }, rows)
          },
          title: this.translations.txtGenericArchive
        })
        actions.push({
          key: 'restore',
          type: 'button',
          variant: 'success',
          hide: !showingArchived,
          action: rows => {
            this.updateCategories({ type: 'archive', value: false }, rows)
          },
          title: this.translations.txtGenericRestore
        })

        actions.push({
          key: 'density',
          type: 'genericInput',
          inputTypeAttrs: {
            operation: 'density',
            inputType: 'number',
            decimals: 2,
            clearAfterEmitOperation: true,
            label: this.translations.txtGenericDensity
          },
          operation: (operation, rows) => {
            this.updateCategories(operation, rows)
          }
        })

        actions.push({
          key: 'target_beverage_cost',
          type: 'genericInput',
          inputTypeAttrs: {
            operation: 'target_beverage_cost',
            inputType: 'number',
            transformerOut: z => z / 100,
            transformerIn: z => z * 100,
            suffix: '%',
            clearAfterEmitOperation: true,
            label: this.translations.txtFamiliesCategoriesTargetBeverageCost
          },
          operation: (operation, rows) => {
            this.updateCategories(operation, rows)
          }
        })
      }

      return actions
    },
    customFilters() {
      return [
        {
          predicate: item => {
            let ok = item.archived
            if (item.family_id && this.familiesById[item.family_id]) {
              ok = ok || this.familiesById[item.family_id].archived
            }

            return ok
          },
          name: 'archived',
          label: this.translations.txtGenericArchived
        }
      ]
    },
    defaultFilter() {
      return { predicate: item => !item.archived && !item.family.archived, name: 'active', label: 'Active', hidesArchivedItems: true }
    },
    showingArchived() {
      return get(this.customFilterSelected, 'name') === 'archived'
    },
    rowData() {
      let rowData = this.categories.map(c => ({ ...c, family: { ...this.familiesById[c.family_id], categories: this.categoriesByFamilyId[c.family_id] } || {} }))

      this.families.forEach(family => {
        rowData.push(getDummyCategory(family))
      })

      return rowData
    },
    bottlesUsedByCategoryColumns() {
      return {
        dropdownMenu: {},
        title: {},
        family: {},
        category: {},
        stock: {},
        perpetual: {}
      }
    },
    bottlesUsedByCategory() {
      if (this.usedCategoryForArchive && this.usedCategoryForArchive.id) {
        return this.bottles.filter(b => b.category_id === this.usedCategoryForArchive.id)
      }
      return []
    },
    selectedCategoryTitle() {
      if (this.usedCategoryForArchive && this.usedCategoryForArchive.id) {
        return this.usedCategoryForArchive.title || ''
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setFamily', 'setCategory']),
    gridApiAvailable(agGridApi) {
      this.gridApi = agGridApi
    },
    clearSelection() {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    },
    requestFilter(name) {
      this.customFilterSelected = this.customFilters.find(filter => filter.name === name)
    },
    newCategory(familyId) {
      this.setGlobalAction({ type: 'categoryEdit', action: { id: 0, family_id: familyId } })
    },
    editFamily(id = 0) {
      this.setGlobalAction({ type: 'familyEdit', action: { id } })
    },
    toggleArchiveFamily(id) {
      let family = this.familiesById[id]
      if (family) {
        this.setFamily({ id, operation: { type: 'archive', value: !family.archived } })
      }
    },
    retryArchiveCategory(id) {
      this.usedCategoryForArchive = null

      setTimeout(() => {
        this.save({ type: 'archive', value: true, id })
      }, 0)
    },
    updateCategories(operation) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            let all = []
            this.selectedCategories.forEach(c => {
              all.push(this.setCategory({ id: c.id, operation }))
            })
            Promise.all(all).finally(() => {
              this.clearSelection()
            })
          },
          message: this.translations.confirmMultipleOperationsGenericText,
          title: this.translations.confirmItemsGridFloatingActionTitle
        })
      }
    },
    save({ value, id, type, previousValue }) {
      if (type === 'archive' && this.bottles.filter(b => b.category_id === id).length) {
        this.usedCategoryForArchive = this.categoriesById[id]
      } else {
        this.loading = true
        this.setCategory({ id, operation: { value, type, from: previousValue } }).finally(() => {
          this.loading = false
        })
      }
    },
    getArchiveButtonText(id) {
      return (this.familiesById[id] && this.familiesById[id].archived && this.translations.txtGenericRestore) || this.translations.txtFamiliesCategoriesArchiveFamily
    },
    getArchiveButtonDisabled(id) {
      let categories = this.categoriesByFamilyId[id] || []

      return !!categories.filter(c => !c.archived).length
    }
  },
  beforeUnmount() {
    this.gridApi = null
  },
  watch: {
    user: {
      handler(user) {
        if (user && !this.initDone) {
          this.gridOptions = {
            suppressAggFuncInHeader: true,
            getRowHeight: params => (params.data && params.data.wiskRowHidden && 1) || 45,
            onRowSelected: event => {
              this.selectedCategories = event.api.getSelectedRows()
            },
            autoGroupColumnDef: {
              cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
              cellRendererParams: {
                subtractFromAllChildrenCount: 1,
                wiskGroupActions: [
                  { action: this.editFamily, title: this.translations.txtFamiliesCategoriesEditFamily, variant: 'primary' },
                  {
                    action: this.toggleArchiveFamily,
                    getTitle: this.getArchiveButtonText,
                    variant: 'danger',
                    disabled: this.getArchiveButtonDisabled,
                    disabledInfo: this.translations.txtFamiliesCategoriesArchiveFamilynotAllowed
                  },
                  {
                    action: this.newCategory,
                    title: this.translations.txtFamiliesCategoriesAddCategory,
                    variant: 'primary',
                    available: id => this.familiesById[id] && !this.familiesById[id].archived
                  }
                ]
              }
            }
          }
          setTimeout(() => {
            this.columns = getGridColumns(this.translations, this.activeFamilies, this.categoryPartialMeasureTypes, this.save, this.setGlobalAction)
            this.initDone = true
          }, 0)
        }
      },
      immediate: true
    },
    families: {
      handler() {
        this.columns = getGridColumns(this.translations, this.activeFamilies, this.categoryPartialMeasureTypes, this.save, this.setGlobalAction)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
