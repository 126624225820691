export default {
  name: 'wisk-venues',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.8,14l-4.3-7.5C30.2,6,29.6,5.7,29,5.6H6C5.4,5.7,4.8,6,4.5,6.5L0.2,14c-0.5,0.8-0.2,1.9,0.7,2.3 c0.3,0.1,0.5,0.2,0.8,0.2h2.7c0,0.1,0,0.2,0,0.3v11.3c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0h13.8c0.7,0,1.2-0.6,1.2-1.2V16.9 c0-0.1,0-0.2,0-0.3h7.6c0,0.1,0,0.2,0,0.3v11.3c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3l0,0V16.9c0-0.1,0-0.2,0-0.3h2.7 c0.6,0,1.2-0.3,1.5-0.8C35.1,15.2,35.1,14.5,34.8,14z M18.1,16.9v2.3c0,0.7-0.6,1.2-1.2,1.2H8.1c-0.7,0-1.3-0.5-1.3-1.2c0,0,0,0,0,0 v-2.3c0-0.1,0-0.2,0-0.3h11.3C18.1,16.7,18.1,16.8,18.1,16.9z'
    }
  ]
}
