<template>
  <div v-if="value && mounted" class="inventory-details-info-container" @click="onIconClick">
    <v-menu :triggers="['hover']" :distance="5" v-if="visible">
      <icon class="info-icon text-secondary" name="wisk-information-button" />

      <template #popper>
        <b-container v-if="mode === 'total'" fluid class="p-2">
          <b-row no-gutters v-for="(loc, i) in locations" :key="i">
            <b-col cols="12">
              <span class="text-muted" v-if="loc?.location?.title"> {{ loc.location.title }} </span>
            </b-col>

            <b-col v-if="loc && loc.stats">
              <b-table-simple v-if="loc?.stats?.item_distributors" bordered small>
                <tbody>
                  <tr>
                    <th class="bg-info">{{ translate('txtVenueBottlesVariant') }}</th>
                    <th class="bg-info">{{ translate('txtGenericQuantity') }}</th>
                    <th class="bg-info">{{ translate('txtGenericCost') }}</th>
                    <th class="bg-info">{{ translate('txtGenericTotal') }}</th>
                  </tr>
                  <tr v-for="variant in loc.stats.item_distributors" :key="variant.item_distributor_id">
                    <td>{{ variant.title }}</td>
                    <td class="text-end">{{ toFixed(variant?.stats?.units || 0, 2) }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.price_per_unit || 0) }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.dollars || 0) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row no-gutters v-if="extraButton">
            <b-col class="ps-0 mt-2">
              <b-button size="sm" variant="link" class="info-icon float-start" @click="extraButton.action(id, data)" v-tooltip="extraButton.title || ''">
                <icon class="text-primary" :name="extraButton.icon || 'wisk-arrow-right-circle'"></icon>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-if="mode === 'cost'" fluid class="p-2">
          <b-row no-gutters>
            <b-col cols="12">
              <span class="text-muted">{{ translate('txtInventoryDetailsPopupCountsByVariation') }}</span>
            </b-col>
            <b-col>
              <b-table-simple bordered small>
                <tbody>
                  <tr>
                    <!-- {{ translate('txtVenueBottlesVariant') }} -->
                    <th class="bg-info"></th>
                    <th class="bg-info">{{ translate('columnCostPerUnit') }}</th>
                    <th class="bg-info">{{ translate('txtGenericQuantity') }}</th>
                    <th class="bg-info">{{ translate('txtGenericTotal') }}</th>
                  </tr>
                  <tr v-for="variant in stats.item_distributors" :key="variant.item_distributor_id">
                    <td>{{ variant.title }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.price_per_unit) }}</td>
                    <td class="text-end">{{ toFixed(variant?.stats?.units || 0, 2) }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.dollars || 0) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <span class="text-muted">{{ translate('txtInventoryDetailsPopupConvertedDefaultVariation') }}</span>
            </b-col>
            <b-col>
              <b-table-simple bordered small>
                <tbody>
                  <tr>
                    <!-- {{ translate('txtVenueBottlesVariant') }} -->
                    <th class="bg-info"></th>
                    <th class="bg-info">{{ translate('columnCostPerUnit') }}</th>
                    <th class="bg-info">{{ translate('txtGenericQuantity') }}</th>
                    <th class="bg-info">{{ translate('txtGenericTotal') }}</th>
                  </tr>
                  <tr v-for="variant in stats.item_distributors" :key="variant.item_distributor_id">
                    <td>{{ variant.title }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.price_per_unit_in_default_measurement) }}</td>
                    <td class="text-end">{{ toFixed(variant?.stats?.units_in_default_measurement || 0, 2) }}</td>
                    <td class="text-end">{{ currencyFormat(variant?.stats?.dollars || 0) }}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td class="bg-light"></td>
                    <td class="bg-light text-end">{{ currencyFormat((sumProperty(stats.item_distributors, 'stats.dollars') || 0) / (sumProperty(stats.item_distributors, 'stats.units_in_default_measurement') || 1)) }}</td>
                    <td class="bg-light text-end">{{ toFixed(sumProperty(stats.item_distributors, 'stats.units_in_default_measurement') || 0, 2) }}</td>
                    <td class="bg-light text-end">{{ currencyFormat(sumProperty(stats.item_distributors, 'stats.dollars') || 0) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row no-gutters v-if="extraButton">
            <b-col class="ps-0 mt-2">
              <b-button size="sm" variant="link" class="info-icon float-start" @click="extraButton.action(id, data)" v-tooltip="extraButton.title || ''">
                <icon class="text-primary" :name="extraButton.icon || 'wisk-arrow-right-circle'"></icon>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </v-menu>

    <b-button v-else-if="extraButton" size="sm" variant="link" class="info-icon float-start" @click="extraButton.action(id, data)" v-tooltip="extraButton.title || ''">
      <icon class="text-primary" :name="extraButton.icon || 'wisk-arrow-right-circle'" />
    </b-button>
  </div>
</template>

<script>
import get from 'lodash.get'
import { percentageFormat, toFixed, currencyFormat } from '@/modules/utils'

export default {
  name: 'InventoryDetailsInfo',
  components: {},
  props: { params: Object },
  data() {
    return {
      mounted: false,

    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    visible() {
      return (this.mode === 'total' && this.locations?.length) || (this.mode === 'cost' && this.stats?.item_distributors?.length)
    },
    data() {
      return this.params.value
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    extraButton() {
      return this.params?.extraButton || null
    },
    value() {
      return this.params?.value?.input_value
    },
    mode() {
      return this.params?.mode || 'total'
    },
    locations() {
      let locations = this.params?.value?.locations || []

      for (let i = 0; i < locations.length; i++) {
        let location = locations[i] || {}
        location.location = location.location || {}
        location.stats = location.stats || {}
      }

      return locations
    },
    stats() {
      return this.params?.value?.stats || {}
    }
  },
  methods: {
    percentageFormat,
    toFixed,
    currencyFormat,
    sumProperty(arr, propPath) {
      return arr.reduce((total, obj) => total + get(obj, propPath, 0), 0)
    },
    onIconClick() {
      if (typeof this.params.onIconClick === 'function') {
        this.params.onIconClick(this.params.value)
      }
    }
  },
  created() { },
  mounted() {
    this.mounted = true
  },
  watch: {}
}
</script>

<style lang="scss">
.inventory-details-info-container {
  display: block;
  position: absolute;
  z-index: 999999;
  bottom: 0;
  right: 5px;
}
</style>
