<template>
  <div class="wisk-input-group" :class="{ 'has-legend': !!legend, disabled }" :key="key">

    <fieldset v-if="legend" :class="fieldsetClass">
      <legend class="text-start"> {{ legend }} </legend>
      <slot></slot>
    </fieldset>

    <slot v-else></slot>

    <wiskLoading :loading="loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import wiskLoading from './WiskLoading'

export default {
  name: 'WiskInputGroup',
  emits: ['operationsChange', 'errorCountChanged', 'value'],
  components: { wiskLoading },
  props: {
    disabled: Boolean,
    legend: String,
    fieldsetClass: String,
    operationTriggerButton: Boolean,
    highlightOnValueChange: Boolean,
    loading: Boolean
  },
  data() {
    return {
      fields: {},
      key: 1,
      debug: 0,
      operations: [],
      inputGroup: true // flag needed for recursive search from inside children
    }
  },
  computed: {
    ...mapState(['viewRefreshKey'])
  },
  methods: {
    //called from outside
    setValidState(payload) {
      this.fields[payload.name] = payload
    },
    //called from outside
    setOperation({ operation, operationTypes, allowDuplicateOperationType }) {
      if (operation && operation.type) {
        if (allowDuplicateOperationType) {
          let oppositeOperationType = operationTypes.set === operation.type ? operationTypes.clear : operationTypes.set,
            oppositeOperationIndex = this.operations.findIndex(o => o.type === oppositeOperationType && isEqual(o.value, operation.value))

          if (oppositeOperationIndex + 1) {
            this.operations.splice(oppositeOperationIndex, 1)
          } else {
            this.operations.push(operation)
          }
        } else {
          let found = this.operations.find(o => o.type === operation.type)

          if (found) {
            found.value = operation.value
          } else if (operation.type === operationTypes.clear) {
            let indexDelete = this.operations.findIndex(o => o.type === operationTypes.set)

            if (indexDelete + 1) {
              this.operations.splice(indexDelete, 1)
            }
            this.operations.push(operation)
          } else if (operation.type === operationTypes.set) {
            let indexDelete = this.operations.findIndex(o => o.type === operationTypes.clear)

            if (indexDelete + 1) {
              this.operations.splice(indexDelete, 1)
            }
            this.operations.push(operation)
          } else {
            this.operations.push(operation)
          }
        }
        //help api not fail on null values
        this.operations.forEach(o => {
          if (o.type === operationTypes.clear) {
            if (o.type === 'custom_field_clear' && !o.value.value) {
              delete o.value.value
            } else if (o.value === null) {
              delete o.value
            }
          }
        })

        this.operations = uniqWith(this.operations, isEqual)
        this.$emit('operationsChange', this.operations)
      }
    },
    removeOperation({ type, operationTypes }) {
      if (type) {
        let index = this.operations.findIndex(o => o.type === type),
          hasClearSibling = this.operations.find(o => o.type === operationTypes.clear)

        if (index + 1) {
          this.operations.splice(index, 1)

          if (type === operationTypes.clear) {
            let indexDelete = this.operations.findIndex(o => o.type === operationTypes.set)

            if (indexDelete + 1) {
              this.operations.splice(indexDelete, 1)
            }
          }

          this.$emit('operationsChange', this.operations)
        }
        if (hasClearSibling) {
          let indexDelete = this.operations.findIndex(o => o.type === operationTypes.clear)

          if (indexDelete + 1) {
            this.operations.splice(indexDelete, 1)
            this.$emit('operationsChange', this.operations)
          }
        }
      }
    },
    reset() {
      this.fields = {}
      this.operations = []
      this.$emit('operationsChange', this.operations)
    }
  },
  watch: {
    viewRefreshKey: {
      handler() {
        this.fields = {}
        this.key++
      }
      // immediate: true
    },
    fields: {
      handler() {
        let count = 0
        Object.keys(this.fields).forEach(key => {
          let field = this.fields[key]
          if (field.hasError) {
            count++

            if (this.debug) {
              console.log('errorCountChanged field', field)
            }
          }
        })
        this.$emit('errorCountChanged', count)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.wisk-input-group {
  height: 100%;
  padding-bottom: 0;
  position: relative;
}
</style>
