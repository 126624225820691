<template>
  <wiskModal :title="modalTitle" :size="size" :visible="!!editAction" @ok="ok" @cancel="cancel" :okText="okText"
    @hide="setGlobalAction({ type: 'adPopup', action: null })" :hideFooter="hideFooter">

    <component v-if="use" :is="currentComponent" @ok="ok" @cancel="cancel" />

    <h4 v-else class="text-danger">
      MISSING COMPONENT
    </h4>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import components from './campaigns'

export default {
  name: 'AdPopup',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      modalTitle: '',
      size: 'lg',
      okText: 'OK',
      hideFooter: false,
      use: ''
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    currentComponent() {
      return components[this.use]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    ok() {
      if (this.editAction?.callback) {
        this.editAction.callback()
      }
      this.setGlobalAction({ type: 'adPopup', action: null })
    },
    cancel() {
      if (this.editAction?.cancelCallback) {
        this.editAction.cancelCallback()
      }
    }
  },
  created() {},
  watch: {
    editAction: {
      handler(editAction) {
        if (editAction) {
          this.modalTitle = editAction.title
          this.use = editAction.use
          this.hideFooter = editAction.hideFooter
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
</style>
