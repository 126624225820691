import icons from '@/modules/icons'

const createStyledSpan = (text, tooltip = '') => `<span class="cell-pop-multiselect" title="${tooltip}">${text}</span>`
const createWrapperDiv = (content, cssClass = '', tooltip = '') => `<div class="${cssClass}" title="${tooltip}">${content}</div>`

const getStyledTitlesFromItems = (values, getItems, trackBy) => {
  let items = getItems(),
    titles = values
      .map(v => {
        let found = items.find(item => item[trackBy] === v)
        return found ? found.title : undefined
      })
      .filter(title => title !== undefined)

  return titles.map(title => createStyledSpan(title, titles.join(', '))).join(' ')
}

const createSVGIcon = (iconName, iconData) => {
  if (!iconData) {
    console.warn(`Icon "${iconName}" not found`)
    return null
  }
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('class', 'fa-icon')
  svg.setAttribute('width', 16) //iconData.width)
  svg.setAttribute('height', 16) //iconData.height)
  svg.setAttribute('viewBox', `0 0 ${iconData.width} ${iconData.height}`)
  svg.setAttribute('class', 'fa-icon text-primary text-primary')

  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')

  iconData.paths.forEach(pathData => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute('d', pathData.d)
    g.appendChild(path)
  })

  svg.appendChild(g)
  return svg
}

const createButton = (button, id, data) => {
  const btn = document.createElement('button')
  btn.title = button.title || ''
  btn.classList.add('btn', 'btn-link', 'btn-sm', 'info-icon')
  btn.dataset.id = id
  btn.dataset.action = button.id

  btn.addEventListener('click', () => {
    button.action(id, data)
  })

  const icon = createSVGIcon(button.icon, icons[button.icon])
  if (icon) {
    btn.appendChild(icon)
  }

  return btn
}

const TextExtraButtons = params => {
  const container = document.createElement('div')
  container.style.display = 'flex'
  container.style.alignItems = 'center'

  const textSpan = document.createElement('span')
  textSpan.textContent = params?.value?.input_value || ''

  container.appendChild(textSpan)

  const buttonsContainer = document.createElement('div')
  buttonsContainer.style.display = 'block'
  buttonsContainer.style.position = 'absolute'
  buttonsContainer.style.zIndex = '999'
  buttonsContainer.style.bottom = '0'
  buttonsContainer.style.right = '5px'

  const id = params?.value?.id
  const buttons = params.colDef?.cellRendererParams?.extraButtons || params?.value?.extraButtons

  if (buttons && buttons.length) {
    buttons
      .filter(button => !button.getVisible || button.getVisible(params.data))
      .forEach(button => {
        const btn = createButton(button, id, params.data)
        buttonsContainer.appendChild(btn)
      })
  }

  container.appendChild(buttonsContainer)

  return container
}

const CellPopMultiselect = params => {
  let value = params?.value?.input_value,
    content = value?.title || ''

  if (Array.isArray(value)) {
    let trackBy = params?.colDef?.cellEditorParams?.multiselectOptions?.trackBy || params?.colDef?.cellRendererParams?.multiselectOptions?.trackBy,
      getItems = params?.colDef?.cellEditorParams?.getItems || params?.colDef?.cellRendererParams?.getItems

    if (trackBy && getItems) {
      content = getStyledTitlesFromItems(value, getItems, trackBy)
    } else {
      content = value.map(title => createStyledSpan(title, value.join(', '))).join(' ')
    }
  }

  return createWrapperDiv(content, 'cell-pop-multiselect-wrapper')
}

export default { CellPopMultiselect, TextExtraButtons }
