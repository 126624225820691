<template>
  <div class="image-preview-container mx-0 px-0" ref="container">
    <!-- v-if="mounted" -->
    <div class="h-100" :style="{ opacity: mounted ? 1 : 0, paddingLeft: (images && images.length > 1) || addImage ? '70px' : '' }">
      <div v-if="(images && images.length > 1) || addImage" class="image-preview-sidebar" :style="{ 'max-height': viewerStyle.height }">
        <b-form-radio-group v-model="selectedImage" size="lg" vertical>
          <div v-for="(image, index) in images" :key="index">
            <b-form-radio v-if="!viewOnly" :value="image" style="top: 38px; left: -15px;" v-tooltip="image === selectedImage ? translations.txtImageUploaderSelectedImage : ''" />

            <div @click="selectedIndex = index" :class="{ 'selected': selectedIndex === index }" style="z-index: 1; position: relative; cursor: pointer; min-height: 60px;" class="mb-2 ms-2">
              <b-img thumbnail fluid v-if="image && !image.endsWith('.pdf')" :src="cloudImage(image, 'thumb')" class="shadow-sm bg-white" />
              <pdfViewer :url="image" v-if="image && image.endsWith('.pdf')" :page="1" hideControls class="shadow-sm bg-white" />
            </div>

          </div>

          <b-button v-if="addImage" variant="link" size="lg" class="mb-2" :class="{ attention: !images.length }" @click="addImage" v-tooltip="translations.txtGenericAdd">
            <icon class="" name="wisk-plus" :scale="1.6"></icon>
          </b-button>
        </b-form-radio-group>
      </div>

      <div class="h-100">
        <imgViewer v-if="viewMode === 'image'" :options="options" style="" ref="imgViewer" :key="key" :style="viewerStyle" :images="images" @inited="inited">
          <template v-slot:default="scope">
            <img v-for="src in scope.images" :data-source="cloudImage(src, 'original')" :key="src" style="/*display: none;*/">
          </template>
        </imgViewer>
        <div v-if="viewMode === 'image' && regionHighlighterStyle && renderHighlighter"
          :style="regionHighlighterStyle" style="position: absolute; z-index: 1;">
          <regionHighlighter v-if="viewMode === 'image' && renderHighlighter" @click="$emit('regionHighlightClear')"
            :originalDocumentSize="originalDocumentSize" :highlightBox="regionHighlight" />
        </div>

        <div ref="pdfContainer" v-if="viewMode === 'pdf'" class="h-100">
          <pdfViewer :url="images[selectedIndex]" renderText class="p-0" @scale="pdfScale = $event"
            @pageNumber="$emit('regionHighlightClear'); $emit('pdfPageNumber', $event)" ref="pdfViewer" :renderHighlighter="renderHighlighter">

            <regionHighlighter v-if="renderHighlighter" @click="$emit('regionHighlightClear')" :originalDocumentSize="originalDocumentSize" :highlightBox="regionHighlight" />

            <regionMap v-if="activeRegions && activeRegions.length" :regions="activeRegions" :originalDocumentSize="originalDocumentSize" :refreshKey="pdfScale" @select="$emit('activeRegionSelected', $event)" />
          </pdfViewer>
        </div>

        <b-button v-if="images.length && addImage && canDelete" @click="deleteImage" variant="link" style="position: absolute; z-index: 5; top: 0; right: 0;">
          <icon class="text-danger" name="wisk-trash" :scale="1.4"></icon>
        </b-button>
      </div>
    </div>

    <wiskLoading :loading="!mounted" />

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { component as imgViewer } from 'v-viewer'
import { cloudImage } from '@/modules/utils'
import pdfViewer from '@/components/viewers/pdf/PDFViewer'
import regionHighlighter from '@/components/common/RegionHighlighter'
import regionMap from '@/components/common/RegionMap'

export default {
  name: 'ImagePicker',
  emits: ['update:modelValue', 'regionHighlightClear', 'pdfPageNumber', 'delete', 'currentImage', 'activeRegionSelected'],
  components: { imgViewer, regionHighlighter, pdfViewer, regionMap },
  props: {
    modelValue: String,
    preSelectFirst: Boolean,
    promptAddIfEmpty: Boolean,
    regionHighlight: Object,
    originalDocumentSize: Object,
    addImage: Function,
    canDelete: Boolean,
    viewOnly: Boolean,
    chooseButtonLabel: { type: String, default: 'Select Image' },
    clearButtonLabel: { type: String, default: 'Clear selection' },
    images: { type: Array, default: () => [] },
    activeRegions: { type: Array, default: () => [] }
  },
  data() {
    return {
      key: 1,
      viewMode: 'image',
      pdfScale: 1,
      selectedIndex: -1,
      selectedImage: null,
      viewerStyle: { height: 'calc(100vh - 200px)' },
      regionHighlighterStyle: null,
      mounted: false,
      options: {
        inline: true,
        zIndexInline: 1,
        button: false,
        navbar: false,
        title: false,
        toolbar: { zoomIn: true, zoomOut: true, oneToOne: true, rotateLeft: true, rotateRight: true },
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: false,
        loading: true,
        keyboard: false,
        backdrop: false,
        zoomRatio: 0.3,
        url: 'data-source'
      },
      resizeDebounceTimeoutId: null
    }
  },
  computed: {
    ...mapState(['windowResizeKey', 'translations']),
    renderHighlighter() {
      return !!(this.regionHighlight && this.originalDocumentSize)
    }
  },
  methods: {
    cloudImage,
    deleteImage() {
      this.$emit('delete', this.images[this.selectedIndex])
    },
    setSize() {
      if (this.$refs.container) {
        let box = this.$refs.container.getBoundingClientRect()
        this.viewerStyle.height = box.height - 50 + 'px'
      }
      this.key++
      this.mounted = true
    },
    inited(v) {
      this.$viewer = v
      v.update()
      this.$emit('pdfPageNumber', 1)

      setTimeout(() => {
        if (v && v.initialImageData) {
          this.regionHighlighterStyle = {
            width: v.initialImageData.width + 'px',
            height: v.initialImageData.height + 'px',
            top: v.initialImageData.top + 'px',
            left: v.initialImageData.left + 'px'
          }
        }
      }, 100)
    },
    goTo(pageNumber) {
      if (this.viewMode === 'pdf' && this.$refs.pdfViewer) {
        this.$refs.pdfViewer.goTo(pageNumber)
      } else if (pageNumber) {
        this.selectedIndex = pageNumber - 1
      }
    },
    reset() {
      if (this.viewMode === 'pdf' && this.$refs.pdfViewer) {
        this.$refs.pdfViewer.reset()
      } else if (this.$viewer && typeof this.$viewer.reset === 'function') {
        this.$viewer.reset()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.addImage && !this.images.length && this.promptAddIfEmpty) {
        this.addImage()
      }

      if (this.preSelectFirst && this.images.length) {
        this.selectedIndex = 0
        this.$emit('update:modelValue', this.images[0])
      }
      this.setSize()
    }, 100)
  },

  watch: {
    windowResizeKey: 'setSize',
    modelValue: {
      handler(modelValue) {
        this.selectedImage = modelValue

        this.selectedIndex = -1
        setTimeout(() => {
          this.selectedIndex = this.images.findIndex(i => i === modelValue) || 0
          this.key++
        }, 0)
      }
    },
    selectedImage(selectedImage) {
      this.$emit('update:modelValue', selectedImage)
    },
    selectedIndex() {
      this.viewMode = this.images[this.selectedIndex].endsWith('.pdf') ? 'pdf' : 'image'
      this.$emit('currentImage', this.images[this.selectedIndex])

      setTimeout(() => {
        if (this.$viewer && typeof this.$viewer.view === 'function') {
          this.$viewer.view(this.selectedIndex)
        }
      }, 0)
    }
  }
}
</script>
<style lang="scss">
.image-preview-container {
  min-height: 100%;
  height: 100%;

  .image-preview {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;

    button {
      position: absolute;
      top: 50px;
      left: 50px;
    }
  }

  .img-thumbnail {
    background-color: #ffffff;
    border: 1px solid transparent;
    cursor: pointer;
  }

  .image-preview-sidebar {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 70px;
    max-width: 70px;
    z-index: 999;
    left: 0;

    .selected:before {
      z-index: 2;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--primary);
      pointer-events: none;
    }
  }
}
</style>
