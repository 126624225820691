export default {
  name: 'wisk-edit-alt',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M30,.051H5A5,5,0,0,0,0,5.034V29.966a5,5,0,0,0,5,4.983H30a5,5,0,0,0,5-4.983V5.034A5,5,0,0,0,30,.051ZM13.148,26.9a.405.405,0,0,1-.252.353c-1.583.808-3.165,1.616-4.748,2.407a.663.663,0,0,1-.774-.1.647.647,0,0,1-.236-.757c.471-1.7.96-3.4,1.431-5.1a.436.436,0,0,1,.337-.32c.32-.084.656-.185.993-.269a.685.685,0,0,1,.875.488c.085.252.118.521.186.791a.729.729,0,0,0,.808.673h.74a.735.735,0,0,1,.724.691C13.2,26.179,13.165,26.532,13.148,26.9ZM24.411,13.468c-1.229,1.5-2.475,3.014-3.721,4.512-1.9,2.306-3.788,4.6-5.69,6.9a.3.3,0,0,0-.067.084c-.337.4-.59.371-.825-.1a1.033,1.033,0,0,0-.674-.5,5.875,5.875,0,0,0-.942-.168.813.813,0,0,1-.808-.657c-.1-.3-.219-.623-.354-.926a1.083,1.083,0,0,0-.707-.589c-.387-.084-.471-.387-.219-.69.993-1.212,2-2.424,3-3.636,2.171-2.627,4.343-5.27,6.515-7.9a.714.714,0,0,1,1.145-.118c1.077.892,2.154,1.785,3.232,2.66a.87.87,0,0,1,.337.707A1.272,1.272,0,0,1,24.411,13.468Zm3.013-3.754c-.454.589-.926,1.162-1.414,1.734a.715.715,0,0,1-1.06.017c-.758-.623-1.516-1.246-2.29-1.886-.3-.252-.606-.488-.909-.741a.765.765,0,0,1-.118-1.195c.37-.454.741-.909,1.111-1.347a2.565,2.565,0,0,1,3.434-.64,3.138,3.138,0,0,1,1.684,2.257,3.222,3.222,0,0,1,.034.4A2.223,2.223,0,0,1,27.424,9.714Z'
    }
  ]
}
