<template>
  <div style="display: block; position: absolute; z-index: 999999; bottom: 0; right: 5px; width: 100%; height: 100%;" v-if="!params.node.group">
    <div v-if="mounted">

      <v-menu :triggers="['hover', 'focus']" :distance="5" style="position: absolute; bottom: 0px; right: 0px; width: 22px; height: 22px;">
        <icon v-if="inValid" class="info-icon text-warning" name="wisk-warning" />
        <icon v-else class="info-icon text-secondary" name="wisk-information-button" />

        <template #popper>
          <gateKeeper feature="wisk_par" class="">
            <div style="" class="p-2">
              <div v-if="hasStockInfo">
                <span class="text-success" v-if="isIdeal">
                  {{ translate('txtParSuggestedInfoIdealStock') }}
                </span>
                <span class="" v-if="notEnoughData">
                  {{ translate(type === 'consumption' ? 'txtParSuggestedInfoNotEnoughData' : 'txtParSuggestedSalesInfoNotEnoughData') }}
                </span>
                <span class="text-danger" v-if="inValid">
                  {{ translate('txtParSuggestedInfoInvalidData') }}
                </span>
                <span class="text-danger" v-if="isUnderstock">
                  {{ translate('txtParSuggestedInfoUnderStockBy') }} {{ formattedStock }}
                </span>
                <span class="text-danger" v-if="isOverstock">
                  {{ isDeadstock ? translate('txtFilterOverStock') : translate('txtParSuggestedInfoOverStockBy') }} {{ formattedStock }}
                </span>
                <br>
                <span v-if="!isDeadstock && valid">
                  {{ translate('txtParSuggestedInfoRecommendedStock') }} : {{ formattedValue }} <br>
                  {{ translate(type === 'consumption' ? 'txtParSuggestedInfoDailyConsumption' : 'txtParSuggestedSalesInfoDailyConsumption') }} : {{ dailyConsumption }} <br>
                  {{ translate(type === 'consumption' ? 'txtParSuggestedInfoWeeklyConsumption' : 'txtParSuggestedSalesInfoWeeklyConsumption') }} : {{ weeklyConsumption }} <br>
                  {{ translate('translate')('tplParSuggestedInfoStockEnoughForDays', { '{a}': stockEnoughForDays, '{b}': (stockEnoughForDays / 7).toFixed(1) }) }}
                </span>
                <span v-if="isDeadstock">
                </span>
              </div>

              <div v-else>
                <span> {{ translate('txtVenueBottlesNotEnoughDataWiskPar') }} </span>
              </div>
            </div>
          </gateKeeper>
        </template>
      </v-menu>

      <v-tooltip v-if="value && parLevel !== value" style="position: absolute; bottom: 0; right: 30px; width: 22px; height: 22px;" class="">
        <b-button size="sm" class="info-icon" style="" variant="link" @click="setSuggestedPar">
          <icon class="" name="wisk-check"></icon>
        </b-button>

        <template #popper>
          <span>
            {{ translate('txtParSuggestedInfoSetYourPar') }}
          </span>
        </template>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
import { round, currencyFormat, wiskCaseUnit } from '@/modules/utils'

export default {
  name: 'ParSuggestedInfo',
  components: {},
  props: { params: Object },
  data() {
    return {
      mounted: false
    }
  },
  computed: {
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    value() {
      return this.params.value.input_value
    },
    formattedValue() {
      return wiskCaseUnit(get(this.params, 'value.input_value', 0), get(this.params, 'value.case_size', 1))
    },
    parLevel() {
      return get(this.params, 'value.par_level', 0)
    },
    dailyConsumption() {
      return wiskCaseUnit(get(this.params, 'value.stock_prediction.daily_consumption', 0), get(this.params, 'value.case_size', 1))
    },
    weeklyConsumption() {
      return wiskCaseUnit(get(this.params, 'value.stock_prediction.daily_consumption', 0) * 7, get(this.params, 'value.case_size', 1))
    },
    hasStockInfo() {
      return get(this.params, 'value.stock_prediction')
    },
    stockEnoughForDays() {
      return round(get(this.params, 'value.stock_prediction.stock_enough_for_days.value', 0))
    },
    notEnoughData() {
      return get(this.params, 'value.stock_prediction.type') === 'not_enough_data'
    },
    valid() {
      return get(this.params, 'value.stock_prediction.type') === 'valid'
    },
    inValid() {
      return get(this.params, 'value.stock_prediction.type') === 'invalid_data'
    },
    orderFormat() {
      return this.params.value.order_format
    },
    isIdeal() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'ideal'
    },
    isOverstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'over'
    },
    isUnderstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'under'
    },
    isDeadstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'over' && get(this.params, 'value.stock_prediction.stock_enough_for_days.type', '') === 'infinite'
    },
    formattedStock() {
      let formattedCaseUnit = wiskCaseUnit(get(this.params, 'value.stock_prediction.stock.units', 0), get(this.params, 'value.case_size', 1)),
        formattedCost = currencyFormat(get(this.params, 'value.stock_prediction.stock.cost', 0))
      return `${formattedCaseUnit} / ${formattedCost}`
    },
    type() {
      return get(this.params, 'type', 'consumption')
    }
  },
  methods: {
    setSuggestedPar() {
      if (this.params.setSuggestedPar) {
        this.params.setSuggestedPar({ value: this.value, id: this.params.value.id, type: 'par_level', data: this.params.value, previousValue: this.parLevel })
      }
    }
  },
  mounted() {
    this.mounted = true
  }
}
</script>
