<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'userEdit', action: null })" :hideHeaderInner="isCurrentUser && !user.first_name && !user.last_name"
    extraLarge :title="modalTitle" size="lg" :hideFooter="!!(localUser && localUser.id)" :preventClose="isCurrentUser && !user.first_name && !user.last_name">

    <userEditOperations :editAction="editAction" @userVenuesChanged="userVenuesChanged = true" @change="operationsChanged" :userFromParent="localUser" :key="key" />

    <wiskLoading :loading="loading" />

    <template v-slot:modal-footer>
      <editorButtons :save="(!loading && editAction && localUser.email && !localUser.id && saveNew) || undefined" />
    </template>
  </wiskModal>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import api from '@/api'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import userEditOperations from '@/components/users/UserEditOperations'
//import {  } from '@/modules/utils'

const newUser = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  role: ''
}

export default {
  name: 'UserEdit',
  components: { editorButtons, userEditOperations },
  props: { editAction: Object },
  data() {
    return {
      operations: [],
      localUser: null,
      userVenuesChanged: false,
      loading: false,
      key: 1
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['user', 'translations']),
    isCurrentUser() {
      return this.user.id === this.localUser.id
    },
    modalTitle() {
      if (this.localUser.id) {
        return `${this.localUser.first_name} ${this.localUser.last_name}`
      }
      return ''
    },
    venueId() {
      if (this.editAction && this.editAction.venueId) {
        return this.editAction.venueId
      }
      return this.venue && this.venue.id
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setUser', 'loadVenuesHighLevel']),
    save(id, operation) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.setUser({ id, operation })
          .then(() => {
            if (this.editAction.onChange) {
              this.editAction.onChange(id)
            }
            this.loading = false
            resolve()
          })
          .catch(() => {
            this.loading = false
            reject()
          })
      })
    },
    operationsChanged(operations) {
      this.operations = uniqWith(operations.concat(operations), isEqual)
    },
    saveNew() {
      this.save(this.localUser.id, this.operations).then(() => {
        this.setGlobalAction({ type: 'userEdit', action: null })
      })
    }
  },
  beforeUnmount() {
    if (this.userVenuesChanged) {
      if (this.isCurrentUser) {
        this.loadVenuesHighLevel()
      }
      if (this.editAction.onChange) {
        this.editAction.onChange()
      }
    }
  },
  watch: {
    editAction: {
      handler(editAction) {
        if (editAction) {
          this.localUser = merge({}, newUser, editAction.user || {})
          this.userVenuesChanged = false

          if (this.localUser.email && !this.localUser.id) {
            this.operations.push({ type: 'email', value: this.localUser.email })
          }
          if (editAction.venueId) {
            this.operations.push({ type: 'assign_to_venue_id', value: editAction.venueId })
          }
          if (editAction.userId) {
            this.loading = true
            api
              .user(editAction.userId)
              .then(user => {
                this.localUser = merge({}, newUser, user || {})
                this.key++
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss"></style>
