<template>
  <div>
    <wiskModal :title="modalTitle" size="lg" :visible="!!editAction" @ok="ok" :okText="translations.txtUsersInvite" hideHeaderExtra
      :okDisabled="!valid || loading" @hide="setGlobalAction({ type: 'inviteUsers', action: null })" preventOKClose>

      <wiskInputGroup style="height: 450px;">
        <b-row v-for="(invitation, index) in invitations" :key="index">
          <b-col cols="6" v-if="targetVenue">
            <wiskInput infoTooltipKey="8e1b3cde-a921-45b7-9e3a-5d058f3cdeec" :validations="[{ type: 'email', validator: value => isValidEmail(value), message: translations.txtValidationEmail }]"
              v-model="invitation.email" :label="translations.txtGenericEmail" />
          </b-col>
          <b-col cols="6" v-if="invitedUser">
            <wiskSelect infoTooltipKey="ce83fcc1-3bae-4275-80be-81676131e583" :label="translations.txtGenericVenue" v-model="invitation.venue_id" @input="refreshList" required :items="venuesHighLevel" />
          </b-col>
          <b-col cols="5">
            <wiskSelect infoTooltipKey="09095f72-7ee6-4f58-8a03-f8e11c3868d3" :label="translations.txtGenericRole" v-if="venuesHighLevelById[invitation.venue_id]" :key="invitation.venue_id"
              v-model="invitation.role_id" required :items="venuesHighLevelById[invitation.venue_id].roles.filter(r => r && !r.archived)" @change="onSelectRole($event, invitation)" />
          </b-col>
          <b-col cols="12" class="d-flex">
            <wiskInput :label="translations.txtUsersInventoryTrainingCompleted" v-model="invitation.inventory_training_completed"
              inputType="checkbox" infoTooltipKey="40cc1778-c43f-4cff-b686-c0fb25d01ddc" class="me-2" />
            <wiskInput :label="translations.txtUsersFoodInventoryTrainingCompleted" v-model="invitation.food_inventory_training_completed"
              inputType="checkbox" infoTooltipKey="8af321cb-5100-4af8-9ebf-65da13e8f392" class="me-2" />
          </b-col>
          <b-col cols="1">
            <b-button variant="link" size="md" title="Remove" class="px-0" @click="invitations.splice(index, 1)" v-if="index">
              <icon class="text-danger" name="wisk-minus" :scale="1" />
            </b-button>
          </b-col>
        </b-row>

        <b-button variant="link" size="lg" title="Add" class="px-0" @click="addInvitation">
          <icon class="" name="wisk-plus" :scale="1.6" />
        </b-button>

        <loading :loading="loading" />
      </wiskInputGroup>
    </wiskModal>

    <wiskModal hideOK :title="translations.txtGenericOperationSuccess" size="md" :visible="!!showSuccess" hideHeaderExtra
      @hide="setGlobalAction({ type: 'inviteUsers', action: null })">
      <span> {{ translations.txtAuthUsersInvited }} </span>
    </wiskModal>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import api from '@/api'
import { isValidEmail, arrayToObjectById } from '@/modules/utils'
import loading from '@/components/common/WiskLoading'

export default {
  name: 'InviteUsers',
  components: { loading },
  props: {
    editAction: Object
  },
  data() {
    return {
      loading: false,
      isValidEmail,
      showSuccess: false,
      invitedUser: null,
      targetVenue: null,
      invitations: [],
      venues: [],
      selectedRolesById: {},
      rolesById: {}
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['translations', 'venuesHighLevel', 'venuesHighLevelById', 'permissionsById']),
    modalTitle() {
      if (this.targetVenue) {
        return this.translations.translate('tplUsersInviteUsersToVenue', { '{a}': this.targetVenue.title })
      }
      if (this.invitedUser) {
        return this.translations.translate('tplUsersInviteUserToVenues', { '{a}': this.invitedUser.full_name })
      }
      return this.translations.txtUsersInviteUsers
    },
    venueId() {
      return this.editAction && this.editAction.venueId
    },
    userId() {
      return this.editAction && this.editAction.userId
    },
    valid() {
      return !!this.invitations.length
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenue']),
    addInvitation() {
      this.invitations.push({
        venue_id: this.venueId || null,
        email: (this.invitedUser && this.invitedUser.email) || '',
        role_id: null,
      })
    },
    onSelectRole(role) {
      if (role) {
        this.selectedRolesById[role.id] = this.rolesById[role.id]
      }
    },
    refreshList() {
      this.invitations = this.invitations.map(i => ({ ...i }))
    },
    getUser() {
      api.user(this.editAction.userId).then(user => {
        this.invitedUser = user
        this.addInvitation()
      })
    },
    getVenue() {
      if (this.editAction.venueId) {
        this.targetVenue = this.venuesHighLevelById[this.editAction.venueId]

        if (this.targetVenue) {
          this.addInvitation()
        } else {
          api.venueById(this.editAction.venueId).then(venue => {
            this.targetVenue = venue
            this.addInvitation()
          })
        }
      }
    },
    ok() {
      let invitations = uniqWith(this.invitations.filter(i => i.email && i.venue_id && i.role_id), isEqual)

      this.loading = true
      api
        .inviteUsers(invitations)
        .then(() => {
          this.showSuccess = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          if (editAction.userId) {
            this.getUser()
          }
          if (editAction.venueId) {
            this.getVenue()
          }
        }
      }
    },
    targetVenue: {
      immediate: true,
      handler(targetVenue) {
        if (targetVenue && targetVenue.id) {
          api.rolesForVenue(this.targetVenue.id).then(roles => {
            this.rolesById = arrayToObjectById(roles)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
