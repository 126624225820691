import { promptDownload } from '@/modules/utils'

const watchReportRecursive = (id, api) => {
  setTimeout(() => {
    api.reportById(id).then(response => {
      if (response.url) {
        promptDownload({ url: response.url, filename: response.name })
        api
          .markDownloadedReport(id)
          .then(() => {})
          .catch(() => {})
      } else if (response.is_valid) {
        watchReportRecursive(id, api)
      }
    })
  }, 10000)
}

export default watchReportRecursive
