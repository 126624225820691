export default {
  name: 'wisk-more-info',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 4.5 13 A 4.5 4.5 0 0 0 0 17.5 A 4.5 4.5 0 0 0 4.5 22 A 4.5 4.5 0 0 0 9 17.5 A 4.5 4.5 0 0 0 4.5 13 z M 17.5 13 A 4.5 4.5 0 0 0 13 17.5 A 4.5 4.5 0 0 0 17.5 22 A 4.5 4.5 0 0 0 22 17.5 A 4.5 4.5 0 0 0 17.5 13 z M 30.5 13 A 4.5 4.5 0 0 0 26 17.5 A 4.5 4.5 0 0 0 30.5 22 A 4.5 4.5 0 0 0 35 17.5 A 4.5 4.5 0 0 0 30.5 13 z '
    }
  ]
}
