<template>
  <wiskInputGroup class="mb-4 p-0" :legend="translations.txtDistributorsPortalCredentials">
    <wiskInputGroup @errorCountChanged="setValidState" v-if="localDistributor.show_portal_credentials && !hasPortalCredentials">
      <infoTooltip inline class="mb-4  d-block" :scale="1.2" helpKey="distributorPortalCredentialsInfo" />
      <!-- There is an integration available for this supplier to import their invoices automatically. Learn more here. (this would include a link to the respective article on how to set up the integration). -->
      <b-row>
        <b-col>
          <wiskInput infoTooltipKey="1fc1b088-e6c4-4c02-84aa-217a3cf15af4" class="mb-3" :label="translations.txtGenericUsername" v-model="portalCredentials.username" required />
        </b-col>
        <b-col>
          <wiskInput infoTooltipKey="6372d715-a602-48af-beea-f703c7928ec7" class="mb-3" :label="translations.txtGenericPassword" v-model="portalCredentials.password" required />
        </b-col>
      </b-row>
      <b-button @click="savePortalCredentials" class="me-2 mb-3" variant="primary" :disabled="!valid" size="sm">
        {{ translations.txtDistributorsSavePortalCredentials }}
      </b-button>
    </wiskInputGroup>

    <div v-if="hasPortalCredentials">
      {{ translations.translate('tplDistributorsPortalCredentialsSaved', { '{a}': portalCredentials.username }) }}

      <b-button @click="clearPortalCredentials" class="mx-3" variant="primary" size="sm">
        {{ translations.txtDistributorsClearPortalCredentials }}
      </b-button>

      <infoTooltip class="ms-2" :scale="1" helpKey="distributorPortalCredentialsInfo" />
    </div>
  </wiskInputGroup>
</template>

<script>
import merge from 'lodash.merge'
import { mapState } from 'vuex'
import wiskInput from '@/components/common/WiskInput'

export default {
  name: 'DistributorPortalCredentials',
  emits: ['update'],
  components: { wiskInput },
  props: {
    distributorId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localDistributor: null,
      valid: false,
      hasPortalCredentials: false,
      portalCredentials: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapState(['translations', 'distributorsById', 'distributorCredentialsByDistributorId'])
  },
  methods: {
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    clearPortalCredentials() {
      this.$emit('update', { type: 'clear_portal_credentials' })
      this.hasPortalCredentials = false
    },
    savePortalCredentials() {
      this.$emit('update', { type: 'set_portal_credentials', value: this.portalCredentials })
    },
    populate() {
      this.hasPortalCredentials = false
      this.portalCredentials = { username: '', password: '' }
      this.localDistributor = merge({}, this.distributorsById[this.distributorId])

      let found = this.distributorCredentialsByDistributorId[this.distributorId]

      if (found && found.credentials) {
        this.portalCredentials = { ...found.credentials }
        this.hasPortalCredentials = true
      }
    }
  },
  watch: {
    distributorId: { immediate: true, handler: 'populate' },
    distributorsById: 'populate',
    distributorCredentialsByDistributorId: 'populate'
  }
}
</script>
<style lang="scss"></style>
