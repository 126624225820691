export default {
  name: 'wisk-feedback',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M19.2346,24.2938c-1.91-2.17-4.85-3.31-8.52-3.31a11.6466,11.6466,0,0,0-7.23,2.12,8.46,8.46,0,0,0-1.29,1.19,8.9022,8.9022,0,0,0-2.18,5.43v.02a1.1489,1.1489,0,0,0-.01.19.8152.8152,0,0,0,.81.82h19.81a.8218.8218,0,0,0,.81-.82A8.8056,8.8056,0,0,0,19.2346,24.2938Zm-17.36,4.68.04-.22a6.9548,6.9548,0,0,1,2.65-4.23,9.9133,9.9133,0,0,1,6.15-1.76c3.19,0,5.6.91,7.18,2.71a7.3105,7.3105,0,0,1,1.63,3.28l.04.22Z'
    },
    { d: 'M10.3645,8.9237a5.5,5.5,0,1,0,5.5,5.5A5.5055,5.5055,0,0,0,10.3645,8.9237Zm0,9.35a3.8451,3.8451,0,1,1,3.84-3.85A3.8581,3.8581,0,0,1,10.3645,18.2738Z' },
    {
      d: 'M35.0045,8.2337a2.4156,2.4156,0,0,0-1.93-2.44,3.4792,3.4792,0,0,0-.7-.06q-4.695-.015-9.41-.01h-1.2c-.46,0-.91,0-1.35.01h-.14a3.02,3.02,0,0,0-.66.07,2.4085,2.4085,0,0,0-1.92,2.42v4.58c0,.15,0,.3-.01.45v.08c0,.39,0,.79.02,1.18a2.3234,2.3234,0,0,0,1.13,1.89,2.4012,2.4012,0,0,0,1.04.37v2.76a.819.819,0,0,0,.58.88.783.783,0,0,0,.26.04.9008.9008,0,0,0,.69-.35c.52-.61,1.03-1.2,1.55-1.8l.06-.07c.4-.46.81-.93,1.21-1.4.04-.04.04-.05.11-.05,1.12.01,2.24.01,3.37,0h1.56c1.04,0,2.1.01,3.15.01h.04a2.4857,2.4857,0,0,0,2.55-2.36v-.25c-.01-.16-.01-.34-.01-.54.01-.1.01-.21.01-.32Zm-2.38,6.88c-.07.01-.15,0-.22.01-.77-.01-1.53,0-2.29,0h-6.04a1.1852,1.1852,0,0,0-.94.42c-.38.46-.78.91-1.17,1.37l-.22.24c-.07.08-.13.16-.2.23v-1.34a.8511.8511,0,0,0-.92-.92h-.5a.7857.7857,0,0,1-.77-.81v-3.08c0-1.01-.01-2.02,0-3.04a.7846.7846,0,0,1,.71-.79,1.1378,1.1378,0,0,1,.18-.01q6.09,0,12.2-.01a.765.765,0,0,1,.77.38.9222.9222,0,0,1,.13.41c.01,2.06.01,4.12.01,6.17A.7687.7687,0,0,1,32.6245,15.1137Z'
    }
  ]
}
