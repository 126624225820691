export default {
  name: 'wisk-inventories',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.2853,23.3h-6.43V8.3718a1.659,1.659,0,0,0-.1009-.5547,1.8168,1.8168,0,0,0-.5926-.8446,1.9135,1.9135,0,0,0-1.2355-.4541H10.1117a1.97,1.97,0,0,0-1.9669,1.967V23.3h-6.43A1.7164,1.7164,0,0,0,0,25.0144v1.7527a1.7163,1.7163,0,0,0,1.7147,1.7145H33.2853A1.7163,1.7163,0,0,0,35,26.7671V25.0144A1.7164,1.7164,0,0,0,33.2853,23.3ZM15.5458,9.0148h3.921v3.8581h-3.921ZM10.0233,8.498c0-.0379.05-.101.0884-.101h3.5556V13.44a1.3,1.3,0,0,0,1.2985,1.3113h5.0811A1.2969,1.2969,0,0,0,21.3327,13.44V8.397h3.5556a.108.108,0,0,1,.101.101V23.1989c-.0126.0631-.0379.1009-.101.1009H10.1117c-.0379,0-.0757-.0378-.0884-.1009ZM1.7652,26.7671V25.0648l6.38-.0123H9.6326l17.2226-.0252,6.38-.0129.05,1.7022Z'
    }
  ]
}
