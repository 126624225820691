export default {
  name: 'wisk-powerpoint',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M18.8,0H9.6C7.2,0,5.2,2,5.2,4.4v26.2c0,2.4,2,4.4,4.4,4.4H27c2.4,0,4.3-1.9,4.3-4.4V12.5L18.8,0z M19.7,5 l6.6,6.7h-6.6V5z M28.4,30.6c0,0.8-0.7,1.5-1.4,1.5H9.6c-0.8,0-1.4-0.7-1.4-1.5V4.4c0-0.8,0.7-1.5,1.4-1.5h7.2v9.5 c0,1.2,1,2.2,2.2,2.2h9.4V30.6z'
    },
    { d: 'M19.2,18.1h-5.1v11.5h2.2v-4.1h2.8c1,0,2-0.4,2.7-1.1c0.7-0.7,1.1-1.6,1.1-2.6C22.9,19.8,21.2,18.1,19.2,18.1z M18.5,23.8 h-2.3V20h2.3c1,0,1.9,0.8,1.9,1.9C20.4,23,19.6,23.8,18.5,23.8z' }
  ]
}
