export default {
  name: 'wisk-check-box',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 34.257,9.158 15.89,29.741 a 2.977,2.977 0 0 1 -4.385,0.094 L 0.824,18.8 A 3.112,3.112 0 0 1 0.683,14.744 2.4,2.4 0 0 1 4.314,14.58 L 13.6,24.2 30.626,5.079 a 2.4,2.4 0 0 1 3.631,0 3.17,3.17 0 0 1 0,4.079 z',
      transform: 'matrix(0.78654911,0,0,0.78654911,3.7355149,3.7350401)'
    },
    {
      d: 'M28.9,34.958H6.1A6.1,6.1,0,0,1,0,28.872V6.127A6.1,6.1,0,0,1,6.1.042H28.9A6.1,6.1,0,0,1,35,6.127V28.872A6.1,6.1,0,0,1,28.9,34.958ZM6.1,3.115A3.023,3.023,0,0,0,3.072,6.127V28.872A3.023,3.023,0,0,0,6.1,31.885H28.9a3.021,3.021,0,0,0,3.025-3.013V6.127A3.021,3.021,0,0,0,28.9,3.115Z'
    }
  ]
}
