export default {
  name: 'wisk-tour',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.5,0.2C7.9,0.1,0,7.9,0,17.5c0.1,9.6,7.9,17.4,17.5,17.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c0,0,0,0,0,0 c-1.2,0-2.4-0.1-3.5-0.4c0-0.1,0-0.2,0-0.3c-0.6-1.9-1.1-3.8-1.5-5.8c1.7,0.2,3.4,0.3,5.1,0.3c0.7,0,1.3-0.6,1.3-1.3 c0-0.7-0.6-1.3-1.3-1.3c0,0,0,0,0,0c-1.8,0-3.7-0.1-5.5-0.3c-0.5-3.6-0.5-7.3,0-10.9c3.7-0.4,7.4-0.4,11.1,0 c0.2,1.8,0.3,3.6,0.3,5.5c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3c0-1.7-0.1-3.4-0.3-5c2,0.4,4,0.9,5.9,1.5 c0.1,0,0.2,0.1,0.3,0.1c0.3,1.1,0.4,2.3,0.4,3.5c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3C34.9,7.9,27.1,0.1,17.5,0.2z M3.1,21 c-0.6-2.3-0.6-4.7,0-7c0.1,0,0.2,0,0.3-0.1c1.9-0.6,3.9-1.1,5.9-1.5c-0.4,3.3-0.4,6.7,0,10.1c-2-0.4-4-0.9-5.9-1.5 C3.3,21,3.2,21,3.1,21z M10.8,30.6c-2.8-1.4-5.1-3.7-6.5-6.5v0C6,24.6,7.8,25,9.6,25.3C9.9,27.1,10.3,28.9,10.8,30.6 C10.8,30.6,10.8,30.6,10.8,30.6z M9.6,9.7C7.8,10,6,10.4,4.3,10.9C5.7,8.1,8,5.8,10.8,4.4h0C10.3,6.2,9.9,7.9,9.6,9.7z M12.4,9.3 c0.4-2,0.9-3.9,1.5-5.8c0-0.1,0-0.2,0-0.3c2.3-0.6,4.7-0.6,7.1,0c0,0.1,0,0.2,0.1,0.3c0.6,1.9,1.1,3.8,1.5,5.8 C19.2,9,15.8,9,12.4,9.3L12.4,9.3z M25.4,9.7c-0.3-1.8-0.7-3.6-1.2-5.3c2.8,1.4,5.1,3.7,6.6,6.5C29,10.4,27.2,10,25.4,9.7L25.4,9.7 z'
    },
    {
      d: 'M34.6,32.5L33,31l0,0c0.7-1,1.1-2.3,1.1-3.5c0-3.6-2.8-6.5-6.4-6.6c-3.6,0-6.5,2.8-6.6,6.4s2.8,6.5,6.4,6.6 c0,0,0.1,0,0.1,0c1.3,0,2.5-0.4,3.6-1.1l0,0l1.6,1.6c0.5,0.5,1.4,0.5,1.9,0C35.1,33.9,35.1,33.1,34.6,32.5z M27.5,31.2 c-2.1,0-3.8-1.7-3.7-3.8c0-2.1,1.7-3.8,3.8-3.7c2.1,0,3.7,1.7,3.7,3.8C31.3,29.5,29.6,31.2,27.5,31.2L27.5,31.2z'
    }
  ]
}
