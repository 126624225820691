export default {
  name: 'wisk-email',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.925,35A18.856,18.856,0,0,1,8.74,32.855a15,15,0,0,1-6.069-6.028,18.714,18.714,0,0,1-2.1-8.983A19.064,19.064,0,0,1,2.832,8.376,15.465,15.465,0,0,1,9.064,2.185,18.126,18.126,0,0,1,17.965,0a18.182,18.182,0,0,1,8.943,2.064,14,14,0,0,1,5.624,5.5,14.758,14.758,0,0,1,1.9,7.324,15.3,15.3,0,0,1-1.174,6.393,8.414,8.414,0,0,1-2.994,3.723,7.446,7.446,0,0,1-4.046,1.173,5.586,5.586,0,0,1-3.4-.93,3.626,3.626,0,0,1-1.457-2.59h-.081a6.766,6.766,0,0,1-6.029,3.52,5.686,5.686,0,0,1-4.451-1.9,7.668,7.668,0,0,1-1.658-5.219A12.687,12.687,0,0,1,10.2,13.879a9.415,9.415,0,0,1,3.034-3.844,7.224,7.224,0,0,1,4.451-1.457,6.03,6.03,0,0,1,3.237.85,4.053,4.053,0,0,1,1.821,2.225h.081l.445-2.63h3.116L24.52,19.584q-.162,1.213-.162,1.537a2.785,2.785,0,0,0,.567,1.943,2.651,2.651,0,0,0,1.861.566,3.348,3.348,0,0,0,2.1-.85,6.523,6.523,0,0,0,1.821-2.832,14.861,14.861,0,0,0,.728-5.058A12.069,12.069,0,0,0,29.9,8.9,11,11,0,0,0,25.37,4.491a14.952,14.952,0,0,0-7.405-1.7,14.675,14.675,0,0,0-7.323,1.821,12.685,12.685,0,0,0-5.1,5.219,16.728,16.728,0,0,0-1.82,8.012,16.052,16.052,0,0,0,1.7,7.566,11.776,11.776,0,0,0,4.9,4.977,15.542,15.542,0,0,0,7.607,1.781,16.207,16.207,0,0,0,5.665-.891,7.993,7.993,0,0,0,3.641-2.508h4.127A11.6,11.6,0,0,1,26.179,33.3,18.626,18.626,0,0,1,17.925,35ZM15.942,23.63A5,5,0,0,0,19.22,22.5a7.156,7.156,0,0,0,2.063-3.035,10.551,10.551,0,0,0,.729-3.884A4.646,4.646,0,0,0,20.96,12.3a3.549,3.549,0,0,0-2.792-1.133A4.719,4.719,0,0,0,15.012,12.3a7.048,7.048,0,0,0-2.024,2.953,10.253,10.253,0,0,0-.687,3.723,5.23,5.23,0,0,0,1.011,3.439A3.264,3.264,0,0,0,15.942,23.63Z'
    }
  ]
}
