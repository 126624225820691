import { loadScript } from '@/modules/utils'

let installed

const attachCloseButtonToLauncher = () => {
  const intercomLauncher = document.querySelector('.intercom-launcher') || document.querySelector('.intercom-launcher-frame')?.parentElement

  window.closeIntercomLauncher = event => {
    event?.stopPropagation()
    event?.preventDefault()
    event?.stopImmediatePropagation()

    if (intercomLauncher) {
      intercomLauncher.style.display = 'none'
    }
  }

  document.querySelector('.bt-close-intercom-launcher')?.remove()

  const closeButtonHTML = `
<button type="button" aria-label="Close" class="close btn-close position-absolute p-1 text-danger bt-close-intercom-launcher" style="top: -15px; right: -15px;" title="Hide this, refresh page to bring it back.">
  <svg class="fa-icon" aria-hidden="true" width="10.24" height="10.24" viewBox="0 0 35 35" focusable="false" data-icon="wisk-exit" style="font-size: 0.64em;">
    <g>
      <path d="M32.2,35a2.792,2.792,0,0,1-1.979-.82L17.5,21.46,4.779,34.18A2.8,2.8,0,1,1,.821,30.22L13.541,17.5.821,4.78A2.8,2.8,0,1,1,4.779.82L17.5,13.54,30.221.82a2.8,2.8,0,1,1,3.958,3.96L21.459,17.5l12.72,12.72A2.8,2.8,0,0,1,32.2,35Z">
      </path>
    </g>
  </svg>
</button>`

  intercomLauncher?.insertAdjacentHTML('beforeend', closeButtonHTML)

  document.querySelector('.bt-close-intercom-launcher')?.addEventListener('mousedown', window.closeIntercomLauncher, true)
}

function initIntercom({ appId }) {
  const _ic = {
    ready: false,
    visible: false,
    unreadCount: 0
  }

  const callIntercom = (...args) => window.Intercom(...args)

  const intercom = { _vm: _ic }

  Object.defineProperty(intercom, 'ready', { get: () => _ic.ready })
  Object.defineProperty(intercom, 'visible', { get: () => _ic.visible })
  Object.defineProperty(intercom, 'unreadCount', { get: () => _ic.unreadCount })

  intercom._call = callIntercom

  intercom._init = () => {
    _ic.ready = true

    callIntercom('onHide', () => {
      _ic.visible = false
      attachCloseButtonToLauncher()
    })
    callIntercom('onShow', () => {
      _ic.visible = true
      document.querySelector('.intercom-launcher-frame')?.parentElement?.removeAttribute('style')
    })
    callIntercom('onUnreadCountChange', unreadCount => {
      _ic.unreadCount = unreadCount
    })
  }
  intercom.boot = (options = { app_id: appId }) => {
    if (!options.app_id) {
      options.app_id = appId
    }
    callIntercom('boot', options)
  }
  intercom.shutdown = () => callIntercom('shutdown')
  intercom.update = (...options) => callIntercom('update', ...options)
  intercom.show = () => callIntercom('show')
  intercom.hide = () => callIntercom('hide')
  intercom.showMessages = () => callIntercom('showMessages')
  intercom.showNewMessage = content => callIntercom('showNewMessage', ...(content && typeof content === 'string' ? [content] : []))
  intercom.trackEvent = (name, ...metadata) => callIntercom('trackEvent', ...[name, ...metadata])
  intercom.getVisitorId = () => callIntercom('getVisitorId')

  return intercom
}

function install(app, options) {
  const { app_id: appId } = options

  // window.intercomSettings = options

  const $intercom = initIntercom({ appId })

  if (!installed) {
    if (typeof window.Intercom === 'function') {
      $intercom._init()
      $intercom._call('reattach_activator')
      $intercom.update()
    } else {
      const placeholder = (...args) => placeholder.c(args)
      placeholder.q = []
      placeholder.c = args => placeholder.q.push(args)
      window.Intercom = placeholder
      const loaded = () =>
        loadScript(`https://widget.intercom.io/widget/${appId}`, {
          defer: true,
          id: 'intercom-script',
          callback: () => {
            $intercom._init()

            setTimeout(() => {
              attachCloseButtonToLauncher()
            }, 2000)
          }
        })
      if (document.readyState === 'complete') {
        loaded()
      } else {
        window.addEventListener('load', loaded, false)
      }
    }
    installed = true
  }

  Object.defineProperty(app.config.globalProperties, '$intercom', {
    get: () => $intercom
  })
}

export default install
