export default {
  name: 'wisk-word',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 8.8007812 0 C 6.4007812 0 4.5 2.0003906 4.5 4.4003906 L 4.5 30.599609 C 4.5 32.999609 6.4007812 35 8.8007812 35 L 26.199219 35 C 28.599219 35 30.5 32.999609 30.5 30.599609 L 30.5 12.5 L 18.099609 0 L 8.8007812 0 z M 8.8007812 2.9003906 L 16 2.9003906 L 16 12.400391 C 16 13.600391 16.999219 14.599609 18.199219 14.599609 L 27.599609 14.599609 L 27.599609 30.599609 C 27.599609 31.399609 26.999219 32.099609 26.199219 32.099609 L 8.8007812 32.099609 C 8.0007813 32.099609 7.4003906 31.399609 7.4003906 30.599609 L 7.4003906 4.4003906 C 7.4003906 3.6003906 8.0007812 2.9003906 8.8007812 2.9003906 z M 18.900391 5 L 25.5 11.699219 L 18.900391 11.699219 L 18.900391 5 z M 9.8007812 18.099609 L 12.599609 29.599609 L 15.199219 29.599609 L 17.199219 20.400391 L 19.400391 29.599609 L 22 29.599609 L 24.800781 18.099609 L 22.5 18.099609 L 20.699219 27.300781 L 18.5 18.099609 L 16.099609 18.099609 L 14 27.5 L 12.099609 18.099609 L 9.8007812 18.099609 z'
    }
  ]
}
