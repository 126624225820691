export default {
  name: 'wisk-circle-notch',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.5,34.8C7.8,34.8,0,27,0,17.4C0,9.1,5.8,1.9,13.9,0.2c0.8-0.2,1.6,0.4,1.8,1.2c0.1,0.8-0.4,1.6-1.1,1.7 C6.7,4.8,1.7,12.5,3.3,20.3s9.3,12.8,17.2,11.2c7.8-1.6,12.8-9.3,11.2-17.2c-1.2-5.6-5.6-10-11.3-11.2c-0.8-0.2-1.3-1-1.1-1.8 C19.4,0.6,20.2,0,21,0.2c9.5,1.9,15.6,11.2,13.7,20.6C33,29,25.8,34.8,17.5,34.8L17.5,34.8z'
    }
  ]
}
