<template>
  <component :is="tag" :type="validBtnType" class="btn" :class="classes" :disabled="disabled" @click="onClick">
    <slot name="default" />
  </component>
</template>

<script>
export default {
  name: 'BButton',
  emits: ['click'],
  props: {
    variant: { type: String, default: 'secondary' },
    block: { type: Boolean, default: false },
    btn2: { type: Boolean, default: false },
    pill: { type: Boolean, default: false },
    squared: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
    disabled: { type: Boolean, default: false },
    preventDoubleClick: { type: Boolean, default: false }
  },
  data() {
    return {
      timeoutId: null,
      inTimeout: false,
    }
  },
  computed: {
    classes() {
      return {
        disabled: this.disabled,
        'btn2': this.btn2,
        'btn-block': this.block,
        'rounded-pill': this.pill,
        'rounded-0': this.squared && !this.pill,
        [`btn-${this.variant}`]: this.variant,
        [`btn-${this.size}`]: this.size
      }
    },
    tag() {
      if (this.$attrs.to) {
        return 'router-link'
      }
      if (this.$attrs.href || this.$attrs.type === 'link') {
        return 'a'
      }
      return 'button'
    },
    validBtnType() {
      return this.$attrs.type === 'submit' ? 'submit' : 'button'
    }
  },
  methods: {
    onClick(ev) {
      if (this.preventDoubleClick) {
        if (!this.inTimeout) {
          this.$emit('click', ev)
          this.inTimeout = true
        }

        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          this.inTimeout = false
        }, 1500)
      } else {
        this.$emit('click', ev)
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId)
  }
}
</script>
