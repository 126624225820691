<template>
  <div class="flex-row align-items-center w-100 h-100 user-onboarding-wrapper">
    <b-container style="padding-top: calc((100vh - 275px) / 2);">
      <b-row>
        <b-col class="middle-center">
          <h4 class="d-block me-4 mb-4" v-if="!user.user_venues.length">
            {{ translations.txtUserOnboardingNoVenue }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4 class="d-block mx-auto mb-4" style="width: 700px;" v-if="venue && venue.id">
            {{ translations.txtUserOnboardingAlmostThere }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col class="pt-4">

          <b-button class="mx-auto d-block" v-if="!user.user_venues.length"
            @click="addVenue" variant="primary">
            {{ translations.txtVenuesNew }}
          </b-button>
          <loading :loading="loading" />
        </b-col>
      </b-row>
      <b-button variant="primary" @click="logout" class="d-block" style="position: absolute; bottom: 50px; left: 50px;">
        {{ translations.txtAuthSignOut }}
      </b-button>

    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import loading from '@/components/common/WiskLoading'

export default {
  name: 'UserOnboarding',
  components: { loading },
  props: {},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'firebaseUser']),
    ...mapGetters(['venue'])
  },
  methods: {
    ...mapActions(['logout', 'setGlobalAction']),
    addVenue() {
      if (this.user && this.user.god_mode) this.setGlobalAction({ type: 'venueEdit', action: { id: 0 }, skipPermissionCheck: true })
      else window.open(this.$upgradeMeetingLink, '_blank')
    }
  },
  watch: {
    user: {
      handler(user) {
        if (user && user.signup_parameters && user.signup_parameters.venue) {
          if (!this.venue || !this.venue.id) {
            this.setGlobalAction({ type: 'venueEdit', action: { id: 0 } })
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.user-onboarding-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
}
</style>
