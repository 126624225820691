export default {
  name: 'wisk-compress-alt',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 33.537109 -0.00390625 A 1.4778 1.4778 0 0 0 32.470703 0.43164062 L 21.21875 11.683594 L 21.25 4.8789062 A 1.4787 1.4787 0 1 0 18.292969 4.8574219 L 18.240234 15.25 A 1.5078 1.5078 0 0 0 18.671875 16.308594 A 1.4814 1.4814 0 0 0 19.730469 16.738281 L 30.123047 16.6875 A 1.4247 1.4247 0 0 0 31.160156 16.255859 A 1.4474 1.4474 0 0 0 31.591797 15.197266 A 1.4642 1.4642 0 0 0 30.101562 13.728516 L 23.337891 13.759766 L 34.568359 2.5292969 A 1.4874 1.4874 0 0 0 34.568359 0.43164062 A 1.4778 1.4778 0 0 0 33.537109 -0.00390625 z M 15.251953 18.253906 L 4.859375 18.306641 A 1.4765 1.4765 0 0 0 3.390625 19.796875 A 1.4946 1.4946 0 0 0 4.8789062 21.285156 L 11.654297 21.244141 L 0.43359375 32.462891 A 1.4831 1.4831 0 0 0 2.53125 34.560547 L 13.751953 23.339844 L 13.720703 30.125 A 1.483 1.483 0 0 0 16.257812 31.173828 A 1.4233 1.4233 0 0 0 16.6875 30.136719 L 16.751953 19.753906 A 1.5026 1.5026 0 0 0 16.300781 18.695312 A 1.4929 1.4929 0 0 0 15.251953 18.253906 z '
    }
  ]
}
