import Noty from 'noty'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import store from '@/store'

const makeToast = (text, type, options = {}) => location.pathname === '/public/dashboard/printable' ? () => ({}) :
  new Noty({
    theme: 'semanticui',
    timeout: 5000,
    progressBar: true,
    visibilityControl: true,
    text,
    layout: 'bottomRight',
    type,
    callbacks: {
      onTemplate() {
        let span = document.createElement('span')
        span.style.position = 'absolute'
        span.style.top = '2px'
        span.style.right = '6px'
        span.style.fontSize = '11px'
        span.style.fontWeight = '600'

        span.innerText = '\u{2715}'
        this.barDom.appendChild(span)
        this.barDom.classList.add('pt-1')
      }
    },
    ...options
  })

const toasts = {
  success: text => {
    if (text) {
      Noty.closeAll('success')
      makeToast(text, 'success', { queue: 'success', timeout: 3000 })?.show?.()
    }
  },

  error: (text, err) => {
    const showErrorIdCopyButton = err?.response?.data?.id && (store.state.user?.god_mode || window.location.hostname === 'web.wisk.cool' || window.location.hostname === 'web.wisk.dev'),
      showOpenContactSupportButton = !store.state.user?.god_mode && (window.location.hostname === 'web.wisk.ai' || window.location.hostname === 'web.wisk.cool'),
      buttons = []

    if (showErrorIdCopyButton) {
      buttons.push(
        Noty.button('Copy error id', 'btn btn-danger btn-sm', e => {
          toClipboard(err.response.data.id)
            .then(() => {
              const button = e.barDom.querySelector('button')
              button.innerText = 'Copied!'
              setTimeout(() => {
                button.innerText = 'Copy error id'
              }, 1000)
            })
            .catch(() => {})
        })
      )
    }

    if (showOpenContactSupportButton) {
      buttons.push(
        Noty.button('Contact support', 'btn btn-danger btn-sm bt-contact-form', e => {
          store.dispatch('setGlobalAction', {
            type: 'supportEmailForm',
            action: {
              extraHelpText: 'Error info attached. Feel free to send as is or add a message for more details.',
              supportFormData: {
                subject: 'Error report from the web app',
                body: '',
                extraData: {
                  'Error ID': err?.response?.data?.id,
                  API: err?.config?.url
                }
              }
            }
          })

          const button = e.barDom.querySelector('.bt-contact-form')
          button.disabled = true
        })
      )
    }

    makeToast(text, 'error', { buttons })?.show?.()
  },

  info: text => {
    makeToast(text, 'info')?.show?.()
  },

  warning: text => {
    makeToast(text, 'warning')?.show?.()
  },
  apiChangesSavedNotification: text =>
    makeToast(text, 'info', {
      timeout: 900,
      progressBar: false,
      visibilityControl: true,
      layout: 'bottomCenter',
      animation: {
        open: 'animated slideUpFadeIn quick',
        close: 'animated fadeOut quick'
      },
      callbacks: {
        onTemplate() {
          this.barDom.style.opacity = 0.6
          this.barDom.style.width = '200px'
          this.barDom.style.textAlign = 'center'
        }
      }
    })?.show?.(),

  makeToast: options => makeToast(null, null, { ...options, type: options.innerType })?.show?.()
}

export default toasts
