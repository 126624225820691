<template>
  <div style="display: block; position: absolute; z-index: 9; bottom: 0px; opacity: .6; font-size: 90%;"
    :style="{ [position]: positionPadding }">
    {{ extraText }}
  </div>
</template>

<script>
export default {
  name: 'ExtraText',
  props: { params: Object, text: String },
  data() {
    return {
      localText: ''
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    extraText() {
      return this.localText || this.text || this.params.extraText || this.params.value.extraText || ''
    },
    position() {
      if (this.params.extraTextPosition) {
        return this.params.extraTextPosition
      }
      return 'left'
    },
    positionPadding() {
      return this.params.extraTextPositionPadding || '5px'
    }
  },
  mounted() {
    if (this.params.getExtraText) {
      this.localText = this.params.getExtraText(this.params)
    }
  },
}
</script>
