<template>
  <b-container fluid>
    <wiskInput infoTooltipKey="9f6db40f-0482-47a5-9441-3ce8819242c9" autofocus :label="translations.txtGenericTypeToSearch" v-model="query" v-if="user?.god_mode" showClearButton class="mb-1"
      size="sm" />
    <wiskGrid gridAutoHeight :columnDefs="columns" :rowData="localData" :loadingOverlay="loading" v-if="user" parentGridName="PartnerVenuesGrid" />
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import { arrayToObjectById } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'


export default {
  name: 'PartnerVenues',
  components: { wiskGrid },
  emits: ['query'],
  props: { venueData: Array },
  data() {
    return {
      localData: [],
      loading: false,
      query: '',
      searchTimeoutId: null
    }
  },
  computed: {
    ...mapState(['user', 'translations']),
    ...mapGetters(['venue']),
    columns() {
      return [
        {
          colId: 'id',
          headerName: this.translations.txtGenericId,
          field: 'id'
        },
        {
          colId: 'title',
          headerName: this.translations.txtGenericTitle,
          field: 'title'
        },
        {
          colId: 'role',
          headerName: this.translations.txtGenericRole,
          valueGetter: params => get(params, 'data.role.title', '-')
        },
        {
          headerName: this.translations.txtVenuesPartnerVenue,
          colId: 'partner',
          cellRenderer: 'CellCheckbox',
          minWidth: 50,
          maxWidth: 150,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            useValueFormatter: true,
            save: (value, id) => {
              this.savePartnerVenue(id, value)
            }
          },
          valueGetter: params => ({
            readonly: (get(params, 'data.role.id') !== 1 || this.user.role.id !== 1) && !this.user.god_mode,
            inputClass: ((get(params, 'data.role.id') !== 1 || this.user.role.id !== 1) && !this.user.god_mode && 'text-gray-400') || '',
            id: get(params, 'data.id'),
            tooltip: (get(params, 'data.role.id') === 1 && this.user.role.id === 1) || this.user.god_mode ? '' : this.translations.txtVenuesPartnerVenueCannotChangeNotAdmin,
            input_value: !!get(params, 'data.partner')
          })
        }
      ]
    },
    partnerVenuesMap() {
      return arrayToObjectById(this.venue.partner_venues)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenue']),
    savePartnerVenue(venueId, partner) {
      this.updateVenue({ id: this.venue.id, operation: { value: venueId, type: partner ? 'partner_venue_add' : 'partner_venue_delete' } })
        .then(response => {
          // TODO:
          // server initially sends a wrong value, this will overwrite it until server sends the right value
          // this is a temporary workaround and will be removed after server fixes the issue
          setTimeout(() => {
            this.$store.commit('mutateCurrentVenue', response)
          }, 1000)
        })
    },
    populateData() {
      this.localData = this.venueData
        .filter(v => v.venue.id !== this.venue.id)
        .map(v => ({ title: v.venue.title, id: v.venue.id, role: v.role, partner: !!this.partnerVenuesMap[v.venue.id] }))

      if (Array.isArray(this.venue?.partner_venues)) {
        this.venue.partner_venues.forEach(partnerVenue => {
          if (!this.localData.find(l => l.id === partnerVenue.id)) {
            this.localData.push({ ...partnerVenue, partner: true, role: {} })
          }
        })
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.searchTimeoutId)
  },
  watch: {
    venueData: {
      handler: 'populateData',
      immediate: true
    },
    venue: 'populateData',
    query(query) {
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(() => {
        if (!query && this.user.god_mode) {
          this.usersVenuesData = []
        }
        this.$emit('query', query)
      }, 600)
    }
  }
}
</script>

<style lang="scss"></style>
