<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'gridViewEdit', action: null })" size="md" @ok="save" :okText="translations.txtGenericSave" hideFooterExtra
    :okDisabled="!valid || !localView.configs.system">

    <wiskInputGroup @errorCountChanged="setValidState" class="px-5 pt-2" style="min-height: 500px;" :disabled="!localView.configs.system">
      <b-row no-gutters class="mb-5">
        <b-col style="height: 20px;" cols="12">
          <small class="text-muted">Id: {{ localView.id }}</small>
          <b-copy variant="link" class="mb-0 ms-1 p-0" :copyText="localView.id" :iconScale="0.7" />
        </b-col>
        <b-col style="height: 20px;" cols="12"><small class="text-muted">System: {{ !!localView.configs.system }}</small></b-col>
        <b-col style="height: 20px;" cols="12"><small class="text-muted">Default: {{ !!localView.configs.default }}</small></b-col>
      </b-row>

      <b-row no-gutters>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.title" label="Title" required />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.titleDashboard" label="Title in Reports Dashboard" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.description" label="Description (subtitle)" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.pageTitle" label="Page Title" :required="localView.configs.default" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.path" label="Path (URL)" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.routeName" label="Route Name" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.gridId" label="Grid Id - parentGridName" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.tabTitle" label="Tab Name" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.tab" label="Tab Id" />
        </b-col>
        <b-col cols="12" class="">
          <wiskInput v-model="localView.configs.dashboardVisible" label="Visible in reports dashboard" inputType="checkbox" />
        </b-col>
      </b-row>
    </wiskInputGroup>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import axios from 'axios'
import { downloadObjectAsJson, isLocalhost } from '@/modules/utils'

export default {
  name: 'WiskGridViewEdit',
  components: {},
  props: { editAction: { type: Object } },
  data() {
    return {
      localView: null,
      valid: true
    }
  },
  computed: {
    ...mapState(['gridViews', 'gridViewsCore', 'translations', 'venue']),
    ...mapGetters([]),
    localViews() {
      let arr = [...this.gridViews, ...this.gridViewsCore].map(v => merge({}, v))

      return arr
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getTabInfo() {
      let tabTitleRaw = document.querySelector('.wisk-tab.nav-link.active')?.textContent?.trim?.(),
        tabTitle = tabTitleRaw ? tabTitleRaw.charAt(0).toUpperCase() + tabTitleRaw.slice(1) : '',
        patch = {
          description: '',
          configs: {
            dashboardVisible: true,
            pageTitle: this.$route.meta?.title || '',
            routeName: this.$route.name,
            tab: this.$route.query?.tab || '',
            tabTitle,
            path: this.$route.path.replace(`/${this.venue.id}`, '')
          }
        }

      return patch
    },
    save() {
      let copy = merge({}, this.localView)
      copy.columns = copy.columns.filter(c => !c.colId.startsWith('custom-field'))

      if (isLocalhost) {
        axios.post('http://localhost:3000/save-json', {
          path: './src/components/grids/views/',
          data: copy,
          filename: `${this.localView.gridId}.${this.localView.id}`
        }).then(() => {
          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(this.localView)
          }
        }).catch(e => {
          alert('Error writing file to disk, initiated a download of the JSON file instead. Check the error in console for more info. Probably the node server was not started.')
          console.error('Error writing file to disk', e)
          downloadObjectAsJson(copy, `${this.localView.gridId}.${this.localView.id}.json`)
        })
      } else {
        downloadObjectAsJson(copy, `${this.localView.gridId}.${this.localView.id}.json`)
      }
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    }
  },
  created() { },
  watch: {
    editAction: {
      handler() {
        if (this.editAction) {
          if (this.editAction.id) {
            this.localView = this.localViews.find(v => v.id === this.editAction.id)
          }
          if (this.editAction.view?.id) {
            this.localView = merge({}, this.editAction.view)
          }

          setTimeout(() => {
            if (!this.localView?.configs?.routeName) {
              this.localView = merge({}, this.localView, this.getTabInfo())

              setTimeout(() => {
                alert('Tab information was missing, please press Save.')
              }, 0)
            }
          }, 1000)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
