<template>
  <div :class="{ 'small-block': smallBlock, 'd-inline-block': inline }" class="fill-width-height">
    <div class="fill-width-height gatekeeper-wrapper" :class="[containerClass, { 'd-inline-block': inline, 'limit-warning': conditionsComputed.bottomWarning }]" @mousedown.capture="onClick">

      <slot v-if="conditionsComputed.defaultSlot" />

      <slot v-if="conditionsComputed.contentSlot" name="replace-content" :callPlanSelector="callPlanSelector" />

      <b-button v-if="conditionsComputed.adminBypass" class="mt-2 admin-bypass-btn" variant="success" size="sm" @click="bypass = true" v-tooltip="'Button visible only for Wisk Admin'">
        {{ translations.txtGenericBypassThis }}
      </b-button>

      <div v-if="conditionsComputed.fullWarning" class="d-flex justify-content-center align-items-center plan-feature-limit-full-block">

        <p class="feature-limit-message p-3 mt-2" v-if="!noMessageButton">
          {{ currentFeature.message }}
        </p>

        <b-button variant="primary" :size="smallBlock ? 'sm' : 'md'" @click="callPlanSelector" v-if="!noMessageButton">
          {{ upgradeNowTextComputed }}
        </b-button>

        <b-button class="mt-2" variant="success" :size="smallBlock ? 'sm' : 'md'" @click="bypass = true" v-if="user.god_mode"
          v-tooltip="'Button visible only for Wisk Admin'">
          {{ translations.txtGenericBypassThis }}
        </b-button>

      </div>
      <div v-if="conditionsComputed.bottomWarning" class="plan-feature-limit-warning">
        <span class="feature-limit-message">
          {{ currentFeature.message }}
        </span>
        <b-button variant="primary" size="sm" @click="callPlanSelector">
          {{ upgradeNowTextComputed }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'GateKeeper',
  components: {},
  props: {
    feature: String,
    containerClass: String,
    keepContent: { type: Boolean, default: true },
    replaceContent: Boolean,
    nagInLimit: Boolean,
    dontNagIfLimitReached: Boolean,
    dontBlockJustNag: Boolean,
    blockIfLimitReached: Boolean,
    smallBlock: Boolean,
    limitedItemsCount: Number,
    noMessageButton: Boolean,
    reactOnClickEvent: Boolean,
    limitReached: Boolean,
    inline: Boolean
  },
  data() {
    return {
      bypass: false,
      gateKeeper: true
    }
  },
  computed: {
    ...mapState(['currentPlanFeaturesByType', 'translations', 'user']),
    ...mapGetters(['venue', 'upgradeNowTextComputed']),
    currentFeature() {
      if (this.feature) {
        return this.currentPlanFeaturesByType[this.feature]
      }
      return null
    },
    limitReachedComputed() {
      if (this.currentFeature && this.currentFeature.limit && this.limitedItemsCount && this.limitedItemsCount >= this.currentFeature.limit) {
        return true
      }

      return !!this.limitReached
    },
    conditionsComputed() {
      let { currentFeature, keepContent, bypass, replaceContent, user, dontBlockJustNag, limitReachedComputed, blockIfLimitReached, reactOnClickEvent, dontNagIfLimitReached, nagInLimit } = this
      return {
        defaultSlot: (currentFeature && (currentFeature.enabled || (!currentFeature.enabled && keepContent))) || !currentFeature || bypass,
        contentSlot: !bypass && currentFeature && !currentFeature.enabled && replaceContent,
        adminBypass: !bypass && currentFeature && !currentFeature.enabled && replaceContent && user.god_mode,
        fullWarning: !bypass && currentFeature && ((!currentFeature.enabled && !dontBlockJustNag) || (limitReachedComputed && blockIfLimitReached)) && !reactOnClickEvent && keepContent,
        bottomWarning: currentFeature && (((limitReachedComputed && !blockIfLimitReached && !dontNagIfLimitReached) || (nagInLimit && currentFeature.limit) || (!currentFeature.enabled && dontBlockJustNag)) && !reactOnClickEvent && keepContent),
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    callPlanSelector() {
      this.setGlobalAction({ type: 'planSelectorEdit', action: { venue: this.venue, feature: this.feature } })
    },
    onClick(ev) {
      if (this.reactOnClickEvent && ((this.currentFeature && !this.currentFeature.enabled) || this.limitReachedComputed)) {
        ev.stopImmediatePropagation()

        this.setGlobalAction({
          type: 'confirmDialog',
          action: {
            type: 'confirm',
            props: { okText: this.upgradeNowTextComputed },
            payload: { callback: this.callPlanSelector, message: this.currentFeature.message, title: this.translations.txtVenueOperationsPlanUpgradeNeeded }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.small-block {
  position: relative;
  margin-bottom: 1rem;

  .gatekeeper-wrapper {
    .plan-feature-limit-full-block {
      flex-direction: unset;
      border: 1px solid var(--primary);
      border-radius: var(--bs-border-radius);
    }
  }
}

.gatekeeper-wrapper {
  position: relative;
  display: flex;

  .plan-feature-limit-full-block {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
    top: -10px;
    left: 0;
    right: 0;
    bottom: -15px;
    flex-direction: column;
  }

  .plan-feature-limit-warning {
    position: absolute;
    bottom: 0;
    background-color: var(--gray-100);
    width: 100%;
    padding: 5px;
    z-index: 100;

    .feature-limit-message {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &.limit-warning .ag-root-wrapper {
    padding-bottom: 35px;
  }

  .admin-bypass-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
