export default {
  name: 'wisk-sales-alt',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M25.4572,28.1293a10.0554,10.0554,0,0,1-5.5981,2.6772v2.598A1.5955,1.5955,0,0,1,18.2636,35h-1.3a1.5955,1.5955,0,0,1-1.5955-1.5955V30.9554a17.5923,17.5923,0,0,1-4.5489-.9341A13.9156,13.9156,0,0,1,8.4178,28.92a1.6255,1.6255,0,0,1-.6962-2.0689l.5776-1.2826A1.5721,1.5721,0,0,1,10.47,24.807a13.4015,13.4015,0,0,0,6.5462,1.6943,6.123,6.123,0,0,0,3.5-.8428,2.7191,2.7191,0,0,0,1.215-2.3759,1.9564,1.9564,0,0,0-.6735-1.5333,4.9684,4.9684,0,0,0-1.7024-.9544q-1.0307-.3553-2.9379-.8428a31.7874,31.7874,0,0,1-4.5692-1.4215A7.9574,7.9574,0,0,1,8.761,16.2257,6.2684,6.2684,0,0,1,7.4681,12.09,7.03,7.03,0,0,1,9.6207,6.9418,10.1834,10.1834,0,0,1,15.256,4.1935V1.5955A1.5955,1.5955,0,0,1,16.8515,0h1.3a1.5955,1.5955,0,0,1,1.5955,1.5955V4.0784a18.4012,18.4012,0,0,1,4.0818,1.0323,11.2886,11.2886,0,0,1,1.9452.9467,1.6174,1.6174,0,0,1,.6335,2.0407l-.5956,1.3226a1.5776,1.5776,0,0,1-2.1976.7467,11.9315,11.9315,0,0,0-5.8879-1.6721,5.4945,5.4945,0,0,0-3.2391.8631,2.7664,2.7664,0,0,0-1.215,2.3963,2.2615,2.2615,0,0,0,.7107,1.7193,4.8808,4.8808,0,0,0,1.7972,1.0493q1.0814.3756,3.0326.86a34.8159,34.8159,0,0,1,4.4541,1.3877A7.598,7.598,0,0,1,26.28,18.9774a6.0984,6.0984,0,0,1,1.2523,4.0446A6.94,6.94,0,0,1,25.4572,28.1293Z'
    }
  ]
}
