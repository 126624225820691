export default {
  name: 'wisk-show',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.6,16.6c-2.5-2.7-5.5-4.9-8.7-6.5c-2.2-1.1-4.6-1.8-7-2c-2.5-0.2-5.1,0.2-7.4,1.1c-4.2,1.6-8,4.2-11,7.5 c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0.1,0.1c2.4,2.5,5.2,4.7,8.3,6.2c2.6,1.4,5.5,2.2,8.4,2.3c2.2,0,4.5-0.4,6.6-1.2 c4.2-1.6,7.9-4.1,10.9-7.4C35.1,17.9,35.1,17.2,34.6,16.6C34.7,16.6,34.6,16.6,34.6,16.6z M17.5,23.4c-3.2,0-5.9-2.6-5.9-5.8 c0-3.2,2.6-5.9,5.8-5.9c3.2,0,5.9,2.6,5.9,5.8c0,0,0,0,0,0C23.4,20.7,20.8,23.3,17.5,23.4z'
    },
    { d: 'M20.9,17.5c0,1.9-1.6,3.4-3.4,3.4c-1.9,0-3.4-1.6-3.4-3.4c0-1.9,1.6-3.4,3.4-3.4c0,0,0.1,0,0.1,0 C19.5,14.2,20.9,15.7,20.9,17.5z' }
  ]
}
