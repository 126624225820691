<template>
  <b-container fluid class="p-0">
    <b-row class="mb-2">
      <b-col cols="4">
        <b-form-group label="" label-for="elementsPrependAppend">
          <b-input-group>
            <input class="form-control form-control-lg" id="elementsPrependAppend" type="text" v-model="query" ref="searchBox"
              :placeholder="translations.txtOnlineItemSearchCatalog" @input="searchCatalog" />
            <div class="d-flex" style="margin-left: -3px ;">
              <div class="input-group-text" style="border-color: var(--bs-border-color);">
                <icon name="wisk-circle-notch" :class="{ 'fa-spin fa-5x': searchLoading }" />
              </div>
            </div>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button @click="newBottle" variant="primary" size="md">
          {{ translations.txtVenueBottlesCreate }}
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="searchPerformed && !bottles.length">
      <b-col cols="3">
        <p> {{ translations.txtGenericSearchReturnNothing }} </p>
      </b-col>
    </b-row>
    <b-row v-if="query.length < 3">
      <b-col cols="3">
        <p> {{ translations.translate('tplOnlineItemSearchMinChars', { '{a}': 3 }) }} </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <wiskItemsGrid :options="gridOptions" :columns="columns" :items="computedBottles" ignoreRowSelection :loading="gridLoading" parentGridName="OnlineItemSearch"
          :gridStyle="{ height: 'calc(100vh - 210px)' }" class="p-0" @gridApi="gridApiAvailable" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import merge from 'lodash.merge'
import api from '@/api'
import { round } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'

export default {
  name: 'OnlineItemSearch',
  emits: ['gridApi'],
  components: { wiskItemsGrid },
  props: {
    distributor: { type: Object },
    startQuery: String,
    extraColumns: { type: Object, default: () => ({}) },
    resultItemTransformer: Function,
    loading: Boolean
  },
  data() {
    return {
      bottles: [],
      gridLoading: false,
      searchLoading: false,
      searchPerformed: false,
      query: '',
      gridOptions: {
        rowSelection: { mode: 'singleRow' }
      },
      timeoutId: null
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById']),
    computedBottles() {
      return this.bottles.map(item => {
        item.variants = item.item_distributors

        if (typeof this.resultItemTransformer === 'function') {
          return this.resultItemTransformer(item)
        }
        return item
      })
    },
    columns() {
      return merge(
        {
          image: {
            hide: false
          },
          title: {
            hide: false,
            cellRendererParams: {
              extraButtons: [
                {
                  id: 1,
                  action: (id, data) => {
                    this.setGlobalAction({ type: 'itemEdit', action: { item: data.item, readonly: true } })
                  },
                  icon: 'wisk-show'
                },
                {
                  id: 2,
                  icon: '',
                  action: () => { }
                }
              ],
              readonly: true
            }
          },
          family: {
            hide: false,
            filter: null,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          category: {
            hide: false,
            filter: null,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          costUnit: {
            hide: false,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          caseSize: {
            hide: false,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          costCase: {
            hide: false,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          fee: {
            hide: false,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          },
          orderFormat: {
            hide: false,
            cellRendererParams: {
              infoComponentType: null,
              readonly: true
            }
          }
        },
        this.extraColumns
      )
    }
  },
  methods: {
    ...mapActions(['importBottle', 'setGlobalAction']),
    gridApiAvailable(agGridApi) {
      this.$emit('gridApi', agGridApi)
    },
    searchCatalog(event) {
      let inputValue = event.target.value
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        if (inputValue && inputValue.length > 2) {
          let distributorId = (this.distributor && this.distributor.id) || ''

          this.searchLoading = true

          api
            .bottleTitleSearch({ title: inputValue, distributorId })
            .then(data => {
              this.bottles = data.map(b => ({
                ...b,
                item_distributor_id: (b.item_distributor_ids && b.item_distributor_ids[0]) || null,
                titleSuffix: ` - (${round(b.measurement.quantity, 4)} ${b.measurement.unit_of_measurement})` + (b.archived ? ` - (${this.translations.txtGenericArchived})` : '')
              }))
              this.searchLoading = false
              this.searchPerformed = true
            })
            .catch(() => {
              this.searchLoading = false
              this.searchLoading = false
            })
        }
      }, 1000)
    },
    newBottle() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: { item_id: 0, title: this.query }
      })
    }
  },
  mounted() {
    this.query = this.startQuery || ''

    this.$refs.searchBox && this.$refs.searchBox.focus()
    setTimeout(() => {
      this.searchCatalog({ target: { value: this.query } })
    }, 0)
  },
  watch: {
    loading(loading) {
      this.gridLoading = loading
    }
  }
}
</script>
