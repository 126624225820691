<template>
  <div v-if="params.node.group && !params.showInGroup" class="fill-width-height">
    <div v-if="params.wiskGroupDisplayCell">
      {{ params.value }}
    </div>
    <div v-if="showAggregatedValue" class="cell-number-group text-end fill-width-height pe-3" :class="{ negative: aggregatedValue < 0 }">
      {{ aggregatedValueString ? prefix + aggregatedValueString + suffix : '' }}
    </div>
  </div>
  <div v-else class="cell-number" :class="{ negative, readonly }">
    <maskInput v-if="!cellOverlayVisible" :className="validationClass + ' form-control text-end pe-3 ' + inputClass" ref="maskInput" @blur="onBlur" :allowEmpty="allowEmpty"
      :readonly="readonly" :placeholder="placeholder" selectOnFocus :decimals="decimals" :decimalSeparator="decimalSeparator" :customFormatter="params.customFormatter"
      :thousandsSeparator="thousandsSeparator" :prefix="prefix" :suffix="suffix" :min="min" :max="max" :transformerOut="transformerOut" :transformerIn="transformerIn" />

    <div @mousedown="onCellClick" v-show="cellOverlayVisible" class="overlay form-control plain-text-span plain-text text-end pe-3"
      :class="colIdClass" :title="formattedDisplayValue">
      {{ formattedDisplayValue }}
    </div>

    <v-menu :triggers="['hover', 'focus']" :distance="5" v-if="!valid && mounted">
      <icon class="info-icon text-danger no-hide validation" name="wisk-warning" />

      <template v-slot:popper>
        <div class="p-2">

          <span class="text-danger text-bold">
            {{ validationMessage }}
          </span>
          <p v-show="!allowBlur"></p>
          <p v-show="!allowBlur">
            {{ translate('txtValidationEscapeToReset') }}
          </p>
        </div>
      </template>
    </v-menu>

    <slot></slot>
  </div>
</template>

<script>
import { formatNumber } from '@/modules/utils'
import maskInput from '@/components/common/MaskInput'

export default {
  name: 'CellNumberBase',
  emits: ['editing'],
  components: { maskInput },
  props: {
    params: Object,
    suffix: { type: String, default: '' }
  },
  data() {
    return {
      oldValue: null,
      localValue: null,
      agCell: null,
      inputClassBase: 'plain-text',
      cellOverlayVisible: true,
      valid: true,
      allowBlur: false,
      validationMessage: '',
      mounted: false,
      aggregatedValue: 0,
      aggregatedValueString: 0
    }
  },
  computed: {
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    transformerOut() {
      return this.params && this.params.transformerOut
    },
    transformerIn() {
      return this.params && this.params.transformerIn
    },
    computedValue() {
      return this.params && this.params.value && this.params.value.input_value
    },
    negative() {
      return !this.params.disableNegative && this.computedValue && this.computedValue < 0
    },
    formattedValue() {
      let value = this.localValue

      if (this.params.node.rowPinned) {
        value = this.params?.data[this.params?.colDef?.colId] || 0
      }

      if (this.transformerIn) {
        value = this.transformerIn(value)
      }

      if (this.params.customFormatter) {
        return this.params.customFormatter(value)
      }

      return formatNumber(value, {
        decimals: this.decimals,
        decimalSeparator: this.decimalSeparator,
        thousandsSeparator: this.thousandsSeparator,
        decimalsAsNeeded: this.decimalsAsNeeded
      })
    },
    formattedDisplayValue() {
      return this.formattedValue ? this.prefix + this.formattedValue + this.suffix : ''
    },
    placeholder() {
      return this.params.placeholder
    },
    type() {
      return this.params.type
    },
    min() {
      return this.params.min
    },
    max() {
      return this.params.max
    },
    validations() {
      return this.params.validations || []
    },
    validationClass() {
      return this.valid ? '' : 'is-invalid'
    },
    id() {
      return this.params && this.params.value && this.params.value.id
    },
    decimals() {
      return (this.params.value && this.params.value.decimals) || this.params.decimals
    },
    decimalsAsNeeded() {
      return this.params.decimalsAsNeeded
    },
    thousandsSeparator() {
      if (this.params.thousandsSeparator === undefined) {
        return ','
      }
      return this.params.thousandsSeparator || ''
    },
    decimalSeparator() {
      return this.params.decimalSeparator || '.'
    },
    prefix() {
      return this.params.prefix || ''
    },
    readonly() {
      return !!this.params.readonly || !!this.params.value.readonly
    },
    allowEmpty() {
      return !!this.params.allowEmpty
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    inputClass() {
      return this.inputClassBase + ' wisk-cell-text ' + this.params.colDef.colId
    },
    showAggregatedValue() {
      return this.params?.colDef?.aggFunc
    }
  },
  methods: {
    prepareAggregatedValue() {
      let colId = this.params.colDef.colId,
        total = this.params?.node?.aggData?.[colId] || 0

      this.aggregatedValue = total

      if (this.params.customFormatter) {
        this.aggregatedValueString = this.params.customFormatter(total)
      } else {
        this.aggregatedValueString = formatNumber(total, {
          decimals: this.decimals,
          decimalSeparator: this.decimalSeparator,
          thousandsSeparator: this.thousandsSeparator,
          decimalsAsNeeded: this.decimalsAsNeeded
        })
      }
    },
    onCellClick() {
      if (this.readonly && this.params?.api?.gridOptionsWrapper?.gridOptions?.rowSelection === 'single') {
        this.params.node.setSelected(true)
      }

      if (!this.readonly) {
        let startEdit = () => {
          this.cellOverlayVisible = false

          if (this.agCell) {
            this.agCell?.classList?.remove && this.agCell.classList.remove('ag-cell-not-inline-editing')
            this.agCell?.classList?.add && this.agCell.classList.add('ag-cell-inline-editing')
          }
          this.inputClassBase = ''

          setTimeout(() => {
            this.initValue()
            this.$refs.maskInput && this.$refs.maskInput.startEditing && this.$refs.maskInput.startEditing()
          }, 0)
        }

        if (this.params?.confirmBeforeEdit) {
          this.params?.confirmBeforeEdit.confirm().then(startEdit)
        } else {
          startEdit()
        }
      }
    },
    initValue() {
      if (this.$refs.maskInput && this.$refs.maskInput.setValue) {
        this.$refs.maskInput.setValue(this.computedValue)
        this.oldValue = this.computedValue
      }
    },
    resetValue() {
      if (this.$refs.maskInput && this.$refs.maskInput.setValue) {
        this.$refs.maskInput.setValue(this.oldValue)
      }
    },
    resetStyle() {
      this.inputClassBase = 'plain-text'
      this.cellOverlayVisible = true

      if (this.agCell) {
        this.agCell.classList.remove('ag-cell-inline-editing')
        this.agCell.classList.add('ag-cell-not-inline-editing')
      }
    },
    onBlur(event) {
      let ok = true,
        value = event.value

      if (event.stop) {
        this.resetValue()
        this.resetStyle()
      } else {
        for (let i = 0; i < this.validations.length; i++) {
          const validation = this.validations[i]
          ok = validation.validator(value, this.computedValue, this.params.data)
          if (!ok) {
            i = this.validations.length
            this.validationMessage = validation.message
            this.allowBlur = validation.allowBlur
            if (!validation.allowBlur) {
              this.$refs.maskInput && this.$refs.maskInput.focus()
            }
          }
        }

        if (ok) {
          if (this.oldValue !== value && typeof this.params.save === 'function') {
            this.params.save(value, this.id, this.type, this.params.value)
            this.localValue = value
          }

          if (this.oldValue !== value && typeof this.params.updateValue === 'function') {
            this.params.updateValue({ value, id: this.id, type: this.type, data: this.params.value, previousValue: this.oldValue })
            this.localValue = value
          }

          this.resetStyle()
        }
      }
      this.valid = ok
    }
  },
  mounted() {
    setTimeout(() => {
      this.agCell = this.params.eGridCell
      this.mounted = true

      if (this.params?.node?.group && this.params?.colDef?.aggFunc) {
        this.params.api && this.params.api.addEventListener('filterChanged', this.prepareAggregatedValue)
        this.params.api && this.params.api.addEventListener('rowDataUpdated', this.prepareAggregatedValue)
        this.params.api && this.params.api.addEventListener('rowDataChanged', this.prepareAggregatedValue)
        this.prepareAggregatedValue()
      }
    }, 0)
  },
  beforeUnmount() {
    this.params.api && this.params.api.removeEventListener('filterChanged', this.prepareAggregatedValue)
    this.params.api && this.params.api.removeEventListener('rowDataUpdated', this.prepareAggregatedValue)
    this.params.api && this.params.api.removeEventListener('rowDataChanged', this.prepareAggregatedValue)
    this.agCell = null
  },
  watch: {
    computedValue: {
      handler() {
        this.oldValue = this.computedValue
        this.localValue = this.computedValue
      },
      immediate: true
    },
    cellOverlayVisible(cellOverlayVisible) {
      setTimeout(() => {
        this.$emit('editing', !cellOverlayVisible)
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.cell-number {
  width: 100%;
  height: 100%;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 0;
    cursor: pointer;

    &.readonly {
      cursor: default;
    }

    &:hover {
      background-color: rgba(#63c2de, 0.1);
    }
  }

  &.negative {
    color: var(--danger);
  }
}

.link-like {
  cursor: pointer;

  &:hover {
    .overlay {
      text-decoration-line: underline;
    }
  }

  .overlay {
    text-underline-offset: 3px;
    text-decoration-color: var(--blue-700) !important;
    text-decoration-thickness: 1px;
    color: var(--primary) !important;
  }
}

.cell-number-group {
  &.negative {
    color: var(--danger);
  }
}

.ag-theme-balham .ag-cell-inline-editing {
  height: inherit;
}
</style>
