export default {
  name: 'wisk-help-page',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.5,2.4c-0.9-0.8-2.1-1.3-3.4-1.2h-0.2c-4.2,0.5-8.3,1.9-12,4l-0.2,0.1c-0.1,0-0.2,0-0.4,0l-0.4-0.2 c-3.7-2.1-7.8-3.4-12-3.9C3.7,1.1,2.4,1.6,1.4,2.4C0.5,3.3,0,4.5,0,5.8v19.3c0.1,2.3,1.7,4.2,4,4.5l0.5,0.1c4,0.7,8,2,11.6,3.8 c0.4,0.2,0.9,0.3,1.4,0.3c0.5,0,1-0.1,1.4-0.3c3.6-1.8,7.5-3.1,11.6-3.8l0.5-0.1c2.2-0.3,3.9-2.2,4-4.5V5.8 C35,4.5,34.5,3.3,33.5,2.4z M16.2,30.5c-3.6-1.7-7.4-2.9-11.4-3.5l-0.5-0.1c-0.9-0.2-1.6-0.9-1.7-1.8V5.8c0-0.5,0.2-1,0.6-1.4 c0.3-0.3,0.8-0.5,1.3-0.5c0.1,0,0.1,0,0.2,0c3.8,0.5,7.5,1.7,10.9,3.6l0.4,0.2c0.1,0,0.1,0.1,0.2,0.1V30.5z M32.3,25.1 c0,0.9-0.7,1.7-1.6,1.8L30.1,27c-3.9,0.6-7.7,1.8-11.3,3.5V7.8c0.1,0,0.2-0.1,0.2-0.1l0.3-0.2c3.3-1.9,6.9-3.1,10.7-3.6h0.2 c0.5-0.1,1.1,0.1,1.5,0.5c0.4,0.4,0.6,0.9,0.6,1.4L32.3,25.1z'
    },
    { d: 'M12,11.9c0,0.7-0.6,1.3-1.3,1.3H7c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3h3.6C11.4,10.6,12,11.1,12,11.9 C12,11.9,12,11.9,12,11.9z' },
    { d: 'M13.2,16.7c0,0.7-0.6,1.3-1.3,1.3H7c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h4.9C12.6,15.4,13.2,16,13.2,16.7 C13.2,16.7,13.2,16.7,13.2,16.7z' }
  ]
}
