<template>
  <div :class="classes">
    <component :is="titleTag" v-if="title" class="card-title"> {{ title }} </component>
    <component :is="subTitleTag" v-if="subTitle" :class="subTitleClasses" v-show="subTitle">{{ subTitle }}</component>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BCardBody',
  props: {
    bodyBgVariant: { type: String, default: '' },
    bodyBorderVariant: { type: String, default: '' },
    bodyTextVariant: { type: String, default: '' },
    overlay: { type: Boolean, default: false },
    subTitle: { type: String, default: '' },
    subTitleTextVariant: { type: String, default: '' },
    subTitleTag: { type: String, default: 'h6' },
    title: { type: String, default: '' },
    titleTag: { type: String, default: 'h4' }
  },
  computed: {
    classes() {
      return {
        'card-body': true,
        'card-img-overlay': this.overlay,
        [`bg-${this.bodyBgVariant}`]: this.bodyBgVariant,
        [`border-${this.bodyBorderVariant}`]: this.bodyBorderVariant,
        [`text-${this.bodyTextVariant}`]: this.bodyTextVariant
      }
    },
    subTitleClasses() {
      return {
        'card-subtitle': true,
        'mb-2': true,
        [`text-${this.subTitleTextVariant}`]: this.subTitleTextVariant
      }
    }
  }
}
</script>
