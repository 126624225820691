<template>
  <div class="list-group-item mb-1 pb-0" @click="openEditor" style="border-radius: 4px;">
    <b-row>
      <b-col cols="1" class="middle-center">
        <icon class="text-primary" name="wisk-rearrange" />
      </b-col>
      <b-col cols="5" style="align-items: center; display: flex;">
        <span style="font-weight: 600; font-size: 1.1em;" :class="servingSize.archived ? 'bg-warning' : ''"> {{ servingSize.title }} </span>
      </b-col>
      <b-col cols="4" class="cursor-pointer">
        <measurement :modelValue="servingSize.measurement" disabled />
      </b-col>
      <b-col cols="2" class="middle-center cursor-pointer">
        <icon class="text-primary" name="wisk-more-info" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, /* mapGetters,*/ mapState } from 'vuex'
import measurement from '@/components/bottles/Measurement'

export default {
  name: 'ServingSizeItem',
  components: { measurement },
  props: { servingSize: Object },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    openEditor() {
      this.setGlobalAction({ type: 'servingSizeEdit', action: { id: this.servingSize.id } })
    }
  },
  watch: {}
}
</script>

<style lang="scss">

</style>
