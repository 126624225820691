export default {
  apiKey: 'AIzaSyAqelXrNj0Vb2CmKJNQX7VXv4YUX4v0mzc',
  authDomain: 'wisk-dev-platform.firebaseapp.com',
  databaseURL: 'https://wisk-dev-platform-default-rtdb.firebaseio.com',
  projectId: 'wisk-dev-platform',
  storageBucket: 'wisk-dev-platform.appspot.com',
  messagingSenderId: '172615538030',
  appId: '1:172615538030:web:d9a3a2dae56a75ba385d7f',
  measurementId: 'G-1MS1CPC62K'
}
