<template>
  <b-container fluid class="inventory-pair-edit">
    <b-row v-if="initDone" class="h-100">
      <b-col md="6" cols="12" class="px-0 h-100">
        <b-card no-body class="mx-1 h-100">
          <b-card-header header-tag="header" class="pt-1 pb-0" style="min-height: 65px;">
            <b-row>
              <b-col md="5" cols="12" class="pe-0 mt-1 pe-3-m">
                <wiskSelect infoTooltipKey="204d157b-aa9c-4623-857d-98fc57ad0a3e" :label="translations.txtGenericView" v-if="previousInventoryId" :items="viewModes" v-model="viewMode"
                  class="mb-1" :multiselectOptions="{ allowEmpty: false }" />
              </b-col>
              <b-col md="4" cols="6" class="pe-0 pb-1 mt-1">
                <wiskInput infoTooltipKey="79dba1ec-0e39-48e7-a319-d96c6a2869bc" class="mb-0" inputClass="py-0" :label="translations.txtGenericTypeToSearch" v-model="itemsSearchQuery">
                  <b-button title="Clear" variant="link" @click="itemsSearchQuery = ''" v-show="itemsSearchQuery" style="top: -31px; right: 12px; position: absolute;">
                    <icon class="text-primary" :scale="0.8" name="wisk-exit"></icon>
                  </b-button>
                </wiskInput>
              </b-col>
              <b-col md="3" cols="6" class="ps-1 pb-0">
                <b-button class="float-end mt-2" variant="outline-primary" size="sm" @click="openCreateItemDialog">
                  {{ translations.txtVenueBottlesCreate }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="viewMode === 'previousInventory' || viewMode === 'previousInventoryWithMovements'">
              <b-col>
              </b-col>
              <b-col v-if="locationId && !currentInventoryMeasurements.length">
                <b-button class="text-start p-0" variant="link" @click.stop="submitAllFromPreviousLocation">
                  <span class="">
                    {{ translations.txtInventoriesSubmitAll }}
                  </span>
                  <icon style="" :scale="0.6" name="wisk-angle-double-right"></icon>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body v-if="viewMode === 'items'" class="p-2 fill-pair-space" style="z-index: 0;">
            <b-accordion :items="itemsByCategory" headerKey="title" v-model="categoryAccordion">
              <template v-slot="category">
                <div v-if="category.items.length">
                  <itemViewMini v-for="item in category.items" :key="item.item_distributor_id" :item="item">
                    <inventoryItemMeasurement class="" @submit="addItem(item)" v-model="itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id]" emitFast
                      @itemFieldChange="onItemFieldChange" :variantId="item.item_distributor_id" showOpenEditButton @open="openSelectedEntriesDialog({ measurement: { item_id: item.item_id } })">

                      <b-badge v-if="submittedTracker[item.item_distributor_id]" style="position: absolute; bottom: 0px; left: 10px; opacity: 0.8;" class="text-white" variant="success">
                        {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[item.item_distributor_id] }}
                      </b-badge>
                    </inventoryItemMeasurement>
                  </itemViewMini>
                </div>
                <div v-else>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </div>
              </template>
            </b-accordion>
          </b-card-body>
          <b-card-body class="p-2 fill-pair-space"
            v-if="(viewMode === 'previousInventory' || viewMode === 'previousInventoryWithMovements' || viewMode === 'previousInventoryHidden') && previousInventoryMeasurements && previousInventoryId && locationId">
            <div v-if="previousInventoryMeasurements.length">
              <itemViewMini v-for="entry in previousInventoryMeasurements" :key="entry.id" :variantId="entry.measurement.item_distributor_id">
                <inventoryItemMeasurement @submit="addEntry(previousInventoryMeasurementsByEntryId[entry.id])" v-model="previousInventoryMeasurementsByEntryId[entry.id]" hideTypeSelector
                  showHideToggleButton :entryHidden="hiddenTrackerByPreviousEntryId[entry.measurement.order]" @toggleHidden="toggleHidden(entry)" :variantId="entry.measurement.item_distributor_id"
                  @itemFieldChange="onItemFieldChange" showOpenEditButton @open="openSelectedEntriesDialog(previousInventoryMeasurementsByEntryId[entry.id])">
                  <b-badge v-if="submittedTracker[entry.measurement.item_distributor_id]" style="position: absolute; bottom: 0px; left: -100%; opacity: 0.8;" class="text-white" variant="success">
                    {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[entry.measurement.item_distributor_id] }}
                  </b-badge>
                </inventoryItemMeasurement>
              </itemViewMini>
            </div>
            <div v-else>
              <wiskLoading loading :timeout="2200" style="position: relative;">
                <template v-slot:display-after-timeout>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </template>
              </wiskLoading>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" cols="12" class="px-0 h-100">
        <b-card no-body class="mx-1 h-100">
          <b-card-header header-tag="header" class="pt-2 pb-1" style="min-height: 65px;">
            <b-row v-if="locationId">
              <b-col>
                <h5>{{ translations.txtGenericSummary }}</h5>
              </b-col>
              <b-col md="4" cols="6" class="pe-0 pb-1">
                <wiskInput infoTooltipKey="589cb78f-dd04-474e-bf84-eafe1aaf5a8c" class="mt-1 mb-0" inputClass="py-0" :label="translations.txtGenericTypeToSearch" v-model="currentItemsSearchQuery">
                  <b-button title="Clear" variant="link" @click="currentItemsSearchQuery = ''" v-show="currentItemsSearchQuery" style="top: -31px; right: 12px; position: absolute; z-index: 1;">
                    <icon class="text-primary" :scale="0.8" name="wisk-exit"></icon>
                  </b-button>
                </wiskInput>
              </b-col>
              <b-col>
                <b-button variant="secondary" size="sm" @click="openSelectedEntriesDialog({ measurement: { item_id: null } })"
                  v-tooltip="translations.txtInventoriesSearchAddQuick" class="float-end mt-2 me-3">
                  <icon class=" me-2" :scale="0.8" name="wisk-zoom-in"></icon>
                  <span> {{ translations.txtInventoriesEntryAdd }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>

          <b-card-body class="p-2 fill-pair-space" v-if="currentVisibleInventoryMeasurements">
            <div v-show="currentVisibleInventoryMeasurements.length">

              <itemViewMini :variantId="entry.measurement.item_distributor_id" v-for="entry in currentVisibleInventoryMeasurements" :key="entry.id">
                <b-row>
                  <b-col cols="10" class="px-0-m">
                    <b-row>
                      <b-col>
                        <inventoryItemMeasurement @update:modelValue="updateEntryDebounced" hideTypeSelector :modelValue="entry" :variantId="entry.measurement.item_distributor_id" hideButtons />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="px-0-m position-relative">
                    <b-button variant="link" @click="removeEntry(entry)" v-tooltip="translations.txtGenericRemove"
                      class="text-primary text-danger-hover px-0-m" style="position: absolute; right: 5px; top: calc(50% - 35px);">
                      <icon class="" scale="1" name="wisk-exit"></icon>
                    </b-button>
                    <b-button size="sm" variant="link" @click="openSelectedEntriesDialog(entry)" class="me-1 pe-0-m" style="position: absolute; right: 5px; top: calc(70% - 15px);">
                      <icon class="" style="margin-top: -1px;" scale="0.8" name="wisk-more-info"></icon>
                    </b-button>
                  </b-col>
                </b-row>
              </itemViewMini>
            </div>
            <div v-if="!currentVisibleInventoryMeasurements.length" style="height: 150px;" class="fill-width-height">
              <wiskLoading loading :timeout="1000">
                <template v-slot:display-after-timeout>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </template>
              </wiskLoading>
            </div>
            <b-button class="d-block w-100 py-3" variant="link" size="sm" @click="openSelectedEntriesDialog({ measurement: { item_id: null } })"
              v-tooltip="translations.txtInventoriesSearchAddQuick" id="bt-add-item-quick-scroll-target">
              <icon class="text-primary" :scale="1.3" name="wisk-zoom-in"></icon>
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <wiskLoading loading />
    </b-row>

    <inventoryItemMeasurementsDialog :visible="inventoryItemMeasurementsDialogVisible" @visible="inventoryItemMeasurementsDialogVisible = $event" @itemSelected="openSelectedEntriesDialog({ measurement: { item_id: $event } })"
      :inventoryEntries="selectedInventoryEntries" :itemId="selectedItemId" :submittedTracker="submittedTracker" :inventoryId="inventoryId" :locationId="locationId"
      @itemFieldChange="onItemFieldChange" @edit="updateEntry" @add="addEntry($event, 'search')" @remove="removeEntry" :key="inventoryItemMeasurementsDialogKey" />

    <wiskModal :visible="!!selectedInventoryEntry" @hide="selectedInventoryEntry = null" size="md" hideFooter :title="translations.txtInventoriesEntryEdit">
      <div style="height: 200px;">
        <itemViewMini v-if="selectedInventoryEntry" :variantId="selectedInventoryEntry.measurement.item_distributor_id">
          <inventoryItemMeasurement @submit="editSelectedEntry" v-model="selectedInventoryEntry" class="mt-2" :variantId="selectedInventoryEntry.measurement.item_distributor_id" @itemFieldChange="onItemFieldChange">
            <b-badge v-if="submittedTracker[selectedInventoryEntry.measurement.item_distributor_id]" style="position: absolute; bottom: 0px; left: -100%; opacity: 0.8;" class="text-white" variant="success">
              {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[selectedInventoryEntry.measurement.item_distributor_id] }}
            </b-badge>
          </inventoryItemMeasurement>
        </itemViewMini>
      </div>
    </wiskModal>

  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import get from 'lodash.get'
import { arrayToObjectById, stringFilter, getRandom, round, prepareVariantAsItem, prepareVariantIdAsItem, getStringForSearchRecursive } from '@/modules/utils'
import itemViewMini from '@/components/bottles/ItemViewMini'
import inventoryItemMeasurement from '@/components/inventories/InventoryItemMeasurement'
import inventoryItemMeasurementsDialog from '@/components/inventories/InventoryItemMeasurementsDialog'

let getId = () => new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)

export default {
  name: 'IndependentInventoryPair',
  emits: ['operation', 'itemOperation'],
  components: { itemViewMini, inventoryItemMeasurement, inventoryItemMeasurementsDialog },
  props: { inventoryId: Number, locationId: Number, inventoryData: Object, entries: Array },
  data() {
    return {
      selectedInventoryEntry: null,
      selectedInventoryEntries: [],
      selectedItemId: null,
      inventoryItemMeasurementsDialogVisible: false,
      initDone: false,
      initDoneCurrent: false,
      initDonePrevious: false,
      submittedTracker: {},
      inventoryItemMeasurementsDialogKey: 1,
      itemsSearchQuery: '',
      currentItemsSearchQuery: '',
      currentItemsSearchTimeoutId: null,
      prepareMeasurementsTimeoutId: null,
      loadPreviousMeasurementsTimeoutId: null,
      updateEntryDebounceId: null,
      viewMode: 'previousInventory',
      unsubscribers: [],
      itemsByCategory: [],
      itemsByCategoryMeasurementsByItemVariantId: {},
      previousInventoryMeasurements: [],
      previousInventoryMeasurementsByEntryId: {},
      currentInventoryMeasurements: [],
      currentVisibleInventoryMeasurements: [],
      submittedTrackerByPreviousEntryId: {},
      hiddenTrackerByPreviousEntryId: {},
      categoryAccordion: '',
      items: []
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'families', 'locations', 'itemVariants', 'categories', 'currentPermissionsByType', 'locationsById', 'user']),
    ...mapGetters(['venue', 'currency']),
    previousInventoryId() {
      return get(this.inventoryData, 'previous.id')
    },
    viewModes() {
      return [
        { id: 'previousInventory', title: 'Previous inventory' },
        { id: 'previousInventoryWithMovements', title: 'Previous inventory + movements' },
        { id: 'previousInventoryHidden', title: 'Hidden items from previous inventory' },
        { id: 'items', title: 'Search All Venue Items' }
      ]
    },
    filteredItems() {
      if (this.itemsSearchQuery) {
        return this.items.filter(item => stringFilter('contains', getStringForSearchRecursive({ payload: item }), this.itemsSearchQuery))
      }
      return this.items
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setItemToState']),
    onItemFieldChange({ itemId, operation, updatedItem }) {
      this.$emit('itemOperation', { itemId, operation })

      if (updatedItem && updatedItem.item_id) {
        this.setItemToState(updatedItem)
      }
    },
    toggleHidden(entry) {
      this.hiddenTrackerByPreviousEntryId[entry.measurement.order /* this "order" word should be id actually*/] = !this.hiddenTrackerByPreviousEntryId[entry.measurement.order]
      this.hiddenTrackerByPreviousEntryId = { ...this.hiddenTrackerByPreviousEntryId }
    },
    addItem(item) {
      let measurement = this.itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id].measurement

      let id = getId(),
        entry = {
          location_id: this.locationId,
          inventory_order: id,
          measurement: {
            ...measurement,
            timestamp: new Date().toISOString(),
            item_distributor_id: item.item_distributor_id,
            item_id: item.item_id,
            order: id,
            item_distributor_measurement: item.measurement,
            user_id: this.user.id
          },
          venue_id: this.venue.id,
          inventory_id: this.inventoryId,
          user_id: this.user.id
        }

      this.addEntry(entry, 'search')

      this.mapItemsToCategories()
    },
    openCreateItemDialog() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          creationSource: { type: 'inventory', id: this.inventoryId },
          onChange: item => {
            let id = getId()
            this.selectedInventoryEntry = {
              item,
              measurement: {
                timestamp: new Date().toISOString(),
                item_id: item.item_id,
                item_distributor_id: item.item_distributor_ids[0],
                measurement: { type: 'unit', quantity: 0 },
                order: id,
                item_distributor_measurement: item.measurement,
                user_id: this.user.id
              },
              id,
              inventory_order: id,
              inventory_id: this.inventoryId,
              location_id: this.locationId,
              venue_id: this.venue.id,
              user_id: this.user.id
            }
          }
        }
      })
    },
    openSelectedEntriesDialog(entry) {
      if (this.currentInventoryMeasurements) {
        let found = this.currentInventoryMeasurements.filter(e => e.measurement.item_id === entry.measurement.item_id)

        this.selectedItemId = entry.measurement.item_id
        this.selectedInventoryEntries = found.map(e => ({ ...e }))

        this.inventoryItemMeasurementsDialogVisible = true
      }
    },
    editSelectedEntry() {
      this.addEntry(this.selectedInventoryEntry)
      setTimeout(() => {
        this.selectedInventoryEntry = null
      }, 0)
    },
    addEntry(entry, inputSource = 'previous_inventory') {
      if (
        entry &&
        entry.measurement &&
        entry.measurement.measurement &&
        (entry.measurement.measurement.quantity || entry.measurement.measurement.percentage_remaining || entry.measurement.measurement.ml_left)
      ) {
        delete entry.measurement.measurement.value
        delete entry.item

        let item = prepareVariantIdAsItem(entry.measurement.item_distributor_id, this.$store.state) || {}
        entry.measurement.measurement.case_size = item.case_size

        let order = getId(),
          measurement = { ...entry.measurement, case_size: item.case_size, order, measurement_id: order, previous_inventory_order: entry.measurement.order }

        let operation = {
          type: 'item_add',
          value: {
            item_id: entry.measurement.item_id,
            item_distributor_id: entry.measurement.item_distributor_id,
            measurement_id: order,
            order,
            item_distributor_measurement: item.measurement,
            user_id: this.user.id,
            input_source: {
              type: inputSource
            },
            previous_inventory_order: entry.measurement.order,
            timestamp: new Date().toISOString(),
            hidden: false,
            ...measurement
          }
        },
          newEntry = {
            ...entry,
            measurement,
            inventory_id: this.inventoryId,
            id: order,
            flash: true
          }

        this.$emit('operation', operation)

        setTimeout(() => {
          if (this.inventoryItemMeasurementsDialogVisible) {
            this.openSelectedEntriesDialog(newEntry)
          }
        }, 3000)
      }
    },
    updateEntryDebounced(entry) {
      clearTimeout(this.updateEntryDebounceId)
      this.updateEntryDebounceId = setTimeout(() => {
        this.updateEntry(entry)
      }, 1000)
    },
    updateEntry(entry) {
      console.log('-+-+-+-+-+-+-+-+-+-+-+ updateEntry', entry)

      if (
        entry &&
        entry.measurement &&
        entry.measurement.measurement &&
        (entry.measurement.measurement.quantity || entry.measurement.measurement.percentage_remaining || entry.measurement.measurement.ml_left)
      ) {
        delete entry.measurement.measurement.value
        delete entry.units_computed

        let operation = {
          type: 'item_update',
          value: {
            item_id: entry.measurement.item_id,
            item_distributor_id: entry.measurement.item_distributor_id || null,
            order: entry.measurement.order,
            measurement_id: entry.id,
            item_measurement: entry.measurement.item_measurement,
            item_distributor_measurement: entry.measurement.item_distributor_measurement || null,
            user_id: this.user.id,
            input_source: {
              type: 'previous_inventory'
            },
            previous_inventory_order: entry.measurement.previous_inventory_order || null,
            timestamp: new Date().toISOString(),
            hidden: false,
            measurement: { ...entry.measurement.measurement }
          }
        }

        this.$emit('operation', operation)
      }
    },
    removeEntry(entry) {
      let operation = {
        type: 'item_delete',
        value: {
          order: entry.measurement.order,
          id: entry.measurement.item_id, //TODO: do not use id for item, correct is item_id
          item_id: entry.measurement.item_id,
          item_distributor_id: entry.measurement.item_distributor_id || null
        }
      }

      this.$emit('operation', operation)
    },
    submitAllFromPreviousLocation() {
      this.previousInventoryMeasurements.forEach(entry => {
        this.submittedTrackerByPreviousEntryId[entry.measurement.inventory_order] = true
      })
      this.currentInventoryMeasurements = [...this.previousInventoryMeasurements]
      this.$emit('operation', { type: this.venue.transfers ? 'submit_previous_with_movements' : 'submit_previous' })
    },
    prepareMeasurementsDebounced() {
      console.log('prepareMeasurementsDebounced')
      clearTimeout(this.prepareMeasurementsTimeoutId)
      this.prepareMeasurementsTimeoutId = setTimeout(this.prepareMeasurements, 200)
    },
    prepareMeasurements() {
      this.submittedTrackerByPreviousEntryId = {}
      this.submittedTracker = {}

      if (Array.isArray(this.entries)) {
        this.currentInventoryMeasurements = []

        this.entries.forEach(entry => {
          if (entry.inventory_id === this.inventoryId) {
            let variantId = get(entry, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(entry, 'measurement.item_id'), 'prepareMeasurements 467'))

            this.submittedTrackerByPreviousEntryId[entry.measurement.previous_inventory_order] = true

            this.submittedTracker[variantId] = this.submittedTracker[variantId] || 0
            this.submittedTracker[variantId] += get(entry, 'units_computed', 0)
            this.submittedTracker[variantId] = round(this.submittedTracker[variantId], 2)

            this.currentInventoryMeasurements.push({ ...entry, measurement: { ...entry.measurement, item_distributor_id: variantId } })
          }
        })
        this.currentVisibleInventoryMeasurements = this.currentInventoryMeasurements

        this.initDoneCurrent = true
        this.initDone = this.initDonePrevious && this.initDoneCurrent

        this.submittedTracker = { ...this.submittedTracker }
        this.submittedTrackerByPreviousEntryId = { ...this.submittedTrackerByPreviousEntryId }
      }
    },
    mapVariantAsItemsToCategories() {
      this.items = this.itemVariants.filter(v => v && (v.inventoriable || v.inventoriable_as_cases)).map(v => ({ ...prepareVariantAsItem(v, this.$store.state) }))
      this.mapItemsToCategories()
    },
    mapItemsToCategories(openFirstCategory = false) {
      console.log('mapItemsToCategories', openFirstCategory)
      if (this.viewMode === 'items') {
        let itemsByCategory = [],
          itemsByCategoryMeasurementsByItemVariantId = {},
          firstCategory = 0
        this.families
          .filter(f => !f.archived)
          .forEach(f => {
            let categories = this.categories.filter(c => !c.archived && c.family_id === f.id)

            categories.forEach(c => {
              let items = this.filteredItems.filter(b => !b.archived && !b.grouped_to && b.category_id === c.id).map(b => ({ ...b }))
              if (items.length) {
                itemsByCategory.push({
                  id: c.id,
                  title: `${f.title} > ${c.title}`,
                  items
                })

                items.forEach(item => {
                  itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id] = { measurement: { measurement: { type: 'unit', quantity: 0, ml_left: 0 } } }
                })

                if (!firstCategory) {
                  firstCategory = c.id
                }
              }
            })
          })

        this.itemsByCategory = itemsByCategory
        this.itemsByCategoryMeasurementsByItemVariantId = itemsByCategoryMeasurementsByItemVariantId
      }

      if (openFirstCategory) {
        this.categoryAccordion = 0
      }
    },
    getVariantIdFromItemId(itemId, caller) {
      console.log('getVariantIdFromItemId', itemId, caller)
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    loadPreviousMeasurementsDebounced(caller) {
      console.log('loadPreviousMeasurementsDebounced', caller)
      clearTimeout(this.loadPreviousMeasurementsTimeoutId)
      this.loadPreviousMeasurementsTimeoutId = setTimeout(() => {
        this.loadPreviousMeasurements(caller)
      }, 2000)
    },
    loadPreviousMeasurements(caller) {
      console.log('loadPreviousMeasurements', caller)
      let map = { previousInventoryWithMovements: 'measurementsWithMovements', previousInventory: 'measurements' },
        previousInventoryMeasurementsByLocationId = {}

      if (this.inventoryData) {
        if (this.inventoryData.previous && this.inventoryData.previous.locations) {
          previousInventoryMeasurementsByLocationId.measurements = {}
          this.inventoryData.previous.locations.forEach(location => {
            previousInventoryMeasurementsByLocationId.measurements[location.location.id] = location.measurements.map(entry => ({
              inventory_order: entry.order,
              measurement: entry,
              id: getRandom(),
              inventory_id: this.previousInventoryId,
              location_id: location.location.id
            }))
          })
        }
        if (this.inventoryData.previous_with_movements && this.inventoryData.previous_with_movements.locations) {
          previousInventoryMeasurementsByLocationId.measurementsWithMovements = {}
          this.inventoryData.previous_with_movements.locations.forEach(location => {
            previousInventoryMeasurementsByLocationId.measurementsWithMovements[location.location.id] = location.measurements.map(entry => ({
              inventory_order: entry.order,
              measurement: entry,
              id: getRandom(),
              inventory_id: this.previousInventoryId,
              location_id: location.location.id
            }))
          })
        }
      }

      if (previousInventoryMeasurementsByLocationId && this.locationId && (this.viewMode === 'previousInventory' || this.viewMode === 'previousInventoryWithMovements')) {
        this.previousInventoryMeasurements = get(previousInventoryMeasurementsByLocationId, `${map[this.viewMode]}.${this.locationId}`, [])
          .map(m => {
            let variantId = get(m, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(m, 'measurement.item_id'), 'loadPreviousMeasurements 586'))

            return {
              ...m,
              measurement: { ...m.measurement, item_distributor_id: variantId },
              item: prepareVariantIdAsItem(variantId, this.$store.state) || {}
            }
          })
          .filter(
            entry =>
              !this.hiddenTrackerByPreviousEntryId[entry.measurement.order] &&
              !this.submittedTrackerByPreviousEntryId[entry.measurement.order] &&
              stringFilter('contains', getStringForSearchRecursive({ payload: entry.item }), this.itemsSearchQuery)
          )

        this.previousInventoryMeasurementsByEntryId = arrayToObjectById(this.previousInventoryMeasurements)
      }

      if (this.viewMode === 'previousInventoryHidden') {
        this.previousInventoryMeasurements = get(previousInventoryMeasurementsByLocationId, `measurements.${this.locationId}`, [])
          .map(m => {
            let variantId = get(m, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(m, 'measurement.item_id'), 'loadPreviousMeasurements 607'))

            return {
              ...m,
              measurement: { ...m.measurement, item_distributor_id: variantId },
              item: prepareVariantIdAsItem(variantId, this.$store.state) || {}
            }
          })
          .filter(entry => this.hiddenTrackerByPreviousEntryId[entry.measurement.order] && stringFilter('contains', getStringForSearchRecursive({ payload: entry.item }), this.itemsSearchQuery))
        this.previousInventoryMeasurementsByEntryId = arrayToObjectById(this.previousInventoryMeasurements)
      }

      this.initDonePrevious = true
      this.initDone = this.initDonePrevious && this.initDoneCurrent
    }
  },
  beforeUnmount() {
    this.unsubscribers.forEach(z => {
      if (typeof z === 'function') {
        z()
      }
    })
  },
  watch: {
    inventoryItemMeasurementsDialogVisible(inventoryItemMeasurementsDialogVisible) {
      if (!inventoryItemMeasurementsDialogVisible) {
        this.selectedInventoryEntries = []
        this.selectedItemId = null
      }
    },
    inventoryData: {
      handler() {
        console.log('this.inventoryData', this.inventoryData)
        if (this.inventoryData) {
          if (this.inventoryData.previous) {
            this.loadPreviousMeasurementsDebounced('inventoryData')
          } else {
            this.viewMode = 'items'
          }
        }
      },
      immediate: true
    },
    submittedTrackerByPreviousEntryId() {
      this.loadPreviousMeasurementsDebounced('submittedTrackerByPreviousEntryId')
    },
    hiddenTrackerByPreviousEntryId() {
      this.loadPreviousMeasurementsDebounced('hiddenTrackerByPreviousEntryId')
    },
    entries: 'prepareMeasurementsDebounced',
    locationId: {
      immediate: true,
      handler(locationId) {
        //this.initDone = false
        console.log('locationId', locationId)
        if (locationId) {
          this.loadPreviousMeasurementsDebounced('locationId')
          this.prepareMeasurementsDebounced()
        } else {
          this.previousInventoryMeasurements = []
          this.currentInventoryMeasurements = []

          this.unsubscribers.forEach(z => {
            if (typeof z === 'function') {
              z()
            }
          })
        }
      }
    },
    viewMode() {
      this.mapItemsToCategories(true)
      this.loadPreviousMeasurementsDebounced('viewMode')
    },
    families: {
      immediate: true,
      handler() {
        this.mapItemsToCategories()
      }
    },
    bottlesById: {
      immediate: true,
      handler: 'mapVariantAsItemsToCategories'
    },
    itemVariants: {
      immediate: false,
      handler: 'mapVariantAsItemsToCategories'
    },
    itemsSearchQuery() {
      this.mapItemsToCategories(true)
      this.loadPreviousMeasurementsDebounced('itemsSearchQuery')
    },
    currentItemsSearchQuery: {
      handler() {
        clearTimeout(this.currentItemsSearchTimeoutId)
        this.currentItemsSearchTimeoutId = setTimeout(() => {
          this.currentVisibleInventoryMeasurements = this.currentInventoryMeasurements.filter(entry => {
            let item = this.bottlesById[entry.measurement.item_id]
            //console.log(entry.measurement.item_id, item)
            if (!item) {
              console.log('this.bottlesById does not contain this item_id', entry.measurement.item_id, (this.bottlesById))
            }
            if (item) {
              return stringFilter('contains', getStringForSearchRecursive({ payload: item }), this.currentItemsSearchQuery)
            }
            return false
          })
        }, 400)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.inventory-pair-edit {
  .fill-pair-space {
    height: 500px;
    overflow: auto;
  }
}
</style>
