<template>
  <component :is="tag" :class="classList" :style="styles">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BRow',
  props: {
    cols: { type: [Number, String] },
    noGutters: { type: Boolean, default: false },
    gap: { type: [Number, String] },
    tag: { type: String, default: 'div' }
  },
  computed: {
    classList() {
      const classList = this.gap ? ['d-flex', 'flex-wrap'] : ['row']
      if (this.noGutters) classList.push('no-gutters g-0')
      return classList
    },
    styles() {
      if (this.gap) {
        return { gap: typeof this.gap === 'number' ? `${this.gap}rem` : this.gap }
      }
      return null
    }
  }
}
</script>
