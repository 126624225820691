export default {
  name: 'wisk-expand',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 33.583984 0.033203125 L 24.464844 0.03515625 A 1.4159 1.4159 0 0 0 23.458984 2.4472656 A 1.4115 1.4115 0 0 0 24.464844 2.8671875 L 32.154297 2.8652344 L 32.082031 10.554688 A 1.4161 1.4161 0 0 0 34.914062 10.582031 L 35 1.4628906 A 1.4588 1.4588 0 0 0 33.583984 0.033203125 z M 1.4238281 0.04296875 A 1.4586 1.4586 0 0 0 0 1.4648438 L 0.044921875 10.585938 A 1.4160166 1.4160166 0 1 0 2.8769531 10.572266 L 2.8378906 2.8828125 L 10.529297 2.9199219 A 1.4114 1.4114 0 0 0 11.537109 2.5039062 A 1.4159 1.4159 0 0 0 10.542969 0.087890625 L 1.4238281 0.04296875 z M 33.570312 22.996094 A 1.4161 1.4161 0 0 0 32.101562 24.398438 L 32.103516 32.089844 L 24.414062 32.017578 A 1.4118 1.4118 0 0 0 23.404297 32.427734 A 1.4159 1.4159 0 0 0 24.386719 34.847656 L 33.505859 34.933594 A 1.4586 1.4586 0 0 0 34.935547 33.519531 L 34.933594 24.398438 A 1.4161 1.4161 0 0 0 33.570312 22.996094 z M 1.4257812 23.015625 A 1.4160166 1.4160166 0 0 0 0.044921875 24.423828 L 0 33.544922 A 1.4587 1.4587 0 0 0 1.4238281 34.966797 L 10.542969 34.921875 A 1.4159 1.4159 0 0 0 11.537109 32.505859 A 1.4124 1.4124 0 0 0 10.529297 32.091797 L 2.8398438 32.128906 L 2.8769531 24.439453 A 1.4160166 1.4160166 0 0 0 1.4257812 23.015625 z '
    }
  ]
}
