import cloneDeep from 'lodash.clonedeep'
import { createStore } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const debug = 0,
  loggerPlugin = store => {
    store.subscribe(mutation => {
      console.log('Mutation ', mutation.type, mutation.payload)
    })
  },
  configs = {
    state: cloneDeep(state),
    getters,
    mutations,
    actions,
    modules: {}
  }

if (debug) {
  configs.plugins = [loggerPlugin]
}

const store = createStore(configs)

export default store
