import get from 'lodash.get'
import agGridFilters from '@/modules/agGridFilters'
import { getPOSItemIngredientDescription, currencyFormatHideZero, setupCustomFieldsAsColumns } from '@/modules/utils'
import cellRenderers from '@/components/cells/cellRenderers'

const getGridColumns = ({ translations, openEditSubrecipe, setGlobalAction, save, customFields, allergens, currentPermissionsByType }) => {
  let translate = translations.translate,
    columns = [
      {
        colId: 'dropdownMenu',
        sortOrder: 100,
        cellRenderer: 'CellMenu',
        headerName: '',
        headerClass: ['ps-2', 'pt-1'],
        maxWidth: 25,
        width: 25,
        remove: !currentPermissionsByType.batch_manage,
        minWidth: 25,
        cellClass: ['menu-cell'],
        cellRendererParams: { excludeFromExport: true },
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        valueGetter: params => ({
          group: params.node.group,
          items: [
            {
              label: translations.txtSubrecipesEdit,
              onClick: openEditSubrecipe,
              value: params.data && params.data.id
            },
            {
              label: translations.txtGenericArchive,
              cssClass: 'text-danger',
              value: [params.data && params.data.id],
              onClick: () => {
                save({ value: true, id: params.data.id, type: 'archive' })
              },
              hide: params.data && params.data.archived
            },
            {
              label: translations.txtGenericRestore,
              value: [params.data && params.data.id],
              cssClass: 'text-success',
              onClick: () => {
                save({ value: false, id: params.data.id, type: 'archive' })
              },
              hide: !params.data || !params.data.archived
            }
          ]
        })
      },
      {
        colId: 'image',
        sortOrder: 300,
        field: 'image',
        headerName: translations.txtGenericImage,
        headerClass: ['hide-header-label'],
        hide: true,
        cellRenderer: 'CellImage',
        maxWidth: 50,
        width: 50,
        minWidth: 50,
        sortable: false,
        cellRendererParams: {
          excludeFromExport: true,
          overlayIcon: 'wisk-edit',
          filterDisplay: 'tinyThumb',
          onClick: data => {
            if (!data.wiskRowHidden) {
              setGlobalAction({
                type: 'fileUpload',
                action: {
                  target: data,
                  useGoogle: true,
                  fileTypesAccept: 'image/*',
                  save: value => {
                    save({ value, id: data.id, type: 'image', previousValue: data.image })
                  }
                }
              })
            }
          }
        },
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: ['image-cell'],
        valueGetter: params => ({
          image: (params.data && params.data.image) || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'
        })
      },
      {
        headerName: translations.txtGenericTitle,
        cellRenderer: 'CellText',
        colId: 'title',
        minWidth: 50,
        width: 150,
        sortOrder: 400,
        maxWidth: 300,
        sort: 'asc',
        cellRendererParams: {
          translate,
          readonly: true,
          type: 'title'
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          input_value: params.data && params.data.title
        })
      },
      {
        headerName: translations.txtGenericIngredients,
        colId: 'more',
        minWidth: 150,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        maxWidth: 500,
        cellRenderer: 'CellIngredients',
        cellClass: ['pt-1'],
        sortOrder: 500,
        sortable: false,
        cellRendererParams: {
          useValueFormatter: true,
          onClick: openEditSubrecipe,
          translate
        },
        valueFormatter: params => params.value && params.value.ingredients && params.value.ingredients.map(i => getPOSItemIngredientDescription(i)).join(', \n'),
        valueGetter: params => ({
          id: params && params.data && params.data.id,
          ingredients: params && params.data && params.data.ingredients
        })
      },
      {
        colId: 'allergens',
        sortOrder: 1020,
        headerName: translations.txtAllergens,
        cellRenderer: cellRenderers.CellPopMultiselect,
        valueSetter: params => {
          params.data.allergens = params.newValue

          return true
        },
        editable: false,
        minWidth: 100,
        width: 100,
        remove: !allergens,
        hide: true,
        cellClass: ['text-center', 'stringTypeCellStyle'],
        keyCreator: params => get(params, 'value.input_value', []).join(', '),
        cellEditorParams: {
          autoOpen: true,
          translate,
          multiple: true,
          useValueFormatter: true,
          readonly: true,
          multiselectWidth: 300,
          multiselectOptions: { trackBy: 'id' },
          getItems: () => allergens
        },
        valueFormatter: params => params.value.input_value.map(id => (allergens.find(a => a.id === id) || { title: 'Not found' }).title).join(', '),
        valueGetter: params => ({
          id: get(params, 'data.id'),
          input_value: (params.data && params.data.allergens) || []
        })
      },
      {
        sortOrder: 1500,
        colId: 'cost',
        width: 120,
        headerName: translations.txtGenericCost,
        cellRendererParams: { decimals: 2 },
        cellClass: ['text-end', 'pt-1', 'pe-2', 'currency'],
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => get(params, 'data.stats.cost.dollars', 0)
      },
      {
        colId: 'measurement',
        sortOrder: 400,
        headerName: translations.txtGenericYields,
        cellRenderer: 'CellText',
        ...agGridFilters.text,
        minWidth: 190,
        maxWidth: 400,
        sort: 'asc',
        enableRowGroup: true,
        keyCreator: params => params.value.input_value,
        cellRendererParams: {
          translate,
          readonly: true
        },
        valueGetter: params => {
          let formatted = `${get(params, 'data.yields.measurement.quantity')} ${get(params, 'data.yields.measurement.unit_of_measurement')}`
          return {
            input_value: formatted,
            item: params.data
          }
        }
      },
      {
        headerName: translations.txtSubrecipesAsItem,
        colId: 'subrecipesAsItem',
        sortOrder: 500,
        enableRowGroup: true,
        cellRenderer: 'CellCheckbox',
        cellClass: ['pt-2'],
        minWidth: 50,
        maxWidth: 150,
        keyCreator: params => params.value.input_value,
        cellRendererParams: {
          translate: translations.translate,
          updateValue: save,
          readonly: !currentPermissionsByType.batch_manage,
          type: 'used_as_inventory_item'
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          input_value: params.data && params.data.used_as_inventory_item
        })
      }
    ]

  let customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate, save, agGridFilters, columns })

  return [...columns, ...customFieldsColumns]
}

const getItemsGridColumns = ({ translations, servingSizes, save, disabled, openCustomServingSize, allergens }) => ({
  dropdownMenu: {
    forceOverrideValuegetter: true,
    remove: disabled,
    cellRendererParams: { excludeFromExport: true },
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: item => {
            if (item) {
              save({
                type: 'ingredient_delete',
                value: {
                  ingredient: item.operation
                }
              })
            }
          },
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  title: {
    hide: false,
    width: 400,
    sort: null
  },
  image: {
    cellRendererParams: { excludeFromExport: true },
    hide: false
  },
  servingSize: {
    cellRenderer: 'CellPopMultiselect',
    colId: 'servingSize',
    sortOrder: 700,
    width: 120,
    maxWidth: 160,
    headerName: translations.txtPOSItemsServingSize,
    cellRendererParams: {
      translate: translations.translate,
      updateValue: params => {
        let found = servingSizes.find(s => s && s.id === params.value)

        if (found.type === 'custom') {
          openCustomServingSize(params.id)
        } else {
          save({ ...params, value: { ingredient: params.data.data.operation, serving_size: { ...found } } })
        }
      },
      required: true,
      multiselectWidth: 280,
      forceInfoComponent: true,
      readonly: disabled,
      type: 'ingredient_update',
      extraButton: {
        id: 1,
        getVisible: data => get(data, 'serving_size.type') === 'custom' && !disabled,
        action: id => {
          openCustomServingSize(id)
        },
        icon: 'wisk-edit'
      }
    },
    valueGetter: params => {
      let type = get(params, 'data.measurement.type', 'volume'),
        extraText = `${get(params, 'data.serving_size.measurement.quantity')} ${get(params, 'data.serving_size.measurement.unit_of_measurement')}`

      return {
        id: params.data && params.data.id,
        input_value: get(params, 'data.serving_size.id') ? params.data.serving_size : servingSizes.find(s => s && s.type === 'custom'),
        items: servingSizes.filter(d => !d.archived && (d?.measurement?.type === type || d?.measurement?.type === 'unit')),
        data: get(params, 'data'),
        extraText
      }
    }
  },
  cost: {
    sortOrder: 1500,
    colId: 'cost',
    width: 120,
    headerName: translations.txtGenericCost,
    cellRendererParams: { decimals: 2 },
    cellClass: ['text-end', 'pt-2', 'pe-2', 'currency'],
    valueFormatter: params => currencyFormatHideZero(params.value),
    valueGetter: params => get(params, 'data.dollars', 0)
  },
  allergens: {
    colId: 'allergens',
    sortOrder: 1600,
    headerName: translations.txtAllergens,
    cellRenderer: cellRenderers.CellPopMultiselect,
    valueSetter: params => {
      params.data.allergens = params.newValue

      return true
    },
    editable: false,
    minWidth: 100,
    remove: !allergens,
    width: 100,
    cellClass: ['text-center', 'stringTypeCellStyle'],
    keyCreator: params => get(params, 'value.input_value', []).join(', '),
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      multiple: true,
      readonly: true,
      allowAddNewItem: false,
      useValueFormatter: true,
      multiselectWidth: 300,
      multiselectOptions: { trackBy: 'id' },
      getItems: () => allergens
    },
    valueFormatter: params => params.value.input_value.map(id => (allergens.find(a => a.id === id) || { title: 'Not found' }).title).join(', '),
    valueGetter: params => ({
      id: get(params, 'data.item_id'),
      input_value: (params.data && params.data.allergens) || []
    })
  }
})

export { getGridColumns, getItemsGridColumns }
