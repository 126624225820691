<template>
  <wiskModal :visible="!!selectedBottle || forceVisible" @hide="hide" size="md" :okText="translations.txtGenericSave" hideFooter :title="modalTitle">
    <!-- <template v-slot:modal-header-extra>
    </template> -->

    <wiskInputGroup class="wisk-inventory-item-quantity" @errorCountChanged="$emit('valid', !$event)" style="min-height: 450px;">
      <wiskInput infoTooltipKey="84f63dc9-8792-462a-8c5a-01f27349ec80" autofocus :key="key" :modelValue="selectedBottle ? selectedBottle.title : ''"
        :placeholder="translations.txtGenericTypeToSearch" :disabled="!!item" @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected"
        :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" @clear="onAutocompleteSelected(null)"
        :label="translations.txtGenericWiskItem" autocomplete style="" :customFormatter="addSuffix">
        <b-button v-if="newBottleVisible" variant="link" @click="setGlobalAction({ type: 'itemEdit', action: { item_id: 0, title: query, creationSource: { type: 'inventory', id: inventory.id } } })">
          {{ translations.txtVenueBottlesNotFoundCreate }}
        </b-button>
      </wiskInput>
      <div v-if="selectedBottle" class="mt-5">
        <b-accordion :items="locationsWithData" headerKey="title" itemKey="id" v-model="selectedLocationId">
          <template v-slot:header="location">
            <span> {{ location.title }} </span>
            <span> ({{ entriesByLocationId[location.id].length }}) </span>
          </template>
          <template v-slot="location">
            <b-row v-for="entry in entriesByLocationId[location.id]" :key="entry.id" class="item-view-mini mt-2 mx-0 " no-gutters>
              <b-col cols="9" class="">
                <inventoryItemMeasurement hideButtons :modelValue="entry" :hideTypeSelector="addEntryId !== entry.id" class="py-2 px-3" @update:modelValue="onEntryChange"
                  :variantId="entry.measurement.item_distributor_id" @itemFieldChange="$emit('itemFieldChange', $event)" :editable="editable" :inventoryEntryInputAttrs="{ horizontal: true }" />
              </b-col>
              <b-col>
                <b-button v-if="editable" variant="link" @click="removeEntry(entry)" class="text-primary text-danger-hover mt-2 bt-remove-entry" v-tooltip="translations.txtGenericRemove" style="">
                  <icon class="" :scale="0.7" name="wisk-exit" />
                </b-button>
              </b-col>

            </b-row>
            <b-row v-if="editable && availableVariants" class="mx-0" no-gutters>
              <b-col>
                <div class="w-100 mt-3">
                  <b-button v-if="availableVariants.length === 1" class="d-block mx-auto mt-3" @click="addNewEntry(selectedBottle.item_distributor_id)" size="md" :disabled="!!addEntryId || !selectedBottle" style="" variant="outline-primary">
                    {{ translations.txtInventoriesEntryAdd }}

                    <icon class="ms-2" :scale="0.8" name="wisk-plus" />
                  </b-button>

                  <b-dropdown up center :disabled="!!addEntryId || !selectedBottle" v-if="availableVariants.length > 1" size="md" :text="translations.txtInventoriesEntryAdd" toggle-class="text-decoration-none px-3 d-block" class="d-block mx-auto w-auto" variant="outline-primary">

                    <b-dropdown-item>
                      <b-button @click="addVariant" size="sm" block variant="primary">
                        {{ translations.txtVenueBottlesCreateVariant }}
                      </b-button>
                    </b-dropdown-item>
                    <div class="dropdown-divider" />
                    <b-dropdown-item v-for="variant in availableVariants" :key="variant.id" @click="addNewEntry(variant.id)" class="py-2" :active="item && variant.id === item.item_distributor_id">
                      <span class="text-muted"> {{ translations.txtVenueBottlesVariant }}:</span> <span class="text-bold"> {{ variant.title }} </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-accordion>
      </div>
      <wiskLoading :loading="loading" />
    </wiskInputGroup>
  </wiskModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import api from '@/api'
import { objectFilter, compareNumbers, prepareVariantIdAsItem } from '@/modules/utils'
import inventoryItemMeasurement from '@/components/inventories/InventoryItemMeasurement'

let getId = () => new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)

export default {
  name: 'InventoryItemQuantity',
  emits: ['hide', 'itemFieldChange', 'valid', 'refresh'],
  components: { inventoryItemMeasurement },
  props: {
    inventory: Object,
    item: Object,
    forceVisible: Boolean,
    locationId: Number,
    editable: { type: Boolean, default: true }
  },
  data() {
    return {
      newBottleVisible: false,
      selectedBottle: null,
      loading: false,
      addEntryId: null,
      timeoutIds: {},
      selectedServingSize: {},
      key: '',
      locationIdMapToInventoryLocationId: {},
      autocompleteItems: [],
      query: '',
      suffix: '',
      entriesByLocationId: {},
      localInventoryEntriesOld: [],
      selectedLocationId: null,
      newEntryType: 'unit'
    }
  },
  computed: {
    ...mapState(['bottles', 'bottlesById', 'translations', 'itemVariants', 'itemVariantsById', 'currentPermissionsByType', 'user', 'locations']),
    ...mapGetters(['venue', 'currency', 'activeLocations']),
    modalTitle() {
      return (this.selectedBottle && this.selectedBottle.title) || this.translations.txtGenericBottle
    },
    locationsWithData() {
      return this.locations.filter(l => !!this.entriesByLocationId[l.id])
    },
    entryTypes() {
      let types = [
        { id: 'unit', title: this.translations.txtGenericUnits },
        { id: 'scale', title: this.translations.txtInventoriesPartialsBottle }
      ]

      if (this.selectedBottle.case_size > 1) {
        types.unshift({ id: 'case', title: this.translations.txtGenericCases })
      }
      return types
    },
    availableVariants() {
      if (this.selectedBottle) {
        let itemId = this.selectedBottle.item_id
        return this.itemVariants.filter(v => v && !v.archived && (v.inventoriable || v.inventoriable_as_cases) && v.item_id === itemId)
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    hide() {
      this.selectedBottle = null
      this.$emit('hide')
    },
    addSuffix(text, item) {
      item = item || this.selectedBottle || {}
      if (item.title) {
        return item.title + item.titleSuffix
      }
      return ''
    },
    onAutocompleteInput(value) {
      this.query = value

      this.autocompleteItems = this.bottles.filter(b => !b.archived && objectFilter({ payload: b, query: value }))
      this.newBottleVisible = !this.autocompleteItems.length && this.currentPermissionsByType.item_manage
    },
    onAutocompleteSelected(bottle) {
      if (bottle) {
        this.selectedBottle = bottle
        this.autocompleteItems = []
      } else {
        this.selectedBottle = null
        this.autocompleteItems = []
      }
    },
    wrapEntryInExtraLevel(entry) {
      return {
        id: entry.order,
        location_id: this.selectedLocationId,
        inventory_order: entry.order,
        measurement: merge({}, entry),
        venue_id: this.venue.id,
        inventory_id: this.inventory.id,
        user_id: this.user.id
      }
    },
    save(operation, locationId = this.selectedLocationId) {
      this.loading = true
      api.updateInventoryLocation(this.inventory.id, { locationId, operation }).then(() => {
        this.$emit('refresh')
      }).finally(() => {
        this.loading = false
      })
    },
    getVariantIdFromItemId(itemId) {
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    addNewEntry(variantId) {
      if (!variantId && this.availableVariants && this.availableVariants[0]) {
        variantId = this.availableVariants[0].id
      }
      let id = getId(),
        item = variantId ? prepareVariantIdAsItem(variantId, this.$store.state) : this.selectedBottle

      if (item) {
        let entry = {
          id,
          location_id: this.selectedLocationId,
          inventory_order: id,
          measurement: {
            measurement: { type: 'unit', quantity: 0 },
            timestamp: new Date().toISOString(),
            item_id: item.item_id,
            item_distributor_id: variantId || this.getVariantIdFromItemId(item.item_id),
            order: id,
            item_measurement: item.measurement,
            user_id: this.user.id
          },
          venue_id: this.venue.id,
          inventory_id: this.inventory.id,
          user_id: this.user.id
        }
        this.addEntryId = id

        this.entriesByLocationId[this.selectedLocationId] = this.entriesByLocationId[this.selectedLocationId] || []
        this.entriesByLocationId[this.selectedLocationId] = [...this.entriesByLocationId[this.selectedLocationId], entry]
        this.entriesByLocationId = { ...this.entriesByLocationId }
      } else if (variantId) {
        api.postErrorInfo({ description: 'Variation not found for variation id ' + variantId })
      }
    },
    onEntryChange(entry) {
      clearTimeout(this.timeoutIds[entry.order])
      this.timeoutIds[entry.order] = setTimeout(() => {

        if (this.entriesByLocationId[this.selectedLocationId]?.find?.(e => e.id === entry.id)) {
          let type = get(entry, 'measurement.measurement.type'),
            value = 0,
            quantity = get(entry, 'measurement.measurement.quantity') || 0,
            mlLeft = get(entry, 'measurement.measurement.ml_left') || 0,
            percentageRemaining = get(entry, 'measurement.measurement.percentage_remaining') || 0

          switch (type) {
            case 'scale':
              value = mlLeft
              break
            case 'slider':
              value = percentageRemaining
              break
            default:
              value = quantity
              break
          }

          if (value) {
            let eventType = (this.addEntryId === entry.id && 'item_add') || 'item_update'

            this.addEntryId = null

            let operation = {
              type: eventType,
              value: entry.measurement
            }

            this.save(operation)
          }
        }
      }, 500)
    },
    removeEntry(entry) {
      if (this.selectedLocationId && entry) {
        this.entriesByLocationId[this.selectedLocationId] = this.entriesByLocationId[this.selectedLocationId].filter(e => e.id !== entry.id)

        if (entry.id === this.addEntryId) {
          this.addEntryId = null
        }

        let operation = {
          type: 'item_delete',
          value: entry.measurement
        }

        this.save(operation)
      }
    },
    populateLocalData() {
      this.entriesByLocationId = {}
      this.addEntryId = null

      if (this.selectedBottle && this.inventory && Array.isArray(this.inventory.locations)) {
        let entriesByLocationId = {}

        this.inventory.locations.forEach(l => {
          if (l && Array.isArray(l.measurements)) {
            this.locationIdMapToInventoryLocationId[l.location.id] = l.id

            entriesByLocationId[l.location.id] = []
            l.measurements.forEach(entry => {
              if (entry && entry.item_id === this.selectedBottle.item_id) {
                entriesByLocationId[l.location.id].push(this.wrapEntryInExtraLevel(entry))
              }
            })
            entriesByLocationId[l.location.id].sort((a, b) => compareNumbers(a.order, b.order))
          }
        })
        this.locationIdMapToInventoryLocationId = { ...this.locationIdMapToInventoryLocationId }
        this.entriesByLocationId = { ...entriesByLocationId }
      }
      this.loading = false
    },
    initAccordion() {
      if (this.activeLocations && this.activeLocations.length) {
        this.activeLocations.forEach((l, i) => {
          if (!i) {
            this.selectedLocationId = l.id
          }
        })

        if (this.locationId) {
          this.selectedLocationId = this.locationId
        }
      }
    },
    addVariant() {
      this.setGlobalAction({
        type: 'itemVariantEdit',
        action: {
          id: 0,
          itemId: this.selectedBottle.item_id,
          onChange: result => {
            setTimeout(() => {
              if (this.itemVariantsById[result.id]) {
                this.addNewEntry(result.id)
              } else {
                setTimeout(() => {
                  this.addNewEntry(result.id)
                }, 2000)
              }
            }, 2000)
          }
        }
      })
    }
  },
  mounted() {
    this.populateLocalData()
    this.initAccordion()
  },
  watch: {
    selectedBottle: 'populateLocalData',
    inventory: 'populateLocalData',
    activeLocations: 'initAccordion',
    locationId: 'initAccordion',
    item: {
      handler(item) {
        if (item) {
          this.onAutocompleteSelected(item)
          this.addEntryId = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.wisk-inventory-item-quantity {

  .bt-remove-entry,
  .bt-submit-entry {
    display: none;
  }

  .item-view-mini {

    .bt-remove-entry {
      position: absolute;
      top: -7px;
      right: -3px;
    }

    &:hover {

      .bt-remove-entry,
      .bt-submit-entry {
        display: block;
      }
    }
  }
}
</style>
