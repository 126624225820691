<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'supportEmailForm', action: null })" :title="translations.txtGenericContactUs" size="md" hideHelpButton
    :okText="translations.txtGenericSendEmail" @ok="sendSupportEmail" :okDisabled="!supportFormValid" :invisible="takingScreenshot">
    <wiskInputGroup v-if="mounted" style="" @errorCountChanged="setValidState">
      <wiskInput infoTooltipKey="ca206f16-5071-4a61-863b-8b9ff4a23947" :label="translations.txtGenericSubject" v-model="supportFormData.subject" inputType="text" required />
      <wiskInput infoTooltipKey="3974b049-b587-40fa-88ba-ed78342cec57" :label="translations.txtGenericMessage" multiline v-model="supportFormData.body" inputType="text" :required="messageFieldRequired" :lines="5" autoHeightTextArea />
    </wiskInputGroup>

    <template v-slot:modal-footer-left-side>
      <b-row>
        <b-col v-for="(url, index) in screenshotURLs" :key="index" cols="3" class="support-form-attachment">
          <imageView :src="url" filterDisplay="tinyThumbLandscape" imgClass="h-75" @click="openViewer(url)" />

          <b-button variant="link" @click="removeScreenshot(index)" class="bt-delete-image">
            <icon name="wisk-trash" class="text-danger" :scale="0.7" />
          </b-button>
        </b-col>
        <b-col cols="6" class="middle-center mt-" v-if="screenshotURLs.length < 2">
          <b-button variant="link" @click="openUploader" class="px-2 py-0" v-tooltip="translations.txtContactUsAddImage">
            <icon name="wisk-plus" class="" :scale="0.9" />
          </b-button>
          <b-button v-if="!takingScreenshot" variant="link" @click="getScreenshot" class="px-2 py-0" v-tooltip="translations.txtContactUsAddScreenshot">
            <icon name="wisk-screenshot" class="" :scale="1.0" />
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="extraHelpText">
        <b-col>
          <small> {{ extraHelpText }} </small>
        </b-col>
      </b-row>
    </template>
  </wiskModal>


</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import imageView from '@/components/common/ImageView'

export default {
  name: 'SupportEmailForm',
  components: { imageView },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      supportFormValid: true,
      supportFormData: { subject: '', body: '', extraData: {} },
      mounted: false,
      screenshotURLs: [], //['F6D42D72-8339-4175-85F6-E789331D912F.png', 'E38BC074-F87E-451B-AB89-7953C27E9E36.png'],
      takingScreenshot: false
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'venue']),
    ...mapGetters([]),
    messageFieldRequired() {
      return !this.editAction?.supportFormData?.extraData
    },
    extraHelpText() {
      return this.editAction.extraHelpText
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    setValidState(errorCount) {
      this.supportFormValid = !errorCount
    },
    openViewer(url) {
      this.setGlobalAction({ type: 'mediaViewer', action: { url } })
      console.log('url', url)
    },
    openUploader() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          fileTypesAccept: '.png, .jpg, .jpeg',
          save: file => {
            this.screenshotURLs.push(file.filename)
          },
          multiple: false,
          fullFileInfo: true
        }
      })
    },
    getScreenshot() {
      this.takingScreenshot = true

      this.setGlobalAction({
        type: 'screenshotTool',
        action: {
          save: file => {
            console.log('1', 1)
            this.screenshotURLs.push(file.filename)
            this.takingScreenshot = false
          },
          cancel: () => {
            console.log('2', 2)
            this.takingScreenshot = false
          }
        }
      })
    },
    removeScreenshot(index) {
      this.screenshotURLs.splice(index, 1)
    },
    sendSupportEmail() {
      let formData = {
        subject: this.supportFormData.subject,
        body: this.supportFormData.body
      }

      if (this.supportFormData.extraData) {
        formData.body += '<br /> \n <br /> \n'

        Object.keys(this.supportFormData.extraData).forEach(key => {
          formData.body += `<br /> \n ${key}: ${this.supportFormData.extraData[key]}`
        })
      }

      formData.body += '<br /> \n <br /> \n'

      if (this.screenshotURLs.length) {
        formData.body += '<br /> \n <br /> \n'
        formData.body += 'Screenshots: <br /> \n'

        this.screenshotURLs.forEach(url => {
          formData.body += `<br /> \n <a href="${'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + url}" target="_blank" title="Click to open in new tab"><img src="${'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + url}" width="100%" /></a>`
        })
      }

      api
        .emailSupport(formData)
        .then(() => {
          this.notify({
            message: this.translations.txtContactUsSuccessMessage,
            type: 'success'
          })
        })
        .finally(() => {
          this.supportFormData = { subject: '', body: '', extraData: {} }
          this.setGlobalAction({ type: 'supportEmailForm', action: null })
        })
    },
  },
  mounted() {
    this.mounted = true
  },
  watch: {
    editAction: {
      handler() {
        if (this.editAction) {
          this.supportFormData = {
            subject: '',
            body: '',
            ...this.editAction.supportFormData,
            extraData: {
              Venue: `${this.venue.title} (${this.venue.id})`,
              User: `${this.user.full_name} (${this.user.email} / ${this.user.id})`,
              Location: window.location.href,
              Timestamp: new Date().toISOString(),
              ...this.editAction.supportFormData?.extraData
            },
          }
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss">
.support-form-attachment {
  position: relative;


  .bt-delete-image {
    position: absolute;
    top: 0;
    right: 2px;
    border-color: transparent;
    padding: 0;
    display: none;
  }

  &:hover {
    .bt-delete-image {
      display: block;
    }
  }
}
</style>
