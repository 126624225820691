<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'movementAdjustmentReasonEdit', action: null })"
    :hideFooter="!!(localReason && localReason.id)" :title="modalTitle">

    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" v-if="localReason?.id" :actions="pageActions" size="sm" />
    </template>

    <wiskInputGroup class="movement-adjustment-reason-edit" style="" @errorCountChanged="setValidState" @operationsChange="operations = $event">
      <wiskInput infoTooltipKey="adjustmentReasonTitle" :label="translations.txtGenericTitle" required :modelValue="localReason.title" operation="title"
        triggerInputOnLoad triggerInputOnSet @operation="onSingleFieldChange" />
    </wiskInputGroup>

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'

const newReason = { id: 0, title: null }

export default {
  name: 'MovementAdjustmentReasonEdit',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      valid: true,
      operations: [],
      localReason: { ...newReason },
    }
  },
  computed: {
    ...mapState(['translations', 'movementAdjustmentReasonsById', 'currentPermissionsByType']),
    ...mapGetters([]),
    modalTitle() {
      if (this.localReason?.id) {
        return this.translations.translate('tplMovementAdjustmentReasonTitle', { '{a}': this.localReason.title })
      }
      return this.translations.txtMovementAdjustmentReasonNew
    },
    pageActions() {
      if (this.localReason?.id) {
        let actions = []

        if (this.currentPermissionsByType.invoice_archive) {
          actions.push({ key: 11, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.localReason.archived, action: this.archive })
          actions.push({ key: 12, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.localReason.archived, action: this.restore })
        }
        return actions
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    archive() {
      this.save({ type: 'archive', value: true })
    },
    restore() {
      this.save({ type: 'archive', value: false })
    },
    onSingleFieldChange(operation) {
      if (this.localReason && this.localReason.id) {
        this.save(operation)
      }
    },
    save(operation) {
      if (this.localReason) {
        api.updateMovementAdjustmentReason(this.localReason.id, operation || this.operations).then(updated => {
          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(updated)
          }

          if (updated && this.localReason && !this.localReason.id) {
            this.setGlobalAction({ type: 'movementAdjustmentReasonEdit', action: null })
          }

          if (updated && this.localReason && this.localReason.id) {
            this.localReason = { ...newReason, ...updated }
          }
        })
      }
    }
  },
  created() { },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction && editAction.reason) {
          this.localReason = { ...newReason, ...editAction.reason }
        }

        if (editAction && editAction.id) {
          this.localReason = { ...newReason, ...this.movementAdjustmentReasonsById[editAction.id] }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
