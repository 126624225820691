<template>
  <component :is="tag" :class="className">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BBadge',
  props: {
    variant: { type: String, default: 'secondary' },
    pill: { type: Boolean, default: false },
    tag: { type: String, default: 'span' }
  },
  computed: {
    className() {
      return {
        badge: true,
        'rounded-pill': this.pill,
        [`bg-${this.variant}`]: this.variant
      }
    }
  }
}
</script>
