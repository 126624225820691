<template>
  <div class="w-100 h-100  pt-ss4" :key="provider">
    <iframe v-if="url" :src="computedURL" class="w-100 h-100" frameborder="0" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'OfficeViewer',
  components: {},
  props: {
    url: String,
    selectedOfficeViewer: {
      type: String,
      default: 'office',
      validator: value => ['office', 'gDocs'].includes(value)
    }
  },
  data() {
    return {
      provider: 'office',
      providersById: {
        office: 'https://view.officeapps.live.com/op/embed.aspx?src=',
        gDocs: 'https://docs.google.com/gview?embedded=true&url='
      }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    computedURL() {
      return this.providersById[this.provider] + this.url
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  watch: {
    selectedOfficeViewer: {
      immediate: true,
      handler() {
        console.log('this.selectedOfficeViewer', this.selectedOfficeViewer)
        if (this.selectedOfficeViewer) {
          this.provider = this.selectedOfficeViewer
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
