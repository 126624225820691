<template>
  <div class="w-100 h-100 middle-center">
    <b-img :src="computedURL" alt="image" v-bind="$attrs" fluid center style="max-width: 100%; max-height: 100%;" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { cloudImage } from '@/modules/utils'

export default {
  name: 'ImageViewer',
  components: {},
  props: {
    url: String
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    computedURL() {
      return cloudImage(this.url, 'hugeLandscape')
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  watch: {}
}
</script>

<style lang="scss">

</style>
