<template>
  <table class="table b-table" :class="classList">
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'BTableSimple',
  props: {
    bordered: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        'table-bordered': this.bordered,
        'table-sm': this.small
      }
    }
  }
}
</script>
