import merge from 'lodash.merge'
import get from 'lodash.get'
import { currencyFormat } from '@/modules/utils'

const getGridColumns = ({ updatePurchaseOrder, translations, orderFormats, itemPricesById }) => ({
  dropdownMenu: {
    forceOverrideValuegetter: true,
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: item => {
            updatePurchaseOrder({ type: 'item_delete', value: item.item_distributor_id }, true)
          },
          getDisabled: item => {
            if (item && item.purchase_order_info && !item.purchase_order_info.editable) {
              return true
            }
            return false
          },
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  image: {
    hide: false
  },
  title: {
    hide: false,
    wiskBottomAggregation: translations.txtGenericTotal + ':'
  },
  category: {
    hide: false
  },
  distributorCode: {
    hide: false
  },
  stock: {
    hide: false
  },
  perpetual: {
    hide: true
  },
  par: {
    hide: true
  },
  pendingPurchaseOrders: { hide: true },
  parSuggested: { hide: true },
  parSuggestedSales: { hide: true },
  suggestedOrderWisk: { hide: true, pinned: null },
  suggestedOrderWiskSales: { hide: true, pinned: null },
  suggestedOrderRealTimeStock: { hide: true, pinned: null },
  suggestedOrder: { hide: true, pinned: null },
  costUnitPurchaseOrder: {
    colId: 'costUnitPurchaseOrder',
    hide: false,
    sortOrder: 1750,
    headerName: translations.columnCostPerUnit,
    cellRenderer: 'CellNumber',
    minWidth: 100,
    maxWidth: 120,
    cellClass: ['text-center'],
    cellRendererParams: {
      save: (inputValue, id, type, data) => {
        let value = merge({}, data.data.purchase_order_info)
        value.price_per_unit = inputValue
        value.price = { type: 'unit', value: inputValue }
        updatePurchaseOrder({ type, value }, true)
      },
      type: 'item_update',
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      id: params.data && params.data.purchase_order_info && params.data.purchase_order_info.id,
      price: get(itemPricesById, `${get(params, 'data.item_id')}`),
      title: params.data && params.data.title,
      input_value: get(params, 'data.purchase_order_info.price_per_unit'),
      data: params.data,
      readonly: !get(params, 'data.purchase_order_info.editable')
    })
  },
  orderItemQuantity: {
    colId: 'orderItemQuantity',
    hide: false,
    sortOrder: 1750,
    headerName: translations.txtGenericQuantity,
    cellRenderer: 'CellNumber',
    minWidth: 100,
    maxWidth: 120,
    cellClass: ['text-center'],
    cellRendererParams: {
      decimals: 2,
      save: (inputValue, id, type, data) => {
        let value = merge({}, data.data.purchase_order_info)
        value.order.quantity = inputValue
        updatePurchaseOrder({ type, value }, true)
      },
      type: 'item_update'
    },
    valueGetter: params => ({
      id: get(params.data, 'purchase_order_info.id'),
      price: get(itemPricesById, `${get(params, 'data.item_id')}`),
      title: params.data && params.data.title,
      input_value: get(params, 'data.purchase_order_info.order.quantity'),
      data: params.data,
      readonly: !get(params.data, 'purchase_order_info.editable')
    })
  },
  orderFormat: {
    colId: 'orderFormat',
    cellRendererParams: {
      save: (inputValue, id, type, data) => {
        let value = merge({}, data.data.purchase_order_info)
        value.order.format = inputValue.id
        updatePurchaseOrder({ type, value }, true)
      },
      type: 'item_update',
      getItems: () => orderFormats,
      multiselectWidth: 230,
      required: true
    },
    valueGetter: p => ({
      id: p.data && p.data.purchase_order_info && p.data.purchase_order_info.id,
      input_value: { id: get(p, 'data.purchase_order_info.order.format'), title: get(p, 'data.purchase_order_info.order.format') },
      readonly: p.data && (p.data.case_size === 1 || !p.data.purchase_order_info?.editable),
      data: p.data
    })
  },
  caseSize: {
    hide: false,
    sortOrder: 2300
  },
  lineTotal: {
    colId: 'lineTotal',
    sortOrder: 2400,
    pinned: 'right',
    wiskGetBottomAggregationValue: rowData => get(rowData, 'purchase_order_info.total', 0),
    cellClass: ['text-end', 'pe-4'],
    minWidth: 100,
    headerName: translations.txtGenericTotal,
    field: 'purchase_order_info.total',
    cellRendererParams: { decimals: 2 },
    valueFormatter: params => currencyFormat(params.value)
  }
})

export default getGridColumns
