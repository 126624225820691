<template>
  <router-link v-if="item.url && !(item.children && bottom) && !item.url.startsWith('http')"
    :to="item.url" class="nav-link children-link-common-selector" :class="[item.className || '', item.name.replace(/ /g, '-'), item.id]">
    <i>
      <icon :name="item.icon" :scale="item.scale || 1"></icon>
    </i>
    <span :class="{ 'ms-2': item.scale && item.scale > 1.2 }">{{ item.name }}</span>
    <slot></slot>
  </router-link>
  <a v-else-if="item.url && !(item.children && bottom) && item.url.startsWith('http')" rel="noreferrer"
    :href="item.url" target="_blank" class="nav-link" :class="[item.className || '', item.name.replace(/ /g, '-')]">
    <i>
      <icon :name="item.icon" :scale="item.scale || 1"></icon>
    </i>
    <span :class="{ 'ms-2': item.scale && item.scale > 1.2 }">{{ item.name }}</span>
    <slot></slot>
  </a>
  <div v-else-if="item.children && bottom" class="nav-link has-children has-extra" :class="[item.className || '', { 'has-extra': !!item.extra }, item.name.replace(/ /g, '-'), item.id]">
    <i>
      <icon :name="item.icon" :scale="item.scale || 1"></icon>
    </i>
    <span :class="{ 'ms-2': item.scale && item.scale > 1.2 }">{{ item.name }}</span>
    <span class="nav-link-extra">{{ item.extra }}</span>
    <slot></slot>
  </div>
  <a target="_self" v-else-if="item.action" class="cursor-pointer nav-link" @click="$emit('handleAction', item.action)" :class="[item.className || '', item?.name?.replace(/ /g, '-'), item.id]">
    <i>
      <icon :name="item.icon" :scale="item.scale || 1"></icon>
    </i>
    <span :class="{ 'ms-2': item.scale && item.scale > 1.2 }">{{ item.name }}</span>
    <slot></slot>
  </a>
  <a target="_self" v-else-if="item.dropdown" class="cursor-pointer nav-link expandable-nav-link" @click="$emit('handleAction', item.action)" :class="[item.className || '', item.name.replace(/ /g, '-'), item.id]">
    <i>
      <icon v-if="item.icon || icon" :name="item.icon || icon" :scale="item.scale || 1"></icon>
    </i>
    <span :class="{ 'ms-2': item.scale && item.scale > 1.2 }">{{ item.name }}</span>
    <slot></slot>
    <i class="expandable-chevron">
      <icon name="wisk-chevron-right" :scale="1" />
    </i>
  </a>
</template>

<script>
export default {
  name: 'SidebarNavLink',
  emits: ['handleAction'],
  props: { item: { type: Object, required: true }, bottom: Boolean }
}
</script>
