export default {
  name: 'wisk-book',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,17.878q0,4.425,0,8.85a2.969,2.969,0,0,1-2.539,2.82,3.421,3.421,0,0,1-1.989-.38,10.243,10.243,0,0,0-9.525.011c-.942.466-1.863.975-2.784,1.482a1.243,1.243,0,0,1-1.285.007c-.889-.484-1.777-.969-2.679-1.428a10.225,10.225,0,0,0-9.734-.024A3.014,3.014,0,0,1,0,26.54q0-8.673,0-17.347A3.084,3.084,0,0,1,1.532,6.429a14.7,14.7,0,0,1,6.349-2.2A14.16,14.16,0,0,1,15.925,5.8c.461.241.924.479,1.376.737a.387.387,0,0,0,.429,0,19.954,19.954,0,0,1,3.629-1.683A14.158,14.158,0,0,1,32.443,5.85c.322.168.651.327.959.519A3.135,3.135,0,0,1,35,9.175Q35,13.527,35,17.878ZM18.6,27.889a.431.431,0,0,0,.1-.008L19.8,27.3a12.6,12.6,0,0,1,11.754-.05.817.817,0,0,0,1.276-.762q0-8.6,0-17.2a1.15,1.15,0,0,0-.669-1.116c-.314-.162-.622-.337-.938-.5A12.011,12.011,0,0,0,22.828,6.7a16.009,16.009,0,0,0-3.917,1.668.5.5,0,0,0-.316.513q.014,9.338.007,18.673Zm-2.205.033c.007-.095.016-.151.016-.208q0-9.481,0-18.964a.307.307,0,0,0-.191-.319c-.481-.245-.953-.51-1.433-.757a11.863,11.863,0,0,0-4.3-1.266,12.447,12.447,0,0,0-7.724,1.82,1.11,1.11,0,0,0-.582,1.02q0,8.643,0,17.284a.959.959,0,0,0,.1.421.821.821,0,0,0,1.215.282,12.54,12.54,0,0,1,8.07-1.2,13.422,13.422,0,0,1,4.279,1.609C16.026,27.735,16.2,27.819,16.4,27.922Z'
    }
  ]
}
