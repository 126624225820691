<template>
  <wiskInput :label="label" v-model="localMeasurement.quantity" inputType="number" @blur="onBlur" @update:modelValue="emitUpdateModel"
    :disabled="disabled" :min="1" :max="localMeasurement.out_of - 1" :helperText="helpText" infoTooltipKey="helpTextInventoryEntryInputPartial" />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'InventoryEntryInputPartial',
  emits: ['update:modelValue', 'blur', 'valid', 'change'],
  components: {},
  props: { modelValue: Object, disabled: Boolean, emitFast: Boolean, item: Object },
  data() {
    return {
      localMeasurement: { quantity: 0, type: 'partial', out_of: 0 }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    label() {
      return this.translations.translate('tplInventoriesEditPartialComponent', { '{a}': this.localMeasurement?.out_of || 0 })
    },
    helpText() {
      return this.translations.translate('tplInventoriesEditPartialComponentHelpText', { '{a}': (this.localMeasurement?.out_of || 1) - 1 })
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onBlur(event) {
      this.$emit('blur', event)
      this.$emit('valid', !!this.localMeasurement.quantity)

      if (!this.modelValue || this.modelValue.quantity !== this.localMeasurement.quantity) {
        this.$emit('update:modelValue', { ...this.localMeasurement })
        this.$emit('change', { ...this.localMeasurement })
      }
    },
    emitUpdateModel() {
      if (this.emitFast) {
        this.$emit('update:modelValue', { ...this.localMeasurement })
        this.$emit('valid', !!this.localMeasurement.quantity)
      }
    }
  },
  mounted() {
    this.$emit('valid', !!this.localMeasurement.quantity)
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler() {
        if (this.modelValue && this.modelValue.quantity !== this.localMeasurement.quantity) {
          this.localMeasurement.quantity = this.modelValue.quantity || 0
        }
      }
    },
    item: {
      immediate: true,
      handler(item) {
        this.localMeasurement.out_of = item?.measurement?.quantity || 0
      }
    }
  }
}
</script>

<style lang="scss"></style>
