export default {
  name: 'wisk-chart',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M16.3,0v9.3c-0.6,0.2-1.2,0.3-1.7,0.5c-4.2,1.6-6.3,6.3-4.7,10.5s6.3,6.3,10.5,4.7c2.4-0.9,4.3-2.9,5-5.5 c0.1-0.2,0.1-0.5,0.1-0.7c0-0.3,0.1-0.3,0.4-0.3c2.7,0,5.3,0,8,0h1c-0.3,5.8-3.5,11-8.6,14c-8.4,4.9-19.1,2.1-24-6.3 c-2.7-4.6-3.1-10.2-1.2-15.2C3.7,4.8,9.6,0.5,16.3,0z'
    },
    { d: 'M25.6,16.3c-0.6-3.6-3.4-6.4-6.9-6.9V0c3.9,0.2,7.7,1.8,10.6,4.5c3.4,3,5.4,7.2,5.7,11.8H25.6z' }
  ]
}
