<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'multivenue_analytics', action: null })" size="lg"
    hideFooter title="Multi-Venue Analytics" class="p-0">
    <iframe id="airtable-iframe" class="airtable-embed" src="https://airtable.com/embed/app0YTgtMPG0uNc06/shrsOcf2d24KPxxW4" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;">

    </iframe>
    <wiskLoading :loading="loading" />
  </wiskModal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'MultiVenueAnalytics',
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {},
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  mounted() {
    setTimeout(() => {
      let iframe = document.getElementById('airtable-iframe')

      if (iframe) {
        iframe.onload = () => {
          this.loading = false
        }
      }
    }, 500)
  }
}
</script>
<style lang="scss"></style>
