// alternative names: wisk-copy
export default {
  name: 'wisk-copy',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M20.1574,0H12.1501c-2.068,0-3.7446,1.6494-3.7446,3.6835v1.8508h-.5385c-2.068,0-3.7446,1.6494-3.7446,3.6835V31.3165c0,2.0341,1.6766,3.6835,3.7446,3.6835h14.9829c2.068,0,3.7445-1.6494,3.7445-3.6835v-1.8508h.5385c2.068,0,3.7446-1.6494,3.7446-3.6835V10.5414L20.1574,0Zm3.9414,31.3165c0,.6765-.5589,1.2263-1.2489,1.2263H7.867c-.6878,0-1.2489-.5498-1.2489-1.2263V9.2178c0-.6788,.5611-1.2263,1.2489-1.2263h6.2425v7.9779c0,1.0182,.8394,1.8418,1.8734,1.8418h8.1159v13.5054Zm-7.4914-15.9603v-5.6293l5.7244,5.6293h-5.7244Zm11.7745,10.426c0,.6765-.5612,1.2263-1.249,1.2263h-.5385v-10.9328L15.8766,5.5343h-4.9754v-1.8508c0-.6788,.5589-1.2263,1.2489-1.2263h3.8758l.0045-.0045h3.1993l3.7988,3.7989,2.6314,2.6314,.9548,.939h-.0159l1.7807,1.7806v.6743h.0023v13.5054Z'
    }
  ]
}
