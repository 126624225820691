import get from 'lodash.get'
import { formatDate, percentageFormat } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'

const gridOptions = {}

const getItemsGridColumns = ({ translations, setGlobalAction, enableMore }) => {
  const cols = {
    image: {},
    title: { sortOrder: 360, sort: null },
    addedOn: {},
    invoiceNumber: {
      headerName: translations.txtMovementEditInvoiceNumber,
      colId: 'invoiceNumber',
      ...agGridFilters.text,
      sortOrder: 500,
      valueGetter: params => get(params, 'data.costChangeItem.invoice_number')
    },
    invoiceDate: {
      headerName: translations.txtScannedInvoicesInvoiceDate,
      colId: 'invoiceDate',
      ...agGridFilters.date,
      sortOrder: 1500,
      valueFormatter: params => formatDate(params.value),
      valueGetter: params => get(params, 'data.costChangeItem.date')
    },
    previousCostUnit: {
      colId: 'previousCostUnit',
      sortOrder: 1700,
      headerName: translations.txtScannedInvoicePricePerUnitPrevious,
      cellRenderer: 'CellNumber',
      cellClass: ['currency'],
      minWidth: 100,
      width: 100,
      ...agGridFilters.number,
      cellRendererParams: {
        decimals: 2,
        prefix: window.WiskGlobals.currency,
        readonly: true
      },
      valueGetter: params => ({ input_value: get(params, 'data.costChangeItem.previous_cost_per_unit') })
    },
    newCostUnit: {
      colId: 'newCostUnit',
      sortOrder: 1710,
      headerName: translations.txtCostChangesNewCostUnit,
      cellRenderer: 'CellNumber',
      cellClass: ['currency'],
      minWidth: 100,
      width: 100,
      ...agGridFilters.number,
      cellRendererParams: {
        decimals: 2,
        prefix: window.WiskGlobals.currency,
        readonly: true
      },
      valueGetter: params => ({ input_value: get(params, 'data.costChangeItem.new_cost_per_unit') })
    },
    changesPerUnit: {
      colId: 'changesPerUnit',
      sortOrder: 1720,
      headerName: translations.txtCostChangesCostDifference,
      cellRenderer: 'CellNumber',
      cellClass: params => ['currency', get(params, 'data.costChangeItem.change_per_unit') > 0 ? 'text-danger' : get(params, 'data.costChangeItem.change_per_unit') < 0 ? 'text-success' : ''],
      minWidth: 100,
      width: 100,
      ...agGridFilters.number,
      cellRendererParams: {
        decimals: 2,
        prefix: window.WiskGlobals.currency,
        disableNegative: true,
        readonly: true
      },
      valueGetter: params => ({ input_value: get(params, 'data.costChangeItem.change_per_unit') })
    },
    changesPercentagePerUnit: {
      headerName: translations.txtCostChangesCostDifferencePercentage,
      colId: 'changesPercentagePerUnit',
      ...agGridFilters.number,
      cellClass: params => [get(params, 'data.costChangeItem.change_per_unit') > 0 ? 'text-danger' : get(params, 'data.costChangeItem.change_per_unit') < 0 ? 'text-success' : ''],
      minWidth: 100,
      width: 100,
      sortOrder: 1720,
      cellRendererParams: { useValueFormatter: true },
      valueFormatter: params => percentageFormat(params.value, 2, true, ''),
      valueGetter: params => get(params, 'data.costChangeItem.change_per_unit', 1) / get(params, 'data.costChangeItem.previous_cost_per_unit')
    },
    distributor: {
      editable: false
    }
  }

  if (enableMore) {
    cols.more = {
      cellRendererParams: {
        onClick: (id, value) => {
          setGlobalAction({ type: 'movementEdit', action: { id, itemIdFilter: value.itemId } })
        }
      },
      valueGetter: params => ({
        id: params.data?.costChangeItem?.movement_id,
        itemId: params.data && params.data.item_id,
        group: !!params.node.group
      })
    }
  }

  return cols
}

export { gridOptions, getItemsGridColumns }
