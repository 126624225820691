<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else class="text-center">
    <b-badge :variant="variant"> {{ text }} </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CellBadge',
  components: {},
  data() {
    return {
      linkInfo: {}
    }
  },
  computed: {
    text() {
      return this.params.value && this.params.value.input_value
    },
    variant() {
      return this.params.value && this.params.value.variant
    }
  }
}
</script>
