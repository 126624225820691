<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemSearchDialog', action: null })" size="md" hideHeaderExtra class="" hideFooter :title="translations.txtItemSearch">
    <b-container fluid class="" style="min-height: 500px;">
      <wiskInput infoTooltipKey="97164fa9-d695-4faf-ab81-c568eaa112de" :label="translations.txtGenericTypeToSearch" v-model="searchQuery" autofocus />

      <wiskItemsGrid :columns="columns" :trackBy="splitItemsByVariant ? 'item_distributor_id' : 'item_id'" ref="itemsGrid" :items="computedItems"
        reactToChangesFast :header="{ hideHeader: true }" parentGridName="ItemSearchDialogGrid" infoTooltipBaseName="ItemSearchDialog"
        :searchQuery="searchQuery" :gridStyle="{ height: '485px' }" class="item-search-dialog-grid" @gridApi="gridReady" />

      <small> {{ translations.txtGenericClickToSelect }} </small>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { prepareVariantAsItem } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'

export default {
  name: 'ItemSearchDialog',
  components: { wiskItemsGrid },
  props: { editAction: Object },
  data() {
    return {
      searchQuery: ''
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'itemVariants', 'distributorsById']),
    splitItemsByVariant() {
      return this.editAction && this.editAction.splitItemsByVariant
    },
    computedItems() {
      let activeBottles = []

      if (this.splitItemsByVariant) {
        activeBottles = this.itemVariants
          .map(v => prepareVariantAsItem(v, this.$store.state))
          .filter(b => !b.archived && b.variant && !b.variant.archived)
      } else {
        activeBottles = this.bottles.filter(b => !b.archived).map(b => ({ ...b, distributor: this.distributorsById[b.distributor_id] }))
      }

      return activeBottles
    },
    columns() {
      return {
        image: { hide: false, cellRendererParams: { readonly: true } },
        title: { hide: false, minWidth: 100, cellRendererParams: { readonly: true } },
        distributor: { hide: false, cellRendererParams: { readonly: true } },
        distributorCode: { hide: false, minWidth: 80, cellRendererParams: { readonly: true } },
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridReady(agGridApi) {
      agGridApi.addEventListener('rowClicked', this.onRowClick)
    },
    onRowClick(row = {}) {
      let item = row.data

      if (item && this.editAction && this.editAction.onChange) {
        this.editAction.onChange(item)
      }
      this.setGlobalAction({ type: 'itemSearchDialog', action: null })
    }
  }
}
</script>

<style lang="scss">
.item-search-dialog-grid {
  .ag-theme-balham {
    .ag-header {

      min-height: 25px !important;
      height: 25px !important;
    }

    .ag-header-row {

      height: 25px !important;

      .wisk-header-cell {
        font-weight: 500;
        font-size: 11px;
        padding: 0px;

      }
    }
  }

  .cell-text .overlay,
  .cell-pop-multiselect .overlay,
  .cell-number .overlay {
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
