<template>
  <div v-if="value && mounted"
    style="display: block; position: absolute; z-index: 999999; bottom: 0;" :style="style" @click="onIconClick">
    <v-menu :triggers="['hover']" :distance="5">
      <icon class="text-primary" :name="showCostChangeWarning ? 'wisk-cost-chart-warning' : 'wisk-cost-chart'" :scale=".9" />

      <template #popper>
        <div class="p-2">
          <div v-for="info in costChangeInfo" :key="info.title" :class="info.class">
            <hr v-if="info.divider" class="my-1 border-black" />
            <span class="me-2">{{ info.title }}:</span>
            <span>{{ info.value }}</span>
          </div>
        </div>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { currencyFormat, toFixed } from '@/modules/utils'

export default {
  name: 'CostChangeInfo',
  props: { params: Object, style: { type: Object, default: () => ({ right: '35px', bottom: '-2px' }) } },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    value() {
      const value = this.params?.value?.costChangeInfo || {}
      if (!value.previous_cost_per_unit || !value.new_cost_per_unit || !value.change_per_unit) {
        return null
      }
      return this.params.value
    },
    costChangeInfo() {
      if (this.value) {
        return [
          {
            title: this.translate('txtScannedInvoicePricePerUnitPrevious'),
            value: currencyFormat(this.value.costChangeInfo.previous_cost_per_unit),
          },
          {
            title: this.translate('txtCostChangesNewCostUnit'),
            value: currencyFormat(this.value.costChangeInfo.new_cost_per_unit)
          },
          {
            title: this.translate('txtCostChangesCostDifference'),
            value: currencyFormat(this.value.costChangeInfo.change_per_unit),
            class: this.value.costChangeInfo.change_per_unit > 0 ? 'text-danger' : this.value.costChangeInfo.change_per_unit < 0 ? 'text-success' : ''
          },
          {
            title: this.translate('txtCostChangesCostDifferencePercentage'),
            value: toFixed(this.value.costChangeInfo.change_percentage, 2) + '%',
            class: this.value.costChangeInfo.change_per_unit > 0 ? 'text-danger' : this.value.costChangeInfo.change_per_unit < 0 ? 'text-success' : ''
          },
          {
            divider: true,
            title: this.translate('txtCostChangesCostTotalChange'),
            value: currencyFormat(this.value.costChangeInfo.total_change),
            class: this.value.costChangeInfo.change_per_unit > 0 ? 'text-danger' : this.value.costChangeInfo.change_per_unit < 0 ? 'text-success' : ''
          }
        ]
      }
      return []
    },
    showCostChangeWarning() {
      const costChangeWarningThreshold = 0.01,
        priceChange = (this.value.costChangeInfo.new_cost_per_unit - this.value.costChangeInfo.previous_cost_per_unit) / this.value.costChangeInfo.previous_cost_per_unit

      return Math.abs(priceChange) > costChangeWarningThreshold
    }
  },
  methods: {
    toFixed,
    onIconClick() {
      if (typeof this.params.onIconClick === 'function') {
        this.params.onIconClick(this.params.value)
      }
    }
  },
  mounted() {
    this.mounted = true
  }
}
</script>
