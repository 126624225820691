<template>
  <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" class="category-operations" style="min-height: 500px;" @operationsChange="onChange">
    <wiskSelect infoTooltipKey="f56263e5-00a6-4f6a-a0f1-e4d0d592c914" :multiselectOptions="multiselectOptionsFamilyId" :label="translations.txtGenericFamily" :disabled="category && category.family_id && !category.id"
      :modelValue="selectedFamily" operation="family_id" :items="activeFamilies" required :triggerInputOnLoad="triggerInputOnLoad" @operation="onSingleChange" :addNewItem="addFamilyConfig" />

    <wiskInput infoTooltipKey="f18904a7-0505-48b2-bfce-e83b3f82a4d0" :label="translations.txtGenericTitle" operation="title" :modelValue="localCategory.title"
      required :triggerInputOnLoad="triggerInputOnLoad" autofocus @operation="onSingleChange" />

    <wiskSelect infoTooltipKey="e0973713-e05e-452d-a9bc-e87760eab635" :multiselectOptions="multiselectOptionsMeasureType" :label="translations.txtFamiliesCategoriesPartial"
      :modelValue="selectedPartial" operation="partial" :items="categoryPartialMeasureTypes" @operation="onSingleChange" />

    <wiskInput infoTooltipKey="c01caf13-b2c4-42e4-8b94-d48b7ca61efc" :label="translations.txtGenericDensity" operation="density" :modelValue="localCategory.density" @operation="onSingleChange"
      inputType="number" :decimals="2" decimalsAsNeeded />

    <wiskInput infoTooltipKey="233aba0f-8d70-4ab2-a357-955656ab8024" :label="translations.txtFamiliesCategoriesTargetBeverageCost" operation="target_beverage_cost" @operation="onSingleChange"
      :modelValue="localCategory.target_beverage_cost" inputType="number" :transformerOut="z => z / 100" :transformerIn="z => z * 100" suffix="%" />

    <wiskSelect infoTooltipKey="821d7181-f929-4e1d-8428-cc4a6b1b955c" :items="activeTaxes" @operation="onSingleChange" :modelValue="localCategory.tax_rate_id" operation="tax_rate_id" :label="translations.txtTax"
      :addNewItem="addTaxConfig" operationEmpty="tax_rate_id_clear" fixedWrapper v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" />

    <wiskInput infoTooltipKey="8a30220e-fc58-4a4d-83f2-f0867cada5f7" :label="translations.txtGenericExcludedFromVariance" :modelValue="localCategory.excluded_from_variance" inputType="checkbox"
      operation="excluded_from_variance" @operation="onSingleChange" />
  </wiskInputGroup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import merge from 'lodash.merge'
import wiskSelect from '@/components/common/WiskSelect'
import wiskInput from '@/components/common/WiskInput'

export default {
  name: 'CategoryOperations',
  emits: ['update:modelValue', 'operation', 'validStateChanged'],
  components: { wiskSelect, wiskInput },
  props: {
    modelValue: Array,
    disabled: Boolean,
    category: Object
  },
  data() {
    return {
      localCategory: {},
      triggerInputOnLoad: false,
      operations: [],
      valid: true,
      selectedFamily: null,
      selectedPartial: null
    }
  },
  computed: {
    ...mapGetters(['categoryPartialMeasureTypes', 'activeFamilies', 'activeTaxes']),
    ...mapState(['families', 'translations', 'currentPermissionsByType', 'venue']),
    multiselectOptionsFamilyId() {
      return {}
    },
    multiselectOptionsMeasureType() {
      return {}
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    addFamilyConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'familyEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: family => {
                callbackItemInjected(family)
              }
            }
          })
        },
        label: this.translations.txtFamiliesCategoriesNewFamily
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('validStateChanged', this.valid)
    },
    onChange(operations) {
      this.operations = [...operations]

      this.$emit('update:modelValue', this.operations)
    },
    onSingleChange(operation) {
      if (this.category && this.category.id) {
        this.$emit('operation', operation)
      }
    }
  },
  watch: {
    category: {
      handler() {
        this.localCategory = merge({}, this.category)
        this.selectedFamily = this.families.find(f => f.id === this.category.family_id)
        this.selectedPartial = this.categoryPartialMeasureTypes.find(f => f.id === this.category.partial)

        if (this.category && !this.category.id) {
          this.triggerInputOnLoad = true
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss"></style>
