export default {
  name: 'wisk-information-button',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.4952,0A17.4951,17.4951,0,1,0,35,17.4952,17.4975,17.4975,0,0,0,17.4952,0ZM15.9721,8.66a2.1672,2.1672,0,0,1,1.5524-.6151,1.9982,1.9982,0,0,1,1.484.6444,2.052,2.052,0,0,1,.6247,1.4938,2.1607,2.1607,0,0,1-3.6806,1.5423,2.121,2.121,0,0,1-.6248-1.5423A2.0211,2.0211,0,0,1,15.9721,8.66Zm5.233,17.9149a.3237.3237,0,0,1-.3711.3612H14.1562a.3167.3167,0,0,1-.3612-.3612V24.8759a.3168.3168,0,0,1,.3612-.3613H15.855V16.47H14.2343c-.1952,0-.3124-.0195-.3611-.0782-.0587-.0487-.0782-.1659-.0782-.3611V14.4881c0-.1854.02-.3125.0782-.3612a.5539.5539,0,0,1,.3611-.0781h4.4715a.5946.5946,0,0,1,.3612.0781.5742.5742,0,0,1,.078.3612V24.5146h1.689a.3238.3238,0,0,1,.3711.3613Z'
    }
  ]
}
