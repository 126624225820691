<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <imageView v-else :src="imgSrc" @click="onClick" :hoverIcon="overlayIcon" :filterDisplay="filterDisplay" />
</template>

<script>
import imageView from '@/components/common/ImageView'

export default {
  name: 'CellImage',
  components: { imageView },
  computed: {
    readonly() {
      return !!this.params.readonly || !!this.params.value.readonly
    },
    imgSrc() {
      return (this.params.value && this.params.value.image) || ''
    },
    overlayIcon() {
      return this.readonly ? null : (this.params && this.params.overlayIcon)
    },
    filterDisplay() {
      return this.params && this.params.filterDisplay
    }
  },
  methods: {
    refresh(/*params*/) {
      //this.params = merge({}, this.params, params)
      return false //true
    },
    onClick(event) {
      if (this.params && this.params.onClick && !this.readonly) {
        this.params.onClick(this.params.data, event)
      }
    }
  }
}
</script>
