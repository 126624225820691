import BAlert from './BAlert'
import BBadge from './BBadge'
import BButton from './BButton'
import BCopyBtn from './BCopyBtn'
import BCard from './BCard'
import BCardBody from './BCardBody'
import BCardHeader from './BCardHeader'
import BCol from './BCol'
import BContainer from './BContainer'
import BDropdown from './BDropdown'
import BDropdownItem from './BDropdownItem'
import BFormGroup from './BFormGroup'
import BFormRadio from './BFormRadio'
import BFormRadioGroup from './BFormRadioGroup'
import BImg from './BImg'
import BInputGroup from './BInputGroup'
import BRow from './BRow'
import BTableSimple from './BTableSimple'
import BTable from './BTable'
import BCollapse from './BCollapse'
import BAccordion from './BAccordion'

export default app => {
  app.component('b-alert', BAlert)
  app.component('b-badge', BBadge)
  app.component('b-button', BButton)
  app.component('b-copy', BCopyBtn)
  app.component('b-card', BCard)
  app.component('b-card-body', BCardBody)
  app.component('b-card-header', BCardHeader)
  app.component('b-col', BCol)
  app.component('b-container', BContainer)
  app.component('b-dropdown', BDropdown)
  app.component('b-dropdown-item', BDropdownItem)
  app.component('b-form-group', BFormGroup)
  app.component('b-form-radio', BFormRadio)
  app.component('b-form-radio-group', BFormRadioGroup)
  app.component('b-img', BImg)
  app.component('b-input-group', BInputGroup)
  app.component('b-row', BRow)
  app.component('b-collapse', BCollapse)
  app.component('b-accordion', BAccordion)
  app.component('b-table', BTable)
  app.component('b-table-simple', BTableSimple)
}
