<template>
  <v-tooltip v-if="!params.node.group" style="" class="w-100 h-100">
    <icon class="info-icon text-secondary" name="wisk-information-button"></icon>

    <template #popper>
      <span>
        {{ translate('txtFamilyInfo') }}
      </span>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: 'FamilyInfo',
  props: { params: Object },
  computed: {
    translate() {
      return (this.params && this.params.translate) || (() => '')
    }
  }
}
</script>
