export default {
  name: 'wisk-open-box',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M29.594,14.191a.1.1,0,0,1,.012-.046,2.438,2.438,0,0,0-2.439-2.438H19.161a.078.078,0,0,1-.051-.016H16.7v.016H12.951l14.413-8.3-1.3-2.256a2.29,2.29,0,0,0-3.13-.843L6.544,9.744A2.294,2.294,0,0,0,5.7,12.877l.444.773a2.327,2.327,0,0,0-.058.522V32.515A2.474,2.474,0,0,0,8.553,35H27.175a2.428,2.428,0,0,0,2.431-2.431.116.116,0,0,1-.012-.054Zm-14.216.649h4.93v4.854h-4.93ZM27.245,32.515a.132.132,0,0,1-.136.136H8.553a.127.127,0,0,1-.112-.136V14.191c0-.058.054-.135.112-.135h4.471V20.42a1.621,1.621,0,0,0,1.627,1.623h6.384a1.62,1.62,0,0,0,1.623-1.623V14.056h4.451a.145.145,0,0,1,.136.135Z'
    }
  ]
}
