export default {
  name: 'wisk-delete',
  width: 35,
  height: 35,
  paths: [
    { d: 'M17.5,35C7.8,35,0,27.2,0,17.5S7.8,0,17.5,0S35,7.8,35,17.5v0C35,27.2,27.2,35,17.5,35z M17.5,2.9 C9.4,2.9,2.9,9.4,2.9,17.5s6.5,14.6,14.6,14.6s14.6-6.5,14.6-14.6C32.1,9.4,25.5,2.9,17.5,2.9z' },
    {
      d: 'M24.4,23.8c0.5,0.6,0.5,1.5-0.1,2.1c0,0,0,0,0,0c-0.3,0.2-0.6,0.4-1,0.4c-0.4,0-0.8-0.2-1.1-0.5l-4.7-5.3l-4.8,5.3 c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.7-0.1-1-0.4c-0.6-0.5-0.7-1.5-0.1-2.1l5-5.6l-5-5.6c-0.5-0.6-0.5-1.5,0.1-2.1c0,0,0,0,0,0 c0.6-0.5,1.5-0.5,2,0.1c0,0,0,0,0,0l4.8,5.3l4.7-5.3c0.5-0.6,1.5-0.7,2.1-0.1s0.7,1.5,0.1,2.1l-5,5.6L24.4,23.8z'
    }
  ]
}
