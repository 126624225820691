<template>
  <div class="d-inline-block wisk-custom-dropdown" :class="classList" @click.stop>
    <slot name="main-btn" />
    <b-button type="button" :size="size" :variant="variant" v-bind="$attrs" :class="buttonClassList" @click="toggle" :disabled="disabled">
      <slot name="button-content" v-if="!split"><span>{{ text }}</span></slot>
    </b-button>
    <div role="menu" tabindex="-1" :class="menuClassList" @click="toggle" style="z-index: 12;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import bButton from './BButton'

const clickOutsideOptions = { passive: true, capture: true }

export default {
  name: 'BDropdown',
  components: { 'b-button': bButton },
  props: {
    variant: { type: String, default: 'secondary' },
    noCaret: { type: Boolean, default: false },
    text: { type: String, default: '' },
    size: { type: String, default: 'md' },
    right: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    up: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    toggleClass: { type: [String, Array], default: '' },
    menuClass: { type: [String, Array], default: '' }
  },
  data() {
    return {
      isOpen: false,
      clickOutsideTimeoutId: null,
      forceRight: false
    }
  },
  computed: {
    split() {
      return !!this.$slots['main-btn']
    },
    classList() {
      const classList = this.split ? ['btn-group'] : [this.up ? 'dropup' : 'dropdown']
      if (this.isOpen) classList.push('show')
      if (this.noCaret) classList.push('dropdown-toggle-no-caret')
      if (this.center) classList.push('dropdown-center')
      return classList
    },
    buttonClassList() {
      const toggleClass = Array.isArray(this.toggleClass) ? this.toggleClass : String(this.toggleClass).split(' ')
      const buttonClassList = toggleClass.concat(['dropdown-toggle'])
      if (this.disabled) buttonClassList.push('disabled')
      if (this.split) buttonClassList.push('dropdown-toggle-split')
      return buttonClassList
    },
    menuClassList() {
      const menuClass = Array.isArray(this.menuClass) ? this.menuClass : String(this.menuClass).split(' ')
      const menuClassList = menuClass.concat(['dropdown-menu'])
      if (this.isOpen) menuClassList.push('show')
      if (this.forceRight) menuClassList.push('dropdown-menu-right')
      return menuClassList
    }
  },
  methods: {
    toggle(e) {
      const left = e.clientX
      this.forceRight = left > 300 && this.right

      this.isOpen = !this.disabled ? !this.isOpen : false
      clearTimeout(this.clickOutsideTimeoutId)
    },
    clickOutside() {
      this.clickOutsideTimeoutId = setTimeout(() => {
        this.isOpen = false
      }, 10)
    }
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, clickOutsideOptions)
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.clickOutside, clickOutsideOptions)
    clearTimeout(this.clickOutsideTimeoutId)
  }
}
</script>

<style lang="scss">
.wisk-custom-dropdown {
  .dropdown-menu {
    top: 100%;
    max-height: 75vh;
    overflow: auto;
  }

  &.dropdown-center {
    .dropdown-menu {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  .dropdown-toggle::after {
    transition: transform 0.2s;
  }

  &.show {
    .dropdown-toggle::after {
      transform: rotateZ(180deg);
    }

  }

  &.dropup {
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-top: 0;
    }
  }
}
</style>
