<template>
  <fieldset v-if="activeCustomFields?.length" class="mt-4 pb-2" style="position: relative;">

    <legend class="mb-3" style="">
      <b-button class="text-primary p-0  float-end ms-2" variant="link" v-tooltip="translations.txtCustomFieldsView"
        @click="setGlobalAction({ type: 'customFields', action: { target } })">
        <icon name="wisk-options" :scale="0.8" />
      </b-button>
      <b-button class="text-primary p-0  float-end ms-5" variant="link" v-tooltip="translations.txtCustomFieldAdd"
        @click="setGlobalAction({ type: 'customFieldEdit', action: { target } })">
        <icon name="wisk-plus" :scale="0.8" />
      </b-button>

      {{ translations.txtCustomFields }}
    </legend>

    <b-row style="min-width: 200px;" class="pt-2">
      <b-col cols="12" :md="mdCol" v-for="customField in activeCustomFields" :key="customField.id">
        <genericInput v-bind="customField.inputTypeAttrs" @operation="$emit('operation', $event)" :modelValue="customFieldValues[customField.id]" :guid="customField.id" />
      </b-col>
    </b-row>
  </fieldset>
  <div v-else></div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { valuesToObjectById } from '@/modules/utils'

export default {
  name: 'CustomFieldsRender',
  emits: ['operation'],
  components: {},
  props: {
    target: { type: String, required: true },
    item: { type: Object, required: true },
    mdCol: { type: String, default: '4' }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations', 'customFieldsByTarget']),
    customFieldValues() {
      if (this.item && Array.isArray(this.item.custom_fields)) {
        return valuesToObjectById(this.item.custom_fields.map(z => ({ id: z.id, value: z.value?.value })))
      }
      return {}
    },
    customFields() {
      if (this.target && this.customFieldsByTarget[this.target] && this.customFieldsByTarget[this.target].length) {
        return this.customFieldsByTarget[this.target]
      }
      return []
    },
    activeCustomFields() {
      return this.customFields.filter(f => !f.archived)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  watch: {}
}
</script>

<style lang="scss"></style>
