<template>
  <div v-if="params.node.group || params.node.rowPinned && !params.showInGroup" class="cell-number">
  </div>
  <div v-else class="cell-pop-multiselect" :key="key">
    <div class="fill-width-height" v-if="((items && items.length) || addNewItem || allowAddNewItem) && mounted">
      <v-menu v-if="!cellOverlayVisible" :triggers="[]" :distance="5" :shown="menuOpen" :autoHide="false" class="cell-pop-multiselect dummy-target" popperClass="wisk-select-wrapper">
        <template v-slot:popper>

          <div class="h-100 w-100" style="" :id="'multiselect-' + domId" :class="colIdClass">
            <wiskSelect :label="''" :modelValue="value" :items="items" :multiple="multiple" :multiselectOptions="multiselectOptions" @close="hide" ref="multiselect" :operationEmpty="operationEmpty" :operation="type"
              :addNewItem="addNewItem" :trackBy="trackBy" @change="save" :addNewItemIfMissing="addNewItemIfMissing" :width="width" :callBeforeAddTag="hasCallBeforeAddTag ? callBeforeAddTag : null"
              @operation="onOperation" :allowAddNewItem="allowAddNewItem" :waitChangeEventUntilClosed="waitChangeEventUntilClosed" :tagValidator="tagValidator" />
          </div>

        </template>
      </v-menu>
    </div>

    <div @mousedown="onCellClick" v-show="cellOverlayVisible" class="overlay form-control plain-text-span plain-text"
      :class="[colIdClass, { 'text-nowrap': !!extraText }]" :title="formattedValue" :style="{ cursor: !readonly ? 'pointer' : '', width: (extraButton && `calc(100% - 35px)`) || null }">
      <span> {{ formattedValue }} </span>
    </div>
    <div v-if="!!extraButton" class="cell-pop-multiselect-extra-button">
      <extraButtons v-if="!menuOpen" :extraButtons="[extraButton]" :params="params" />
    </div>
    <div v-if="!!extraText" class="cell-pop-multiselect-extra-text">
      <extraText v-if="!menuOpen" :text="extraText" :params="params" />
    </div>

  </div>
</template>

<script>
import merge from 'lodash.merge'
import { guid, closestParent } from '@/modules/utils'
import extraButtons from './infoHelpers/ExtraButtons'
import extraText from './infoHelpers/ExtraText'

export default {
  name: 'CellPopMultiselect',
  components: { extraButtons, extraText },
  props: {},
  data() {
    return {
      value: null,
      preventClose: false,
      cellOverlayVisible: true,
      menuOpen: false,
      items: [],
      key: '',
      mounted: false
    }
  },
  computed: {
    domId() {
      return 'z' + guid()
    },
    extraButton() {
      return this.params.extraButton
    },
    extraText() {
      if (this.params.getExtraText) {
        return this.params.getExtraText(this.params)
      }

      return this.params.extraText || this.params.value.extraText || ''
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    trackBy() {
      return this.multiselectOptions.trackBy || this.params.trackBy
    },
    computedValue() {
      return this.params.value.input_value
    },
    waitChangeEventUntilClosed() {
      return !!this.params.waitChangeEventUntilClosed
    },
    formattedValue() {
      if (this.multiple) {
        let display = ''
        if (Array.isArray(this.computedValue)) {
          if (this.computedValue.length) {
            if (this.trackBy) {
              this.computedValue.forEach((id, index) => {
                let found = this.items.find(item => item[this.trackBy] === id)
                if (found) {
                  let title = (found && found.title) || found
                  display += title
                  if (index < this.computedValue.length - 1) {
                    display += ', '
                  }
                }
              })
            } else {
              return this.computedValue.join(', ')
            }
          } else {
            return ''
          }
        } else if (typeof this.computedValue === 'object') {
          console.warn('computedValue is an object, expected an array')
          return this.computedValue.title || ''
        } else if (typeof this.computedValue === 'string') {
          console.warn('computedValue is a string, expected an array')
          return this.computedValue
        }

        return display
      }
      return (this.computedValue && this.computedValue.title) || this.computedValue || ''
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    placeholder() {
      return this.params.placeholder
    },
    multiselectOptions() {
      let options = {
        showNoResults: true
      }
      if (this.required) {
        options.allowEmpty = false
        options.deselectLabel = ''
      }
      return merge(options, this.params.multiselectOptions)
    },
    hasCallBeforeAddTag() {
      return typeof this.params.callBeforeAddTag === 'function'
    },
    tagValidator() {
      return this.params.tagValidator
    },
    addNewItemIfMissing() {
      if (this.params.addNewItemIfMissing) {
        let addNewItemIfMissing = { ...this.params.addNewItemIfMissing },
          actionCopy = this.params.addNewItemIfMissing.action

        addNewItemIfMissing.action = (...actionArgs) => {
          if (actionArgs && typeof actionArgs[3] === 'function') {
            let callbackItemInjectedCopy = actionArgs[3],
              newCallback = (...callBackArgs) => {
                this.save(callBackArgs[0])
                callbackItemInjectedCopy(...callBackArgs)

                setTimeout(() => {
                  this.refresh()
                }, 3000)
              }
            actionArgs[3] = newCallback
          }
          actionCopy(...actionArgs)
        }
        return addNewItemIfMissing
      }

      return null
    },
    addNewItem() {
      if (this.params.addNewItem) {
        let addNewItem = { ...this.params.addNewItem },
          actionCopy = this.params.addNewItem.action

        addNewItem.action = (...actionArgs) => {
          this.hide()
          if (actionArgs && typeof actionArgs[3] === 'function') {
            let callbackItemInjectedCopy = actionArgs[3],
              newCallback = (...callBackArgs) => {
                this.save(callBackArgs[0])
                callbackItemInjectedCopy(...callBackArgs)

                setTimeout(() => {
                  this.refresh()
                }, 3000)
              }
            actionArgs[3] = newCallback
          }
          actionCopy(...actionArgs)
        }
        return addNewItem
      }

      return null
    },
    allowAddNewItem() {
      return this.params.allowAddNewItem
    },
    width() {
      return this.params.multiselectWidth || 200
    },
    type() {
      return this.params.type || ''
    },
    operationEmpty() {
      return this.params.operationEmpty || ''
    },
    multiple() {
      return !!this.params.multiple
    },
    id() {
      return this.params.value.id
    },
    readonly() {
      return !!this.params.readonly || !!this.params.value.readonly
    },
    required() {
      return !!this.params.required || !!this.params.value.required
    }
  },
  methods: {
    refresh(row) {
      if (row && row.data && row.data[row.column.colId]) {
        this.value = row.data[row.column.colId]
      }
      this.key = guid()
      this.setItems()
      return false
    },
    getValue() {
      return this.value
    },
    callBeforeAddTag(tag) {
      return new Promise(resolve => {
        if (this.hasCallBeforeAddTag) {
          this.preventClose = true
          this.params.callBeforeAddTag(tag).then(savedTag => {
            resolve(savedTag)
            this.preventClose = false
            if (savedTag && savedTag[this.trackBy] && Array.isArray(this.computedValue)) {
              this.save([...this.computedValue.map(z => ({ [this.trackBy]: z })), savedTag])
            }
          })
        }
      })
    },
    onOperation(operation) {
      if (typeof this.params.onOperation === 'function') {
        this.params.onOperation(operation, this.id)
      }
    },
    extractValue(obj, track) {
      if (typeof obj === 'object' && obj !== null && track in obj) {
        return obj[track]
      }
      return obj
    },
    prepareValue(item) {
      let defaultTrackBy = 'id'

      if (Array.isArray(item)) {
        return item.map(z => this.extractValue(z, this.trackBy || defaultTrackBy))
      }
      return this.extractValue(item, this.trackBy || defaultTrackBy)
    },
    save(item) {
      let preparedValue = this.prepareValue(item),
        defaultTrackBy = 'id',
        previousValue = this.extractValue(this.computedValue, this.trackBy || defaultTrackBy)

      if (!this.params.onOperation) {
        if (typeof this.params.save === 'function') {
          this.params.save(preparedValue, this.id, this.type, this.params.value)
        }

        if (typeof this.params.updateValue === 'function') {
          this.params.updateValue({
            value: preparedValue,
            id: this.id,
            type: this.type,
            data: this.params.value,
            previousValue
          })
        }
      }

      this.value = preparedValue
      this.hide()
    },
    customLabel(item) {
      if (item && item.title !== undefined) {
        return item.title
      }
      return item
    },
    onCellClick() {
      if (!this.readonly) {
        if (this.agCell) {
          this.agCell.classList && this.agCell.classList.remove && this.agCell.classList.remove('ag-cell-not-inline-editing')
          this.agCell.classList && this.agCell.classList.add && this.agCell.classList.add('ag-cell-inline-editing')
        }

        this.cellOverlayVisible = false
        this.show()

        setTimeout(() => {
          this.$refs.multiselect && this.$refs.multiselect.activate() //TODO: change this when when upgrading to multiselect version 3
        }, 100)
      }
    },
    show() {
      if (!this.menuOpen) {
        document.addEventListener('mousedown', this.hide, true)
      }
      this.menuOpen = !this.menuOpen
    },
    triggerMultiselectClose() {
      if (this.$refs.multiselect) {
        try {
          this.$refs.multiselect && this.$refs.multiselect.deactivate()
        } catch (error) {
          console.log('error', error)
        }
      }

      this.params.api.stopEditing()
    },
    hide(event) {
      let stop = (event?.target && closestParent(event.target, `#multiselect-${this.domId}`)) || this.preventClose

      if (!stop) {
        this.triggerMultiselectClose()
        document.removeEventListener('mousedown', this.hide, true)

        setTimeout(() => {
          this.menuOpen = false
          this.cellOverlayVisible = true

          try {
            this.params.api && this.params.api.stopEditing()
          } catch (error) { }
        }, 0)
      }
    },
    onScroll() {
      if (this.menuOpen) {
        this.hide()
      }
    },
    setItems() {
      this.items = this.params.value.items || (this.params.getItems && this.params.getItems(this.params.value)) || []
    }
  },
  mounted() {
    if (this.computedValue) {
      this.value = this.prepareValue(this.computedValue)
    }

    this.mounted = true
    this.setItems()

    if (this.params.autoOpen) {
      this.onCellClick()
    }

    this.params.api && this.params.api.addEventListener('bodyScroll', this.onScroll)
  },
  beforeUnmount() {
    this.params.api && this.params.api.removeEventListener('bodyScroll', this.onScroll)
    document.removeEventListener('mousedown', this.hide, true)
    this.triggerMultiselectClose()
  },
  watch: {}
}
</script>

<style lang="scss">
.wisk-select-wrapper {
  // min-width: 300px;
  // min-height: 450px;
  // overflow: hidden;

  .v-popper__inner {
    overflow-y: unset;
  }

  .v-popper__arrow-container {
    display: none;
  }
}

.cell-pop-multiselect {
  width: 100%;
  height: 100%;

  .v-popper__arrow-container {
    display: none !important;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      background-color: rgba(#63c2de, 0.1);
    }
  }

  .cell-pop-multiselect-extra-button {
    position: absolute;
    top: 0;
    width: 35px;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .cell-pop-multiselect-extra-text {
    pointer-events: none;
  }

  .dummy-target {
    width: 1px;
    height: 1px;
    position: absolute;
    background-color: transparent;
    top: -2px;
    //left: 50%;
    right: -20px;
    //bottom: 0;
    pointer-events: none;
    position: absolute;
    //outline: 1px solid purple;
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .fill-width-height {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
  }
}

.ag-theme-balham .ag-cell-inline-editing {
  height: inherit;
}
</style>
