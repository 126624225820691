<template>
  <svg :class="options.class" :style="options.style" v-bind="options.attrs">
    <g v-if="icon" v-bind="options.g">
      <path v-for="(attrs, index) in icon.paths" :key="index" v-bind="attrs" />
    </g>
  </svg>
</template>
<script>
import icons from '@/modules/icons'

export default {
  name: 'WiskIcon',
  props: {
    name: {
      type: String,
      validator(val) {
        if (val && !(val in icons)) {
          console.warn(`Invalid prop: prop "name" is referring to an unregistered icon "${val}".\nPlease make sure you have imported this icon before using it.`, this)
          return false
        }
        return true
      }
    },
    title: String,
    scale: [Number, String],
    spin: Boolean,
    inverse: Boolean,
    pulse: Boolean,
    flip: {
      validator(val) {
        return val === 'horizontal' || val === 'vertical' || val === 'both'
      }
    },
    label: String,
    tabindex: [Number, String]
  },
  data() {
    return {
      paths: [],
      x: false,
      y: false
    }
  },
  computed: {
    icon() {
      if (this.name) {
        return icons[this.name]
      }
      return null
    },
    classes() {
      let classes = {
        'fa-icon': true,
        'fa-spin': this.spin,
        'fa-flip-horizontal': this.flip === 'horizontal',
        'fa-flip-vertical': this.flip === 'vertical',
        'fa-flip-both': this.flip === 'both',
        'fa-inverse': this.inverse,
        'fa-pulse': this.pulse
      }
      return classes
    },
    normalizedScale() {
      return typeof this.scale === 'undefined' ? 1 : Math.abs(Number(this.scale))
    },
    box() {
      if (this.icon) {
        return `0 0 ${this.icon.width} ${this.icon.height}`
      }
      return `0 0 ${this.width} ${this.height}`
    },
    ratio() {
      if (this.icon) {
        let { width, height } = this.icon
        return Math.max(width, height) / 16
      }
      return 1
    },
    width() {
      return (this.icon && (this.icon.width / this.ratio) * this.normalizedScale) || 0
    },
    height() {
      return (this.icon && (this.icon.height / this.ratio) * this.normalizedScale) || 0
    },
    style() {
      if (this.normalizedScale === 1) {
        return {}
      }
      return {
        fontSize: this.normalizedScale + 'em'
      }
    },
    focusable() {
      let { tabindex } = this
      //TODO format this to not need == null
      // eslint-disable-next-line eqeqeq
      if (tabindex == null) {
        return 'false'
      }
      let index = typeof tabindex === 'string' ? parseInt(tabindex, 10) : tabindex
      if (index >= 0) {
        return null
      }
      return 'false'
    },
    options() {
      return {
        style: this.style,
        class: this.classes,
        g: this.icon.g,
        attrs: {
          role: this.$attrs.role || (this.label || this.title ? 'img' : null),
          'aria-label': this.label || null,
          'aria-hidden': !(this.label || this.title),
          tabindex: this.tabindex,
          ...(this.x ? { x: this.x } : {}),
          ...(this.y ? { y: this.y } : {}),
          width: this.width,
          height: this.height,
          viewBox: this.box,
          focusable: this.focusable,
          'data-icon': this.name,
          ...this.$attrs
        }
      }
    }
  }
}
</script>

<style>
.fa-icon {
  display: inline-block;
  fill: currentColor;
  overflow: visible;
  vertical-align: -0.125em;
}

.fa-icon>g {
  transform-origin: 50% 50%;
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both {
  transform: scale(-1, -1);
}

.fa-spin>g {
  animation: fa-spin 1s 0s infinite linear;
}

.fa-pulse>g {
  animation: fa-spin 1s infinite steps(8);
}

.fa-inverse {
  color: #fff;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
