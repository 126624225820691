<template>
  <gateKeeper feature="custom_roles" dontBlockJustNag class="mb-4" ref="gateKeeperCustomRoles">
    <wiskGrid :rowData="rolesPermissionsFlat" :gridStyle="gridStyle" :loadingOverlay="loading" v-if="user && options"
      :columnDefs="gridColumns" :gridOptions="options" :header="{}" parentGridName="RolesPermissions">

      <template v-slot:additional-header-controls>
        <wiskInput inputType="checkbox" v-if="archivedRolesPresent" v-model="showArchived" :label="translations.txtServingSizesShowArchived" class="me-3" />

        <div v-if="currentPermissionsByType.role_manage">
          <gateKeeper feature="custom_roles" reactOnClickEvent>
            <b-button class="" style="" variant="primary" v-tooltip="translations.txtVenuesNewRole" @click="addRole" size="sm">
              <span> {{ translations.txtVenuesNewRole }} </span>
            </b-button>
          </gateKeeper>
        </div>
      </template>

    </wiskGrid>

    <confirm ref="confirmDialog" />
    <confirm ref="confirmDialogAddRole" autofocus :validations="addRoleValidations" />
    <confirm ref="confirmDialogRenameRole" autofocus :validations="renamePromptValidations" />

    <wiskModal v-model="retryArchiveVisible" size="lg" hideHeaderExtra :title="translations.txtGenericErrors"
      :okText="translations.txtGenericRetry" @ok="retryArchive">

      <roleArchiveErrorHandler :userIds="userIdsBlockingArchive" />
    </wiskModal>
  </gateKeeper>
</template>

<script>
import { markRaw } from 'vue'
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import api from '@/api'
import { sortObject, fromJSON } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import wiskGrid from '@/components/grids/WiskGrid'
import roleArchiveErrorHandler from '@/components/users/RoleArchiveErrorHandler'

export default {
  name: 'RolesPermissions',
  components: { wiskGrid, roleArchiveErrorHandler },
  props: {
    gridStyle: { type: Object, default: () => ({ height: 'calc(100vh - 180px)' }) }
  },
  data() {
    return {
      renamePromptValidations: [],
      retryArchiveVisible: false,
      retryArchiveRoleId: null,
      userIdsBlockingArchive: [],
      loading: false,
      rolesChanged: false,
      selectedRoleId: null,
      options: null,
      showArchived: false,
      multiselectOptions: { groupLabel: 'groupTitle', groupValues: 'values' }
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'roles', 'rolesById', 'permissionsById', 'permissions', 'venue', 'currentPermissionsByType']),
    usersBlockingArchiveColumns() {
      return []
    },
    archivedRolesPresent() {
      return this.roles.some(r => r.archived)
    },
    rolesPermissionsFlat() {
      let arr = [],
        permissions = this.permissions

      for (let i = 0; i < this.roles.length; i++) {
        let role = this.roles[i]

        for (let j = 0; j < permissions.length; j++) {
          let permission = merge({}, permissions[j]),
            enabled = !!role.permissions.find(p => p === permission.id)

          if ((!role.is_wisk_role || this.user.is_wisk_user || this.user.god_mode) && (!role.archived || this.showArchived)) {
            arr.push(markRaw({
              id: `${role.id}_${permission.id}`,
              roleId: role.id,
              roleTitle: role.title,
              permissionId: permission.id,
              permissionGroup: permission.group,
              permissionTitle: permission.title,
              enabled
            }))
          }
        }
      }

      return arr
    },
    addRoleValidations() {
      return [
        {
          message: this.translations.txtVenuesAddRoleValidation,
          validator: value => !this.roles.find(r => r.title.trim().toLowerCase() === value.trim().toLowerCase())
        }
      ]
    },
    gridColumns() {
      return [
        {
          headerName: this.translations.txtGenericRole,
          colId: 'role',
          hide: true,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          minWidth: 220,
          keyCreator: params => JSON.stringify(sortObject(params.value)),
          valueGetter: params => ({ title: params?.data?.roleTitle || 'id missing!!!', id: params?.data?.roleId })
        },
        {
          colId: 'permission',
          headerName: this.translations.txtGenericPermission,
          minWidth: 620,
          ...agGridFilters.text,
          valueGetter: params => params.data?.permissionTitle || ''
        },
        {
          headerName: this.translations.txtGenericEnabled,
          colId: 'permissionEnabled',
          cellRenderer: 'CellCheckbox',
          minWidth: 50,
          maxWidth: 150,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            useValueFormatter: true,
            save: (value, id, type, data) => {
              api.updateRole(id, { value: data.permissionId, type: value ? 'add_permission' : 'delete_permission' }).then(() => {
                this.rolesChanged = true
              })
            }
          },
          valueGetter: params => ({
            readonly: !this.getRoleEditable(params?.data?.roleId) || !this.currentPermissionsByType.role_manage,
            id: get(params, 'data.roleId'),
            tooltip: get(params, 'data.enabled') ? this.translations.txtGenericEnabled : this.translations.txtGenericDisabled,
            permissionId: get(params, 'data.permissionId'),
            input_value: get(params, 'data.enabled')
          })
        },
        {
          colId: 'permissionGroup',
          headerName: this.translations.txtGenericType,
          minWidth: 220,
          hide: true,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          keyCreator: params => JSON.stringify(sortObject(params.value)),
          valueGetter: params => {
            let group = get(params, 'data.permissionGroup', '')
            return { id: params?.data?.roleId, sort: group, group, title: group }
          }
        },
        {
          colId: 'dummy',
          headerName: '',
          minWidth: 220,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          valueGetter: () => ''
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'loadVenuesHighLevel']),
    getRoleEditable(id) {
      let role = this.rolesById[id]
      return !!role?.editable
    },
    getCustomAllChildrenCount(params) {
      let data = params.value || {},
        id = data.id || 0,
        group = data.group,
        role = this.rolesById[id],
        count = 0,
        total = group ? this.permissions.filter(p => p.group === group).length : this.permissions.length
      if (role) {
        if (group) {
          for (let i = 0; i < role.permissions.length; i++) {
            let permission = this.permissionsById[role.permissions[i]] || {}
            if (permission.group === group) {
              count++
            }
          }
        } else {
          count = role.permissions.length
        }
      }
      return `${count} of ${total}`
    },
    addRole(seedRoleId) {
      if (this.currentPermissionsByType.role_manage && this.$refs.confirmDialogAddRole) {
        this.$refs.confirmDialogAddRole.prompt({
          callback: text => {
            let operations = [{ value: text, type: 'title' }]

            this.loading = true

            if (seedRoleId) {
              let role = this.rolesById[seedRoleId]
              if (role && role.permissions) {
                role.permissions.forEach(permissionId => {
                  operations.push({ value: permissionId, type: 'add_permission' })
                })
              }
            }

            api.updateRole(0, operations).finally(() => {
              this.rolesChanged = true
              this.loading = false
            })
          },
          label: this.translations.txtGenericRole,
          title: this.translations.txtVenuesNewRole,
          required: true
        })
      }
    },
    renameRole(id) {
      let role = this.rolesById[id]
      this.renamePromptValidations = [
        {
          message: this.translations.txtRoleRenameValidationMessage,
          validator: value => role.title === value || !this.roles.find(v => v.title.trim().toLowerCase() === value.trim().toLowerCase())
        }
      ]

      if (role && this.currentPermissionsByType.role_manage && this.$refs.confirmDialogRenameRole) {
        this.$refs.confirmDialogRenameRole.prompt({
          callback: text => {
            let operations = [{ value: text, type: 'title' }]

            this.loading = true

            if (text !== role.title) {
              api.updateRole(id, operations).finally(() => {
                this.rolesChanged = true
                this.loading = false
              })
            }
          },
          text: role.title,
          label: this.translations.translate('tplRoleRenameValidationLabel', { '{a}': role.title }),
          title: this.translations.txtGenericRename,
          required: true
        })
      }
    },
    toggleArchived(id) {
      let role = this.rolesById[id],
        archived = role.archived

      if (role && this.currentPermissionsByType.role_manage && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            let operation = { value: !archived, type: 'archive' }

            this.loading = true
            api.updateRole(id, operation)
              .then(() => {
                this.retryArchiveRoleId = null
                this.rolesChanged = true
              })
              .catch(error => {
                this.userIdsBlockingArchive = error?.data?.info?.used_by
                this.retryArchiveVisible = !!this.userIdsBlockingArchive.length
                this.retryArchiveRoleId = id
              }).finally(() => {
                this.loading = false
              })
          },
          autoConfirm: this.retryArchiveRoleId === id,
          message: archived ? this.translations.confirmRoleRestoreText : this.translations.confirmRoleArchiveText,
          title: archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    retryArchive() {
      this.retryArchiveVisible = false

      if (this.retryArchiveRoleId) {
        this.toggleArchived(this.retryArchiveRoleId)
      }
    }
  },
  mounted() {
    let customRolesEnabled = get(this.$refs, 'gateKeeperCustomRoles.currentFeature.enabled', false)

    this.options = {
      getRowHeight: params => (params.data && (!params.data.id || params.data.wiskRowHidden) && 1) || 45,
      getRowClass: params => {
        let data = fromJSON(params?.node?.key) || {},
          archived = data.archived

        return archived ? 'danger-row' : ''
      },
      headerHeight: 30,
      autoGroupColumnDef: {
        cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
        cellRendererParams: {
          getCustomAllChildrenCount: this.getCustomAllChildrenCount,
          wiskGroupActions: [
            {
              action: this.addRole,
              available: (id, params) => {
                let data = fromJSON(params.value) || {},
                  available = !data.group && customRolesEnabled
                return available && this.currentPermissionsByType.role_manage
              },
              info: this.translations.txtVenuesNewRoleFromThisInfo,
              title: this.translations.txtVenuesNewRoleFromThis,
              variant: 'primary'
            },
            {
              available: (id, params) => {
                let role = this.rolesById[id],
                  data = fromJSON(params.value) || {},
                  available = !data.group && !role.is_wisk_role && role.editable && this.currentPermissionsByType.role_manage

                return available
              },
              title: this.translations.txtGenericEdit,
              dropdownPlacement: 'bottom-start',
              children: [
                {
                  title: this.translations.txtGenericRename,
                  action: this.renameRole,
                  variant: 'primary',
                  icon: 'wisk-edit'
                },
                { key: 40, isDivider: true },
                {
                  title: this.translations.txtGenericArchive,
                  action: this.toggleArchived,
                  variant: 'danger',
                  checkVisibility: (id) => {
                    let role = this.rolesById[id]

                    return !role.archived
                  },
                  icon: 'wisk-archive'
                },
                {
                  title: this.translations.txtGenericRestore,
                  action: this.toggleArchived,
                  variant: 'success',
                  checkVisibility: (id) => {
                    let role = this.rolesById[id]

                    return role.archived
                  },
                  icon: 'wisk-history'
                }
              ],
              variant: 'link'
            }
          ]
        }
      }
    }
  },
  beforeUnmount() {
    if (this.rolesChanged) {
      this.loadVenuesHighLevel()
    }
  }
}
</script>
