export default {
  name: 'wisk-slash',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.97,34.381a2.762,2.762,0,0,1-1.978.793,2.8,2.8,0,0,1-1.974-.84l-8.792-9,.016-.016-7.7-7.87L10.49,14.336.971,4.588A2.807,2.807,0,0,1,4.987.666l9.6,9.845-.016.016,6.9,7.012L25.432,21.6l8.585,8.808A2.791,2.791,0,0,1,33.97,34.381Z'
    }
  ]
}
