<template>
  <table :class="classList">
    <thead v-if="cols.length">
      <tr>
        <th scope="col" v-for="col in cols" :key="col.key">
          <slot :name="`head(${col.key})`">
            {{ col.label }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <td v-for="col in cols" :key="col.key">
          <slot :name="`cell(${col.key})`" v-bind="item">
            {{ item[col.key] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BTable',
  props: {
    striped: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    items: { type: Array, required: true },
    fields: { type: Array }
  },
  computed: {
    cols() {
      if (Array.isArray(this.fields)) return this.fields
      if (this.items.length) {
        return Object.keys(this.items[0])
          .filter(key => key !== 'id')
          .map(key => ({ key, label: key.charAt(0).toUpperCase() + key.substr(1) }))
      }
      return []
    },
    classList() {
      const classList = ['table']
      if (this.striped) classList.push('table-striped')
      if (this.hover) classList.push('table-hover')
      return classList
    }
  },
  methods: {
    returnStackedStatus() {
      if (this.stacked === true) {
        return 'b-table-stacked'
      }
      return `b-table-stacked-${this.stacked}`
    }
  }
}
</script>
