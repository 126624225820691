<template>
  <div id="wisk-draft-invoice-region-highlighter-container" ref="container" :key="refreshKey">
    <div class="region-highlighter-component" id="wisk-draft-invoice-region-highlighter" :style="lightBoxStyle" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getPDFRegionPosition } from '@/modules/utils'

export default {
  name: 'RegionHighlighter',
  components: {},
  emits: ['click'],
  props: {
    rotation: Number,
    originalDocumentSize: { type: Object, validator: z => z && z.height && z.width },
    highlightBox: { type: Object, validator: z => z && z.xmin && z.xmax && z.ymin && z.ymax },
    refreshKey: Number
  },
  data() {
    return {
      containerWidth: 0,
      containerHeight: 0,
      scale: 0,
      lightBoxStyle: {}
    }
  },
  computed: {
    ...mapState(['translations', 'windowResizeKey'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    init() {
      let container = this.$refs.container

      if (container) {
        let box = container.getBoundingClientRect(),
          rotated = this.rotation === 90 || this.rotation === 270,
          containerWidth = box.width,
          containerHeight = box.height,
          originalDocumentHeight = rotated ? this.originalDocumentSize.width : this.originalDocumentSize.height,
          originalDocumentWidth = rotated ? this.originalDocumentSize.height : this.originalDocumentSize.width

        this.scale = (originalDocumentHeight / containerHeight + originalDocumentWidth / containerWidth) / 2
        this.lightBoxStyle = getPDFRegionPosition(this.highlightBox, this.scale, this.rotation)
        this.lightBoxStyle.boxShadow = '0 0 0 3px rgb(0 114 236 / 70%)'
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 0)
  },
  watch: {
    windowResizeKey: 'init',
    rotation: 'init',
    highlightBox: 'init',
    originalDocumentSize: 'init'
  }
}
</script>

<style lang="scss">
#wisk-draft-invoice-region-highlighter-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  .region-highlighter-component {
    position: absolute;
    z-index: 99999;
    pointer-events: none;
  }

  #wisk-draft-invoice-region-highlighter {
    background-color: transparent;
    pointer-events: none;
  }
}
</style>
