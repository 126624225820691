<template>
  <component :is="editAction ? 'wiskModal' : 'b-container'" fluid :visible="!!editAction" @hide="setGlobalAction({ type: 'taxes', action: null })" size="lg" :title="translations.txtTaxes" hideFooter>
    <wiskGrid :rowData="rowData" :columnDefs="columns" resetRows parentGridName="Taxes" :gridStyle="{ height: '600px' }"
      :excel="{ fileName: 'Taxes' }" :header="{ hideSearch: true }" v-bind="$attrs">
      <template v-slot:additional-header-controls>
        <wiskInput infoTooltipKey="fe824d85-29f5-4993-94b7-fe13ca249d34" :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" />
        <b-button size="sm" @click="add" variant="primary">
          <!-- v-if="currentPermissionsByType.tax permission missing" -->
          {{ translations.txtTaxesNew }}
        </b-button>
      </template>

    </wiskGrid>
  </component>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { percentageFormat } from '@/modules/utils'

export default {
  name: 'Taxes',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      showArchived: false

    }
  },
  computed: {
    ...mapState(['translations', 'currentPermissionsByType', 'taxes']),
    ...mapGetters([]),
    rowData() {
      if (this.showArchived) {
        return this.taxes
      }
      return this.taxes.filter(a => a && !a.archived)
    },
    columns() {
      return [
        {
          headerName: this.translations.txtTaxesTitle,
          sortOrder: 1000,
          colId: 'taxTitle',
          field: 'title'
        },
        {
          headerName: this.translations.txtTaxesRateComputed,
          sortOrder: 3000,
          colId: 'taxRateComputed',
          field: 'calculated_tax_rate',
          valueFormatter: params => percentageFormat(params.value, 4),
          cellRendererParams: { useValueFormatter: true }
        },
        {
          colId: 'more',
          sortOrder: 2700,
          headerName: this.translations.columnDetails,
          minWidth: 100,
          width: 100,
          remove: !this.currentPermissionsByType.tax_rate_manage,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true,
            onClick: id => {
              this.setGlobalAction({ type: 'taxEdit', action: { id } })
            }
          },
          valueGetter: params => ({
            id: (params.data && params.data.id) || 0,
            group: !!params.node.group
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    add() {
      this.setGlobalAction({ type: 'taxEdit', action: { id: 0 } })
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss"></style>
