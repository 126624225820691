export default {
  name: 'wisk-caret-down',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M7.3227,30.5H27.6773a7.163,7.163,0,0,0,5.03-2.0165A7.2679,7.2679,0,0,0,35,23.1775V11.8012A7.35,7.35,0,0,0,27.6773,4.5H7.3227A7.3054,7.3054,0,0,0,0,11.8012V23.1775A7.3236,7.3236,0,0,0,7.3227,30.5Zm3.7356-16.7465a1.2537,1.2537,0,0,1,.955-.4032,1.4305,1.4305,0,0,1,.9976.4032l4.5,4.5634,4.5-4.5634a1.3657,1.3657,0,0,1,1.9314,1.9315l-5.4547,5.54a1.423,1.423,0,0,1-1.974,0l-5.476-5.54A1.3514,1.3514,0,0,1,11.0583,13.7538Z'
    }
  ]
}
