export default {
  name: 'wisk-caret-up',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M27.677,4.5H7.323A7.167,7.167,0,0,0,2.292,6.516,7.268,7.268,0,0,0,0,11.822V23.2a7.348,7.348,0,0,0,7.323,7.3H27.677A7.305,7.305,0,0,0,35,23.2V11.822A7.323,7.323,0,0,0,27.677,4.5ZM23.942,21.246a1.255,1.255,0,0,1-.955.4,1.431,1.431,0,0,1-1-.4l-4.5-4.563-4.5,4.563a1.366,1.366,0,0,1-1.932-1.931l5.455-5.54a1.422,1.422,0,0,1,1.974,0l5.476,5.54A1.35,1.35,0,0,1,23.942,21.246Z'
    }
  ]
}
