export default {
  name: 'wisk-chat',
  width: 35,
  height: 29.4193,
  paths: [
    {
      d: 'M34.2747,4.2309a3.6241,3.6241,0,0,0-3.0031-1.44H10.2051a3.6943,3.6943,0,0,0-2.8258,1.13A3.4809,3.4809,0,0,0,6.393,6.414c.0111.5541,0,1.1082,0,1.6733-.7979,0-1.5626,0-2.3161.0111a6.0178,6.0178,0,0,0-1.2633.1A3.5192,3.5192,0,0,0,.01,11.7553v7.9015c0,.975-.0333,1.95.0222,2.9144a3.3738,3.3738,0,0,0,1.6512,2.7593,3.8137,3.8137,0,0,0,2.0944.5652v5.3748c0,.4875.1662.7867.543.8975a.8573.8573,0,0,0,.9641-.3435c1.6179-1.8838,3.247-3.7457,4.865-5.63a.7909.7909,0,0,1,.6759-.31c4.6433.0111,9.2866-.0111,13.9409.0111A3.6465,3.6465,0,0,0,28.59,22.0836c-.0332-.4875,0-.9863,0-1.5293h.399c.8643-.0111,1.74.0222,2.6152-.0111a3.553,3.553,0,0,0,3.3911-3.4907c.0111-3.5794,0-7.1588,0-10.7493A3.4315,3.4315,0,0,0,34.2747,4.2309ZM25.1766,24.2778c-.1551.0111-.31.0111-.4654.0111-4.7763,0-9.5525.0111-14.34,0a1.3791,1.3791,0,0,0-1.13.51C8.1,26.1505,6.9249,27.48,5.7613,28.8214c-.1.1218-.2105.2327-.31.3546a.16.16,0,0,0-.0775-.0222V25.2419a.8375.8375,0,0,0-.942-.953c-.2992,0-.5984.0111-.8976,0a1.9893,1.9893,0,0,1-1.9393-2.0281c0-3.5128-.0111-7.0367,0-10.55A1.9837,1.9837,0,0,1,3.4009,9.7054c.1219-.0111.2549-.0111.3768-.0111q10.5056,0,21.0332-.0111a1.9472,1.9472,0,0,1,1.8839.975,2.3166,2.3166,0,0,1,.2992,1.0085c.0111,3.5463.0111,7.1035.0111,10.65A1.97,1.97,0,0,1,25.1766,24.2778Zm6.4607-5.3525c-.9974.0443-2.0058.011-3.0475.011V11.7775A3.5571,3.5571,0,0,0,25.7639,8.187,4.8748,4.8748,0,0,0,24.7,8.0984q-8.1118-.0166-16.2459-.0111H8.0109a18.7723,18.7723,0,0,1,.0222-2.0944A1.9564,1.9564,0,0,1,9.784,4.3972c.133-.0111.266,0,.399,0,7.0147-.0111,14.0295,0,21.0442-.0111A1.9851,1.9851,0,0,1,33.1555,5.45a1.7932,1.7932,0,0,1,.2327.8533q.0166,5.3691.0111,10.716A1.9109,1.9109,0,0,1,31.6373,18.9253Z'
    },
    { d: 'M20.7437,15.0391a2.1836,2.1836,0,1,1-2.22,2.2079A2.2128,2.2128,0,0,1,20.7437,15.0391Zm-.0046,1.5949a.6006.6006,0,0,0-.6134.61.608.608,0,0,0,.6606.562.588.588,0,1,0-.0472-1.1722Z' },
    { d: 'M7.43,15.04A2.1826,2.1826,0,1,1,5.1959,17.235,2.2145,2.2145,0,0,1,7.43,15.04Zm0,2.7659a.5862.5862,0,1,0,.0189-1.17.5876.5876,0,1,0-.0189,1.17Z' },
    { d: 'M16.3209,17.2418a2.2209,2.2209,0,0,1-4.4409-.0434,2.2209,2.2209,0,0,1,4.4409.0434Zm-2.2308.5635a.5855.5855,0,1,0-.6149-.6031A.5833.5833,0,0,0,14.09,17.8053Z' }
  ]
}
