export default {
  name: 'wisk-lock',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M26.643,14.267a11.568,11.568,0,0,1,1.21.006,3.19,3.19,0,0,1,2.983,3.362c.024,2.795.005,5.59,0,8.385q0,2.784,0,5.569a3.218,3.218,0,0,1-1.847,3.07,3.3,3.3,0,0,1-1.554.321q-3.634.011-7.269.013-4.929.006-9.857,0c-1.058,0-2.118.017-3.173-.04a3.2,3.2,0,0,1-2.983-3.294q0-4.112,0-8.222,0-2.9,0-5.8a3.234,3.234,0,0,1,2.473-3.282,8.486,8.486,0,0,1,1.109-.152c0-.633-.008-1.284,0-1.935a39.266,39.266,0,0,1,.09-3.99A8.986,8.986,0,0,1,12.2,1.441,9.452,9.452,0,0,1,26.63,9.623c-.011,1.452,0,2.9,0,4.357C26.627,14.054,26.635,14.129,26.643,14.267Zm-15.425-.043h11.92c.006-.082.012-.136.012-.189,0-1.539.02-3.078,0-4.616A5.963,5.963,0,0,0,11.228,9.17c-.037,1.046-.009,2.1-.01,3.142Z'
    }
  ]
}
