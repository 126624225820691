export default {
  name: 'wisk-excel',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M8.8,2.9C8,2.9,7.4,3.6,7.4,4.4v26.2c0,0.8,0.6,1.5,1.4,1.5h17.4c0.8,0,1.4-0.7,1.4-1.5v-16h-9.4 c-1.2,0-2.2-1-2.2-2.2V2.9H8.8z M18.9,5l6.6,6.7h-6.6V5z M4.5,4.4C4.5,2,6.4,0,8.8,0c0,0,0,0,0,0h9.3l12.4,12.5v18.1 c0,2.4-1.9,4.4-4.3,4.4c0,0,0,0,0,0H8.8c-2.4,0-4.4-2-4.3-4.4c0,0,0,0,0,0L4.5,4.4z'
    },
    { d: 'M18.7,23.8l4.4,5.8h-3l-2.8-4l-2.9,4h-2.9l4.4-5.8l-4.3-5.7h2.9l2.8,3.8l2.8-3.8H23L18.7,23.8z' }
  ]
}
