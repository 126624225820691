export default {
  name: 'wisk-notifications',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M19.8,2.2c0,0.1,0,0.1,0,0.2c6.4,1,8.8,6.6,8.8,13.3c0,3,0.4,7.5,1.6,9.6l1.1,2c0.3,0.7,0.1,1.5-0.6,1.8 c-0.2,0.1-0.5,0.2-0.7,0.1H5.1c-0.7,0.1-1.4-0.5-1.5-1.3c0-0.2,0-0.5,0.1-0.7l1.1-2c1.7-3,1.6-4.6,1.6-7.6c0-0.6,0-1.3,0-2 C6.4,9,8.8,3.5,15.2,2.4c0-0.1,0-0.1,0-0.2C15.2,1,16.3,0,17.6,0C18.8,0,19.8,1,19.8,2.2z M15.7,5.6c3.1-0.6,6.6,0.3,8.3,3.1 c1.1,2.2,1.6,4.6,1.5,7.1c0,1.9,0.1,3.7,0.4,5.6c0.2,1.6,0.6,3.2,1.2,4.7H8c0.6-1.2,1.1-2.6,1.3-4c0.2-1.4,0.3-2.8,0.2-4.2v-0.1 c0-0.6,0-1.2,0-2c-0.1-2.5,0.4-4.9,1.5-7.1c0.9-1.4,2.3-2.5,3.9-2.9C15.2,5.7,15.4,5.6,15.7,5.6z'
    },
    { d: 'M17.5,35c2-0.1,3.5-1.7,3.4-3.7c0-0.3,0-0.5-0.1-0.8h-6.7c-0.1,0.3-0.1,0.5-0.1,0.8C14,33.3,15.5,34.9,17.5,35z' }
  ]
}
