export default {
  name: 'wisk-account',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M13.9,21.2c4,0,7,1.1,9,3.4c1.1,1.3,1.7,2.8,2,4.1h-0.3h-3.2H4.7H2.9c0.2-1.1,0.7-2.6,2-4.1c0.4-0.4,0.8-0.8,1.3-1.2 C8.1,21.9,10.7,21.2,13.9,21.2 M13.9,18.4c-4.1,0-7.2,1.1-9.3,2.8c-0.6,0.5-1.2,1-1.7,1.5c-2,2.3-2.8,5.1-2.8,7C0,29.9,0,30,0,30 c0,0.7,0.6,1.3,1.3,1.3c0.7,0,0.3,0,0.9,0c1.1,0,0.5,0,2.6,0h16.6c2.7,0,1.6,0,3.2,0h1.9c0.7,0,1.3-0.6,1.3-1.3 c0-2-0.7-4.9-2.8-7.3C22.7,20.3,19.2,18.4,13.9,18.4L13.9,18.4z'
    },
    {
      d: 'M13.9,6.4c2.1,0,3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7C10.1,8.1,11.8,6.4,13.9,6.4 M13.9,3.7 c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5C20.3,6.6,17.4,3.7,13.9,3.7L13.9,3.7z'
    },
    {
      d: 'M34.1,15.3c0.4-0.2,1.1-0.7,0.9-1.6c-0.2-0.5-0.4-1.1-0.7-1.5c-0.5-0.8-1.4-0.7-1.8-0.5 c0.2-0.4,0.3-1.3-0.5-1.7c-0.5-0.3-1-0.5-1.6-0.6c-0.9-0.2-1.5,0.4-1.6,0.8c-0.2-0.4-0.8-1.1-1.6-0.8c-0.6,0.1-1.1,0.4-1.6,0.6 c-0.8,0.4-0.7,1.3-0.5,1.7c-0.4-0.2-1.3-0.3-1.8,0.5c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.8,0.4,1.4,0.9,1.6c-0.4,0.2-1.1,0.7-0.9,1.6 c0.1,0.4,0.2,0.7,0.4,1c0.2,0.5,0.7,0.7,1.1,0.7c0.4,0,0.7-0.1,1-0.2c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.8,0.6,1.1 c0.6,0.4,1.2,0.7,1.9,0.8c0.9,0.2,1.5-0.4,1.6-0.8c0.2,0.4,0.8,1,1.6,0.8c0.7-0.2,1.4-0.5,1.9-0.8c0.5-0.3,0.6-0.8,0.6-1.1 c0-0.3-0.2-0.7-0.4-0.9c0.3,0.2,0.7,0.3,1,0.2c0.4,0,0.8-0.2,1.1-0.7c0.2-0.3,0.3-0.7,0.4-1C35.2,16,34.5,15.5,34.1,15.3z  M33.7,16.3c0.1,0.1,0.3,0.2,0.2,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.4-0.2-0.6-0.2 c-0.6,0-1.1,0.5-1.1,1c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.2,0.4,0,0.5c-0.5,0.3-1,0.6-1.6,0.7c-0.2,0-0.3-0.1-0.4-0.2 c-0.2-0.4-0.6-0.7-1-0.7c-0.4,0-0.8,0.3-1,0.7c-0.1,0.1-0.2,0.3-0.4,0.2c-0.6-0.1-1.1-0.4-1.6-0.7c-0.2-0.1-0.1-0.4,0-0.5 c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.5-1-1.1-1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.5-0.1c-0.1-0.3-0.2-0.6-0.3-0.9 c0-0.1,0.1-0.3,0.2-0.3c0.4-0.2,0.7-0.5,0.7-1c0-0.4-0.3-0.8-0.7-0.9c-0.1-0.1-0.3-0.2-0.2-0.3c0.1-0.4,0.3-0.9,0.6-1.3 c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.5,1.1-1c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.3,0.1-0.4 c0.4-0.2,0.9-0.4,1.3-0.5c0.2,0,0.3,0.1,0.4,0.2c0.2,0.4,0.6,0.6,1,0.6c0.5,0,0.9-0.3,1-0.6c0.1-0.1,0.2-0.3,0.4-0.2 c0.5,0.1,0.9,0.3,1.3,0.5c0.1,0.1,0.2,0.3,0.1,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.6,0.5,1,1.1,1c0.2,0,0.3,0,0.5-0.1 c0.2-0.1,0.3,0,0.4,0.1c0.3,0.4,0.4,0.8,0.6,1.3c0,0.1-0.1,0.3-0.2,0.3c-0.4,0.1-0.7,0.5-0.7,0.9C33,15.7,33.3,16.1,33.7,16.3z'
    },
    { d: 'M28.7,12.2c-1.8,0-3.2,1.4-3.2,3.1s1.5,3.1,3.2,3.1c1.8,0,3.2-1.4,3.2-3.1S30.5,12.2,28.7,12.2z M28.7,17.4 c-1.2,0-2.1-0.9-2.1-2.1c0-1.1,1-2,2.1-2c1.2,0,2.2,0.9,2.2,2C30.8,16.4,29.9,17.4,28.7,17.4z' }
  ]
}
