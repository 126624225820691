<template>
  <component :is="headerTag" class="card-header" :class="classList">
    <slot>{{ header }}</slot>
  </component>
</template>

<script>
export default {
  name: 'BCardHeader',
  props: {
    header: { type: String, default: '' },
    headerBgVariant: { type: String, default: '' },
    headerBorderVariant: { type: String, default: '' },
    headerClass: { type: [String, Array], default: '' },
    headerTag: { type: String, default: 'div' },
    headerTextVariant: { type: String, default: '' }
  },
  computed: {
    classList() {
      const classList = Array.isArray(this.headerClass) ? [this.headerClass] : String(this.headerClass).split(' ')
      if (this.headerBgVariant) classList.push(`bg-${this.headerBgVariant}`)
      if (this.headerBorderVariant) classList.push(`border-${this.headerBorderVariant}`)
      if (this.headerTextVariant) classList.push(`text-${this.headerTextVariant}`)
      return classList
    }
  }
}
</script>
