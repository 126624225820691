<template>
  <wiskInputGroup>
    <wiskInput v-if="inline" infoTooltipKey="95619fef-3d70-4361-acd9-b5d38e85ff39" :label="translations.txtDistributorSearchAdd" :modelValue="autocompleteValue"
      required @autocompleteInput="onAutocompleteInput" autofocus autocomplete autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" :autocompleteMinChars="1"
      :helperText="helperText" @autocompleteSelected="onAutocompleteSelected" @blur="onTitleChange" ref="autoCompleteInput"
      @clear="onAutocompleteSelected(null)" operation="title" :autocompleteItemFormatter="autocompleteItemFormatter" />

    <wiskModal v-else v-model="localVisible" :title="modalTitle" size="md" hideHeaderExtra hideFooterExtra :okDisabled="!wiskDistributor" @ok="ok" :okText="translations.txtDistributorPublincLinkSelected">
      <b-container fluid class="item-swap-edit" style="min-height: 500px;">
        <b-row>
          <b-col>
            <wiskInput infoTooltipKey="95619fef-3d70-4361-acd9-b5d38e85ff39" :label="translations.txtDistributorSearch" :modelValue="autocompleteValue" ref="autocompleteInputInModal"
              required @autocompleteInput="onAutocompleteInput" autofocus autocomplete autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" :autocompleteMinChars="1"
              :helperText="translations.txtDistributorFindHelpTextPublic" @autocompleteSelected="onAutocompleteSelected"
              @clear="onAutocompleteSelected(null)" operation="title" :autocompleteItemFormatter="autocompleteItemFormatter" @mounted="autocompleteInputInModalMounted" />
          </b-col>
        </b-row>

      </b-container>
    </wiskModal>
  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'WiskDistributorFind',
  emits: ['select', 'hide', 'titleChange'],
  components: {},
  props: {
    inline: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
    distributor: { type: Object, required: true }
  },
  data() {
    return {
      autocompleteItems: [],
      autocompleteValue: '',
      localVisible: false,
      wiskDistributor: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters(['countriesByCode']),
    helperText() {
      if (this.distributor && this.distributor.id && this.distributor.wisk_distributor) {
        return this.autocompleteItemFormatter(this.distributor.wisk_distributor)
      }
      return this.translations.txtDistributorFindHelpTextPublic
    },
    modalTitle() {
      return this.translations.txtPurchaseOrdersSelectDistributor
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    autocompleteItemFormatter(text, item) {
      return text + (this.countriesByCode[item.country] ? ` (${this.countriesByCode[item.country].title})` : '')
    },
    onTitleChange() {
      this.$emit('titleChange', this.autocompleteValue)
    },
    onAutocompleteInput(value) {
      this.autocompleteValue = value

      api.wiskDistributorsSearch({ title: value }).then(data => {
        this.autocompleteItems = data
      })
    },
    onAutocompleteSelected(wiskDistributor) {
      this.wiskDistributor = wiskDistributor

      if (wiskDistributor) {
        this.autocompleteValue = wiskDistributor.title
      }

      if (this.inline) {
        this.$emit('select', wiskDistributor)
      }
    },
    ok() {
      this.$emit('select', this.wiskDistributor)
    },
    autocompleteInputInModalMounted() {
      if (this.localVisible && this.$refs.autocompleteInputInModal) {

        let innerComponent = this.$refs.autocompleteInputInModal.getInputComponentRef()

        if (innerComponent) {
          innerComponent.onInput({ target: { value: this.distributor.title } })
        }
      }
    }
  },
  mounted() {
    this.localVisible = this.visible
    setTimeout(() => {
      let outerComponent = this.$refs.autoCompleteInput?.getInputComponentRef()
      outerComponent?.onInput({ target: { value: this.distributor.title } })
    }, 0)
  },
  watch: {
    localVisible(localVisible) {
      if (!localVisible) {
        this.$emit('hide')
      }
    },
    visible: {
      immediate: true,
      handler(visible) {
        this.localVisible = visible
      }
    }
  }
}
</script>

<style lang="scss"></style>
