<template>
  <component :is="tag" :class="classList">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BCol',
  props: {
    xs: { type: [Number, String] },
    sm: { type: [Number, String] },
    md: { type: [Number, String] },
    lg: { type: [Number, String] },
    xl: { type: [Number, String] },
    col: { type: Boolean, default: false },
    cols: { type: [Number, String] },
    tag: { type: String, default: 'div' }
  },
  computed: {
    classList() {
      const classList = ['col']
      if (this.xs) classList.push(`col-${this.xs}`)
      if (this.sm) classList.push(`col-sm-${this.sm}`)
      if (this.md) classList.push(`col-md-${this.md}`)
      if (this.lg) classList.push(`col-lg-${this.lg}`)
      if (this.xl) classList.push(`col-xl-${this.xl}`)
      // if (this.col || (!(this.xs || this.sm || this.md || this.lg || this.xl) && !this.cols)) classList.push('col')
      if (this.cols) classList.push(`col-${this.cols}`)
      return classList
    }
  }
}
</script>
