<template>
  <div class="wisk-pdf-viewer-container" ref="thisDiv">
    <div class="pdfViewer w1-100"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as PDFJSViewerWrapper from 'pdfjs-dist/web/pdf_viewer'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import 'pdfjs-dist/web/pdf_viewer.css'

let PDFViewer = PDFJSViewerWrapper.PDFViewer,
  EventBus = PDFJSViewerWrapper.EventBus,
  PDFLinkService = PDFJSViewerWrapper.PDFLinkService,
  PDFFindController = PDFJSViewerWrapper.PDFFindController,
  pdfViewer = null,
  pdfLinkService = null,
  eventBus = null,
  pdfFindController = null

export default {
  name: 'PDFViewerFull',
  emits: ['scaleChanged'],
  components: {},
  props: {
    url: String,
    base64data: String
  },
  data() {
    return {
      scale: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    init() {
      eventBus = new EventBus()

      pdfLinkService = new PDFLinkService({
        eventBus
      })

      pdfFindController = new PDFFindController({
        eventBus,
        linkService: pdfLinkService
      })
      eventBus.on('pagesinit', () => {
        pdfViewer.currentScaleValue = 'page-width'
        this.scale = pdfViewer.currentScale
        this.$emit('scaleChanged', this.scale)
      })

      eventBus.on('scalechange', () => {
        this.scale = pdfViewer.currentScale
        this.$emit('scaleChanged', this.scale)
      })
    },
    loadUrl() {
      if (pdfViewer) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'
        let promise = null

        if (this.url) {
          promise = pdfjsLib.getDocument({ url: this.url }).promise
        }
        if (this.base64data) {
          promise = pdfjsLib.getDocument({ data: this.base64data }).promise
        }
        if (promise) {
          promise.then(doc => {
            pdfViewer.setDocument(doc)
            pdfLinkService.setDocument(doc, null)
          })
        }
      }
    }
  },

  mounted() {
    this.init()

    setTimeout(() => {
      if (this.$refs.thisDiv) {

        pdfViewer = new PDFViewer({
          container: this.$refs.thisDiv,
          eventBus,
          linkService: pdfLinkService,
          findController: pdfFindController,
          textLayerMode: 2,
          annotationMode: 2,
        })
        pdfLinkService.setViewer(pdfViewer)
        this.loadUrl()
      }
    }, 0)
  },
  watch: {
    url: 'loadUrl'
  }
}
</script>

<style lang="scss">
.wisk-pdf-viewer-container {
  background-color: #444;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
}
</style>
