export default {
  name: 'wisk-log-out',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M22.148,8.1227V3.86a.8433.8433,0,0,0-.8526-.8359H3.82a.84.84,0,0,0-.8361.8359v27.263a.8606.8606,0,0,0,.8688.8525H21.2954a.8572.8572,0,0,0,.8526-.8525V26.8771a1.5,1.5,0,0,1,3,0v4.2461a3.8585,3.8585,0,0,1-3.8525,3.8525H3.82A3.827,3.827,0,0,1,0,31.14V3.86A3.8269,3.8269,0,0,1,3.82.0243H21.2954A3.8444,3.8444,0,0,1,25.1479,3.86V8.1227a1.5,1.5,0,0,1-3,0Z'
    },
    {
      d: 'M35,17.5a1.4775,1.4775,0,0,1-.459,1.0656l-6.246,6.2131a1.5158,1.5158,0,0,1-1.0656.4427,1.5713,1.5713,0,0,1-1.082-.4591,1.5188,1.5188,0,0,1,.0165-2.1311l3.6721-3.6229H19.7047a1.5082,1.5082,0,1,1,0-3.0164H29.836l-3.6721-3.6394a1.4989,1.4989,0,0,1-.0165-2.1148,1.5388,1.5388,0,0,1,2.1476-.0164l6.246,6.2133A1.45,1.45,0,0,1,35,17.5Z'
    }
  ]
}
