<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: ' ', action: null })"
    class="" size="md" hideOK hideFooter hideHeaderExtra>
    <template v-slot:modal-title>
      <b-row>
        <b-col cols="4">
          <h5 class="modal-title"> {{ translations.txtAllergens }} </h5>
        </b-col>
        <b-col cols="4">
          <wiskInput infoTooltipKey="9c9e119e-bf68-4b9b-a58f-a83694ea5e9f" :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" class="" />
        </b-col>
        <b-col cols="4">
          <b-button @click="addAllergen" size="sm" class="float-end me-2" variant="primary"> {{ translations.txtAllergensNew }} </b-button>
        </b-col>
      </b-row>
    </template>

    <b-container style="min-height: 400px;">
      <b-row v-for="allergen in localAllergens" :key="allergen.id" class="allergen" :class="{ 'bg-warning': allergen.archived }">
        <b-col cols="8">
          {{ allergen.title }} {{ allergen.archived ? ` - (${translations.txtGenericArchived})` : '' }}
        </b-col>
        <b-col class="px-1">
          <b-button variant="link" @click="openEdit(allergen)" class="py-0 allergen-edit-action"
            v-tooltip="translations.txtGenericEdit">
            <icon name="wisk-edit" class="text-primary" :scale="0.8" />
          </b-button>
        </b-col>
        <b-col class="px-1">
          <b-button v-if="allergen.archived" variant="link" @click="onChange(allergen, { type: 'archive', value: false })" class="py-0 allergen-edit-action"
            v-tooltip="translations.txtGenericRestore">
            <icon name="wisk-history" class="text-success" :scale="0.8" />
          </b-button>
          <b-button v-if="!allergen.archived" variant="link" @click="onChange(allergen, { type: 'archive', value: true })" class="py-0 allergen-edit-action"
            v-tooltip="translations.txtGenericArchive">
            <icon name="wisk-archive" class="text-danger" :scale="0.8" />
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <confirm ref="confirmDialog" autofocus />

  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import api from '@/api'

export default {
  name: 'AllergensEdit',
  components: {},
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      showArchived: false,
      autocompleteItems: [],
      localAllergens: []
    }
  },
  computed: {
    ...mapGetters(['allergenSearchList']),
    ...mapState(['translations', 'allergens'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    addAllergen() {
      let allergen = { title: '', id: 0 }
      this.openEdit(allergen)
    },
    openEdit(allergen) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          callback: value => {
            this.onChange(allergen, { type: 'title', value })
          },
          text: allergen.title,
          required: true,
          message: this.translations.txtGenericTitle,
          title: allergen.id ? this.translations.txtAllergensTitle : this.translations.txtAllergensNew
        })
      }
    },
    onChange(allergen, operation) {
      api.updateAllergen(allergen.id, operation).then(() => {
        if (typeof this.editAction.onChange === 'function') {
          this.editAction.onChange()
        }
      })
    },
    load() {
      this.localAllergens = this.allergens.map(a => ({ ...a }))

      if (!this.showArchived) {
        this.localAllergens = this.localAllergens.filter(a => !a.archived)
      }
    }
  },
  watch: {
    allergens: {
      immediate: true,
      handler: 'load'
    },
    showArchived: 'load'
  }
}
</script>

<style lang="scss">
.allergen {
  border-radius: 4px;
  margin-top: 5px;
  height: 30px;
  line-height: 2rem;

  &:nth-child(even) {
    background-color: rgba(28, 130, 239, 0.1);
  }

  &:hover {
    background-color: rgba(59, 195, 59, 0.2);

    .allergen-edit-action {
      opacity: 1;
    }
  }

  .allergen-edit-action {
    opacity: 0;
  }
}
</style>
