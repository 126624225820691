export default {
  name: 'wisk-retweet',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M20.8,25.8c0,0.7-0.6,1.3-1.3,1.3H6.9c-0.7,0-1.2-0.6-1.2-1.2c0,0,0,0,0,0V12.3l-3.5,3.5c-0.5,0.5-1.3,0.5-1.8,0 c-0.5-0.5-0.5-1.3,0-1.8c0,0,0,0,0,0L6,8.3c0.5-0.5,1.3-0.5,1.8,0l5.7,5.7c0.5,0.5,0.5,1.3,0,1.8c-0.5,0.5-1.3,0.5-1.8,0l-3.5-3.5 v12.2h11.3C20.2,24.5,20.8,25.1,20.8,25.8z'
    },
    {
      d: 'M14.2,9.2c0-0.7,0.6-1.3,1.3-1.3h12.6c0.7,0,1.2,0.6,1.2,1.3c0,0,0,0,0,0v13.5l3.5-3.5c0.5-0.5,1.3-0.5,1.8,0 s0.5,1.3,0,1.8c0,0,0,0,0,0L29,26.7c-0.5,0.5-1.3,0.5-1.8,0L21.5,21c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0l3.5,3.5V10.5 H15.5C14.8,10.5,14.2,9.9,14.2,9.2z'
    }
  ]
}
