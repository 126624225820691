export default {
  name: 'wisk-depletions',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M25.729,22.928c0-1.65-.015-3.619-.043-6.5a9.775,9.775,0,0,0-.1-1.931v-.019a6.715,6.715,0,0,0-1.924-4.141c.009-.009.009-.009,0-.009A5.527,5.527,0,0,0,22.394,9.4c-.716-.391-.841-.8-.869-1.138-.018-.175-.027-.35-.036-.524-.037-.66-.054-1.32-.041-2.009-.009-.145-.009-.3-.008-.446-.019-.223-.018-.437-.017-.66a3.032,3.032,0,0,0,.53-1.609l.01-.058-.01-.058A2.918,2.918,0,0,0,19.034.015H19L15.929.032h-.194a3.289,3.289,0,0,0-.932.2l-.068.029L14.657.3a2.9,2.9,0,0,0-.4.232.465.465,0,0,0-.078.058A2.9,2.9,0,0,0,12.959,3.01a3.01,3.01,0,0,0,.518,1.583c.009.116.009.233.008.339.009.224.008.447.008.66.026.718.023,1.407-.008,2.077a2.5,2.5,0,0,1-.012.387,1.466,1.466,0,0,1-.7,1.23c-.087.058-.184.106-.282.173a3.762,3.762,0,0,0-.466.261,5.065,5.065,0,0,0-.818.695l-.01.01a6.168,6.168,0,0,0-1.083,1.606,8.684,8.684,0,0,0-.828,3.422v.029c-.01.214-.011.437-.012.67l.018,5.423L9.3,22.691c0,.291,0,.543.006.786-.01.213,0,.427,0,.64.007.786,0,1.543,0,2.338,0,.951,0,1.97.007,3.163a2.078,2.078,0,0,0,.027.67,5.4,5.4,0,0,0,5.405,4.726h5.5a5.48,5.48,0,0,0,3.05-.929,5.4,5.4,0,0,0,2.254-3.271,4.583,4.583,0,0,0,.14-1.193l.007-4.259.034-1.349ZM11.173,16.139a7.068,7.068,0,0,1,.652-3.286A3.75,3.75,0,0,1,13.083,11.3a2.889,2.889,0,0,1,.3-.164c.146-.087.3-.184.438-.28a3.313,3.313,0,0,0,1.543-2.691c.052-.9.046-1.834.03-2.775-.008-.514-.016-1.048-.023-1.562a1.023,1.023,0,0,1-.511-.875.966.966,0,0,1,.459-.842A1.537,1.537,0,0,1,15.485,2a.8.8,0,0,1,.272-.057.6.6,0,0,1,.126-.009l3.144-.017h.01a1.041,1.041,0,0,1,1.024,1.032,1.027,1.027,0,0,1-.44.833.83.83,0,0,0-.087.048c0,.029-.01.087-.01.136.007.553,0,1.106,0,1.659.016.931.022,1.853.106,2.775A3.225,3.225,0,0,0,21.5,11.065a4.5,4.5,0,0,1,2.218,3.705,8.4,8.4,0,0,1,.081,1.64q.027,4.221.007,8.422c-3.1-.264-4.137,1.012-6.909,2.942-2.121,1.477-4.5.381-5.694-.39C11.183,23.639,11.2,19.894,11.173,16.139Z'
    }
  ]
}
