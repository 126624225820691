<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else :title="tooltip" style="white-space: normal; line-height: 1.5;">
    <component v-on.prevent="element.action ? { click: element.action } : {}" :is="element.action ? 'a' : 'span'" v-for="element in elements" :key="element.key" href="javascript: void(0)">
      {{ element.text }}
    </component>
  </div>
</template>

<script>
export default {
  name: 'CellDynamicLinkInText',
  computed: {
    elements() {
      return (this.params && this.params.value && this.params.value.elements) || []
    },
    tooltip() {
      return this.elements.map(e => e.text).join('')
    }
  }
}
</script>
