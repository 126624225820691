<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else class="overflow-hidden text-wrap grid-text-span editable-content" :class="colIdClass">
    <div v-if="!modifiers.length" class="ps-3">
      -
    </div>
    <div v-else v-for="(modifier, index) in modifiers" :key="index" class="modifier" :class="{ 'archived': modifier.archived }" :title="getLineTooltip(modifier)">
      <b-button variant="link" @click="onModifierClick(modifier)" class="py-0 no-link-hover-effect">
        {{ getLineDescription(modifier) }}
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CellModifiers',
  data() {
    return {
      src: ''
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    translate() {
      return this.params?.translate || (() => '')
    },
    modifiers() {
      return this.params?.value?.modifiers || []
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef?.colId
    }
  },
  methods: {
    refresh() {
      return false
    },
    getLineDescription(modifier) {
      if (this.params.getLineDescription) {
        return this.params.getLineDescription(modifier)
      }
      return modifier?.title || ''
    },
    getLineTooltip(modifier) {
      if (this.params.getLineTooltip) {
        return this.params.getLineTooltip(modifier)
      }
      return ''
    },
    onModifierClick(modifier) {
      if (this.params.onModifierClick) {
        this.params.onModifierClick(modifier)
      }
    }
  }
}
</script>
<style lang="scss">
.edit-icon {
  opacity: 0;
}

.editable-content:hover {
  .edit-icon {
    opacity: 1;
  }
}

.modifier {
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;

  &.archived {
    background-color: var(--red-300);
  }
}
</style>
