<template>
  <div v-if="value && mounted" style="display: block; position: absolute; z-index: 999999; bottom: 0; right: 5px;">
    <v-menu :triggers="['hover', 'focus']" :distance="5">
      <icon class="info-icon text-secondary" name="wisk-information-button" />

      <template #popper>
        <div class="p-2">
          {{ infoComputed }}
          <template v-if="pendingPurchaseOrdersMapper.length">
            <hr class="mb-0 mt-2 border-black" />
            <b-table striped hover :items="pendingPurchaseOrdersMapper">
              <template v-slot:cell(date)="row">
                <b-button size="sm" variant="link" type="link" @click="rowClicked(row)">
                  {{ row.date }}
                </b-button>
              </template>
            </b-table>
          </template>
        </div>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate, unitsCases } from '@/modules/utils'

export default {
  name: 'PendingPurchaseInfo',
  props: { params: Object },
  data() {
    return {
      mounted: false
    }
  },
  computed: {
    infoComputed() {
      let units = Math.round(this.value * 10) / 10
      return units + unitsCases(this.value, this.orderFormat, this.caseSize)
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    value() {
      return this.params.value.input_value
    },
    pendingPurchaseOrders() {
      return this.params.value.pending_purchase_orders
    },
    title() {
      return this.params.value.title
    },
    pendingPurchaseOrdersMapper() {
      let orders = (this.pendingPurchaseOrders && this.pendingPurchaseOrders.items) || []

      return orders.map(element => ({
        date: formatDate(element.date),
        quantity: `${element.item.order.quantity} ${element.item.order.type}`,
        id: element.id,
      }))
    },
    orderFormat() {
      return this.params.value.order_format
    },
    caseSize() {
      return this.params.value.case_size
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    rowClicked(item) {
      this.setGlobalAction({ type: 'purchaseOrderEdit', action: { id: item.id }, resetBeforeOpen: true })
    },
  },
  mounted() {
    this.mounted = true
  }
}
</script>
