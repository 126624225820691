import get from 'lodash.get'

export default translations => [
  {
    predicate: item => !!get(item, 'stats.consumption.consumption.ml') || !!get(item, 'stats.sales.volume.ml'),
    name: 'consumption_or_sales',
    label: translations.txtFilterConsumptionOrSales
  },
  {
    predicate: item => item.varianceGroup,
    name: 'variance_groups',
    label: translations.txtVarianceGroups
  },
  {
    predicate: item => item.flags.consumption.negative.value,
    name: 'consumption_negative_value',
    label: translations.txtFilterNegativeConsumption
  },
  {
    predicate: item => item.flags.consumption.consumption_not_mapped,
    name: 'consumption_not_mapped',
    label: translations.txtFilterConsumptionNotMapped
  },
  {
    predicate: item => !!get(item, 'stats.sales.volume.ml'),
    name: 'sales',
    label: translations.txtFilterSales
  },
  {
    predicate: item => item.flags.consumption.consumption_no_sales,
    name: 'consumption_no_sales',
    label: translations.txtFilterConsumptionNoSales
  },
  {
    predicate: item => item.flags.sales.sales_no_consumption,
    name: 'sales_no_consumption',
    label: translations.txtFilterSalesNoConsumption
  },
  {
    predicate: item => get(item, 'stats.sales.volume.ml', 0) < get(item, 'stats.consumption.consumption.ml', 0),
    name: 'sales_smaller_than_consumption',
    label: translations.txtFilterConsumptionGreaterThanSales
  },
  {
    predicate: item => get(item, 'stats.sales.volume.ml', 0) > get(item, 'stats.consumption.consumption.ml', 0),
    name: 'sales_greater_than_consumption',
    label: translations.txtFilterSalesGreaterThanConsumption
  }
]
