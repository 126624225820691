// alternative names: wisk-copy
export default {
  name: 'wisk-cursor-normal',
  width: 70,
  height: 90,
  paths: [
    {
      d: 'M8.20465 75.2686L3.3355 5.63273C3.20293 3.7369 5.30122 2.51152 6.88725 3.55854L65.864 42.4921C67.1033 43.3102 66.4972 45.2402 65.0126 45.2028L49.0395 44.8006L41.1309 44.6014C39.9727 44.5723 39.2329 45.8264 39.8186 46.826L56.5729 75.4165C57.6007 77.1704 57.0186 79.425 55.2702 80.4621L50.6982 83.1742L45.4319 86.1323C43.6429 87.1372 41.3778 86.4919 40.3871 84.695L24.3873 55.6769C23.8216 54.6508 22.3439 54.6597 21.7905 55.6924L18.2371 62.3236L10.9815 75.8635C10.2814 77.1701 8.30805 76.7473 8.20465 75.2686Z'
    }
  ]
}
