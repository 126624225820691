<template>
  <wiskItemsGrid :gridStyle="gridStyle" :columns="columns"
    :items="items" :loading="loading" :header="header" :customFilter="customFilterSelected" :options="{ rowSelection: { mode: 'multiRow', checkboxes: true } }"
    :excel="{ fileName: 'Variance' }" :additionalHeaderCols="4" :additionalHeaderColsMobile="8"
    parentGridName="Variance" :defaultFilter="{ predicate: item => !!item, name: 'all', label: 'All' }">
    <template v-slot:additional-controls>
      <wiskGridCustomFilterDropdown :customFilters="customFilters" v-model="customFilterSelected" class="" />
    </template>
  </wiskItemsGrid>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import getVarianceColumns from './varianceColumns'
import getCustomFilters from './gridFilters'

export default {
  name: 'VarianceGrid',
  components: { wiskItemsGrid },
  emits: ['refresh', 'openDetails'],
  props: {
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    gridStyle: { type: Object, default: () => ({}) },
    selectedRange: { type: Object, default: () => ({}) },
    header: { type: Object, default: () => ({}) },
    independentInventory: Boolean
  },
  data() {
    return {
      customFilterSelected: null,
      customFilters: [],
      columns: {},
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'bottlesById', 'itemVariantsById']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    emitRefresh() {
      this.$emit('refresh')
    },
    openDetails(id) {
      this.$emit('openDetails', id)
    }
  },
  created() {
    this.customFilters = getCustomFilters(this.translations)
  },
  watch: {
    selectedRange: {
      handler(selectedRange) {
        if (selectedRange?.inventory_range?.start_inventory_id && selectedRange?.inventory_range?.end_inventory_id) {
          this.columns = getVarianceColumns({
            translations: this.translations,
            setGlobalAction: this.setGlobalAction,
            startInventoryId: selectedRange.inventory_range.start_inventory_id,
            endInventoryId: selectedRange.inventory_range.end_inventory_id,
            emitRefresh: this.emitRefresh,
            openDetails: !this.independentInventory && this.openDetails,
            defaultUMVolume: this.venue.default_um_volume,
            defaultUMWeight: this.venue.default_um_weight,
          })
        }
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss"></style>
