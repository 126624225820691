<template>
  <v-menu :triggers="['hover']" :distance="5" @show="refreshData" class="text-center">
    <icon name="wisk-history" :scale="0.7" style="color: var(--blue-300);" />

    <template #popper>
      <b-container fluid class="px-2 pb-2 pt-0" style="max-height: 450px; overflow-y: auto;">
        <div class="w-100 pb-2"></div>
        <b-table-simple bordered small style="">
          <thead style="position: sticky; top: 0; background-color: var(--blue-100);">
            <tr>
              <th scope="row"> {{ translations.txtGenericDate }} </th>
              <th scope="row"> {{ translations.txtGenericChange }} </th>
              <th scope="row"> {{ translations.txtGenericUser }} </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="change in changes" :key="change.id">
              <td> {{ change.date }} </td>
              <td> {{ change.description }} </td>
              <td> {{ change.doneBy }} </td>
            </tr>
          </tbody>
        </b-table-simple>

        <wiskLoading :loading="loading" />
      </b-container>
    </template>
  </v-menu>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'
import api from '@/api'
import { formatDate } from '@/modules/utils'
import timelineDataMapper from '@/components/timeline/timelineDataMapper'

export default {
  name: 'DraftInvoiceLineTimeline',
  components: {},
  props: {
    draftInvoiceId: { type: Number, required: true },
    draftInvoiceLineId: { type: Number, required: true }
  },
  data() {
    return {
      changes: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    processData(data) {
      let filtered = data.filter(change => change.message.includes(`${this.draftInvoiceLineId}`)),
        processed = timelineDataMapper(filtered, this.$store.state, this.setGlobalAction)

      //Let's hope timeline data will never be translated
      this.changes = processed.map(change => {
        const lineId = this.draftInvoiceLineId.toString(),
          hasWiskTeam = change.text.includes('Wisk Team'),
          startIndex = change.text.indexOf(lineId) + lineId.length + 3,
          endIndexCalc = change.text.indexOf(hasWiskTeam ? 'Wisk Team' : change.done_by) - 3,
          endIndex = endIndexCalc >= 0 ? endIndexCalc : change.text.length

        let description = change.text.substring(startIndex, endIndex).trim()

        if (description.startsWith('gl account')) {
          description = 'GL' + description.substring(2)
        } else {
          description = description.replace(/^\w/, c => c.toUpperCase())
        }

        if (endIndexCalc < 0) {
          console.warn(`Invalid done_by for change ID ${change.id}`, change)
          Sentry.captureException(new Error(`Invalid done_by for change with date ${change.date.toISOString()} and ID ${change.id}`))
        }

        return {
          id: change.id,
          date: formatDate(change.date),
          description,
          doneBy: hasWiskTeam ? 'Wisk Team' : change.done_by
        }
      })
    },
    refreshData() {
      this.loading = true
      api.getFilteredTimeline({ filters: [{ type: 'draft_invoice', id: this.draftInvoiceId }] }).then(this.processData).finally(() => { this.loading = false })
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss"></style>
