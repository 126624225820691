<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'salesUpload', action: null })"
    :title="translations.txtSalesUploadTitle"
    size="lg" :okDisabled="okDisabled" @ok="save" :okText="translations.txtGenericUpload" preventOKClose>
    <div v-if="editAction" class="wisk-sales-upload" style="min-height: 550px;">
      <b-container fluid>
        <b-row>
          <b-col class="mb-2-m px-0-m">
            <div class="d-flex align-items-center">
              <wiskSelect :label="translations.txtPOSIntegration" :modelValue="selectedPOSIntegration?.id" @change="selectedPOSIntegration = $event"
                          :items="computedPOSIntegrations" class="w-100" trackBy="id" disableTooltip />
              <b-button variant="link" @click="selectedPOSIntegration = null" v-if="selectedPOSIntegration" style="top: 1px; position: absolute;" :style="{ right: '40px' }" :title="translations.txtGenericClear">
                <icon :scale="0.7" name="wisk-exit" />
              </b-button>
            </div>

            <div role="toolbar" class="btn-toolbar float-start">
              <b-form-radio-group class="btn-group-vertical me-3 no-shadow-focus" buttons button-variant="transparent" size="sm" vertical v-model="intervalType" name="radiosBtn">
                <b-form-radio class="m-0 text-end" style="box-shadow: none; cursor: pointer;" value="inventory_range" :class="{ 'text-primary': intervalType === 'inventory_range' }">
                  {{ translations.txtGenericInventoryRange }}
                  <icon class="" name="wisk-bars" :scale="0.8" />
                </b-form-radio>
                <b-form-radio class="m-0 text-end" style="box-shadow: none; cursor: pointer;" value="date_range" :class="{ 'text-primary': intervalType === 'date_range' }">
                  {{ translations.txtGenericDateRange }}
                  <icon class="" name="wisk-date" :scale="0.8" />
                </b-form-radio>
              </b-form-radio-group>
            </div>

            <intervalByIdSelector v-if="inventories && intervalType === 'inventory_range'" :inputData="computedInventories"
              @changedFrom="selectedInventoryFrom = $event" @changedTo="selectedInventoryTo = $event" />

            <dateRangeSelector v-if="intervalType === 'date_range'" v-model="selectedDateRange"
              initialShortcut="last30Days" :notBefore="venue.last_locked_inventory_date && new Date(venue.last_locked_inventory_date)" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-3">
            <div v-if="formattedLastManualSalesUploadInterval" class="my-2">
              {{ translations.translate('tplSalesLastUploadWasAtDate', { '{formatted}': formattedLastManualSalesUploadInterval }) }}
            </div>
            <b-input-group class="mt-4">
              <fileInput accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .csv" class="wisk-sales-upload-input" @reset="file = null" v-model="file" />
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-4">
            <a href="https://wiskai-report-templates.s3.ca-central-1.amazonaws.com/WISK_Sales_Upload_TEMPLATE.xlsx" target="_blank" rel="noreferrer">
              {{ translations.txtSalesUploadTemplateLink }}
            </a>
          </b-col>
        </b-row>

        <wiskInput infoTooltipKey="f1e7e5af-63ee-4b67-908f-e6bc4d031f1d" :label="translations.txtSalesUploadKeepSales" v-model="keepSales" inputType="checkbox" class="mt-3" />

        <div class="mt-5" v-if="uploadErrors && uploadErrors.length">
          <h5> {{ translations.txtSalesUploadErrors }} </h5>
          <b-row v-for="(error, index) in uploadErrors" :key="index">
            <b-col class="text-danger">
              {{ error }}
            </b-col>
          </b-row>
        </div>
      </b-container>
      <wiskLoading :loading="loading" />
    </div>
    <confirm ref="confirmDialog" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import get from 'lodash.get'
import api from '@/api'
import { formatDate } from '@/modules/utils'
import intervalByIdSelector from '@/components/common/IntervalByIdSelector'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import fileInput from '@/components/common/FileInput'

export default {
  name: 'SalesUploader',
  components: { intervalByIdSelector, dateRangeSelector, fileInput },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      selectedInventoryFrom: null,
      selectedInventoryTo: null,
      intervalType: 'inventory_range',
      selectedDateRange: [DateTime.fromJSDate(new Date()).minus({ days: 30 }).toJSDate(), new Date()],
      keepSales: false,
      secondaryPos: false,
      loading: false,
      file: null,
      uploadErrors: [],
      selectedPOSIntegration: null
    }
  },
  computed: {
    ...mapState(['translations', 'inventories', 'user', 'posIntegrations']),
    ...mapGetters(['venue']),
    okDisabled() {
      if (!this.file) {
        return true
      }

      if (this.intervalType === 'date_range') {
        return false
      }

      return !this.selectedInventoryFrom || !this.selectedInventoryTo
    },
    formattedLastManualSalesUploadInterval() {
      let interval = this.selectedPOSIntegration?.last_manual_sales_upload_interval || {}
      if (interval.start || interval.end) {
        return `${formatDate(interval.start, { defaultIfEmpty: '-' })} - ${formatDate(interval.end, { defaultIfEmpty: '-' })}`
      }
      return ''
    },
    computedInventories() {
      return this.inventories.map(i => ({ ...i, $isDisabled: i.is_locked }))
    },
    computedPOSIntegrations() {
      return this.posIntegrations.filter(i => !i.is_archived).map(item => ({
        ...item,
        title: `${item.title} - ${item.type}`
      }))
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    save() {
      let dateRangeStart, dateRangeEnd
      let formData = new FormData()
      this.loading = true
      formData.append('sales', this.file)

      if (this.intervalType === 'date_range') {
        this.selectedDateRange[0] && formData.append('start_date', this.selectedDateRange[0].toISOString())
        this.selectedDateRange[1] && formData.append('end_date', this.selectedDateRange[1].toISOString())
        dateRangeStart = this.selectedDateRange[0]
        dateRangeEnd = this.selectedDateRange[1]
      }

      if (this.intervalType === 'inventory_range') {
        if (this.selectedInventoryFrom) {
          formData.append('start_inventory_id', this.selectedInventoryFrom.id)
          formData.append('start_id', this.selectedInventoryFrom.id)
          dateRangeStart = this.selectedInventoryFrom.date
        }
        if (this.selectedInventoryTo) {
          formData.append('end_inventory_id', this.selectedInventoryTo.id)
          formData.append('end_id', this.selectedInventoryTo.id)
          dateRangeEnd = this.selectedInventoryTo.date
        }
      }

      formData.append('keep_sales_for_interval', this.keepSales)
      formData.append('is_secondary_pos', this.secondaryPos)

      if (this.selectedPOSIntegration) {
        formData.append('pos_integration_id', this.selectedPOSIntegration.id)
      }

      if (this.$refs.confirmDialog) {
        dateRangeStart = formatDate(dateRangeStart, { defaultIfEmpty: '-' })
        dateRangeEnd = formatDate(dateRangeEnd, { defaultIfEmpty: '-' })
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.uploadSales(formData)
              .then(() => {
                this.setGlobalAction({ type: 'salesUpload', action: null })
              })
              .catch(e => {
                let errorInfo = get(e, 'data.info.errors')
                if (Array.isArray(errorInfo)) {
                  this.uploadErrors = errorInfo
                } else {
                  this.setGlobalAction({ type: 'salesUpload', action: null })
                }
                this.loading = false
              })
          },
          cancelCallback: () => {
            this.loading = false
          },
          message: this.keepSales ? this.translations.txtSalesUploadMessageKeepExisting : this.translations.txtSalesUploadMessageReplaceExisting,
          title: this.translations.translate('tplSalesUploadConfirmTitle', { '{from}': dateRangeStart, '{to}': dateRangeEnd })
        })
      }
    }
  },
  watch: {
    editAction: {
      handler() {
        if (this.editAction.integration && this.computedPOSIntegrations) {
          this.selectedPOSIntegration = this.computedPOSIntegrations.find(i => i.id === this.editAction.integration.id) || this.computedPOSIntegrations[0]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
