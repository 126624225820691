<template>
  <div ref="container" class="wisk-pdf-viewer" :style="containerStyle">
    <div :style="heightStyle" class="position-relative">
      <div v-if="!hideControls && pagesCount > 1" :style="{ opacity: sidebarOpen ? 1 : 0, 'pointer-events': sidebarOpen ? null : 'none' }" class="pdf-viewer-sidebar">
        <thumbnail v-for="(t, index) in thumbnails" :pdfPage="t" :key="index" @click="currentPageNumber = index + 1" :width="thumbnailWidth" :height="thumbnailHeight"
          :class="{ active: index + 1 === currentPageNumber }" />
      </div>
      <page v-if="currentPDFPage" :zoomControlType="zoomControlType" :zoomType="selectedZoomType?.id || 'fit'" ref="pdfPage"
        :scale="scale" :rotation="rotation" :renderHighlighter="renderHighlighter" @scale="refreshRegionMapKey" @pdfPageRendered="onPDFPageRender"
        @click="$emit('regionHighlightClear')" :pdfPage="currentPDFPage" :key="key" :renderText="renderText">

        <regionHighlighter v-if="renderHighlighter" @click="$emit('regionHighlightClear')" :originalDocumentSize="originalDocumentSize"
          :highlightBox="regionHighlight" :rotation="rotation" :key="rotation" :refreshKey="regionMapKey" />

        <regionMap v-if="activeRegions && activeRegions.length && originalDocumentSize" :regions="activeRegions.filter(r => r.page === currentPageNumber)" :originalDocumentSize="originalDocumentSize"
          :refreshKey="regionMapKey" @select="$emit('activeRegionSelected', $event)" :rotation="rotation" />
      </page>
      <wiskLoading :loading="loading" />
    </div>

    <div v-if="!hideControls" class="p-1 pt-2" style="pointer-events: initial; z-index: 9999; position: relative;">
      <div role="toolbar" class="btn-toolbar" style="height: 20px;">
        <b-button v-if="pagesCount > 1" variant="dark" class="py-0 me-1" style="z-index: 999; height: 20px;" size="sm" @click="sidebarOpen = !sidebarOpen" v-tooltip="translations.txtPDFViewerPages">
          <icon class="mt-1" name="wisk-bars" :scale="0.7"></icon>
        </b-button>
        <b-button variant="dark" class="py-0" style="z-index: 999; height: 20px;" size="sm" @click="downloadPDF" v-tooltip="translations.txtPDFViewerDownload">
          <icon class="mt-1" name="wisk-pdf" :scale="0.7"></icon>
        </b-button>
        <div role="group" class="btn-group mx-1" v-if="pagesCount > 1" style="height: 20px;">
          <b-input-group size="sm" style="height: 20px;">
            <template #prepend>
              <div style="height: 20px;" class="mx-2">
                Page:
              </div>
            </template>
            <template #append>
              <div style="height: 20px;" class="mx-2">
                {{ ` of ${pagesCount}` }}
              </div>
            </template>
            <input class="form-control form-control-sm py-0 m-0" style="width: 40px; height: 20px; min-height: 20px;" type="number" inputmode="numeric" :max="pagesCount" :min="1" :value="currentPageNumber"
              @keyup.enter="currentPageNumber = parseInt($event.target.value, 10)" />
          </b-input-group>
          <b-button :disabled="currentPageNumber === 1" variant="dark" class="py-0 " style="height: 20px;" size="sm" @click="currentPageNumber = currentPageNumber - 1" v-tooltip="translations.txtPDFViewerPageUp">
            <icon class="mt-1" name="wisk-chevron-up" :scale="0.7"></icon>
          </b-button>
          <b-button :disabled="currentPageNumber === pagesCount" variant="dark" class="py-0 " style="height: 20px;" size="sm" @click="currentPageNumber = currentPageNumber + 1" v-tooltip="translations.txtPDFViewerPageDown">
            <icon class="mt-1" name="wisk-chevron-down" :scale="0.7"></icon>
          </b-button>
        </div>
        <div role="group" class="btn-group mx-1" style="height: 20px;">
          <b-button variant="dark" class="py-0 " size="sm" @click="$emit('regionHighlightClear'); setRotation('left')" v-tooltip="translations.txtPDFViewerRotateCounterClockwise">
            <icon class="mt-1" name="wisk-undo" :scale="0.7"></icon>
          </b-button>
          <b-button variant="dark" class="py-0 " size="sm" @click="$emit('regionHighlightClear'); setRotation('right')" v-tooltip="translations.txtPDFViewerRotateClockwise">
            <icon class="mt-1" name="wisk-redo" :scale="0.7"></icon>
          </b-button>
        </div>
        <div role="group" class="btn-group mx-1" style="height: 20px;" :key="zoomControlType">
          <b-button :variant="zoomControlType === 'dropdown' ? 'outline-dark' : 'dark'" class="py-0 " size="sm" v-tooltip="translations.txtPDFViewerSelectText"
            @click="$emit('regionHighlightClear'); zoomControlType = 'dropdown'">
            <icon class="mt-1" name="wisk-cursor-normal" :scale="0.7"></icon>
          </b-button>
          <b-button :variant="zoomControlType === 'scroll' ? 'outline-dark' : 'dark'" class="py-0 " size="sm" v-tooltip="translations.txtPDFViewerPan"
            @click="$emit('regionHighlightClear'); zoomControlType = 'scroll'">
            <icon class="mt-1" name="wisk-cursor-hand" :scale="0.7"></icon>
          </b-button>
        </div>
        <div v-show="zoomControlType === 'dropdown'" role="group" class="btn-group mx-1" style="height: 20px;">
          <b-input-group size="sm" variant="dark">
            <template #prepend>
              <b-dropdown up :text="(selectedZoomType && selectedZoomType.title) || ''" class="text-capitalize py-0 wisk-pdf-viewer-zoom-selector" size="sm" variant="dark"
                toggle-class="text-decoration-none w-100" style="width: 70px; height: 20px;" @click="$emit('regionHighlightClear')" v-tooltip="translations.txtPDFViewerZoomPresets">
                <b-dropdown-item v-for="zoomType in zoomTypes" :style="{ display: zoomType.hide ? 'none' : 'block' }" :key="zoomType.id" @click="selectedZoomType = zoomType; scale = 1; key++">
                  {{ zoomType.title }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>
          <b-button style="height: 20px;" :disabled="scale <= 0.5" variant="dark" class="py-0 " size="sm" v-tooltip="translations.txtPDFViewerZoomOut"
            @click="$emit('regionHighlightClear'); scale -= 0.25; selectedZoomType = zoomTypes.find(z => z.id === 'scale'); key++">
            <icon class="mt-1" name="wisk-zoom-out" :scale="0.7"></icon>
          </b-button>
          <b-button style="height: 20px;" :disabled="scale >= 5" variant="dark" class="py-0 " size="sm" v-tooltip="translations.txtPDFViewerZoomIn"
            @click="$emit('regionHighlightClear'); scale += 0.25; selectedZoomType = zoomTypes.find(z => z.id === 'scale'); key++">
            <icon class="mt-1" name="wisk-zoom-in" :scale="0.7"></icon>
          </b-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import isFinite from 'lodash.isfinite'
import { loadPDF } from '@/modules/utils'
import thumbnail from '@/components/viewers/pdf/PDFThumbnail'
import page from '@/components/viewers/pdf/PDFPage'
import regionHighlighter from '@/components/common/RegionHighlighter'
import regionMap from '@/components/common/RegionMap'

export default {
  name: 'PDFViewer',
  emits: ['pageNumber', 'numPages', 'scale', 'regionHighlightClear', 'activeRegionSelected', 'pdfPageRendered', 'pdfLoaded', 'errorInvalidPDF'],
  components: { thumbnail, page, regionHighlighter, regionMap },
  props: {
    url: String,
    page: Number,
    hideControls: { type: Boolean, default: false },
    renderText: { type: Boolean, default: false },
    activeRegions: { type: Array, default: () => [] },
    regionHighlight: Object,
    listenForWindowResize: { type: Boolean, default: false },
    originalDocumentSize: Object
  },
  data() {
    return {
      currentPageNumber: 1,
      thumbnailWidth: 35,
      thumbnailHeight: 60,
      pagesCount: 1,
      pdf: null,
      pdfForThumbnails: null,
      key: 1,
      firstLoad: true,
      scale: 1,
      regionMapKey: 1,
      rotation: 0,
      selectedZoomType: { id: 'fit', title: 'Fit' },
      sidebarOpen: false,
      thumbnails: [],
      currentPDFPage: null,
      containerStyle: { height: '500px', width: '500px' },
      resizeDebounceId: null,
      zoomControlType: 'dropdown',
      loading: false,
      currentPageSelectedAt: null,
      firstRenderDone: false
    }
  },
  computed: {
    ...mapState(['translations', 'windowResizeKey']),
    ...mapGetters([]),
    renderHighlighter() {
      return !!(this.regionHighlight && this.originalDocumentSize)
    },
    urlComputed() {
      if (this.url && !this.url.startsWith('http')) {
        return 'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + this.url
      }
      return this.url
    },
    zoomTypes() {
      return [
        { id: 'fit', title: 'Fit' },
        { id: 'pageWidth', title: 'Page Width' },
        { id: 'scale', title: this.scale * 100 + '%' },
        { id: 1, title: '100%', hide: this.scale === 1 },
        { id: 2, title: '200%' },
        { id: 3, title: '300%' },
        { id: 4, title: '400%' },
        { id: 5, title: '500%' }
      ]
    },
    heightStyle() {
      return { height: this.hideControls ? '100%' : 'calc(100% - 40px)' }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    refreshRegionMapKey(scale) {
      this.regionMapKey = scale * 100 + this.currentPageNumber * 10
    },
    downloadPDF() {
      if (this.urlComputed) {
        window.open(this.urlComputed, '_blank')
      }
    },
    loadPDF() {
      if (this.urlComputed) {
        this.loading = true

        loadPDF(this.urlComputed).then(pdf => {
          this.pdf = markRaw(pdf)
          this.$emit('pdfLoaded', this.pdf)

          if (pdf && pdf.numPages) {
            this.$emit('numPages', pdf.numPages)
            this.pagesCount = pdf.numPages

            if (!this.hideControls) {
              // thumbnails
              for (let i = 0; i < pdf.numPages; i++) {
                pdf.getPage(i + 1).then(p => {
                  this.thumbnails[i] = markRaw(p)
                })
              }
            }

            // page
            if (this.page && this.firstLoad) {
              this.currentPageNumber = this.page
            } else {
              this.currentPageNumber = 1
            }
            this.selectCurrentPage()
          }
        }).catch(error => {
          if (error.message === 'InvalidPDF') {
            this.notify({
              type: 'error',
              message: 'Invalid PDF file'
            })
            this.$emit('errorInvalidPDF')
          }

          if (error.message !== 'RenderingCancelled' && error.message !== 'InvalidPDF') {
            console.error('Error while rendering page:', error)
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    setRotationFromOutside(rotation) {
      if (isFinite(rotation)) {
        this.rotation = rotation
        this.key++
      }
    },
    setRotation(direction) {
      if (direction === 'left') {
        this.rotation -= 90
        if (this.rotation === -90) {
          this.rotation = 270
        }
      } else {
        this.rotation += 90
        if (this.rotation === 360) {
          this.rotation = 0
        }
      }

      this.key++
    },
    reset() {
      this.rotation = 0
      this.selectedZoomType = this.zoomTypes.find(z => z.id === 'fit')
      this.key++
    },
    goTo(pageNumber) {
      this.currentPageNumber = pageNumber
    },
    selectCurrentPage() {
      if (this.pdf) {
        let pageNumber = parseInt(this.currentPageNumber, 10)

        this.currentPageSelectedAt = Date.now()

        if (!pageNumber || pageNumber < 1) {
          pageNumber = 1
        }
        if (pageNumber > this.pagesCount) {
          pageNumber = this.pagesCount
        }
        this.currentPageNumber = pageNumber

        this.refreshRegionMapKey(this.scale)

        this.pdf.getPage(pageNumber).then(pdfPage => {
          this.currentPDFPage = markRaw(pdfPage)

          this.$emit('pageNumber', pageNumber)
          this.key++
        })
      }
    },
    initContainerSize() {
      clearTimeout(this.resizeDebounceId)
      this.resizeDebounceId = setTimeout(() => {
        if (this.$refs.container) {
          let box = this.$refs.container.parentElement.getBoundingClientRect()
          if (box) {
            this.containerStyle.height = box.height + 'px'
            this.containerStyle.width = box.width + 'px'

            if (!this.firstLoad && this.$refs.pdfPage) {
              this.$refs.pdfPage.render()
            }
          }
        }
      }, 300)
    },
    onPDFPageRender() {
      let now = Date.now()

      if (!this.firstRenderDone && now - this.currentPageSelectedAt > 1000) {
        this.key++
        console.log('Retry render of first page for big file size')
      }

      this.firstRenderDone = true
      this.$emit('pdfPageRendered')
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.container) {
        this.initContainerSize()
        this.loadPDF()
        this.firstLoad = false
      }
    }, 0)
  },
  beforeUnmount() {
    if (this.pdfForThumbnails && this.pdfForThumbnails.destroy) {
      this.pdfForThumbnails.destroy()
    }
    if (this.pdf && this.pdf.destroy) {
      this.pdf.destroy()
    }

    clearTimeout(this.resizeDebounceId)
  },
  watch: {
    urlComputed: 'loadPDF',
    currentPageNumber: 'selectCurrentPage',
    windowResizeKey() {
      if (this.listenForWindowResize) {
        this.initContainerSize()
      }
    }
  }
}
</script>

<style lang="scss">
.pdf-viewer-sidebar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 68px;
  height: 100%;
  overflow-x: hidden;
  background-color: white;
  overflow-y: auto;
  z-index: 999;
  box-shadow: 15px 25px 25px -25px #333;
}

.wisk-pdf-viewer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
    outline: none;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .wisk-pdf-viewer-zoom-selector {
    .btn-sm {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
</style>
