<template>
  <div class="wisk-grid-container" :class="{ 'bottom-aggregation': !!bottomAggregationRow, 'has-header': header && !header.hideHeader }"
    ref="thisGridContainer">

    <b-button variant="link" class="text-primary found-archived-link d-block" @click="requestFilter('archived')" v-if="archivedItemsCount && showFoundArchived && searchText">
      <icon name="wisk-information-button" :scale="0.7" />
      {{ translations.translate('tplFilterArchivedFoundItems', { '{a}': archivedItemsCount }) }}
    </b-button>

    <gridHeader v-if="currentViewId && header && !header.hideHeader" :columns="columns"
      :gridName="gridName" @change="forceReactOnViewChange = true" @heightChanged="headerHeight = $event"
      :currentView="currentView" :headerConfig="header" @searchQuery="updateSearchText" :innitialQuery="searchText"
      :actionsPanelOpen="actionsPanelOpen" @pinned="gridPanelPinned = $event" :isSystemView="currentView?.configs?.system">

      <template v-slot:icon-buttons>
        <b-button variant="link" class="text-success excel-icon-only d-flex align-items-center px-1 float-start" @click="exportExcel" :title="translations.txtGenericExport + ' (Excel)'">
          <icon class="" name="wisk-excel" :scale="1" />
        </b-button>
        <b-button variant="link" class="text-danger pdf-icon-only d-flex align-items-center px-1" @click="exportPDF" :title="translations.txtGenericExport + ' (PDF) - BETA'">
          <icon class="" name="wisk-pdf" :scale="1" />
        </b-button>
      </template>

      <slot name="additional-header-controls"></slot>

    </gridHeader>

    <gridViewManager v-if="multiPanelParams && gridApi" :gridApi="gridApi" @select="switchToView" :gridName="gridName" :multiPanelParams="multiPanelParams" @filterConfig="setFilterConfig" :gridHeight="gridHeight" :preSelectedView="preSelectedView" :saveSelectedView="saveSelectedView" ref="gridViewManagerInstance" :hideViewsInReportsDashboard="hideViewsInReportsDashboard"/>

    <div :id="gridDOMId" :style="[computedGridStyle]">
      <errorBoundary :label="`ag-grid-vue (${parentGridName})`" class="h-100">
        <agGridVue v-if="optionsInitDone" :defaultColDef="defaultColDef" v-bind="$attrs" class="wisk-grid ag-theme-balham" :class="{ 'no-header': !header }" @displayedColumnsChanged="displayedColumnsChanged" @modelUpdated="modelUpdated" @gridReady="gridReady" @firstDataRendered="firstDataRendered" :isExternalFilterPresent="() => true" @toolPanelVisibleChanged="toolPanelVisibleChanged" @sortChanged="displayedColumnsChanged" @rowGroupOpened="rowGroupOpened" :doesExternalFilterPass="doesExternalFilterPass" :modules="agGridModules" :key="gridKey" @filterChanged="gridfilterChanged" valueCache :gridOptions="options" :columnDefs="columns" :loading="false"/>
      </errorBoundary>
      <div class="loadingState" v-if="loadingOverlay">
        <div>Loading...</div>
      </div>
    </div>

    <div class="wisk-grid-bottom-extra-lines-container" v-if="extraLines && extraLines.length && visibleColumns.length && deviceType !== 'mobile'" ref="extraLines">
      <div class="wisk-grid-bottom-extra-line" v-for="(line, index) in extraLines" :key="index">
        <div v-for="column in visibleColumns" :style="{ width: column.width + 'px' }" :key="column.colId" :class="[{ 'has-content': line[column.colId] !== undefined }, line.colId, column.cellClass]">
          {{ line[column.colId] || '' }}
        </div>
      </div>
    </div>

    <slot></slot>

    <confirm ref="confirmDialog" autofocus />
    <loading :loading="loading" />
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { mapGetters, mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import merge from 'lodash.merge'
import uniq from 'lodash.uniq'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
// import { detailedDiff } from 'deep-object-diff'
import sanitize from 'sanitize-filename'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import { AgGridVue } from '@ag-grid-community/vue3'
import { mergeArrayOfObjects, arrayToObjectById, compareNumbers, stringFilter, formatDate, getStringForSearchRecursive, setValueAtPath, guid } from '@/modules/utils'
import loading from '@/components/common/WiskLoading'
import gridHeader from '@/components/grids/WiskGridHeader'
import gridViewManager from '@/components/grids/WiskGridViewManager'
import { getGridOptions, getDefaultColDef, createComparatorWithColId } from '@/components/grids/options/wiskGrid'
import { exportExcel, exportPDF } from '@/components/grids/export'
import { gridFilters } from '@/components/grids/options/filters'

/* eslint-disable vue/no-unused-components */
//imported only for ag grid to find them
import WiskGridSelectedRowsActionsPanel from '@/components/grids/WiskGridSelectedRowsActionsPanel'
import CellPopMultiselect from '@/components/cells/CellPopMultiselect'
import CellIngredients from '@/components/cells/CellIngredients'
import CellMenu from '@/components/cells/CellMenu'
import CellModifiers from '@/components/cells/CellModifiers'
import CellText from '@/components/cells/CellText'
import CellMoreDetails from '@/components/cells/CellMoreDetails'
import CellCheckbox from '@/components/cells/CellCheckbox'
import CellNumber from '@/components/cells/CellNumber'
import CellAttachments from '@/components/cells/CellAttachments'
import CellImage from '@/components/cells/CellImage'
import CellOrderUnitsEditor from '@/components/cells/CellOrderUnitsEditor'
import CellUnits from '@/components/cells/CellUnits'
import CellBadge from '@/components/cells/CellBadge'
import CellExternalLink from '@/components/cells/CellExternalLink'
import CellWiskItems from '@/components/cells/CellWiskItems'
import CellHeader from '@/components/cells/CellHeader'
import CellDynamicLinkInText from '@/components/cells/CellDynamicLinkInText'
import CellCostPercentage from '@/components/cells/CellCostPercentage'

ModuleRegistry.registerModules([ExcelExportModule, RowGroupingModule, SideBarModule, ClientSideRowModelModule])

const styles = getComputedStyle(document.body)

export default {
  name: 'WiskGrid',
  emits: ['gridApi', 'firstDataRendered', 'displayedColumnsChanged', 'selectedRowsChanged', 'visibleRows', 'requestFilter', 'searchQueryClear', 'rowNodes'],
  components: {
    agGridVue: AgGridVue,
    gridViewManager,
    loading,
    gridHeader,
    WiskGridSelectedRowsActionsPanel,
    CellPopMultiselect,
    CellHeader,
    CellDynamicLinkInText,
    CellExternalLink,
    CellBadge,
    CellIngredients,
    CellUnits,
    CellOrderUnitsEditor,
    CellMenu,
    CellModifiers,
    CellText,
    CellWiskItems,
    CellCheckbox,
    CellNumber,
    CellAttachments,
    CellImage,
    CellMoreDetails,
    CellCostPercentage
  },
  props: {
    bottomAggregationRow: Boolean,
    columnDefs: { default: () => [], type: Array },
    customFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function'
    },
    defaultFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function'
    },
    excel: { default: true, type: [Object, Boolean] },
    extraLines: Array,
    fitInAppBody: Boolean,
    hideViewsInReportsDashboard: Boolean,
    doesExternalFilterPassOverride: Function,
    searchFieldGeneratorParams: { default: () => ({}), type: Object },
    gridAutoHeight: Boolean,
    gridOptions: { default: () => ({}), type: Object },
    gridStyle: {
      default: () => ({
        height: '255px'
      }),
      type: Object
    },
    groupCheckboxSelection: Boolean,
    checkBoxSelection: Boolean,
    isRowSelectable: {
      type: Function,
      default: rowNode => !rowNode?.data?.wiskRowHidden
    },
    grouping: { default: () => ({}), type: Object },
    header: Object,
    height: Number,
    loadingOverlay: { default: false, type: Boolean },
    noFloatingPanel: Boolean,
    parentGridName: { required: true, type: String },
    infoTooltipBaseName: String,
    preSelectedView: [Object, String],
    saveSelectedView: { type: Boolean, default: true },
    resetRows: Boolean,
    rowData: { default: () => [], type: Array },
    searchLabel: String,
    searchQuery: String,
    selectedRowsActions: Array,
    showFoundArchived: Boolean,
    trackBy: { default: '', type: String },
    viewSelectorCols: { default: 2, type: Number }
  },
  data() {
    return {
      gridApi: null,
      loading: false,
      unMounted: false,
      debug: 0,
      rowDataLocal: markRaw([]),
      gridPanelPinned: false,
      actionsPanelOpen: false,
      windowResizeTimeoutId: null,
      agGridModules: [ClientSideRowModelModule, ExcelExportModule, RowGroupingModule, SideBarModule],
      gridKey: 1,
      groupsCount: 0,
      headerHeight: 80,
      gridHeight: 0,
      archivedItemsCount: 0,
      forceReactOnViewChange: false,
      currentView: null,
      firstViewSetDone: false,
      rowDataModified: false,
      options: {},
      filterConfig: { filters: [] },
      columns: [],
      visibleColumns: [],
      collapsedWatchTimeoutId: null,
      visibleColumnsTimeoutId: null,
      updateViewTimeoutId: null,
      displayedColumnsChangedTimeoutId: null,
      refreshFilteredRowsTimeoutId: null,
      filterChangedTimeoutId: null,
      stopFilterChangesListener: null,
      modelUpdatedTimeoutId: null,
      saveColumnsInfoTimeoutId: null,
      applyCurrentViewTimeoutId: null,
      sizeToFitTimeoutId: null,
      firstDataRenderedTimeoutId: null,
      handlePinnedColumnShadowTimeoutId: null,
      groupsCollapsedByViewId: {},
      resizeHeightTimeoutId: null,
      searchText: null,
      multiPanelParams: null,
      safeTimers: [],
      optionsInitDone: false,
      mounted: false,
      rowNodes: []
    }
  },
  computed: {
    ...mapGetters(['venue', 'deviceType']),
    ...mapState(['translations', 'windowResizeKey']),
    computedGridHeight() {
      return { height: this.gridAutoHeight ? '100%' : `${this.gridHeight}px` }
    },
    gridDOMId() {
      return `wisk-grid-${guid()}`
    },
    computedGridStyle() {
      return { ...this.gridStyle, ...this.computedGridHeight, 'padding-left': this.gridPanelPinned ? '225px' : '', position: 'relative' }
    },
    showVenueNameInGridExports() {
      return get(this.currentView, 'configs.showVenueNameInGridExports', false)
    },
    canPrepareGrid() {
      return !!this.gridApi && this.mounted
    },
    defaultColDef() {
      //we use object returned from a method in order to avoid agGrid writing to it
      return getDefaultColDef()
    },
    externalFilter() {
      return this.customFilter || this.defaultFilter
    },
    gridName() {
      return this.parentGridName || this.$parent.$options.name
    },
    currentViewId() {
      return this.currentView?.id
    },
    savedViews() {
      return null
    },
    columnDefsById() {
      if (this.columnDefs) {
        return arrayToObjectById(this.columnDefs, 'colId')
      }
      return {}
    },
    systemViews() {
      return {}
    }
  },
  methods: {
    prepareGrid() {
      if (this.gridApi) {
        this.multiPanelParams = { gridApi: this.gridApi }
      } else {
        this.multiPanelParams = null
      }

      this.handlePinnedColumnShadowTimeoutId = setTimeout(() => {
        this.handlePinnedColumnShadow()
      }, 1000)
    },
    gridReady(event) {
      this.gridApi = markRaw(event.api)
      this.prepareGrid()
      this.gridApi.setSideBarVisible(false)
      this.gridApi.closeToolPanel()
      this.$emit('gridApi', this.gridApi)
    },
    computeGridHeight(caller) {
      if (this.debug) {
        console.log('computeGridHeight', caller)
      }
      const divElement = this.$refs.thisGridContainer,
        modalElement = divElement && divElement.closest('.modal-body')

      let height = this.height || 800

      if (this.gridAutoHeight && modalElement) {
        const divRect = divElement.getBoundingClientRect(),
          modalRect = modalElement.getBoundingClientRect()


        if (divRect.top >= modalRect.top && divRect.bottom <= modalRect.bottom) {
          height = divRect.height
        } else if (divRect.top < modalRect.top) {
          height = divRect.bottom - modalRect.top
        } else if (divRect.bottom > modalRect.bottom) {
          height = modalRect.bottom - divRect.top
        } else {
          height = Math.min(divRect.bottom, modalRect.bottom) - Math.max(divRect.top, modalRect.top)
        }
      } else {
        if (this.gridStyle?.height) {
          if (this.gridStyle.height.includes('calc(100vh')) {
            let arr = this.gridStyle.height.split('-')
            height = window.innerHeight - parseInt(arr[1], 10)
          } else if (!this.gridStyle.height.includes('%')) {
            height = parseInt(this.gridStyle.height, 10)
          }
        }
        if (this.fitInAppBody) {
          let appBody = document.getElementById('app-body') || {}
          height = appBody.clientHeight || 0
        }

        height = height - (this.header && !this.header.hideHeader ? this.headerHeight : 0)
      }

      if (height) {
        this.gridHeight = height - 60
      }
    },
    onWindowResize() {
      if (this.firstViewSetDone) {
        setTimeout(() => {
          this.sizeColumnsToFit(this.gridApi, 'onWindowResize')
        }, 400)
      }
      this.handlePinnedColumnShadow()
    },
    setGridPanel(id) {
      setTimeout(() => {
        if (this.gridApi) {
          if (id) {
            this.gridApi.setSideBarVisible(true)
            this.gridApi.openToolPanel(id)

            if (id === 'actions') {
              this.actionsPanelOpen = true
            }
          } else {
            this.gridApi.setSideBarVisible(false)
            this.gridApi.closeToolPanel()
            this.actionsPanelOpen = false
          }
        }
      }, 0)
    },
    displayedColumnsChanged() {
      this.$emit('displayedColumnsChanged')
      clearTimeout(this.displayedColumnsChangedTimeoutId)
      this.displayedColumnsChangedTimeoutId = setTimeout(() => {
        if (!this.unMounted) {
          this.sizeColumnsToFit(this.gridApi, 'displayedColumnsChanged')

          let state = [],
            groupedCount = 0

            if (this.gridApi?.getColumnState) {
              state = this.gridApi.getColumnState()
              groupedCount = state.filter(c => typeof c.rowGroupIndex === 'number').length
            }

          this.groupsCount = groupedCount
        }
      }, 500)
    },
    rowGroupOpened() {
      this.saveCollapsedState('rowGroupOpened')
    },
    saveCollapsedState(caller) {
      if (this.debug) {
        console.log('saveCollapsedState', caller)
      }
      this.groupsCollapsedByViewId[this.currentViewId].collapsed = true

      if (this.rowNodes?.length) {
        this.rowNodes.forEach(node => {
          if (node.group && node.expanded) {
            this.groupsCollapsedByViewId[this.currentViewId].collapsed = false
          }
        })
      }
      this.groupsCollapsedByViewId = merge({}, this.groupsCollapsedByViewId)
    },
    switchToView(view) {
      this.currentView = merge({}, view)
      this.groupsCollapsedByViewId[view.id] = this.groupsCollapsedByViewId[view.id] || { byId: {} }

      this.applyCurrentView()

      if (!this.unMounted) {
        setTimeout(() => {
          this.saveCollapsedState('switchToView')
        }, 0)
      }
    },
    applyCurrentView() {
      clearTimeout(this.applyCurrentViewTimeoutId)
      this.applyCurrentViewTimeoutId = setTimeout(() => {
        if (!this.unMounted && this.currentView && this.gridApi) {
          let nowState = this.gridApi.getColumnState().map(c => ({ ...c, rowGroupIndex: null, rowGroup: null, hide: null, sort: null })),
            merged = mergeArrayOfObjects(nowState, this.currentView.columns, 'colId'),
            columnsById = arrayToObjectById(this.columnDefs, 'colId'),
            groupsCount = merged.filter(c => typeof c.rowGroupIndex === 'number').length

          if (groupsCount && !merged.find(c => c.colId === 'ag-Grid-AutoColumn')) {
            merged.push({
              colId: 'ag-Grid-AutoColumn'
            })
          }

          merged.forEach((col, index) => {
            let colDef = columnsById[col.colId] || {}
            col.sortOrder = col.sortOrder || colDef.sortOrder || index * 100

            if (col.colId === 'more') {
              col.sortOrder = 9999999
            }
            if (col.colId === 'ag-Grid-ControlsColumn') {
              col.sortOrder = -2
            }
            if (col.colId === 'dropdownMenu') {
              col.sortOrder = -3
            }
            if (col.colId === 'ag-Grid-AutoColumn') {
              col.sortOrder = -1
            }
            if (col.colId === '0') {
              col.sortOrder = -4
              col.suppressColumnsToolPanel = true
              col.hide = true
            }

            if (col.hide === undefined || col.hide === null) {
              col.hide = colDef.hide
            }

            if (colDef.suppressColumnsToolPanel && !colDef.hide) {
              col.hide = false
            }

            if (colDef.suppressColumnsToolPanel && colDef.hide) {
              col.hide = true
            }

            delete col.aggFunc
          })
          merged.sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder))

          this.gridApi.applyColumnState({ state: merged, applyOrder: true })

          this.currentViewComparator = merge({}, this.currentView)
        }
      }, 500)
    },
    computeBottomAggregation(nodes) {
      if (this.bottomAggregationRow && nodes?.length) {
        let bottomAggregation = {},
          columnsComputed = this.columns.filter(c => typeof c.wiskGetBottomAggregationValue === 'function'),
          columnsDirect = this.columns.filter(c => c.wiskBottomAggregation)

        columnsDirect.forEach(column => {
          bottomAggregation[column.colId] = column.wiskBottomAggregation

          if (column.field) {
            setValueAtPath(bottomAggregation, column.field, bottomAggregation[column.colId])
          }
        })

        columnsComputed.forEach(column => {
          bottomAggregation[column.colId] = this.computeTotal(column, nodes)

          if (column.field) {
            setValueAtPath(bottomAggregation, column.field, bottomAggregation[column.colId])
          }
        })

        this.gridApi.setGridOption('pinnedBottomRowData', [bottomAggregation])
      }
    },
    computeTotal(column, nodes) {
      let value = 0,
        leafRows = [],
        found = {},
        getLeafNodesRecursive = node => {
          if (node && node.group && node.childrenAfterFilter && node.childrenAfterFilter.length) {
            node.childrenAfterFilter.forEach(inner => {
              getLeafNodesRecursive(inner)
            })
          } else if (node.data && !found[node.id]) {
            leafRows.push(node)
            found[node.id] = true
          }
        }

      nodes.forEach(node => {
        getLeafNodesRecursive(node)
      })

      leafRows.forEach(node => {
        value += column.wiskGetBottomAggregationValue(node.data) || 0
      })

      return value
    },
    onSelectionChanged(nodes) {
      let rows = nodes.map(n => n.data)
      this.$emit('selectedRowsChanged', rows)

      this.setGridPanel(rows.length ? 'actions' : null)
    },
    exportPDF() {
      exportPDF(this.gridApi, {
        translate: this.translations.translate,
        headerText: `${this.translations.txtVenueOperationsName}:  ${this.venue.title}`,
        fileName: sanitize(
          `${this.gridName}-${this.venue.title}-exported-${formatDate(new Date(), { format: 'dd-LL-yyyy--HH-mm-ss' })}.pdf`
        )
      })
    },
    exportExcel({ header }) {
      if (!Array.isArray(header)) {
        header = this.excel.header || []
      }
      if (!header.length) {
        header = [[this.translations.txtVenueOperationsName], [this.venue.title]]
      }

      let params = {
        header,
        fileName: sanitize(
          `${(this.excel && this.excel.fileName) || this.gridName}-${this.venue.title}-exported-${formatDate(new Date(), { format: 'dd-LL-yyyy--HH-mm-ss' })}.xlsx`
        ),
        sheetName: (this.excel && this.excel.sheetName) || 'Export',
        rowHeight: 25,
        headerRowHeight: 35
      }

      this.gridApi && exportExcel(this.gridApi, params)
    },
    modelUpdated() {
      clearTimeout(this.modelUpdatedTimeoutId)
      this.modelUpdatedTimeoutId = setTimeout(() => {
        if (!this.unMounted && this.gridApi) {
          let visibleRows = [],
            nodes = []

          this.gridApi.forEachNodeAfterFilterAndSort(node => {
            nodes.push(node)
          })

          this.computeBottomAggregation(nodes)

          nodes.forEach(node => {
            this.fillVisibleRowsRecursive(visibleRows, node)
          })

          //remove duplicates
          let map = arrayToObjectById(visibleRows, this.trackBy || 'id')

          this.rowNodes = nodes
          this.$emit('visibleRows', Object.values(map))
          this.$emit('rowNodes', nodes)
        }
      }, 600)
    },
    gridfilterChanged() {
      console.time('Performance investigation * wisk grid first data rendered')
      clearTimeout(this.filterChangedTimeoutId)
      this.filterChangedTimeoutId = setTimeout(() => {
        if (this.searchText) {
          let archivedWithSearchField = this.rowData.filter(item => item?.archived)
            .map(item => ({ ...item, search_field: getStringForSearchRecursive(merge({ payload: item, stopAtLevel: 2, currentLevel: 0, propertiesToExclude: ['title_for_search'] }, this.searchFieldGeneratorParams)) }))

          this.archivedItemsCount = archivedWithSearchField.filter(item => stringFilter('contains', item.search_field, this.searchText)).length
        } else {
          this.archivedItemsCount = 0
        }
      }, 1000)
    },

    fillVisibleRowsRecursive(visibleRowsToFill, node) {
      if (node.data && this.doesExternalFilterPass(node)) {
        visibleRowsToFill.push(node.data)
      } else if (node.allLeafChildren && node.allLeafChildren.length) {
        node.allLeafChildren.forEach(leaf => {
          this.fillVisibleRowsRecursive(visibleRowsToFill, leaf)
        })
      }
    },
    requestFilter(name) {
      this.$emit('requestFilter', name)
    },
    updateSearchText(value) {
      if (this.searchQuery && !value) {
        this.$emit('searchQueryClear')
      }

      this.searchText = value
      this.gridApi && this.gridApi.onFilterChanged()
    },
    setFilterConfig(filterConfig) {
      this.filterConfig = filterConfig
      this.gridApi && this.gridApi.onFilterChanged()
    },
    doesExternalFilterPass(node) {
      let finalResult = true
      if (this.searchQuery || (this.header && !this.header.hideHeader && !this.header.hideSearch)) {
        if (this.searchText) {
          finalResult = stringFilter('contains', get(node, 'data.search_field', ''), this.searchText)
        }
      }

      if (finalResult && this.filterConfig?.filters?.length) {
        if (this.filterConfig.combineClause) {
          let result = this.filterConfig.combineClause === 'and'
          for (let i = 0; i < this.filterConfig.filters.length; i++) {
            let f = this.filterConfig.filters[i],
              cellValue = this.gridApi.getCellValue({ colKey: f.colId, rowNode: node }),
              trackBy = get(this.columnDefsById, `${f.colId}.cellEditorParams.trackBy`, get(this.columnDefsById, `${f.colId}.cellRendererParams.trackBy`, 'id')),
              innerResult = gridFilters[f.dataType]?.[f.operation]?.(f.filterValue, cellValue, trackBy)

            if (this.filterConfig.combineClause === 'and') {
              result = result && innerResult
            }
            if (this.filterConfig.combineClause === 'or') {
              result = result || innerResult
              if (result) {
                i = this.filterConfig.filters.length
              }
            }
          }
          finalResult = result
        } else {
          let f = this.filterConfig.filters[0],
            trackBy = get(this.columnDefsById, `${f.colId}.cellEditorParams.trackBy`, get(this.columnDefsById, `${f.colId}.cellRendererParams.trackBy`, 'id')),
            cellValue = this.gridApi.getCellValue({ colKey: f.colId, rowNode: node })

          if (typeof gridFilters[f.dataType][f.operation] === 'function') {
            finalResult = gridFilters[f.dataType][f.operation](f.filterValue, cellValue, trackBy)
          }
        }
      }

      if (this.doesExternalFilterPassOverride) {
        return this.doesExternalFilterPassOverride(node, finalResult)
      }

      return finalResult
    },
    firstDataRendered() {
      this.$emit('firstDataRendered')

      this.firstDataRenderedTimeoutId = setTimeout(() => {
        if (!this.unMounted && this.gridApi) {
          this.expandFirstGroupRecursive([this.gridApi.getDisplayedRowAtIndex(0)])
        }
      }, 1000)
    },
    expandFirstGroupRecursive(nodes) {
      if (nodes && nodes.length) {
        let node = nodes[0]

        if (node && node?.rowGroupColumn?.colDef?.wiskExpandFirst) {
          node.setExpanded(true)
          this.expandFirstGroupRecursive(node.childrenAfterSort)
          this.groupsCollapsedByViewId[this.currentViewId].collapsed = false
          this.groupsCollapsedByViewId = merge({}, this.groupsCollapsedByViewId)
        }
      }
    },
    toolPanelVisibleChanged(event) {
      this.sizeColumnsToFit(event.api, 'toolPanelVisibleChanged')
    },
    sizeColumnsToFit(agGridApi, caller) {
      if (this.debug) {
        console.log('*sizeColumnsToFit', caller)
      }
      clearTimeout(this.sizeToFitTimeoutId)
      this.sizeToFitTimeoutId = setTimeout(() => {
        if (this.debug) {
          console.log('*sizeColumnsToFit in timeout', caller)
        }
        if (!this.unMounted && agGridApi) {
          agGridApi.sizeColumnsToFit()
          this.setVisibleColumns(agGridApi)

          this.resizeHeightTimeoutId = setTimeout(() => {
            this.computeGridHeight('sizeColumnsToFit')
          }, 1000)
        }
      }, 300)
    },
    createColumnDefs() {
      if (this.columnDefs) {
        let columns = this.columnDefs
          .map((item, index) => {
            let local = {
              headerTooltip: item.headerTooltip || item.headerName,
              sortOrder: item.sortOrder || (1 + index) * 100,
              headerComponentParams: {
                helpKey: 'grid__' + (this.infoTooltipBaseName || this.parentGridName) + '__' + item.colId
              },
              comparator: createComparatorWithColId(item.colId, item.comparator) //if a column has a comparator it means it is custom
            }

            if (item.colId === 'more') {
              local.pinned = 'right'
            }

            if (item.filter !== undefined) {
              local.filter = item.filter
            }
            if (item.suppressFiltersToolPanel) {
              local.filter = null
            }
            if (item && item.field && !item.tooltipValueGetter) {
              if (item.valueFormatter) {
                local.tooltipValueGetter = params => item.valueFormatter(params) || ''
              } else {
                local.tooltipField = item.field
              }
            }

            if (!item.filterType) {
              if (item.filter === 'agTextColumnFilter') {
                item.filterType = 'text'
              } else if (item.filter === 'agNumberColumnFilter') {
                item.filterType = 'number'
              } else if (item.filter === 'agDateColumnFilter') {
                item.filterType = 'date'
              } else if (typeof item.cellRenderer === 'string') {
                switch (item.cellRenderer) {
                  case 'CellPopMultiselect':
                    item.filterType = 'list'
                    break
                  case 'CellCheckbox':
                    item.filterType = 'bool'
                    break
                  case 'CellText':
                    item.filterType = 'text'
                    break
                  case 'CellNumber':
                  case 'CellUnits':
                    item.filterType = 'number'
                    break
                  default:
                    break
                }
              } else if (item.cellEditor === 'CellPopMultiselect') {
                item.filterType = 'list'
              }
            }

            const computeCellClass = c => {
              c.headerClass = c.headerClass || []
              c.cellClass = c.cellClass || []

              if (!Array.isArray(c.headerClass)) {
                console.warn('c.headerClass is not array!!!', c)
              }
              if (typeof c.headerClass === 'string') {
                c.headerClass = [c.headerClass]
              }

              if (c.aggFunc === 'wiskSum' && Array.isArray(c.cellClass)) {
                c.cellClass.push('text-end')
              }

              if (!Array.isArray(c.cellClass) && typeof c.cellClass !== 'function') {
                console.warn('c.cellClass is not array or function!!!', c.cellClass, c)
              }

              if (Array.isArray(c.cellClass)) {
                if (c.filterType === 'number' || c.cellClass.includes('text-end')) {
                  if (Array.isArray(c.headerClass)) {
                    c.headerClass.push('text-end')
                  }
                }
                c.cellClass.push('cellStyle')
              } else if (typeof c.cellClass === 'function') {
                let oldFunc = c.cellClass,
                  newFunc = params => {
                    let result = oldFunc(params)
                    if (typeof result === 'string') {
                      result = [result]
                    }
                    if (Array.isArray(result)) {
                      result.push('cellStyle')
                    }

                    if (c.filterType === 'number' || result.includes('text-end')) {
                      if (Array.isArray(c.headerClass)) {
                        c.headerClass.push('text-end')
                      }
                    }
                    return result
                  }
                c.cellClass = newFunc
              } else if (!c.cellClass) {
                c.cellClass = ['cellStyle']
                if (c.filterType === 'number' && Array.isArray(c.headerClass)) {
                  c.headerClass.push('text-end')
                }
              }
            }
            if (item && item.children && item.children.length) {
              item.children = item.children.filter(child => !child.remove).sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder))
              item.children.forEach(child => {
                child.headerTooltip = child.headerTooltip || child.headerName
                computeCellClass(child)
              })
            } else {
              computeCellClass(item)
            }

            return merge({}, item, local)
          })
          .filter(item => !item.remove)
          .sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder))

        let foundWithoutColId = columns.find(c => !c.colId)
        if (foundWithoutColId) {
          console.error('!!! Found column without colId !!!', foundWithoutColId, this.gridName)
        }

        return columns
      }

      return []
    },
    setVisibleColumns(agGridApi) {
      clearTimeout(this.visibleColumnsTimeoutId)
      this.visibleColumnsTimeoutId = setTimeout(() => {
        let columns = agGridApi?.getColumns?.()

        if (!this.unMounted && columns?.length) {
          this.visibleColumns = columns
            .filter(c => c.visible)
            .map(column => {
              let cellRenderer = get(column, 'colDef.cellRenderer', null),
                cellClass = get(column, 'colDef.cellClass', [])

              if (!Array.isArray(cellClass)) {
                cellClass = []
              }

              return {
                colId: this.getColId(column),
                width: column.actualWidth,
                cellClass: uniq([...cellClass, (['CellNumber', 'CellUnits']).includes(cellRenderer) ? 'text-end' : '']),
              }
            })
        }
      }, 300)
    },
    getColId(item) {
      //it's not consistent what each grid will send as item
      item = item.colDef || item
      let colId = 'notFound'
      try {
        colId = item.colId
        colId = colId || item.field || item.headerName.replace(/ /g, '_').toLowerCase()
      } catch (error) {
        console.log('colId not found on item', item)
      }

      return colId
    },
    handleCollapsed(caller) {
      if (this.debug) {
        console.log('handleCollapsed', caller)
      }
      if (this.gridApi) {
        if (this.groupsCollapsedByViewId[this.currentViewId].collapsed) {
          this.gridApi.collapseAll()
        } else {
          this.gridApi.expandAll()
        }
        setTimeout(() => {
          this.gridApi.forEachNodeAfterFilterAndSort(node => {
            let children = node.allLeafChildren
            if (children && children.length === 1) {
              let hidden = children[0].data.wiskRowHidden
              if (hidden) {
                node.setExpanded(false)
              }
            }
          })
        }, 0)

        this.saveCollapsedState('handleCollapsed')
      } else {
        clearTimeout(this.collapsedWatchTimeoutId)
        this.collapsedWatchTimeoutId = setTimeout(() => {
          !this.unMounted && this.handleCollapsed('retry timeout this.gridApi was missing')
        }, 300)
      }
    },
    refreshRowDataDebounced(caller) {
      if (this.debug) {
        console.log('refreshRowDataDebounced', caller)
      }
      clearTimeout(this.updateViewTimeoutId)
      this.updateViewTimeoutId = setTimeout(() => {
        if (this.debug) {
          console.log('refreshRowDataDebounced in timeout', caller)
        }

        if (this.mounted && this.gridApi) {
          this.refreshRowData()
        }
      }, 300)
    },
    refreshRowData() {
      if (this.debug) {
        console.log('this.rowDataLocal.length', this.rowDataLocal.length)
        console.log('refreshRowData')
      }
      if (!this.rowDataModified || this.resetRows) {
        console.time('Performance investigation * refreshRowData (!this.rowDataModified || this.resetRows)')
        let rowDataWithSearchField = this.rowDataLocal.map((r, index) => {
          if (!r) {
            r = {}
            Sentry.withScope(scope => {
              scope.setExtra('index', index)
              scope.setExtra('Grid name', this.gridName)
              scope.setExtra('URL', window.location.href)
              Sentry.captureException(new Error('rowData item is missing'))
            })
          }
          return {
            ...r,
            search_field: getStringForSearchRecursive(merge({
              payload: r,
              stopAtLevel: 2,
              currentLevel: 0,
              propertiesToExclude: ['title_for_search']
            }, this.searchFieldGeneratorParams))
          }
        })

        this.gridApi.setGridOption('rowData', rowDataWithSearchField)

        console.timeEnd('Performance investigation * refreshRowData (!this.rowDataModified || this.resetRows)')
      } else {
        console.time('Performance investigation * refreshRowData')
        let add = [],
          update = [],
          remove = [],
          id = this.trackBy || (this.rowDataLocal.length && this.rowDataLocal[0] && this.rowDataLocal[0].item_id ? 'item_id' : 'id'),
          handledCheckMap = {},
          withSearchField = Array.isArray(this.rowDataLocal) ? this.rowDataLocal.map(r => ({
            ...r,
            search_field: getStringForSearchRecursive(merge({
              payload: r,
              stopAtLevel: 2,
              currentLevel: 0,
              propertiesToExclude: ['title_for_search']
            }, this.searchFieldGeneratorParams))
          })) : [],
          rowDataMap = arrayToObjectById(withSearchField, id, true)

        this.gridApi.forEachNode(node => {
          if (node?.data && node.data[id]) {
            let data = rowDataMap[node.data[id]],
              copy1 = { ...data },
              copy2 = { ...node.data }

            delete copy1.search_field
            delete copy2.search_field


            if (data) {
              if (!isEqual(copy1, copy2)) {
                update.push((data))

                // if (this.parentGridName === 'some grid name to investigate changes in data') {
                //   let diff = detailedDiff(copy1, copy2)
                //   console.log('refreshRowData diff', diff)
                // }
              }
              handledCheckMap[node.data[id]] = true
            } else {
              remove.push(node.data)
            }
          }
        })

        Object.keys(rowDataMap).forEach(key => {
          let data = rowDataMap[key]
          if (!handledCheckMap[key]) {
            add.push((data))
          }
        })

        this.gridApi.applyTransaction({ add, update, remove })
        console.timeEnd('Performance investigation * refreshRowData (!this.rowDataModified || this.resetRows)')
      }

      this.gridApi && this.gridApi.onFilterChanged()
    },
    refreshFilteredRowsDebounced(caller) {
      if (this.debug) {
        console.log('refreshFilteredRowsDebounced', caller)
      }
      clearTimeout(this.refreshFilteredRowsTimeoutId)
      this.refreshFilteredRowsTimeoutId = setTimeout(() => {
        if (this.debug) {
          console.log('refreshFilteredRowsDebounced in timeout', caller)
        }
        if (this.mounted && this.gridApi) {
          this.refreshFilteredRows(caller)
        }
      }, 100)
    },
    refreshFilteredRows(caller) {
      if (this.debug) {
        console.log('refreshFilteredRows caller', caller, this.externalFilter)
      }
      if (this.stopFilterChangesListener) {
        this.stopFilterChangesListener()
      }

      if (this.externalFilter?.predicate && this.externalFilter.fetchDataAsync) {
        this.rowDataLocal = []

        if (this.externalFilter.setLoading) {
          this.externalFilter.setLoading(true)
        }
        this.externalFilter.fetchDataAsync().then(data => {
          this.rowDataLocal = data.filter(row => this.externalFilter.predicate(row)).map(item => markRaw(item))
          if (this.canPrepareGrid) {
            this.refreshRowDataDebounced('refreshFilteredRows fetchDataAsync')
            this.rowDataModified = true
          }
        }).catch(e => {
          console.log('refreshFilteredRows error', e)
        }).finally(() => {
          if (this.externalFilter.setLoading) {
            this.externalFilter.setLoading(false)
          }
        })

        if (this.externalFilter.changesListener) {
          this.stopFilterChangesListener = this.externalFilter.changesListener(this.refreshFilteredRowsDebounced)
        }
      } else if (this.externalFilter?.predicate) {
        this.rowDataLocal = this.rowData.filter(row => this.externalFilter.predicate(row)).map(item => markRaw(item))
      } else {
        this.rowDataLocal = this.rowData.map(item => markRaw(item))
      }

      if (this.canPrepareGrid && !this.externalFilter?.fetchDataAsync) {
        this.refreshRowDataDebounced('refreshFilteredRows')
        this.rowDataModified = true
      }

      if (this.debug) {
        console.log('this.rowDataLocal.length', this.rowDataLocal.length)
      }
    },
    getSelectedRowsActions() {
      return this.selectedRowsActions
    },
    saveCurrentView() {
      if (this.$refs.gridViewManagerInstance?.save) {
        this.$refs.gridViewManagerInstance.save()
      }
    },
    prepareGridOptions() {
      this.options = merge(
        {},
        getGridOptions(
          {
            options: {
              onSelectionChanged: event => {
                if (event?.api) {
                  this.onSelectionChanged(event.api.getSelectedNodes().filter(this.doesExternalFilterPass))
                }
              },
              domLayout: this.gridAutoHeight ? 'autoHeight' : 'normal'
            },
            trackBy: this.trackBy
          }
        ),
        this.gridOptions, //override from outside
        {
          //can't be overriden from outside
          context: {
            saveCurrentView: this.saveCurrentView,
          },
          rowSelection: {
            groupSelects: this.groupCheckboxSelection ? 'filteredDescendants' : 'self',
          },
          autoGroupColumnDef: {
            cellRendererParams: {
              translate: this.translations.translate
            }
          },
          sideBar: {
            toolPanels: [
              { toolPanelParams: { getWiskSelectedRowsActions: this.getSelectedRowsActions, doesExternalFilterPass: this.doesExternalFilterPass } } //row actions
            ]
          },
          onBodyScrollEnd: (e) => {
            if (e.direction === 'horizontal') {
              this.handlePinnedColumnShadow()
            }
          }
        }
      )

      if (this.checkBoxSelection) {
        this.options.rowSelection = this.options.rowSelection || {}
        this.options.rowSelection.mode = 'multiRow'
        this.options.rowSelection.checkboxes = true
        this.options.rowSelection.isRowSelectable = this.options.rowSelection.isRowSelectable || this.isRowSelectable

        //TODO: check if we need to set as false if checBoxSelection is false
      }


      //no reason to keep sidebar prepared if no buttons to open it
      if (!this.header || this.header.hideHeader) {
        this.options.sideBar = false
      }

      if (this.debug) {
        console.log('this.options', JSON.parse(JSON.stringify(this.options)))
      }

      this.optionsInitDone = true
    },
    handlePinnedColumnShadow() {
      if (this.gridApi) {
        const parent = document.getElementById(this.gridDOMId)

        if (parent) {
          const el = parent.querySelector('.ag-body-horizontal-scroll .ag-body-horizontal-scroll-viewport'),
            scrollLeft = el.scrollLeft,
            width = el.clientWidth,
            scrollWidth = el.scrollWidth

          if (scrollWidth - Math.ceil(scrollLeft) <= width) {
            parent.querySelector('.ag-pinned-right-header').style.boxShadow = 'unset'
            parent.querySelector('.ag-pinned-right-cols-container').style.boxShadow = 'unset'
          } else {
            parent.querySelector('.ag-pinned-right-header').style.boxShadow = styles.getPropertyValue('--pinned-right-header-box-shadow')
            parent.querySelector('.ag-pinned-right-cols-container').style.boxShadow = styles.getPropertyValue('--pinned-right-cols-box-shadow')
          }
        }
      }
    }
  },
  created() {
    this.prepareGridOptions()
    this.gridHeight = parseInt(this.gridStyle?.height, 10) || 255
  },
  mounted() {
    console.time('Performance investigation * wisk grid mounted => grid ready')
    this.mounted = true
    this.columns = this.createColumnDefs()
    this.computeGridHeight('mounted')
  },
  beforeUnmount() {
    this.unMounted = true
    this.mounted = false

    // if (this.gridApi && this.gridApi.destroy && !this.gridApi.isDestroyed()) {
    //   this.gridApi.destroy()
    // }

    this.gridApi = null
    clearTimeout(this.displayedColumnsChangedTimeoutId)
    clearTimeout(this.saveColumnsInfoTimeoutId)
    clearTimeout(this.firstDataRenderedTimeoutId)
    clearTimeout(this.filterChangedTimeoutId)
    clearTimeout(this.sizeToFitTimeoutId)
    clearTimeout(this.updateViewTimeoutId)
    clearTimeout(this.collapsedWatchTimeoutId)
    clearTimeout(this.modelUpdatedTimeoutId)
    clearTimeout(this.resizeHeightTimeoutId)
    clearTimeout(this.visibleColumnsTimeoutId)
    clearTimeout(this.refreshFilteredRowsTimeoutId)
    clearTimeout(this.handlePinnedColumnShadowTimeoutId)
    this.stopFilterChangesListener && this.stopFilterChangesListener()
  },
  watch: {
    gridName() {
      this.gridApi = null
      this.unMounted = true
      this.mounted = false
      this.gridKey++

      setTimeout(() => {
        this.unMounted = false
        this.mounted = true
      }, 0)
    },
    windowResizeKey: 'onWindowResize',
    gridPanelPinned: 'onWindowResize',
    gridStyle() {
      this.computeGridHeight('gridStyle')
    },
    searchQuery: {
      immediate: true,
      deep: true,
      handler: 'updateSearchText'
    },
    columnDefs() {
      this.columns = this.createColumnDefs()
      this.applyCurrentView()

      setTimeout(() => {
        if (!this.unMounted && this.gridApi) {
          this.gridApi.resetRowHeights()
        }
      }, 0)
    },
    externalFilter() {
      this.gridApi && this.gridApi.deselectAll()
      this.gridApi && this.refreshFilteredRowsDebounced('externalFilter watcher')
    },
    canPrepareGrid() {
      if (this.canPrepareGrid) {
        this.refreshRowDataDebounced('canPrepareGrid watcher')
      }
    },
    rowData: {
      immediate: true,
      deep: true,
      handler() {
        this.refreshFilteredRowsDebounced('rowData watcher')
      }
    },
    customFilter(newVal, oldVal) {
      if (((!newVal && oldVal) || (newVal && oldVal && newVal.name !== oldVal.name)) && this.$route.query.preselectFilter) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            preselectFilter: undefined
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-grid-container {
  width: 100%;
  height: 100%;
  position: relative;

  .grid-views-switch {
    .material-design-input-multiselect-as-buttons {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .loadingState {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(256, 256, 256, 0.66);

    div {
      background-color: white;
      border: 1px solid #83afde;
      color: #181818;
      padding: calc(4px * 4);
      font-size: 12px;
    }
  }

  .wisk-grid {
    width: 100%;
    height: 100%;

    .ag-root {
      .form-control {
        border: none;
      }
    }

    .ag-side-bar {
      .ag-side-buttons {
        padding-top: 40px;
      }
    }

    .pinned-row {
      background-color: transparent;
      font-weight: 600;
      border: 1px dotted silver;

      .ag-cell-focus {
        border: none;
        border-right: 1px dotted silver;
      }
    }

    .ag-body-viewport.ag-layout-auto-height {
      overflow: hidden;
    }

    .ag-group-contracted,
    .ag-group-expanded {
      float: left;
      pointer-events: none;

      padding: 5px;
    }

    .ag-root .ag-row {
      border-bottom: 1px solid transparent;
    }
  }

  .wisk-grid-bottom-extra-line {
    display: flex;
    background-color: transparent;
    font-weight: 600;
    background-color: #f0f3f5;
    border-top: 1px solid white;

    .has-content {
      padding-left: 11px;
      padding-right: 11px;
      border-right: 1px solid white;

      &:nth-of-type(1) {
        border-right: none;
      }
    }
  }

  .wisk-grid-bottom-aggregation {
    display: flex;
    background-color: transparent;
    font-weight: 600;
    background-color: #f0f3f5;

    .has-content {
      padding-left: 11px;
      padding-right: 11px;
      border-right: 1px solid white;

      &:nth-of-type(1) {
        border-right: none;
      }
    }
  }

  .info-row {
    background-color: var(--blue-200) !important;

    // &:nth-child(even) {
    //   background-color: var(--blue-200) !important;
    // }

    // &.ag-row-hover {
    //   background-color: var(--blue-300) !important;
    // }

    // &.ag-row-selected {
    //   background-color: var(--blue-400) !important;
    // }
  }

  .warning-row {
    background-color: var(--yellow-100) !important;

    &:nth-child(even) {
      background-color: var(--yellow-100) !important;
    }

    &.ag-row-hover {
      background-color: var(--yellow-300) !important;
    }

    &.ag-row-selected {
      background-color: var(--yellow-400) !important;
    }
  }

  .danger-row {
    background-color: var(--red-200) !important;

    &:nth-child(even) {
      background-color: var(--red-200) !important;
    }

    &.ag-row-hover {
      background-color: var(--red-400) !important;
    }

    &.ag-row-selected {
      background-color: var(--red-500) !important;
    }
  }


  &.bottom-aggregation {
    .ag-theme-balham {
      .ag-root-wrapper {
        box-shadow: none;
      }
    }

    .wisk-grid-bottom-aggregation {
      box-shadow: 0px 4px 10px -7px #0e2b48;
    }
  }
}

.dark-mode {
  .wisk-grid-container {
    .warning-row {
      --darkreader-bg--ag-row-hover-color: var(--darkreader-bg--yellow-300) !important;

      &:nth-child(even) {
        background-color: var(--darkreader-bg--yellow-400) !important;
      }

      &.ag-row-selected {
        background-color: var(--darkreader-bg--yellow-100) !important;
      }
    }

    .danger-row {
      background-color: var(--red-800) !important;

      --darkreader-bg--ag-row-hover-color: var(--red-600) !important;

      &:nth-child(even) {
        background-color: var(--red-900) !important;
      }

      &.ag-row-selected {
        background-color: var(--red-700) !important;
      }

      .text-danger{
        color: var(--yellow-300) !important;
      }
    }
  }
}
</style>
