import { isTouch } from '@/modules/utils'

export default {
  mounted(el, binding /*, vnode*/) {
    let element = el,
      movable = binding.value.moveParent ? el.parentElement : el,
      parent = movable.parentElement,
      preventMovement = binding.value.preventMovement,
      callerId = binding.value.callerId,
      returnOriginalEvent = binding.value.returnOriginalEvent,
      preventVertical = preventMovement && preventMovement.vertically,
      preventHorizontal = preventMovement && preventMovement.horizontally,
      keepInsideParent = typeof binding.value.keepInsideParent === 'boolean' && binding.value.keepInsideParent,
      scrollInsteadOfMoving = typeof binding.value.scrollInsteadOfMoving === 'boolean' && binding.value.scrollInsteadOfMoving,
      verticalBounds = keepInsideParent || (binding.value.keepInsideParent && typeof binding.value.keepInsideParent === 'object' && binding.value.keepInsideParent.vertically),
      horizontalBounds = keepInsideParent || (binding.value.keepInsideParent && typeof binding.value.keepInsideParent === 'object' && binding.value.keepInsideParent.horizontally),
      pushBoundaries = binding.value.pushBoundaries || { vertical: 0, horizontal: 0 },
      pushVertical = parseInt(pushBoundaries.vertical, 10) || 0,
      pushHorizontal = parseInt(pushBoundaries.horizontal, 10) || 0,
      initX,
      initY,
      top,
      left,
      isMoving,
      userSelectBackup,
      transitionBackup,
      movableClassNameBackup,
      dragendCallback = binding.value.dragend,
      draggingCallback = binding.value.dragging,
      maxTop = 0,
      maxLeft = 0,
      touch = isTouch(),
      initScrollTop,
      initScrollLeft,
      scrollerBox

    const move = event => {
      if (event.preventDefault) {
        event.preventDefault()
      }
      event.returnValue = false

      if (isMoving) {
        window.requestAnimationFrame(() => {
          if (!preventHorizontal) {
            left = initX + (touch ? event.pageX : event.clientX)

            if (horizontalBounds) {
              left = left < -pushHorizontal ? -pushHorizontal : left
              left = left > maxLeft ? maxLeft : left
            }
          }

          if (!preventVertical) {
            top = initY + (touch ? event.pageY : event.clientY)

            if (verticalBounds) {
              top = top < -pushVertical ? -pushVertical : top
              top = top > maxTop ? maxTop : top
            }
          }
          if (scrollInsteadOfMoving) {
            left -= scrollerBox.left
            parent.scrollTop = initScrollTop ? initScrollTop - top : -top
            parent.scrollLeft = initScrollLeft ? initScrollLeft - left : -left
          } else {
            movable.style.top = top + 'px'
            movable.style.left = left + 'px'
          }

          if (typeof draggingCallback === 'function') {
            draggingCallback({ top, left, event: returnOriginalEvent ? event : undefined, callerId })
          }
        })
      }
    }

    const destroy = event => {
      isMoving = false
      document.body.style.userSelect = userSelectBackup
      movable.className = movableClassNameBackup
      movable.style.transition = transitionBackup

      if (touch) {
        window.removeEventListener('touchmove', move, false)
      }

      window.removeEventListener('mousemove', move, false)
      window.removeEventListener('mouseup', destroy, true)

      if (touch) {
        window.removeEventListener('touchend', destroy, true)
      }

      window.removeEventListener('dragend', destroy, true)

      movable.classList.remove('moving')

      if (typeof dragendCallback === 'function') {
        dragendCallback({ top, left, event: returnOriginalEvent ? event : undefined, callerId })
      }
    }

    const start = event => {
      initX = movable.offsetLeft - (touch ? event.pageX : event.clientX)
      initY = movable.offsetTop - (touch ? event.pageY : event.clientY)
      maxTop = parent.scrollHeight - movable.offsetHeight + pushHorizontal
      maxLeft = parent.scrollWidth - movable.offsetWidth + pushVertical
      initScrollTop = parent.scrollTop
      initScrollLeft = parent.scrollLeft
      scrollerBox = movable.getBoundingClientRect()
      isMoving = true

      if (touch) {
        window.addEventListener('touchmove', move, false)
      }

      window.addEventListener('mousemove', move, false)
      window.addEventListener('mouseup', destroy, true)
      window.addEventListener('dragend', destroy, true)

      if (touch) {
        window.addEventListener('touchend', destroy, true)
      }

      movable.classList.add('moving')
      userSelectBackup = document.body.style.userSelect
      transitionBackup = movable.style.transition
      movableClassNameBackup = movable.className
      document.body.style.userSelect = movable.style.transition = 'none'
    }

    if (touch) {
      element.addEventListener('touchstart', start, false)
    }

    element.addEventListener('mousedown', start, false)
  }
}
