// alternative names: wisk-copy
export default {
  name: 'wisk-cursor-hand',
  width: 68,
  height: 87,
  paths: [
    {
      d: 'M36.1638 0.000932836C33.3994 -0.0519026 31.1155 2.14631 31.0627 4.91078L30.3711 41.094C29.6223 41.5551 28.9027 41.438 28.3311 41.1204L25.6192 12.461C25.3451 9.56443 22.7747 7.43846 19.8781 7.71255C16.9815 7.98664 14.8555 10.557 15.1296 13.4536L18.239 46.3141C17.5548 47.795 16.3432 47.8219 15.4498 47.4997L11.5592 33.4166C10.6937 30.2837 7.45237 28.4456 4.31947 29.3111C1.18658 30.1766 -0.65152 33.4179 0.213973 36.5508L9.26628 69.3182C11.5833 79.0414 22.4851 86.4012 35.7791 86.4012C50.6239 86.4012 62.8916 73.1652 63.1572 61.6795L66.9787 27.9436C67.2949 25.1524 65.2885 22.6334 62.4973 22.3172C59.7061 22.001 57.1871 24.0074 56.8709 26.7986L54.5831 46.9953C53.5935 46.8695 52.8007 46.2172 52.3231 45.5594L55.4089 12.4289C55.6784 9.53486 53.5509 6.97026 50.6568 6.7007C47.7628 6.43115 45.1982 8.55873 44.9286 11.4528L42.1427 41.3639C41.4686 41.4977 40.8521 41.2257 40.3903 40.8582L41.0737 5.10211C41.1265 2.33764 38.9283 0.0537683 36.1638 0.000932836Z'
    }
  ]
}
