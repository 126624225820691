import get from 'lodash.get'
import { getFormattedTextFromGridCell as processCellCallback } from '@/components/grids/options/wiskGrid'

const expandExcelHeader = (rows = []) => {
  let result = []

  rows.forEach((row, rowIndex) => {
    if (Array.isArray(row)) {
      let arr = []

      row.forEach(value => {
        if (typeof value === 'string') {
          arr.push({
            data: {
              type: 'String',
              value
            },
            styleId: rowIndex ? 'cellStyle' : 'header'
          })
        } else if (value && value.type) {
          arr.push({
            data: value,
            styleId: rowIndex ? (value.styleId ? ['cellStyle', value.styleId] : 'cellStyle') : 'header'
          })
        }
      })
      result.push({ cells: arr })
    }
  })
  return result
}

const exportExcel = (gridApi, { header, fileName, sheetName = 'Export', rowHeight = 25, headerRowHeight = 35 }) => {
  let columnKeys = [],
    prependContent = [],
    columns = gridApi.getAllGridColumns()

  if (Array.isArray(header)) {
    prependContent = expandExcelHeader(header)
  }

  columns
    .filter(c => c.visible)
    .forEach(column => {
      if (column && column.colDef) {
        if (!get(column, 'colDef.cellRendererParams') || !get(column, 'colDef.cellRendererParams.excludeFromExport')) {
          columnKeys.push(column.colDef)
        }
      }
    })

  let params = { fileName, sheetName, columnKeys, prependContent, rowHeight, headerRowHeight, processCellCallback }

  gridApi.exportDataAsExcel(params)
}

export { exportExcel }
