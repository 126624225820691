export default {
  name: 'wisk-draft-invoices',
  width: 35,
  height: 35,
  paths: [
    { d: 'M21.4,7.6h5.4v19.7h-5.4V7.6z' },
    { d: 'M6.7,7.6v19.8H4.4V27c0-6.3,0-12.7,0-19c0-0.3,0.1-0.4,0.4-0.4C5.4,7.6,6.1,7.6,6.7,7.6z' },
    { d: 'M14.3,27.3V7.6h1.9v19.7H14.3z' },
    { d: 'M10.1,27.3H8.2V27c0-6.3,0-12.7,0-19c0-0.3,0.1-0.4,0.4-0.4c0.5,0,1,0,1.5,0L10.1,27.3z' },
    { d: 'M30.4,27.3h-1.9V7.6h1.9V27.3z' },
    { d: 'M17.9,7.6h1.4v19.7h-1.4V7.6z' },
    { d: 'M12.9,7.6v19.8h-1.2V27c0-6.3,0-12.7,0-19c0-0.3,0.1-0.4,0.4-0.4C12.4,7.6,12.7,7.6,12.9,7.6z' },
    {
      d: 'M0,7.5C0,6.8,0,6.2,0,5.6c0.1-1.3,1.2-2.3,2.5-2.4c1.2,0,2.3,0,3.5,0c0.4,0,0.7,0.3,0.6,0.7c0,0.4-0.3,0.7-0.7,0.6 c-1.1,0-2.1,0-3.2,0c-0.3,0-0.7,0.1-0.9,0.3C1.5,5.1,1.3,5.5,1.3,6c0,1.1,0,2.1,0,3.2c0,0.4-0.3,0.7-0.7,0.7C0.3,9.9,0,9.6,0,9.2 C0,8.6,0,8,0,7.5L0,7.5z'
    },
    {
      d: 'M35,7.5c0-0.6,0-1.3,0-1.9c-0.1-1.3-1.2-2.3-2.5-2.4c-1.2,0-2.3,0-3.5,0c-0.4,0-0.7,0.3-0.6,0.7c0,0.4,0.3,0.7,0.7,0.6 c1.1,0,2.1,0,3.2,0c0.3,0,0.7,0.1,0.9,0.3c0.4,0.3,0.5,0.7,0.5,1.2c0,1.1,0,2.1,0,3.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7 C35,8.6,35,8,35,7.5L35,7.5z'
    },
    {
      d: 'M0,27.5c0,0.6-0.1,1.3,0,1.9c0.1,1.3,1.2,2.3,2.5,2.4c1.2,0,2.3,0,3.5,0c0.4,0,0.7-0.3,0.6-0.7c0-0.4-0.3-0.7-0.7-0.6l0,0 c-1.1,0-2.1,0-3.2,0c-0.3,0-0.7-0.1-0.9-0.3c-0.4-0.3-0.5-0.7-0.5-1.2c0-1.1,0-2.1,0-3.2c0-0.4-0.3-0.7-0.7-0.7S0,25.4,0,25.8 c0,0,0,0,0,0C0,26.4,0,27,0,27.5L0,27.5z'
    },
    {
      d: 'M35,27.5c0,0.6,0,1.3,0,1.9c-0.1,1.3-1.2,2.3-2.5,2.4c-1.2,0-2.3,0-3.5,0c-0.4,0-0.7-0.3-0.6-0.7s0.3-0.7,0.7-0.6 c1.1,0,2.1,0,3.2,0c0.3,0,0.7-0.1,0.9-0.3c0.4-0.3,0.5-0.7,0.5-1.2c0-1.1,0-2.1,0-3.2c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7 C35,26.4,35,27,35,27.5L35,27.5z'
    }
  ]
}
