<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'taxEdit', action: null })" :title="modalTitle" :hideFooter="!!(tax && tax.id)" preventOKClose
    :okText="translations.txtGenericSave" @ok="save" size="md" :okDisabled="!valid" class="tax-edit" :warning="!!(tax && tax.archived)">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="tax && tax.id" />
    </template>

    <b-row v-if="tax && tax.id" class="mb-5 px-2 py-2 mx-2 bg-info" style="border-radius: 8px;">
      <b-col> {{ translations.txtTaxesRateComputed }}: </b-col>
      <b-col> {{ percentageFormat(tax.calculated_tax_rate, 4) }} </b-col>
    </b-row>

    <h5 v-if="tax && !tax.id" class="mb-5 ms-3"> {{ translations.txtTaxesMessageSaveFirst }} </h5>

    <wiskInputGroup @errorCountChanged="setValidState" v-if="tax" @operationsChange="operations = $event" :key="key" style="min-height: 300px;" class=""
      horizontal>
      <b-row>
        <b-col>
          <wiskInput infoTooltipKey="41ff37b7-5947-4489-a730-98bc9b2c4351" :label="translations.txtTaxesTitle" required autofocus
            operation="title" :modelValue="tax.title" @operation="onChange" :triggerInputOnLoad="!!editAction.title" triggerInputOnSet />
        </b-col>
        <b-col v-if="tax && tax.id">
          <wiskSelect :items="viewModes" v-model="viewMode" displayAsButtonGroup expandButtonGroup />
          <!-- :label="translations.txtGenericType" -->
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="currentPermissionsByType.invoice_send_to_accounting && accountingTaxes && accountingTaxes.length">
          <wiskSelect infoTooltipKey="acb4aa65-2b78-443a-ae3d-3e688af610af" @change="onAccountingTaxSelected" :modelValue="tax.id_in_accounting"
            operation="id_in_accounting" :label="translations.txtTaxesTitleAccounting" @operation="onChange" :items="accountingTaxes"
            trackBy="id_in_accounting" fixedWrapper />
        </b-col>
        <b-col>
          <wiskInput v-if="tax && tax.id && viewMode === 'rate'" infoTooltipKey="0d8c9316-1e9a-4fad-aa30-71309843c4cd" :label="translations.txtTaxesRate" class="w-100 mx-auto"
            :decimals="4" :minDecimals="2" decimalsAsNeeded :transformerOut="percentTransformerOut" :transformerIn="percentTransformerIn" suffix="%" inputType="number"
            operation="rate" :modelValue="tax.rate" @operation="onChange" />
        </b-col>
      </b-row>

      <fieldset v-if="tax && tax.id && viewMode === 'children'" class="mt-4" style="position: relative;">
        <legend class="" style="">
          <span class="text-start me-2"> {{ translations.txtTaxesInner }} </span>

          <b-button variant="link" size="sm" :disabled="addChildTaxDisabled" @click="tax.compound_taxes.push({ id: 0, title: '', priority: 1, rate: 0 })">
            <icon class="text-secondary m-0" name="wisk-plus" :scale="0.6" />
          </b-button>
        </legend>

        <taxChild v-for="child in tax.compound_taxes" :parentTax="tax" :tax="child" :key="child.id" @change="onChildChange" />
      </fieldset>

    </wiskInputGroup>

    <wiskLoading :loading="loading" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { percentageFormat, getRandom } from '@/modules/utils'
import taxChild from '@/components/accounting/TaxChild'

const newTax = {
  title: '',
  rate: 0,
  calculated_tax_rate: 0,
  archived: false,
  id: 0,
  compound_taxes: []
}

export default {
  name: 'TaxEdit',
  components: { taxChild },
  props: { editAction: Object },
  data() {
    return {
      percentageFormat,
      loading: false,
      tax: null,
      accountingTaxes: [],
      viewMode: 'rate',
      operations: [],
      key: 1,
      valid: true
    }
  },
  computed: {
    ...mapState(['translations', 'taxesById', 'taxes', 'currentPermissionsByType']),
    ...mapGetters([]),
    viewModes() {
      return [{ id: 'rate', title: this.translations.txtTaxesSingle }, { id: 'children', title: this.translations.txtTaxesCompounded }]
    },
    pageActions() {
      if (this.tax) {
        return [
          { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.tax.archived, action: this.archive },
          { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.tax.archived, action: this.restore }
        ]
      }
      return []
    },
    taxesForCompounding() {
      if (this.taxes && this.tax && this.tax.id) {
        return this.taxes.filter(t => t && t.id !== this.tax.id && !t.compound_taxes.length)
      }

      return []
    },
    addChildTaxDisabled() {
      return this.tax && this.tax.compound_taxes ? !!this.tax.compound_taxes.filter(t => !t.id).length : true
    },
    modalTitle() {
      if (this.tax) {
        let archived = this.tax.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.tax.id ? this.translations.txtTaxesEdit + ': ' + this.tax.title + archived : this.translations.txtTaxesNew
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    percentTransformerIn(z) {
      return z * 100
    },
    percentTransformerOut(z) {
      return z / 100
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    save(operations = this.operations) {
      this.loading = true

      api.updateTax(this.tax.id, operations).then(updated => {
        if (!this.tax.id && updated && updated.id) {
          this.setGlobalAction({ type: 'taxEdit', action: null })

          setTimeout(() => {
            this.setGlobalAction({ type: 'taxEdit', action: { tax: updated } })
          }, 0)
        }

        if (typeof this.editAction.onChange === 'function') {
          this.editAction.onChange(updated)
        }

        this.tax = { ...updated }
      }).finally(() => {
        this.loading = false
      })
    },
    onChange(operation) {
      if (this.tax && this.tax.id) {
        this.save(operation)
      }
    },
    onChildChange(child) {
      this.save({ type: child.REMOVE ? 'compound_tax_delete' : (child.id ? 'compound_tax_update' : 'compound_tax_add'), value: child.REMOVE ? child.id : { ...child, id: child.id || getRandom() } })
    },
    archive() {
      this.save([{ type: 'archive', value: true }])
    },
    restore() {
      this.save([{ type: 'archive', value: false }])
    },
    onAccountingTaxSelected(taxInAccounting) {
      if (taxInAccounting && this.tax && !this.tax.id && !this.tax.title) {
        this.tax.title = taxInAccounting.title
        this.operations.push({ type: 'title', value: taxInAccounting.title })
      }
    }
  },
  created() {
    api.accountingTaxes().then(accountingTaxes => {
      this.accountingTaxes = accountingTaxes
    })
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          if (editAction.id === 0) {
            this.tax = { ...newTax, title: editAction.title || '' }

            if (editAction.title) {
              this.key++
            }
          }

          if (editAction.id) {
            this.tax = { ...this.taxesById[editAction.id] }

            if (!this.tax) {
              console.error('tax not found for id ' + editAction.id + 'in taxesById', this.taxesById)
            }
          }

          if (editAction.tax) {
            this.tax = { ...editAction.tax }
          }

          if (this.tax && this.tax.compound_taxes && this.tax.compound_taxes.length) {
            this.viewMode = 'children'
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
