export default {
  name: 'wisk-undo',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M10.963,5.746A13.777,13.777,0,0,1,29.413,10.3a13.631,13.631,0,0,1-4.729,19,17.147,17.147,0,0,1-7.813,1.82,13.632,13.632,0,0,1-9.8-4.072,1.7,1.7,0,0,0-2.409,2.409,17.027,17.027,0,0,0,12.177,5.07,20.5,20.5,0,0,0,9.479-2.238l.023-.012.022-.013a16.78,16.78,0,0,0,8.254-18.022A17.073,17.073,0,0,0,3.463,8.373L3.4,3.145a1.7,1.7,0,0,0-3.408.042L.119,13.579A1.744,1.744,0,0,0,2.3,15.246l10.212-2.658a1.7,1.7,0,1,0-.858-3.3L6,10.762A13.554,13.554,0,0,1,10.963,5.746Z'
    }
  ]
}
