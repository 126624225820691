export default {
  name: 'wisk-options',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M32.5,17.5c1.8-0.6,2.8-2.5,2.4-4.3c-0.4-1.5-1.1-2.9-1.9-4.3c-1.1-1.6-3.3-2.2-5-1.4c0.9-1.7,0.3-3.8-1.4-4.8 C25.1,2,23.6,1.4,22,1c-1.9-0.5-3.8,0.5-4.5,2.3C16.8,1.5,14.8,0.6,13,1c-1.6,0.4-3.1,1-4.5,1.7C6.8,3.7,6.1,5.8,7,7.5 C5.2,6.7,3.1,7.3,2,8.9c-0.8,1.3-1.4,2.8-1.8,4.3c-0.4,1.8,0.6,3.7,2.4,4.3c-1.8,0.6-2.8,2.5-2.4,4.3c0.3,1,0.6,1.9,1.1,2.8 c0.6,1.2,1.7,1.9,3,2c1,0.1,2-0.1,2.8-0.7c-0.7,0.7-1.1,1.6-1.1,2.5c-0.1,1.3,0.6,2.5,1.7,3.1c1.7,1,3.5,1.8,5.4,2.3 c1.9,0.4,3.8-0.5,4.5-2.3c0.8,1.8,2.7,2.7,4.5,2.3c1.9-0.5,3.7-1.3,5.4-2.3c1.1-0.7,1.7-1.9,1.7-3.1c0-1-0.4-1.9-1.1-2.5 c0.8,0.5,1.8,0.8,2.8,0.7c1.3-0.1,2.4-0.9,3-2c0.5-0.9,0.8-1.9,1.1-2.8C35.3,20,34.2,18.1,32.5,17.5z M31.4,20.1 c0.4,0.1,0.7,0.5,0.6,1c-0.2,0.8-0.5,1.6-0.9,2.4c-0.2,0.5-1,0.5-1.4,0.2c-1.4-0.9-3.3-0.5-4.2,0.9C25.2,25,25,25.5,25,26 c0,0.7,0.3,1.4,0.8,1.9c0.4,0.3,0.4,0.9,0.1,1.3c0,0-0.1,0.1-0.1,0.1c-1.4,0.9-2.9,1.5-4.5,1.9c-0.4,0.1-0.8-0.2-1-0.6 c-0.7-1.5-2.5-2.2-4-1.5c-0.7,0.3-1.2,0.9-1.5,1.5c-0.2,0.4-0.6,0.6-1,0.6c-1.6-0.4-3.1-1-4.5-1.9c-0.4-0.3-0.4-0.9-0.1-1.3 c0,0,0.1-0.1,0.1-0.1C9.7,27.4,10,26.7,10,26c0-1.6-1.4-2.9-3-2.8c-0.6,0-1.2,0.2-1.7,0.5c-0.5,0.3-1.2,0.3-1.4-0.2 c-0.4-0.8-0.7-1.5-0.9-2.4c-0.1-0.4,0.2-0.8,0.6-1c1.1-0.4,1.9-1.5,1.9-2.7c0-1.2-0.8-2.2-1.9-2.6c-0.4-0.1-0.7-0.5-0.6-1 c0.3-1.2,0.9-2.4,1.5-3.5c0.3-0.4,0.8-0.5,1.2-0.3c0.4,0.2,0.8,0.3,1.3,0.3c1.6,0,2.9-1.2,3-2.8c0-0.4-0.1-0.8-0.3-1.2 C9.5,6,9.6,5.5,10,5.2c1.2-0.6,2.4-1.1,3.7-1.5c0.4-0.1,0.9,0.2,1,0.6c0.7,1.5,2.5,2.2,4.1,1.5C19.4,5.5,20,5,20.3,4.3 c0.1-0.4,0.6-0.7,1-0.6c1.3,0.3,2.6,0.8,3.7,1.5c0.4,0.2,0.5,0.7,0.3,1.1C25.1,6.7,25,7.1,25,7.5c0,1.6,1.4,2.9,3,2.8 c0.4,0,0.9-0.1,1.3-0.3c0.4-0.2,0.9-0.1,1.2,0.3c0.7,1.1,1.2,2.3,1.5,3.5c0.1,0.4-0.2,0.8-0.6,1c-1.5,0.5-2.2,2.1-1.7,3.5 C29.9,19.2,30.5,19.9,31.4,20.1z'
    },
    {
      d: 'M17.5,9c-4.8-0.1-8.8,3.7-9,8.5c0.1,4.8,4.1,8.6,9,8.5c0,0,0,0,0,0c4.8,0.1,8.8-3.7,9-8.5c0,0,0,0,0,0 C26.3,12.7,22.3,8.8,17.5,9z M17.5,23.2c-3.2,0.1-5.9-2.5-6-5.7c0.1-3.2,2.8-5.8,6-5.7c3.2-0.1,5.9,2.5,6,5.7 C23.4,20.7,20.7,23.3,17.5,23.2z'
    }
  ]
}
