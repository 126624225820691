export default {
  name: 'wisk-long-arrow-up',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M26.613,10.809a1.554,1.554,0,0,1-1.133.469,1.662,1.662,0,0,1-1.15-.469L19.116,5.546V33.381a1.619,1.619,0,0,1-3.238,0V5.546l-5.214,5.263a1.62,1.62,0,0,1-2.3-2.283L16.347.461a1.664,1.664,0,0,1,2.3,0L26.63,8.526A1.6,1.6,0,0,1,26.613,10.809Z'
    }
  ]
}
