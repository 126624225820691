export default {
  name: 'wisk-whiskey-glass',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.529.005H33.37C34.613.005,35,.392,35,1.64q0,14.76,0,29.52A3.478,3.478,0,0,1,33.006,34.6a4.1,4.1,0,0,1-1.7.373Q17.5,35.012,3.686,35A3.6,3.6,0,0,1,0,31.2c.029-4.516.009-9.031.009-13.547q0-7.986,0-15.973a4.839,4.839,0,0,1,.03-.66,1.134,1.134,0,0,1,.988-.98A4.367,4.367,0,0,1,1.644.006Q9.587,0,17.529.005ZM32.557,18.656V2.452H2.455a1.112,1.112,0,0,0-.027.159q0,7.722-.009,15.445c0,.239.093.322.309.389a10.023,10.023,0,0,0,3.263.433.6.6,0,0,0,.376-.256A12.022,12.022,0,0,1,7.21,17.4c.972-1.024,1.986-2.008,2.987-3a2.986,2.986,0,0,1,4.493.008c.794.787,1.581,1.581,2.374,2.37.087.086.187.16.312.265.194-.486.365-.921.541-1.354a2.988,2.988,0,0,1,4.1-1.752c1.215.481,2.416,1,3.646,1.443a3.206,3.206,0,0,1,2.378,2.8Zm-6.862-.4a2.254,2.254,0,0,0-.54-.465c-1.341-.557-2.694-1.087-4.046-1.619-.512-.2-.749-.094-.958.427q-.787,1.96-1.563,3.927c-.234.591-.134.819.456,1.054q1.942.776,3.887,1.544c.576.228.8.135,1.027-.427q.785-1.962,1.561-3.927C25.573,18.634,25.614,18.494,25.7,18.256ZM12.448,15.745a2.741,2.741,0,0,0-.491.308Q10.387,17.6,8.835,19.171a.594.594,0,0,0-.017.96q1.566,1.584,3.151,3.151a.564.564,0,0,0,.922.012q1.6-1.581,3.182-3.182a.563.563,0,0,0-.011-.922q-1.568-1.584-3.153-3.15A2.764,2.764,0,0,0,12.448,15.745Z'
    }
  ]
}
