export default {
  name: 'wisk-archive',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M32.5,1.6h-30C1.1,1.6,0,2.7,0,4.1v1.7C0,7,0.9,8,2.1,8.2C1.8,8.8,1.7,9.4,1.7,10v19.7c0,2.1,1.7,3.8,3.8,3.8h24 c2.1,0,3.8-1.7,3.8-3.8V10c0-0.6-0.1-1.2-0.4-1.7C34.1,8,35,7,35,5.8V4.1C35,2.7,33.9,1.6,32.5,1.6z M31.2,29.6 c0,0.9-0.8,1.7-1.7,1.7l0,0h-24c-0.9,0-1.7-0.7-1.7-1.7V10c0-0.9,0.8-1.7,1.7-1.7h24c0.9,0,1.7,0.7,1.7,1.7l0,0V29.6z M33,5.8 c0,0.2-0.2,0.4-0.4,0.4h-0.1h-30C2.3,6.2,2.1,6,2.1,5.8l0,0V4.1c0-0.2,0.2-0.4,0.4-0.4h30.1c0.2,0,0.4,0.2,0.4,0.4V5.8L33,5.8z'
    },
    {
      d: 'M25.9,20.1c0,0.1,0,0.2,0,0.3c-0.1,0.6-1.1,1.3-1.5,1.7c-0.6,0.6-1.3,1.3-1.9,1.9c-1.2,1.2-2.4,2.4-3.6,3.6 c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.3s-0.4-0.1-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.1l-7.3-7.3c-0.4-0.4-0.4-1.1,0-1.5 s1.1-0.4,1.5,0l5.5,5.5V12.2c0-0.6,0.5-1,1.1-1s1,0.5,1,1v12.7c0,0,5.5-5.5,5.5-5.5C24.9,18.7,25.9,19.2,25.9,20.1z'
    }
  ]
}
