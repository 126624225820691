import { createRouter, createWebHistory } from 'vue-router'
import { getPreferences, setPreferences } from '@/modules/storage'
import { venueIdFromUrl } from '@/modules/utils'

const Account = () => import('@/views/Users/Account')
const Auth = () => import('@/views/Auth/Auth')
const ChurnedVenue = () => import('@/views/Venues/ChurnedVenue')
const Consumption = () => import('@/views/Consumption/Consumption')
const CostChanges = () => import('@/views/Reports/CostChanges')
const Dashboard2 = () => import('@/views/Dashboard/Dashboard2')
const DeepLink = () => import('@/views/Auth/DeepLink')
const Distributors = () => import('@/views/Bottles/Distributors')
const DownloadManager = () => import('@/views/Reports/DownloadManager')
const DraftInvoices = () => import('@/views/DraftInvoices/DraftInvoices')
const Families = () => import('@/views/Bottles/Families')
const Full = () => import('@/components/containers/Full')
const IndependentInventories = () => import('@/views/Inventories/IndependentInventories')
const Inventories = () => import('@/views/Inventories/Inventories')
const Locations = () => import('@/views/Locations/Locations')
const LogoutRedirect = () => import('@/views/Auth/LogoutRedirect')
const Movements = () => import('@/views/Movements/Movements')
const MovementsAnalytics = () => import('@/views/Movements/MovementsAnalytics')
const OrderPage = () => import('@/views/Orders/OrderPage')
const Organizations = () => import('@/views/Organizations/Organizations')
const OverstockUnderstock = () => import('@/views/Reports/OverstockUnderstock')
const PageNotFound = () => import('@/views/PageNotFound')
const POSItems = () => import('@/views/POSItems/POSItems')
const PrintableDashboard = () => import('@/views/Dashboard/PrintableDashboard')
const PublicLinkToEmail = () => import('@/views/Users/PublicLinkToEmail')
const PublicOrder = () => import('@/views/Orders/PublicOrder')
const PublicPOSSignupLightspeed = () => import('@/views/Users/PublicPOSSignupLightspeed')
const PublicRedirect = () => import('@/views/Users/PublicRedirect')
const PurchaseOrders = () => import('@/views/Orders/PurchaseOrders')
const ReloadData = () => import('@/views/Users/ReloadData')
const Sales = () => import('@/views/Sales/Sales')
const Subrecipes = () => import('@/views/Subrecipes/Subrecipes')
const Subscription = () => import('@/views/Users/Subscription')
const TestPage = () => import('@/views/TestPage')
const Transfers = () => import('@/views/Venues/Transfers')
const TranslationsTransformer = () => import('@/views/TranslationsTransformer')
const UIComponentsDemo = () => import('@/views/UIComponentsDemo')
const Users = () => import('@/views/Users/Users')
const Variance = () => import('@/views/Variance/Variance')
const VenueBottles = () => import('@/views/Bottles/VenueBottles')
// const Venues = () => import('@/views/Venues/Venues')

const hasIdInPath = path => /^\d+$/.test(path.split('/')[1])

// eslint-disable-next-line new-cap
/**
 * @type {import('vue-router').Router}
 */
const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0, top: 0 }),
  routes: [
    {
      path: '/deep-link',
      name: 'deep_link',
      component: DeepLink
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      meta: { title: 'Auth' }
    },
    {
      path: '/signin',
      name: 'signin',
      component: Auth,
      meta: { title: 'Sign In' }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Auth,
      beforeEnter: to => {
        if (!to.query.invitation) {
          location.replace(import.meta.env.VITE_SIGNUP_MEETING_LINK)
        }
      },
      meta: { title: 'Sign Up' }
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutRedirect
    },
    {
      path: '/testpage',
      name: 'testpage',
      component: TestPage,
      meta: { title: 'Test Page', lightWeightPage: true }
    },
    {
      path: '/components-demo',
      name: 'components_demo',
      component: UIComponentsDemo,
      meta: { title: 'UI Components Demo', lightWeightPage: true }
    },
    {
      path: '/translations',
      name: 'translations',
      component: TranslationsTransformer,
      meta: { title: 'Translations transformer', lightWeightPage: true }
    },
    {
      path: '/public/orders/:guid?',
      name: 'public_order',
      component: PublicOrder,
      meta: { title: 'Orders' }
    },
    {
      path: '/public/lightspeed/signup',
      name: 'public_lightspeed_signup',
      component: PublicPOSSignupLightspeed,
      meta: { title: 'Lightspeed Signup' }
    },
    {
      path: '/public/signup/lightspeed',
      name: 'public_signup_lightspeed',
      component: PublicPOSSignupLightspeed,
      meta: { title: 'Lightspeed Signup' }
    },
    {
      path: '/public/redirect/:api?',
      name: 'public_redirect',
      component: PublicRedirect,
      meta: { title: 'Setup WISK', lightWeightPage: true }
    },
    {
      path: '/public/no_credit_card',
      name: 'public_no_credit_card_email',
      component: PublicLinkToEmail,
      meta: { title: 'Contact' }
    },
    {
      path: '/public/link_to_email',
      name: 'public_link_to_email',
      component: PublicLinkToEmail,
      meta: { title: 'Contact' }
    },
    {
      // this url is used for logic in different places of source code, if changed, update other parts as well
      path: '/public/dashboard/printable',
      name: 'dashboard_printable',
      component: PrintableDashboard,
      meta: { title: 'Dashboard Printable', lightWeightPage: true }
    },
    {
      path: '/:id?',
      redirect: to => {
        const { params } = to
        return params.id ? `/${params.id}/dashboard` : '/dashboard'
      },
      name: 'home',
      component: Full,
      children: [
        {
          path: 'reload',
          name: 'reload',
          component: ReloadData
        },
        {
          path: 'account',
          name: 'account',
          component: Account,
          meta: { title: 'Account' }
        },
        {
          path: 'subscription',
          name: 'subscription',
          component: Subscription,
          meta: { title: 'Subscription' }
        },
        {
          path: 'organizations',
          name: 'organizations',
          component: Organizations,
          meta: { title: 'Organizations' }
        },
        // {
        //   path: 'venues',
        //   name: 'venues',
        //   component: Venues,
        //   meta: { title: 'Venues' }
        // },
        {
          path: 'venue-transfers',
          name: 'venue_transfers',
          component: Transfers,
          meta: { title: 'Venue Transfers' }
        },
        {
          path: 'locations',
          name: 'locations',
          component: Locations,
          meta: { title: 'Locations' }
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard2,
          meta: { title: 'Dashboard' }
        },
        {
          path: 'stock-analysis',
          name: 'stock_analysis',
          component: OverstockUnderstock,
          meta: { title: 'Overstock / Understock Analysis' }
        },
        {
          path: 'orders',
          name: 'orders',
          component: OrderPage,
          meta: { title: 'Orders' }
        },
        {
          path: 'pos-items',
          name: 'pos_items',
          component: POSItems,
          meta: { title: 'POS Items' }
        },
        {
          path: 'sub-recipes',
          name: 'sub_recipes',
          component: Subrecipes,
          meta: { title: 'Batches' }
        },
        {
          path: 'items',
          name: 'venue_bottles',
          component: VenueBottles,
          meta: { title: 'Venue Items' }
        },
        {
          path: 'items-recent',
          name: 'venue_bottles_recent',
          component: VenueBottles,
          meta: { title: 'Recently Added' }
        },
        {
          path: 'items-check-units',
          name: 'venue_bottles_check_units',
          component: VenueBottles,
          meta: { title: 'Check Units' }
        },
        {
          path: 'families',
          name: 'families',
          component: Families,
          meta: { title: 'Families & Categories' }
        },
        {
          path: 'distributors',
          name: 'distributors',
          component: Distributors,
          meta: { title: 'Distributors' }
        },
        {
          path: 'wisk-distributors',
          name: 'wisk_distributors',
          component: Distributors,
          meta: { title: 'Wisk Distributors' }
        },
        {
          path: 'inventories',
          name: 'inventories',
          component: Inventories,
          meta: { title: 'Inventories' }
        },
        {
          path: 'partial-inventories',
          name: 'independent_inventories',
          component: IndependentInventories,
          meta: { title: 'Partial Inventories' }
        },
        {
          path: 'consumption',
          name: 'consumption',
          component: Consumption,
          meta: { title: 'Consumption' }
        },
        {
          path: 'cost-changes',
          name: 'cost_changes',
          component: CostChanges,
          meta: { title: 'Cost Changes' }
        },
        {
          path: 'download-manager',
          name: 'download_manager',
          component: DownloadManager,
          meta: { title: 'Download Manager' }
        },
        {
          path: 'purchase-orders',
          name: 'purchase_orders',
          component: PurchaseOrders,
          meta: { title: 'Purchase Orders' },
          props: {
            isPage: true
          }
        },
        {
          path: 'transfers',
          name: 'transfers',
          component: Movements,
          meta: { title: 'Transfers' },
          props: {
            pageTitle: 'Transfers',
            planFeature: 'transfers',
            movementTypes: ['transfer', 'transfer_out'],
            movementsItemsGridName: 'MovementsItemsGridTransfers'
          }
        },
        {
          path: 'depletions',
          name: 'adjustments',
          component: Movements,
          meta: { title: 'Depletions' },
          props: {
            pageTitle: 'Depletions',
            planFeature: 'depletions',
            movementTypes: ['depletion', 'adjustment'],
            movementsItemsGridName: 'MovementsItemsGridDepletions'
          }
        },
        {
          path: 'intakes',
          name: 'intakes',
          component: Movements,
          meta: { title: 'Invoices / Returns' },
          props: {
            pageTitle: 'Invoices / Returns',
            planFeature: 'deliveries',
            movementTypes: ['intake', 'return'],
            movementsItemsGridName: 'MovementsItemsGridIntakes'
          }
        },
        {
          path: 'scanned-invoices/',
          name: 'scanned_invoices',
          component: DraftInvoices,
          meta: { title: 'Draft Invoices' }
        },
        {
          path: 'movements-summary/',
          name: 'movements_summary',
          component: MovementsAnalytics,
          meta: { title: 'Movements Summary' },
          props: {
            pageTitle: 'Movements Summary',
            movementTypes: ['intake', 'return', 'adjustment', 'transfer']
          }
        },
        {
          path: 'sales',
          name: 'sales',
          component: Sales,
          meta: { title: 'Sales' }
        },
        {
          path: 'variance',
          name: 'variance',
          component: Variance,
          meta: { title: 'Variance' }
        },
        {
          path: 'team',
          name: 'team',
          component: Users,
          meta: { title: 'Users' }
        },
        {
          path: 'deactivated',
          name: 'churned',
          component: ChurnedVenue,
          meta: {}
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      params: { pathMatch: ['not', 'found'] },
      component: PageNotFound
    }
  ]
})

const hasAlreadyRefreshedOnce = getPreferences('hasAlreadyRefreshedOnceForChunkLoadError')

router.onError(err => {
  if ((err.message && err.message.includes('Loading chunk')) || err.message.includes('Failed to fetch dynamically imported module')) {
    console.error('ChunkLoadError caught by router:', err)
    if (hasAlreadyRefreshedOnce) {
      console.error('ChunkLoadError already refreshed once, not refreshing again')
    } else {
      console.error('ChunkLoadError refreshing once')
      setPreferences('hasAlreadyRefreshedOnceForChunkLoadError', true)
      window.location.reload()
    }
  } else {
    console.error('Router error:', err)
  }
})

router.beforeEach((to, from, next) => {
  const currentId = venueIdFromUrl()

  // Check if the 'home' route is present in the destination route's full path
  const hasHomeParent = to?.matched?.some(route => route.name === 'home')

  if (currentId && !hasIdInPath(to.path) && hasHomeParent) {
    next({ path: `/${currentId}${to.path}`, query: to.query })
  } else {
    next()
  }
})

router.afterEach(to => {
  setPreferences('hasAlreadyRefreshedOnceForChunkLoadError', false)

  setTimeout(() => {
    document.title = 'WISK.ai'
    if (to.meta && to.meta.title) {
      document.title += ' - ' + to.meta.title
    }
  }, 0)

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 500)
})
export default router
