<template>
  <wiskInput v-if="item" :label="translations.txtGenericCases" v-model="localMeasurement.quantity" inputType="number" :decimals="1" decimalsAsNeeded :dummy="item.case_size < 2"
    :helperText="(item.case_size > 1 && translations.txtGenericCaseSize + ': ' + item.case_size) || ''" @blur="onBlur" @update:modelValue="emitUpdateModel" :disabled="disabled">

    <b-button v-if="item.item_distributor_id && (!item.case_size || item.case_size === 1)" size="sm" variant="outline-secondary" @click="setCaseSize" class="px-1" :disabled="disabled">
      <span> {{ translations.txtInventoriesCaseSizeSet }} </span>
    </b-button>

    <confirm ref="confirmDialog" promptForTextType="number" autofocus size="sm" />

  </wiskInput>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'InventoryEntryInputCase',
  emits: ['update:modelValue', 'itemFieldChange', 'blur', 'valid', 'change'],
  components: {},
  props: { modelValue: Object, item: Object, disabled: Boolean, emitFast: Boolean },
  data() {
    return {
      localMeasurement: { type: 'case', quantity: 0, case_size: 1 }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setCaseSize() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          callback: caseSize => {
            if (caseSize) {
              this.$emit('itemFieldChange', {
                itemId: this.item.item_id,
                variantId: this.item.item_distributor_id,
                operation: { type: 'case_size', value: caseSize, from: (this.item && this.item.case_size) || 1 },
                updatedItem: { ...this.item, case_size: caseSize }
              })
            }
          },
          message: '',
          title: this.translations.txtGenericCaseSize
        })
      }
    },
    onBlur(event) {
      this.$emit('blur', event)
      this.$emit('valid', !!this.localMeasurement.quantity)

      if (!this.modelValue || this.modelValue.quantity !== this.localMeasurement.quantity) {
        this.$emit('update:modelValue', { ...this.localMeasurement, case_size: (this.item && this.item.case_size) || 1 })
        this.$emit('change', { ...this.localMeasurement })
      }
    },
    emitUpdateModel() {
      if (this.emitFast) {
        this.$emit('update:modelValue', { ...this.localMeasurement, case_size: (this.item && this.item.case_size) || 1 })
        this.$emit('valid', !!this.localMeasurement.quantity)
      }
    }
  },
  mounted() {
    this.$emit('valid', !!this.localMeasurement.quantity && this.item && this.item.case_size > 1)
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler() {
        if (this.modelValue && this.modelValue.quantity !== this.localMeasurement.quantity) {
          this.localMeasurement.quantity = this.modelValue.quantity
          this.localMeasurement.case_size = (this.item && this.item.case_size) || 1
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
