<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'movementAdjustmentReasons', action: null })" size="lg" :title="translations.txtMovementAdjustmentReasons" hideFooter>
    <template v-slot:modal-header-extra>
      <b-button @click="createNewReason" variant="outline-primary" size="sm" class="">
        {{ translations.txtMovementAdjustmentReasonNew }}
      </b-button>
    </template>

    <wiskGrid class="movement-extra-line-reasons" :rowData="movementAdjustmentReasons" :columnDefs="columns" resetRows gridAutoHeight :loadingOverlay="loading" parentGridName="MovementAdjustmentReasons" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'MovementAdjustmentReasons',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['translations', 'movementAdjustmentReasons']),
    ...mapGetters([]),
    columns() {
      return [
        {
          sortOrder: 200,
          colId: 'title',
          headerName: this.translations.txtGenericTitle,
          minWidth: 150,
          valueGetter: params => `${params.data?.title}${this.getSuffix(params.data)}`,
        },
        {
          colId: 'more',
          sortOrder: 40000,
          headerName: this.translations.txtGenericDetails,
          minWidth: 100,
          width: 200,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          headerClass: ['text-center'],
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          cellRendererParams: { onClick: this.openReason },
          valueGetter: params => ({
            id: params.data && params.data.id,
            group: !!params.node.group
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    createNewReason() {
      this.setGlobalAction({ type: 'movementAdjustmentReasonEdit', action: { id: 0 } })
    },
    openReason(id) {
      this.setGlobalAction({ type: 'movementAdjustmentReasonEdit', action: { id } })
    },
    getSuffix(reason) {
      if (reason?.archived) {
        return ` (${this.translations.txtGenericArchived})`
      }
      return ''
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss"></style>
