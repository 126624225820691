<template>
  <div style="position: relative;">
    <wiskSelect v-bind="$attrs" v-if="inputType === 'wiskSelect'" :class="componentClass" />
    <dateRangeSelector v-bind="$attrs" v-else-if="inputType === 'dateRange'" :class="componentClass" />
    <measurement v-bind="$attrs" v-else-if="inputType === 'measurement'" :class="componentClass" />
    <wiskInput v-bind="$attrs" :inputType="inputType" v-else :class="componentClass" />
  </div>
</template>

<script>
import measurement from '@/components/bottles/Measurement'
import dateRangeSelector from '@/components/common/DateRangeSelector'

export default {
  name: 'GenericInput',
  components: { measurement, dateRangeSelector },
  props: {
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    inputType: { type: String, default: '' },
    componentClass: { type: String, default: '' }
  }
}
</script>
