/*

NOTE: This function is available via this.$logEvent in Vue components or importing it directly
      Make sure it is initialized in the main.js file and assigned to $logEvent by app.config.globalProperties.$logEvent = logEvent

available log levels:
  - track
  - debug
  - info
  - notice
  - warning
  - error
  - critical

endpoints:
  - /log for one
  - /logs for batch

data structure:
  {
    message: 'message',
    level: 'level',
    metadata: {
      key: 'value can be: string, int, decimal and boolean'
    }
  }

*/

import merge from 'lodash.merge'
import axios from 'axios'
import Dexie from 'dexie'
import { getPreferences } from '@/modules/storage'
import { guid, isLocalhost } from '@/modules/utils'

const logIntervalMS = 30000

let logEndpoint = 'https://logs.wisk.restaurant/logs',
  logsDB = new Dexie('logs'),
  store = null

logsDB.version(1).stores({
  logs: 'id'
})

switch (window.location.hostname) {
  case 'feature1.wisk.dev':
  case 'feature2.wisk.dev':
  case 'web.wisk.dev':
    logEndpoint = 'https://logs.wisk.dev/logs'
    break
  case 'web.wisk.cool':
    logEndpoint = 'https://logs.wisk.cool/logs'
    break
  case 'web.wisk.restaurant':
    logEndpoint = 'https://logs.wisk.restaurant/logs'
    break
  case 'web.wisksolutions.com':
    logEndpoint = 'https://logs.wisksolutions.com/logs'
    break
  default:
    logEndpoint = 'https://logs.wisk.restaurant/logs'
}

if (getPreferences('environment') !== null) {
  switch (getPreferences('environment')) {
    case 1:
      logEndpoint = 'https://logs.wisk.cool/logs'
      break
    case 2:
      logEndpoint = 'https://logs.wisk.dev/logs'
      break
    default:
      logEndpoint = 'https://logs.wisk.restaurant/logs'
      break
  }
}

const sendLogsBatch = async () => {
    if (logsDB.isOpen()) {
      try {
        let logs = await logsDB.logs.toArray()
        if (logs.length > 0) {
          const data = logs.map(log => log.data)
          axios
            .post(logEndpoint, data)
            .then(() => logsDB.logs.clear())
            .then(() => {})
            .catch(error => {
              console.error(error)
            })
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  bytesToMB = bytes => Math.round(bytes / 1024 ** 2),
  defaultEventData = (eventData, level) => {
    const user = store.state.user
    const { jsHeapSizeLimit = 0, totalJSHeapSize = 0, usedJSHeapSize = 0 } = performance?.memory || {}

    return merge(
      {
        level,
        message: '',
        metadata: {
          type: 'wisk-webapp-dashboard',
          user_email: user.email,
          user_full_name: user.full_name,
          user_id: user.id,
          venue_id: store.state.venue.id,
          venue_title: store.state.venue.title,
          session_id: getPreferences('session_id'),
          memory_unit: 'mb',
          memory_jsHeapSizeLimit: bytesToMB(jsHeapSizeLimit),
          memory_totalJSHeapSize: bytesToMB(totalJSHeapSize),
          memory_usedJSHeapSize: bytesToMB(usedJSHeapSize),
          timestamp: new Date().toISOString(),
          wisk_timestamp: new Date().toISOString() // temporary until fixing timestamp overwrite bug on server
        }
      },
      eventData
    )
  },
  handleLog = async (eventData, level) => {
    if (store && !isLocalhost && logsDB.isOpen()) {
      try {
        await logsDB.logs.put({ data: defaultEventData(eventData, level), id: guid() })
      } catch (error) {
        console.error(error)
      }
    }
  }

// Periodically send log batches
setInterval(() => {
  sendLogsBatch()
}, logIntervalMS)

export const logEvent = {
  debug: eventData => handleLog(eventData, 'debug'),
  info: eventData => handleLog(eventData, 'info'),
  warning: eventData => handleLog(eventData, 'warning'),
  error: eventData => handleLog(eventData, 'error')
}

export const initLogger = globalStore => {
  store = globalStore
}
