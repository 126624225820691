<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'venueTransferEdit', action: null })" :title="modalTitle" :size="editAction && editAction.id ? 'lg' : 'md'"
    :hideFooter="!!(venueTransfer && venueTransfer.id)" :warning="!!(venueTransfer && venueTransfer.archived)" :extraLarge="!!(editAction && editAction.id)"
    :okDisabled="!newOperation.value" @ok="newVenueTransfer">

    <template v-if="editAction && editAction.id" v-slot:modal-header-extra>
      <gateKeeper feature="multi_venue" class="" noMessageButton>
        <div class="float-end w-100">
          <b-form-radio-group class="float-start" buttons v-model="selectedEditorTab" button-variant="outline-primary">
            <!-- txtGenericView -->
            <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
            <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
          </b-form-radio-group>

          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" :zIndex="12" size="sm" />
        </div>
      </gateKeeper>
    </template>

    <gateKeeper v-if="editAction && !editAction.id" feature="multi_venue" class="w-100">
      <div class="w-100 px-5" style="height: 500px;">
        <wiskSelect infoTooltipKey="aba82b3b-9090-4a26-a28a-41db253f4f9c" :label="translations.txtMovementEditPartnerVenue" trackBy="link_with_venue_id" :items="linkedDistributors" required
          @update:modelValue="newOperation.value = $event" />
      </div>
    </gateKeeper>

    <gateKeeper v-if="editAction && editAction.id && venueTransfer" feature="multi_venue" class="">
      <div class="w-100 min-h-440">
        <div v-if="selectedEditorTab === 0">
          <wiskInputGroup>
            <b-row>
              <b-col>
                <wiskInput infoTooltipKey="dcd7d721-4748-45ca-94f2-289e42939a8b" showPlainText disabled :modelValue="venueTransfer.date" :label="translations.txtGenericDate" inputType="datepicker" />
              </b-col>
              <b-col>
                <wiskInput infoTooltipKey="8ae39baa-4bed-4f4e-bee8-86d9beedc6c9" showPlainText v-if="venueTransfer.status"
                  :modelValue="translations.groupTransferRequestStatuses[venueTransfer.status.type]" :label="translations.txtGenericStatus" />
              </b-col>
              <b-col>
                <wiskSelect infoTooltipKey="bb2a25a9-660d-4846-9c46-7c9e2dd717a6" :label="translations.txtTaxesScheme" trackBy="id" :items="taxSchemes" required :customLabelOverride="taxSchemeSelectorLabelTranslator"
                  @operation="updateValue" :modelValue="venueTransfer.taxes_scheme" operation="taxes_scheme" :disabled="disabled" v-if="venue.taxes_enabled" />
              </b-col>
              <b-col>
                <wiskInput infoTooltipKey="eafea6c3-06cc-462c-ab8b-102f650bd0c9" showPlainText inputType="number" :decimals="2" :prefix="currency" :modelValue="venueTransfer.stats.dollars"
                  :label="translations.txtGenericTotal" />
              </b-col>
            </b-row>
          </wiskInputGroup>
          <b-row>
            <b-col cols="12">
              <wiskItemsGrid class="p-0" :columns="columns" ref="itemsGrid" :items="items" gridAutoHeight :key="gridKey" :header="{}"
                :gridStyle="{ height: 'auto' }" :excel="{ fileName: 'VenueTransferItems' }" parentGridName="VenueTransferItems" bottomAggregationRow trackBy="item_distributor_id">
                <template v-slot:additional-controls>
                  <b-button variant="outline-primary" @click="emailPdf" :disabled="loading">
                    {{ translations.txtVenueTransferDetailsEmail }}
                  </b-button>
                </template>
              </wiskItemsGrid>
            </b-col>
          </b-row>

          <venueTransferAddItem class="mt-4" v-if="venueTransfer && !disabled" @addItem="addItem" :venueTransferId="venueTransfer.id" id="venue-transfer-add-item" :key="addItemKey" />

        </div>
        <div v-if="selectedEditorTab === 1">
          <timeline v-if="venueTransfer && venueTransfer.id" :filters="timelineFilters" parentGridName="VenueTransferEditTimeline" />
        </div>
      </div>
    </gateKeeper>

    <measurementEditor v-if="selectedLineMeasurement" :modelValue="selectedLineMeasurement" @update:modelValue="onMeasurementChange" startOpened @hide="selectedLineMeasurement = null; selectedLine = null" disableTypeChange
      :preferredType="selectedLineMeasurement.item_measurement_type" :extraUnitsOfMeasurement="extraUnitsOfMeasurement" inputClass="d-none" :caseSize="selectedLine.case_size" />

    <priceEditor v-if="selectedLinePrice" :itemVariantId="selectedLine.item_distributor_id" v-model="selectedLinePrice" startOpened
      @hide="selectedLinePrice = null; selectedLine = null" @update:modelValue="onPriceChange" inputClass="d-none" />

    <wiskLoading :loading="loading" />
    <confirm ref="confirmDialogDecline" autofocus :promptForTextLabel="translations.txtGenericReason" textarea />
    <confirm ref="confirmDialogSendItems" autofocus :promptForTextLabel="translations.txtGenericNotes" textarea />
    <confirm ref="confirmDialogArchive" />

  </wiskModal>
</template>

<script>
/**
incoming = kitchen
only show incoming, or show outgoing as disabled

requested = arrived at kitchen
preparing = baking the bread
sent = sent the scooter with the bread
received = scooter arrived at other venue and they made an invoice
declined = we, the kitchen don't want to make this bread because we are out in the bar having drinks
 */

import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { /*compareNumbers, */ prepareVariantIdAsItem } from '@/modules/utils'
import venueTransferAddItem from '@/components/venueTransfers/VenueTransferAddItem'
import timeline from '@/components/timeline/Timeline'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import measurementEditor from '@/components/bottles/Measurement'
import priceEditor from '@/components/bottles/ItemPrice'
import { getItemsGridColumns } from './gridOptions'

/*todo:
 add buttons to change status
*/

export default {
  name: 'VenueTransferEdit',
  components: { venueTransferAddItem, wiskItemsGrid, timeline, measurementEditor, priceEditor },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      venueTransfer: null,
      loading: false,
      columns: {},
      gridKey: 1,
      newOperation: { type: 'partner_venue_id', value: null },
      addItemKey: 1,
      partnerVenueId: 123,
      selectedEditorTab: 0,
      items: [],
      selectedLineMeasurement: null,
      selectedLinePrice: null,
      selectedLine: null
    }
  },
  computed: {
    ...mapState(['translations', 'itemVariantsById', 'venueTransfersById', 'currentPermissionsByType', 'taxesById', 'venue']),
    ...mapGetters(['activeDistributors', 'currency', 'taxSchemes', 'activeTaxes']),
    linkedDistributors() {
      return this.activeDistributors.filter(d => d && d.link_with_venue_id && d.link_with_venue_id !== this.venue.id)
    },
    extraUnitsOfMeasurement() {
      let arr = [{ id: 'unit', short: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]

      if (this.selectedLine) {
        let item = prepareVariantIdAsItem(this.selectedLine.item_distributor_id, this.$store.state)
        if (item && item.case_size && item.case_size > 1) {
          arr.push({ id: 'case', title: 'Case', units_of_measurements: [{ id: 'case', short: 'case', title: 'Case', type: 'case' }] })
        }
      }

      return arr
    },
    timelineFilters() {
      if (this.venueTransfer) {
        return [{ type: 'transfer_request', id: this.venueTransfer.id }]
      }
      return []
    },
    disabled() {
      if (this.venueTransfer && this.venueTransfer.status) {
        return !!(
          // this.venueTransfer.type === 'outgoing' ||
          this.venueTransfer.status.type === 'sent' ||
          this.venueTransfer.status.type === 'received' ||
          this.venueTransfer.status.type === 'declined'
        )
      }

      return false
    },
    modalTitle() {
      console.log('this.editAction', this.editAction)
      if (this.venueTransfer && this.venueTransfer.id) {
        return this.translations.translate('tplVenueTransferRequestTitle', { '{a}': this.venueTransfer.partner_venue.title, '{b}': this.venueTransfer.id })
      }
      return this.translations.txtVenueTransferRequestNew
    },
    pageActions() {
      if (!this.venueTransfer) {
        return []
      }

      let canAccept = this.venueTransfer.status.type === 'requested' || this.venueTransfer.status.type === 'draft',
        canDecline = this.venueTransfer.status.type === 'requested' || this.venueTransfer.status.type === 'in_preparation',
        canSend = this.venueTransfer.status.type === 'in_preparation',
        archived = !!(this.venueTransfer && this.venueTransfer.archived),
        canArchive = this.venueTransfer.status.type === 'draft' && this.currentPermissionsByType.transfer_request_manage,
        actions = [
          { key: 20, title: this.translations.txtVenueTransferRequestAccept, icon: 'wisk-check', variant: 'success', action: this.accept, hide: !canAccept },
          { key: 30, title: this.translations.txtVenueTransferRequestSend, icon: 'wisk-distributors', variant: 'primary', action: this.send, hide: !canSend },
          { key: 40, title: this.translations.txtVenueTransferRequestDecline, icon: 'wisk-delete', variant: 'danger', action: this.decline, hide: !canDecline },
        ]

      if (canArchive) {
        actions.push({ key: 50, isDivider: true, hide: !canAccept && !canDecline && !canSend })
        actions.push({ key: 60, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: archived, action: this.toggleArchived })
        actions.push({ key: 70, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !archived, action: this.toggleArchived })
      }

      return actions
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenueTransfer']),
    taxSchemeSelectorLabelTranslator(item) {
      return this.translations.groupTaxSchemes[item.id]
    },
    accept() {
      this.updateValue([{ type: 'status', value: { type: 'in_preparation' }, from: this.venueTransfer.status }])
    },
    emailPdf() {
      this.loading = true
      api.emailPdf(`/transfer-requests/${this.venueTransfer.id}/pdf`
        // 'Transfer requests.pdf'
      ).then(() => {
        this.loading = false
      })
    },
    toggleArchived() {
      if (this.$refs.confirmDialogArchive && this.venueTransfer) {
        this.$refs.confirmDialogArchive.confirm({
          callback: () => {
            this.updateValue({ type: 'archive', value: !this.venueTransfer.archived })
          },
          message: this.venueTransfer.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.venueTransfer.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    decline() {
      if (this.$refs.confirmDialogDecline) {
        this.$refs.confirmDialogDecline.prompt({
          callback: (reason = '') => {
            reason = reason.trim()
            if (reason) {
              this.updateValue([{ type: 'status', value: { type: 'declined', reason }, from: this.venueTransfer.status }])
            }
          },
          title: this.translations.txtVenueTransferRequestDecline,
          text: '',
          required: true
        })
      }
    },
    send() {
      if (this.$refs.confirmDialogSendItems) {
        this.$refs.confirmDialogSendItems.prompt({
          callback: (reason = '') => {
            reason = reason.trim()
            this.updateValue({ type: 'send_items', value: reason })
          },
          title: this.translations.txtVenueTransferRequestSend,
          text: '',
          required: false
        })
      }
    },
    addItem(value) {
      this.updateValue({ type: 'item_add', value })
    },
    openMeasurement(variantId) {
      this.selectedLine = this.items.find(i => i.item_distributor_id === variantId)
      this.selectedLineMeasurement = this.selectedLine.venueTransferInfo.measurement
      this.selectedLineMeasurement.unit_of_measurement = this.selectedLineMeasurement.type
    },
    openPriceEditor(variantId) {
      this.selectedLine = this.items.find(i => i.item_distributor_id === variantId)
      this.selectedLinePrice = this.selectedLine.venueTransferInfo.price
    },
    updateValue(operation) {
      this.updateVenueTransfer({ id: this.venueTransfer.id, operation })
    },
    onMeasurementChange(measurement) {
      if (this.selectedLine && measurement) {
        if (measurement.unit_of_measurement === 'case' || measurement.unit_of_measurement === 'unit') {
          measurement.type = measurement.unit_of_measurement
        } else {
          measurement.type = 'manual'
        }
        this.updateValue({ type: 'item_update', value: { ...this.selectedLine.venueTransferInfo, measurement }, from: this.selectedLine.venueTransferInfo })
      }
    },
    onPriceChange(price) {
      if (this.selectedLine && price) {
        this.updateValue({ type: 'item_update', value: { ...this.selectedLine.venueTransferInfo, price }, from: this.selectedLine.venueTransferInfo })
      }
    },
    prepareData() {
      if (this.editAction && this.editAction.id) {
        this.venueTransfer = this.venueTransfersById[this.editAction.id]
        this.items = this.venueTransfer.items.map(item => ({
          ...prepareVariantIdAsItem(item.item_distributor_id, this.$store.state),
          venueTransferInfo: item
        }))
        console.log('this.venueTransfer', JSON.parse(JSON.stringify(this.venueTransfer)))
      }
    },
    prepareColumns() {
      this.columns = getItemsGridColumns({
        venue: this.venue,
        currentPermissionsByType: this.currentPermissionsByType,
        translations: this.translations,
        updateValue: this.updateValue,
        activeTaxes: this.activeTaxes,
        taxesById: this.taxesById,
        setGlobalAction: this.setGlobalAction,
        openMeasurement: this.openMeasurement,
        openPriceEditor: this.openPriceEditor,
        disabled: this.disabled
      })
    },
    newVenueTransfer() {
      this.updateVenueTransfer({ id: 0, operation: this.newOperation }).then(result => {
        this.setGlobalAction({ type: 'venueTransferEdit', action: null })

        setTimeout(() => {
          this.setGlobalAction({ type: 'venueTransferEdit', action: { id: result.id } })
        }, 500)
      })
    }
  },
  watch: {
    disabled: { immediate: true, handler: 'prepareColumns' },
    editAction: { immediate: true, handler: 'prepareData' },
    venueTransfersById: 'prepareData',
    itemVariantsById: 'prepareData'
  }
}
</script>

<style lang="scss"></style>
