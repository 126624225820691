export default onMessage => {
  const worker = new Worker(new URL('./dymo.worker.js', import.meta.url))
  let savedArgs = []

  const postMessage = (payload, ...args) => {
    worker.postMessage(payload)
    savedArgs = args
  }

  worker.onmessage = event => {
    onMessage(event, ...savedArgs)
  }

  return postMessage
}
