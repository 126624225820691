<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BContainer',
  props: {
    fluid: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return this.fluid ? 'container-fluid' : 'container'
    }
  }
}
</script>
