function loadAds() {
  const adModules = import.meta.glob('./*.vue', { eager: true }),
    ads = Object.keys(adModules).reduce((acc, path) => {
      const module = adModules[path],
        componentName = path.replace(/^\.\/(.*)\.\w+$/, '$1').toLowerCase()

      acc[componentName] = module.default
      return acc
    }, {})

  return ads
}

const ads = loadAds()
export default ads
