<template>
  <div v-if="params.node.group || params.node.rowPinned || hideButton"></div>
  <div v-else class="fill-width-height middle-center">
    <b-button @click="params.onClick(params.value.id, params.value)" :variant="buttonVariant" size="xs" v-show="!hideIcon || title"
      class="fill-width-height middle-center more-icon" :class="[colIdClass, buttonClass]">
      <icon v-if="!hideIcon" :class="iconClass" :name="icon" />
      <span v-if="title" :class="titleClass"> {{ title }} </span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'CellMoreDetails',
  computed: {
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    icon() {
      return this.params.icon || 'wisk-more-info'
    },
    hideIcon() {
      return !!this.params.hideIcon
    },
    title() {
      if (typeof this.params.getTitle === 'function') {
        return this.params.getTitle(this.params.value.id, this.params.value)
      }
      return this.params.title || ''
    },
    buttonClass() {
      return this.params.buttonClass || ''
    },
    buttonVariant() {
      return this.params.buttonVariant || 'link'
    },
    iconClass() {
      return this.params.iconClass || ''
    },
    titleClass() {
      return this.params.titleClass || ''
    },
    hideButton() {
      if (typeof this.params.getHideButton === 'function') {
        return this.params.getHideButton(this.params.value.id, this.params.value)
      }
      return !!this.params.hideButton
    }
  }
}
</script>
<style lang="scss">
.cell-more-details {
  display: flex;
  align-items: center;
  justify-content: center;

  // &:hover {
  //   .more-icon {
  //     // color: #4078c7;
  //   }
  // }

  // .more-icon {
  //   // color: gray;
  // }
}
</style>
