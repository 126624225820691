<template>
  <b-container fluid style="position: relative;">
    <b-row>
      <b-col class="ps-0-m" cols="1">
        <b-button v-if="previous" variant="secondary" size="md" @click="previous" v-tooltip="translations.txtGenericPrevious">
          <icon class="" name="wisk-chevron-left"></icon>
        </b-button>
      </b-col>
      <b-col class="px-0-m" cols="2">
        <b-button v-if="typeof archive === 'function'" size="md" variant="link" @click="archive" class="me-1" v-tooltip="translations.txtGenericArchive">
          <icon class="text-danger" name="wisk-archive" scale="1.2" />
        </b-button>
        <b-button v-if="typeof restore === 'function'" size="md" variant="link" @click="restore" class="me-1" v-tooltip="translations.txtGenericRestore">
          <icon class="text-success" name="wisk-history" scale="1.5" />
        </b-button>
      </b-col>
      <b-col cols="6" class="px-0-m middle-center">
        <b-button v-if="!save && close" variant="secondary" size="md" @click="close()" :title="translations.txtGenericClose" class="me-1 pe-3">
          <icon class="mt-1 me-2" name="wisk-check"></icon>
          {{ translations.txtGenericClose }}
        </b-button>
        <b-button v-if="save" :disabled="!editorValid" variant="primary" size="md" @click="saveLocal" class="me-1 pe-3">
          <icon class="me-2" name="wisk-save"></icon>
          {{ translations.txtGenericSave }}
        </b-button>
        <b-button v-if="save && next" :disabled="!editorValid" variant="primary" size="md" @click="saveAndNext">
          <icon class="mt-1 me-2" name="wisk-save"></icon>
          {{ translations.txtGenericSaveNext }}
          <icon class="mt-1 ms-2" name="wisk-chevron-right"></icon>
        </b-button>
      </b-col>
      <b-col class="pe-0-m" cols="3">
        <b-button v-if="next" variant="secondary" size="md" @click="next" class="float-end" v-tooltip="translations.txtGenericNext">
          <icon class="" name="wisk-chevron-right"></icon>
        </b-button>
      </b-col>
    </b-row>
    <wiskLoading :loading="loading || localLoading" />

  </b-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WiskEditorModalButtons',
  props: {
    loading: Boolean,
    previous: { type: Function },
    archive: { type: null },
    restore: { type: null },
    save: { type: Function },
    next: { type: Function },
    close: { type: Function },
    editorValid: Boolean
  },
  data() {
    return {
      localLoading: false
    }
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    saveAndNext() {
      if (typeof this.save === 'function' && typeof this.next === 'function') {
        this.save(true).then(() => {
          this.next()
        })
      }
    },
    saveLocal() {
      if (typeof this.save === 'function') {
        this.localLoading = true
        this.save()

        //Normally this modal will be closed after the save is done, but in case of an error, we need to remove the loading
        //If we could be sure all save functions would return a promise, we could just use the .finally() method
        setTimeout(() => {
          this.localLoading = false
        }, 2000)
      }
    }
  }
}
</script>

<style></style>
