<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'servingSizes', action: null })"
    :title="translations.txtServingSizesTitle" size="lg" hideFooter>
    <template v-slot:modal-header-extra>
      <wiskInput infoTooltipKey="f9a6174a-c22c-45f6-a3ef-15966f3d13e9" :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" fitChildren>
        <b-button @click="newServingSize" class="float-end" variant="primary"> {{ translations.txtServingSizesAddServingSize }} </b-button>
      </wiskInput>
    </template>

    <draggable class="list-group px-3 py-2" :list="visibleServingSizes" v-bind="dragOptions" @start="drag = true" @end="saveSortOrder" itemKey="id">
      <template #item="{ element: item }">
        <servingSizeItem :servingSize="item" />
      </template>
    </draggable>

    <small> {{ translations.txtGenericDragSort }} </small>

    <wiskLoading :loading="loading" />

  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { compareNumbers } from '@/modules/utils'
import servingSizeItem from '@/components/servingSizes/ServingSizeItem'
import wiskModal from '@/components/common/WiskModal'

export default {
  name: 'ServingSizes',
  components: { draggable, wiskModal, servingSizeItem },
  props: {
    editAction: Object
  },
  data() {
    return {
      gridLoading: false,
      drag: false,
      timeoutId: null,
      showArchived: false,
      gridOptions: {},
      loading: false
    }
  },
  computed: {
    ...mapState(['servingSizes', 'translations']),
    ...mapGetters([]),
    visibleServingSizes() {
      let servingSizes = this.servingSizes.map(s => ({ ...s })).sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
      if (this.showArchived) {
        return servingSizes.filter(s => s.id > 0)
      }
      return servingSizes.filter(s => s.id > 0 && !s.archived)
    },
    dragOptions() {
      return {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    ...mapActions(['updateServingSize', 'setGlobalAction', 'setServingSizesOrder']),
    selectForEdit(id) {
      this.setGlobalAction({ type: 'servingSizeEdit', action: { id } })
    },
    updateValueFromGrid(value, id, type) {
      this.updateServingSize({ id, operation: { type, value } })
    },
    newServingSize() {
      this.setGlobalAction({ type: 'servingSizeEdit', action: { id: 0 } })
    },
    saveSortOrder() {
      this.drag = false

      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.loading = true
        this.setServingSizesOrder({ serving_size_orders: this.visibleServingSizes }).finally(() => {
          this.loading = false
        })
      }, 1000)
    }
  }
}
</script>

<style>

</style>
