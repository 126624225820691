export default {
  account: 'https://help.wisk.ai/en/articles/4816235',
  adjustments: 'https://help.wisk.ai/en/collections/2612461-managing-inventory#depletions',
  consumption: 'https://help.wisk.ai/en/articles/3533671-consumption-report',
  distributors: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#distributors',
  families: 'https://help.wisk.ai/en/collections/2612898-items#families-categories',
  home: 'https://help.wisk.ai/',
  independent_inventories: 'https://help.wisk.ai/en/collections/2043377-taking-inventory#independent-inventories',
  intakes: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#adding-invoices-receiving-orders',
  inventories: 'https://help.wisk.ai/en/collections/2612461-managing-inventory#inventory-counts',
  locations: 'https://help.wisk.ai/en/collections/2612461-managing-inventory#areas',
  movements_summary: 'https://help.wisk.ai/en/articles/5629151-movements-analytics-report',
  orders: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#generating-orders',
  pos_items: 'https://help.wisk.ai/en/collections/2611239-point-of-sale-pos-integrations-pos-items#pos-items',
  purchase_orders: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#adding-invoices-receiving-orders',
  sales: 'https://help.wisk.ai/en/collections/2611239-point-of-sale-pos-integrations-pos-items#sales-data',
  scanned_invoices: 'https://help.wisk.ai/en/articles/5232631-reviewing-converting-a-draft-invoice',
  stock_analysis: 'https://help.wisk.ai/en/collections/2612967-reports',
  sub_recipes: 'https://help.wisk.ai/en/collections/2612898-items#batched-items',
  subscription: 'https://help.wisk.ai/en/collections/2612901-venue-settings-userssubscription#managing-your-subscription',
  team: 'https://help.wisk.ai/en/collections/2612901-venue-settings-users-subscription#users',
  transfers: 'https://help.wisk.ai/en/collections/2612461-managing-inventory#transfers',
  variance: 'https://help.wisk.ai/en/collections/2238591-variance',
  venue_bottles: 'https://help.wisk.ai/en/collections/2612898-items'
}
