<template>
  <div class="b-accordion">
    <b-card no-body class="mb-2 position-relative rounded" v-for="item in computedItems" :key="item.key">
      <b-card-header header-tag="header" class="position-sticky cursor-pointer px-3" :class="value === item.key ? 'rounded-top' : 'rounded'" role="tab" @click="toggle(item.key)">
        <div class="text-primary text-bold">
          <slot name="header" v-bind="item.data">{{ getItemHeader(item.data) }}</slot>

          <icon name="wisk-caret-down" class="float-end" :scale="1" v-if="value !== item.key" />
          <icon name="wisk-caret-up" class="float-end" :scale="1" v-if="value === item.key" />
        </div>
      </b-card-header>
      <b-collapse :visible="value === item.key">
        <b-card-body class="p-2">
          <slot v-bind="item.data" />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import get from 'lodash.get'

export default {
  name: 'BAccordion',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  emits: ['update:modelValue'],
  props: {
    items: { type: Array, required: true },
    modelValue: { type: [Number, String], default: '' },
    headerKey: { type: String, default: 'title' },
    itemKey: { type: String, default: '' }
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => ({
        data: item,
        key: this.getItemKey(item, index)
      }))
    }
  },
  methods: {
    toggle(key) {
      this.value = this.value !== key ? key : ''
    },
    getItemHeader(item) {
      return get(item, this.headerKey, '')
    },
    getItemKey(item, index) {
      return this.itemKey ? get(item, this.itemKey, index) : index
    }
  },
  mounted() {
    this.value = this.modelValue
  },
  watch: {
    value(v) {
      this.$emit('update:modelValue', v)
    },
    modelValue(v) {
      if (v !== this.value) {
        this.value = v
      }
    }
  }
}
</script>

<style lang="scss">
.b-accordion {
  .card-header {
    top: -9px;
    z-index: 8;
    outline: none;
  }
}
</style>
