<template>
  <span class="wisk-price-check-container">
    <itemCostChangeWarning v-if="inline && showPriceChangeWarning" :previousPricePerUnit="previousPricePerUnit" :pricePerUnit="pricePerUnit" :totalChange="totalChange" />

    <b-row v-if="(showPricePerUnit && pricePerUnit) || (showPreviousPrices && previousPricePerUnit)">
      <b-col v-if="showPricePerUnit && pricePerUnit" class="text-gray-400" cols="5">
        {{ translations.txtScannedInvoicePricePerUnit }}: {{ currencyFormat(pricePerUnit) }}
      </b-col>
      <b-col v-if="showPreviousPrices && previousPricePerUnit" :class="{ 'text-danger text-bold': showPriceChangeWarning, 'text-gray-400': !showPriceChangeWarning }">
        {{ translations.txtScannedInvoicePricePerUnitPrevious }}: {{ currencyFormat(previousPricePerUnit) }}

        <b-button @click="inakesPopupOpen = true" class="info-icon p-0 pb-1" variant="link" :class="{ 'text-danger': showPriceChangeWarning, 'text-gray-400': !showPriceChangeWarning }">
          <icon name="wisk-arrow-right-circle" :scale="0.7"></icon>
        </b-button>
      </b-col>
    </b-row>

    <wiskModal v-model="inakesPopupOpen" v-if="itemComputed" size="lg" hideFooter :title="translations.translate('tplCostUnitInfoIntakes', { '{a}': itemComputed.title })" :cancelText="translations.txtGenericClose" hideOK>
      <h6 class="text-muted">
        {{ translations.txtGenericCost }}: {{ currencyFormat(pricePerUnit) }}
      </h6>
      <p v-if="venue.weighted_average_pricing"> {{ translations.txtWeightedAveragePricingInfo }} </p>
      <hr>
      <b-table striped hover :items="intakesMapper" :fields="intakesPopUpFields">
        <template v-slot:cell(edit)="row">
          <b-button size="sm" variant="link" type="link" class="me-1 text-end" @click="openMovement(row.intake_id)">
            <icon name="wisk-more-info" class="pt-1"></icon>
          </b-button>
        </template>
      </b-table>
    </wiskModal>

  </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { guid, prepareVariantIdAsItem, currencyFormat, formatDate, getBaseValueFromMeasurement } from '@/modules/utils'
import itemCostChangeWarning from './ItemCostChangeWarning.vue'

export default {
  name: 'ItemPriceCheck',
  components: { itemCostChangeWarning },
  props: {
    item: Object,
    caseSize: Number,
    itemId: Number,
    itemVariantId: Number,
    horizontal: Boolean,
    required: Boolean,
    discountAvailable: Boolean,
    modelValue: Object,
    title: String,
    label: String,
    showPreviousPrices: Boolean,
    previousPrice: Number,
    showPricePerUnit: Boolean,
    movementId: { type: Number, default: 0 },
    disabled: Boolean,
    inputClass: String,
    startOpened: Boolean,
    showLabelIfInline: Boolean,
    triggerInputOnLoad: Boolean,
    inline: Boolean,
    totalChange: Number,
    currentPrice: Number,
  },
  data() {
    return {
      inakesPopupOpen: false,
      selectedPriceType: 'unit',
      discountTypes: [{ id: 'amount', title: '$' }, { id: 'percent', title: '%' }],
      discountType: 'amount',
      priceValue: 0,
      discountValuesMap: { amount: 0, percent: 0 },
      key: 1,
      currencyFormat
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'itemVariantPricesById', 'itemPricesById', 'venue', 'distributorsById']),
    ...mapGetters(['measurementsByType']),
    itemVariantPrice() {
      if (this.itemComputed?.item_distributor_id) {
        return this.itemVariantPricesById[this.itemComputed.item_distributor_id]
      }
      if (this.itemComputed?.item_id) {
        return this.itemPricesById[this.itemComputed.item_id]
      }

      return null
    },
    pricePerUnit() {
      if (this.currentPrice) {
        return this.currentPrice
      }
      if (this.selectedPriceType && this.itemComputed) {
        switch (this.selectedPriceType) {
          case 'case':
            return this.priceValue / this.itemComputed.case_size

          case 'unit':
          case 'ea':
            return this.priceValue

          default: {
            let baseValueForItem = getBaseValueFromMeasurement(this.itemComputed.measurement),
              baseValueForPrice = getBaseValueFromMeasurement({ quantity: 1, unit_of_measurement: this.selectedPriceType })

            return this.priceValue * baseValueForItem / baseValueForPrice
          }
        }
      }

      return 0
    },
    previousPricePerUnit() {
      return this.previousPrice || 0
    },
    showPriceChangeWarning() {
      if (this.previousPricePerUnit && this.pricePerUnit) {
        let thenPrice = this.previousPricePerUnit,
          nowPrice = this.pricePerUnit

        return thenPrice !== nowPrice
      }

      return false
    },
    intakesPopUpFields() {
      return [
        { key: 'intake_id', label: this.translations.txtCostUnitInfoIntakesId },
        { key: 'distributor_title', label: this.translations.txtGenericDistributor },
        { key: 'date', label: this.translations.txtGenericDate },
        { key: 'quantity', label: this.translations.txtGenericQuantity },
        { key: 'price', label: this.translations.txtCostUnitInfoUnitCost },
        { key: 'edit', label: this.translations.txtGenericEdit }
      ]
    },
    intakesMapper() {
      let intakes = [],
        itemVariantPrice = this.itemVariantPrice

      if (this.inakesPopupOpen && itemVariantPrice && itemVariantPrice.intakes?.length && this.showPreviousPrices) {
        intakes = itemVariantPrice.intakes

        intakes = itemVariantPrice.intakes.map(element => ({
          intake_id: element.intake_id,
          distributor_title: this.distributorsById[element.distributor_id]?.title,
          date: formatDate(element.date),
          quantity: element.units,
          price: currencyFormat(element.price_per_unit)
        }))
      }

      return intakes
    },
    domId() {
      return 'z-' + (this.label || 'price-component').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    itemComputed() {
      if (this.item) {
        return this.item
      }
      if (this.itemVariantId) {
        return prepareVariantIdAsItem(this.itemVariantId, this.$store.state)
      }
      if (this.itemId) {
        return this.bottlesById[this.itemId]
      }
      return null
    },
    priceTypes() {
      let arr = []
      if (
        this.itemComputed &&
        this.itemComputed.measurement &&
        this.itemComputed.measurement.type &&
        this.measurementsByType[this.itemComputed.measurement.type] &&
        this.measurementsByType[this.itemComputed.measurement.type].units_of_measurements
      ) {
        arr = this.measurementsByType[this.itemComputed.measurement.type].units_of_measurements.map(z => ({ ...z }))
      }

      if (this.itemComputed && this.itemComputed.case_size && this.itemComputed.case_size > 1) {
        arr.unshift({ id: 'case', title: this.translations.txtGenericCase })
      }

      if (!arr.find(u => u.id === 'unit')) {
        arr.unshift({ id: 'unit', title: this.translations.txtGenericUnit })
        arr.unshift({ id: 'ea', title: this.translations.txtGenericEach })
      }

      return arr
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    openMovement(id) {
      this.setGlobalAction({ type: 'movementEdit', action: { id } })
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(modelValue) {
        if (modelValue) {
          this.quantity = modelValue.quantity || 0
          this.selectedPriceType = modelValue.type === 'manual' ? modelValue.measurement.unit_of_measurement : modelValue.type
          this.priceValue = modelValue.value || 0

          if (modelValue.price_discount) {
            this.discountType = modelValue.price_discount.type
            this.discountValuesMap[this.discountType] = modelValue.price_discount.value
          } else if (modelValue.discount) {
            this.discountType = 'amount'
            this.discountValuesMap[this.discountType] = modelValue.discount
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
