<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'draftInvoiceItemSearch', action: null })" size="lg"
    class="" hideFooter :title="translations.txtScannedInvoicesItemSearchDialog">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" :zIndex="12" size="sm" />
    </template>
    <b-container fluid class="px-0" style="min-height: 500px;">
      <div class="d-flex align-items-end mb-3">
        <div>
          <span>{{ translations.txtScannedInvoicesItemSearchBy }}</span>
          <br />
          <b-form-radio-group class="me-2" size="sm" buttons v-model="searchBasedOn" name="titleSearchLocal" button-variant="outline-primary">
            <b-form-radio value="code"> {{ translations.txtScannedInvoicesItemSearchByCode }} </b-form-radio>
            <b-form-radio value="other"> {{ translations.txtScannedInvoicesItemSearchByName }} </b-form-radio>
          </b-form-radio-group>
        </div>

        <wiskInput v-if="searchBasedOn === 'code'" infoTooltipKey="945627fb-1aae-48b0-9075-45a35c014121" :label="translations.txtGenericBottleSearchPlaceholderByCode" v-model="distributorCode" autofocus class="flex-grow-1 mb-0" />
        <wiskInput v-else infoTooltipKey="ff629753-aca5-4c8b-a012-378bb4972e3e" :label="translations.txtGenericBottleSearchPlaceholder" v-model="searchQuery" autofocus class="flex-grow-1 mb-0" />
      </div>

      <h6> {{ translations.translate('txtScannedInvoicesFoundLocal', { '{a}': filteredItems.length }) }} </h6>

      <wiskItemsGrid :columns="columnsLocal" trackBy="item_distributor_id" :items="filteredItems" :options="gridOptions" :header="{ hideHeader: true }" parentGridName="DraftInvoiceItemSearchGrid" infoTooltipBaseName="DraftInvoiceItemSearch" :gridStyle="{ height: !distributor?.wisk_distributor ? '500px' : '300px' }" class="draft-invoice-item-search-grid" preventCustomFieldsAsColumns />

      <template v-if="distributor?.wisk_distributor">
        <b-row class="mt-4">
          <b-col cols="11">
            <h6> {{ translations.translate('txtScannedInvoicesFoundOnline', { '{a}': onlineItems.length }) }} </h6>
          </b-col>
          <b-col>
            <wiskLoading :loading="loadingOnlineItems" style="background-color: transparent; justify-content: left; align-items: normal;" />
          </b-col>
        </b-row>
        <div class="px-0 " style="max-height: 300px; overflow: auto;">
          <wiskItemsGrid :columns="columnsOnline" trackBy="item_distributor_id" :items="onlineItems" :options="gridOptions" :defaultFilter="{ predicate: item => item }"
            :header="{ hideHeader: true }" parentGridName="DraftInvoiceItemSearchGridOnline" infoTooltipBaseName="DraftInvoiceItemSearchGridOnline"
            gridAutoHeight class="draft-invoice-item-search-grid" preventCustomFieldsAsColumns />
        </div>
      </template>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import get from 'lodash.get'
import api from '@/api'
import { round, stringFilter } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'

export default {
  name: 'DraftInvoiceItemSearch',
  components: { wiskItemsGrid },
  props: { editAction: Object },
  data() {
    return {
      searchQuery: '',
      caseSize: 1,
      distributorCode: '',
      distributorId: null,
      measurement: null,
      localGridApi: null,
      onlineItems: [],
      loadingOnlineItems: true,
      onlineSearchDebounceId: null,
      filteredItems: [],
      searchBasedOn: 'code'
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'itemVariants', 'distributorsById']),
    ...mapGetters(['itemVariantsAsItems', 'activeDistributors']),
    distributor() {
      return this.distributorsById[this.distributorId]
    },
    pageActions() {
      return [{ key: 10, title: this.translations.txtVenueBottlesCreate, action: this.openCreateBottleDialog }]
    },
    gridOptions() {
      return {
        getRowHeight: () => 50
      }
    },
    columnsLocal() {
      return {
        archived: { sortable: false, hide: true, field: 'archived', colId: 'archived' },
        image: { sortable: false, minWidth: 40, hide: false, cellRendererParams: { readonly: true } },
        title: { sort: null, sortable: false, minWidth: 130, cellRendererParams: { readonly: true, } },
        distributor: {
          hide: false,
          minWidth: 90,
          // sortable: true,
          sort: 'asc',
          comparator: (a, b) => {
            // bail out if distributor_id not defined in both a and b
            if (!a.input_value?.id && !b.input_value?.id) return 0

            // if b.input_value?.id not defined, a comes first
            if (!b.input_value?.id) return -1

            // if a.input_value?.id not defined, b comes first
            if (!a.input_value?.id) return 1

            // else perform our original custom sort
            return (b.input_value?.id === this.distributor?.id) - (a.input_value?.id === this.distributor?.id)
          },
          editable: false,
          cellEditorParams: {
            readonly: true,
            infoComponentType: 'extraText',
            extraTextPositionPadding: '13px',
            getExtraText: params => this.translations.txtGenericCode + ': ' + get(params, 'data.distributor_code', '')
          }
        },
        select: {
          colId: 'select',
          sortOrder: 2000,
          headerName: '',
          minWidth: 40,
          width: 80,
          sortable: false,
          headerClass: ['text-center'],
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            hideIcon: true,
            title: this.translations.txtGenericSelect,
            buttonVariant: 'outline-primary',
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: this.selectVariant
          },
          valueGetter: params => ({
            id: params.data && params.data.item_distributor_id,
            group: !!params.node.group,
            data: params.data
          })
        },
        newVariant: {
          colId: 'newVariant',
          sortOrder: 2000,
          headerName: '',
          minWidth: 70,
          width: 100,
          sortable: false,
          headerClass: ['text-center'],
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            hideIcon: true,
            title: this.translations.txtVenueBottlesCreateVariantShort,
            buttonVariant: 'outline-primary',
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: this.addVariant
          },
          valueGetter: params => ({
            id: params.data && params.data.item_id,
            group: !!params.node.group,
            data: params.data
          })
        },
      }
    },
    columnsOnline() {
      return {
        archived: { sortable: false, hide: true, field: 'archived', colId: 'archived' },
        // venueId: { field: 'venue_id' }, - only for testing
        image: { sortable: false, minWidth: 40, hide: false, cellRendererParams: { readonly: true } },
        title: {
          minWidth: 130,
          sortable: false,
          sort: null,
          cellRendererParams: {
            extraButtons: [],
            infoComponentType: 'extraText',
            extraText: this.translations.txtScannedInvoicesItemSearchDialogAddOnlineItemStatus,
            readonly: true,
          }
        },
        distributor: {
          hide: false,
          minWidth: 90,
          forceOverrideValuegetter: true,
          sort: 'asc',
          comparator: (a, b) => (b.input_value?.wisk_distributor_id === this.distributor?.wisk_distributor?.id) - (a.input_value?.wisk_distributor_id === this.distributor?.wisk_distributor?.id),
          editable: false,
          cellEditorParams: {
            readonly: true,
            infoComponentType: 'extraText',
            extraTextPositionPadding: '13px',
            getExtraText: params => this.translations.txtGenericCode + ': ' + get(params, 'data.distributor_code', '')
          }
        },
        addToVenue: {
          colId: 'addToVenue',
          sortOrder: 2000,
          headerName: '',
          minWidth: 70,
          width: 100,
          sortable: false,
          headerClass: ['text-center'],
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            hideIcon: true,
            title: this.translations.txtScannedInvoicesItemSearchDialogAddOnlineItem,
            buttonVariant: 'outline-primary',
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: this.addOnlineItemToVenue
          },
          valueGetter: params => ({
            id: params.data && params.data.item_distributor_id,
            group: !!params.node.group,
            data: params.data
          })
        },
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'importItem']),
    openCreateBottleDialog() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          preventNewItemPreview: true,
          creationSource: { type: 'draft_invoice', id: this.editAction.draftInvoiceId },
          title: this.searchQuery,
          caseSize: this.caseSize,
          measurement: this.measurement,
          distributorCode: this.distributorCode,
          distributor_id: this.distributorId,
          onChange: (item) => {
            this.selectVariant(item.item_distributors[0].id, { data: { ...item, createdNow: true, item_distributor_id: item.item_distributors[0].id } })
          }
        }
      })
    },
    addOnlineItemToVenue(id, row) {
      let variantId = get(row, 'data.item_distributor_id'),
        venueId = get(row, 'data.venue_id')

      // console.log('addOnlineItemToVenue', row.data)

      if (variantId && venueId) {
        this.importItem({
          from_venue_id: venueId,
          item_distributor_id: variantId
        }).then(item => {
          setTimeout(() => {
            this.selectVariant(variantId, { data: { ...item, item_distributor_id: variantId, fromOnlineImport: true } })
          }, 0)
        })
      }
    },
    addVariant(itemId) {
      this.setGlobalAction({
        type: 'itemVariantEdit',
        action: {
          id: 0,
          itemId,
          itemVariant: {
            item_code: this.distributorCode,
            title: this.searchQuery,
            distributor_id: this.distributorId,
            measurement: this.measurement
          },
          onChange: result => {
            this.selectVariant(result.id, { data: { ...result, item_distributor_id: result.id } })
          }
        }
      })
    },
    onlineSearch({ title, itemCode }) {
      if (this.distributor?.wisk_distributor) {
        this.loadingOnlineItems = true
        clearTimeout(this.onlineSearchDebounceId)
        this.onlineSearchDebounceId = setTimeout(() => {
          if (title || itemCode) {
            api.bottleTitleSearch({ skipArchived: true, skipCurrentVenueItems: true, title, itemCode, distributorId: this.distributorId })
              .then(data => {
                let onlineItems = []
                data.forEach(item => {
                  item.item_distributors.forEach(variant => {
                    onlineItems.push({
                      ...item,
                      titleSuffix: ` - (${round(variant.measurement.quantity, 4)} ${variant.measurement.unit_of_measurement}) - (${this.translations.txtGenericCode}: ${variant.item_code || ''})`,
                      online: true,
                      item_distributor_id: variant.id
                    })
                  })
                })

                this.onlineItems = onlineItems
                this.loadingOnlineItems = false
              })
          } else {
            this.onlineItems = []
            this.loadingOnlineItems = false
          }
        }, 500)
      }
    },
    localSearch: debounce(function ({ title = '', itemCode = '' }) {
      this.filteredItems = this.itemVariantsAsItems.filter(i => {
        if (i.archived) {
          return false
        }
        if (title.length) {
          return stringFilter('contains', i.title, title)
        }
        if (itemCode.length) {
          return stringFilter('contains', i.distributor_code, itemCode)
        }
        return false
      })
    }, 500),
    selectVariant(variantId, row = {}) {
      if (variantId && this.editAction && this.editAction.onChange) {
        this.editAction.onChange({ ...row.data, fromOnlineSearch: true })
        this.setGlobalAction({ type: 'draftInvoiceItemSearch', action: null })
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.onlineSearchDebounceId)
  },
  watch: {
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction) {
          this.distributorCode = this.editAction.distributorCode || ''
          this.searchQuery = this.editAction.query || ''
          this.distributorId = this.editAction.distributorId || null
          this.measurement = this.editAction.measurement || null
          this.caseSize = this.editAction.caseSize || 1
        }
      }
    },
    searchQuery(searchQuery) {
      console.log('searchQuery', searchQuery)

      this.onlineSearch({ title: searchQuery })
      this.localSearch({ title: searchQuery })
    },
    distributorCode(distributorCode) {
      console.log('distributorCode', distributorCode)

      this.onlineSearch({ itemCode: distributorCode })
      this.localSearch({ itemCode: distributorCode })
    },
    searchBasedOn: {
      immediate: true,
      handler() {
        if (this.searchBasedOn === 'code') {
          this.onlineSearch({ itemCode: this.distributorCode })
          this.localSearch({ itemCode: this.distributorCode })
        } else {
          this.onlineSearch({ title: this.searchQuery })
          this.localSearch({ title: this.searchQuery })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.draft-invoice-item-search-grid {
  .ag-theme-balham {
    .ag-header {
      max-height: 22px !important;
      min-height: 22px !important;

      .ag-header-row {
        height: 22px !important;

        .wisk-header-cell {
          font-weight: 500;
          font-size: 11px;
          padding: 0px;

        }
      }
    }
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .cell-text .overlay,
  .cell-pop-multiselect .overlay,
  .cell-number .overlay {
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
