<template>
  <img :src="src" :alt="alt" :class="classes" />
</template>

<script>
export default {
  name: 'BImg',
  props: {
    src: { type: String, default: '' },
    alt: { type: String, default: '' },
    block: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
    fluidGrow: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    rounded: { type: [Boolean, String], default: false },
    thumbnail: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        'd-block': this.block,
        'mx-auto': this.center,
        'img-fluid': this.fluid,
        'w-100': this.fluidGrow,
        'float-start': this.left,
        'float-end': this.right,
        rounded: this.rounded === true,
        [`rounded-${this.rounded}`]: typeof this.rounded === 'string' && this.rounded !== '',
        'img-thumbnail': this.thumbnail
      }
    }
  }
}
</script>
