<template class="w-100">
  <div class="w-100">
    <highstock v-if="itemStats || stats" :options="options" ref="graph"></highstock>
  </div>
</template>

<script>
export default {
  name: 'ItemStatsGraph',
  props: {
    type: String,
    stats: Array,
    item: Object,
    itemStats: Object,
    translations: { type: Object, required: true }
  },
  data() {
    return {
      chartAvailable: false,
      options: {}
    }
  },
  methods: {
    extractStats(callback) {
      let stats = this.stats || this.itemStats.inventory_periods || []
      return (stats || []).map(stat => [Date.parse(stat.date), callback(stat.stats)])
    },

    extractWeeklySalesStats(callback) {
      return (this.itemStats.sales_weekly || []).map(salesWeek => [Date.parse(salesWeek.interval.end), callback(salesWeek.stats)])
    },

    extractWeeklyIntakesStats(callback) {
      return (this.itemStats.intakes_weekly || []).map(salesWeek => [Date.parse(salesWeek.interval.end), callback(salesWeek.stats)])
    },

    updateGraphs() {
      if ((this.stats || this.itemStats) && this.chartAvailable) {
        let chart = this.$refs.graph.chart,
          series = []

        if (this.type === 'stock') {
          chart.setTitle({ text: 'Stock' })

          let seriesStock = {
            name: `${this.translations.txtGenericStock} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#6CF',
            data: this.extractStats(stats => stats.consumption.end.bottles)
          }

          let seriesIntake = {
            name: `${this.translations.txtGenericDeliveries} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#11E',
            visible: false,
            data: this.extractStats(stats => stats.consumption.movements.bottles)
          }
          series = [seriesStock, seriesIntake]
        } else if (this.type === 'consumption') {
          chart.setTitle({ text: this.translations.txtGenericConsumption })

          let seriesConsumption = {
            name: `${this.translations.txtGenericConsumption} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#6CF',
            data: this.extractStats(stats => stats.consumption.consumption.bottles)
          }

          let seriesSales = {
            name: `${this.translations.txtGenericSales} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#11E',
            data: this.extractStats(stats => (stats && stats.sales && stats.sales.volume && stats.sales.volume.ml / this.item.volume) || 0)
          }

          let seriesVariance = {
            name: `${this.translations.txtGenericVariance} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#F7A',
            data: this.extractStats(stats => (stats && stats.variance && stats.variance.volume && stats.variance.volume.ml / this.item.volume) || 0)
          }

          series = [seriesConsumption, seriesSales, seriesVariance]
        } else if (this.type === 'sales') {
          chart.setTitle({ text: this.translations.txtGenericWeekly })

          let seriesWeeklySales = {
            name: `${this.translations.txtItemStatsGraphWeeklySales} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#6CF',
            data: this.extractWeeklySalesStats(stats => stats.ml / this.item.volume)
          }

          let seriesWeeklyIntakes = {
            name: `${this.translations.txtItemStatsGraphWeeklyIntake} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#11E',
            visible: false,
            data: this.extractWeeklyIntakesStats(stats => stats.ml / this.item.volume)
          }

          let seriesStock = {
            name: `${this.translations.txtGenericStock} (${this.translations.txtGenericUnits})`,
            showInLegend: true,
            color: '#F7A',
            visible: false,
            data: this.extractStats(stats => stats.consumption.end.bottles)
          }

          series = [seriesWeeklySales, seriesWeeklyIntakes, seriesStock]
        }

        while (chart.series.length > 0) {
          chart.series[0].remove()
        }
        for (let i = 0; i < series.length; i++) {
          chart.addSeries(series[i])
        }
      }
    }
  },
  mounted() {
    this.chartAvailable = true

    if (this.stats || this.itemStats) {
      this.updateGraphs()
    }
  },
  watch: {
    itemStats: {
      handler: 'updateGraphs',
      immediate: true
    },
    translations: {
      handler() {
        let txtGenericClickToHide = this.translations.txtGenericClickToHide
        this.options = {
          /* time: {
            useUTC: false
          },*/
          chart: {
            type: 'spline',
            zoomType: 'x'
          },
          rangeSelector: {
            selected: 0
          },
          legend: {
            enabled: true,
            backgroundColor: '#FFFFFF',
            labelFormatter() {
              return `${this.name} (${txtGenericClickToHide})`
            }
          },
          xAxis: {
            type: 'datetime',
            labels: {
              format: '{value: %DoMMM}',
              align: 'right'
            },
            title: {
              text: this.translations.txtGenericDate
            }
          },
          yAxis: {
            title: {
              text: this.translations.txtGenericValue
            }
          },
          plotOptions: {
            spline: {
              marker: {
                enabled: true
              }
            }
          },
          tooltip: {
            pointFormat: '<b>{series.name}</b> {point.x: %DoMMMMYYYYhmmA}: {point.y:.2f} ' + this.translations.txtGenericUnits
          },

          series: []
        }
      },
      immediate: true
    }
  }
}
</script>
