export default {
  name: 'wisk-arrow-right-circle',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 16.771484 0.015625 A 17.5 17.5 0 0 0 17.5 35 A 17.5205 17.5205 0 0 0 35 17.5 A 17.5 17.5 0 0 0 16.771484 0.015625 z M 17.5 2.9160156 A 14.6 14.6 0 0 1 32.083984 17.5 A 14.5838 14.5838 0 1 1 17.5 2.9160156 z M 17.375 8.7070312 A 1.48 1.48 0 0 0 16.335938 11.238281 L 21.144531 15.998047 L 10.255859 15.998047 A 1.5 1.5 0 0 0 8.7558594 17.498047 A 1.5 1.5 0 0 0 10.255859 18.998047 L 12.185547 18.998047 A 1.528 1.528 0 0 0 12.484375 18.966797 L 21.175781 18.966797 L 16.335938 23.757812 A 1.4814267 1.4814267 0 0 0 18.414062 25.867188 L 25.804688 18.556641 A 1.5336 1.5336 0 0 0 26.244141 17.498047 A 1.4755 1.4755 0 0 0 25.804688 16.447266 L 18.414062 9.1367188 A 1.4128 1.4128 0 0 0 17.375 8.7070312 z'
    }
  ]
}
