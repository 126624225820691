export default {
  name: 'wisk-graph',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,29.9c0.1,0.8-0.5,1.6-1.3,1.8c-0.2,0.1-0.5,0.1-0.7,0.1l-31,0c-1,0.1-1.9-0.6-2-1.5c0-0.1,0-0.3,0-0.4L0,5.2 c0-0.2,0-0.5,0.1-0.7C0.3,3.7,1,3.2,1.8,3.2c0.8,0,1.4,0.5,1.6,1.3C3.4,4.8,3.4,5,3.4,5.3v23.1h29.5c0.2,0,0.4,0,0.6,0 C34.3,28.4,35,29.1,35,29.9z'
    },
    {
      d: 'M6.3,21.7c-0.2-0.6,0-1.3,0.5-1.7l7.9-7.1c0.6-0.7,1.7-0.8,2.4-0.2c0.1,0.1,0.1,0.1,0.2,0.2l5.5,4.7l6.2-5.6h-1.1 c-0.9,0.1-1.7-0.6-1.7-1.6s0.6-1.7,1.6-1.7c0.1,0,0.1,0,0.2,0c1.7,0,3.5,0,5.2,0c0.9-0.1,1.7,0.6,1.8,1.5c0,0.1,0,0.2,0,0.3v5.2 c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.7,0.5-1.2,0.5c-0.9,0-1.7-0.8-1.6-1.7c0,0,0,0,0,0v-1.5L24,21.1c-0.6,0.6-1.7,0.7-2.3,0 c0,0,0,0,0,0l-5.6-4.8l-6.9,6.1c-0.3,0.3-0.8,0.6-1.3,0.6c-0.1,0-0.2,0-0.2,0C7.1,22.8,6.5,22.4,6.3,21.7z'
    }
  ]
}
