export default {
  name: 'wisk-team',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 10.425781 7.0976562 A 4.8549 4.8549 0 1 0 15.291016 11.953125 A 4.86 4.86 0 0 0 10.425781 7.0976562 z M 25.910156 8.5859375 A 4.2341 4.2341 0 1 0 30.152344 12.820312 A 4.2385 4.2385 0 0 0 25.910156 8.5859375 z M 10.425781 9.15625 A 2.7975 2.7975 0 1 1 7.6191406 11.953125 A 2.8056 2.8056 0 0 1 10.425781 9.15625 z M 25.910156 10.644531 A 2.1767 2.1767 0 1 1 23.724609 12.820312 A 2.1834 2.1834 0 0 1 25.910156 10.644531 z M 10.425781 18.197266 A 11.2985 11.2985 0 0 0 3.3964844 20.265625 A 8.3186 8.3186 0 0 0 2.1425781 21.427734 A 8.5858 8.5858 0 0 0 0.009765625 26.728516 A 1.4488 1.4488 0 0 0 0 26.935547 A 0.9579 0.9579 0 0 0 0.94726562 27.902344 L 19.90625 27.902344 A 0.9578 0.9578 0 0 0 20.853516 26.935547 A 8.62 8.62 0 0 0 18.710938 21.427734 C 17.081537 19.575234 14.444481 18.197266 10.425781 18.197266 z M 24.941406 18.265625 A 9.6419 9.6419 0 0 0 19.537109 20.265625 A 8.313 8.313 0 0 1 20.791016 21.427734 A 8.5854 8.5854 0 0 1 22.923828 26.728516 L 34.179688 26.728516 A 0.83 0.83 0 0 0 35 25.880859 A 7.4826 7.4826 0 0 0 33.132812 21.082031 C 31.720913 19.466231 29.42135 18.265625 25.90625 18.265625 A 9.6419 9.6419 0 0 0 24.941406 18.265625 z M 9.9101562 20.242188 A 9.3441 9.3441 0 0 1 10.425781 20.253906 C 13.419181 20.253906 15.687162 21.106056 17.164062 22.785156 A 6.73 6.73 0 0 1 18.677734 25.84375 L 2.1777344 25.84375 A 6.5873 6.5873 0 0 1 3.6855469 22.789062 A 6.3356 6.3356 0 0 1 4.6425781 21.902344 A 9.3441 9.3441 0 0 1 9.9101562 20.242188 z M 25.78125 20.322266 A 9.7861 9.7861 0 0 1 25.90625 20.322266 C 28.44815 20.322266 30.358197 21.033613 31.591797 22.445312 A 5.5062 5.5062 0 0 1 32.773438 24.671875 L 24.710938 24.671875 A 10.6755 10.6755 0 0 0 22.886719 20.751953 A 9.7861 9.7861 0 0 1 25.78125 20.322266 z '
    }
  ]
}
