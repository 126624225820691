<template>
  <wiskModal :visible="!!editAction" hideHeaderExtra :title="modalTitle" size="lg" fullScreen :invisible="takingScreenshot" @ok="save" @hide="cancel" preventOKClose aria-label="Screenshot Tool" hideHelpButton>
    <div id="screenshotCanvasContainer" :class="{ drawing: drawingModeActive }"></div>

    <wiskLoading :loading="loading" />

    <template v-slot:modal-footer-left-side>
      <b-button variant="link" @click="drawingModeActive = !drawingModeActive" v-tooltip="drawingModeActive ? translations.txtContactUsAddScreenshotDrawStop : translations.txtContactUsAddScreenshotDraw">
        <icon name="wisk-edit" :class="{ 'text-primary': !drawingModeActive, 'text-danger': drawingModeActive }" />
      </b-button>
      <b-button variant="link" @click="clearDrawing" v-tooltip="translations.txtContactUsAddScreenshotDrawClear">
        <icon name="wisk-undo" />
      </b-button>
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import html2canvas from 'html2canvas'
import api from '@/api'

export default {
  name: 'ScreenshotTool',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      takingScreenshot: false,
      screenshotCanvas: null,
      loading: false,
      drawingModeActive: false,
      screenshotDataURL: null,
      drawingCanvasContext: null,
      screenshotCanvasContext: null,
      isDrawing: false,
      drawingCanvas: null,
      screenshotCanvasRect: null
    }
  },
  computed: {
    ...mapState(['translations', 'windowResizeKey']),
    ...mapGetters([]),
    modalTitle() {
      return this.editAction?.title || this.translations.txtContactUsAddScreenshot
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    save() {
      if (this.screenshotCanvas) {
        this.loading = true

        //!!! we must use a third canvas to merge the screenshot and the drawing, otherwise the scale is broken !!!
        let newCanvas = document.createElement('canvas'),
          newContext = newCanvas.getContext('2d')

        newCanvas.width = this.screenshotCanvas.width
        newCanvas.height = this.screenshotCanvas.height

        newContext.drawImage(this.screenshotCanvas, 0, 0)
        newContext.drawImage(
          this.drawingCanvas,
          0, 0, this.drawingCanvas.width, this.drawingCanvas.height,
          0, 0, this.screenshotCanvas.width, this.screenshotCanvas.height
        )

        newCanvas.toBlob(blob => {
          let formData = new FormData()
          formData.append('data', blob, 'screenshot.png')

          api.uploadMedia(formData).then(result => {
            this.editAction.save(result)
            this.loading = false

            setTimeout(() => {
              this.setGlobalAction({ type: 'screenshotTool', action: null })
            }, 0)
          })
        })
      }
    },
    cancel() {
      this.editAction.cancel()
      this.loading = false

      setTimeout(() => {
        this.setGlobalAction({ type: 'screenshotTool', action: null })
      }, 0)
    },
    clearDrawing() {
      this.drawingCanvasContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height)
    },
    canvasEventListenerDown(event) {
      const x = event.clientX - this.screenshotCanvasRect.left,
        y = event.clientY - this.screenshotCanvasRect.top

      this.isDrawing = true
      this.drawingCanvasContext.beginPath()
      this.drawingCanvasContext.moveTo(x, y)
    },
    canvasEventListenerMove(event) {
      if (this.isDrawing) {
        const x = event.clientX - this.screenshotCanvasRect.left,
          y = event.clientY - this.screenshotCanvasRect.top

        this.drawingCanvasContext.lineTo(x, y)
        this.drawingCanvasContext.stroke()
      }
    },
    canvasEventListenerUp() {
      this.isDrawing = false
    },
    init() {
      this.takingScreenshot = true

      html2canvas(document.body).then(screenshotCanvas => {
        let screenshotCanvasContainer = document.getElementById('screenshotCanvasContainer'),
          containerRect = screenshotCanvasContainer.getBoundingClientRect()

        this.screenshotDataURL = screenshotCanvas.toDataURL('image/png')

        if (screenshotCanvasContainer) {
          screenshotCanvasContainer.appendChild(screenshotCanvas)
          screenshotCanvas.classList.add('screenshot-canvas')
          screenshotCanvas.style.width = 'auto'
          screenshotCanvas.style.height = 'auto'

          setTimeout(() => {
            let canvasRect = screenshotCanvas.getBoundingClientRect(),
              adjustX = canvasRect.left - containerRect.left,
              adjustY = canvasRect.top - containerRect.top

            this.screenshotCanvasRect = canvasRect

            let drawingCanvas = document.createElement('canvas')
            screenshotCanvasContainer.appendChild(drawingCanvas)

            drawingCanvas.width = canvasRect.width
            drawingCanvas.height = canvasRect.height
            drawingCanvas.classList.add('drawing-canvas')

            drawingCanvas.style.top = `${adjustY}px`
            drawingCanvas.style.left = `${adjustX}px`

            this.screenshotCanvas = screenshotCanvas
            this.drawingCanvas = drawingCanvas
            this.drawingCanvasContext = drawingCanvas.getContext('2d')
            this.screenshotCanvasContext = screenshotCanvas.getContext('2d')

            this.drawingCanvasContext.strokeStyle = 'red'
            this.drawingCanvasContext.lineWidth = 2
            this.drawingCanvasContext.imageSmoothingEnabled = true
            this.drawingCanvasContext.imageSmoothingQuality = 'high'
            this.screenshotCanvasContext.imageSmoothingEnabled = true
            this.screenshotCanvasContext.imageSmoothingQuality = 'high'

            this.takingScreenshot = false
          }, 0)
        }
      })
    }
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {
    this.drawingCanvas.removeEventListener('mousedown', this.canvasEventListenerDown)
    this.drawingCanvas.removeEventListener('mousemove', this.canvasEventListenerMove)
    this.drawingCanvas.removeEventListener('mouseup', this.canvasEventListenerUp)
    document.removeEventListener('mouseup', this.canvasEventListenerUp)
  },
  watch: {
    takingScreenshot(takingScreenshot) {
      if (!takingScreenshot) {
        this.drawingModeActive = true
      }
    },
    drawingModeActive(drawingModeActive) {
      if (drawingModeActive) {
        this.drawingCanvas.addEventListener('mousedown', this.canvasEventListenerDown)
        this.drawingCanvas.addEventListener('mousemove', this.canvasEventListenerMove)
        this.drawingCanvas.addEventListener('mouseup', this.canvasEventListenerUp)
        document.addEventListener('mouseup', this.canvasEventListenerUp)
      } else {
        this.drawingCanvas.removeEventListener('mousedown', this.canvasEventListenerDown)
        this.drawingCanvas.removeEventListener('mousemove', this.canvasEventListenerMove)
        this.drawingCanvas.removeEventListener('mouseup', this.canvasEventListenerUp)
        document.removeEventListener('mouseup', this.canvasEventListenerUp)
      }
    }
  },
  windowResizeKey() {
    this.init()
  }
}
</script>

<style lang="scss">
#screenshotCanvasContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;
  //outline: 1px solid green;

  canvas.screenshot-canvas {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    //outline: 1px solid magenta;
  }

  canvas.drawing-canvas {
    position: absolute;
    //outline: 1px solid cyan;
  }

  &.drawing {
    canvas {
      cursor: url('/img/pencil-cursor.svg') 0 17, auto;
    }
  }
}
</style>
