import { DateTime, Duration } from 'luxon'
import get from 'lodash.get'
import isFinite from 'lodash.isfinite'
import isObject from 'lodash.isobject'
import { formatDate, currencyFormatHideZero, sortObject, round, setupCustomFieldsAsColumns } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'

const getGridColumns = ({ translations, setGlobalAction, checkInventoryEditable }) => [
  {
    headerName: translations.txtGenericYear,
    colId: 'year',
    wiskExpandFirst: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value }),
    valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
  },
  {
    headerName: translations.txtGenericMonth,
    colId: 'month',
    wiskExpandFirst: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify({ colId: 'month', sort: params.value + 1, title: translations[`txtGenericMonth${params.value}`] }),
    valueFormatter: params => translations[`txtGenericMonth${params.value}`],
    cellRendererParams: { useValueFormatter: true },
    valueGetter: params => (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0
  },
  {
    headerName: translations.txtGenericWeek,
    colId: 'week',
    wiskExpandFirst: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let dt = (params.data && params.data.date && DateTime.fromJSDate(params.data.date)) || null,
        week = (dt && dt.weekNumber) || 0,
        year = (week && params.data.date.getYear()) || 0,
        sort = parseInt(year.toString() + week.toString(), 10),
        title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

      return { colId: 'week', sort, title }
    }
  },
  {
    headerName: translations.txtInventoriesStartedAt,
    colId: 'startedAt',
    field: 'started_at',
    ...agGridFilters.date,
    sortOrder: 700,
    minWidth: 170,
    width: 200,
    cellRendererParams: { useValueFormatter: true },
    valueFormatter: params => formatDate(params.value)
  },
  {
    headerName: translations.txtInventoriesFinishedAt,
    colId: 'finishedAt',
    field: 'finished_at',
    ...agGridFilters.date,
    sortOrder: 800,
    minWidth: 170,
    width: 200,
    hide: true,
    cellRendererParams: { useValueFormatter: true },
    valueFormatter: params => formatDate(params.value)
  },
  {
    headerName: translations.txtGenericDescription,
    colId: 'description',
    field: 'description',
    hide: true,
    minWidth: 200,
    width: 200
  },
  {
    headerName: translations.txtInventoriesInAppTime,
    colId: 'duration',
    cellClass: ['text-center'],
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    minWidth: 70,
    width: 100,
    valueFormatter: params => {
      if (params.value) {
        let duration = Duration.fromObject({ seconds: params.value }).toFormat('h mm').split(' ')

        return `${duration[0]}h : ${duration[1]}m`
      }
      return '0m'
    },
    cellRendererParams: { useValueFormatter: true },
    valueGetter: params => {
      let duration = 0
      if (params.data) {
        duration = (params.data.stats && params.data.stats.duration && params.data.stats.duration.duration) || 0
      } else if (params.node && params.node.group && params.node.allLeafChildren) {
        params.node.allLeafChildren.forEach(child => {
          duration += (child.data && child.data.stats && child.data.stats.duration && child.data.stats.duration.duration) || 0
        })
      }
      return duration
    }
  },
  {
    colId: 'startedBy',
    headerName: translations.txtInventoriesStartedBy,
    minWidth: 140,
    width: 170,
    cellClass: [],
    hide: true,
    valueGetter: params => params.data && params.data.started_by && params.data.started_by.name
  },
  {
    colId: 'approvedBy',
    headerName: translations.txtInventoriesApprovedBy,
    minWidth: 140,
    width: 170,
    cellClass: [],
    hide: true,
    valueGetter: params => params.data && params.data.approved_by && params.data.approved_by.name
  },
  {
    headerName: translations.txtInventoriesTotalBottlesCost,
    colId: 'unitsTotalPrice',
    cellClass: ['text-end', 'currency'],
    remove: true, //temp fix until we can make sure this column has fresh data
    minWidth: 100,
    width: 100,
    cellRendererParams: { decimals: 2 },
    valueFormatter: params => currencyFormatHideZero(params.value),
    valueGetter: params => get(params, 'data.stats.stats.dollars', 0)
  },
  {
    colId: 'isLocked',
    sortOrder: 2600,
    headerName: translations.txtInventorieLockStatus,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    cellClass: ['pt-1'],
    valueGetter: params => (!params?.node?.group && (params?.data?.is_locked ? translations.txtInventoriesLocked : translations.txtInventoriesUnlocked)) || ''
  },
  {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 70,
    width: 70,
    cellClass: ['cell-more-details'],
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRenderer: 'CellText',
    cellRendererParams: {
      excludeFromExport: true,
      readonly: true,
      forceInfoComponent: true,
      extraButtons: [
        {
          id: 1,
          getVisible: checkInventoryEditable,
          action: id => {
            setGlobalAction({ type: 'inventoryEdit', action: { id } })
          },
          icon: 'wisk-edit'
        },
        {
          id: 2,
          action: id => {
            setGlobalAction({ type: 'inventoryDetails', action: { id } })
          },
          icon: 'wisk-more-info'
        }
      ],
      infoComponentType: 'extraButtons'
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group,
      input_value: ''
    })
  }
]

const getItemsGridColumns = ({ translations, openEdit, viewMode, subrecipesByInventoryItemId }) => ({
  image: {
    hide: false
  },
  title: {
    hide: false
  },
  location: {
    headerName: translations.txtGenericLocation,
    remove: viewMode === 'item',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    sortOrder: 790,
    colId: 'location',
    valueGetter: params => get(params, 'data.inventoryItem.location.title')
  },
  family: {
    hide: true,
    cellRendererParams: {}
  },
  category: {
    hide: true,
    cellRendererParams: {}
  },
  par: {
    hide: true,
    cellRendererParams: {
      readonly: true,
      infoComponentType: null
    }
  },
  costUnit: {
    minWidth: 120,
    sortOrder: 700,
    cellRendererParams: {
      readonly: true
    }
  },
  costUnitInventory: {
    colId: 'costUnitInventory',
    sortOrder: 750,
    headerName: translations.txtInventoriesCostUnit,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 100,
    width: 100,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      decimals: 2,
      mode: 'cost',
      infoComponentType: 'inventoryDetails',
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => {
      let price = get(params, 'data.inventoryItem.stats', {})

      return {
        id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
        stats: get(params, 'data.inventoryItem.stats', {}),
        item_id: params.data?.item_id,
        input_value: price.price_per_unit
      }
    }
  },
  distributor: { hide: true },
  distributorCode: { hide: true },
  full: {
    headerName: translations.txtInventoriesFullBottles,
    sortOrder: 920,
    colId: 'full',
    hide: true,
    cellRenderer: 'CellNumber',
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      decimals: 2,
      decimalsAsNeeded: true,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group,
      input_value: get(params, 'data.inventoryItem.stats.inventory_full')
    })
  },
  partials: {
    headerName: translations.txtInventoriesPartialsBottles,
    sortOrder: 910,
    colId: 'partials',
    hide: true,
    cellRenderer: 'CellNumber',
    aggFunc: 'wiskSum',
    cellRendererParams: {
      //decimals: 2,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group,
      input_value: get(params, 'data.inventoryItem.stats.partial_count')
    })
  },
  units: {
    headerName: translations.txtInventoriesTotalBottles,
    sortOrder: 900,
    colId: 'units',
    cellRenderer: 'CellUnits',
    keyCreator: params => params.value.input_value,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      decimals: 2,
      decimalsAsNeeded: true,
      readonly: true,
      infoComponentType: 'stock',
      suffix: ''
    },
    valueGetter: params => ({
      order_format: get(params, 'data.order_format'),
      case_size: get(params, 'data.case_size'),
      locations: get(params, 'data.inventoryItem.stats.locations', []),
      itemId: get(params, 'data.item_id'),
      group: !!params.node.group,
      input_value: get(params, 'data.inventoryItem.stats.units'),
      subrecipesByInventoryItemId
    })
  },
  unitsTotalUOM: {
    headerName: translations.txtInventoriesTotalUom,
    sortOrder: 900,
    colId: 'totalUom',
    cellRenderer: 'CellUnits',
    cellRendererParams: {
      decimals: 2,
      decimalsAsNeeded: true,
      readonly: true,
      suffix: '',
      useValueFormatter: true
    },
    valueFormatter: ({ value }) => {
      if (isObject(value) && isFinite(value.input_value)) {
        return `${round(value.input_value, 2)}${value.suffix}`
      }
      return ''
    },
    valueGetter: params => {
      let inputValue = 0,
        suffix = '',
        group = params.node.group

      if (params.data?.inventoryItemMeasurement) {
        const infoMeasurement = get(params.data, 'inventoryItemMeasurement.item_distributor_measurement', get(params.data, 'inventoryItemMeasurement.item_measurement'))
        inputValue = infoMeasurement.quantity * get(params, 'data.inventoryItem.stats.units')
        suffix = `${infoMeasurement.unit_of_measurement || ''}`
      }

      return {
        order_format: get(params, 'data.order_format'),
        case_size: get(params, 'data.case_size'),
        locations: get(params, 'data.inventoryItem.stats.locations', []),
        itemId: get(params, 'data.item_id'),
        group,
        suffix,
        input_value: group ? '' : inputValue
      }
    }
  },
  unitsTotalPrice: {
    headerName: translations.txtInventoriesTotalBottlesCost,
    sortOrder: 900,
    colId: 'unitsTotalPrice',
    minWidth: 100,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      infoComponentType: 'inventoryDetails',
      decimals: 2,
      prefix: window.WiskGlobals.currency,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group,
      locations: get(params, 'data.inventoryItem.stats.locations', []),
      input_value: get(params, 'data.inventoryItem.stats.dollars', 0)
    })
  },
  locationGroup: {
    colId: 'locationGroup',
    headerName: translations.txtGenericLocation,
    sortOrder: 800,
    hide: true,
    remove: viewMode === 'item',
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    keyCreator: params => JSON.stringify(sortObject(params.value)),
    valueGetter: params => {
      if (get(params, 'data.inventoryItem.location.id')) {
        return {
          id: get(params, 'data.inventoryItem.location.id'),
          sort: get(params, 'data.inventoryItem.location.title'),
          title: get(params, 'data.inventoryItem.location.title')
        }
      }

      return 'id missing!!!'
    }
  },
  more: {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 70,
    width: 70,
    remove: !openEdit,
    cellClass: ['cell-more-details'],
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      excludeFromExport: true,
      onClick: item => {
        let locationId = get(item, 'inventoryItem.location.id', get(item, 'inventoryItem.stats.locations.0.location.id'))
        openEdit(item.item_id, locationId)
      }
    },
    valueGetter: params => ({
      id: params.data,
      group: !!params.node.group
    })
  }
})

const getIndependentInventoriesGridColumns = ({ translations, openDetails, save, customFields }) => {
  let columns = [
      {
        headerName: translations.txtGenericYear,
        colId: 'year',
        wiskExpandFirst: true,
        enableRowGroup: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value }),
        valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
      },
      {
        headerName: translations.txtGenericMonth,
        colId: 'month',
        wiskExpandFirst: true,
        enableRowGroup: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        keyCreator: params => JSON.stringify({ colId: 'month', sort: params.value + 1, title: translations[`txtGenericMonth${params.value}`] }),
        valueFormatter: params => translations[`txtGenericMonth${params.value}`],
        cellRendererParams: { useValueFormatter: true },
        valueGetter: params => (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0
      },
      {
        headerName: translations.txtGenericWeek,
        colId: 'week',
        wiskExpandFirst: true,
        enableRowGroup: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        keyCreator: params => JSON.stringify(params.value),
        valueFormatter: params => params.value.title,
        valueGetter: params => {
          let dt = (params.data && params.data.date && DateTime.fromJSDate(params.data.date)) || null,
            week = (dt && dt.weekNumber) || 0,
            year = (week && params.data.date.getYear()) || 0,
            sort = parseInt(year.toString() + week.toString(), 10),
            title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

          return { colId: 'week', sort, title }
        }
      },
      {
        headerName: translations.txtInventoriesStartedAt,
        colId: 'startedAt',
        field: 'started_at',
        ...agGridFilters.date,
        sortOrder: 700,
        minWidth: 170,
        width: 200,
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => formatDate(params.value)
      },
      {
        headerName: translations.txtInventoriesFinishedAt,
        colId: 'finishedAt',
        field: 'finished_at',
        ...agGridFilters.date,
        sortOrder: 800,
        minWidth: 170,
        width: 200,
        hide: true,
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => formatDate(params.value)
      },
      {
        headerName: translations.txtGenericDescription,
        colId: 'description',
        field: 'description',
        hide: true,
        minWidth: 200,
        width: 200
      },
      {
        colId: 'user',
        headerName: translations.txtGenericUser,
        minWidth: 140,
        width: 170,
        cellClass: [],
        hide: true,
        valueGetter: params => params.data && params.data.user && params.data.user.name
      },
      {
        colId: 'id',
        headerName: translations.txtGenericId,
        minWidth: 140,
        width: 170,
        cellClass: [],
        hide: true,
        valueGetter: params => get(params, 'data.id')
      },
      {
        colId: 'measurementsCount',
        headerName: translations.txtInventoriesEntriesCount,
        minWidth: 140,
        width: 170,
        cellClass: [],
        hide: true,
        valueGetter: params => get(params, 'data.measurements_count')
      },
      {
        colId: 'more',
        sortOrder: 2700,
        headerName: translations.columnDetails,
        minWidth: 70,
        width: 70,
        cellClass: ['cell-more-details'],
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          excludeFromExport: true,
          onClick: openDetails
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          group: !!params.node.group,
          data: params.data
        })
      }
    ],
    customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate: translations.translate, save, agGridFilters, columns })
console.log(customFields)
  return [...columns, ...customFieldsColumns]
}

export { getGridColumns, getItemsGridColumns, getIndependentInventoriesGridColumns }
