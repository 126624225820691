<template>
  <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" v-if="localBottle" class="px-2 fill-width-height" :highlightOnValueChange="highlightOnChange"
    :operationTriggerButton="operationTriggerButton" @value="$emit('value', $event)" :key="key" @operationsChange="onChange" ref="localInputGroup">

    <b-row style="justify-content: center;">
      <b-col class="item-operations-main-column" :style="{ 'min-height': localBottle.item_id ? null : '650px' }">
        <wiskInput infoTooltipKey="4571ff19-7681-4de7-aee1-d8f4894053e3" :triggerInputOnLoad="localForceInitOperations" :modelValue="localBottle.image"
          :label="translations.txtGenericImage" operation="image" showPlainText dummy v-if="showImage" @operation="onSingleFieldChange">
          <div class="position-relative images-controls-wrapper">
            <imageView v-if="localBottle" :src="localBottle.image" @click="editItemImage" filterDisplay="small" />
            <div class="position-absolute" v-if="localBottle.image" style="top: 0; right: 0;">
              <b-button variant="link" @click="removeItemImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericRemove">
                <icon name="wisk-trash" class="image-view-overlay-icon text-danger" :scale="1" />
              </b-button>
            </div>
            <div class="position-absolute" style="bottom: 0; right: 0;">
              <b-button variant="link" @click="editItemImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericEdit">
                <icon name="wisk-edit" class="image-view-overlay-icon text-primary" :scale="1" />
              </b-button>
            </div>
          </div>
        </wiskInput>
        <wiskInput infoTooltipKey="f78e889c-6bad-49c3-9558-f5d1724029f7" class="" :label="translations.txtGenericTitle" :modelValue="localBottle.title" operation="title"
          required :autocomplete="autocompleteTitle && !cloneBase" @autocompleteInput="onTitleAutocompleteInput" @autocompleteSelected="titleAutocompleteSelected"
          :autocompleteItems="autocompleteItems" :autocompleteMinChars="titleSearchLocal ? 1 : 2" :autocompleteItemFormatter="addSuffix" :disabled="!!localBottle.linked_subrecipe_id"
          autocompleteDisplayKey="title" :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations"
          @operation="onSingleFieldChange" />

        <wiskSelect infoTooltipKey="5a25e541-2b14-4ad9-a100-6207a5156008" :label="translations.txtGenericCategory" v-show="activeFamilies && activeFamilies.length" :modelValue="localBottle.category"
          operation="category_id" :items="activeFamilies" :multiselectOptions="multiselectOptionsCategories" :triggerInputOnSet="triggerInputOnSet"
          :triggerInputOnLoad="localForceInitOperations" valueName="category" required ref="categorySelector" @operation="onSingleFieldChange"
          :addNewItem="addCategoryConfig" />

        <wiskSelect infoTooltipKey="de8146b2-a56c-492e-888f-b7b47967c2e9" v-if="!localBottle.item_id" :label="translations.txtGenericDistributor" v-show="activeDistributors && activeDistributors.length"
          :modelValue="localBottle.distributor" operation="distributor_id" operationEmpty="distributor_clear" :items="activeDistributors" valueName="distributor" :addNewItem="addDistributorConfig" />

        <wiskInput infoTooltipKey="6a013597-911a-4552-88d9-7918982f9612" class="d-none" :modelValue="localBottle.distributor_id" operation="distributor_id" operationEmpty="distributor_clear"
          :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" />

        <wiskInput infoTooltipKey="abf236aa-f465-4e2d-a910-52e20b77bcf4" v-if="!localBottle.item_id" :label="translations.txtGenericDistributorCode" v-model="localBottle.distributor_code" operation="distributor_code"
          operationEmpty="distributor_code_clear" />

        <wiskInput infoTooltipKey="305af914-96ae-42f7-b7ba-da9a7eda2d8f" class="d-none" :modelValue="localBottle.distributor_code" operation="distributor_code" operationEmpty="distributor_code_clear"
          :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" />

        <wiskInput infoTooltipKey="1c07ed36-8322-4e04-96d0-b7c3f9a13f39" :label="translations.txtGenericWiskId" :modelValue="localBottle.item_id" operation="item_id" disabled v-if="showItemId" />

        <measurement v-if="!localBottle.item_id" v-model="localBottle.measurement" :triggerInputOnLoad="localForceInitOperations"
          :startOpened="openMeasurement" :inline="!localBottle.item_id" showAlias horizontal :label="translations.txtVenueBottlesMeasurement" :disabled="localBottle && !!localBottle.linked_subrecipe_id" />

        <wiskInput infoTooltipKey="fd8d7ec1-3ec1-41a6-8e40-23e35ec11cdf" v-if="!localBottle.item_id" triggerInputOnSet triggerInputOnLoad
          :label="translations.txtGenericCaseSize" inputType="number" operation="case_size" :modelValue="localBottle.case_size" />

        <!-- <priceEditor v-if="!localBottle.item_id" :item="localBottle" inline horizontal operation="price_per_uom" /> -->

        <b-card no-body class="mb-2 position-relative rounded" v-if="!localBottle.item_id">
          <b-card-header header-tag="header" class="position-sticky cursor-pointer px-3 rounded-top" role="tab" @click="newItemExtraFieldsVisible = !newItemExtraFieldsVisible">
            <div class="text-primary text-bold">
              {{ translations.txtItemOperationsAdditionalFields }}

              <icon name="wisk-caret-down" class="float-end" :scale="1" v-if="!newItemExtraFieldsVisible" />
              <icon name="wisk-caret-up" class="float-end" :scale="1" v-if="newItemExtraFieldsVisible" />
            </div>
          </b-card-header>
          <b-collapse :visible="newItemExtraFieldsVisible">
            <b-card-body class="p-2">
              <wiskSelect v-if="!localBottle.item_id" infoTooltipKey="5ac7b970-6119-4dd9-9399-9e7555142a25" :label="translations.txtGenericWeights" v-model="localBottle.weights" operation="weight_add" operationEmpty="weight_delete"
                taggable multiple forceNumber :multiselectOptions="multiselectOptionsWeights" valueName="weights" triggerInputOnSet />

              <wiskSelect v-if="!localBottle.item_id" infoTooltipKey="7018ddd9-6d3a-47f2-84fb-c4d474a0d939" :label="translations.txtGenericEmptyWeights" v-model="localBottle.empty_weights" :multiselectOptions="multiselectOptionsEmptyWeights"
                operationEmpty="empty_weight_delete" valueName="empty_weights" taggable multiple forceNumber operation="empty_weight_add" triggerInputOnSet />

              <wiskSelect v-if="!localBottle.item_id" infoTooltipKey="f300f3f4-eacb-4bf3-abb5-a0decd934559" :label="translations.txtGenericBarcodes" v-model="localBottle.barcodes" operation="barcode_add"
                operationEmpty="barcode_delete" :multiselectOptions="multiselectOptionsBarcodes" taggable multiple valueName="barcodes" triggerInputOnSet :tagValidator="value => value.length > 5" />

              <infoTooltip v-if="localBottle && !localBottle.item_id" class="mt-3 mb-4 d-block w-100" inline :scale="1.2" helpKey="newItemCreationInfo" />
            </b-card-body>
          </b-collapse>
        </b-card>

        <wiskInput infoTooltipKey="6a1d1284-778d-4fe8-81ba-0742fa98ec21" :label="translations.txtGenericRetailPrice" :modelValue="localBottle.retail_price" inputType="number"
          :decimals="4" decimalsAsNeeded :minDecimals="2" operation="retail_price" :triggerInputOnSet="triggerInputOnSet" v-if="venue.price_retail && localBottle.item_id"
          :triggerInputOnLoad="localForceInitOperations" @operation="onSingleFieldChange" />

        <wiskInput infoTooltipKey="2835def7-4946-4c8a-a3d9-5c93a2726063" v-if="localBottle.item_id" :label="translations.txtGenericParLevel" :modelValue="parLevel.par_level" inputType="number"
          :decimals="2" decimalsAsNeeded operation="par_level" :fitChildren="defaultVariant && defaultVariant.case_size > 1" :readonly="!currentPermissionsByType.item_par_level_manage"
          :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" @operation="onSingleFieldChange">
          <wiskSelect infoTooltipKey="ff809abf-b835-48fd-94fe-fc2c1432c8f8" v-if="defaultVariant && defaultVariant.case_size > 1" required :modelValue="parLevel.type" displayAsButtonGroup @operation="onSingleFieldChange"
            :items="[{ id: 'unit', title: translations.txtGenericUnits }, { id: 'case', title: translations.txtGenericCases }]" operation="par_level_type" class="mb-0" />
        </wiskInput>

        <wiskInput infoTooltipKey="02dfcc60-a2a6-48fd-a291-e58892fc2543" v-if="localBottle.item_id && hasPos" :label="translations.txtGenericInventoryAlert" :modelValue="localBottle.inventory_alert"
          inputType="number" :decimals="1" decimalsAsNeeded operation="inventory_alert" @operation="onSingleFieldChange"
          :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" />

        <wiskInput v-if="localBottle.item_source_id && !cloneBase" hidden :modelValue="localBottle.item_source_id || localBottle.id" inputType="number"
          :decimals="0" operation="item_source_id" :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" />

        <wiskInput v-if="localBottle.item_id && !cloneBase" hidden :modelValue="localBottle.item_id" inputType="number"
          :decimals="0" operation="item_source_id" :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" />

        <wiskInput v-if="!showImage && localBottle.image" hidden :modelValue="localBottle.image" operation="image"
          :triggerInputOnSet="triggerInputOnSet" :triggerInputOnLoad="localForceInitOperations" @operation="onSingleFieldChange" />

        <wiskSelect infoTooltipKey="0a940d2f-65ff-4ec0-8f3f-129394da0b93" v-if="venue.venue_type === 'food' && localBottle.item_id" :label="translations.txtAllergens"
          :modelValue="localBottle.allergens" @operation="onSingleFieldChange" operation="allergen_add" operationEmpty="allergen_delete" taggable multiple valueName="allergens"
          :items="activeAllergens" :addNewItem="manageAllergensConfig" />

        <wiskSelect infoTooltipKey="dad59ae0-7c51-44cd-9b0f-e66538cfc12a" v-if="localBottle.item_id" :label="translations.txtItemOperationsInventoryAreas"
          :modelValue="localBottle.inventory_areas" @operation="onSingleFieldChange" operation="inventory_area_add" operationEmpty="inventory_area_delete" multiple valueName="inventory_areas"
          :items="activeLocations" :allowAddNewItem="false" />

        <wiskSelect infoTooltipKey="190b1901-682a-46af-b2e0-7fa9d769c54d" v-if="localBottle.item_id" :label="translations.txtGenericCountry" trackBy="code"
          :modelValue="localBottle.country_code" @operation="onSingleFieldChange" operation="country" valueName="country" :items="countries" />

        <wiskInput v-if="localBottle.item_id && venue.venue_type === 'food'" infoTooltipKey="5a9805b5-e105-4ee9-a5c0-b9407d93cd8e" decimalsAsNeeded
          :label="translations.txtGenericYields" :valueCol="12" hideLegend inputClass="px-0" @operation="onSingleFieldChange" operation="yields"
          inputType="range" :modelValue="localBottle.yields" :min="0.01" :max="1" :step="0.01" :decimals="2" suffix=" unit" />

        <wiskInput v-if="localBottle.item_id" :label="translations.txtGenericExcludedFromVariance" infoTooltipKey="20733d6f-ee85-48f1-9e52-52d9de21974e" :modelValue="bottle.excluded_from_variance"
          :triggerInputOnLoad="localForceInitOperations" @operation="onSingleFieldChange" operation="excluded_from_variance" inputType="checkbox" />

        <wiskInput v-if="localBottle.item_id && venue.child_venues?.length" :label="translations.txtParentVenueItemsActiveInVenues" infoTooltipKey="8bd8df31-043c-405b-8831-7244fce6593d" :modelValue="bottle.always_active_in"
          @operation="onSingleFieldChange" operation="active_in_venue_set" inputType="gridMultiSelect" :items="childVenuesWithPermissions" :titleLabel="translations.txtGenericVenue" parentGridName="ItemAlwaysActivIn" />

        <div v-if="algo">
          <span>
            {{ translations.txtGenericStock }}: {{ toFixed(algo.stats.units, 2) }} {{ translations.txtGenericUnits }}
          </span>

          <v-menu :triggers="['hover', 'focus']" :distance="5" v-if="algo.item_distributors && algo.item_distributors.length" class="d-inline" :disposeTimeout="1000000">
            <icon :id="stockInfoDomId" class="text-primary ms-2 float-right" name="wisk-information-button" :scale="0.8" />

            <template v-slot:popper>
              <div class="p-2 pb-0 overflow-hidden">
                <b-table-simple bordered small style="">
                  <thead>
                    <tr>
                      <th scope="row"> {{ translations.txtVenueBottlesVariant }} </th>
                      <th scope="row"> {{ translations.txtGenericUnits }} </th>
                      <th scope="row"> {{ translations.txtVenueBottlesViewInventory }} </th>
                      <th scope="row"> {{ translations.txtVenueBottlesViewMovement }} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="variant in algo.item_distributors" :key="variant.item_distributor_id">
                      <td> {{ variant.title }} </td>
                      <td> {{ toFixed(variant.stats ? variant.stats.units : 0, 2) }} </td>
                      <td class="text-center">
                        <b-button variant="link" v-if="get(variant, 'stats.inventory.id')" @click="openInventory(get(variant, 'stats.inventory.id'))" class="text-primary" style="bottom: 10px;">
                          <icon name="wisk-arrow-right-circle" />
                        </b-button>
                      </td>
                      <td class="text-center">
                        <b-row v-for="movement in get(variant, 'stats.intake.movements')" :key="movement.id">
                          <b-col>
                            <span>
                              {{ translations.translate('tplVenueBottlesViewMovementDetail', { '{a}': formatDate(movement.date), '{b}': movement.value }) }}
                            </span>
                            <b-button variant="link" v-if="movement.id" @click="openMovement(movement.id)" class="text-primary" style="bottom: 10px;">
                              <icon name="wisk-arrow-right-circle" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </div>
            </template>
          </v-menu>

        </div>
        <wiskInputGroup v-if="activeVariants?.length > 1" class="m-0 p-0 h-auto" key="inventoriableInputGroup">
          <inventoriable :itemId="localBottle.item_id" class="mt-5" />
        </wiskInputGroup>

        <div class="mt-3">
          <span>
            {{ translations.txtGenericWiskId }}: {{ localBottle.item_id }}
          </span>
        </div>

      </b-col>

      <b-col class="item-operations-main-column" v-if="!variantSelectorVisible && bottle.item_distributor_ids?.length">
        <wiskLoading loading />
      </b-col>
      <b-col class="item-operations-main-column" v-if="variantSelectorVisible && bottle.item_distributor_ids?.length">
        <fieldset class="mt-4" style="position: relative;" :key="visibleVariants?.length">
          <legend class="item-variant-selector-legend" style="" :class="{ 'has-variants': visibleVariants.length > 1 }">
            {{ translations.txtVenueBottlesVariants }}

            <wiskInputGroup class="m-0 p-0" :key="variantSelectorKey">
              <wiskSelect infoTooltipKey="d8dfb161-8953-4d14-985b-61baeb6fedee" v-if="visibleVariants.length > 1" required :leabel="translations.txtVenueBottlesVariants" v-model="selectedItemVariantTab" @buttonGroupWidth="variantSelectorWidth = $event"
                :items="visibleVariants" :multiselectOptions="{}" :displayAsButtonGroup="variantSelectorAsButtonGroup" ref="variantSelectorComponent" class="mb-0 mt-2" :customLabelOverride="setVariantSelectorSuffix" />
            </wiskInputGroup>

            <wiskActions v-if="!disabled && !hideVariantsActions" :actions="variantsActions" style="position: absolute; top: -5px; right: -5px;" dropdownClass="float-end" hasButtonSlot variant="link">
              <template v-slot:button-content>
                <icon name="wisk-caret-down" :scale="1" class="text-primary mt-1" />
              </template>
            </wiskActions>

          </legend>

          <div v-if="variantsKeepAlive">
            <itemVariant v-for="variant in visibleVariants" v-show="selectedItemVariant && selectedItemVariant.id === variant.id" :emitOperationNoSave="variantsInsideEmitOperation"
              :disabled="disabled" @operation="$emit('variantOperation', { id: variant.id, operations: $event })" :key="variant.id" @variantIsDefault="$emit('variantIsDefault', $event)"
              :itemId="useItemNotItemId ? null : localBottle.item_id" :item="useItemNotItemId ? localBottle : null" :itemVariant="variant" :usePriceFromVariant="usePriceFromVariant"
              @newVariant="addVariant" />
          </div>

          <div v-if="!variantsKeepAlive" :key="visibleVariants?.length">
            <!-- @switchToVariant="switchToVariant" -->
            <itemVariant v-if="selectedItemVariant" :emitOperationNoSave="variantsInsideEmitOperation" @variantIsDefault="$emit('variantIsDefault', $event)" :usePriceFromVariant="usePriceFromVariant"
              :disabled="disabled" @operation="$emit('variantOperation', { id: selectedItemVariant.id, operations: $event })" :key="selectedItemVariant.id" @newVariant="addVariant"
              :itemId="useItemNotItemId ? null : localBottle.item_id" :item="useItemNotItemId ? localBottle : null" :itemVariant="selectedItemVariant" />
          </div>

        </fieldset>
      </b-col>

      <b-col class="item-operations-main-column" v-if="localBottle && localBottle.item_id && customFieldsByTarget.item && customFieldsByTarget.item.filter(c => !c.archived).length">
        <customFields target="item" :item="localBottle" @operation="onSingleFieldChange" mdCol="12" />
      </b-col>
    </b-row>
    <confirm ref="confirmDialogArchive" />

    <confirm ref="confirmDialog" />

    <slot name="inputFields"></slot>

    <wiskLoading :loading="loading" />
  </wiskInputGroup>

  <!-- dummy just for height -->
  <div v-else style="height: 1000px;"></div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import { emptyBottle, guid, objectFilter, toFixed, formatDate, round } from '@/modules/utils'
import imageView from '@/components/common/ImageView'
import customFields from '@/components/customFields/CustomFieldsRender'
import itemVariant from '@/components/bottles/ItemVariantEdit'
import inventoriable from '@/components/bottles/ItemInventoriable'
import measurement from '@/components/bottles/Measurement'

export default {
  name: 'ItemOperations',
  emits: ['operations', 'value', 'validStateChanged', 'titleAutocompleteSelected', 'variantIsDefault', 'variantOperation', 'operation', 'refresh'],
  components: { imageView, itemVariant, measurement, customFields, inventoriable },
  props: {
    disabled: Boolean,
    operationsFromOutside: Array,
    algo: Object,
    variantsInsideEmitOperation: Boolean,
    variantsKeepAlive: Boolean,
    hideVariantsActions: Boolean,
    openMeasurement: Boolean,
    usePriceFromVariant: Boolean,
    useParLevelFromItem: Boolean,
    useItemNotItemId: Boolean,
    showItemId: Boolean,
    showImage: { type: Boolean, default: true },
    autocompleteTitle: Boolean,
    cloneBase: Object,
    triggerInputOnLoad: Boolean,
    triggerInputOnSet: Boolean,
    selectedVariantId: Number,
    hideArchiveCheckbox: Boolean,
    operationTriggerButton: Boolean,
    highlightOnChange: Boolean,
    bottle: {
      type: Object,
      default: () => ({ item_id: 0, id: 0 })
    }
  },
  data() {
    return {
      get,
      newItemExtraFieldsVisible: false,
      formatDate,
      operations: [],
      variantSelectorKey: 1,
      visibleVariants: [],
      showArchivedVariants: false,
      autocompleteItems: [],
      titleSearchOptions: [
        { text: 'Global', value: false },
        { text: 'Local', value: true }
      ],
      loading: false,
      titleSearchLocal: false,
      valid: true,
      variantSelectorAsButtonGroup: true,
      variantSelectorWidth: 1,
      variantSelectorVisible: false,
      multiselectOptionsCategories: {
        groupValues: 'categories',
        groupLabel: 'title',
        multiple: false
      },
      key: 1,
      selectedItemVariantTab: 0,
      selectedItemVariant: null,
      localBottle: merge({}, emptyBottle),
      localForceInitOperations: false,
      parLevel: { par_level: 0 }
    }
  },
  computed: {
    ...mapState(['parLevelsById', 'familiesById', 'categoriesById', 'translations', 'distributorsById', 'customFieldsByTarget', 'itemPricesById', 'bottles', 'itemVariantsById', 'currentPermissionsByType', 'posIntegrations']),
    ...mapGetters(['activeFamilies', 'activeAllergens', 'venue', 'activeDistributors', 'countries', 'activeLocations', 'permissionsByVenueIdByType']),
    hasArchivedVariants() {
      return !!(
        this.localBottle &&
        this.localBottle.item_distributor_ids &&
        this.localBottle.item_distributor_ids.find(id => id && this.itemVariantsById[id] && this.itemVariantsById[id].archived)
      )
    },
    activeVariants() {
      return this.localBottle?.item_distributor_ids?.map(id => this.itemVariantsById[id]).filter(v => v && !v.archived)
    },
    hasPos() {
      return this.posIntegrations.filter(i => !i.is_archived).length > 0
    },
    stockInfoDomId() {
      return 'stock-info-icon-' + guid()
    },
    childVenuesWithPermissions() {
      return this.venue?.child_venues?.map(v => ({ ...v, disabled: !get(this.permissionsByVenueIdByType, `${v.id}.shared_item_manage`, false) }))
    },
    addDistributorConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
    variantsActions() {
      if (this.selectedItemVariant) {
        return [
          {
            key: 8,
            title: this.translations.txtVenueBottlesVariantShowArchived,
            variant: 'primary',
            hide: !this.hasArchivedVariants || this.showArchivedVariants,
            action: () => {
              this.showArchivedVariants = true
            }
          },
          {
            key: 9,
            title: this.translations.txtVenueBottlesVariantHideArchived,
            variant: 'primary',
            hide: !this.hasArchivedVariants || !this.showArchivedVariants,
            action: () => {
              this.showArchivedVariants = false
            }
          },
          {
            key: 10,
            title: this.translations.txtVenueBottlesCreateVariant,
            variant: 'primary',
            action: this.addVariant,
            hide: !!(this.bottle && this.bottle.linked_subrecipe_id)
          },
          {
            key: 15,
            title: this.translations.txtVenueBottlesCloneVariant,
            variant: 'primary',
            action: this.cloneVariant,
            hide: !!(this.bottle && this.bottle.linked_subrecipe_id)
          },
          { key: 100, isDivider: true },
          {
            key: 20,
            title: this.translations.txtGenericArchive,
            variant: 'danger',
            hide: this.selectedItemVariant.archived || this.selectedItemVariant.is_default,
            action: this.toggleItemVariantArchived
          },
          {
            key: 30,
            title: this.translations.txtGenericRestore,
            variant: 'success',
            hide: !this.selectedItemVariant.archived || this.selectedItemVariant.is_default,
            action: this.toggleItemVariantArchived
          }
        ]
      }
      return []
    },
    defaultVariant() {
      if (this.visibleVariants && this.visibleVariants.length) {
        return this.visibleVariants.find(v => v && v.is_default)
      }
      return null
    },
    priceEditingDisabledInfo() {
      if (get(this.localBottle, 'item_distributor_ids.length', 0) > 1) {
        return this.translations.txtVenueBottlesPriceDisabledMultipleVariants
      }

      return ''
    },
    addCategoryConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'categoryEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: category => {
                callbackItemInjected(category)
              }
            }
          })
        },
        label: this.translations.txtGenericCreateCategory
      }
    },
    manageAllergensConfig() {
      return {
        action: (id, searchQuery, value, callbackRefresh) => {
          this.setGlobalAction({
            type: 'allergens',
            action: {
              onChange: callbackRefresh
            }
          })
        },
        label: this.translations.txtAllergensManage
      }
    },
    titlePlaceholder() {
      if (this.autocompleteTitle) {
        return this.titleSearchLocal ? this.translations.txtItemOperationsSearchLocal : this.translations.txtItemOperationsSearchWisk
      }
      return this.translations.txtGenericTitle
    },
    multiselectOptionsBarcodes() {
      return {
        tagPlaceholder: this.translations.txtGenericAddBarcode,
        placeholder: this.translations.txtGenericInputBarcode
      }
    },
    multiselectOptionsWeights() {
      return {
        tagPlaceholder: this.translations.txtGenericAddWeight,
        placeholder: this.translations.txtGenericInputWeight
      }
    },
    multiselectOptionsEmptyWeights() {
      return {
        tagPlaceholder: this.translations.txtGenericAddEmptyWeight,
        placeholder: this.translations.txtGenericInputEmptyWeight
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setItemVariant']),
    toFixed,
    //due to the async nature of couch/pouch roundtrip, we can't switch to the new variant
    // switchToVariant(id) {
    //   this.$emit('refresh')

    //   setTimeout(() => {
    //     this.selectedItemVariantTab = id
    //   }, 1000)
    // },
    openInventory(id) {
      this.setGlobalAction({
        type: 'inventoryDetails',
        action: {
          id,
          itemIdFilter: get(this.localBottle, 'item_id'),
          viewMode: 'item_location',
          expandBatches: true
        }
      })
    },
    openMovement(id) {
      this.setGlobalAction({ type: 'movementEdit', action: { id, itemIdFilter: get(this.localBottle, 'item_id') } })
    },
    toggleItemVariantArchived() {
      if (this.$refs.confirmDialogArchive && this.selectedItemVariant) {
        this.$refs.confirmDialogArchive.confirm({
          callback: () => {
            this.setItemVariant({ id: this.selectedItemVariant.id, operation: { type: 'archive', value: !this.selectedItemVariant.archived } }).then(() => {
              this.selectedItemVariantTab = (this.visibleVariants[0] && this.visibleVariants[0].id) || (this.defaultVariant && this.defaultVariant.id) || 0

              setTimeout(() => {
                this.variantSelectorKey++
                this.checkVariantComponentWidth()
              }, 0)
            })
          },
          message: this.selectedItemVariant.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.selectedItemVariant.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    setOperationAndValue(value, type, valueName = type, typeClear = null) {
      this.localBottle[valueName] = value
      this.localBottle = { ...this.localBottle }
      if (this.$refs.localInputGroup && this.$refs.localInputGroup.setOperation) {
        this.$refs.localInputGroup.setOperation({ operation: { type, value }, operationTypes: { set: type, clear: typeClear } })
      }
    },
    titleAutocompleteSelected(bottle) {
      if (bottle) {
        this.autocompleteItems = []
        this.localForceInitOperations = this.triggerInputOnLoad
        this.operations = []

        this.localBottle = merge({}, emptyBottle, bottle, {
          order_format: bottle.order_format || 'unit'
        })
        if (this.localBottle.distributor_id) {
          this.localBottle.distributor = this.distributorsById[this.localBottle.distributor_id]
        }

        if (this.localBottle.category_id) {
          this.localBottle.category = this.categoriesById[this.localBottle.category_id]

          if (this.localBottle.category) {
            this.localBottle.family = this.familiesById[this.localBottle.category.family_id]
          }
        }
        delete this.localBottle.item_id

        this.operations = this.operations.filter(o => o && !o.type === 'title')
        this.operations.push({ type: 'title', value: bottle.title })

        this.$emit('titleAutocompleteSelected', bottle)
      }

      if (this.localForceInitOperations) {
        setTimeout(() => {
          this.key++
        }, 0)
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    setVariantSelectorSuffix(item) {
      if (item && typeof item === 'object') {
        if (item.archived) {
          return `${item.title}` + (item.archived ? ` -  ${this.translations.txtGenericArchived})` : '')
        }
        return item.title || ''
      }
      return item || ''
    },
    editItemImage() {
      if (!this.disabled) {
        this.setGlobalAction({
          type: 'fileUpload',
          action: {
            fileTypesAccept: 'image/*',
            useGoogle: true,
            target: this.localBottle,
            save: value => {
              this.setOperationAndValue(value, 'image')

              if (this.localBottle.item_id) {
                this.onSingleFieldChange({ type: 'image', value })
              }

              //needed in order to display new image before being saved
              this.$emit('value', { image: value })
            }
          }
        })
      }
    },
    removeItemImage() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          message: this.translations.confirmRemoveImages,
          title: this.translations.confirmRemoveTitle,
          callback: () => {
            this.setOperationAndValue(null, 'image_clear')

            if (this.localBottle.item_id) {
              this.onSingleFieldChange({ type: 'image', value: null })
            }

            //needed in order to display new image before being saved
            this.$emit('value', { image: null })
          },
        })
      }

    },
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('validStateChanged', this.valid)
    },
    addVariant(itemVariant) {
      this.setGlobalAction({
        type: 'itemVariantEdit',
        action: {
          id: 0,
          itemId: this.localBottle.item_id,
          itemVariant,
          onChange: result => {
            this.selectedItemVariantTab = result.id
          }
        }
      })
    },
    cloneVariant() {
      this.loading = true
      api.cloneItemVariant(this.selectedItemVariantTab).then(result => {
        setTimeout(() => {
          if (result && result.id) {
            this.selectedItemVariantTab = result.id
          }
        }, 500)
        this.loading = false
      })
    },
    onTitleAutocompleteInput(value) {
      if (this.titleSearchLocal) {
        this.autocompleteItems = this.bottles.filter(b => objectFilter({ payload: b, query: value }))
      } else {
        api.bottleTitleSearch({ title: value }).then(data => {
          this.autocompleteItems = data.map(b => ({
            ...b,
            titleSuffix: ` - (${round(b.measurement.quantity, 4)} ${b.measurement.unit_of_measurement})` + (b.archived ? ` - (${this.translations.txtGenericArchived})` : '')
          }))
        })
      }
    },
    onChange(operations) {
      let distributorOperation = operations.find(o => o.type === 'distributor_id')

      if (distributorOperation && distributorOperation.value && !this.distributorsById[distributorOperation.value]) {
        operations = operations.filter(o => o.type !== 'distributor_id')
      }

      this.operations = operations
      this.$emit('operations', [...operations])
    },
    onSingleFieldChange(operation) {
      this.$emit('operation', operation)
    },
    checkVariantComponentWidth(caller) {
      this.variantSelectorAsButtonGroup = true
      console.log('checkVariantComponentWidth', caller)
      setTimeout(() => {
        if (this.$refs.variantSelectorComponent && this.$refs.variantSelectorComponent.$el) {
          let targetWidth = this.$refs.variantSelectorComponent.$el.clientWidth || 406
          this.variantSelectorAsButtonGroup = this.variantSelectorWidth + 10 <= targetWidth
        }
      }, 200)
    },
    setVisibleVariants() {
      let variants = this.bottle.variants || (this.bottle.item_distributor_ids || []).map(id => id && this.itemVariantsById[id]),
        found = variants.find(v => v && v.is_default)

      variants = variants.filter(v => v)

      if (found) {
        found = { ...found }
        variants = variants.filter(v => v && !v.is_default)
        variants.unshift(found)
      }

      this.visibleVariants = this.showArchivedVariants ? variants : variants.filter(v => !v.archived)

      if (!this.selectedItemVariantTab && this.visibleVariants && this.visibleVariants.length && this.visibleVariants[0]) {
        this.selectedItemVariantTab = this.visibleVariants[0].id
      }
      if (this.visibleVariants && this.visibleVariants.length && !this.visibleVariants.find(v => v.id === this.selectedItemVariantTab)) {
        this.selectedItemVariantTab = this.visibleVariants[0].id
      }
    },
    setParLevel() {
      if (this.localBottle) {
        this.parLevel = this.useParLevelFromItem
          ? { par_level: this.localBottle.par_level, type: this.localBottle.par_level_type }
          : this.parLevelsById[this.localBottle.item_id] || { par_level: 0, type: 'unit' }
      }
    }
  },
  created() {
    this.localForceInitOperations = this.triggerInputOnLoad

    if (this.cloneBase) {
      this.titleAutocompleteSelected(this.cloneBase)
    }
  },
  watch: {
    variantSelectorWidth() {
      this.checkVariantComponentWidth('variantSelectorWidth')
    },
    itemVariantsById() {
      this.setVisibleVariants('itemVariantsById')
    },
    showArchivedVariants: {
      handler() {
        this.setVisibleVariants('showArchivedVariants')

        setTimeout(() => {
          this.variantSelectorVisible = true

          this.checkVariantComponentWidth('showArchivedVariants')
        }, 100)
      },
      immediate: true
    },
    operationsFromOutside: {
      handler(operationsFromOutside) {
        if (operationsFromOutside && operationsFromOutside.length) {
          this.onChange(operationsFromOutside.map(v => ({ ...v })))
        }
      },
      immediate: true
    },
    selectedVariantId: {
      handler(selectedVariantId) {
        if (selectedVariantId) {
          let found = this.bottle.item_distributor_ids && this.bottle.item_distributor_ids.find(id => id === this.selectedVariantId)

          if (found && this.itemVariantsById[found] && this.itemVariantsById[found].archived) {
            this.showArchivedVariants = true
          }

          this.selectedItemVariantTab = selectedVariantId
        } else if (this.visibleVariants && this.visibleVariants.length) {
          this.selectedItemVariantTab = this.visibleVariants[0].id
        }
      },
      immediate: true
    },
    selectedItemVariantTab() {
      if (this.selectedItemVariantTab) {
        this.selectedItemVariant = this.visibleVariants.find(v => v && v.id === this.selectedItemVariantTab)
      }
    },
    visibleVariants() {
      if (this.selectedItemVariantTab) {
        this.selectedItemVariant = this.visibleVariants.find(v => v && v.id === this.selectedItemVariantTab)
      }
    },
    parLevelsById: { handler: 'setParLevel' },
    localBottle: { handler: 'setParLevel', immediate: true },
    selectedItemVariant() {
      if (!this.showArchivedVariants && this.selectedItemVariant && this.selectedItemVariant.archived) {
        let found = this.visibleVariants.find(v => v && v.is_default)
        this.selectedItemVariantTab = (found && found.id) || 0
      }
    },
    bottle: {
      deep: true,
      immediate: true,
      handler(bottle) {
        if (bottle.item_id) {
          this.localBottle = merge({}, emptyBottle, bottle, { order_format: bottle.order_format || 'unit' })
        } else {
          this.localBottle = merge(this.localBottle, bottle, { order_format: bottle.order_format || 'unit' })
        }
        if (this.localBottle.distributor_id) {
          this.localBottle.distributor = this.distributorsById[this.localBottle.distributor_id]
        }
        if (this.bottle.case_size) {
          this.localBottle.case_size = this.bottle.case_size
        }

        //fix for item merge value event from operation
        if (this.localBottle.category) {
          this.localBottle.category_id = this.localBottle.category.id
        }

        if (this.localBottle.category_id) {
          this.localBottle.category = this.categoriesById[this.localBottle.category_id]

          if (this.localBottle.category) {
            this.localBottle.family = this.familiesById[this.localBottle.category.family_id]
          }
        }

        this.setVisibleVariants('bottle')
      }
    }
  }
}
</script>

<style lang="scss">
.bt-add-custom-field {
  flex: 1;
  padding-right: 5px;
  height: 33px;
  width: 100%;
  border: 1px dashed var(--wisk-grey);
  text-align: left;
  margin-bottom: 0.5em;
}

.item-operations-main-column {
  min-width: 270px;
}

.item-variant-selector-legend {
  position: relative;
  padding-right: 40px;

  &.has-variants {
    padding-right: 10px;
    width: 100%;
  }
}
</style>
