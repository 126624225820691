export default {
  name: 'wisk-dashboard',
  width: 576,
  height: 512,
  paths: [
    {
      d: 'M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V352H64V64H512z'
    },
    {
      transform: 'translate(100, 90) scale(0.35)',
      d: 'M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z'
    },
    { transform: 'translate(320, 100) scale(4)', d: 'M32.6,7.2H2.4C1.1,7.3,0,6.2,0,4.9s1-2.5,2.4-2.5h0.1h30.1c1.3,0,2.5,1,2.5,2.4C35.1,6.1,34,7.2,32.6,7.2L32.6,7.2z' },
    { transform: 'translate(320, 140) scale(4)', d: 'M32.6,7.2H2.4C1.1,7.3,0,6.2,0,4.9s1-2.5,2.4-2.5h0.1h30.1c1.3,0,2.5,1,2.5,2.4C35.1,6.1,34,7.2,32.6,7.2L32.6,7.2z' },
    { transform: 'translate(320, 180) scale(4)', d: 'M32.6,7.2H2.4C1.1,7.3,0,6.2,0,4.9s1-2.5,2.4-2.5h0.1h30.1c1.3,0,2.5,1,2.5,2.4C35.1,6.1,34,7.2,32.6,7.2L32.6,7.2z' }
  ]
}
