<template>
  <div>
    <wiskInputGroup style="padding: 0; display: flex;" @errorCountChanged="setValidState" :key="key"
      v-bind="$attrs">
      <wiskInput infoTooltipKey="d769672c-e1bb-4b79-b8f6-1cc9a0f4f142" autocompleteOpenAbove style="flex: 5; padding-right: 5px;" autofocus :modelValue="selectedBottle ? selectedBottle.title : ''"
        :label="translations.txtPurchaseOrdersAddWiskItem" :disabled="!!incommingItem || !distributor"
        :placeholder="autocompletePlaceHolder" autocomplete @autocompleteInput="onAutocompleteInput" @clear="onAutocompleteSelected(null)"
        @autocompleteSelected="onAutocompleteSelected" :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" :autocompleteItemExists="!!selectedBottle"
        autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" required />

      <wiskInput infoTooltipKey="ec0da93c-ff6e-4d7c-89a7-15c8023fdb40" style="flex: 3; padding-right: 5px;" :label="translations.txtGenericQuantity" required
      v-model="localValue.order.quantity" inputType="number" :decimals="localValue.order.format === 'partial' ? 2 : 0" decimalsAsNeeded ref="quantityInput" @keydown.enter.stop="emitAddOperation" />

      <div class="d-none">
        <wiskInput infoTooltipKey="2890b49d-32c5-4bd7-a24b-9642f09486f6" label="should be hidden" required v-model="localValue.item_distributor_id" inputType="number" triggerInputOnLoad />
      </div>

      <wiskSelect infoTooltipKey="3fce90d5-1582-406b-9ec8-847cc1255ee2" style="flex: 3; padding-right: 5px;" :label="translations.txtGenericOrderFormat" :modelValue="localValue.order.format" @update:modelValue="formatChanged"
        :items="orderFormats" :multiselectOptions="multiselectOptionsOrderFormat" />

      <wiskInput infoTooltipKey="65a523e7-e2b0-427a-94c7-453b7263a0e7" style="flex: 3; padding-right: 5px;" :label="translations.txtPurchaseOrdersPricePerUnit" required
        v-model="localValue.price_per_unit" inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" :prefix="currency" @keydown.enter.stop="emitAddOperation" />

      <wiskInput infoTooltipKey="477d2577-e94e-48cb-a1cb-486f4fa4b9b9" style="flex: 3; padding-right: 5px;" :label="translations.txtGenericTotal" showPlainText :modelValue="localValue.total"
        inputType="number" :decimals="2" :prefix="currency" />

      <b-button :disabled="!valid" style="flex: 1; padding: 0; height: 35px; height: 40px" size="sm" class="text-primary" variant="link" @click="emitAddOperation"
        ref="buttonAdd" v-tooltip="translations.txtGenericAdd">
        <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
        <icon style="margin-top: -10px; margin-left: 5px" name="wisk-check" :scale="1.2" />
      </b-button>
    </wiskInputGroup>
    <b-row>
      <b-col>
        <b-button v-if="newBottleVisible" variant="link" @click="createNewItem">
          {{ translations.txtVenueBottlesNotFoundCreate }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import merge from 'lodash.merge'
import { mapGetters, mapState, mapActions } from 'vuex'
import { objectFilter, prepareVariantAsItem } from '@/modules/utils'
import wiskInput from '@/components/common/WiskInput'
import wiskSelect from '@/components/common/WiskSelect'

const newItem = {
  total: 0,
  item_id: 0,
  order: {
    format: 'unit',
    quantity: 0
  },
  price_per_unit: 0
}

export default {
  name: 'PurchaseOrderAddItem',
  emits: ['validStateChanged', 'onlineCatalogButton', 'addItem'],
  components: { wiskSelect, wiskInput },
  props: { incommingItem: Object, purchaseOrderId: Number, distributor: { type: Object } },
  data() {
    return {
      key: 0,
      valid: true,
      autocompleteItems: [],
      query: '',
      newBottleVisible: false,
      selectedBottle: null,
      localValue: {
        order: {
          format: 'unit',
          quantity: 0
        }
      },
      multiselectOptionsOrderFormat: {
        showLabels: false,
        allowEmpty: false,
        searchable: false,
        openDirection: 'top',
        placeholder: 'Order Format'
      }
    }
  },
  computed: {
    ...mapGetters(['orderFormats', 'currency']),
    ...mapState(['itemVariants', 'distributorsById', 'translations', 'distributorPricesById', 'itemVariantPricesById']),
    autocompletePlaceHolder() {
      return this.distributor ? this.translations.txtGenericBottleSearchPlaceholder : this.translations.txtPurchaseOrdersAddItemPlaceHolderDisabled
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount

      this.$emit('validStateChanged', this.valid)
      if (this.valid && this.$refs.buttonAdd) {
        this.$refs.buttonAdd.classList && this.$refs.buttonAdd.classList.add('attention')
      }
    },
    createNewItem() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          creationSource: { type: 'purchase_order', id: this.purchaseOrderId },
          title: this.query,
          onChange: item => {
            this.onAutocompleteSelected(item)
            this.newBottleVisible = false
          }
        }
      })
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    onAutocompleteInput(value) {
      if (this.distributor) {
        this.query = value

        this.autocompleteItems = this.itemVariants
          .map(v => ({ ...prepareVariantAsItem(v, this.$store.state) }))
          .filter(b => !b.archived && b.variant && !b.variant.archived && objectFilter({ payload: b, query: value }))

        this.newBottleVisible = !this.autocompleteItems.length
        //this.$emit('onlineCatalogButton', true)
        //this.$emit('searchQuery', value)
      } else {
        console.error('Distributor is required. If no distributor provided autocomplete should have been disabled already!')
      }
    },
    onAutocompleteSelected(bottle) {
      if (bottle) {
        const variantId = bottle.item_distributor_id || bottle.item_distributor_ids[0],
          priceInfo = this.distributorPricesById?.[this.distributor?.id]?.[variantId] || this.itemVariantPricesById?.[variantId]?.purchase_price_per_unit || 0

        this.selectedBottle = bottle
        this.localValue = {
          ...this.localValue,
          item_id: bottle.item_id,
          item_distributor_id: variantId,
          order: {
            ...this.localValue.order,
            format: bottle.order_format,
            case_size: bottle.case_size,
          },
          price_per_unit: priceInfo + (bottle.fee || 0),
        }
        this.$refs.quantityInput.focus()
      } else {
        this.selectedBottle = null
        this.localValue = {
          ...this.localValue,
          item_distributor_id: 0,
          order: { format: 0, case_size: 0 },
          price_per_unit: 0,
        }
      }

      this.autocompleteItems = []
      this.$emit('onlineCatalogButton', false)
    },
    computeTotal() {
      if (this.selectedBottle) {
        if (this.localValue.order.format === 'case') {
          this.localValue.total = this.localValue.price_per_unit * this.selectedBottle.case_size * this.localValue.order.quantity
        } else if (this.localValue.order.format === 'unit') {
          this.localValue.total = this.localValue.price_per_unit * this.localValue.order.quantity
        }
      } else {
        this.localValue.total = 0
      }
    },
    formatChanged(value) {
      this.localValue.order.format = value
      this.localValue = merge({}, this.localValue)
    },
    emitAddOperation() {
      if (this.valid) {
        this.$emit('addItem', merge({}, this.localValue))
        this.localValue = merge({}, newItem)
        this.selectedBottle = null
        this.key++
      }
    }
  },
  watch: {
    localValue: {
      handler: 'computeTotal',
      deep: true
    },
    incommingItem(incommingItem) {
      this.onAutocompleteSelected(incommingItem)
    }
  }
}
</script>

<style></style>
