<template>
  <ul class="nav nav-tabs border-bottom-0">
    <li class="nav-item" v-for="tab in tabsComputed" :key="tab.id" :class="tab.cssClass">
      <span class="wisk-tab nav-link" style="margin-right: 2px" :class="{ active: selectedTabId === tab.id, 'cursor-pointer bg-gray-200': selectedTabId !== tab.id, 'tab-disabled': tab.disabled }" @click="select(tab)">
        {{ tab.title }}

        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="tab.htmlAppend"></span>
      </span>
    </li>
  </ul>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'

export default {
  name: 'TabSelector',
  emits: ['tabSelected'],
  components: {},
  props: {
    tabs: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedTabId: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    tabsComputed() {
      return this.tabs.map((tab, index) => {
        if (typeof tab === 'string') {
          return { title: tab, id: index }
        }
        return tab
      }).filter(t => !t.hide)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    select(tab) {
      if (tab && !tab.disabled) {
        this.selectedTabId = tab.id
        this.$emit('tabSelected', tab.id)

        let query = merge({}, this.$route.query, { tab: this.selectedTabId })
        this.$router.replace({ name: this.$route.name, query }).catch(() => { })
      }
    },
    clearFromURL() {
      let query = merge({}, this.$route.query)
      delete query.tab
      this.$router.replace({ name: this.$route.name, query }).catch(() => { })
    }
  },
  created() {
    if (this.tabsComputed.length && !this.$route?.query?.tab) {
      this.select(this.tabsComputed[0])
    }
  },
  beforeUnmount() {
    this.clearFromURL()
  },
  watch: {
    $route: {
      handler() {
        let tabQuery = this.$route.query.tab,
          fromURL

        if (!Number.isNaN(parseInt(tabQuery, 10))) {
          fromURL = parseInt(tabQuery, 10)
        } else {
          fromURL = tabQuery
        }

        let found = this.tabsComputed.find(t => t.id === fromURL)
        if (tabQuery && fromURL !== this.selectedTabId && found && !found.disabled) {
          this.select(found)
        }
      },
      immediate: true
    }
  }

}
</script>

<style lang="scss">
.tab-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
