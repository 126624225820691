export default {
  name: 'wisk-caret-up-down',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M30.8482,10.1264H4.1518A4.1535,4.1535,0,0,0,0,14.2783v6.4585a4.15,4.15,0,0,0,4.1518,4.1368H30.8482A4.1408,4.1408,0,0,0,35,20.7368V14.2783A4.1535,4.1535,0,0,0,30.8482,10.1264Zm-14.39,13.72H4.1518a3.1214,3.1214,0,0,1-3.125-3.11V14.2783a3.1246,3.1246,0,0,1,3.125-3.125H16.4584Zm13.11-4.5535a.6985.6985,0,0,1-.5358.2232.825.825,0,0,1-.5655-.2232l-2.5595-2.5893-2.5446,2.5893a.7787.7787,0,0,1-1.1012-1.1012l3.0953-3.14a.8093.8093,0,0,1,1.116,0l3.11,3.14A.777.777,0,0,1,29.5685,19.2932Z'
    },
    { d: 'M11.9643,17.18l-3.11,3.155a.8137.8137,0,0,1-.5655.2379.78.78,0,0,1-.5506-.2379L4.628,17.18a.7787.7787,0,1,1,1.116-1.0863L8.2886,18.683l2.56-2.5893A.7787.7787,0,0,1,11.9643,17.18Z' }
  ]
}
