<template>
  <div v-if="params.node.group || params.node.rowPinned || !items.length"></div>
  <div v-else class="align-items-center justify-content-center wisk-cell-wrapper">
    <popoverDropdown :items="items" :buttonClass="'menu-button ' + colIdClass + (forceVisible ? ' no-hide' : '')" :distance="distance" @visible="forceVisible = $event" :containerWidth="menuWidth" />
  </div>
</template>

<script>
import popoverDropdown from '@/components/common/PopoverDropdown'

export default {
  name: 'CellMenu',
  components: { popoverDropdown },
  data() {
    return {
      forceVisible: false
    }
  },
  computed: {
    items() {
      if (this.params.value && Array.isArray(this.params.value.items)) {
        return this.params.value.items.filter(item => !item.hide)
      }
      return []
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    distance() {
      return this.params.menuDistanceFromTop || 30
    },
    menuWidth() {
      return this.params.menuWidth || '160px'
    }
  },
  methods: {
    refresh() {
      return false
    }
  },
  watch: {
    forceVisible(forceVisible) {
      console.log('forceVisible', forceVisible)
    }
  }
}
</script>

<style lang="scss">
.ag-root {
  .ag-row-group {
    .menu-cell {
      background-color: transparent;
      cursor: default;
      border: 0 !important;
      width: 0 !important;
      padding: 0;
    }
  }

  .menu-cell {
    padding: 0 !important;
    overflow: visible;
    cursor: pointer;

    .menu-button {
      visibility: hidden;
      padding: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0;
        vertical-align: -0.2em;
        content: '';
        border-top: 0.45em solid;
        border-right: 0.45em solid transparent;
        border-bottom: 0;
        border-left: 0.45em solid transparent;
      }

      &.no-hide {
        visibility: visible;
      }
    }
  }

  .menu-cell.ag-cell-focus {
    border: 0 !important;
  }

  .ag-row-hover {
    .menu-cell {
      .menu-button {
        visibility: visible;
      }
    }
  }
}

.menu-button,
.menu-button button {
  width: 100%;
  height: 100%;
  border: 0;
}

.wisk-cell-wrapper {
  height: 100%;
  width: 100%;
}
</style>
