import get from 'lodash.get'
import { compareStrings, currencyFormatHideZero, formatDate, round, setupCustomFieldsAsColumns, getBaseValueFromMeasurement, toFixedHideZero, percentageFormat } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import cellRenderers from '@/components/cells/cellRenderers'

const getItemsGridColdefs = ({
  translations,
  translate,
  saveItemVariant,
  save,
  setGlobalAction,
  hasPos,
  getGetters,
  customFields,
  getDistributorsById,
  allergensById,
  currentPermissionsByType,
  locationsById,
  itemsByVariation,
  preventCustomFieldsAsColumns
}) => {
  const getInventoryValue = data => {
    let units = get(data, 'extra.algo.stats.units', 0),
      price = get(data, 'price.price_per_unit', 0),
      locationId = get(data, 'location.id'),
      variantId = get(data, 'item_distributor_id'),
      found = (locationId && get(data, 'extra.algo.locations', []).filter(l => l.location.id === locationId)) || { location: {} }

    if (variantId) {
      let foundInnerAlgo = get(data, 'extra.algo.item_distributors', []).find(v => v.item_distributor_id === variantId) || {}
      units = round(get(foundInnerAlgo, 'stats.units', 0), 2)
    }

    if (locationId) {
      units = round(
        found.reduce((sum, item) => sum + item.stats.units, 0),
        2
      )
    }

    return price * units >= 0 ? price * units : 0
  }
  const getInventoryValueRealTime = data => {
    let price = get(data, 'price.price_per_unit', 0),
      units = round(get(data, 'extra.algo.estimated_stock.units', 0), 2),
      locationId = get(data, 'location.id'),
      found = (locationId && get(data, 'extra.algo.estimated_stock.locations', []).find(l => l.location.id === locationId)) || { location: {} },
      variantId = get(data, 'item_distributor_id')

    if (variantId) {
      // We don't have realtime stock for variations
      units = 0
    }

    if (locationId) {
      units = round(get(found, 'stats.units', 0), 2)
    }

    return price * units >= 0 ? price * units : 0
  }

  let colDef = {
    dropdownMenu: {
      colId: 'dropdownMenu',
      sortOrder: 0,
      cellRenderer: 'CellMenu',
      headerName: '',
      headerClass: ['ps-2', 'pt-1'],
      maxWidth: 25,
      width: 25,
      minWidth: 25,
      cellClass: ['menu-cell'],
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressSortingToolPanel: true,
      suppressFiltersToolPanel: true,
      cellRendererParams: {
        excludeFromExport: true,
        menuDistanceFromTop: 49
      }
      /* the menu items must be placed inside this by the calling components using valueGetter method*/
    },
    image: {
      colId: 'image',
      sortOrder: 300,
      headerName: '',
      labelForPanel: translations.txtGenericImage,
      field: 'image',
      cellRenderer: 'CellImage',
      maxWidth: 50,
      width: 50,
      minWidth: 50,
      sortable: false,
      cellRendererParams: {
        excludeFromExport: true,
        overlayIcon: 'wisk-edit',
        filterDisplay: 'tinyThumb',
        onClick: data => {
          if (!data.wiskRowHidden) {
            setGlobalAction({
              type: 'fileUpload',
              action: {
                fileTypesAccept: 'image/*',
                useGoogle: true,
                target: data,
                save: value => {
                  save({ value, id: data.item_id, type: 'image', previousValue: data.image })
                }
              }
            })
          }
        }
      },
      //suppressColumnsToolPanel: true,
      suppressSortingToolPanel: true,
      suppressFiltersToolPanel: true,
      cellClass: ['image-cell'],
      valueGetter: params => ({ image: params?.data?.image || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png' })
    },
    title: {
      colId: 'title',
      sortOrder: 400,
      headerName: translations.txtGenericTitle,
      cellRenderer: 'CellText',
      ...agGridFilters.text,
      minWidth: 190,
      maxWidth: 400,
      sort: 'asc',
      enableRowGroup: true,
      keyCreator: params => (params.data.item_distributor_id ? params.data.title : params.value.formatted),
      cellRendererParams: {
        translate,
        actAsMoreButton: true,
        updateValue: save,
        type: 'title',
        suffix: '',
        validations: [{ type: 'required', validator: value => !!value, message: translations.txtValidationRequired }],
        extraButtons: [
          {
            id: 1,
            getVisible: data => {
              let visible = !!data.item_id && currentPermissionsByType.item_manage

              return visible
            },
            action: (id, data) => {
              let variantId = null
              if (data && data.item && data.item.item_distributor_id) {
                variantId = data.item.item_distributor_id
              }

              if (data && data.item) {
                console.log('data.item', JSON.parse(JSON.stringify(data.item)))
              }

              setGlobalAction({ type: 'itemEdit', action: { item_id: id, variantId } })
            },
            icon: 'wisk-edit'
          },
          {
            id: 2,
            getVisible: data => !!data.item_id,
            action: id => {
              setGlobalAction({ type: 'itemDetails', action: { item_id: id } })
            },
            icon: 'wisk-graph'
          },
          {
            id: 3,
            getVisible: data => !data.item_id && data.id && data.type === 'subrecipe',
            action: (id, data) => {
              if (data?.item?.subrecipe_id) {
                setGlobalAction({ type: 'subrecipeEdit', action: { id: data?.item?.subrecipe_id } })
              }
            },
            icon: 'wisk-edit'
          }
        ],
        infoComponentType: 'extraButtons'
      },
      wiskBottomAggregation: translations.txtGenericTotal + ':',
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        readonly: params?.data?.wiskRowHidden || !currentPermissionsByType.item_manage || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data && params.data.title,
        volume: params.data && params.data.volume,
        archived: params.data && params.data.archived,
        image: params.data && params.data.image,
        suffix: (params.data && params.data.titleSuffix) || '',
        formatted: (params.data && params.data.title && `${params.data.title}${params.data.titleSuffix || ''}`) || '',
        item: params.data
      })
    },
    measurement: {
      colId: 'measurement',
      sortOrder: 400,
      headerName: translations.txtGenericMeasurement,
      cellRenderer: 'CellText',
      ...agGridFilters.text,
      minWidth: 190,
      maxWidth: 400,
      enableRowGroup: true,
      keyCreator: params => params.value.input_value,
      cellRendererParams: {
        translate,
        readonly: true,
        extraButtons: [
          {
            id: 1,
            getVisible: data => currentPermissionsByType.item_manage && (get(data, 'item_distributor_ids', []).length === 1 || get(data, 'item_distributor_id')),
            action: (id, data) => {
              setGlobalAction({ type: 'itemEdit', action: { item_id: id, variantId: get(data, 'item.item_distributor_id'), openMeasurement: true } })
            },
            icon: 'wisk-edit'
          }
        ],
        infoComponentType: 'extraButtons'
      },
      valueGetter: params => {
        let formatted = `${get(params, 'data.measurement.quantity', '')} ${get(params, 'data.measurement.unit_of_measurement', '')}`,
          sort = getBaseValueFromMeasurement(get(params, 'data.measurement'))
        return {
          id: params.data && params.data.item_id,
          input_value: formatted,
          sort,
          item: params.data
        }
      }
    },
    measurementType: {
      colId: 'measurementType',
      hide: true,
      sortOrder: 490,
      enableRowGroup: true,
      headerName: translations.txtItemOperationsMeasurementType,
      filterType: 'list',
      cellRendererParams: {
        getFilterItems: () => [
          { title: translations.txtGenericVolume, id: 'volume' },
          { title: translations.txtGenericWeight, id: 'weight' },
          { title: translations.txtGenericUnit, id: 'unit' }
        ],
        readonly: true
      },
      cellClass: [],
      minWidth: 100,
      width: 110,
      valueGetter: params => get(params, 'data.measurement.type')
    },
    creationSource: {
      colId: 'creationSource',
      sortOrder: 400,
      headerName: translations.txtMovementSource,
      cellRenderer: 'CellText',
      filterType: 'list',
      minWidth: 190,
      maxWidth: 400,
      enableRowGroup: true,
      hide: true,
      keyCreator: params => params.value.input_value?.title,
      valueFormatter: params => params.value.input_value?.title,
      useValueFormatter: true,
      cellRendererParams: {
        translate,
        readonly: true,
        getFilterItems: () => Object.keys(translations.groupItemCreationSource).map(key => ({ id: key, title: translations.groupItemCreationSource[key] })),
        extraButtons: [
          {
            id: 1,
            getVisible: ({ creation_source: creationSource }) => {
              let type = get(creationSource, 'type'),
                id = get(creationSource, 'id')

              if (!id) {
                return false
              }

              switch (type) {
                case 'copy_from_venue':
                  if (getGetters().venuesHighlevelById[id]) {
                    return true
                  }
                  break
                case 'inventory':
                case 'purchase_order':
                case 'draft_invoice':
                case 'invoice':
                  return true

                default:
                  return false
              }
            },
            action: (itemId, { creationSource }) => {
              let type = get(creationSource, 'type'),
                id = get(creationSource, 'id')

              switch (type) {
                case 'copy_from_venue':
                  if (getGetters().venuesHighlevelById[id]) {
                    window.open(`${window.location.origin}/${id}`, '_blank')
                  }
                  break
                case 'inventory':
                  setGlobalAction({ type: 'inventoryDetails', action: { id } })
                  break
                case 'purchase_order':
                  setGlobalAction({ type: 'purchaseOrderEdit', action: { id } })
                  break
                case 'draft_invoice':
                  setGlobalAction({ type: 'draftInvoiceEdit', action: { id } })
                  break
                case 'invoice':
                  setGlobalAction({ type: 'movementEdit', action: { id } })
                  break

                default:
                  break
              }
            },
            icon: 'wisk-arrow-right-circle'
          }
        ],
        secondaryInfoComponentType: 'extraText',
        infoComponentType: 'extraButtons'
      },
      valueGetter: params => {
        let source = get(params, 'data.creation_source', {}),
          type = get(source, 'type'),
          user = getGetters().usersHighlevelById[get(source, 'user_id')]

        return {
          id: params?.data?.item_id,
          input_value: { id: type, title: translations.groupItemCreationSource[type] },
          creationSource: source,
          extraText: user ? `${translations.txtGenericCreatedBy}: ${user.name || ''}` : ''
        }
      }
    },
    distributor: {
      colId: 'distributor',
      sortOrder: 600,
      headerName: translations.txtGenericDistributor,
      cellEditor: 'CellPopMultiselect',
      cellRenderer: cellRenderers.CellPopMultiselect,
      minWidth: 120,
      width: 150,
      maxWidth: 200,
      enableRowGroup: true,
      keyCreator: params => params?.value?.input_value?.title || '',
      valueSetter: params => {
        params.data.distributor = getDistributorsById()[params.newValue]

        return true
      },
      editable: params => !params.data.wiskRowHidden && !params.data.archived_by_merge && !params?.data?.grouped_to,
      cellEditorParams: {
        autoOpen: true,
        translate,
        updateValue: params => {
          saveItemVariant({ ...params, type: params.value ? 'distributor_id' : 'distributor_clear' })
        },
        trackBy: 'id',
        getItems: () => getGetters().activeDistributors,
        multiselectWidth: 280
      },
      valueGetter: params => ({
        id: (params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id) || 'missing_default_variation_for_item_id_' + params.data?.item_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data?.distributor || getDistributorsById()[params.data?.distributor_id],
        title: params.data?.distributor?.title || `(${translations.txtGenericNoDistributor})`,
        sort: params.data?.distributor?.title
      })
    },
    distributorCode: {
      colId: 'distributorCode',
      sortOrder: 610,
      headerName: translations.txtGenericDistributorCode,
      cellRenderer: 'CellText',
      minWidth: 110,
      maxWidth: 140,
      enableRowGroup: true,
      cellClass: ['text-center', 'wrap'],
      cellRendererParams: {
        translate,
        updateValue: params => {
          saveItemVariant({ ...params, type: params.value ? 'item_code' : 'item_code_clear' })
        },
        type: ''
      },
      valueGetter: params => ({
        id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data?.distributor_code
      })
    },
    wiskId: {
      colId: 'wiskId',
      sortOrder: 700,
      hide: true,
      ...agGridFilters.text,
      headerName: translations.txtGenericWiskId,
      minWidth: 90,
      maxWidth: 120,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      valueGetter: params => `${params?.data?.item_id || ''}`
    },
    item: {
      colId: 'item',
      sortOrder: 700,
      hide: true,
      enableRowGroup: true,
      ...agGridFilters.text,
      headerName: translations.txtGenericBottle,
      minWidth: 90,
      maxWidth: 120,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      valueGetter: params => `${params?.data?.title || ''}`
    },
    variationGroup: {
      colId: 'variationGroup',
      sortOrder: 700,
      hide: true,
      suppressColumnsToolPanel: true,
      suppressSortingToolPanel: true,
      suppressFiltersToolPanel: true,
      headerName: translations.txtVenueBottlesVariant,
      keyCreator: params => JSON.stringify(params.value),
      enableRowGroup: true,
      minWidth: 90,
      maxWidth: 120,
      cellClass: ['text-center'],
      cellRenderer: 'CellText',
      valueGetter: params => ({
        id: params.data?.item_id,
        title: params.data?.title,
        input_value: params.data?.title
      })
    },
    excludedFromVariance: {
      colId: 'excludedFromVariance',
      sortOrder: 740,
      hide: true,
      headerName: translations.txtFilterExcludedfromVariance,
      minWidth: 150,
      maxWidth: 180,
      cellClass: ['checkbox-cell', 'pt-1'],
      cellRenderer: 'CellCheckbox',
      cellRendererParams: {
        translate: translations.translate,
        updateValue: save,
        type: 'excluded_from_variance'
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        input_value: params.data && params.data.excluded_from_variance
      })
    },
    location: {
      colId: 'location',
      sortOrder: 750,
      enableRowGroup: true,
      hide: true,
      headerName: translations.txtGenericLocation,
      minWidth: 100,
      maxWidth: 200,
      valueGetter: params => get(params, 'data.location.title', '')
    },
    family: {
      colId: 'family',
      sortOrder: 800,
      headerName: translations.txtGenericFamily,
      cellClass: ['text-center'],
      cellRenderer: 'CellText',
      minWidth: 90,
      maxWidth: 120,
      enableRowGroup: true,
      keyCreator: params => (params.value && params.value.input_value) || '',
      cellRendererParams: {
        translate,
        infoComponentType: 'familyInfo',
        readonly: true
      },
      valueGetter: params => ({
        id: params.data?.item_id,
        input_value: params.data?.family?.title,
        title: params.data?.family?.title
      })
    },
    category: {
      colId: 'category',
      sortOrder: 1000,
      headerName: translations.txtGenericCategory,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.category = getGetters().categoriesById[params.newValue]
        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 100,
      cellClass: ['text-center'],
      enableRowGroup: true,
      keyCreator: params => (params.value && params.value.input_value && params.value.input_value.title) || '',
      cellEditorParams: {
        autoOpen: true,
        translate,
        updateValue: save,
        required: true,
        type: 'category_id',
        trackBy: 'id',
        getItems: () => getGetters().activeFamilies,
        getFilterItems: () => getGetters().activeFamilies,
        multiselectWidth: 300,
        multiselectOptions: { groupValues: 'categories', groupLabel: 'title' },
        multiselectOptionsFilter: { groupValues: 'categories', groupLabel: 'title' }
      },
      valueGetter: params => ({
        id: params?.data?.item_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params?.data?.category,
        title: params.data?.category?.title
      })
    },
    orderGuide: {
      colId: 'orderGuide',
      sortOrder: 1005,
      hide: true,
      headerName: translations.txtCartBuilderOrderGuideItem,
      enableRowGroup: true,
      keyCreator: params => (params?.value?.input_value ? translations.txtCartBuilderInOrderGuide : translations.txtCartBuilderNotInOrderGuide),
      minWidth: 150,
      maxWidth: 180,
      cellClass: ['checkbox-cell', 'pt-1'],
      cellRenderer: 'CellCheckbox',
      cellRendererParams: {
        translate: translations.translate,
        updateValue: saveItemVariant,
        type: 'order_guide'
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        input_value: !!get(params, 'data.order_guide_item')
      })
    },
    barcodes: {
      colId: 'barcodes',
      sortOrder: 1010,
      headerName: translations.txtGenericBarcodes,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.barcodes = params.newValue

        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 100,
      hide: true,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      keyCreator: params => get(params, 'value.input_value', []).join(', '),
      cellEditorParams: {
        autoOpen: true,
        onOperation: (operation, id) => {
          saveItemVariant({ ...operation, id })
        },
        translate,
        multiple: true,
        type: 'barcode_add',
        operationEmpty: 'barcode_delete',
        allowAddNewItem: true,
        multiselectWidth: 300,
        tagValidator: value => value.length > 5,
        multiselectOptions: {
          noOptions: translations.txtGenericInputBarcode,
          tagPlaceholder: translations.txtGenericAddBarcode,
          placeholder: translations.txtGenericInputBarcode
        }
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: (params.data && params.data.barcodes) || [],
        items: []
      })
    },
    allergens: {
      colId: 'allergens',
      sortOrder: 1020,
      headerName: translations.txtAllergens,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.allergens = params.newValue

        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      remove: getGetters().venue.venue_type !== 'food',
      width: 100,
      hide: true,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      keyCreator: params => get(params, 'value.input_value', []).join(', '),
      cellEditorParams: {
        autoOpen: true,
        onOperation: (operation, id) => {
          save({ ...operation, id })
        },
        translate,
        multiple: true,
        type: 'allergen_add',
        operationEmpty: 'allergen_delete',
        allowAddNewItem: false,
        multiselectWidth: 300,
        multiselectOptions: { trackBy: 'id' },
        useValueFormatter: true,
        getItems: () => getGetters().activeAllergens,
        addNewItem: {
          action: (id, searchQuery, value, callbackRefresh) => {
            setGlobalAction({
              type: 'allergens',
              action: {
                onChange: callbackRefresh
              }
            })
          },
          label: translations.txtAllergensManage
        }
      },
      valueFormatter: params => params.value.input_value.map(id => (allergensById[id] || { title: 'Not found' }).title).join(', '),
      valueGetter: params => ({
        id: get(params, 'data.item_id'),
        input_value: (params.data && params.data.allergens) || []
      })
    },
    locations: {
      colId: 'locations',
      sortOrder: 1030,
      headerName: translations.txtItemOperationsInventoryAreas,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.inventory_areas = params.newValue

        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 100,
      hide: true,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      enableRowGroup: true,
      keyCreator: params => params.value.valueFormatted || '-',
      cellEditorParams: {
        autoOpen: true,
        onOperation: (operation, id) => {
          save({ ...operation, id })
        },
        translate,
        multiple: true,
        type: 'inventory_area_add',
        operationEmpty: 'inventory_area_delete',
        allowAddNewItem: false,
        multiselectWidth: 300,
        multiselectOptions: { trackBy: 'id' },
        useValueFormatter: true,
        getItems: () => getGetters().activeLocations
      },
      valueFormatter: params => params.value.valueFormatted,
      valueGetter: params => {
        let inputValue = get(params, 'data.inventory_areas', []),
          valueFormatted = inputValue
            .map(id => (locationsById[id] || { title: 'Not found' }).title)
            .sort((left, right) => compareStrings(left, right))
            .join(', ')

        return {
          id: get(params, 'data.item_id'),
          input_value: inputValue,
          valueFormatted,
          sort: valueFormatted
        }
      }
    },
    country: {
      colId: 'country',
      sortOrder: 1100,
      headerName: translations.txtGenericCountry,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.country = getGetters().countries.find(c => c.code === params.newValue)
        return true
      },
      editable: params => !params?.data?.grouped_to && !params?.data?.archived_by_merge && !params?.data?.wiskRowHidden,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 120,
      cellClass: ['text-center'],
      enableRowGroup: true,
      keyCreator: params => get(params, 'value.input_value.title', ''),
      remove: !(getGetters().countries && getGetters().countries.length),
      cellEditorParams: {
        autoOpen: true,
        translate,
        trackBy: 'code',
        updateValue: save,
        type: 'country',
        getItems: () => getGetters().countries,
        multiselectWidth: 400
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data && params.data.country
      })
    },
    weights: {
      colId: 'weights',
      sortOrder: 1150,
      headerName: translations.txtGenericWeights,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.weights = params.newValue

        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 100,
      hide: true,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      keyCreator: params => get(params, 'value.input_value', []).join(', '),
      cellEditorParams: {
        autoOpen: true,
        onOperation: (operation, id) => {
          saveItemVariant({ ...operation, id })
        },
        translate,
        multiple: true,
        type: 'weight_add',
        operationEmpty: 'weight_delete',
        allowAddNewItem: true,
        multiselectWidth: 300,
        multiselectOptions: {
          noOptions: translations.txtGenericInputWeight,
          tagPlaceholder: translations.txtGenericAddWeight,
          placeholder: translations.txtGenericInputWeight
        }
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: (params.data && params.data.weights) || [],
        items: []
      })
    },
    emptyWeights: {
      colId: 'emptyWeights',
      sortOrder: 1160,
      headerName: translations.txtGenericEmptyWeights,
      cellRenderer: cellRenderers.CellPopMultiselect,
      valueSetter: params => {
        params.data.empty_weights = params.newValue

        return true
      },
      editable: true,
      cellEditor: 'CellPopMultiselect',
      minWidth: 100,
      width: 100,
      hide: true,
      cellClass: ['text-center', 'stringTypeCellStyle'],
      keyCreator: params => get(params, 'value.input_value', []).join(', '),
      cellEditorParams: {
        autoOpen: true,
        onOperation: (operation, id) => {
          saveItemVariant({ ...operation, id })
        },
        translate,
        multiple: true,
        type: 'empty_weight_add',
        operationEmpty: 'empty_weight_delete',
        allowAddNewItem: true,
        multiselectWidth: 300,
        multiselectOptions: {
          noOptions: translations.txtGenericInputEmptyWeight,
          tagPlaceholder: translations.txtGenericAddEmptyWeight,
          placeholder: translations.txtGenericInputEmptyWeight
        }
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: (params.data && params.data.empty_weights) || [],
        items: []
      })
    },
    stock: {
      colId: 'stock',
      sortOrder: 1200,
      headerName: translations.txtGenericStock,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        decimals: 2,
        decimalsAsNeeded: true,
        readonly: true,
        infoComponentType: 'stock',
        secondaryInfoComponentType: 'extraText',
        suffix: '',
        extraButton: {
          action: id => {
            setGlobalAction({ type: 'itemDetails', action: { item_id: id, selectedTab: 3 } })
          },
          title: translations.txtGenericEdit,
          icon: 'wisk-arrow-right-circle'
        }
      },
      valueGetter: params => {
        let orderFormat = params.data && params.data.order_format,
          caseSize = params.data && params.data.case_size,
          units = round(get(params, 'data.extra.algo.stats.units', 0), 2),
          asCases = units / caseSize,
          locationId = get(params, 'data.location.id'),
          variantId = get(params, 'data.item_distributor_id'),
          foundLocation = (locationId && get(params, 'data.extra.algo.locations', []).filter(l => l.location.id === locationId)) || { location: {} }

        if (variantId) {
          let foundInnerAlgo = get(params, 'data.extra.algo.item_distributors', []).find(v => v.item_distributor_id === variantId) || {}
          units = round(get(foundInnerAlgo, 'stats.units', 0), 2)
          asCases = units / caseSize
        }

        if (locationId) {
          units = round(
            foundLocation.reduce((sum, item) => sum + item.stats.units, 0),
            2
          )
        }

        return {
          id: params.data && params.data.item_id,
          input_value: units,
          item_distributor_id: params.data && params.data.item_distributor_id,
          order_format: orderFormat,
          case_size: caseSize,
          locations: (locationId && foundLocation) || get(params, 'data.extra.algo.locations'),
          extraText: orderFormat === 'case' && asCases ? round(asCases, 2) + ' ' + translations.txtGenericCases : ''
        }
      }
    },
    stockFullBottles: {
      colId: 'stock_full_bottles',
      sortOrder: 1210,
      headerName: translations.txtGenericStockFullBottles,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        decimals: 2,
        decimalsAsNeeded: true,
        readonly: true,
        infoComponentType: 'stock'
      },
      valueGetter: params => ({
        input_value: get(params, 'data.extra.algo.stats.full'),
        item_distributor_id: params.data && params.data.item_distributor_id,
        order_format: params.data && params.data.order_format,
        case_size: params.data && params.data.case_size,
        locations: get(params, 'data.extra.algo.locations')
      })
    },
    pendingPurchaseOrders: {
      colId: 'pendingPurchaseOrders',
      sortOrder: 1300,
      headerName: translations.columnPendingPurchaseOrders,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        decimals: 1,
        readonly: true,
        infoComponentType: 'pendingPurchase'
      },
      valueGetter: params => ({
        id: params.data?.item_id,
        item_distributor_id: params.data?.item_distributor_id,
        input_value: get(params, 'data.extra.algo.pending_purchase_orders.units', 0),
        pending_purchase_orders: get(params, 'data.extra.algo.pending_purchase_orders', 0),
        title: params.data?.title,
        order_format: params.data?.order_format,
        case_size: params.data?.case_size
      })
    },
    perpetual: {
      colId: 'perpetual',
      sortOrder: 1400,
      remove: !hasPos,
      headerName: translations.txtGenericPerpetual,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      maxWidth: 150,
      cellRendererParams: {
        translate,
        readonly: true,
        decimals: 2,
        decimalsAsNeeded: true,
        infoComponentType: 'stock',
        secondaryInfoComponentType: 'extraText',
        extraButton: {
          action: id => {
            setGlobalAction({ type: 'itemDetails', action: { item_id: id, selectedTab: 6 } })
          },
          icon: 'wisk-arrow-right-circle'
        },
        placeholder: '-',
        suffix: ''
      },
      valueGetter: params => {
        let orderFormat = params?.data?.order_format,
          caseSize = params?.data?.case_size,
          locationId = params?.data?.location?.id,
          found = (locationId && get(params, 'data.extra.algo.estimated_stock.locations', []).find(l => l.location.id === locationId)) || { location: {} },
          units = locationId ? round(found?.stats?.units || 0, 2) : round(params?.data?.extra?.algo?.estimated_stock?.units || 0, 2),
          asCases = units / caseSize,
          status = params?.data?.extra?.algo?.estimated_stock?.status || 'available',
          extraText = orderFormat === 'case' && asCases ? round(asCases, 2) + ' ' + translations.txtGenericCases : ''

        if (status !== 'available') {
          extraText = translations.groupBottlesAlgoPerpetualStatus[status]
          units = '-'
        }

        //TODO: add extra text or info tooltip explaining this is real time value per item not variation
        //TODO: add unit of measurement of the default variation to the number
        return {
          id: params.data && params.data.item_id,
          input_value: params.data?.linked_subrecipe_id ? '-' : units,
          linked_subrecipe_id: params.data?.linked_subrecipe_id,
          item_distributor_id: params.data && params.data.item_distributor_id,
          order_format: orderFormat,
          case_size: caseSize,
          locations: (locationId && [found]) || get(params, 'data.extra.algo.estimated_stock.locations'),
          extraText
        }
      }
    },
    parSuggested: {
      colId: 'parSuggested',
      sortOrder: 1500,
      headerName: translations.txtGenericWISKPar,
      headerTooltip: translations.txtGenericWISKPar,
      cellClass: [],
      cellRenderer: 'CellUnits',
      minWidth: 110,
      width: 120,
      maxWidth: 150,
      cellRendererParams: {
        translate,
        decimals: 1,
        readonly: true,
        infoComponentType: 'parSuggested',
        setSuggestedPar: save
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        item_distributor_id: params.data && params.data.item_distributor_id,
        order_format: params.data && params.data.order_format,
        case_size: params.data && params.data.case_size,
        stock_prediction: get(params, 'data.extra.stockPrediction'),
        par_level: params.data && params.data.par_level,
        input_value: get(params, 'data.extra.stockPrediction.units_ideal_stock_range.ideal', 0)
      })
    },
    parSuggestedSales: {
      colId: 'parSuggestedSales',
      sortOrder: 1510,
      headerName: translations.txtGenericWISKParSales,
      headerTooltip: translations.txtGenericWISKParSales,
      cellClass: [],
      cellRenderer: 'CellUnits',
      minWidth: 110,
      width: 120,
      maxWidth: 150,
      cellRendererParams: {
        translate,
        decimals: 1,
        readonly: true,
        infoComponentType: 'parSuggested',
        setSuggestedPar: save,
        type: 'sale'
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        item_distributor_id: params.data && params.data.item_distributor_id,
        order_format: params.data && params.data.order_format,
        case_size: params.data && params.data.case_size,
        stock_prediction: get(params, 'data.extra.stockPredictionFromSales'),
        par_level: params.data && params.data.par_level,
        input_value: get(params, 'data.extra.stockPredictionFromSales.units_ideal_stock_range.ideal', 0)
      })
    },
    par: {
      colId: 'par',
      sortOrder: 1600,
      headerName: translations.txtGenericYourPar,
      headerTooltip: translations.txtGenericParLevel,
      cellRenderer: 'CellNumber',
      minWidth: 110,
      width: 130,
      maxWidth: 150,
      cellRendererParams: {
        translate,
        updateValue: save,
        type: 'par_level',
        allowEmpty: true,
        decimals: 1,
        extraButtons: [
          {
            id: 1,
            getVisible: data => {
              let defaultVariant = (data.variants || []).find(v => v && v.is_default)

              if (data.readonly) {
                return false
              }

              if (defaultVariant && defaultVariant.case_size > 1 && data.parLevelType === 'unit') {
                return true
              }

              return false
            },
            action: id => {
              save({ value: 'case', id, type: 'par_level_type', from: 'unit' })
            },
            title: translations.txtItemOperationsParLevelSwitchToCases,
            icon: 'wisk-independent-inventory'
          },
          {
            id: 2,
            getVisible: data => {
              let defaultVariant = (data.variants || []).find(v => v && v.is_default)

              if (data.readonly) {
                return false
              }

              if (defaultVariant && defaultVariant.case_size > 1 && data.parLevelType === 'case') {
                return true
              }

              return false
            },
            action: id => {
              save({ value: 'unit', id, type: 'par_level_type', from: 'case' })
            },
            title: translations.txtItemOperationsParLevelSwitchToUnits,
            icon: 'wisk-items-alt'
          }
        ],
        infoComponentType: 'extraButtons'
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        item_distributor_id: params.data && params.data.item_distributor_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge || !currentPermissionsByType.item_par_level_manage,
        order_format: params.data && params.data.order_format,
        case_size: params.data && params.data.case_size,
        input_value: params.data && params.data.par_level,
        suffix: (params.data && params.data.parLevelType && translations[params.data.parLevelType + 's']) || ''
      })
    },
    inventoryAlert: {
      colId: 'inventoryAlert',
      sortOrder: 1610,
      remove: !hasPos,
      headerName: translations.txtGenericInventoryAlert,
      cellRenderer: 'CellNumber',
      minWidth: 70,
      width: 70,
      hide: true,
      enableRowGroup: true,
      keyCreator: params => params.value.input_value,
      cellRendererParams: {
        translate,
        updateValue: save,
        type: 'inventory_alert',
        decimals: 1,
        suffix: ''
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data && params.data.inventory_alert
      })
    },
    variantsCount: {
      colId: 'variantsCount',
      headerName: translations.txtVenueBottlesVariants,
      sortOrder: 1620,
      cellClass: ['text-end'],
      ...agGridFilters.number,
      minWidth: 70,
      width: 70,
      hide: true,
      enableRowGroup: true,
      cellRendererParams: { useValueFormatter: true },
      valueFormatter: params => toFixedHideZero(params.value),
      valueGetter: params => get(params, 'data.variants', []).filter(v => v && !v.archived).length
    },
    invoicesCount: {
      colId: 'invoicesCount',
      sortOrder: 1690,
      headerName: translations.txtGenericIntakes,
      cellClass: ['text-end'],
      ...agGridFilters.number,
      minWidth: 70,
      width: 70,
      hide: true,
      valueGetter: params => params.data?.price?.intakes?.length
    },
    costUnit: {
      colId: 'costUnit',
      sortOrder: 1700,
      headerName: translations.txtGenericCurrentCostUnit,
      cellRenderer: 'CellNumber',
      cellClass: params => (params?.data?.price?.intakes?.length ? ['currency', 'link-like'] : ['currency']),
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        updateValue: params => {
          if (params.id) {
            saveItemVariant({ ...params, value: { type: 'unit', value: params.value } })
          }
        },
        type: 'price_per_uom',
        infoComponentType: 'costUnitInfo',
        secondaryInfoComponentType: 'extraText',
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      valueGetter: params => {
        let price = params.data?.price || {}

        return {
          id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
          item_id: params.data?.item_id,
          price,
          extraText: price.price?.type === 'case' ? `${currencyFormatHideZero(price.price?.value_after_discount || price.price?.value)} / ${translations.case}` : '',
          readonly: !!price.intakes?.length || !!params.data?.linked_subrecipe_id,
          input_value: price.price_per_unit === undefined ? price.price?.value : price.price_per_unit
        }
      }
    },
    costAlertUnit: {
      colId: 'costAlertUnit',
      sortOrder: 1705,
      headerName: translations.txtPOSItemsCostAlert,
      cellRenderer: 'CellNumber',
      cellClass: ['currency'],
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        type: 'alert_cost_per_uom',
        updateValue: params => saveItemVariant({ ...params, value: { type: params.data.alert_cost_per_uom.type, value: params.value } }),
        infoComponentType: 'extraText',
        extraTextPosition: 'right',
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      valueGetter: params => {
        let found = itemsByVariation || params.data?.variant ? params.data?.variant?.alert_cost_per_uom : params.data?.defaultVariant?.alert_cost_per_uom,
          value = 0,
          extraText = ''

        if (found) {
          value = found.value
          extraText = ` / ${found.type}`

          if (found.type === 'manual') {
            if (found.measurement.quantity === 1) {
              extraText = ` / ${found.measurement.unit_of_measurement}`
            } else {
              extraText = ` / ${found.measurement.quantity} ${found.measurement.unit_of_measurement}`
            }
          }
        }

        return {
          id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
          item_id: params.data?.item_id,
          extraText,
          input_value: value,
          readonly: !found,
          alert_cost_per_uom: found
        }
      }
    },
    costCase: {
      colId: 'costCase',
      sortOrder: 1710,
      headerName: translations.txtGenericCostCase,
      cellRenderer: 'CellNumber',
      cellClass: ['currency'],
      minWidth: 100,
      width: 100,
      cellRendererParams: {
        translate,
        updateValue: params => {
          if (params.id) {
            saveItemVariant({ ...params, value: { type: 'case', value: params.value, case_size: params.data.caseSize } })
          }
        },
        type: 'price_per_uom',
        infoComponentType: 'costUnitInfo',
        secondaryInfoComponentType: 'extraText',
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      valueGetter: params => {
        let price = get(params, 'data.price') || {},
          caseSize = get(params, 'data.variant.case_size', get(params, 'data.defaultVariant.case_size', 1)),
          pricePerCase = get(price, 'price.type') === 'case' ? get(price, 'price.value') : price.price_per_unit * caseSize

        return {
          id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
          item_id: params.data && params.data.item_id,
          price,
          caseSize,
          extraText: get(price, 'price.type') === 'unit' && caseSize > 1 ? window.WiskGlobals.currency + round(get(price, 'price.value'), 2) + ` / ${translations.unit}` : '',
          readonly: !!get(price, 'intakes.length') || caseSize < 2,
          input_value: caseSize < 2 ? 0 : pricePerCase
        }
      }
    },
    retailPrice: {
      colId: 'retailPrice',
      sortOrder: 1760,
      hide: true,
      remove: !(getGetters().venue && getGetters().venue.price_retail),
      headerName: translations.txtGenericRetailPrice,
      cellRenderer: 'CellNumber',
      minWidth: 70,
      width: 70,
      field: 'retail_price',
      enableRowGroup: true,
      cellClass: ['currency'],
      keyCreator: params => params.value.input_value,
      aggFunc: 'wiskSum',
      cellRendererParams: {
        translate,
        updateValue: save,
        type: 'retail_price',
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      valueGetter: params => ({
        id: params.data && params.data.item_id,
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data && params.data.retail_price
      })
    },
    caseSize: {
      colId: 'caseSize',
      sortOrder: 1800,
      headerName: translations.txtGenericCaseSize,
      cellRenderer: 'CellNumber',
      minWidth: 70,
      width: 70,
      enableRowGroup: true,
      keyCreator: params => params.value.input_value,
      cellRendererParams: {
        translate,
        updateValue: saveItemVariant,
        type: 'case_size',
        decimals: 0,
        suffix: '',
        confirmBeforeEdit: {
          confirm: () =>
            new Promise(resolve => {
              setGlobalAction({
                type: 'confirmDialog',
                action: {
                  type: 'confirm',
                  payload: {
                    message: translations.txtItemOperationsCaseSizeChangeMessage,
                    okText: translations.txtItemOperationsCaseSizeChangeOK,
                    callback: () => {
                      resolve()
                    }
                  }
                }
              })
            })
        }
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge || params.data?.is_measurement_locked,
        input_value: params.data && params.data.case_size
      })
    },
    yields: {
      colId: 'yields',
      sortOrder: 1900,
      headerName: translations.txtGenericYields,
      remove: getGetters().venue.venue_type !== 'food',
      ...agGridFilters.number,
      cellClass: ['text-end'],
      minWidth: 70,
      width: 90,
      valueFormatter: params => percentageFormat(params.value),
      cellRendererParams: { useValueFormatter: true },
      valueGetter: params => get(params, 'data.yields', 0)
    },
    fee: {
      colId: 'fee',
      sortOrder: 2000,
      headerName: translations.txtGenericFee,
      cellRenderer: 'CellNumber',
      minWidth: 70,
      remove: !(getGetters().venue && getGetters().venue.price_fee),
      width: 70,
      field: 'fee',
      cellClass: ['currency'],
      cellRendererParams: {
        translate,
        updateValue: saveItemVariant,
        type: 'fee',
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge,
        input_value: params.data && params.data.fee
      })
    },
    addedOn: {
      headerName: translations.txtGenericAddedOn,
      colId: 'addedOn',
      minWidth: 100,
      sortOrder: 2050,
      enableRowGroup: true,
      hide: true,
      field: 'added_at',
      ...agGridFilters.date,
      valueFormatter: params => formatDate(params.value),
      keyCreator: params => formatDate(params.value)
    },
    inventoryValue: {
      colId: 'inventoryValue',
      cellRenderer: 'CellNumber',
      sortOrder: 2100,
      headerName: translations.txtGenericInventoryValue,
      cellClass: ['text-end', 'pt-2', 'pe-3', 'currency'],
      minWidth: 100,
      width: 100,
      aggFunc: 'wiskSum',
      cellRendererParams: {
        translate,
        useValueFormatter: true,
        readonly: true,
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      wiskGetBottomAggregationValue: rowData => getInventoryValue(rowData),
      valueFormatter: params => currencyFormatHideZero(params.value),
      valueGetter: params => ({ id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')), input_value: getInventoryValue(params.data) })
    },
    inventoryValueRealTime: {
      colId: 'inventoryValueRealTime',
      cellRenderer: 'CellNumber',
      sortOrder: 2150,
      headerName: translations.columnRealTimeInventoryValue,
      cellClass: ['text-end', 'pt-2', 'pe-3', 'currency'],
      minWidth: 100,
      width: 100,
      aggFunc: 'wiskSum',
      remove: !!itemsByVariation,
      cellRendererParams: {
        translate,
        useValueFormatter: true,
        readonly: true,
        decimals: 2,
        prefix: window.WiskGlobals.currency
      },
      wiskGetBottomAggregationValue: rowData => getInventoryValueRealTime(rowData),
      valueFormatter: params => currencyFormatHideZero(params.value),
      valueGetter: params => ({ id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')), input_value: getInventoryValueRealTime(params.data) })
    },
    orderFormat: {
      colId: 'orderFormat',
      sortOrder: 2200,
      headerName: translations.txtGenericOrderFormat,
      cellEditor: 'CellPopMultiselect',
      cellRenderer: cellRenderers.CellPopMultiselect,
      minWidth: 80,
      width: 100,
      cellClass: ['text-center'],
      enableRowGroup: true,
      keyCreator: params => params.value.input_value.title,
      remove: !(getGetters().orderFormats && getGetters().orderFormats.length),
      valueSetter: params => {
        params.data.order_format = params.newValue
        return true
      },
      editable: params => params.data && !params.data.wiskRowHidden && !params.data.grouped_to && !params.data.archived_by_merge && params.data.case_size > 1,
      cellEditorParams: {
        autoOpen: true,
        translate,
        updateValue: saveItemVariant,
        trackBy: 'id',
        type: 'order_format',
        getItems: () => getGetters().orderFormats,
        multiselectWidth: 230,
        required: true
      },
      valueGetter: params => ({
        id: get(params, 'data.item_distributor_id', get(params, 'data.defaultVariant.id')),
        input_value: params.data && { id: params.data.order_format, title: params.data.order_format },
        readonly: params.data?.wiskRowHidden || !!params?.data?.grouped_to || !!params?.data?.archived_by_merge || get(params, 'data.case_size', 1) < 2
      })
    },
    suggestedOrderWisk: {
      colId: 'suggestedOrderWisk',
      sortOrder: 2300,
      headerName: translations.columnBasedOnWiskPar,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      cellClass: [],
      maxWidth: 200,
      pinned: 'right',
      cellRendererParams: { readonly: true, translate, secondaryInfoComponentType: 'extraText' },
      valueGetter: params => {
        if (!params.data) {
          return {
            input_value: 0,
            order_format: 'unit',
            case_size: 1
          }
        }

        let inputValue = 0,
          prefillValue = get(params, 'data.extra.algo.prefill_order.wisk_consumption'),
          caseSize = get(params, 'data.case_size'),
          extraText = '',
          suffix = translations.txtGenericUnits

        if (get(params, 'data.extra.cart.units', 0) < prefillValue) {
          inputValue = prefillValue

          if (get(params, 'data.order_format') === 'case') {
            inputValue = Math.ceil(prefillValue / get(params, 'data.case_size'))
            extraText = get(params, 'data.order_format') === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
            suffix = translations.txtGenericCases
          }
        }

        return {
          id: get(params, 'data.item_id'),
          item_distributor_id: get(params, 'data.item_distributor_id'),
          input_value: inputValue,
          order_format: get(params, 'data.order_format'),
          case_size: caseSize,
          extraText,
          suffix
        }
      }
    },
    suggestedOrderWiskSales: {
      colId: 'suggestedOrderWiskSales',
      sortOrder: 2301,
      headerName: translations.columnBasedOnWiskParSales,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      cellClass: [],
      maxWidth: 200,
      pinned: 'right',
      cellRendererParams: { readonly: true, translate, secondaryInfoComponentType: 'extraText' },
      valueGetter: params => {
        if (!params.data) {
          return {
            input_value: 0,
            order_format: 'unit',
            case_size: 1
          }
        }

        let inputValue = 0,
          prefillValue = get(params, 'data.extra.algo.prefill_order.wisk_sales'),
          caseSize = get(params, 'data.case_size'),
          extraText = '',
          suffix = translations.txtGenericUnits

        if (get(params, 'data.extra.cart.units', 0) < prefillValue) {
          inputValue = prefillValue

          if (get(params, 'data.order_format') === 'case') {
            inputValue = Math.ceil(prefillValue / get(params, 'data.case_size'))
            extraText = get(params, 'data.order_format') === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
            suffix = translations.txtGenericCases
          }
        }

        return {
          id: get(params, 'data.item_id'),
          item_distributor_id: get(params, 'data.item_distributor_id'),
          input_value: inputValue,
          order_format: get(params, 'data.order_format'),
          case_size: caseSize,
          extraText,
          suffix
        }
      }
    },
    suggestedOrder: {
      colId: 'suggestedOrder',
      sortOrder: 2302,
      headerName: translations.columnBasedOnYourPar,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      cellClass: [],
      maxWidth: 200,
      pinned: 'right',
      cellRendererParams: { readonly: true, translate, secondaryInfoComponentType: 'extraText' },
      valueGetter: params => {
        if (!params.data) {
          return {
            input_value: 0,
            order_format: 'unit',
            case_size: 1
          }
        }
        let inputValue = 0,
          prefillValue = get(params, 'data.extra.algo.prefill_order.venue'),
          caseSize = get(params, 'data.case_size'),
          extraText = '',
          suffix = translations.txtGenericUnits

        if (get(params, 'data.extra.cart.units', 0) < prefillValue) {
          inputValue = prefillValue

          if (get(params, 'data.order_format') === 'case') {
            inputValue = Math.ceil(prefillValue / get(params, 'data.case_size'))
            extraText = get(params, 'data.order_format') === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
            suffix = translations.txtGenericCases
          }
        }

        return {
          id: get(params, 'data.item_id'),
          item_distributor_id: get(params, 'data.item_distributor_id'),
          input_value: inputValue,
          order_format: get(params, 'data.order_format'),
          case_size: caseSize,
          extraText,
          suffix
        }
      }
    },
    suggestedOrderRealTimeStock: {
      colId: 'suggestedOrderRealTimeStock',
      sortOrder: 2304,
      headerName: translations.columnBasedOnRealTimeStock,
      cellRenderer: 'CellUnits',
      minWidth: 100,
      cellClass: [],
      maxWidth: 200,
      pinned: 'right',
      cellRendererParams: { readonly: true, translate, secondaryInfoComponentType: 'extraText' },
      valueGetter: params => {
        if (!params.data) {
          return {
            input_value: 0,
            order_format: 'unit',
            case_size: 1
          }
        }

        let inputValue = 0,
          prefillValue = get(params, 'data.extra.algo.prefill_order.venue_estimated_stock'),
          caseSize = get(params, 'data.case_size'),
          extraText = '',
          suffix = translations.txtGenericUnits

        if (get(params, 'data.extra.cart.units', 0) < prefillValue) {
          inputValue = prefillValue

          if (get(params, 'data.order_format') === 'case') {
            inputValue = Math.ceil(prefillValue / caseSize)
            extraText = get(params, 'data.order_format') === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
            suffix = translations.txtGenericCases
          }
        }

        return {
          id: get(params, 'data.item_id'),
          item_distributor_id: get(params, 'data.item_distributor_id'),
          input_value: inputValue,
          order_format: get(params, 'data.order_format'),
          case_size: caseSize,
          extraText,
          suffix
        }
      }
    },
    quantityToOrder: {
      colId: 'quantityToOrder',
      sortOrder: 2400,
      headerName: translations.txtGenericQuantityToOrder,
      cellRenderer: 'CellOrderUnitsEditor',
      cellRendererParams: {
        translate
      },
      cellClass: ['text-center', 'units-cell', 'p-0'],
      minWidth: 140,
      width: 140,
      maxWidth: 150,
      pinned: 'right'
      //valueGetter and params must be pushed from outside!
    },
    distributorGroup: {
      colId: 'distributorGroup',
      sortOrder: 2500,
      hide: true,
      headerName: translations.txtGenericDistributor,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      valueGetter: params => {
        let distributor = getDistributorsById()[get(params, 'data.distributor_id')]
        if (distributor) {
          return JSON.stringify({
            id: distributor.id,
            title: distributor.title,
            sort: distributor.title,
            notes: distributor.notes
          })
        }
        return JSON.stringify({
          id: 0,
          title: `(${translations.txtGenericNoDistributor})`,
          sort: '~'
        })
      }
    },
    total: {
      colId: 'total',
      sortOrder: 2700,
      headerName: translations.txtGenericTotal,
      minWidth: 100,
      cellClass: ['currency'],
      maxWidth: 200,
      pinned: 'right',
      cellRendererParams: { useValueFormatter: true, decimals: 2 },
      valueFormatter: params => currencyFormatHideZero(params.value),
      aggFunc: 'sum'
    },
    more: {
      colId: 'more',
      sortOrder: 2800,
      headerName: translations.columnDetails,
      minWidth: 100,
      width: 100,
      headerClass: ['text-center'],
      cellClass: ['cell-more-details'],
      cellRenderer: 'CellMoreDetails',
      suppressColumnsToolPanel: true,
      suppressSortingToolPanel: true,
      suppressFiltersToolPanel: true,
      cellRendererParams: {
        excludeFromExport: true,
        translate,
        onClick: id => {
          console.log('default more details onClick called, this does nothing; you need to override it from calling component', id)
        }
      },
      valueGetter: params => ({
        id: params.data && (params.data.combined_id || params.data.item_distributor_id || params.data.item_id || params.data.id),
        group: !!params.node.group,
        data: params.data
      })
    }
  }

  if (!preventCustomFieldsAsColumns) {
    let columns = Object.values(colDef),
      customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate, save, agGridFilters, columns })

    customFieldsColumns.forEach(column => {
      colDef[column.colId] = column
    })
  }

  return colDef
}
export default getItemsGridColdefs
