<template>
  <wiskModal :visible="!!editAction" :size="selectedEditorTab ? 'lg' : 'md'" @ok="save" :okText="translations.txtGenericSave" v-if="editAction"
    @hide="setGlobalAction({ type: 'servingSizeEdit', action: null })" :okDisabled="!valid" :warning="!!(servingSize && servingSize.archived)"
    :hideFooter="!!(servingSize && servingSize.id)" hideHeaderExtra>

    <template v-slot:modal-title>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="modal-title"> {{ modalTitle }} </h5>
        <b-form-radio-group v-if="editAction.id" size="sm" buttons v-model="selectedEditorTab" button-variant="outline-primary">
          <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
          <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
        </b-form-radio-group>
        <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="servingSize && servingSize.id" :dropdownClass="selectedEditorTab === 1 && 'me-4'" />
      </div>
    </template>

    <wiskInputGroup class="" style="height: 500px;" v-if="selectedEditorTab === 0 && servingSize" @errorCountChanged="setValidState" @operationsChange="operations = $event">

      <wiskInput infoTooltipKey="76cca391-af80-42f6-a2dd-e856009260b0" :label="translations.txtGenericTitle" :modelValue="servingSize.title" required operation="title" autofocus
        :triggerInputOnLoad="!editAction.id" @operation="singleFieldSave" />

      <measurement v-model="servingSize.measurement" @operation="singleFieldSave" :triggerInputOnLoad="!editAction.id" :inline="!servingSize.id" horizontal
        :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]" />

    </wiskInputGroup>

    <timeline v-if="selectedEditorTab === 1" :filters="[{ type: 'serving_size', id: editAction.id }]" parentGridName="ServingSizeEditTimeline" gridAutoHeight />

    <confirm ref="confirmDialog" />

  </wiskModal>
</template>

<script>
import merge from 'lodash.merge'
import { mapGetters, mapState, mapActions } from 'vuex'
import measurement from '@/components/bottles/Measurement'
import timeline from '@/components/timeline/Timeline'

const newServingSize = {
  id: 0,
  title: '',
  type: 'predefined',
  aliases: [],
  measurement: {
    type: 'volume',
    quantity: 1,
    unit_of_measurement: 'oz'
  }
}

export default {
  name: 'ServingSizeEdit',
  components: { measurement, timeline },
  props: {
    editAction: Object
  },
  data() {
    return {
      valid: false,
      unitsSelectOptions: {
        trackBy: 'id',
        preselectFirst: true
      },
      aliasesSelectOptions: {},
      operations: [],
      servingSize: null,
      selectedEditorTab: 0
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations', 'servingSizes']),
    pageActions() {
      return [
        { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.servingSize.archived, action: this.toggleArchived },
        { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.servingSize.archived, action: this.toggleArchived }
      ]
    },
    modalTitle() {
      if (this.servingSize) {
        let archived = this.servingSize.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.servingSize.title ? `${this.servingSize.title} (${this.translations.txtPOSItemsServingSize})` + archived : this.translations.txtPOSItemsServingSizeNew
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['updateServingSize', 'setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    toggleArchived() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.operations = [
              {
                type: 'archive',
                value: !this.servingSize.archived
              }
            ]
            this.save()
          },
          message: this.servingSize.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.servingSize.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    singleFieldSave(operation) {
      if (this.servingSize && this.servingSize.id) {
        this.save(operation)
      }
    },
    save(operation) {
      this.gridLoading = true
      this.updateServingSize({
        id: this.servingSize.id,
        operation: operation || this.operations
      })
        .then(updated => {
          this.gridLoading = false

          this.servingSize = merge({}, newServingSize, updated)

          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(merge({}, updated))
          }
          if (!this.servingSize.id) {
            this.setGlobalAction({ type: 'servingSizeEdit', action: null })
          }
        })
        .catch(() => {
          this.gridLoading = false

          if (!this.servingSize.id) {
            this.setGlobalAction({ type: 'servingSizeEdit', action: null })
          }
        })
    }
  },
  mounted() {
    this.aliasesSelectOptions = {
      tagPlaceholder: this.translations.txtServingSizesAddAlias,
      placeholder: this.translations.txtServingSizesInputAlias
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction) {
          this.servingSize = merge({}, newServingSize, this.editAction)

          if (this.editAction.id) {
            this.servingSize = this.servingSizes.find(s => s.id === this.editAction.id)
          }
        }
      }
    }
  }
}
</script>
