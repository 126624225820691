<template>
  <div v-if="loadingScreenVisible || forceVisible" class="loading-screen">
    <div class="inner" style="width: 380px; margin-left: auto; margin-right: auto;">
      <svg xmlns="http://www.w3.org/2000/svg" width="280" height="200" preserveAspectRatio="xMidYMid">
        <g style="transform-origin:140px 125px;transform:scale(1)">
          <g transform="translate(140,125)">
            <g transform="translate(0,0)">
              <g class="path"
                style="transform: scale(0.91); transform-origin: -57.8164px -6.33594px 0px; animation: 1s linear -0.495s infinite normal forwards running wisk-loading;">
                <path d="M79.04-54.80L79.04-54.80Q79.36-55.84 80.16-56.36L80.16-56.36L80.16-56.36Q80.96-56.88 81.92-56.88L81.92-56.88L81.92-56.88Q83.20-56.88 84.12-56.12L84.12-56.12L84.12-56.12Q85.04-55.36 85.04-54.08L85.04-54.08L85.04-54.08Q85.04-53.52 84.80-52.80L84.80-52.80L66.72-1.84L66.72-1.84Q66.32-0.72 65.36-0.12L65.36-0.12L65.36-0.12Q64.40 0.48 63.20 0.48L63.20 0.48L63.20 0.48Q62.08 0.48 61.12-0.12L61.12-0.12L61.12-0.12Q60.16-0.72 59.76-1.84L59.76-1.84L44.08-46.16L28.24-1.84L28.24-1.84Q27.84-0.72 26.88-0.12L26.88-0.12L26.88-0.12Q25.92 0.48 24.80 0.48L24.80 0.48L24.80 0.48Q23.68 0.48 22.72-0.12L22.72-0.12L22.72-0.12Q21.76-0.72 21.36-1.84L21.36-1.84L3.20-52.80L3.20-52.80Q2.96-53.44 2.96-54L2.96-54L2.96-54Q2.96-55.28 3.96-56.08L3.96-56.08L3.96-56.08Q4.96-56.88 6.24-56.88L6.24-56.88L6.24-56.88Q7.20-56.88 8.04-56.36L8.04-56.36L8.04-56.36Q8.88-55.84 9.20-54.80L9.20-54.80L24.96-9.36L40.96-54.32L40.96-54.32Q41.36-55.52 42.24-56.12L42.24-56.12L42.24-56.12Q43.12-56.72 44.16-56.72L44.16-56.72L44.16-56.72Q45.20-56.72 46.08-56.08L46.08-56.08L46.08-56.08Q46.96-55.44 47.36-54.24L47.36-54.24L63.12-9.20L79.04-54.80"
                  fill="#007bff" stroke="none" stroke-width="none" transform="translate(-101.81664276123047,21.86266502380371)" style="fill: rgb(0, 123, 255);"></path>
              </g>
              <g class="path" style="transform: scale(0.91); transform-origin: -3.49609px -6.33789px 0px; animation: 1s linear -0.33s infinite normal forwards running wisk-loading;">
                <path d="M98.32 0.40L98.32 0.40Q96.80 0.40 95.92-0.52L95.92-0.52L95.92-0.52Q95.04-1.44 95.04-2.96L95.04-2.96L95.04-53.44L95.04-53.44Q95.04-54.96 95.92-55.88L95.92-55.88L95.92-55.88Q96.80-56.80 98.32-56.80L98.32-56.80L98.32-56.80Q99.84-56.80 100.72-55.88L100.72-55.88L100.72-55.88Q101.60-54.96 101.60-53.44L101.60-53.44L101.60-2.96L101.60-2.96Q101.60-1.44 100.72-0.52L100.72-0.52L100.72-0.52Q99.84 0.40 98.32 0.40L98.32 0.40"
                  fill="#007bff" stroke="none" stroke-width="none" transform="translate(-101.81664276123047,21.86266502380371)" style="fill: rgb(0, 123, 255);"></path>
              </g>
              <g class="path" style="transform: scale(0.91); transform-origin: 31.3418px -6.33594px 0px; animation: 1s linear -0.165s infinite normal forwards running wisk-loading;">
                <path d="M133.52 0.64L133.52 0.64Q127.52 0.64 122.04-1.16L122.04-1.16L122.04-1.16Q116.56-2.96 113.28-6L113.28-6L113.28-6Q112.08-7.12 112.08-8.80L112.08-8.80L112.08-8.80Q112.08-9.92 112.72-10.76L112.72-10.76L112.72-10.76Q113.36-11.60 114.24-11.60L114.24-11.60L114.24-11.60Q115.28-11.60 116.48-10.72L116.48-10.72L116.48-10.72Q123.92-4.96 133.44-4.96L133.44-4.96L133.44-4.96Q140.32-4.96 144-7.60L144-7.60L144-7.60Q147.68-10.24 147.68-15.12L147.68-15.12L147.68-15.12Q147.68-17.92 145.92-19.64L145.92-19.64L145.92-19.64Q144.16-21.36 141.28-22.40L141.28-22.40L141.28-22.40Q138.40-23.44 133.52-24.56L133.52-24.56L133.52-24.56Q126.96-26.08 122.76-27.76L122.76-27.76L122.76-27.76Q118.56-29.44 115.96-32.56L115.96-32.56L115.96-32.56Q113.36-35.68 113.36-40.72L113.36-40.72L113.36-40.72Q113.36-45.52 115.96-49.24L115.96-49.24L115.96-49.24Q118.56-52.96 123.24-55L123.24-55L123.24-55Q127.92-57.04 133.92-57.04L133.92-57.04L133.92-57.04Q139.52-57.04 144.36-55.32L144.36-55.32L144.36-55.32Q149.20-53.60 152.48-50.40L152.48-50.40L152.48-50.40Q153.76-49.12 153.76-47.60L153.76-47.60L153.76-47.60Q153.76-46.48 153.12-45.64L153.12-45.64L153.12-45.64Q152.48-44.80 151.60-44.80L151.60-44.80L151.60-44.80Q150.72-44.80 149.28-45.68L149.28-45.68L149.28-45.68Q145.36-48.88 142-50.16L142-50.16L142-50.16Q138.64-51.44 133.92-51.44L133.92-51.44L133.92-51.44Q127.28-51.44 123.60-48.68L123.60-48.68L123.60-48.68Q119.92-45.92 119.92-40.96L119.92-40.96L119.92-40.96Q119.92-36.72 123.16-34.60L123.16-34.60L123.16-34.60Q126.40-32.48 133.12-30.88L133.12-30.88L133.12-30.88Q140.40-29.12 144.56-27.64L144.56-27.64L144.56-27.64Q148.72-26.16 151.48-23.20L151.48-23.20L151.48-23.20Q154.24-20.24 154.24-15.28L154.24-15.28L154.24-15.28Q154.24-10.56 151.64-6.96L151.64-6.96L151.64-6.96Q149.04-3.36 144.32-1.36L144.32-1.36L144.32-1.36Q139.60 0.64 133.52 0.64L133.52 0.64"
                  fill="#007bff" stroke="none" stroke-width="none" transform="translate(-101.81664276123047,21.86266502380371)" style="fill: rgb(0, 123, 255);"></path>
              </g>
              <g class="path" style="transform: scale(0.91); transform-origin: 83.9023px -6.33789px 0px; animation: 1s linear 0s infinite normal forwards running wisk-loading;">
                <path d="M205.44-4.96L205.44-4.96Q206.56-3.92 206.56-2.64L206.56-2.64L206.56-2.64Q206.56-1.52 205.64-0.60L205.64-0.60L205.64-0.60Q204.72 0.32 203.52 0.32L203.52 0.32L203.52 0.32Q202.48 0.32 201.36-0.64L201.36-0.64L171.44-26.80L171.44-2.96L171.44-2.96Q171.44-1.44 170.56-0.52L170.56-0.52L170.56-0.52Q169.68 0.40 168.16 0.40L168.16 0.40L168.16 0.40Q166.64 0.40 165.76-0.52L165.76-0.52L165.76-0.52Q164.88-1.44 164.88-2.96L164.88-2.96L164.88-53.44L164.88-53.44Q164.88-54.96 165.76-55.88L165.76-55.88L165.76-55.88Q166.64-56.80 168.16-56.80L168.16-56.80L168.16-56.80Q169.68-56.80 170.56-55.88L170.56-55.88L170.56-55.88Q171.44-54.96 171.44-53.44L171.44-53.44L171.44-30.48L200-55.84L200-55.84Q200.88-56.72 202.08-56.72L202.08-56.72L202.08-56.72Q203.28-56.72 204.16-55.80L204.16-55.80L204.16-55.80Q205.04-54.88 205.04-53.76L205.04-53.76L205.04-53.76Q205.04-52.56 204-51.52L204-51.52L178.16-28.80L205.44-4.96"
                  fill="#007bff" stroke="none" stroke-width="none" transform="translate(-101.81664276123047,21.86266502380371)" style="fill: rgb(0, 123, 255);"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div v-if="initialDBLoadingProgress && initialDBLoadingProgress.total" class="mb-5">
        <h3>
          {{ `${progress.toFixed(0)}%` }}
        </h3>

        <div class="progress my-3" style="height: 4px; border-radius: 0; background-color: var(--blue-300);">
          <div class="progress-bar" role="progressbar" :style="{ 'width': progress.toFixed(0) + '%' }" style="border-radius: 0; background-color: var(--blue-400);"
            :aria-valuenow="initialDBLoadingProgress.done || 0" aria-valuemin="0" :aria-valuemax="initialDBLoadingProgress.total">
          </div>
        </div>
        <h4 class="position-relative text-dark mt-4" :style="initialDBLoadingProgress.style">
          {{ initialDBLoadingProgress.message }}
        </h4>
      </div>
      <div v-if="initialDBLoadingProgress && !initialDBLoadingProgress.total" class="mb-5">
        <h3 class="position-relative text-dark">
          {{ initialDBLoadingProgress.message }}
        </h3>
      </div>
      <!-- <div class="loading-issue-help" v-if="pastWaitTime">
        <h1>Experiencing loading issues?</h1>
        <h3 class="mb-3">Try clearing your site data on Google Chrome</h3>
        <ul>
          <h5 v-for="step in chromeSteps" :key="step.title" class="mb-3">
            <li>
              {{ step.title }}
              <img :src="step.image" class="w-100 mt-1" v-if="step.image" />
            </li>
          </h5>
        </ul>
      </div> -->
    </div>
    <div class="font-monospace" :class="[!pastWaitTime && 'loading-footer', pastWaitTime && 'my-5 p-5 text-right']">
      {{ wiskWebAppVersion }} &nbsp; | &nbsp; {{ wiskWebAppCOMMITHASH }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoadingScreen',
  props: { forceVisible: Boolean },
  data() {
    return {
      wiskWebAppVersion: window.wiskWebAppVersion,
      wiskWebAppCOMMITHASH: window.wiskWebAppCOMMITHASH,
      pastWaitTime: false,
      timer: null,
      chromeSteps: [
        {
          title: 'On web.wisk.ai, click the lock icon next to the address bar.',
          image: '/img/loading-page/ClearData1a.png'
        },
        {
          title: 'Click "Site settings"',
          image: '/img/loading-page/ClearData2a.png'
        },
        {
          title: 'Click "Clear data"',
          image: '/img/loading-page/ClearData3a.png'
        },
        {
          title: 'Click "Clear" on the confirmation window. This will sign you out of the WISK Web Portal.',
          image: '/img/loading-page/ClearData4a.png'
        },
        {
          title: 'Log back in and try to load the page again'
        }
      ]
    }
  },
  computed: {
    ...mapState(['loadingScreenVisible', 'initialDBLoadingProgress']),
    progress() {
      if (this.initialDBLoadingProgress && this.initialDBLoadingProgress.total) {
        let done = this.initialDBLoadingProgress.done || 0,
          progress = Math.floor(done / this.initialDBLoadingProgress.total * 100)
        return progress > 100 ? 100 : progress
      }

      return 0
    }
  },
  // mounted() {
  //   if (navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Edg')) {
  //     this.timer = setTimeout(() => {
  //       this.pastWaitTime = true
  //     }, window.location.host === 'web.wisk.ai' ? 3 * 60 * 1000 : 10000)
  //   }
  // },
  // beforeUnmount() {
  //   if (this.timer) clearTimeout(this.timer)
  // }
}
</script>

<style lang="scss">
.loading-screen {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  background-color: #e4e5e6;
  overflow-y: auto;
}

.loading-issue-help {
  text-align: left;

  ul {
    list-style-type: decimal;
    list-style-position: inside;
  }
}

.loading-footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}

.text-right {
  text-align: right;
}

@keyframes wisk-loading {
  0% {
    animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
    transform: scale(0.9099999999999999);
  }

  51% {
    animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
    transform: scale(1.02994);
  }

  100% {
    transform: scale(0.9099999999999999);
  }
}
</style>
