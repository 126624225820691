export default {
  name: 'wisk-items-alt',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M30.4,2.382,27.644.555a3.015,3.015,0,0,0-1.11-.5L26.453.04,26.362.03A3.3,3.3,0,0,0,25.878,0a3.087,3.087,0,0,0-2.624,1.423,3.212,3.212,0,0,0-.485,1.705c-.06.1-.131.2-.191.3-.626.979-1.222,1.9-1.867,2.745a1.556,1.556,0,0,1-1.353.666c-.111,0-.232-.009-.353-.009a3.386,3.386,0,0,0-.565-.041,5.944,5.944,0,0,0-3.058.9,9.615,9.615,0,0,0-3.189,3.139l-3.21,4.8-.656.989c-.171.262-.323.484-.464.7-.131.192-.252.374-.383.566-.455.7-.909,1.362-1.383,2.068-.565.838-1.171,1.736-1.877,2.8a2.494,2.494,0,0,0-.374.605,5.766,5.766,0,0,0,1.968,7.388l4.855,3.27a5.875,5.875,0,0,0,3.25.989,5.721,5.721,0,0,0,3.935-1.554,5.046,5.046,0,0,0,.838-.969l2.533-3.754.828-1.171.646-.959c.979-1.463,2.139-3.209,3.825-5.763a10.281,10.281,0,0,0,1.07-1.786,6.737,6.737,0,0,0,.2-6.378,1.432,1.432,0,0,1-.09-1.524c.5-.919,1.069-1.776,1.685-2.685.121-.2.252-.393.383-.585A3.247,3.247,0,0,0,31.2,6.762l.041-.051.03-.05A3.121,3.121,0,0,0,30.4,2.382Zm-.837,3.2a1.1,1.1,0,0,1-.879.474c-.03,0-.07-.01-.11-.01-.021.031-.061.071-.091.111-.858,1.332-1.777,2.614-2.534,3.987a3.429,3.429,0,0,0,.061,3.461,4.8,4.8,0,0,1-.242,4.582,8.684,8.684,0,0,1-.909,1.5c-2.472,3.744-5,7.448-7.508,11.162l-.3.454a3.443,3.443,0,0,1-.545.646,3.744,3.744,0,0,1-2.583,1.03,3.869,3.869,0,0,1-2.12-.646l-4.864-3.27a3.766,3.766,0,0,1-1.242-4.885.394.394,0,0,1,.111-.172c2.675-4.026,5.4-8.023,8.074-12.06A7.514,7.514,0,0,1,16.4,9.436a3.961,3.961,0,0,1,2.039-.625,2.222,2.222,0,0,1,.363.03c.182.01.374.02.555.02A3.552,3.552,0,0,0,22.315,7.4c.919-1.2,1.736-2.544,2.584-3.836a1.07,1.07,0,0,1,.071-1.069,1.037,1.037,0,0,1,.908-.475,1.25,1.25,0,0,1,.2.011.97.97,0,0,1,.393.171l2.8,1.857A1.105,1.105,0,0,1,29.562,5.581Z'
    }
  ]
}
