<template>
  <wiskInput :label="translations.txtGenericScale" v-model="grams" inputType="number" :helperText="(editing && formattedValue) || ''" :validations="[inWeightRangeValidation]"
    @blur="onBlur" :dummy="!editing" ref="inputComponent" allowEmpty :disabled="disabled" forceSuffixInFocus suffix="g" class="iventory-entry-input-scale" @valid="setValidState">

    <div v-if="!editing && fullWeight" @click="startEditing" class="fill-width-height" style="">

      <wiskInput :label="translations.txtGenericScale" :modelValue="formattedValue" showPlainText class="text-end" :disabled="disabled">
        <icon scale="1" name="wisk-scale" class="text-primary" style="position: absolute; top: -25px; left: 25px;" />
      </wiskInput>
    </div>

    <b-button v-if="!fullWeight" size="sm" variant="outline-secondary" @click="setFullWeight" class="px-1" :disabled="disabled">
      <span> {{ translations.txtInventoriesFullWeightSet }} </span>
    </b-button>

    <confirm ref="confirmDialog" promptForTextType="number" autofocus size="sm" promptForTextSuffix=" g" />

    <small v-if="editing" class="text-muted">
      <span class="float-start" v-tooltip="translations.txtInventoriesWeightEmpty">
        <icon name="wisk-weight-empty" :scale="0.9"/>
        <span class="">{{ emptyWeight }} g</span>
      </span>
      <span class="float-end" v-tooltip="translations.txtInventoriesWeightFull">
        <icon name="wisk-weight-full" :scale="1"/>
        <span class="">{{ fullWeight }} g</span>
      </span>
    </small>


  </wiskInput>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import { round, getBaseValueFromMeasurement, convertUM } from '@/modules/utils'

let computeFullWeight = arr => (arr?.length && arr.reduce((a, b) => a + b, 0) / arr.length) || 0

export default {
  name: 'InventoryEntryInputScale',
  emits: ['update:modelValue', 'itemFieldChange', 'valid', 'blur', 'change'],
  components: {},
  props: { modelValue: Object, item: Object, disabled: Boolean, emitFast: Boolean },
  data() {
    return {
      editing: false,
      grams: 0,
      localMeasurement: { ml_left: 0 },
      localMlLeft: 0,
      valid: false
    }
  },
  computed: {
    ...mapState(['translations', 'categoriesById']),
    ...mapGetters([]),
    formattedValue() {
      return `${round(this.getFormattedValueFromMeasurement(this.ml, this.item.measurement.unit_of_measurement), 2)} ${this.item.measurement.unit_of_measurement}`
    },
    category() {
      return this.categoriesById[this.item?.category_id]
    },
    densityComputed() {
      return this.item?.density_calculated || this.category?.density || 1
    },
    volumeComputed() {
      if (this.item?.measurement) {
        return getBaseValueFromMeasurement(this.item.measurement)
      }
      return 1
    },
    fullWeight() {
      return computeFullWeight(this.item.weights)
    },
    emptyWeight() {
      return Math.max(this.fullWeight - this.volumeComputed * this.densityComputed , 0)
    },
    inWeightRangeValidation() {
      return {
        validator: value => value >= this.emptyWeight && value <= this.fullWeight,
        message: this.translations.translate('tplInventoriesWeightInputValidation', {
          '{a}': this.emptyWeight,
          '{b}': this.fullWeight
        })
      }
    },
    ml() {
      if (this.localMlLeft) {
        return this.localMlLeft
      }

      let weight = this.grams - this.emptyWeight,
        ml = Math.max(round(weight / this.densityComputed, 2), 0)

      if (ml > this.volumeComputed) {
        ml = this.volumeComputed
      }

      return ml
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    startEditing() {
      let grams = this.grams
      this.grams = 0
      this.editing = true

      setTimeout(() => {
        this.$refs.inputComponent && this.$refs.inputComponent.focus()
        this.grams = grams
      }, 0)
    },
    getFormattedValueFromMeasurement(baseValue, unitOfMeasurement) {
      if (baseValue) {
        return convertUM({ from: { unit_of_measurement: 'ml', quantity: baseValue }, to: unitOfMeasurement })
      }
      return 0
    },
    onBlur() {
      if (this.valid) {
        this.editing = false
      }
    },
    setValidState(valid) {
      this.valid = valid
    },
    setFullWeight() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          callback: fullWeight => {
            if (fullWeight >= this.localMlLeft) {
              this.$emit('itemFieldChange', {
                itemId: this.item.item_id,
                variantId: this.item.item_distributor_id,
                operation: { type: 'weight_add', value: fullWeight }
              })
            }
          },
          message: '',
          title: this.translations.txtInventoriesFullWeightSet + ' (g)'
        })
      }
    }
  },
  watch: {
    ml(ml) {
      this.localMeasurement = merge({}, this.modelValue, { ml_left: ml })

      if (this.emitFast && this.editing) {
        this.$emit('update:modelValue', merge({}, this.localMeasurement))
        this.$emit('valid', !!this.localMeasurement.ml_left)
      }
    },
    grams(grams) {
      if (grams) {
        this.localMlLeft = 0
      }
    },
    editing(editing) {
      this.localMeasurement = merge({}, this.modelValue, { ml_left: this.ml })

      if (!editing && !isEqual(this.localMeasurement, this.modelValue)) {
        if (!this.editing) {
          this.$emit('valid', !!this.localMeasurement.ml_left)
          this.$emit('update:modelValue', merge({}, this.localMeasurement))
          this.$emit('change', merge({}, this.localMeasurement))
          this.$emit('blur')
          this.grams = round(this.ml * this.densityComputed , 2) + this.emptyWeight
        }
      }
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler(modelValue) {
        if (modelValue && !isEqual(modelValue, this.localMeasurement)) {
          this.localMeasurement = merge({}, modelValue)
          this.localMlLeft = this.grams ? 0 : modelValue.ml_left

          if (!this.grams && modelValue.ml_left) {
            this.grams = round(this.localMlLeft * this.densityComputed, 2) + this.emptyWeight
          }
        }
        this.$emit('valid', !!this.localMeasurement.ml_left)
      }
    }
  }
}
</script>

<style lang="scss">
.iventory-entry-input-scale {
  .plain-text-span {
    border: 1px solid #ced4da !important;
    text-align: right !important;
  }

  label {
    top: 1px !important;
    line-height: var(--font-size);
  }
}
</style>
