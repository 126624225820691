<template>
  <wiskInputGroup @errorCountChanged="setValidState" class="px-2 fill-width-height invite-to-venues-selected-rows-action-container text-center"
    fieldsetClass="bg-white" :legend="translations.txtUsersInviteUsers" :key="key">

    <wiskSelect infoTooltipKey="83129de4-e8df-495b-a027-3925ccaa89f5" :label="translations.txtGenericVenue" @change="selectedVenue = $event" :items="availableVenues"
      class="mb-3" required />

    <wiskSelect infoTooltipKey="c9e76e92-f80c-4a3c-8ee5-d3c284e760cf" :label="translations.txtGenericRole" v-model="selectedRoleId" :items="availableRoles"
      class="mb-3" required />

    <b-button size="sm" variant="link" @click="submit" class="mx-auto text-primary" v-tooltip="translations.txtGenericSubmit" :disabled="!valid">
      <icon class="ms-1 d-inline" style="margin-top: -1px;" scale="1.4" name="wisk-check" />
    </b-button>

  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'InviteUsersSelectedRowsAction',
  emits: ['submit'],
  components: {},
  props: {
    rows: { type: Array, required: true },
    clearAfterEmitOperation: Boolean,
    checkDisabled: Function,
  },
  data() {
    return {
      valid: true,
      selectedRoleId: null,
      selectedVenue: null,
      key: 1
    }
  },
  computed: {
    ...mapState(['translations', 'venuesHighLevel']),
    ...mapGetters([]),
    availableVenues() {
      return this.venuesHighLevel.filter(venue => !this.checkDisabled(venue))
    },
    availableRoles() {
      return (this.selectedVenue && this.selectedVenue.roles) || []
    },
    invitations() {
      let invitations = []

      this.rows.forEach(row => {
        invitations.push({
          venue_id: this.selectedVenue.id,
          email: row.email,
          role_id: this.selectedRoleId
        })
      })

      return invitations
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    submit() {
      if (this.valid && this.invitations.length) {
        this.$emit('submit', this.invitations)

        if (this.clearAfterEmitOperation) {
          this.selectedRoleId = null
          this.selectedVenue = null
          this.key++
        }
      }
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss">

</style>
