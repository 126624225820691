export default {
  name: 'wisk-independent-inventory',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,3.807h0A3.629,3.629,0,0,0,31.371.178H19.455a.111.111,0,0,1-.074-.023h-3.6V.178H3.669A3.68,3.68,0,0,0,0,3.847v27.3a3.682,3.682,0,0,0,3.669,3.7H31.383A3.618,3.618,0,0,0,35,31.227h0a.174.174,0,0,1-.017-.08V3.876A.147.147,0,0,1,35,3.807ZM13.825,4.842h7.338v7.223H13.825ZM31.486,31.147a.2.2,0,0,1-.2.2H3.669a.188.188,0,0,1-.167-.2V3.876c0-.086.081-.2.167-.2h6.654v9.472a2.412,2.412,0,0,0,2.421,2.415h9.5a2.411,2.411,0,0,0,2.416-2.415V3.675h6.625a.216.216,0,0,1,.2.2Z'
    }
  ]
}
