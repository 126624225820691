<template>
  <div>
    <v-menu v-if="filterConfig?.filters?.length" :triggers="['hover']" :distance="5">
      <div class="fill-width-height">
        <slot></slot>
      </div>

      <template #popper>
        <div class="p-2 cursor-pointer" @click="openFiltersPanel">
          <h6> {{ translations.txtFilterInfo }}: </h6>
          <filterInfo v-for="filter in filterConfig?.filters" :key="filter.id" :filter="filter" :columns="columnsComputed" />
        </div>
      </template>
    </v-menu>

    <div v-else>
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import filterInfo from '@/components/grids/WiskGridFilterInfo'

export default {
  name: 'WiskGridFilterInfoTooltip',
  emits: ['openFiltersPanel'],
  components: { filterInfo },
  props: { filterConfig: Object, columns: Array },
  computed: {
    ...mapState(['translations']),
    columnsComputed() {
      if (this.columns?.length) {
        return this.columns.map(col => ({
          id: col.colId,
          colId: col.colId,
          dataType: col.filterType,
          title: col.headerName
        }))
      }
      return []
    }
  },
  methods: {
    openFiltersPanel() {
      this.$emit('openFiltersPanel')
    }
  }
}
</script>
