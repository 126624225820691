export default {
  name: 'wisk-print',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 10.720703 2.3105469 A 2.8251 2.8251 0 0 0 7.9003906 5.140625 L 7.9003906 7.9707031 L 5.140625 7.9707031 A 5.1384 5.1384 0 0 0 0 13.109375 L 0 25.349609 A 5.1385 5.1385 0 0 0 5.140625 30.490234 L 7.9804688 30.490234 A 2.8087 2.8087 0 0 0 10.720703 32.689453 L 24.279297 32.689453 A 2.82 2.82 0 0 0 27.029297 30.490234 L 29.859375 30.490234 A 5.1385 5.1385 0 0 0 35 25.349609 L 35 13.109375 A 5.1384 5.1384 0 0 0 29.859375 7.9707031 L 27.109375 7.9707031 L 27.109375 5.140625 A 2.8337 2.8337 0 0 0 24.279297 2.3105469 L 10.720703 2.3105469 z M 11.400391 5.8105469 L 23.609375 5.8105469 L 23.609375 7.9707031 L 11.400391 7.9707031 L 11.400391 5.8105469 z M 5.140625 11.470703 L 29.859375 11.470703 A 1.6474 1.6474 0 0 1 31.5 13.109375 L 31.5 25.349609 A 1.6475 1.6475 0 0 1 29.859375 26.990234 L 27.109375 26.990234 L 27.109375 18.449219 L 7.9003906 18.449219 L 7.9003906 26.990234 L 5.140625 26.990234 A 1.6475 1.6475 0 0 1 3.5 25.349609 L 3.5 13.109375 A 1.6474 1.6474 0 0 1 5.140625 11.470703 z M 11.400391 21.949219 L 23.609375 21.949219 L 23.609375 29.189453 L 11.400391 29.189453 L 11.400391 21.949219 z M 13.386719 24.800781 A 0.7494 0.7494 0 0 0 13.435547 26.298828 L 21.564453 26.298828 A 0.7422 0.7422 0 0 0 22.310547 25.554688 A 0.7493 0.7493 0 0 0 21.564453 24.800781 L 13.435547 24.800781 A 0.7494 0.7494 0 0 0 13.386719 24.800781 z '
    }
  ]
}
