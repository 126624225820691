import { formatDate, getRandom } from '@/modules/utils'

export default (state, type, value, setGlobalAction) => {
  let map = {
    batch: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    category: payload => ({
      key: getRandom(),
      text: state.categoriesById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'categoryEdit', action: { id: payload.id } })
      }
    }),
    custom_field: payload => ({
      key: getRandom(),
      text: state.customFieldsById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'customFieldEdit', action: { id: payload.id } })
      }
    }),
    date: payload => ({ key: getRandom(), text: formatDate(payload.value) }),
    distributor: payload => ({
      key: getRandom(),
      text: state.distributorsById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'distributorEdit', action: { id: payload.id } })
      }
    }),
    scanned_invoice: payload => ({
      key: getRandom(),
      text: payload.id,
      action: () => {
        setGlobalAction({ type: 'draftInvoiceEdit', action: { id: payload.id } })
      }
    }),
    draft_invoice: payload => ({
      key: getRandom(),
      text: payload.id,
      action: () => {
        setGlobalAction({ type: 'draftInvoiceEdit', action: { id: payload.id } })
      }
    }),
    email: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    family: payload => ({
      key: getRandom(),
      text: state.familiesById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'familyEdit', action: { id: payload.id } })
      }
    }),
    independent_inventory: payload => ({
      key: getRandom(),
      text: payload.value || payload.id,
      action: () => {
        setGlobalAction({ type: 'independentInventoryEdit', action: { id: payload.id } })
      }
    }),
    independent_inventory_title: payload => ({
      key: getRandom(),
      text: payload.value || payload.id,
      action: () => {
        setGlobalAction({ type: 'independentInventoryEdit', action: { id: payload.id } })
      }
    }),
    interval: payload => ({
      key: getRandom(),
      text: payload.value && payload.value.start ? `${formatDate(payload.value.start)} - ${formatDate(payload.value.end)}` : payload.value || payload.id
    }),
    inventory: payload => ({
      key: getRandom(),
      text: payload.id,
      action: () => {
        setGlobalAction({ type: 'inventoryDetails', action: { id: payload.id } })
      }
    }),
    inventory_location: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    movement_extra_line_reason: payload => ({ key: getRandom(), text: state.movementExtraLineReasonsById[payload.id]?.title }),
    item: payload => ({
      key: getRandom(),
      text: state.bottlesById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'itemEdit', action: { item_id: payload.id } })
      }
    }),
    item_distributor: payload => {
      let variant = state.itemVariantsById[payload.id] || {}
      return {
        key: getRandom(),
        text: (state.bottlesById[variant.item_id]?.title || '') + ((variant.title && `( ${variant.title})`) || ''),
        action: () => {
          if (variant.item_id) {
            setGlobalAction({ type: 'itemEdit', action: { item_id: variant.item_id } })
          }
        }
      }
    },
    liquor_house: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    location: payload => ({
      key: getRandom(),
      text: state.locationsById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'locationEdit', action: { id: payload.id } })
      }
    }),
    movement: payload => ({
      key: getRandom(),
      text: payload.value || payload.id,
      action: () => {
        setGlobalAction({ type: 'movementEdit', action: { id: payload.id } })
      }
    }),
    onboarding_bottle: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    subrecipe: payload => ({
      key: getRandom(),
      text: state.subrecipesById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'subrecipeEdit', action: { id: payload.id } })
      }
    }),
    pos_brand: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    pos_ingredient: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    pos_item: payload => ({
      key: getRandom(),
      text: state.posItemsById[payload.id]?.title,
      action: () => {
        setGlobalAction({ type: 'posItemEdit', action: { id: payload.id } })
      }
    }),
    pos_menu_category: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    pos_sales_category: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    purchase_order: payload => ({
      key: getRandom(),
      text: payload.value || payload.id,
      action: () => {
        setGlobalAction({ type: 'purchaseOrderEdit', action: { id: payload.id } })
      }
    }),
    revenue_cat_event: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    serving_size: payload => ({
      key: getRandom(),
      text: payload.value || state.servingSizes.find(s => s.id === payload.id)?.title || payload.id,
      action: () => {
        setGlobalAction({ type: 'servingSizeEdit', action: { id: payload.id } })
      }
    }),
    shopping_cart: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    spout: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    type: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    user: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    user_role: payload => ({ key: getRandom(), text: state.rolesById[payload.id]?.title }),
    user_venue: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    variance_group: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    venue: payload => ({
      key: getRandom(),
      text: (state.venuesHighLevelById[payload.id] && state.venuesHighLevelById[payload.id]?.title) || payload.id,
      action: () => {
        setGlobalAction({ type: 'venueEdit', action: { id: payload.id } })
      }
    }),
    venue_subscription: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    web_view_setting: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    wisk_bottle: payload => ({ key: getRandom(), text: payload.value || payload.id }),
    extended_user: payload => ({
      key: getRandom(),
      text: payload.is_super_user ? (state.user.god_mode || payload.full_name === 'System Update' ? payload.full_name : 'Wisk Team') : payload.full_name
    }),

    //missing type
    notFound: payload => ({ key: getRandom(), text: payload.value || payload.id })
  }

  return (type && map[type] && map[type](value)) || map.notFound(value)
}
