export default {
  name: 'wisk-exit',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M32.2,35a2.792,2.792,0,0,1-1.979-.82L17.5,21.46,4.779,34.18A2.8,2.8,0,1,1,.821,30.22L13.541,17.5.821,4.78A2.8,2.8,0,1,1,4.779.82L17.5,13.54,30.221.82a2.8,2.8,0,1,1,3.958,3.96L21.459,17.5l12.72,12.72A2.8,2.8,0,0,1,32.2,35Z'
    }
  ]
}
