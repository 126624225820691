import posthogjs from 'posthog-js'

let posthogToken
switch (window.location.hostname) {
  case 'web.wisk.cool':
    posthogToken = 'phc_3jsp25ugLw5q7rDhDpcFbWRsIC5WzR3MXGvtoimhbeL'
    break
  case 'web.wisk.ai':
    posthogToken = 'phc_DLMDwx3sb8YZw25imCsyvWBuXIaQ65aMxDssCBhKtmH'
    break
  default:
    posthogToken = 'phc_by1m1VlWoUfD9zxqxLbTgnjCEOTFAdWT5i84uBNZ5dk'
}

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthogjs.init(posthogToken, {
      api_host: 'https://us.i.posthog.com',
      capture_pageview: false,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        }
      }
    })
  }
}
