<template>
  <div>
    <div v-if="full" ref="embeddingDiv" style="min-width:320px;height:680px;">
      <iframe v-if="googleURL" :src="googleURL" style="border: 0" width="100%" height="600" frameborder="0"></iframe>
    </div>

    <wiskModal v-if="!full" v-model="modalBookDemoVisible" size="lg" hideFooter title="Book a Demo" extraLarge>
      <div ref="embeddingDiv" style="min-width:320px;height:680px;" v-if="modalBookDemoVisible">
        <iframe v-if="googleURL" :src="googleURL" style="border: 0" width="100%" height="600" frameborder="0"></iframe>
      </div>
      <wiskLoading :loading="loading" />
    </wiskModal>

  </div>
</template>

<script>
import { loadScript } from '@/modules/utils'
import wiskModal from '@/components/common/WiskModal'

export default {
  name: 'EmbeddedForm',
  emits: ['hide'],
  components: { wiskModal },
  props: {
    full: Boolean,
    visible: Boolean,
    calendlyURL: String,
    reclaimDataId: String,
    googleURL: String
  },
  data() {
    return {
      loading: false,
      modalBookDemoVisible: false
    }
  },
  methods: {
    loadCalendlyScript() {
      let calendlyScriptLoaded = !!document.getElementById('wisk-script-loader-calendly-library')

      return new Promise(resolve => {
        if (calendlyScriptLoaded) {
          resolve()
        } else {
          loadScript('https://assets.calendly.com/assets/external/widget.js', {
            async: false,
            id: 'wisk-script-loader-calendly-library',
            callback: () => { resolve() }
          })
        }
      })
    },
    initCalendlyWidget() {
      setTimeout(() => {
        if (window.Calendly && this.$refs && this.$refs.embeddingDiv) {
          window.Calendly.initInlineWidget({
            url: this.calendlyURL,
            parentElement: this.$refs.embeddingDiv,
            prefill: {}
            // utm: {
            //   utmContent: window.wiskSignupFormGlobal_d6f87sdf84 && window.wiskSignupFormGlobal_d6f87sdf84.guid
            // }
          })
          window.addEventListener('message', this.calendlyPostMessageHandler)
        }
      }, 100)
    },
    calendlyPostMessageHandler(e) {
      if (e?.data?.event && e.data.event.startsWith('calendly')) {
        if (e.data.event === 'calendly.event_type_viewed') {
          this.loading = false
        }
      }
    },
    loadReclaimScript() {
      let reclaimScriptLoaded = !!document.getElementById('wisk-script-loader-reclaim-library')

      return new Promise(resolve => {
        setTimeout(() => {
          if (reclaimScriptLoaded) {
            resolve()
          } else {
            loadScript('https://app.reclaim.ai/scripts/embed-scheduling-link.0.x.x.js', {
              async: false,
              id: 'wisk-script-loader-reclaim-library',
              customAttributes: {
                'data-id': this.reclaimDataId
              },
              callback: () => {
                this.loading = false
                resolve()
              },
              target: () => this.$refs.embeddingDiv
            })
          }
        }, 100)
      })
    },
  },
  mounted() {
    if (this.full) {
      if (this.calendlyURL) {
        this.loadCalendlyScript().then(() => {
          this.initCalendlyWidget()
        })
      }
      if (this.reclaimDataId) {
        this.loadReclaimScript()
      }
    }
  },
  beforeUnmount() {
    if (this.calendlyURL) {
      window.removeEventListener('message', this.calendlyPostMessageHandler)
    }
  },
  watch: {
    modalBookDemoVisible(modalBookDemoVisible) {

      if (!this.full) {
        if (modalBookDemoVisible) {
          this.loading = true
          if (this.calendlyURL) {
            this.loadCalendlyScript().then(() => {
              this.initCalendlyWidget()
            })
          }
          if (this.reclaimDataId) {
            this.loadReclaimScript()
          }
        } else {
          if (this.calendlyURL) {
            window.removeEventListener('message', this.calendlyPostMessageHandler)
          }
          this.$emit('hide')
        }
      }
    },
    visible(visible) {
      this.modalBookDemoVisible = visible
    }
  }
}
</script>
