export default {
  Batches: {
    feature: '?',
    helpLink: 'https://help.wisk.ai/en/collections/2612898-items#batched-items',
    icon: 'wisk-batches',
    img: '0cf89088-658e-4b7e-89af-134f378c2c6c.png',
    subFeature: '?'
  },
  MultiVenue: {
    feature: '?',
    helpLink: 'https://help.wisk.ai/en/collections/2954142-enterprise-multi-venue-features',
    icon: 'wisk-multivenue',
    img: '2b12c321-e06e-4c93-8b54-bce5fc4d0de2.png',
    subFeature: '?'
  },
  Reports: {
    feature: '?',
    helpLink: 'https://help.wisk.ai/en/collections/2612967-reports',
    icon: 'wisk-reports-analytics',
    img: '74001ab9-0e35-4db4-bdc2-907ab114c8a3.png',
    subFeature: '?'
  },
  consumption: {
    feature: 'consumption',
    routeName: 'consumption',
    helpLink: 'https://help.wisk.ai/en/articles/3533671-consumption-report',
    icon: 'wisk-consumption',
    img: '4acff33a-46c4-4414-9b06-ed70d16461d1.png'
  },
  deliveries_analytics: {
    feature: 'deliveries',
    routeName: 'movements_summary',
    helpLink: 'https://help.wisk.ai/en/articles/5629151-movements-analytics-report',
    icon: 'wisk-invoices',
    img: '26f5e430-f88f-4c59-ac59-528143955f82.png',
    subFeature: 'analytics'
  },
  deliveries_draft_invoices: {
    feature: 'deliveries',
    routeName: 'scanned_invoices',
    helpLink: 'https://help.wisk.ai/en/articles/5232631-reviewing-converting-a-draft-invoice',
    icon: 'wisk-draft-invoices',
    img: 'ae424df3-5203-4d6a-82e1-fa27ed477778.png',
    subFeature: 'draft_invoices'
  },
  deliveries: {
    feature: 'deliveries',
    routeName: 'intakes',
    helpLink: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#adding-invoices-receiving-orders',
    icon: 'wisk-invoices-returns',
    img: '0c29765b-8e9b-4f59-893a-4438daf33aa8.png'
  },
  deliveries_returns: {
    feature: 'deliveries',
    helpLink: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#adding-invoices-receiving-orders',
    icon: 'wisk-undo',
    img: '2f932a94-f7a0-4a29-a58d-c08fc429ef5a.png',
    subFeature: '?'
  },
  pos_integration_pos_items: {
    feature: 'pos_integration',
    routeName: 'pos_items',
    helpLink: 'https://help.wisk.ai/en/collections/2611239-point-of-sale-pos-integrations-pos-items#pos-items',
    icon: 'wisk-pos-items',
    img: 'cd3bd6d8-2ed9-4b4a-b08e-7cbdcbceb80e.png',
    subFeature: 'pos_items'
  },
  pos_integration_sales: {
    feature: 'pos_integration',
    routeName: 'sales',
    helpLink: 'https://help.wisk.ai/en/collections/2611239-point-of-sale-pos-integrations-pos-items#sales-data',
    icon: 'wisk-sales',
    img: '6178d4e4-ea34-47d7-9bee-925124255f3d.png',
    subFeature: 'sales'
  },
  pos_integration_variance: {
    feature: 'pos_integration',
    routeName: 'variance',
    helpLink: 'https://help.wisk.ai/en/collections/2238591-variance',
    icon: 'wisk-variance',
    img: '7c543a22-8fc0-48e1-9c02-ac1ded5884d8.png',
    subFeature: 'variance'
  },
  purchase_orders_cart: {
    feature: 'purchase_orders',
    routeName: 'orders',
    helpLink: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#generating-orders',
    icon: 'wisk-orders',
    img: '668b74f0-cadb-4fd8-bb8e-69b053df971a.png',
    subFeature: 'cart'
  },
  purchase_orders: {
    feature: 'purchase_orders',
    routeName: 'purchase_orders',
    helpLink: 'https://help.wisk.ai/en/collections/1906933-generating-orders-adding-invoices-returns#adding-invoices-receiving-orders',
    icon: 'wisk-purchase-orders',
    img: 'ab34bd2f-bb41-4407-9679-0ebb872c9c5e.png'
  },
  transfers: {
    feature: 'transfers',
    routeName: 'transfers',
    helpLink: 'https://help.wisk.ai/en/collections/2612461-managing-inventory#transfers',
    icon: 'wisk-transfers',
    img: 'a8340faa-a9ed-4a3a-a3a7-35bf91281a9e.png'
  }
}
