<template>
  <b-button v-if="value" size="sm" class="info-icon" style="right: 10px;" variant="link" @click="order" v-tooltip="title">
    <icon name="wisk-purchase-orders" />
  </b-button>
</template>

<script>
import isFinite from 'lodash.isfinite'

export default {
  name: 'OrderButton',
  props: {
    title: String,
    params: Object
  },
  computed: {
    id() {
      return this.params.value.id
    },
    orderFormat() {
      return this.params.value.order_format
    },
    caseSize() {
      return this.params.value.case_size
    },
    value() {
      return parseFloat(this.params.value.input_value)
    }
  },
  methods: {
    order() {
      if (this.params.addToCart && isFinite(this.value)) {
        let units = this.value

        if (this.orderFormat === 'case') {
          units = this.value * this.caseSize
        }

        this.params.addToCart({ item_distributor_id: this.params.value.item_distributor_id, units })
      }
    }
  }
}
</script>
