<template>
  <div>
    <wiskGrid :rowData="contacts" :columnDefs="columns" :gridOptions="options" trackBy="uuid" resetRows gridAutoHeight :loadingOverlay="gridLoading" parentGridName="DistributorContactsGrid" />
    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import wiskGrid from '@/components/grids/WiskGrid'
import { getContactsGridColumns } from './gridOptions'

export default {
  name: 'DistributorContactsGrid',
  components: { wiskGrid },
  props: {
    distributor: Object
  },
  data() {
    return {
      columns: [],
      options: { getRowHeight: () => 50 },
      gridLoading: false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations']),
    contacts() {
      if (this.distributor && Array.isArray(this.distributor.contacts)) {
        return this.distributor.contacts.map(c => ({ ...c, ...{ distributorId: this.distributor.id } }))
      }

      return []
    }
  },
  methods: {
    ...mapActions(['setDistributor']),
    deleteContact(value, id, type) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ value, id, type })
          },
          message: this.translations.confirmDistributorContactDeleteText,
          title: this.translations.confirmDistributorContactDeleteTitle
        })
      }
    },
    save({ value, id, type, previousValue }) {
      this.gridLoading = true

      //api validation fails on empty strings
      if (type === 'contact_update' && value) {
        if (!value.email) {
          delete value.email
        }
        if (!value.phone) {
          delete value.phone
        }
      }

      this.setDistributor({ id, operation: { type, value, from: previousValue } })
        .then(() => {
          this.gridLoading = false
        })
        .catch(() => {
          this.gridLoading = false
        })
    }
  },
  created() {
    this.columns = getContactsGridColumns(this.translations, this.save, this.deleteContact)
  }
}
</script>

<style lang="scss"></style>
