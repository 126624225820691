export default {
  name: 'wisk-arrows-h',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'm 26.119141,8.7011719 c -0.391045,0.00248 -0.764441,0.1631147 -1.035157,0.4453125 -0.58187,0.578455 -0.574822,1.5220986 0.01563,2.0917966 l 4.804688,4.759766 -24.8242189,0.02539 4.8203125,-4.775391 C 10.842373,10.312717 10.175106,8.7058297 8.8476562,8.7128906 8.4576858,8.7079913 8.082786,8.863317 7.8105469,9.1425781 L 0.42382812,16.439453 c -0.27949344,0.284881 -0.43410159,0.669293 -0.4296875,1.068359 -0.00132011,0.393868 0.15313534,0.772284 0.4296875,1.052735 l 7.38671878,7.310547 c 0.5806275,0.573915 1.5167748,0.56779 2.0898437,-0.01367 0.5794064,-0.582999 0.5794064,-1.524423 0,-2.107422 l -4.8203125,-4.759766 24.8535159,-0.02539 -4.833985,4.791015 c -1.355078,1.384241 0.67308,3.441019 2.076172,2.105469 l 7.384766,-7.3125 c 0.280823,-0.278885 0.440761,-0.656985 0.445312,-1.052734 -0.0017,-0.396348 -0.162047,-0.775507 -0.445312,-1.052735 L 27.175781,9.1328125 C 26.903357,8.8524081 26.527638,8.6963292 26.136719,8.7011719 c -0.0059,-3.56e-5 -0.01172,-3.56e-5 -0.01758,0 z'
    }
  ]
}
