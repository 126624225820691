export default {
  name: 'wisk-arrow-left',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,17.5c0,1.2-1,2.1-2.1,2.1H7.3l11.2,11.1c0.8,0.8,0.9,2.2,0,3c-0.4,0.4-1,0.6-1.5,0.6c-0.5,0-1.1-0.2-1.5-0.6L0.6,19 C0.2,18.6,0,18.1,0,17.5c0-0.4,0.1-0.9,0.4-1.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0.1-0.1L15.5,1.3c0.8-0.8,2.2-0.8,3,0 c0.8,0.8,0.8,2.2,0,3L7.3,15.4h25.6C34,15.4,35,16.3,35,17.5z'
    }
  ]
}
