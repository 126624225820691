export default {
  name: 'wisk-history',
  width: 35,
  height: 34.9917,
  paths: [
    { d: 'M17.505.0041A17.4959,17.4959,0,1,1,0,17.5317,17.5137,17.5137,0,0,1,17.505.0041ZM17.52,31.67A14.1554,14.1554,0,1,0,3.357,17.5513,14.22,14.22,0,0,0,17.52,31.67Z' },
    {
      d: 'M15.8425,12.5275c0-1.5511-.0056-3.1023.0045-4.6533a3.1806,3.1806,0,0,1,.1072-.9286A1.6288,1.6288,0,0,1,17.772,5.87a1.6529,1.6529,0,0,1,1.3953,1.5934c.0366.8231.0136,1.6489.014,2.4735.0009,2.0813.0131,4.1627-.01,6.2437a1.4161,1.4161,0,0,0,.4758,1.1248c1.85,1.8156,3.6712,3.66,5.5092,5.4878a1.8433,1.8433,0,0,1,.6659,1.4445A1.6542,1.6542,0,0,1,23.2389,25.54a3.7861,3.7861,0,0,1-.5241-.4713c-2.0291-2.0254-4.0435-4.0658-6.0917-6.0716a2.4451,2.4451,0,0,1-.7907-1.9343C15.8671,15.5518,15.8425,14.0394,15.8425,12.5275Z'
    }
  ]
}
