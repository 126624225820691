<template>
  <fieldset class="pdf-split-draggable-list-container">
    <legend style="">
      <wiskSelect infoTooltipKey="03bb256d-07eb-4fc7-8fd0-afb169f4da00" :label="translations.txtGenericDistributor" v-show="activeDistributors && activeDistributors.length"
        v-model="distributorId" :items="activeDistributors" :addNewItem="addDistributorConfig" variant="link" class="mb-2 distributor-selector"
        @update:modelValue="onDistributorChange" :disabled="distributorChangeDisabled"/>
    </legend>

    <draggable v-if="localList" :list="localList.items" :group="{ name: 'PDFSplitDraggableList', pull: true, put: true }" itemKey="id"
      :class="[`list-${list.id}`, { 'pdf-split-drag-enabled': dragEnabled }]" :data-list-id="list.id"
      class="flex-row flex-nowrap pdf-split-draggable-list" dragClass="dragging" :key="localList.key"> <!-- @end="dragEnd"  -->
      <template #item="{ element: listItem, index }">
        <div class="thumbnail-wrapper">
          <imageView v-if="listItem.type === 'image'" :src="listItem.fileName" filterDisplay="pdfThumb">
            <b-button class="bt-draft-invoice-remove-page px-1" @click="removePage(listItem.id)" variant="link" v-tooltip="translations.txtScannedInvoicesPDFSplitRemovePage">
              <icon name="wisk-trash" :scale="1" class="text-danger" />
            </b-button>
          </imageView>

          <thumbnail v-else :pdfPage="listItem.pdfPage" :width="thumbnailWidth" :height="thumbnailHeight" class="p-0" hasOverlaySlot
            :title="dragEnabled ? translations.txtScannedInvoicesPDFSplitDragDropInfo : ''">
            <div v-if="localList.items.length > 1" class="" style="height: 30px; width: 100%; bottom: 2px; left: 0; right: 0;  position: absolute;">
              <span v-if="listItem.pdfPage.pageNumber !== index + 1" class="fs-4 text-danger" style="text-decoration: line-through;" v-tooltip="translations.txtScannedInvoicesPDFSplitPageNumberOld">
                &nbsp; {{ listItem.pdfPage.pageNumber }} &nbsp;
              </span>
              <span class="fs-4 fw-bold text-success float-end" style="" v-tooltip="translations.txtScannedInvoicesPDFSplitPageNumber">
                &nbsp; {{ index + 1 }} &nbsp;
              </span>
            </div>
            <b-button class="bt-draft-invoice-view-page px-1" @click="zoomInPage(listItem.id)" variant="link" v-tooltip="translations.txtScannedInvoicesPDFSplitZoom">
              <icon name="wisk-zoom-in" :scale="1" class="text-primary" />
            </b-button>
            <b-button class="bt-draft-invoice-remove-page px-1" @click="removePage(listItem.id)" variant="link" v-tooltip="translations.txtScannedInvoicesPDFSplitRemovePage">
              <icon name="wisk-trash" :scale="1" class="text-danger" />
            </b-button>
          </thumbnail>

          <b-button class="bt-draft-invoice-split-pdf px-1" @click="splitList(index + 1)" variant="link" v-tooltip="translations.txtScannedInvoicesPDFSplitHere">
            <icon name="wisk-scissors" class="text-danger" :scale="1" />
            <div class="split-line"></div>
          </b-button>
        </div>
      </template>
    </draggable>

    <wiskModal :visible="!!selectedPage" size="lg" title="" @hide="selectedPage = null" hideFooter>
      <b-container style="height: calc(100vh - 250px);">
        <PDFPage v-if="selectedPage" :pdfPage="selectedPage" zoomControlType="scale" :scale="1" />
      </b-container>
    </wiskModal>

  </fieldset>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import draggable from 'vuedraggable'
import thumbnail from '@/components/viewers/pdf/PDFThumbnail'
import PDFPage from '@/components/viewers/pdf/PDFPage'
import imageView from '@/components/common/ImageView'

export default {
  name: 'DraftInvoicePageManagerDraggableList',
  emits: ['split', 'fromListId', 'updateList'],
  components: { draggable, thumbnail, imageView, PDFPage },
  props: { list: Object, listCount: Number, distributorChangeDisabled: Boolean },
  data() {
    return {
      localList: null,
      thumbnailWidth: 90,
      thumbnailHeight: 120,
      distributorId: null,
      selectedPage: null
    }
  },
  computed: {
    ...mapState(['translations', 'distributorsById']),
    ...mapGetters(['activeDistributors']),
    dragEnabled() {
      return this.localList?.items?.length > 1 || this.listCount > 1
    },
    addDistributorConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onDistributorChange(distributorId) {
      this.$emit('updateList', { ...this.localList, distributorId })
    },
    zoomInPage(pageId) {
      let found = this.localList.items.find(i => i.id === pageId)
      this.selectedPage = found?.pdfPage
    },
    removePage(id) {
      this.localList.items = this.localList.items.filter(i => i.id !== id)
    },
    splitList(index) {
      this.$emit('split', { listId: this.localList.id, index })
    }
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(list) {
        if (!isEqual(list, this.localList)) {
          this.localList = { ...list, items: [...list.items] }
          this.distributorId = list.distributorId
        }
      }
    },
    localList: {
      handler(localList) {
        this.$emit('updateList', localList)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.pdf-split-draggable-list-container {
  padding: 16px;
  // padding-top: 32px;
  margin-right: 25px;
  position: relative;
  display: inline-block;
  max-width: 99%;
  // overflow-x: auto;
  // overflow-y: hidden;
  // border: 1px solid var(--bs-primary);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  background-color: var(--bs-light);

  legend {
    min-width: 150px;
    max-width: 190px;
    width: 100%;
    padding: 0px;
    border: 0px;
    margin-bottom: 0px;
    background: transparent;
    margin-top: -32px;

    .distributor-selector {
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }
  }

  .bt-draft-invoice-split-pdf {
    opacity: 0;
    height: 100%;
    position: relative;
    width: 18px;

    svg {
      display: none;
      position: absolute;
      bottom: 0;
      left: 1px;
    }

    .split-line {
      height: 150%;
      width: 1px;
      border: 1px dashed var(--bs-border-color);
      margin: auto;
    }

    &:hover {
      .split-line {
        border-color: var(--bs-danger);
      }

      svg {
        display: block;
      }
    }
  }

  &:hover {
    .bt-draft-invoice-split-pdf {
      opacity: 1;
    }
  }
}

.pdf-split-draggable-list {
  // border-radius: var(--bs-border-radius);
  // padding: 10px;
  // padding-bottom: 50px;
  max-width: 100%;

  min-height: 100px;
  overflow-y: hidden;
  overflow-x: auto;
  display: inline-flex;
  width: auto;
  position: relative;
  float: left;

  .wisk-pdf-viewer-thumbnail {
    flex: 0 0 auto;
  }

  .thumbnail-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .bt-draft-invoice-remove-page {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
    }

    .bt-draft-invoice-view-page {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    &:hover {

      .bt-draft-invoice-remove-page,
      .bt-draft-invoice-view-page {
        opacity: 1;
      }

      // .bt-draft-invoice-split-pdf {
      //   opacity: 1;
      // }
    }

    &.sortable-chosen {

      .bt-draft-invoice-split-pdf,
      .bt-draft-invoice-remove-page,
      .bt-draft-invoice-view-page {
        opacity: 0;
      }
    }
  }

  .thumbnail-wrapper:last-child .bt-draft-invoice-split-pdf {
    /* Hide the split button in the last draggable item */
    display: none;
  }

  &.pdf-split-drag-enabled {
    .wisk-pdf-viewer-thumbnail {
      cursor: grab;

      &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
      }
    }
  }
}
</style>
