<template>
  <div class="form-control fill-width-height file-input-container material-design-input plain-text has-value py-0">
    <div class="plain-text-span fill-width-height position-relative " :class="[className, { disabled }]" tabindex="0">

      <span class="" v-if="!showButtonOnly">
        {{ formattedValue }}
      </span>

      <b-button class="wisk-file-clear-search" variant="link" @click="reset" v-if="file" style="top: 0px; right: -10px; position: absolute; z-index: 9;" :title="translations.txtGenericClear">
        <icon class="text-primary" name="wisk-exit" :scale="0.7" />
      </b-button>
      <b-button class="wisk-file-select-file" :block="showButtonOnly" :variant="variant" v-if="!file" :size="size">
        {{ buttonText || translations.txtImageUploaderSelectFile }}
      </b-button>

      <input v-bind="$attrs" :multiple="multiple" class="wisk-file-input cursor-pointer" ref="fileInput" autocomplete="off" :disabled="disabled" @click="onClick" @input="onInput" type="file" />
    </div>

    <label v-if="label || infoTooltipVisible">
      <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
      {{ label }}

      <slot name="label-append"></slot>
    </label>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { round } from '@/modules/utils'

export default {
  name: 'FileInput',
  emits: ['update:modelValue', 'reset'],
  components: {},
  props: {
    label: String,
    size: { type: String, default: 'sm' },
    variant: { type: String, default: 'primary' },
    infoTooltipKey: String,
    showButtonOnly: Boolean,
    allowOnlyDrop: Boolean,
    buttonText: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    fileTypesAccept: String,
    maxFileSize: { type: Number, default: 50000000 },
    disabled: Boolean,
    multiple: Boolean,
    className: null
  },
  data() {
    return {
      file: null,
      files: [],
      infoTooltipVisible: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    formattedValue() {
      if (this.file) {
        return this.file.name
      }
      return 'No file selected'
    }
  },
  methods: {
    ...mapActions(['notify', 'setGlobalAction']),
    reset() {
      this.file = null
      this.files = []

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null
      }

      this.$emit('reset')
    },
    onClick(ev) {
      if (this.allowOnlyDrop) {
        ev.preventDefault()
      }
    },
    onInput(ev) {
      this.file = ev.target.files[0]
      this.files = ev.target.files

      if (this.file && this.checkFile(this.file) && !this.multiple) {
        this.$emit('update:modelValue', this.file)
      }
      if (this.multiple) {
        this.$emit(
          'update:modelValue',
          [...this.files].filter(file => file && this.checkFile(file))
        )
      }
    },
    checkFile(file) {
      let acceptedFiles = (this.fileTypesAccept && this.fileTypesAccept.split(',')) || [],
        mimeType = file.type,
        baseMimeType = mimeType.replace(/\/.*$/, '')

      if (file.size >= this.maxFileSize) {
        this.notify({
          message: `File "${file.name}" is too big (${round(file.size / 1048576, 1)} MB). Max size is: ${round(this.maxFileSize / 1048576, 1)} MB`,
          type: 'error'
        })

        return false
      }
      if (!this.fileTypesAccept) {
        return true
      }
      for (let i = 0; i < acceptedFiles.length; i++) {
        let validType = acceptedFiles[i]

        validType = validType.trim()
        if (validType.charAt(0) === '.') {
          if (file.name.toLowerCase().endsWith(validType.toLowerCase())) {
            return true
          }
        } else if (/\/\*$/.test(validType)) {
          if (baseMimeType === validType.replace(/\/.*$/, '')) {
            return true
          }
        } else if (mimeType === validType) {
          return true
        }
      }

      this.notify({
        message: `File "${file.name}" of type ${file.type} does not match accepted file types: ${this.fileTypesAccept}`,
        type: 'error'
      })

      return false
    }
  },
  mounted() {
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  watch: {}
}
</script>

<style lang="scss">
.wisk-file-input {
  opacity: 0;
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.wisk-file-clear-search {
  :hover {
    transform: scale(1.05);
  }
}

.wisk-file-select-file {
  top: 0px;
  right: 0px;
  position: absolute !important;
  z-index: 0 !important;
}
</style>
