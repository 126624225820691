export default {
  name: 'wisk-empty-box',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M28.9,34.958H6.1A6.1,6.1,0,0,1,0,28.872V6.127A6.1,6.1,0,0,1,6.1.042H28.9A6.1,6.1,0,0,1,35,6.127V28.872A6.1,6.1,0,0,1,28.9,34.958ZM6.1,3.115A3.023,3.023,0,0,0,3.072,6.127V28.872A3.023,3.023,0,0,0,6.1,31.885H28.9a3.021,3.021,0,0,0,3.025-3.013V6.127A3.021,3.021,0,0,0,28.9,3.115Z'
    }
  ]
}
