export default {
  name: 'wisk-consumption',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.66,11.681a1.56,1.56,0,0,1-.461.06A1.822,1.822,0,0,1,31.458,10.4l-.6-2.262L23.675,20.67a1.813,1.813,0,0,1-2.188.788L13.466,18.5,3.258,32.6a1.8,1.8,0,0,1-1.459.744,1.724,1.724,0,0,1-1.056-.343,1.8,1.8,0,0,1-.4-2.514L11.353,15.283a1.778,1.778,0,0,1,2.068-.64l7.888,2.917L27.722,6.354l-2.232.625A1.807,1.807,0,0,1,24.523,3.5L31,1.711a1.875,1.875,0,0,1,1.384.179,1.782,1.782,0,0,1,.834,1.1L34.94,9.479A1.8,1.8,0,0,1,33.66,11.681Z'
    }
  ]
}
