export default {
  name: 'wisk-families-categories',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.5441,13.0025,31.2376,2.9076A3.5943,3.5943,0,0,0,27.1513.0316L20.5306.8147c-1.139.1566-2.278.2847-3.417.3987a3.8913,3.8913,0,0,0-2.805,1.7227c-2.89,4.1148-5.7663,8.2011-8.6424,12.3159L2.1637,20.2353a1.9814,1.9814,0,0,0-.2136.3132,3.5484,3.5484,0,0,0-.1281,3.6733,3.9238,3.9238,0,0,0,1.2386,1.3527l12.3159,8.6423A3.7075,3.7075,0,0,0,17.5976,35h.1851a3.6323,3.6323,0,0,0,2.8618-1.6659L32.8891,15.8926a6.5542,6.5542,0,0,0,.37-.5838A3.4412,3.4412,0,0,0,33.5441,13.0025Zm-1.9506,1.4663a2.4784,2.4784,0,0,1-.2136.37L19.1211,32.2805a1.7991,1.7991,0,0,1-1.4381.8685,1.9133,1.9133,0,0,1-1.2387-.4415l-12.33-8.6564a2.5868,2.5868,0,0,1-.6549-.6693,1.7315,1.7315,0,0,1,.0569-1.8651c.057-.0855.1-.1425.2136-.2989L7.1754,16.32c2.8761-4.1147,5.7664-8.201,8.6425-12.33a2.0211,2.0211,0,0,1,1.5092-.9112c1.2244-.1566,2.4347-.2847,3.6591-.4555l1.1818-.1283,5.1968-.6264a1.7462,1.7462,0,0,1,2.0645,1.4523l2.3066,10.08A1.4737,1.4737,0,0,1,31.5935,14.4688Z'
    },
    {
      d: 'M27.5784,7.9194a3.373,3.373,0,0,0-6.6491,1.1391,3.3927,3.3927,0,0,0,1.3668,2.2068,3.3345,3.3345,0,0,0,1.9506.6123,2.7009,2.7009,0,0,0,.5838-.057,3.2949,3.2949,0,0,0,2.1926-1.381A3.3591,3.3591,0,0,0,27.5784,7.9194ZM25.5,9.3717a1.4743,1.4743,0,0,1-.9824.6264,1.4508,1.4508,0,0,1-1.1532-.2562,1.4781,1.4781,0,0,1-.6123-.9682A1.5232,1.5232,0,0,1,23.99,7.0082a1.5712,1.5712,0,0,1,.27-.0284,1.5228,1.5228,0,0,1,.8685.2705,1.5414,1.5414,0,0,1,.6265.9823A1.4741,1.4741,0,0,1,25.5,9.3717Z'
    },
    {
      d: 'M18.2953,25.1758,10.5926,19.737c-.1-.057-.1994-.1283-.2991-.1853a2.2756,2.2756,0,0,0-1.0536-.27,2.2027,2.2027,0,0,0-2.1356,2.4346l.0142.0428a2.2937,2.2937,0,0,0,1.0109,1.6088l7.5888,5.3252c.1424.0853.2848.1707.4271.2419a1.9582,1.9582,0,0,0,.954.2562,1.99,1.99,0,0,0,.7546-.1564,2.1644,2.1644,0,0,0,1.41-1.8225A2.0768,2.0768,0,0,0,18.2953,25.1758Zm-.4414,1.8793a.7738.7738,0,0,1-.5411.6551.8051.8051,0,0,1-.2135.0568,1.1525,1.1525,0,0,1-.3133-.0853,2.6775,2.6775,0,0,1-.2562-.1709L8.9409,22.1859a.8733.8733,0,0,1-.4128-.6264l-.0143-.0713a.7231.7231,0,0,1,.3417-.6406.6049.6049,0,0,1,.3844-.1425.6756.6756,0,0,1,.3417.0855l.0855.0712c.057.0284.1.0569.1566.0854l7.66,5.396A.7152.7152,0,0,1,17.8539,27.0551Z'
    }
  ]
}
