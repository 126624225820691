export default {
  name: 'wisk-scale',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M17.5,35c-3.9,0-7.8,0-11.6,0c-3-0.1-5.4-2.4-5.8-5.4C0,28.1,0,26.5,0,25c0-5.6,0-11.3,0-16.9C0,7,0,5.9,0.2,4.9 C0.8,2,3.4-0.1,6.3,0c5.1,0,10.1,0,15.2,0c2.4,0,4.8,0,7.1,0c2.3-0.1,4.4,1.1,5.5,3.1c0.6,1,0.9,2.1,0.8,3.2c0,5.1,0,10.2,0,15.4 c0,2.4,0,4.8,0,7.2c-0.1,3-2.3,5.5-5.2,5.9C28.6,35,27.5,35,26.3,35C23.4,35,20.4,35,17.5,35L17.5,35z M17.3,5 c-1.8,0-3.5,0.3-5.1,1c-2.6,1-4.9,2.7-6.7,4.8c-0.5,0.5-0.6,1.2-0.1,1.8c0.1,0.1,0.2,0.2,0.3,0.2c1.8,1.5,3.7,2.9,5.5,4.3 c0.5,0.4,1.3,0.4,1.7-0.1c0,0,0.1-0.1,0.1-0.2c0.2-0.4,0.5-0.7,0.8-1c1-1.1,2.4-1.8,3.9-1.7c1.8,0,3.5,1,4.4,2.6 c0.3,0.6,1,0.9,1.6,0.7c0.2-0.1,0.3-0.2,0.4-0.3l5.3-4.2c0.6-0.3,0.8-1.1,0.5-1.7c-0.1-0.2-0.2-0.3-0.3-0.4c-1-1.1-2.1-2.1-3.3-2.9 C23.7,6,20.5,5,17.3,5z'
    },
    { d: 'M8.1,11.7c5.5-5.5,13.3-5.5,18.8,0l-3.5,2.8c-1.2-1.4-2.8-2.3-4.6-2.7c0-0.6,0-1.1,0-1.7c0.1-0.7-0.5-1.3-1.1-1.4 c-0.7-0.1-1.3,0.5-1.4,1.1c0,0.1,0,0.1,0,0.2c0,0.5,0,1.1,0,1.7c-1.8,0.3-3.4,1.3-4.6,2.7L8.1,11.7z' }
  ]
}
