<template>
  <component :is="inline ? 'div' : 'wiskModal'" :visible="!!editAction" :preventClose="editAction?.preventClose" hideFooter
    @hide="setGlobalAction({ type: 'planSelectorEdit', action: null })" :title="translations.txtBookDemoTitle" class="" bodyClass="" >

    <b-container fluid style="height: 180px;" class="pt-5">
      <b-row class="text-center">
        <b-col>
          <b-button :size="deviceType === 'mobile' ? 'md' : 'lg'" variant="outline-upgrade" href="https://www.wisk.ai/price" target="_blank" rel="noopener" class="w-100">
            {{ translations.txtBookDemoButtonPrices }}
            <icon name="wisk-verynice" :scale="0.9" class="ms-2" />
          </b-button>
        </b-col>
        <b-col>
          <b-button :size="deviceType === 'mobile' ? 'md' : 'lg'" variant="outline-success" :href="$signupMeetingLink" target="_blank" rel="noopener" class="w-100">
            {{ translations.txtBookDemoButtonMeeting }}
            <icon name="wisk-new-tab" :scale="0.8" class="ms-2" />
          </b-button>
        </b-col>
      </b-row>
    </b-container>

  </component>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'PlanSelectorEdit',
  props: {
    inline: Boolean,
    editAction: {
      type: Object
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters(['deviceType']),
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  }
}
</script>

<style lang="scss" >
</style>
