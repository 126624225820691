<template>
  <wiskModal size="lg" :title="translations.txtAuthPassInputNew" @ok="ok" :centered="true" v-model="modalOpen" v-bind="$attrs"
    class="wisk-confirm" @hide="cancel" :okDisabled="!valid">
    <b-container fluid v-if="modalOpen">
      <b-row class="my-1">
        <b-col sm="3">
          <label for="password1"> {{ translations.txtAuthPass1 }} :</label>
        </b-col>
        <b-col sm="9">
          <input class="form-control" id="password1" type="password" v-model="password1" />
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="3">
          <label for="password2"> {{ translations.txtAuthPass2 }} :</label>
        </b-col>
        <b-col sm="9">
          <input class="form-control" id="password2" type="password" v-model="password2" />
        </b-col>
      </b-row>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserPasswordPrompt',
  components: {},
  props: {},
  data() {
    return {
      password1: '',
      password2: '',
      valid: false,
      modalOpen: false,
      callback: null
    }
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    ok() {
      if (this.valid && this.callback) {
        this.callback(this.password1)
        setTimeout(() => {
          this.password2 = this.password1 = ''
          this.callback = null
          this.modalOpen = false
        }, 0)
      }
    },
    cancel() {
      this.password2 = this.password1 = ''
      this.callback = null
      this.modalOpen = false
    },
    promptForNewPassword(callback) {
      if (typeof callback === 'function') {
        this.modalOpen = true
        this.callback = callback
      } else {
        console.error('UserPasswordChange.promptForNewPassword was called without a callback method')
      }
    }
  },
  watch: {
    password1() {
      this.valid = this.password1 && this.password1.length > 5 && this.password1 === this.password2
    },
    password2() {
      this.valid = this.password1 && this.password1.length > 5 && this.password1 === this.password2
    }
  }
}
</script>

<style lang="scss"></style>
