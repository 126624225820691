<template>
  <div v-show="loading" class="wisk-component-loading" :style="{ 'z-index': timeoutPassed && '0' || '1000' }">
    <icon name="wisk-circle-notch" class="icon-spin3d fa-5x" v-show="!timeoutPassed"></icon>

    <slot name="display-after-timeout" v-if="timeoutPassed"></slot>
  </div>
</template>

<script>
export default {
  name: 'WiskLoading',
  props: {
    loading: Boolean,
    timeout: Number
  },
  data() {
    return {
      timeoutPassed: false
    }
  },
  mounted() {
    if (this.timeout) {
      setTimeout(() => {
        this.timeoutPassed = true
      }, this.timeout)
    }
  }
}
</script>

<style>
.wisk-component-loading {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1000;
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.icon-spin3d {
  display: inline-block;
  animation: spin3d 1s infinite linear;
}

@keyframes spin3d {
  0% {
    transform: rotate(0deg) translateZ(0);
  }

  100% {
    transform: rotate(359deg) translateZ(0);
  }
}
</style>
