export default {
  name: 'wisk-items',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 17.5 0 C 15.597976 0.01761818 13.942612 1.3054514 13.457031 3.1445312 L 10.625 3.1445312 C 9.5550204 3.1598863 8.6998252 4.0393906 8.7148438 5.109375 L 6.8046875 5.109375 C 5.0929237 5.1340508 3.7255236 6.5421395 3.75 8.2539062 L 3.75 31.853516 C 3.7238491 33.566447 5.0917346 34.975332 6.8046875 35 L 28.193359 35 C 29.905516 34.97587 31.274474 33.567621 31.25 31.855469 L 31.25 8.2539062 C 31.274474 6.5417546 29.905516 5.1335041 28.193359 5.109375 L 26.285156 5.109375 C 26.300175 4.0393906 25.44498 3.1598863 24.375 3.1445312 L 21.542969 3.1445312 C 21.057085 1.3056514 19.401904 0.01796066 17.5 0 z M 17.5 2.3496094 C 18.476282 2.3496094 19.452721 3.0070568 19.410156 4.3222656 L 19.410156 5.5 L 23.992188 5.5 L 23.992188 9.4296875 L 11.007812 9.4296875 L 11.007812 5.5 L 15.589844 5.5 L 15.589844 4.3222656 C 15.547279 3.0070568 16.523718 2.3496094 17.5 2.3496094 z M 6.8046875 8.2539062 L 8.7148438 8.2539062 L 8.7148438 9.8242188 C 8.6998247 10.894203 9.5550204 11.773708 10.625 11.789062 L 24.380859 11.789062 C 25.450839 11.773713 26.304081 10.894203 26.289062 9.8242188 L 26.289062 8.2539062 L 28.193359 8.2539062 L 28.193359 31.853516 L 6.8046875 31.853516 L 6.8046875 8.2539062 z '
    }
  ]
}
