<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'distributors', action: null })"
    extraLarge size="lg" hideFooter :title="translations.txtGenericDistributors">
    <distributorsGrid inModal />
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import distributorsGrid from '@/components/distributors/DistributorsGrid'

export default {
  name: 'DistributorsEdit',
  components: { distributorsGrid },
  props: { editAction: Object },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  }
}
</script>
<style lang="scss"></style>
