export default {
  name: 'wisk-prepare-orders',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M31.8,11.5H21.8l3.7-3.7c0.4-0.4,0.4-1.2,0-1.6s-1.2-0.4-1.6,0l0,0l-2.7,2.8V1.3c0-0.6-0.5-1.1-1.1-1.2 c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0.1v7.6l-2.8-2.8c-0.4-0.4-1.2-0.5-1.6,0c-0.4,0.4-0.4,1.2,0,1.6l3.7,3.7H7.8l-1-4 C6.4,6.1,5.1,5.1,3.6,5.1h-2C0.8,5.1,0,5.8,0,6.7s0.7,1.6,1.6,1.6h2l4.2,16.9c0.4,1.4,1.6,2.4,3.1,2.5h18.4c1.5,0,2.8-1,3.1-2.5 l2.4-9.7c0.4-1.7-0.6-3.5-2.3-3.9C32.4,11.5,32.1,11.5,31.8,11.5z M29.4,24.4H11l-2.4-9.7h23.3L29.4,24.4z'
    },
    { d: 'M11.3,34.9c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4c0,0,0,0,0,0C8.9,33.8,10,34.9,11.3,34.9z' },
    { d: 'M29.1,34.9c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4c0,0,0,0,0,0 C26.6,33.8,27.7,34.9,29.1,34.9z' }
  ]
}
