<template>
  <div :data-error-boundary-name="label">
    <b-alert v-if="hasError" variant="danger" :dismissible="!inProduction" show @closed="hasError = false">
      <strong>
        <span v-show="count > 1">({{ count }}) </span>
        Something went wrong
        <span v-show="!inProduction">
          with
          <kbd>{{ label }}</kbd>
        </span>
      </strong>
      <p class="m-0" v-show="!inProduction">
        <small>search dev console for more details: <pre class="user-select-all mb-0 ms-1 d-inline">{{ uuid }}</pre></small>
      </p>
    </b-alert>
    <slot v-else />
  </div>
</template>
<script>
import { guid } from '@/modules/utils'

export default {
  name: 'ErrorBoundary',
  props: {
    label: { type: String, default: 'Unnamed' }
  },
  data: () => {
    let inProduction = import.meta.env.PROD
    return {
      count: 0,
      inProduction,
      hasError: false,
      uuid: guid()
    }
  },
  errorCaptured(err, vm, info) {
    this.count++
    let label = `[${this.uuid}] ${this.label}: error boundary report:`
    console.group(label)
    console.log('info:', info)
    console.error('err:', err)
    console.log('vm:', vm)
    console.groupEnd(label)
    this.hasError = true
    return false
  }
}
</script>
