export default {
  name: 'wisk-add',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M28.3-0.5H6.7C3-0.5,0,2.5,0,6.2v21.7c0,3.7,3,6.7,6.7,6.7h21.7c3.7,0,6.7-3,6.7-6.7V6.2C35,2.5,32-0.5,28.3-0.5z  M25.5,18.5H19v6.4c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7v-6.4H9.3c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7h6.4V8.8 c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7v6.4h6.4c0.9,0,1.7,0.7,1.7,1.7C27.1,17.8,26.4,18.5,25.5,18.5z'
    }
  ]
}
