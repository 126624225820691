<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemEdit', action: null })" class="bottle-edit"
    :hideFooter="!!(bottle && bottle.item_id)" hideHeaderExtra :size="bottle && bottle.item_id ? 'lg' : 'md'" :extraLarge="modalExtraLarge">
    <template v-slot:modal-title>
      <b-row>
        <b-col md="6" cols="12" :class="{ 'bg-warning': !!(bottle && bottle.archived) }">
          <h5 class="modal-title mb-2"> {{ modalTitle }} </h5>
        </b-col>
        <b-col v-if="bottle && bottle.item_id" md="3" cols="6">
          <b-form-radio-group style="margin-left: 5px;" size="sm" buttons v-model="selectedEditorTab" name="titleSearchLocal" button-variant="outline-primary">
            <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
            <b-form-radio :value="1"> {{ translations.txtGenericUsedIn }} </b-form-radio>
            <b-form-radio :value="2"> {{ translations.txtGenericTimeline }} </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col v-if="bottle && bottle.linked_subrecipe_id">
          <b-button style="float: left;" @click="setGlobalAction({ type: 'subrecipeEdit', action: { id: bottle.linked_subrecipe_id } })" variant="link">
            {{ translations.txtSubrecipesLinkedSubrecipe }}
          </b-button>
        </b-col>
        <b-col cols="4" md="2" class="ps-0" v-if="bottle && bottle.item_id && !readonly && !selectedEditorTab">
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" :zIndex="12" size="sm" />
        </b-col>
      </b-row>
    </template>

    <div class="min-h-440">
      <div v-if="selectedEditorTab === 0 && bottle">
        <b-row>
          <b-col class="text-center">
            <b-button v-if="bottle.grouped_to" variant="link" @click="setGlobalAction({ type: 'itemEdit', action: { itemId: bottle.grouped_to } })">
              {{ translations.txtBottlesGroupOpenResulted }}
            </b-button>
            <h5 v-if="bottle.archived_by_merge">
              {{ translations.txtBottlesMergedArchived }}
            </h5>
          </b-col>
        </b-row>

        <gateKeeper feature="items" :blockIfLimitReached="bottle && bottle.item_id === 0" dontNagIfLimitReached :limitedItemsCount="activeBottles.length">
          <!-- @refresh="refresh" -->
          <itemOperations :triggerInputOnLoad="(!!editAction.item || !!editAction.title || (bottle && bottle.item_id === 0)) && !editAction.readonly" :operationsFromOutside="forcedOperations"
            :bottle="bottle" :disabled="readonly" @validStateChanged="$event => valid = $event" :key="localKey" @operation="onSingleChange" :algo="algo"
            :autocompleteTitle="!bottle.item_id" :openMeasurement="editAction.openMeasurement" :cloneBase="editAction.cloneBase" :selectedVariantId="selectedVariantId"
            @titleAutocompleteSelected="titleAutocompleteSelected" @operations="onOperationsChange" />
        </gateKeeper>
      </div>
      <div v-if="selectedEditorTab === 1 && bottle && bottle.item_id">
        <itemUsageGrid :item="bottle" v-if="selectedEditorTab === 1" />
      </div>
      <div v-if="selectedEditorTab === 2 && bottle && bottle.item_id">
        <timeline v-if="selectedEditorTab === 2" :filters="[{ type: 'bottle', id: bottle.item_id }]" parentGridName="BottleEditTimeline" gridAutoHeight />
      </div>
    </div>

    <wiskModal v-model="copyToVenueModal" :title="translations.txtGenericCopyToVenue" :okDisabled="!venueToCopyItem" @ok="copyItemToVenue"
      :okText="translations.txtGenericSubmit" @hide="venueToCopyItem = null">
      <wiskSelect :items="siblingVenues" :label="translations.txtGenericCopyToVenue" @change="venueToCopyItem = $event" fixedWrapper />
    </wiskModal>

    <confirm ref="confirmDialog" />
    <confirm ref="confirmDialogArchive" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :previous="editAction && editAction.previous" :save="readonly || selectedEditorTab ? null : saveNew"
        :next="editAction && editAction.next" :close="readonly ? () => { setGlobalAction({ type: 'itemEdit', action: null }) } : null" :loading="loading" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import { printBarcode, dymoPrinterSetup, testDymoPrinterAvailable } from '@/modules/dymoPrinter'
import { isLocalhost, getBaseValueFromMeasurement, getMeasurementDisplayString } from '@/modules/utils'
import itemUsageGrid from '@/components/bottles/ItemUsageGrid'
import timeline from '@/components/timeline/Timeline'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import itemOperations from '@/components/bottles/ItemOperations'

export default {
  name: 'ItemEdit',
  components: { itemOperations, editorButtons, timeline, itemUsageGrid },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      operations: [],
      forcedOperations: [],
      dymoPrinterAvailable: false,
      selectedEditorTab: 0,
      selectedVariantId: 0,
      valid: true,
      bottle: null,
      localKey: 1,
      itemStats: null,
      itemEditComponent: true,
      callbackItemCreated: null,
      dymoCheckTimeoutId: null,
      copyToVenueModal: false,
      venueToCopyItem: null,
      loading: false
    }
  },
  computed: {
    ...mapState(['customFieldsByTarget', 'currentPermissionsByType', 'translations', 'bottleAlgosById', 'distributorsById', 'bottlesById', 'bottles', 'itemVariantsById', 'user']),
    ...mapGetters(['venue', 'siblingVenues']),
    algo() {
      if (this.bottle && this.bottle.item_id && this.bottleAlgosById && this.bottleAlgosById[this.bottle.item_id]) {
        let found = this.bottleAlgosById[this.bottle.item_id]
        found.item_distributors = found.item_distributors || []
        found.item_distributors = found.item_distributors.map(v => {
          let variant = this.itemVariantsById[v.item_distributor_id] || { title: '', measurement: {} }

          return {
            ...v,
            title:
              variant.title + ` - (${variant.measurement.alias ? variant.measurement.alias + ' - ' : ''}${variant.measurement.quantity} ${variant.measurement.unit_of_measurement})`
          }
        })

        return found
      }

      return null
    },
    modalExtraLarge() {
      if (this.bottle && this.bottle.item_id && this.customFieldsByTarget && this.customFieldsByTarget.item && this.customFieldsByTarget.item.filter(c => !c.archived).length) {
        return true
      }

      return false
    },
    pageActions() {
      if (this.bottle) {
        let actions = [
          {
            key: 8,
            title: this.translations.txtVenueBottlesCreateVariant,
            variant: 'primary',
            icon: 'wisk-item-variation',
            action: this.addVariant,
            hide: !!this.bottle?.linked_subrecipe_id
          },

          { key: 9, title: this.translations.txtBottlesMergeRevert, variant: 'primary', icon: 'wisk-ungroup-object', action: this.unMerge, hide: !this.bottle.grouped_from },
          { key: 10, title: this.translations.txtGenericClone, variant: 'primary', icon: 'wisk-clone', action: this.cloneItem },
          { key: 11, title: this.translations.txtCustomFieldAdd, variant: 'primary', icon: 'wisk-plus', action: this.addCustomField },
          { key: 12, title: this.translations.txtCustomFieldsView, variant: 'primary', icon: 'wisk-options', action: this.viewCustomFields },
          { key: 13, title: this.translations.txtItemSwap, variant: 'primary', icon: 'wisk-retweet', action: this.swapItem },
          {
            key: 13, title: this.translations.txtGenericCopyToVenue, variant: 'primary', icon: 'wisk-copy',
            action: () => {
              this.copyToVenueModal = true
            },
            hide: !this.currentPermissionsByType.venue_data_copy
          },
          { key: 14, title: this.translations.txtGLAccountsManage, variant: 'primary', action: this.glAccounts, hide: !this.venue.accounting_enabled },
          { key: 15, title: this.translations.txtTaxes, variant: 'primary', action: this.taxes },
          { key: 19, title: this.translations.txtBottlesMergeOrGroup, variant: 'primary', icon: 'wisk-group-object', action: this.itemMergeGroupPrepare },
          { key: 20, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: !this.currentPermissionsByType.item_archive || this.bottle.archived, action: this.toggleArchived },
          {
            key: 30,
            title: this.translations.txtGenericRestore,
            icon: 'wisk-history',
            variant: 'success',
            hide: !this.currentPermissionsByType.item_archive || !this.bottle.archived || this.bottle.archived_by_merge,
            action: this.toggleArchived,
            planFeature: 'items',
            planFeatureLimitedItemsCount: this.activeBottles.length
          },
          { key: 40, isDivider: true },
          { key: 45, title: this.translations.txtBarcodePrinterConfig, variant: 'primary', icon: 'wisk-print', action: this.setupPrinter }
        ]

        if (this.dymoPrinterAvailable) {
          actions.push({ key: 50, title: this.translations.txtItemOperationsBarcodePrint, variant: 'primary', icon: 'wisk-draft-invoices', action: this.printBarcode })
        }

        return actions
      }
      return []
    },
    activeBottles() {
      return this.bottles.filter(b => !b.archived)
    },
    modalTitle() {
      if (this.bottle) {
        if (this.bottle.item_id) {
          return this.bottle.title + this.bottle.titleSuffix
        }
        return this.translations.txtVenueBottlesNewBottleModalTitle
      }

      return ''
    },
    readonly() {
      return (this.editAction && this.editAction.readonly) || !this.currentPermissionsByType.item_manage || this.bottle?.grouped_to || this.bottle?.archived_by_merge
    },
  },
  methods: {
    ...mapActions(['updateBottle', 'setGlobalAction', 'notify']),
    onOperationsChange(operations) {
      this.operations = [...operations]
    },
    glAccounts() {
      this.setGlobalAction({ type: 'glAccounts', action: {} })
    },
    taxes() {
      this.setGlobalAction({ type: 'taxes', action: {} })
    },
    printBarcode() {
      if (testDymoPrinterAvailable()) {
        let software = get(this.venue, 'print_barcode_configs[0].software', 'label'),
          labelTemplate = 'label_' + get(this.venue, 'print_barcode_configs[0].label', '36x89')

        printBarcode({ title: this.bottle.title, barcode: this.bottle.item_id, labelTemplate, software })
      }
    },
    setupPrinter() {
      this.setGlobalAction({ type: 'barcodePrinterSetup', action: {} })
    },
    onSingleChange(operation) {
      if (this.bottle && this.bottle.item_id) {
        this.save(operation)
      }
    },
    unMerge() {
      if (this.$refs.confirmDialog && this.bottle && this.bottle.grouped_from) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.bottleUnMerge({ item_id: this.bottle.item_id }).then(() => {
              this.setGlobalAction({ type: 'itemEdit', action: null })
            })
          },
          message: this.translations.confirmBottlesUnMergeUnGroupText,
          title: this.translations.confirmBottlesUngroupTitle
        })
      }
    },
    cloneItem() {
      api.cloneItem(this.bottle.item_id).then(result => {
        result.variants = result.item_distributors
        this.setGlobalAction({ type: 'itemEdit', action: { item_id: result.item_id, item: result } })
      })
    },
    addCustomField() {
      this.setGlobalAction({ type: 'customFieldEdit', action: { target: 'item' } })
    },
    viewCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'item' } })
    },
    addVariant() {
      this.setGlobalAction({ type: 'itemVariantEdit', action: { id: 0, itemId: this.bottle.item_id } })
    },
    swapItem() {
      this.setGlobalAction({ type: 'itemSwap', action: { itemId: this.bottle.item_id } })
    },
    itemMergeGroupPrepare() {
      this.setGlobalAction({ type: 'itemMergeGroupPrepare', action: { itemId: this.bottle.item_id } })
    },
    titleAutocompleteSelected(item) {
      let defaultVariant = (item.item_distributors || []).find(v => v.is_default)
      this.bottle.title = item.title

      if (defaultVariant) {
        this.bottle.barcodes = defaultVariant.barcodes
        this.bottle.weights = defaultVariant.weights
        this.bottle.empty_weights = defaultVariant.empty_weights
      }
    },
    saveNew() {
      let operations = this.operations

      if (operations?.length && !this.bottle.item_id && this.$refs.confirmDialog) {
        let measurementOperation = operations.find(o => o.type === 'measurement'),
          isUnitOrEach = measurementOperation?.value?.unit_of_measurement === 'unit' || measurementOperation?.value?.unit_of_measurement === 'ea',
          measurementDisplayString = getMeasurementDisplayString(measurementOperation?.value),
          autoConfirm = true,
          baseValueTo = getBaseValueFromMeasurement(measurementOperation.value),
          okText = '',
          message = ''

        if (baseValueTo > 100000) {
          message = this.translations.translate('tplConfirmItemMeasurementGreaterThanKgOrLThreshold', { '{a}': measurementDisplayString })
          autoConfirm = false
        }

        if (isUnitOrEach) {
          message = this.translations.translate('tplConfirmItemMeasurementUnit', { '{a}': measurementDisplayString })
          autoConfirm = false
          okText = this.translations.txtGenericConfirm + ' ' + measurementDisplayString
        }

        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save(operations, true)
          },
          message,
          okText,
          title: this.translations.confirmItemsGridFloatingActionTitle,
          autoConfirm
        })
      }
    },
    save(operations, newItem) {
      if ((this.valid || operations) && this.bottle) {
        this.loading = true
        if (!this.bottle.item_id) {
          // for creating new bottle only operations containing a value are valid
          this.operations = this.operations.filter(o => o.value)
          this.operations.push({ type: 'creation_source', value: this.editAction.creationSource || { type: 'manual' } })
          this.bottle.item_id = 0
        }

        this.updateBottle({ id: this.bottle.item_id, operation: operations || this.operations })
          .then(updatedItem => {
            if (typeof this.editAction.onChange === 'function') {
              this.editAction.onChange(merge({}, updatedItem))
            }

            this.operations = []

            if (newItem) {
              this.setGlobalAction({ type: 'itemEdit', action: null })

              if (!this.editAction.preventNewItemPreview) {
                setTimeout(() => {
                  this.setGlobalAction({ type: 'itemEdit', action: { itemId: updatedItem.item_id } })
                }, 500)
              }
            }
            this.loading = false
          })
          .catch(error => {
            let failed = get(error, 'data.info')
            if (failed && Array.isArray(failed.used_by)) {
              this.setGlobalAction({
                type: 'itemOperationErrorHandler',
                action: {
                  items: [failed],
                  operation: operations,
                  retry: operation => {
                    this.save(operation)
                  }
                }
              })
            }
            this.loading = false
          })
      }
    },
    toggleArchived() {
      if (this.$refs.confirmDialogArchive && this.bottle) {
        this.$refs.confirmDialogArchive.confirm({
          callback: () => {
            this.save({ type: 'archive', value: !this.bottle.archived })
          },
          message: this.bottle.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.bottle.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    copyItemToVenue() {
      api.copyDataToVenue({
        'origin_venue_id': this.venue.id,
        'destination_venue_id': this.venueToCopyItem.id,
        'data_filter': {
          type: 'filtered_items',
          item_ids: [this.bottle.item_id]
        }
      }).then(() => {
        this.notify({
          message: this.translations.confirmCopyDataToVenue,
          type: 'success'
        })
      })
    }
  },
  beforeUnmount() {
    this.bottle = null
    clearTimeout(this.dymoCheckTimeoutId)
  },
  watch: {
    venue: {
      immediate: true,
      handler() {
        if (!isLocalhost && this.venue?.print_barcode_configs?.length && this.venue.print_barcode_configs[0]) {
          this.dymoCheckTimeoutId = setTimeout(() => {
            dymoPrinterSetup(get(this.venue, 'print_barcode_configs[0].software', 'label'))
              .then(() => {
                this.dymoPrinterAvailable = true
              })
              .catch(() => { })
          }, 1000)
        } else {
          this.dymoPrinterAvailable = false
        }
      }
    },
    bottle: {
      handler(bottle) {
        if (bottle) {
          console.log('bottle', JSON.parse(JSON.stringify(bottle)))
        }
      },
      immediate: true
    },
    editAction: {
      handler() {
        this.selectedEditorTab = 0

        if (this.editAction) {
          this.forcedOperations = []

          if (this.editAction.itemId || this.editAction.item_id) {
            const id = this.editAction.itemId || this.editAction.item_id
            if (this.bottlesById[id]) {
              this.bottle = merge({}, this.bottlesById[id])
            } else if (this.editAction.item) {
              this.bottle = merge({}, this.editAction.item)
            }
          } else if (this.editAction.item) {
            this.bottle = merge({}, this.editAction.item, {
              item_id: this.editAction.readonly ? this.editAction.item.item_id : 0,
              bottle_id: this.editAction.item.item_id || 0,
              item_distributor_ids: this.editAction.item.item_distributor_ids || []
            })
            if (this.bottle.distributor_id) {
              this.bottle.distributor = this.distributorsById[this.bottle.distributor_id]
            }
          } else {
            this.bottle = { item_id: 0, item_distributor_ids: [] }

            if (this.editAction.title) {
              this.forcedOperations.push({ type: 'title', value: this.editAction.title })
              this.bottle.title = this.editAction.title
            }
            if (this.editAction.distributor_id) {
              this.forcedOperations.push({ type: 'distributor_id', value: this.editAction.distributor_id })
              this.bottle.distributor = this.distributorsById[this.editAction.distributor_id]
              this.bottle.distributor_id = this.editAction.distributor_id
            }
            if (this.editAction.distributorId) {
              this.forcedOperations.push({ type: 'distributor_id', value: this.editAction.distributorId })
              this.bottle.distributor = this.distributorsById[this.editAction.distributorId]
              this.bottle.distributor_id = this.editAction.distributorId
            }
            if (this.editAction.distributorCode) {
              this.forcedOperations.push({ type: 'distributor_code', value: this.editAction.distributorCode })
              this.bottle.distributor_code = this.editAction.distributorCode
            }
            if (this.editAction.caseSize) {
              this.forcedOperations.push({ type: 'case_size', value: this.editAction.caseSize })
              this.bottle.case_size = this.editAction.caseSize
            }
            if (this.editAction.case_size) {
              this.forcedOperations.push({ type: 'case_size', value: this.editAction.case_size })
              this.bottle.case_size = this.editAction.case_size
            }
            if (this.editAction.measurement) {
              this.forcedOperations.push({ type: 'measurement', value: this.editAction.measurement })
              this.bottle.measurement = this.editAction.measurement
            }
          }

          if (this.editAction.variantId) {
            this.selectedVariantId = this.editAction.variantId
            this.bottle.item_distributor_id = this.editAction.variantId
          }

          if (this.bottle && !this.bottle.custom_fields) {
            this.bottle.custom_fields = []
          }
          if (this.bottle && this.bottle.distributor_id) {
            this.bottle.distributor = this.distributorsById[this.bottle.distributor_id]
          }
        } else {
          this.bottle = null
        }
        console.log(' this.bottle', this.bottle)
      },
      immediate: true
    },
    bottlesById() {
      let itemId = this.editAction.itemId || this.editAction.item_id
      if (itemId) {
        this.bottle = merge({}, this.bottlesById[itemId])
      }
    }
  }
}
</script>

<style>
.wisk-modal-container.bottle-edit .wisk-modal.no-footer .modal-dialog .modal-body {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
</style>
