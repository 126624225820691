/* eslint-disable camelcase */
import label_19x51 from '@/modules/dymoPrinter/labels/19x51.xml?raw'
import label_25x54 from '@/modules/dymoPrinter/labels/25x54.xml?raw'
import label_28x89 from '@/modules/dymoPrinter/labels/28x89.xml?raw'
import label_36x89 from '@/modules/dymoPrinter/labels/36x89.xml?raw'
import label_51x89 from '@/modules/dymoPrinter/labels/51x89.xml?raw'
import label_54x70 from '@/modules/dymoPrinter/labels/54x70.xml?raw'
import label_54x101 from '@/modules/dymoPrinter/labels/54x101.xml?raw'
import label_19x51_old from '@/modules/dymoPrinter/labels/19x51_old.xml?raw'
import label_25x54_old from '@/modules/dymoPrinter/labels/25x54_old.xml?raw'
import label_28x89_old from '@/modules/dymoPrinter/labels/28x89_old.xml?raw'
import label_36x89_old from '@/modules/dymoPrinter/labels/36x89_old.xml?raw'
import label_51x89_old from '@/modules/dymoPrinter/labels/51x89_old.xml?raw'
import label_54x70_old from '@/modules/dymoPrinter/labels/54x70_old.xml?raw'
import label_54x101_old from '@/modules/dymoPrinter/labels/54x101_old.xml?raw'
import dymoWorkerInit from './dymoWorkerInit'

let printerName = null,
  labelTemplates = {
    label_19x51,
    label_25x54,
    label_28x89,
    label_36x89,
    label_51x89,
    label_54x70,
    label_54x101,
    label_19x51_old,
    label_25x54_old,
    label_28x89_old,
    label_36x89_old,
    label_51x89_old,
    label_54x70_old,
    label_54x101_old
  },
  dymoIframe = null,
  iframeId = 'dymoIframe',
  okFromWorker = false

const postMessageToWorker = dymoWorkerInit(e => {
  let message = e.data

  if (message === 'available') {
    okFromWorker = true
  }
  if (message === 'unavailable') {
    okFromWorker = false
  }
})

const setupIframe = software => {
  const existingIframe = document.getElementById(iframeId)
  if (existingIframe) {
    existingIframe.remove()
  }

  dymoIframe = document.createElement('iframe')
  dymoIframe.style.display = 'none'
  dymoIframe.id = iframeId

  const scriptToLoad = software === 'connect' ? '/dymo/dymo.connect.framework.min.js' : '/dymo/DYMO.Label.Framework.latest.js'

  dymoIframe.onload = () => {
    const scriptEl = dymoIframe.contentWindow.document.createElement('script')
    scriptEl.src = scriptToLoad

    scriptEl.onload = () => {
      window.top.dymo = window.dymo
    }

    window.document.body.appendChild(scriptEl)
  }

  document.body.appendChild(dymoIframe)
}

export const dymoPrinterSetup = software =>
  new Promise((resolve, reject) => {
    let retryCount = 10

    postMessageToWorker('check')

    const checkPrintersAvailable = () => {
      if (!dymoIframe && okFromWorker) {
        setupIframe(software)
      }

      if (dymoIframe && window.dymo) {
        const printers = window?.dymo?.label?.framework?.getPrinters()

        if (printers && printers.length) {
          printerName = printers[0].name
        }
        resolve()
      } else if (retryCount > 0) {
        retryCount--
        setTimeout(checkPrintersAvailable, 700)
      } else {
        reject()
      }
    }

    setTimeout(() => {
      checkPrintersAvailable()
    }, 500)
  })

export const printBarcode = ({ title = '', barcode, labelTemplate, software = '' }) => {
  if (printerName && barcode && labelTemplates && labelTemplates[labelTemplate]) {
    let suffix = software === 'connect' ? '' : '_old',
      label = window?.dymo?.label?.framework?.openLabelXml(labelTemplates[labelTemplate + suffix])

    if (label && printerName) {
      label.setObjectText('Title', title)
      label.setObjectText('Barcode', barcode)
      label.print(printerName)
    }
  }
}

export const testDymoPrinterAvailable = () => !!printerName
