<template>
  <div class="wisk-grid-views" :style="{ height: `${gridHeight}px` }">
    <div class="overflow-auto" style="height: calc(100% - 49px);">
      <div class="px-3">
        <header class="dropdown-header">
          <span class="mb-2 d-inline-block" :class="{ 'mb-2': user.god_mode }">
            {{ translations.txtWiskItemsGridGroupSuggested }}
          </span>

        </header>
        <div v-if="systemViews.length" class="position-relative">
          <div v-for="item in systemViews" :key="item.id" class="system-view-item my-0">
            <div class="btn-group w-100 my-0" role="group">
              <b-button block class="py-2 text-start rounded-0 my-0 border-end-0" :variant="item.id === currentView.id ? 'primary selected' : 'outline-primary'" @click="select(item.id)">
                <span>
                  {{ item.title }}
                </span>
              </b-button>
              <popoverDropdown :variant="item.id === currentView.id ? 'primary selected' : 'link'" buttonClass="my-0 py-0 px-2 w-100 h-100 d-block rounded-0 border-start-0"
                noCaret class="d-block position-relative wisk-more-info" :items="getSystemViewActions(item)">
                <template v-slot:button-content>
                  <icon name="wisk-more-info" :scale="0.8" />
                </template>
              </popoverDropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="px-3 mt-2" v-if="userViews.length" :class="{ 'can-save': currentPermissionsByType.grid_view_manage }">
        <header class="dropdown-header">{{ translations.txtWiskItemsGridGroupMyViews }}</header>
        <draggable group="columns-panel" class="" v-model="userViews" v-bind="dragOptionsUser" @end="saveSortOrder" handle=".drag-handle" itemKey="id">
          <template #item="{ element: item }">
            <div class="custom-view-item my-0" :class="{ 'can-sort': currentPermissionsByType.grid_view_manage }">
              <div class="btn-group w-100 my-0" role="group">
                <b-button :variant="item.id === currentView.id ? 'primary selected' : 'link'"
                  class="rounded-0 my-0 border-end-0 py-0 px-2 drag-handle" :title="translations.txtGenericDragSort">
                  <icon name="wisk-rearrange" :scale="0.8"></icon>
                </b-button>
                <b-button block class="py-2 text-start rounded-0 my-0 border-start-0 border-end-0" :variant="item.id === currentView.id ? 'primary selected' : 'outline-primary'" @click="select(item.id)" style="text-overflow: ellipsis; overflow: hidden;">
                  <span>
                    {{ item.title }}
                    <span v-if="user.god_mode">
                      {{ !item.id.includes('-') && !item.configs?.system ? ' *** - to be saved as system' : '' }}
                    </span>
                  </span>
                </b-button>
                <popoverDropdown :variant="item.id === currentView.id ? 'primary selected' : 'link'" buttonClass="my-0 py-0 px-2 w-100 h-100 d-block rounded-0 border-start-0"
                  noCaret class="d-block position-relative wisk-more-info" :items="getUserViewActions(item.id)">
                  <template v-slot:button-content>
                    <icon name="wisk-more-info" :scale="0.8" />
                  </template>
                </popoverDropdown>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <b-button v-if="currentPermissionsByType.grid_view_manage" block class="text-center mt-2 addView" variant="link" @click="add">
        <icon name="wisk-plus" class="" />
        {{ translations.txtWiskGridAddViewTitle }}
      </b-button>
    </div>
    <confirm ref="confirmDialogViewName" autofocus :promptForTextLabel="translations.txtWiskGridAddViewLabel" :validations="namePromptValidations" />
    <confirm ref="confirmDialogViewNameChange" autofocus :promptForTextLabel="translations.txtWiskGridAddViewLabel" :validations="renamePromptValidations" />
    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import draggable from 'vuedraggable'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import { guid, compareNumbers } from '@/modules/utils'
import { setDocument } from '@/firebase'
import popoverDropdown from '@/components/common/PopoverDropdown'

export default {
  name: 'WiskGridViews',
  components: { draggable, popoverDropdown },
  emits: ['addView', 'cloneView', 'select', 'saveSortOrder', 'saveView', 'removeView'],
  props: {
    items: Array,
    currentView: Object,
    addNewItem: Object,
    gridName: String,
    gridHeight: Number
  },
  data() {
    return {
      renamePromptValidations: [],
      dragOptions: {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        ghostClass: 'ghost'
      },
      systemViews: [],
      userViews: []
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'currentPermissionsByType']),
    ...mapGetters(['venue']),
    dragOptionsUser() {
      return merge({}, this.dragOptions, {
        disabled: this.userViews.length < 2 || !this.currentPermissionsByType.grid_view_manage
      })
    },
    namePromptValidations() {
      return [
        {
          message: this.translations.txtWiskGridAddViewValidationExists,
          validator: value => !this.items.find(v => v.title.trim().toLowerCase() === value.trim().toLowerCase())
        }
      ]
    },
    domId() {
      return 'z-' + (this.label || '').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getUserViewActions(id) {
      let arr = [
        {
          id,
          title: this.translations.txtGenericRename,
          disabled: !this.currentPermissionsByType.grid_view_manage,
          icon: 'wisk-edit',
          variant: 'primary',
          action: this.rename
        },
        {
          id,
          title: this.translations.txtGenericClone,
          disabled: !this.currentPermissionsByType.grid_view_manage,
          icon: 'wisk-copy',
          variant: 'primary',
          action: this.clone
        },
        {
          id,
          title: this.translations.txtGenericRemove,
          disabled: !this.currentPermissionsByType.grid_view_manage,
          icon: 'wisk-trash',
          class: 'mt-2 pt-2 border-top',
          variant: 'danger',
          action: this.remove
        }
      ]

      return arr
    },
    getSystemViewActions(item) {
      let arr = [
        {
          id: item.id,
          title: this.translations.txtGenericClone,
          disabled: !this.currentPermissionsByType.grid_view_manage,
          icon: 'wisk-ungroup-object',
          variant: 'primary',
          action: this.clone
        }
      ]

      if (this.user.god_mode) {
        arr.push({
          id: item,
          title: 'Copy JSON file name',
          icon: 'wisk-file',
          variant: 'primary',
          action: this.getSystemViewFileName
        })
        arr.push({
          id: item.id,
          title: 'Copy view ID',
          icon: 'wisk-copy',
          variant: 'primary',
          action: this.getSystemViewId
        })

        arr.push({
          id: item.id,
          title: 'Copy URL',
          icon: 'wisk-link',
          variant: 'primary',
          action: this.getSystemViewURL
        })

        arr.push({
          id: item.id,
          title: 'Edit',
          icon: 'wisk-edit',
          variant: 'primary',
          action: this.openEditSystemView
        })
      }

      return arr
    },
    openEditSystemView(id) {
      if (id) {
        this.setGlobalAction({ type: 'gridViewEdit', action: { id } })
      }
    },
    getSystemViewFileName(view) {
      toClipboard(`${this.gridName}.${view.id}.json`)
    },
    getSystemViewId(id) {
      toClipboard(id)
    },
    getSystemViewURL(id) {
      toClipboard(`${window.location.href}?viewId=${id}`)
    },
    clone(id) {
      this.$emit('cloneView', id)
    },
    remove(id) {
      let found = this.items.find(v => v.id === id),
        message = this.translations.translate('tplWiskGridRemoveViewMessage', { '{a}': found.title }),
        canSave = this.currentPermissionsByType.grid_view_manage

      if (!canSave) {
        return
      }

      this.$refs.confirmDialog.confirm({
        message,
        callback: () => {
          this.$emit('removeView', id)
        }
      })
    },
    promptForNewTitle(message, text) {
      this.renamePromptValidations = [
        {
          message: this.translations.txtWiskGridAddViewValidationExists,
          validator: value => text === value || !this.items.find(v => v.title.trim().toLowerCase() === value.trim().toLowerCase())
        }
      ]

      return new Promise(resolve => {
        if (this.$refs.confirmDialogViewNameChange) {
          this.$refs.confirmDialogViewNameChange.prompt({
            callback: (title = '') => {
              title = title.trim()
              if (title) {
                resolve(title)
              }
            },
            title: this.translations.txtGenericRename,
            required: true,
            text,
            message
          })
        }
      })
    },
    rename(id) {
      let found = this.items.find(v => v.id === id)

      if (found) {
        let message = this.translations.translate('tplWiskGridRenameViewMessage', { '{a}': found.title }),
          text = found.title

        this.promptForNewTitle(message, text).then(title => {
          this.save({ ...found, title })
        })
      }
    },
    saveSortOrder() {
      let views = this.addSortOrder(this.userViews)
      this.$emit('saveSortOrder', { views, system: false })
    },
    add() {
      if (this.$refs.confirmDialogViewName && this.currentPermissionsByType.grid_view_manage) {
        this.$refs.confirmDialogViewName.prompt({
          callback: (title = '') => {
            title = title.trim()
            if (title) {
              this.$emit('addView', title)
            }
          },
          title: this.translations.txtWiskGridAddViewTitle,
          text: '',
          required: true
        })
      }
    },
    select(id) {
      this.$emit('select', id)
    },
    save(view) {
      if (this.currentPermissionsByType.grid_view_manage) {
        this.$emit('saveView', view)
      }
    },
    checkSystemViewsTitlesTranslation() {
      this.systemViews.forEach(systemView => {
        let found = this.translations?.groupGridViews?.[`${this.gridName}_${systemView?.id}`]

        if (found && found !== systemView?.title && this.user?.language?.toLowerCase() === 'en' && (this.user?.is_wisk_user || this.user?.god_mode)) {
          let id = 'update_translations'
          setDocument(`settings/web/developerAlerts/${id}`, { id, message: 'Update translations - system view renamed', extraData: { gridName: this.gridName, viewId: systemView.id, oldTitle: systemView.title, newTitle: found } })
        }
        systemView.title = found || systemView.title
      })
    },
    addSortOrder(arr) {
      return arr.map((v, index) => ({ ...v, sortOrder: v.configs?.default ? 0 : (v.sortOrder || (index + 1)) }))
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        if (items) {
          let itemsCopy = items.map(v => merge({}, v)),
            systemViews = [],
            userViews = []

          itemsCopy.forEach(v => {
            if (v?.id && v.configs?.system) {
              systemViews.push(v)
            } else if (v?.id && v.id !== 'default' && !v.configs?.system) {
              userViews.push(v)
            }
          })

          systemViews = this.addSortOrder(systemViews)
          userViews = this.addSortOrder(userViews)
          systemViews.sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder))
          userViews.sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder))

          this.systemViews = systemViews
          this.userViews = userViews

          this.checkSystemViewsTitlesTranslation()
        }
      }
    },
    translations: 'checkSystemViewsTitlesTranslation'
  }
}
</script>

<style lang="scss">
.wisk-grid-views {
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 150px);
  width: 435px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  left: 0px;

  .wisk-grid-views-header {
    text-align: left;
    padding: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 49px;
    border-bottom: 0.25px solid var(--bs-border-color);

    svg {
      cursor: pointer;
    }
  }

  .dropdown-divider {
    border-color: #5e5e5e;
    margin: 0;
    margin-bottom: 10px;
  }

  .dropdown-header {
    font-size: 14px;
    text-align: left;
    padding: 10px 0;
    color: var(--bs-dark-text-emphasis);
  }

  .custom-view-item {
    position: relative;

    .btn {
      &:not(.selected) {
        border-color: var(--bs-border-color);
      }
    }

    .drag-handle svg,
    .wisk-more-info svg {
      opacity: 0;
    }

    &:hover {
      .wisk-more-info svg {
        opacity: 1;
      }
    }

    &.can-sort:hover {
      .drag-handle svg {
        opacity: 1;
      }
    }

    &:not(:last-child) .btn {
      border-bottom: none !important;
    }
  }

  .system-view-item {
    position: relative;

    .btn {
      &:not(.selected) {
        border-color: var(--bs-border-color);
      }
    }

    .drag-handle svg,
    .wisk-more-info svg {
      opacity: 0;
    }

    &:hover {
      .wisk-more-info svg {
        opacity: 1;
      }
    }

    &.can-sort:hover {
      .drag-handle svg {
        opacity: 1;
      }
    }

    &:not(:last-child) .btn {
      border-bottom: none !important;
    }
  }

  li {
    list-style: none;
  }

  .addView {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;

    svg {
      margin-left: 7px;
      padding: 3px;
      width: 18px;
      height: 18px;
    }
  }
}

.pinned {
  .wisk-grid-views {
    box-shadow: none; //0px 0px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--bs-border-color);
    width: 225px;

    .wisk-grid-views-header {
      height: 41px;
    }
  }
}
</style>
