<template>
  <component :is="tag" role="menuitem" :class="classList">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BDropdownItem',
  props: {
    variant: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    group: { type: Boolean, default: false },
    tag: { type: String, default: 'div' }
  },
  computed: {
    classList() {
      return {
        [`text-${this.variant}`]: this.variant && !this.disabled,
        disabled: this.disabled,
        'cursor-pointer dropdown-item': !this.group,
        active: this.active
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown-item {

  &:hover {
    background-color: var(--info);
  }
}
</style>
