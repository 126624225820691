<template>
  <div :key="key">
    <wiskInputGroup @errorCountChanged="newUserValid = !$event" v-if="localUser && localUser.email && user"
      @operationsChange="onChange">
      <b-row>
        <b-col v-if="!localUser.first_name && !localUser.last_name" sm="12" class="mb-1 animation attention">
          <b-alert variant="warning" show>
            <icon name="wisk-warning" :scale="0.8" class="text-danger me-1" />
            {{ translations.txtUsersCompleteInformation }}
          </b-alert>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskInput infoTooltipKey="1deb8ab3-75a8-433f-ab0e-ff3d8831214b" :label="translations.txtGenericFirstName" inputType="text" :disabled="!canEditFields"
            operation="first_name" :modelValue="localUser.first_name" required @operation="saveSingleOperation" ref="firstnameInput" />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskInput infoTooltipKey="8c59da75-e568-4915-a5ff-4ed0762ddb97" :label="translations.txtGenericLastName" inputType="text" :disabled="!canEditFields"
            operation="last_name" :modelValue="localUser.last_name" required @operation="saveSingleOperation" ref="lastnameInput" />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskInput infoTooltipKey="b3ef8245-cf39-4884-b73f-0904269b0f18" :label="translations.txtGenericEmail" inputType="text" v-if="isCurrentUser" operation="email" required
            :validations="[{ type: 'email', validator: value => isValidEmail(value), message: translations.txtValidationEmail }]"
            @operation="onChangeEmail" :modelValue="localUser.email" />

          <wiskInput infoTooltipKey="b77bcea4-873f-40f8-a89d-c7fa38874660" :label="translations.txtGenericEmail" inputType="text" v-if="!isCurrentUser && localUser.id" operation="email" required
            :validations="[{ type: 'email', validator: value => isValidEmail(value), message: translations.txtValidationEmail }]"
            @operation="onChangeEmailBySuperAdmin" :modelValue="localUser.email" :disabled="!user.god_mode" />

          <wiskInput infoTooltipKey="94de8603-0c44-4fba-8f07-68748ffaacbd" :label="translations.txtGenericEmail" inputType="text" v-if="!localUser.id && localUser.email" operation="email" required
            disabled :modelValue="localUser.email" triggerInputOnLoad />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskInput infoTooltipKey="369855d5-d7ee-43ae-9be1-21f53298195e" :label="translations.txtGenericPhone" inputType="text" :disabled="!canEditFields" @operation="saveSingleOperation"
            :validations="[{ type: 'phone', validator: value => !value || isValidPhoneNumber(value), message: translations.txtValidationPhone }]"
            operation="phone" :modelValue="localUser.phone" />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskSelect infoTooltipKey="1f2bbfc4-ada3-4d7c-91a9-12a4f20acad6" :label="translations.txtGenericRole" :modelValue="localUser.role" required operation="role_id"
            :disabled="(!currentPermissionsByType.user_role_manage || isCurrentUser) && !user.god_mode" :items="roles" :multiselectOptions="{}" @operation="saveSingleOperation" />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskSelect infoTooltipKey="d6a7c431-fff8-4b6b-b396-7e1e162223e7" :label="(translations.txtGenericLanguage === 'Language' && translations.txtGenericLanguage) || translations.txtGenericLanguage + ' / Language'"
            :modelValue="localUser.language" operation="language" :items="languages" :multiselectOptions="{}" @operation="saveSingleOperation" />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <wiskSelect v-if="isCurrentUser" infoTooltipKey="d416c3a2-89b4-431a-afee-26b119693126" :items="darkModeSelectOptions" @change="onDarkModeSelected" :modelValue="darkModeTheme"
            :label="translations.txtUsersDarkModeOptions" required />
        </b-col>
        <b-col sm="12" md="6" lg="3" v-if="localUser?.id && localUser?.email && user">
          <wiskInput infoTooltipKey="49091248-79f8-4101-9151-362a015594b9" :modelValue="''" :label="translations.txtGenericPassword" operation="" disabled showPlainText dummy
            style="" class="mb-3">
            <b-button @click="changePassword" v-if="isCurrentUser" class="" variant="primary" size="sm">
              {{ translations.txtAuthPassChange }}
            </b-button>
            <b-button @click="changePasswordBySuperAdmin" v-if="!isCurrentUser && user.god_mode && localUser.id" class="" variant="primary" size="sm">
              {{ translations.txtAuthPassChange }}
            </b-button>
          </wiskInput>
        </b-col>
        <b-col sm="12" md="6" lg="3" v-if="!localUser.id && localUser.email">
          <wiskInput infoTooltipKey="eaa8f78b-d8d4-43e8-ab4b-7f490332e29a" :label="translations.txtAuthPass1"
            operation="password" required :validations="newPasswordValidations" name="new-password" :showPlainText="false" />
        </b-col>
      </b-row>
      <b-row v-if="user.god_mode">
        <b-col class="flex-grow-1 d-flex align-items-center">
          <wiskInput :label="translations.txtGenericSuperUser" :modelValue="localUser.god_mode"
            @operation="saveSingleOperation" inputType="checkbox" operation="is_super_user"
            infoTooltipKey="edd5e25c-cb70-46b3-9be7-973a397130bf" class="me-2" />
          <wiskInput :label="translations.txtUsersInventoryTrainingCompleted" :modelValue="localUser.inventory_training_completed"
            @operation="saveSingleOperation" inputType="checkbox" operation="inventory_training_completed"
            infoTooltipKey="40cc1778-c43f-4cff-b686-c0fb25d01ddc" class="me-2" />
          <wiskInput :label="translations.txtUsersFoodInventoryTrainingCompleted" :modelValue="localUser.food_inventory_training_completed"
            @operation="saveSingleOperation" inputType="checkbox" operation="food_inventory_training_completed"
            infoTooltipKey="8af321cb-5100-4af8-9ebf-65da13e8f392" class="me-2" />
        </b-col>
      </b-row>

      <wiskInput infoTooltipKey="674b074b-8197-47f4-8a14-cecfcc89160e" v-if="localUser && localUser.id && localUser.user_venues" :modelValue="''" :label="translations.txtMenuVenues" operation="" disabled showPlainText dummy :key="userVenuesKey">
        <wiskInput infoTooltipKey="af66180c-1871-4099-a755-252e52b12275" hidden v-if="editAction.venueId" operation="assign_to_venue_id" :modelValue="editAction.venueId" triggerInputOnLoad />

        <userVenues :localUser="localUser" @change="$emit('userVenuesChanged')" />
      </wiskInput>

      <wiskLoading :loading="loading" />
    </wiskInputGroup>

    <userPasswordPrompt ref="userPasswordPrompt" />

    <confirm ref="confirmDialog" promptForTextType="password" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth'
import api from '@/api'
import { isValidEmail, isValidPhoneNumber } from '@/modules/utils'
import { setPreferences } from '@/modules/storage'
import { AUTH } from '@/firebase'
import userPasswordPrompt from '@/components/users/UserPasswordPrompt'
import userVenues from '@/components/users/UserVenues'
import wiskSelect from '@/components/common/WiskSelect'
import wiskInput from '@/components/common/WiskInput'

const newUser = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  role: ''
}

export default {
  name: 'UserEditOperations',
  emits: ['userVenuesChanged', 'change'],
  components: { wiskSelect, wiskInput, userPasswordPrompt, userVenues },
  props: { editAction: Object, userFromParent: Object },
  data() {
    return {
      operations: [],
      localUser: null,
      userVenuesKey: 1,
      newUserValid: true,
      isValidPhoneNumber,
      isValidEmail,
      key: 1,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['languages', 'venue', 'permissionsByVenueIdByType']),
    ...mapState(['user', 'translations', 'firebaseUser', 'rolesByVenueId', 'permissionsById', 'currentPermissionsByType', 'darkModeTheme']),
    isCurrentUser() {
      return this.user.id === this.localUser.id
    },
    darkModeSelectOptions() {
      //0 light, 1 dark, 2 auto
      return [
        { id: 0, title: this.translations.txtUsersDarkModeOptionsLight },
        { id: 1, title: this.translations.txtUsersDarkModeOptionsDark },
        { id: 2, title: this.translations.txtUsersDarkModeOptionsAuto }
      ]
    },
    roles() {
      if (this.venueId) {
        return this.rolesByVenueId[this.venueId]
      }
      return []
    },
    canEditFields() {
      return this.user.god_mode || this.isCurrentUser || !this.localUser.id
    },
    newPasswordValidations() {
      return [{ type: 'length', validator: value => value && value.length > 5, message: this.translations.txtAuthPassLength }]
    },
    venueId() {
      if (this.editAction && this.editAction.venueId) {
        return this.editAction.venueId
      }
      return this.venue && this.venue.id
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify', 'setUser', 'updateVenue', 'setDarkModeTheme']),
    save(id, operation) {
      return new Promise((resolve, reject) => {
        this.setUser({ id, operation })
          .then((user) => {
            if (this.editAction.onChange) {
              this.editAction.onChange(id)
            }

            user.god_mode = user.god_mode || user.is_super_user
            this.localUser = merge({}, newUser, user || {})

            if (this.isCurrentUser && !user.first_name && !user.last_name) {
              this.setGlobalAction({ type: 'userEdit', action: { user, userId: user.id, resetBeforeOpen: true } })
            }

            resolve()
          })
          .catch((e) => {
            console.log('save user error', e)
            reject(e)
          })
      })
    },
    onDarkModeSelected(value) {
      this.setDarkModeTheme(value.id)
      setPreferences('darkModeTheme', value.id)
    },
    saveSingleOperation(operation) {
      if (this.localUser?.id) {
        this.save(this.localUser.id, operation)
      }
    },
    onChange(operations) {
      this.operations = [...operations]
      this.$emit('change', this.operations)
    },
    changePasswordBySuperAdmin() {
      if (this.user.god_mode && this.$refs.userPasswordPrompt) {
        this.$refs.userPasswordPrompt.promptForNewPassword(newPassword => {
          if (newPassword) {
            this.save(this.localUser.id, { type: 'password', value: newPassword })
          }
        })
      }
    },
    onChangeEmailBySuperAdmin(operation) {
      if (this.$refs.confirmDialog && operation) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save(this.localUser.id, operation)
          },
          message: this.translations.txtAuthEmailChangeBySuperAdminConfirm,
          title: this.translations.confirmOperationTitle,
          cancelCallback: () => {
            this.onChange([])
            this.key++
          }
        })
      }
    },
    onChangeEmail(operation) {
      if (isValidEmail(operation.value)) {
        this.signInRefresh()
          .then(result => {
            if (result) {
              this.save(this.localUser.id, operation)
            }
          })
          .catch(() => {
            this.key++
          })
      }
    },
    changePassword() {
      this.signInRefresh().then(result => {
        if (result && this.$refs.userPasswordPrompt) {
          this.$refs.userPasswordPrompt.promptForNewPassword(newPassword => {
            if (newPassword) {
              updatePassword(this.firebaseUser, newPassword)
                .then(() => {
                  this.notify({ title: this.translations.txtGenericSuccess, message: this.translations.txtAuthPassChangedMessage, type: 'success' })
                })
                .catch(error => {
                  this.notify({ title: this.translations.txtGenericError, message: error.message, type: 'error' })
                  console.log('error', error)
                })
            }
          })
        }
      })
    },
    signInRefresh() {
      return new Promise((resolve, reject) => {
        if (this.isCurrentUser && this.firebaseUser && this.user && this.user.email && this.$refs.confirmDialog) {
          this.$refs.confirmDialog.prompt({
            callback: password => {
              let credentials = EmailAuthProvider.credential(this.firebaseUser.email, password)

              reauthenticateWithCredential(AUTH.currentUser, credentials)
                .then(result => {
                  resolve(result)
                })
                .catch(error => {
                  this.notify({ title: this.translations.txtGenericError, message: error.message, type: 'error' })
                  reject(error)
                  console.log('error', error)
                })
            },
            message: this.translations.txtAuthPassInputCurrent,
            title: this.translations.txtAuthConfirmPass,
            required: true
          })
        } else {
          console.warn(
            'missing data one of these: this.firebaseUser , this.user ,this.user.email , this.$refs.confirmDialog',
            this.firebaseUser,
            this.user,
            this.user.email,
            this.$refs.confirmDialog
          )
          reject()
        }
      })
    },
    unsubscribeAllEmailReports() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.removeAllEmailReportsForUser().then((response) => {
              this.localUser = merge({}, this.localUser, { user_venues: response })
            })
          },
          message: this.translations.txtUsersUnsubscribeConfirmationMessage,
          title: this.translations.txtUsersUnsubscribeAllEmailReports,
        })
      }
    }
  },
  watch: {
    editAction: {
      handler(editAction) {
        if (editAction) {
          this.localUser = merge({}, newUser, editAction.user || {})
          this.userVenuesKey++

          if (editAction.userId && !this.userFromParent) {
            api.user(editAction.userId).then(user => {
              user.god_mode = user.god_mode || user.is_super_user
              this.localUser = merge({}, newUser, user || {})
            })
          }
          if (this.userFromParent) {
            this.localUser = merge({}, newUser, this.userFromParent || {}, { god_mode: this.userFromParent.god_mode || this.userFromParent.is_super_user })
          }
          if (!this.localUser.first_name || !this.localUser.last_name) {
            setTimeout(() => {
              if (this.$refs.firstnameInput && this.$refs.lastnameInput) {
                this.$refs.firstnameInput.triggerValidationCheck()
                this.$refs.lastnameInput.triggerValidationCheck()
              }
            }, 500)
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss"></style>
