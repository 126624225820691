export default {
  apiKey: 'AIzaSyBzGAdwDgzLn2hhlVdy7WnztKDFvRA84TY',
  authDomain: 'wisk-162305.firebaseapp.com',
  databaseURL: 'https://wisk-162305.firebaseio.com',
  projectId: 'wisk-162305',
  storageBucket: 'wisk-162305.appspot.com',
  messagingSenderId: '661464495930',
  appId: '1:661464495930:web:03134bb9bd1897cb210203',
  measurementId: 'G-20BHN53F2P'
}
