<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'organizationEdit', action: null })" :title="modalTitle" size="md" :hideFooter="!!organization?.id"
    :warning="!!organization?.archived">

    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="organization?.id" />
    </template>

    <wiskInputGroup @errorCountChanged="setValidState" v-if="organization" @operationsChange="operations = $event" :key="key">
      <wiskInput infoTooltipKey="3e196267-af2a-4c06-bf25-5c09a53512d9" :label="translations.txtGenericTitle" required autofocus
        operation="title" :modelValue="organization.title" @operation="onChange" :triggerInputOnLoad="!!editAction.title" />

      <wiskInput infoTooltipKey="1087e4d6-e6a1-420a-9c63-990b00f3ebcb" :label="translations.txtOrganizationsTotalVenues" inputType="number"
        operation="total_venues" :modelValue="organization.total_venues" @operation="onChange" />
    </wiskInputGroup>

    <wiskLoading :loading="loading" />
    <confirm ref="confirmDialog" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="!loading && valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'

const newOrganization = {
  id: 0,
  title: '',
  total_venues: 0
}

export default {
  name: 'OrganizationEdit',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      organization: null,
      operations: [],
      key: 0,
      valid: true,
      loading: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    modalTitle() {
      if (this.organization) {
        let organization = this.organization.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.organization.id ? this.organization.title + organization : this.translations.txtOrganizationsNew
      }
      return ''
    },
    pageActions() {
      if (this.organization) {
        return [
          { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.organization.archived, action: this.archive },
          { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.organization.archived, action: this.restore }
        ]
      }
      return []
    },
  },
  methods: {
    ...mapActions(['setGlobalAction', 'loadVenuesHighLevel']),
    getData() {
      this.loading = true
      let id = this.editAction?.id || this.editAction?.organization?.id

      api.organizationDetails(id).then(organization => {
        this.organization = organization

        if (!this.organization.id) {
          console.error('organization not found for id ' + id + ' in organizations list', this.organizations)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onChange(operation) {
      if (this.organization?.id) {
        this.save(operation)
      }
    },
    save(operations = this.operations) {
      api.updateOrganization(this.organization.id, operations).then(updated => {

        if (!this.organization.id && updated && updated.id) {
          this.setGlobalAction({ type: 'organizationEdit', action: null })

          setTimeout(() => {
            this.setGlobalAction({ type: 'organizationEdit', action: { organization: updated } })
          }, 0)
        }

        if (typeof this.editAction.onChange === 'function') {
          this.editAction.onChange(updated)
        }

        this.organization = { ...updated }
      })
    },
    archive() {
      if (this.$refs.confirmDialog && this.organization?.id) {
        this.$refs.confirmDialog.confirm({
          title: this.translations.confirmArchiveTitle,
          message: this.translations.txtOrganizationsArchiveConfirm,
          callback: async () => {
            this.save([{ type: 'archive', value: true }])
          }
        })
      }
    },
    restore() {
      this.save([{ type: 'archive', value: false }])
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          if (editAction.id === 0) {
            this.organization = { ...newOrganization, title: editAction.title || '' }

            if (editAction.title) {
              this.key++
            }
          } else {
            this.getData()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
