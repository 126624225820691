export default {
  name: 'wisk-group-object',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.876,3.222V1.357A1.364,1.364,0,0,0,33.519,0H31.654A1.363,1.363,0,0,0,30.3,1.305H10.573A1.354,1.354,0,0,0,9.216,0H7.351A1.353,1.353,0,0,0,6.007,1.357V3.222A1.354,1.354,0,0,0,7.312,4.579V7.436H4.69a1.354,1.354,0,0,0-1.357-1.3H1.468A1.353,1.353,0,0,0,.124,7.488V9.353a1.354,1.354,0,0,0,1.3,1.357V30.421a1.354,1.354,0,0,0-1.3,1.357v1.865A1.353,1.353,0,0,0,1.468,35H3.333A1.354,1.354,0,0,0,4.69,33.7H24.414A1.363,1.363,0,0,0,25.771,35h1.865a1.364,1.364,0,0,0,1.357-1.357V31.778a1.365,1.365,0,0,0-1.318-1.357V27.564H30.3a1.363,1.363,0,0,0,1.357,1.3h1.865a1.364,1.364,0,0,0,1.357-1.357V25.647a1.365,1.365,0,0,0-1.317-1.357V4.579A1.365,1.365,0,0,0,34.876,3.222ZM3.385,33.643a.056.056,0,0,1-.052.052H1.428V31.778c0-.026.013-.052.04-.052H3.333a.056.056,0,0,1,.052.052Zm0-24.29a.056.056,0,0,1-.052.052H1.468c-.027,0-.04-.026-.04-.052V7.436H3.333a.056.056,0,0,1,.052.052ZM9.269,3.222a.057.057,0,0,1-.053.052H7.351c-.026,0-.039-.026-.039-.052V1.305h1.9a.057.057,0,0,1,.053.052ZM27.688,31.778v1.865c0,.013-.013.026-.013.04V33.7h-1.9a.057.057,0,0,1-.053-.052V31.778a.057.057,0,0,1,.053-.052h1.865a.048.048,0,0,1,.039.013C27.675,31.752,27.688,31.765,27.688,31.778Zm5.884-6.131v1.865c0,.013-.013.026-.013.039v.013h-1.9a.056.056,0,0,1-.052-.052V25.647a.056.056,0,0,1,.052-.052h1.865a.051.051,0,0,1,.04.013C33.559,25.621,33.572,25.634,33.572,25.647ZM33.519,3.274H31.654a.056.056,0,0,1-.052-.052V1.357a.056.056,0,0,1,.052-.052h1.9v.013c0,.013.013.026.013.039V3.222c0,.013-.013.026-.013.039S33.532,3.274,33.519,3.274Z'
    }
  ]
}
