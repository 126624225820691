export default {
  name: 'wisk-trash',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M14.8,2.9c-0.8,0-1.4,0.7-1.4,1.5h8.2c0-0.8-0.6-1.4-1.4-1.5H14.8z M24.4,4.4C24.4,2,22.6,0.1,20.2,0h-5.5 c-2.3,0.1-4.2,2-4.1,4.4H5.1c-0.8,0-1.4,0.7-1.4,1.5c0,0.8,0.6,1.4,1.4,1.5h24.8c0.8,0,1.4-0.7,1.4-1.5c0-0.8-0.6-1.4-1.4-1.5H24.4 z'
    },
    { d: 'M14,14.6c0.8,0,1.4,0.6,1.5,1.4c0,0,0,0,0,0l0.7,11.4c0.1,0.8-0.5,1.5-1.3,1.5c-0.8,0-1.4-0.6-1.5-1.3 c0,0,0,0,0,0l-0.7-11.4C12.6,15.4,13.2,14.7,14,14.6z' },
    { d: 'M21,14.6c-0.8,0-1.4,0.6-1.5,1.4c0,0,0,0,0,0l-0.7,11.4c-0.1,0.8,0.5,1.5,1.3,1.5c0.8,0,1.4-0.6,1.5-1.3 c0,0,0,0,0,0l0.7-11.4C22.4,15.4,21.8,14.7,21,14.6z' },
    {
      d: 'M5.2,11.2C5.1,10,5.9,8.9,7,8.8c0.1,0,0.2,0,0.2,0h20.4c1.2,0,2.1,1,2.1,2.2c0,0.1,0,0.2,0,0.2l-2.4,20 c-0.2,2.1-2,3.8-4.1,3.8H11.7c-2.1-0.1-3.9-1.7-4.1-3.8L5.2,11.2z M8.1,11.7l2.3,19.1c0.1,0.7,0.7,1.3,1.4,1.3h11.6 c0.7,0,1.3-0.6,1.4-1.3l2.3-19.1H8.1z'
    }
  ]
}
