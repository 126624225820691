/**
 * Organization:
 * Group all translations related to the same entity in the same section of the file for easy management and retrieval.
 * Ensure consistent naming for the entity across all related translations.
 *
 * Naming and Structure:
 * - Use txtEntityIdentifier for simple strings: 'Text'
 * - Use tplEntityIdentifier for template strings: 'Text with {a} and {b}'
 * - Group related translations under groupEntity:
 *   groupEntity: {
 *     identifier1: 'Text',
 *     identifier2: 'Text',
 *   }
 */

/* eslint-disable no-template-curly-in-string */
const translations = {
  //generic
  txtGenericUser: 'User',
  txtGenericUsers: 'Users',
  txtGenericUsername: 'Username',
  txtGenericPassword: 'Password',
  txtGenericCredentials: 'Credentials',
  txtGenericDescription: 'Description',
  txtGenericAddDescription: 'Add Description',
  txtGenericLearnMore: 'Learn more...',
  txtGenericLoadMore: 'Load more...',
  txtGenericSelection: 'Selection',
  txtGenericViewer: 'Viewer',
  txtGenericMain: 'Main',
  txtGenericDownload: 'Download',
  txtGenericPlatform: 'Platform',
  txtGenericEvent: 'Event',
  txtGenericMin: 'Min',
  txtGenericMax: 'Max',
  txtGenericOK: 'OK',
  txtGenericSend: 'Send',
  txtGenericDismiss: 'Dismiss',
  txtGenericUM: 'UM',
  txtGenericGetData: 'Fetch Data',
  txtGenericRunReport: 'Run Report',
  txtGenericGroupTitleMissing: 'Other/Missing',
  txtGenericYes: 'Yes',
  txtGenericNo: 'No',
  txtGenericId: 'Id',
  txtGenericSort: 'Sort',
  txtGenericSortAsc: 'Sort ascending',
  txtGenericSortDesc: 'Sort descending',
  txtGenericSortClear: 'Clear sort',
  txtGenericAggregation: 'Aggregation',
  txtGenericApiKey: 'API Key',
  txtGenericVenueApiKey: 'Venue API Key',
  txtGenericCreate: 'Create',
  txtGenericCreated: 'Created',
  txtGenericCompleted: 'Completed',
  txtGenericConfirmation: 'Confirmation',
  txtGenericLanguage: 'Language',
  txtGenericContactUsTitle: 'Contact Us',
  txtGenericContactUs: 'Email Us',
  txtGenericSendUsEmail: 'Send us an email',
  txtGenericSendUsMessage: 'Send us a message',
  txtGenericTakeatour: 'Take a tour',
  txtGenericName: 'Name',
  txtGenericFirstName: 'First Name',
  txtGenericLastName: 'Last Name',
  txtGenericFullName: 'Full Name',
  txtGenericRole: 'Role',
  txtGenericReason: 'Reason',
  txtGenericPermission: 'Permission',
  txtGenericSymbol: 'Symbol',
  txtGenericCancel: 'Cancel',
  txtGenericHideAll: 'Hide all',
  txtGenericShowAll: 'Show all',
  txtGenericSelectAll: 'Select all',
  txtGenericUnSelectAll: 'Unselect all',
  txtGenericClose: 'Close',
  txtGenericUpload: 'Upload',
  txtGenericClickToHide: 'click to hide',
  txtGenericHide: 'Hide',
  txtGenericNotFound: 'Not Found',
  txtGenericNotSet: 'Not set',
  txtGenericActions: 'Actions',
  txtGenericRetry: 'Retry',
  txtGenericNext: 'Next',
  txtGenericPrevious: 'Previous',
  txtGenericStart: 'Start',
  txtGenericContinue: 'Continue',
  txtGenericEmptyList: 'Nothing to display',
  txtGenericModify: 'Modify',
  txtGenericCustomFilters: 'Predefined filters',
  txtGenericCustomRange: 'Custom range',
  txtGenericFilter: 'Filter',
  txtGenericFilters: 'Filters',
  txtGenericClickToSelect: 'Click row to select',
  txtGenericClickSelect: 'Select',
  txtGenericClickDeselect: 'Click to deselect',
  txtGenericSave: 'Save',
  txtGenericEdit: 'Edit',
  txtGenericView: 'View',
  txtGenericReport: 'Report',
  txtGenericOverview: 'Overview',
  txtGenericDate: 'Date',
  txtGenericCreatedAt: 'Created at',
  txtGenericTimestamp: 'Timestamp',
  txtGenericTime: 'Time',
  txtGenericAddedOn: 'Added On',
  txtGenericFromDate: 'From date',
  txtGenericStartDate: 'Start date',
  txtGenericStartedOn: 'started on',
  txtGenericEndDate: 'End date',
  txtGenericValue: 'Value',
  txtGenericUnits: 'units',
  txtGenericCases: 'cases',
  txtGenericPartial: 'partial',
  txtGenericSaveNext: 'Save & Next',
  txtGenericLabel: 'Label',
  txtGenericPlaceholder: 'Placeholder',
  txtGenericAll: 'All',
  txtGenericDetails: 'Details',
  txtGenericNumber: 'Number',
  txtGenericLineNumber: 'Line #',
  txtGenericDecimalPlaces: 'Decimal places',
  txtGenericExport: 'Export',
  txtGenericDefault: 'Default',
  txtGenericURL: 'Link',
  txtGenericReset: 'Reset',
  txtGenericHelpLink: 'Help Link',
  txtGenericTo: 'To',
  txtGenericFrom: 'From',
  txtGenericOf: 'of',
  txtGenericHere: 'here',
  txtGenericCurrency: '$',
  txtGenericCurrencyText: 'Currency',
  txtGenericDisplayCurrency: 'Display Currency',
  txtGenericAmount: 'Amount',
  txtGenericTaxRate: 'Tax Rate (ex 14.975, 13,...)',
  txtGenericEmail: 'Email',
  txtGenericEmailAdresses: 'Email Adresses',
  txtGenericSpreadsheet: 'Spreadsheet',
  txtGenericPDF: 'Download PDF',
  txtGenericText: 'Text',
  txtGenericTax: 'Tax',
  txtGenericNormalView: 'Normal View',
  txtGenericEmailAdressesInput: 'Input one or more email adresses',
  txtGenericPhoneNumberInput: 'Input one or more phone numbers',
  txtGenericInputManually: 'Input manually',
  txtGenericPhone: 'Phone Number',
  txtGenericCity: 'City',
  txtGenericAddress: 'Address',
  txtGenericState: 'State',
  txtGenericCountry: 'Country',
  txtGenericSubject: 'Subject',
  txtGenericAllButArchived: 'All (but archived)',
  txtGenericAllIncludingArchived: 'All (including archived)',
  txtGenericDelete: 'Permanently Delete',
  txtGenericArchive: 'Archive',
  txtGenericArchived: 'Archived',
  txtGenericRestore: 'Restore',
  txtGenericActivate: 'Activate',
  txtGenericDeactivate: 'Deactivate',
  txtGenericInactie: 'Inactive',
  txtGenericAddItem: 'Add Item',
  txtGenericAdd: 'Add',
  txtGenericError: 'Error',
  txtGenericErrorNo: 'No Error',
  txtGenericErrors: 'The following errors occurred',
  txtGenericRemove: 'Remove',
  txtGenericRename: 'Rename',
  txtGenericType: 'Type',
  txtGenericTimeZone: 'Time zone',
  txtGenericSubmit: 'Submit',
  txtGenericPreview: 'Preview',
  txtGenericUse: 'Use',
  txtGenericManage: 'Manage',
  txtGenericTimeline: 'Timeline',
  txtGenericTitleSearch: 'Title / Search',
  txtGenericTypeToSearch: 'Type to search',
  txtGenericNoResult: 'No result',
  txtGenericNoItems: 'no items',
  txtGenericGroup: 'Group',
  txtGenericStatusPending: 'Pending',
  txtGenericStatusProcessed: 'Processed',
  txtGenericStatusProcessing: 'Processing',
  txtGenericStatusInfoRequired: 'Information Required',
  txtGenericStatusSkipped: 'Skipped',
  txtGenericSkip: 'Skip',
  txtGenericBottleSearchPlaceholder: 'Search by item name ...',
  txtGenericBottleSearchPlaceholderByCode: 'Search by item code ...',
  txtGenericOrganization: 'Organization',
  txtGenericVenue: 'Venue',
  txtGenericVenues: 'Venues',
  txtGenericFindVenue: 'Find your venue on Google',
  txtGenericFindOnGoogle: 'Find on Google',
  txtGenericGooglePlacesId: 'Google Places Id',
  txtGenericPartnerVenue: 'Partner Venue',
  txtGenericDistributorCode: 'Distributor Code',
  txtGenericItemCode: 'Item Code',
  txtGenericCode: 'Code',
  txtGenericInfo: 'Info',
  txtGenericVariant: 'Variant',
  txtGenericVariants: 'Variants',
  txtGenericDistributor: 'Distributor',
  txtGenericNewDistributor: 'New Distributor',
  txtGenericCreateCategory: 'Create category',
  txtGenericDistributors: 'Distributors',
  txtGenericNoDistributor: 'No distributor',
  txtGenericExcludeFromVariance: 'Exclude from Variance',
  txtGenericExcludedFromVariance: 'Excluded from Variance',
  txtGenericIncludeInVariance: 'Include in Variance',
  txtGenericCopyToVenue: 'Copy to venue',
  txtGenericPrice: 'Price',
  txtGenericCost: 'Cost',
  txtGenericRetailPrice: 'Retail Price',
  txtGenericRetailValue: 'Retail Value',
  txtGenericDiscount: 'Discount',
  txtGenericPrices: 'Prices',
  txtGenericBatch: 'Batch',
  txtGenericNotes: 'Notes',
  txtGenericTransfers: 'Transfers',
  txtGenericAdjustments: 'Adjustments',
  txtGenericAdjustment: 'Adjustment',
  txtGenericDepletions: 'Depletions',
  txtGenericDepletion: 'Depletion',
  txtGenericIngredients: 'Ingredients',
  txtGenericAddIngredient: 'Add Ingredient',
  txtGenericParLevel: 'Par Level',
  txtGenericYourPar: 'Your Par',
  txtGenericParLevels: 'Par Levels',
  txtGenericPOSItems: 'POS Items',
  txtGenericPOSItem: 'POS Item',
  txtGenericVariance: 'Variance',
  txtGenericSet: 'set',
  txtGenericEnabled: 'Enabled',
  txtGenericDisabled: 'Disabled',
  txtGenericSetUp: 'Set up',
  txtGenericBottles: 'items',
  txtGenericBottle: 'Item',
  txtGenericStockInformation: 'Stock Information',
  txtGenericSuggestedStock: 'Suggested stock',
  txtGenericStock: 'Stock',
  txtGenericStockFullBottles: 'Stock Full Units',
  txtGenericSold: 'Sold',
  txtGenericWISKPar: 'WISK Par (Consumption)',
  txtGenericWISKParSales: 'WISK Par (Sales)',
  txtGenericIntakes: 'Invoices',
  txtGenericDeliveries: 'Invoices',
  txtGenericDeliveriesReturns: 'Invoices / Returns',
  txtGenericDeadstock: 'Deadstock',
  txtGenericUnderstock: 'Understock',
  txtGenericOverstock: 'Overstock',
  txtGenericFamilies: 'Families',
  txtGenericFamiliesAndCategories: 'Families & Categories',
  txtGenericFix: 'Fix',
  txtGenericFamily: 'Family',
  txtGenericCategory: 'Category',
  txtGenericGenerated: 'Generated',
  txtGenericVolume: 'Volume',
  txtGenericWeight: 'Weight',
  txtGenericPriority: 'Priority',
  txtGenericTitle: 'Title',
  txtGenericClone: 'Clone',
  txtGenericDensity: 'Density',
  txtGenericOrders: 'Orders',
  txtGenericEmptyWeights: 'Empty Weights (g)',
  txtGenericWeights: 'Weights (g)',
  txtGenericFee: 'Fee',
  txtGenericBatches: 'Batches',
  txtGenericBeerSaverTap: 'Beer Saver Tap',
  txtGenericProfit: 'Profit',
  txtGenericPerpetual: 'Real Time Stock',
  txtGenericConsumed: 'Consumed',
  txtGenericDegrees: 'Degrees',
  txtGenericBarcodes: 'Barcodes',
  txtGenericOrderFormat: 'Order Format',
  txtGenericCaseSize: 'Case Size',
  txtGenericSize: 'Size',
  txtGenericCase: 'Case',
  txtGenericUnit: 'Unit',
  txtGenericEach: 'Each',
  txtGenericVisual: 'Visual',
  txtGenericManual: 'Manual',
  txtGenericInventoryAlert: 'Stock Alert',
  txtGenericAddBarcode: 'Add barcode (min 6 characters)',
  txtGenericInputBarcode: 'Input barcode',
  txtGenericAddWeight: 'Add weight (g)',
  txtGenericInputWeight: 'Input weight (g)',
  txtGenericAddEmptyWeight: 'Add empty weight (g)',
  txtGenericInputEmptyWeight: 'Input empty weight (g)',
  txtGenericWeekly: 'Weekly',
  txtGenericConsumption: 'Consumption',
  txtGenericSales: 'Sales',
  txtGenericUnitOfMeasurement: 'Unit of measurement',
  txtGenericMeasurement: 'Measurement',
  txtGenericNoChangesRecorded: 'No changes recorded',
  txtGenericChange: 'Changes',
  txtGenericNoInfo: 'No info',
  txtGenericPending: 'Pending',
  txtGenericProcessed: 'Processed',
  txtGenericNote: 'Note',
  txtGenericPercentage: 'Percentage',
  txtGenericCostPercentage: 'Cost Percentage',
  txtGenericVolumePercentage: 'Volume Percentage',
  txtGenericStatus: 'Status',
  txtGenericQuantity: 'Quantity',
  txtGenericImages: 'Images',
  txtGenericImage: 'Image',
  txtGenericImageAdd: 'Add Image',
  txtGenericImagesPDFAdd: 'Add images / pdf',
  txtGenericImagesClear: 'Clear Images',
  txtGenericUnmapped: 'Unmapped',
  txtGenericUnknown: 'Unknown',
  txtGenericInventory: 'Inventory',
  txtGenericNew: 'New',
  txtGenericOpen: 'Open',
  txtGenericReports: 'Reports',
  txtGenericMovementsAnalytics: 'Movement Analytics',
  txtGenericCostChanges: 'Cost Changes',
  txtGenericPage: 'Page',
  txtGenericScale: 'Scale',
  txtGenericDollars: 'Dollars',
  txtGenericHours: 'hours',
  txtGenericMinutes: 'minutes',
  txtGenericDays: 'days',
  txtGenericOnline: 'Online',
  txtGenericYields: 'Yields',
  txtGenericOperation: 'Operation',
  txtGenericOperationSuccess: 'Changes Saved!',
  txtGenericTotal: 'Total',
  txtGenericSubTotal: 'Subtotal',
  txtGenericGrandTotal: 'Grand Total',
  txtGenericComplete: 'Complete',
  txtGenericDetailed: 'Detailed',
  txtGenericCopyClipboard: 'Copy to clipboard',
  txtGenericExisting: 'Existing',
  txtGenericInformationRequired: 'Information Required',
  txtGenericSearchReturnNothing: 'Search returned no elements',
  txtGenericSearchOnline: 'Search online catalog',
  txtGenericGraphPointInfo: 'Click on a point to view details in the panel on the left',
  txtGenericSentAt: 'Sent At',
  txtGenericMessageStatus: 'Message status',
  txtGenericMessage: 'Message',
  txtGenericSendEmail: 'Send email',
  txtGenericSendTransferOut: 'Send transfer out',
  txtGenericReceivedStatus: 'Invoice status',
  txtGenericReceivedAt: 'Received At',

  //TODO: remove these
  not_sent: 'Not sent',
  sent: 'Sent',
  sending: 'Sending',
  not_received: 'Not received',
  pending_approval: 'Pending approval',
  approved: 'Approved',
  requested: 'Requested',
  canceled: 'Canceled',
  in_preparation: 'In Preparation',
  declined: 'Declined',
  received: 'Received',
  received_manual_intake: 'Received with manual invoice',

  txtGenericPOSCode: 'POS Code',
  txtGenericPressEnterToAdd: 'Press enter to add',
  txtGenericBeginningInventory: 'Beginning inventory',
  txtGenericReceivings: 'Invoices',
  txtGenericEndingInventory: 'Ending inventory',
  txtGenericBeverageSales: 'Sales',
  txtGenericBeverageCost: 'Cost of Sales',
  txtGenericInventoryDuration: 'Inventory duration',
  txtGenericWeeklyIntakes: 'Weekly Invoices',
  txtGenericInventoryValue: 'Inventory value',
  txtGenericInventories: 'Inventories',
  txtGenericWiskItem: 'WISK Item',
  txtGenericWiskItems: 'WISK Items',
  txtGenericItems: 'Items',
  txtGenericColumns: 'Columns',
  txtGenericField: 'Field',
  txtGenericSelect: 'Select',
  txtGenericSelectImage: 'Select Image',
  txtGenericClear: 'Clear',
  txtGenericResolve: 'Resolve',
  txtGenericResolved: 'Resolved',
  txtGenericCurrentCostUnit: 'Current cost per Unit',
  txtGenericInvoiceCostUnit: 'Invoice cost per Unit',
  txtGenericCostCase: 'Cost per Case',
  txtGenericWiskId: 'WISK ID',
  txtGenericQuantityToOrder: 'Quantity to order',
  txtGenericNewIntake: 'New invoice',
  txtGenericDateRange: 'Date Range',
  txtGenericInventoryRange: 'Inventory Range',
  txtGenericNewInventory: 'New inventory',
  txtGenericContinueInventory: 'Continue inventory',
  txtGenericNewMovement: 'New movement',
  txtGenericMovementType: 'Movement type',
  txtGenericLocation: 'Area',
  txtGenericLocations: 'Areas',
  txtGenericUsedIn: 'Used in',
  txtGenericPerson: 'Person',
  txtGenericPhotos: 'Photos',
  txtGenericPrivacy: 'Privacy',
  txtGenericTermsOfService: 'Terms Of Service',
  txtGenericByUser: 'By User',
  txtGenericCreatedBy: 'Created by',
  txtGenericByItem: 'By Item',
  txtGenericByLocation: 'By Area',
  txtGenericByVariation: 'By Variation',
  txtGenericByLocationOrdered: 'By Area Ordered',
  txtGenericByLocationFamilyCategory: 'By Area > Family > Category',
  txtGenericByFamilyCategory: 'By Family > Category',
  txtGenericByDistributor: 'By Distributor',
  txtGenericByMonthDistributor: 'By Month > By Distributor',
  txtGenericByCategory: 'By Category',
  txtGenericNotEnoughData: 'Not enough data',
  txtGenericSignupDate: 'Signup Date',
  txtGenericPaymentMethod: 'Payment method',
  txtGenericStockPrediction: 'Stock Prediction',
  txtGenericMovements: 'Movements',
  txtGenericLastSalesCheck: 'Last sales check',
  txtGenericImportedNow: 'Import now',
  txtGenericDepositFee: 'Fee',
  txtGenericDeposit: 'Deposit',
  txtGenericHelp: 'Help',
  txtGenericMonth: 'Month',
  txtGenericYear: 'Year',
  txtGenericWeek: 'Week',
  txtGenericGrams: 'grams',
  txtGenericMililiters: 'milliliters',
  txtGenericDayOfWeek: 'Day Of week',
  txtGenericSummary: 'Summary',
  txtGenericViewAccount: 'View Account',
  txtGenericResumeSubscription: 'Resume Subscription',
  txtGenericBypassThis: 'Bypass this',
  txtGenericOrderAmount: 'Order Amount',
  txtGenericWiskSupportWillContactYou: 'Thank you, WISK customer support will contact you shortly',
  txtGenericTakeYouToLightspeed: 'Taking you to Lightspeed...',
  txtGenericTesting: 'TESTING',
  txtGenericLogOut: 'Logout',
  tplGenericForEmail: 'for <strong>{email}</strong>',
  txtGenericSetAsDefault: 'Set as default',
  tplGenericLoadingInProgress: 'Data loading in progress for {b}: {a}%',
  txtGenericSuperUser: 'Super user',
  txtGenericEmailInProgress: "Generating your report! It will download when finished. If you close the browser tab while generating, it'll send to your email when complete.",
  txtGenericUnsubscribeAll: 'Unsubscribe all',
  txtGenericSubscribeAll: 'Subscribe all',
  txtGenericDownloadManager: 'Download Manager',
  txtGenericMoreInfo: 'More info',
  txtGenericMobile: 'Mobile',
  txtGenericWeb: 'Web',
  txtGenericWarningInventoryStarted: 'There is currently an inventory in progress. These numbers are not final and may change until you submit the inventory.',
  txtGenericNoValue: 'No value',
  txtGenericConfirm: 'Confirm',
  txtGenericOpenVenueNewTab: 'Open venue in new tab',

  //month names
  txtGenericMonth0: 'January',
  txtGenericMonth1: 'February',
  txtGenericMonth2: 'March',
  txtGenericMonth3: 'April',
  txtGenericMonth4: 'May',
  txtGenericMonth5: 'June',
  txtGenericMonth6: 'July',
  txtGenericMonth7: 'August',
  txtGenericMonth8: 'September',
  txtGenericMonth9: 'October',
  txtGenericMonth10: 'November',
  txtGenericMonth11: 'December',

  //days of week
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  txtGenericToday: 'Today',
  txtGenericYesterday: 'Yesterday',
  txtGenericLastWeek: 'Last week',
  txtGenericLast7Days: 'Last 7 days',
  txtGenericLast30Days: 'Last 30 days',
  txtGenericLast30DaysLast6Months: 'Last 6 months',
  txtGenericCurrentMonth: 'Current month',
  txtGenericLastMonth: 'Last month',
  txtGenericLast90Days: 'Last 90 days',
  txtGenericCurrentYear: 'Current year',
  txtGenericLastYear: 'Last year',
  txtGenericCurrentQuarter: 'Current quarter',
  txtGenericLastQuarter: 'Last quarter',
  txtGenericThankYou: 'Thank you for using WISK!',
  txtGenericBack: 'Back',

  //order format
  case: 'case',
  unit: 'unit',
  cases: 'cases',
  units: 'units',

  //movements operations - replaced by groupMovementTypes - to be removed
  intake: 'Invoice',
  return: 'Return',
  transfer: 'Transfer between areas',
  transfer_in: 'Transfer In',
  transfer_out: 'Transfer to Partner Venue',
  adjustment: 'Depletion',

  //menu
  txtMenuGettingstarted: 'Getting started',
  txtMenuFeedbackPage: 'Feedback',
  txtMenuHelpPage: 'Help Page',
  txtMenuHelpChat: 'Chat with us',
  txtMenuAskUsAnything: 'Ask us anything',
  txtMenuFeedbackCenter: 'WISK Feedback',
  txtMenuHelpCenter: 'WISK Help Center',
  txtMenuAccount: 'Account',
  txtMenuTeam: 'Team',
  txtMenuOverstockUnderstock: 'Overstock / Understock',
  txtMenuPriceChanges: 'Price Changes',
  txtMenuSubscription: 'Subscription',
  txtMenuVenueSettings: 'Venue Settings',
  txtMenuVenues: 'Venues',
  txtMenuLocations: 'Areas',
  txtMenuSignOut: 'Log Out',
  txtMenuSwitchVenue: 'Switch Venue',
  txtMenuDashboard: 'Dashboard',
  txtMenuHome: 'Home',
  txtMenuVenueBottles: 'Items',
  txtMenuInventory: 'Inventory',
  txtMenuConsumption: 'Consumption',
  txtMenuPrepareOrders: 'Prepare Orders',
  txtMenuPurchaseOrders: 'Purchase Orders',
  txtMenuIntakes: 'Invoices',
  txtMenuSales: 'Sales',
  txtMenuMultiVenue: 'Multivenue',
  txtMenuMultiVenueTransfers: 'Venue Transfers',
  txtMenuPOSItems: 'POS Items',
  txtMenuVariance: 'Variance',

  //messages
  txtMessageRedirect: 'Setting up integration...',
  txtMessageRedirectSuccess: 'All done! Redirecting to home page...',

  //sort
  txtSortBy: 'Sort by',
  txtSortAdd: 'Then by',
  txtGroupBy: 'Group By',

  //filters
  txtFilterAdd: 'Add Filter',
  txtFilterBy: 'Filter By',
  txtFilterInfo: 'Active filters',
  txtFilterCombineClauseAnd: 'And',
  txtFilterCombineClauseOr: 'Or',
  txtFilterActive: 'Active',
  txtFilterArchived: 'Archived',
  tplFilterArchivedFoundItems: 'We found {a} archived items. Click here to view them.',
  txtFilterDeadStock: 'Deadstock',
  txtFilterOverStock: 'Overstock and deadstock',
  txtFilterUnderStock: 'Understock',
  txtFilterInStock: 'In stock',
  txtFilterNotInStock: 'Not in stock',
  txtFilterWithPerpetualInventory: 'With Real-Time Inventory',
  txtFilterNegativeConsumption: 'Stock Errors',
  txtFilterConsumptionNewItems: 'New Items',
  txtFilterConsumptionDepletedItems: 'Depleted Items',
  txtFilterNegativeConsumptionWithIntake: 'Stock Errors With Invoice',
  txtFilterConsumptionGreaterThanSales: 'Consumption greater than sales',
  txtFilterSalesGreaterThanConsumption: 'Sales greater than consumption',
  txtFilterNegativeConsumptionWithoutIntake: 'Stock Errors Without Invoice',
  txtFilterConsumptionNotMapped: 'With Consumption but not mapped to Any POS Items',
  txtFilterConsumptionNoSales: 'With Consumption but without Sales',
  txtFilterConsumptionOrSales: 'With Consumption or Sales',
  txtFilterSalesNoConsumption: 'With Sales but no consumption',
  txtFilterSales: 'With Sales',
  txtFilterUnmappedPOSItems: 'Unmapped POS Items',
  txtFilterWithArchivedBottles: 'With archived items',
  txtFilterWithNoIngredients: 'With no ingredients',
  txtFilterCostHigherThan100Percent: 'Cost % higher than 100%',
  txtFilterPOSItemSoldInTheLast30days: 'Sold in the last 30 days',
  txtFilterPOSItemSoldNot: 'With no sales',
  txtFilterCocktails: '2+ Ingredients',
  txtFilterMissingCosts: 'Missing Cost',
  txtFilterManualCost: 'Cost Set Manually',
  txtFilterMissingCostAlert: 'Missing Cost Alert',
  txtFilterMissingStockAlert: 'Missing Stock Alert',
  txtFilterMissingPercentage: 'Missing %',
  txtFilterMissingFullWeights: 'Missing Full Weights',
  txtFilterMissingParLevel: 'Missing Par Level',
  txtFilterMissingCategory: 'Missing Category (unknown)',
  txtFilterWithSuggestedOrder: 'With Suggested Order',
  txtFilterExcludedfromVariance: 'Excluded from Variance',
  txtFilterIncludedInVariance: 'Included in Variance',
  txtFilterMissingDistributor: 'Missing Distributor',
  txtFilterUnmappedToPOSItemsOrBatches: 'Unmapped to POS Items / batches',
  txtFilterDuplicatedBarcodes: 'Duplicated Barcodes',
  txtFilterMultipleBarcodes: 'Multiple Barcodes',
  txtFilterOutTestAccounts: 'Filter out test accounts',
  txtFilterOutLostAccounts: 'Filter out lost accounts',

  //column headers
  columnVarianceConsumptionCost: 'Consumption Cost ($)',
  columnVarianceSoldBottles: 'Sold (units)',
  columnQuantityUnits: 'Quantity (units)',
  columnVarianceSoldUM: 'Sold (UM)',
  columnVarianceConsumedBottles: 'Consumed (units)',
  columnVarianceConsumedUM: 'Consumed (UM)',
  columnVarianceBottles: 'Variance (units)',
  columnVarianceUM: 'Variance (UM)',
  columnVarianceSold: 'Sold ($)',
  columnVarianceCost: 'Variance Cost ($)',
  columnVariancePercentage: 'Variance (%)',
  columnVarianceRetail: 'Variance Retail ($)',
  columnVarianceBeginningInventory: 'Beginning Inventory (units)',
  columnVarianceMovements: 'Invoices (units)',
  columnVarianceEndingInventory: 'Ending Inventory (units)',
  columnCost: 'Cost ($)',
  columnCostOfGoodsSold: 'Cost of goods sold ($)',
  columnCostPerUnit: 'Cost per unit',
  columnSuggestedOrder: 'Suggested Order',
  columnPendingPurchaseOrders: 'Pending Purchase Orders',
  columnPrice: 'Price',
  columnStartPrice: 'Start Price',
  columnEndPrice: 'End Price',
  columnPriceDifference: 'Price Difference',
  columnPriceDifferencePercent: 'Price Difference %',
  columnLastMonth: 'Last 30 days',
  columnMenuPrice: 'Menu price',
  columnDetails: 'Details',
  columnItemTotal: 'Item Total',
  columnVenueId: 'Venue Id',
  columnBasedOnWiskPar: 'Based on WISK par (Consumption)',
  columnBasedOnWiskParSales: 'Based on WISK par (Sales)',
  columnBasedOnYourPar: 'Based on your par (stock)',
  columnFromArea: 'From Area',
  columnToArea: 'To Area',

  columnBasedOnRealTimeStock: 'Based on your par (real-time stock)',
  columnRealTimeInventoryValue: 'Real-time inventory value',
  columnDistributors: 'Distributors',
  columnInventorySize: 'Inventory Size ($)',
  columnConsumption: 'Consumption ($)',
  columnBeverageCostPercent: 'Beverage Cost (%)',
  columnCostPercentagePercent: 'Cost Percentage (%)',
  columnFoodCostPercent: 'Food Cost (%)',
  columnShrinkagePercent: 'Shrinkage (%)',
  columnShrinkageVolumePercent: 'Shrinkage Volume (%)',
  columnBeverageCostPercentActual: 'Actual Cost of Sales (%)',
  columnBeverageCostPercentTheoretical: 'Theoretical Cost of Sales (%)',

  //confirm
  confirmUnlinkPublicDistributorText: "Please confirm you want to unlink this distributor from the public database. The distributor will be marked as private and won't appear in searches.",
  confirmUnlinkPublicDistributorTitle: 'Unlink from WISK Distributor',
  confirmInventoryApproveText: 'Please confirm that the inventory count has been completed and approved. Upon confirmation, you will be emailed a copy of your inventory report.',
  confirmInventoryApproveTitle: 'Confirm inventory approval',
  confirmInventoryLocationResetText: 'Do you want to restart the inventory count for this area? Restarting the count will delete all saved item counts in this area.',
  confirmInventoryLocationResetTitle: 'Restart Area',
  confirmInventoryDurationUnusualText:
    "The selected inventory duration is more than 48 hours, are you sure you want to save it? In most cases, the inventory count should be completed the same day it's started during a period with no sales in the venue.",
  confirmInventoryDurationUnusualTitle: 'Inventory duration is greater than 48 hours',
  confirmRemoveText: 'Please confirm deleting the selected item(s). This operation is permanent.',
  confirmRemoveTitle: 'Confirm Delete',
  confirmRemovePermissionText: 'Please confirm removing the selected permission from the role.',
  confirmRemovePermissionTitle: 'Remove Permission',
  confirmRemoveImages: 'Please confirm removing the selected image. This operation is permanent.',
  confirmResyncVenueData: 'You usually need to do this if a Wisk Customer Support agent has asked you to do so.',
  confirmResyncVenueDataTitle: 'Resync Venue Data',
  confirmItemVariantSetDefaultText:
    "The new default item variation you have selected is a different size than the current default item variation. The stock count will be converted to units in the new default item variation's measurement size. Please confirm that you want to complete this operation.",
  confirmRoleArchiveText: 'Please confirm archiving the selected role',
  confirmRoleRestoreText: 'Please confirm restoring the selected role',
  confirmItemVariantSetDefaultTitle: 'Confirm operation',
  confirmArchivePurchaseOrderText: 'Please confirm archiving the selected purchase order',
  confirmMultipleOperationsGenericText: 'Please confirm the selected operation for all the selected items',
  confirmArchiveText: 'Please confirm archiving the selected item(s)',
  confirmForceArchiveText: 'Are you sure you want to force archive this item? The item will be archived and removed from every POS Item and Batch it is used in',
  confirmArchiveTitle: 'Confirm archive',
  confirmRestoreText: 'Please confirm restoring the selected item(s)',
  confirmRestorePurchaseOrderText: 'Please confirm restoring the selected purchase order',
  confirmInventoryEntriesCopyText: 'Copy inventory entries from selected areas to respective areas in the selected inventory?',
  confirmRestoreTitle: 'Confirm restore',
  confirmClearCartDistributorText: 'This will clear the shopping cart for this distributor. Continue?',
  confirmClearCartDistributorTitle: 'Clear shopping cart for distributor',
  confirmGenerateOrderDistributorText: 'This will generate a purchase order for this distributor. Continue?',
  confirmGenerateOrderDistributorTitle: 'Generate order',
  confirmGenerateOrderSendDistributorText: 'This will generate and send a purchase order for this distributor. Continue?',
  confirmGenerateOrderSendDistributorTitle: 'Generate order and send it to distributor',
  confirmPrefillCartDistributorTextWiskPar: 'This will replace the quantity to order using WISK par. Continue?',
  confirmPrefillCartDistributorTextYourPar: 'This will replace the quantity to order using your par. Continue?',
  confirmPrefillCartDistributorTextWiskParEstimated: 'This will replace the quantity to order using WISK par based on your real-time stock. Continue?',
  confirmPrefillCartDistributorTextYourParEstimated: 'This will replace the quantity to order using your par based on your real-time stock. Continue?',
  confirmPrefillCartDistributorTitle: 'Prefill with recommended',
  confirmPurchaseOrderSAQArchiveTitle: 'Confirm placing order',
  confirmPurchaseOrderSAQArchiveMessage: 'Did you place the order in the SAQ portal?',
  confirmPurchaseOrderMarkAsSentTitle: 'Confirm mark as sent',
  confirmPurchaseOrderMarkAsSentMessage: 'Selected order(s) will be marked as sent to distributor but will not actually send it',
  confirmPurchaseOrdersMarkReceivedNoIntake: 'Do you want to mark the selected order(s) as received without generating an invoice? This operation is only necessary if the invoice was previously done manually.',
  confirmPurchaseOrdersLinkToInvoice: 'Are you sure you want to link current purchase order to this invoice?',
  confirmPurchaseOrderSendTitle: 'Confirm send',
  confirmPurchaseOrderSendMessage: 'Selected order(s) will be sent to distributor and marked as sent',
  confirmPurchaseOrderCancelTransferRequestTitle: 'Confirm cancel',
  confirmPurchaseOrderCancelTransferRequestMessage: 'Selected order will be canceled',
  confirmItemsGridFloatingActionText: 'The selected operation will be executed for all the selected items.',
  confirmItemsGridFloatingActionTitle: 'Confirm operation',
  confirmOnboardingBottleEditArchiveText: 'Please confirm removing this item',
  confirmOnboardingBottleEditOpenProcessingItem: 'This item is already open in another session. Open it anyway?',
  confirmOnboardingBottleEditNoteText: 'Ask for information',
  confirmBottlesMergeText: 'Are you sure you want to merge these items? This operation cannot be undone. Please use with extra care.',
  confirmBottlesMergeGroupText: `Are you sure you want to group these items?
- The stock of the grouped item becomes the total count of all item variations, converted to the default.
- Items are replaced by the grouped item in all inventories and movements (invoices, etc.) in unlocked inventory periods. Data from locked periods is unaffected.
- After the grouping is finished it can take several minutes for your data to update. To ensure your data remains accurate, please don't make changes to the item immediately after grouping.`,
  confirmBottlesUnMergeUnGroupText: `Ungrouping will remove all item variations from this item that were added using the “Group” function.
 The removed item variations will be restored as separate items. Click “OK” to confirm.`,
  confirmBottlesUngroupTitle: 'Confirm Ungrouping',
  confirmBottlesMergeTitle: 'Confirm merge',
  confirmPOSItemsSaveNoIngredientsText: 'Are you sure you want to save the POS item with no ingredients?',
  confirmPOSItemsSaveNoIngredientsTitle: 'Confirm save without ingredients',
  confirmUserDeleteFromVenueText: 'Please confirm removing the selected user(s) from the selected venue(s).',
  confirmUserDeleteFromVenueTitle: 'Remove from venue',
  confirmDistributorContactDeleteText: 'Please confirm deleting selected contact.',
  confirmStripeCardDeleteText: 'Please confirm removing the selected card.',
  confirmDistributorContactDeleteTitle: 'Delete contact',
  confirmHistoryChangingOperation: 'This operation will affect all the previous inventories taken',
  tplConfirmHistoryChangingOperation: "Changing the {a} updates the item in all inventories, invoices, and other movements in the unlocked inventory period. Data in locked inventory periods won't be changed.",
  tplConfirmItemMeasurementVariationBy10Percent: 'Are you sure you want to change the measurement from {a} to {b}? This is a large change in measurement.',
  tplConfirmItemMeasurementGreaterThanKgOrLThreshold: 'The entered measurement of {a} is unusually high for this item. Please confirm if this is correct.',
  tplConfirmItemMeasurementUnit: `
  Warning: You have selected {a} for this item.

Are you sure this is intentional? Selecting {a} could cause issues with inventory tracking, especially for items that are typically managed by weight or in packs. Consider whether a different measurement would be more accurate.

Please confirm to proceed with this choice.
  `,
  tplConfirmMovementEditSendEmail: 'Did you finish the {a}? Would you like to send a confirmation email?',
  tplConfirmMovementEditSendTransferOut: `
  If you have added all of the items to be transferred to {b}, click "OK".
  The transferred items will be removed from {a} immediately and a draft invoice will be created in {b}.
  The draft invoice must be approved in the receiving venue to complete the transfer.`,
  confirmMovementEditSendEmailTitle: 'Confirm send email',
  confirmMovementEditSendTransferOutTitle: 'Transfer out confirmation',
  confirmMovementEditLinkToPurchaseOrder: 'Are you sure you want to link current invoice to this purchase order?',
  confirmPaymentEditSendEmail: 'Would you like to send a subscription update email to the venue?',
  confirmOperationTitle: 'Confirm operation',
  confirmVenueDuplicate: 'There is already a venue at this address, create new venue anyway?',
  confirmVenueDuplicateTitle: 'Confirm create venue',
  confirmVenueCopyDataTitle: 'Copy data to selected venue',
  confirmVenueCopyData: 'Are you sure you want to copy data to selected venue?',
  confirmVenueCopyDataCheckLater: 'The venue copy is scheduled to be executed. Please check the destination venue in 10 minutes.',
  confirmCopyDataToVenue: 'Copy operation is scheduled to be executed, the process may take a while, please be patient.',
  confirmAppReload: 'We need to refresh the page.',
  confirmApplyGLAccounts:
    'By confirming this action, any GL accounts that have been assigned to an Item, Family, or Distributor will be applied to existing movements / invoices.\nThe GL accounts will be applied in the following priority:\nItem > Family > Distributor',
  confirmPOSIntegrationReimportSalesData: 'Do you want to reimport sales data again for this integration?',
  confirmPOSIntegrationDuplicateTitle: 'Duplicate POS integration',
  confirmPOSIntegrationDuplicateDescription:
    'Are you sure you want to add a new POS integration for {a}? If your existing integration is disconnected, please contact support@wisk.ai to resolve the issue. Adding the same integration again can lead to duplicate POS Items',
  confirmPOSIntegrationDuplicateArchivedDescription:
    'There is currently an inactive integration for {a}. You can either restore the inactive integration or create a new one. If you need help regarding the inactive integration, please contact support@wisk.ai. ',
  txtHelpInfo: 'The text will propagate to all clients instantly',
  txtHelpInfoTitle: 'Change tooltip text',

  //support email
  txtContactUsSuccessMessage: 'The message has been sent succesfully.',
  txtContactUsAddScreenshot: 'Capture screenshot',
  txtContactUsAddScreenshotDraw: 'Draw on screenshot to highlight issues',
  txtContactUsAddScreenshotDrawStop: 'Stop drawing',
  txtContactUsAddScreenshotDrawClear: 'Clear drawing',
  txtContactUsAddImage: 'Attach an image',

  //view Users
  txtUsersInvite: 'Invite',
  tplEmailsCountFoundInBulkText: 'Found {a} email addresses',
  txtUsersInviteMultiple: 'Invite more users at once',
  txtUsersInviteUsers: 'Invite Users',
  tplUsersInviteUsersToVenue: 'Invite users to venue {a}',
  tplUsersInviteUserToVenues: 'Invite user {a} to multiple venues',
  tplUsersInviteUserToVenuesSelected: 'Invite user {a} to selected venues',
  txtUserInvitedToVenues: 'An invitation has been sent for all the selected venues',
  txtUsersInvitedToVenue: 'An invitation has been sent to all the selected users',
  txtUsersRefereeName: 'Accepted by',
  txtUsersInviteUser: 'Invite User',
  txtUsersEmailReports: 'Email Reports',
  txtUsersReceiveEmailInventory: 'Inventories',
  txtUsersReceiveEmailIntakes: 'Invoices',
  txtUsersReceiveEmailPurchaseOrders: 'Orders',
  txtUsersReceiveEmailDailySales: 'Daily sales',
  txtUsersReceiveEmailWeeklySales: 'Weekly sales',
  txtUsersUnsubscribeAllEmailReportsAllVenues: 'Unsubscribe all email reports on all venues',
  txtUsersUnsubscribeAllEmailReports: 'Unsubscribe all email reports',
  txtUsersSubscribeTypeForAllVenues: 'Subscribe "{a}" email report for all venues',
  txtUsersUnsubscribeTypeForAllVenues: 'Unsubscribe "{a}" email report for all venues',
  txtUsersUnsubscribeAllEmailReportsOneVenue: 'Unsubscribe all email reports for "{a}" venue',
  txtUsersSubscribeAllEmailReportsOneVenue: 'Subscribe all email reports for "{a}" venue',
  txtUsersEmailReportsConfirmationMessage: 'Are you sure you want to apply this change on your email reports?',
  txtUsersInventoryTrainingCompleted: 'Inventory training completed',
  txtUsersFoodInventoryTrainingCompleted: 'Food inventory training completed',
  txtUsersDarkModeOptions: 'Select your preferred theme',
  txtUsersDarkModeOptionsLight: 'Light',
  txtUsersDarkModeOptionsDark: 'Dark',
  txtUsersDarkModeOptionsAuto: 'Auto (follow system settings)',
  txtUsersRefer: 'Refer Friends',
  txtUsersReferInfo: 'Refer users by adding their email address or phone number in the form below',
  txtUsersReferInvite: 'Invite your friends to WISK',
  txtUsersReferInviteSuccess: 'Invitation sent successfully',
  txtUsersCompleteInformation: 'Please complete your information to continue using Wisk.',

  //view PageNotFound
  txtPageNotFoundTitle: "Oops! You're lost.",
  txtPageNotFound: 'The page you are looking for was not found.',

  //view LoginPage
  txtAuthSignNewToWISK: 'New to WISK?',
  txtAuthSignIn: 'Sign In',
  txtAuthSignInHere: 'Sign in here',
  txtAuthResetPasswordTitle: 'Reset your password',
  txtAuthResetPassword: 'Reset Password',
  txtAuthResetPasswordEmptyInput: 'Enter your password',
  txtAuthErrorTitle: 'Try resetting your password again',
  txtAuthErrorMessage: 'Your request to reset your password has expired or the link has already been used',
  txtAuthResetWeakPassword: 'Strong passwords have at least 6 characters and a mix of letters and numbers',
  txtAuthResetedTitle: 'Password changed',
  txtAuthResetedMessage: 'You can now sign in with your new password',
  txtAuthHaveAccount: 'Have an Account?',
  txtAuthSignUp: 'Sign Up',
  txtAuthGetStarted: 'Get Started',
  tplAuthSignupInvitedMessage: '{a} invited you to the venue {b}',
  txtAuthSignupInvitedAcceptedMessage: 'This invitation has already been accepted',
  txtAuthPassChange: 'Change password',
  txtAuthPassSet: 'Set password',
  txtAuthPassRecoverQuestion: 'Trouble signing in?',
  txtAuthPassRecover: 'Recover password',
  txtAuthPassRecoverConfirmTitle: 'Check your email',
  tplAuthPassRecoverConfirm: 'Follow the instructions sent to {a} to recover your password',
  txtAuthEmailInput: 'Please input your email address',
  txtAuthPassInputNew: 'Please input new password',
  txtAuthPassInputCurrent: 'Please input your current password',
  txtAuthConfirmPass: 'This operation requires password verification',
  txtAuthUserAdd: 'Add user',
  txtAuthPassLength: 'Password must be at least 6 characters',
  txtAuthPassDescription: '(Minimum 6 characters)',
  txtAuthPhoneDescription: '(Please include area code)',
  txtAuthPassMatch: 'Password and confirm password must be the same',
  txtAuthPassCreate: 'Create password',
  txtAuthPass1: 'Password',
  txtAuthPass2: 'Confirm password',
  txtAuthPassChangedMessage: 'Your password was changed successfully',
  txtAuthEmailChangeBySuperAdminConfirm: 'User will have to sign in using the new email',
  txtAuthUserInvitedFound: 'We found a user with this email address and sent an invitation to join the selected venue',
  txtAuthUserInvited: 'An invitation has been sent',
  txtAuthUsersInvited: 'An invitation has been sent to all the emails in the list',
  txtAuthUsersInvitePlaceholder: 'Please input emails separated by comma, semicolon, space, etc...',
  txtAuthSignInOrSignUpHere: 'Sign into your WISK account, or sign up',
  txtAuthWelcomeBack: 'Welcome back',
  txtAuthNewPassword: 'New password',
  txtAuthSignOut: 'Sign out',
  txtAuthInvalidCredentials: 'Invalid credentials. Please check your email and password.',

  //sign up
  txtSignupSubTitleUser: 'A few details about yourself.',
  txtSignupSubTitleVenue: "Let's get your venue started on WISK.",

  txtSignupTitleTrial: 'Choose your plan',
  txtSignupTitleUser: 'User Details',
  txtSignupTitleVenue: 'Set up your venue',

  //view VenueBottles
  txtVenueBottlesTitle: 'Items List',
  txtVenueBottlesRecentlyAdded: 'Recently Added',
  txtVenueBottlesCheckMeasurement: 'Check Measurement',
  txtVenueBottlesMeasurement: 'Item measurement',
  txtVenueBottlesCreate: 'Create new item',
  txtVenueBottlesEdit: 'Edit WISK item',
  txtVenueBottlesFind: 'Search item',
  txtItemSearch: 'Search WISK Item',
  txtItemSearchAdd: 'Search / Add',
  tplVenueBottlesVariantFor: 'Item Variation for {a}',
  txtVenueBottlesVariantDefaultTitle: 'Default variation',
  txtVenueBottlesVariantShowArchived: 'View archived variations',
  txtVenueBottlesVariantHideArchived: 'Hide archived variations',
  txtVenueBottlesVariantDefault: 'Default for inventory / par level',
  txtVenueBottlesVariantDefaultSet: 'Set as default for inventory / par level',
  txtVenueBottlesVariantOrderFormat: 'Allow order in units',
  txtVenueBottlesVariantReceiveOnly: 'Receive only',
  txtVenueBottlesVariantInventoriable: 'Inventoriable',
  txtVenueBottlesVariantLabel: 'Variation Name',
  txtVenueBottlesVariantInventoriableAs: 'Inventoried as',
  txtVenueBottlesVariantInventoriableAsUnits: 'Inventoriable as units',
  txtVenueBottlesVariantInventoriableAsCases: 'Inventoriable as cases',
  txtVenueBottlesVariantInventoriableAsCasesDisabledReason: 'Case size must be greater than 1',
  txtVenueBottlesVariants: 'Item Variations',
  txtVenueBottlesVariant: 'Item Variation',
  txtVenueBottlesVariantNameNotUnique: 'A variation with the same name already exists for this item.',
  txtVenueBottlesMeasurementConversion: 'Measurement Conversion',
  tplVenueBottlesMeasurementConvertsTo: 'Converts to {a}',
  txtVenueBottlesCreateVariant: 'Create new item variation',
  txtVenueBottlesVariantConfirmMeasurmentConversion: 'Use measurement conversion',
  txtVenueBottlesCreateVariantShort: 'New variation',
  txtVenueBottlesCloneVariant: 'Clone this item variation',
  txtVenueBottlesAddCodeToVariant: 'Add this code to an existing item variation',
  txtVenueBottlesImport: 'Import items from Excel file',
  txtIncludeVenueNameInExcel: 'Include venue name in header of exported file',
  txtVenueBottlesImportBarcodes: 'Import prices from Excel file based on barcodes',
  txtVenueBottlesNotFoundCreate: 'Item not found, create new',
  txtVenueBottlesManageFamiliesCategories: 'Manage Families and Categories',
  txtVenueBottlesSpreadsheet: 'Items Spreadsheet',
  txtVenueBottlesPDF: 'Download Items PDF',
  txtVenueBottlesByLocation: 'Items by location',
  txtVenueBottlesPDFBarcodes: 'Items Barcodes',
  txtVenueBottlesNewBottleModalTitle: 'New Item',
  txtVenueBottlesFloatingActionsDescription: 'items selected',
  txtVenueBottlesNotEnoughDataWiskPar: 'Not enough data to calculate the WISK Par',
  txtVenueBottlesUploadMessage: "Download the file below and populate it with your latest item data. Existing items will be updated, and new items will be added to your venue's item list.",
  txtVenueBottlesUploadErrorMessage: 'The file could not be uploaded because of the following errors. Please correct these errors and try uploading the file again:',
  txtVenueBottlesUploadDownloadExistingItems: 'Download template',
  txtVenueBottlesViewInventory: 'Find in inventory',
  txtVenueBottlesViewMovement: 'Find in intake',
  tplVenueBottlesViewMovementDetail: 'Date: {a}, quantity: {b}',
  txtVenueBottlesUploadItems: 'Upload items',
  txtVenueBottlesForceArchive: 'Force Archive',
  txtVenueBottlesPriceDisabledInvoicePresent: 'The price is taken from the last invoice, please modify it there',
  txtVenueBottlesPriceDisabledSubrecipe: 'The price is taken from the connected subrecipe, please modify it there',
  txtVenueBottlesPriceDisabledMultipleVariants: 'Please change the price on each variation of this item',
  txtVenueBottlesFindInParent: 'Search for items in parent venue',
  txtVenueBottlesAllItemsInOnePlace: 'All your items, in one place!',
  txtVenueBottlesItemsPage: 'Go to items page',
  txtVenueBottleManageYourItemsExplanation:
    'Easily manage and update all your items on this page. You can drag & drop the columns in any order you want, update key fields like cost, distributor, title, and many more, and even save these views as presets to access them instantly in the future.',
  txtVenueBottleKeepTrackBottles: 'Keep track of your bottles today!',
  txtVenueBottleNotEditable: 'This item is temporarily locked from further changes until the current update is applied to your data.',

  // parent venue items
  txtParentVenueFindItems: 'Find items in parent venue',
  txtParentVenueActivateItems: 'Activate items',
  txtParentVenueItemsSelectVenue: 'Select Venues',
  txtParentVenueItemsActiveInVenues: 'Active in venues',

  //view Onboarding
  txtOnboardingTitle: 'Venue Onboarding',
  txtOnboardingSelectPlaceholder: 'View items for venue',
  txtOnboardingHelpMessage: 'Click here if you need help',
  txtOnboardingVenueStatus: 'Venue Status: ',

  //user onboarding
  txtUserOnboardingAlmostThere: 'Almost there! After your account is confirmed you will be able to access the application.',
  txtUserOnboardingNoVenue: 'No venue found. Please create your first venue in order to start.',
  txtUserOnboardingEmailConfirmAgain: 'Send email confirmation again',
  txtUserOnboardingEmailConfirmResendSuccess: 'Email verification link has been sent.',
  txtUserOnboardingVenueNone: 'Welcome to WISK! Please fill in your venue details below',
  txtUserOnboardingReopeningTitle: 'Reopening? Unpause your account and enjoy WISK right where you left off.',
  txtUserOnboardingReopeningMessage: 'By clicking "Resume Subscription", your payment method will be charged at the beginning of the next billing period',
  txtOpenInAppstore: 'The WISK Web Portal is not optimized for mobile browsers. For the best experience please use the iOS app.',
  txtWebAppNotOptimizedMobile: 'Wisk web-app is not optimized for mobile browsers.',
  txtSignUpAskUserOrigin: 'Where did you find out about WISK?',
  txtSignUpAskUserOriginCustom: 'Where?',
  txtSignedUpOnMobileAlert: 'You will now be redirected to the WISK mobile app to continue the signup process',
  txtOpenInAppstoreTitle: 'Open the WISK mobile app',
  txtHelpForBackup: 'Need help picking back up?',

  //View Inventories

  txtInventoriesFilterByLocation: 'Filter by area',
  txtInventoriesFilterByCreatedDate: 'Filter by Added Date',
  txtInventoriesEditDetails: 'Details / Edit',
  tplInventoriesEditPartialComponent: 'Sub units of {a}',
  tplInventoriesEditPartialComponentHelpText: 'Input value from 1 to {a}',
  txtInventoriesStartedBy: 'Started By',
  txtInventoriesReset: 'Restart',
  txtInventoriesCaseSizeSet: 'Set case size',
  txtInventoriesFullWeightSet: 'Set full weight',
  txtInventoriesWeightFull: 'Full weight',
  txtInventoriesWeightEmpty: 'Empty weight',
  tplInventoriesWeightInputValidation: 'Please input a weight between {a} g and {b} g',
  txtInventoriesApprove: 'Approve',
  txtInventoriesLocationSelect: 'Select location',
  txtInventoriesStartedNot: 'Not started',
  tplInventoriesStartedAtBy: 'Started {a} by {b}',
  tplInventoriesFinishedAtBy: 'Finished {a} by {b}',
  txtInventoriesStartedAt: 'Start Time',
  txtInventoriesFinishedAt: 'End Time',
  txtInventoriesFinishedAtNone: 'Not finished yet',
  txtInventoriesInAppTime: 'In-App Time',
  txtInventoriesApprovedBy: 'Approved By',
  txtInventoriesEntryEdit: 'Inventory entry',
  txtInventoriesEntryAdd: 'Add inventory entry',
  txtInventoriesEntryMove: 'Move inventory entry',
  txtInventoriesEntriesMove: 'Move inventory entries',
  txtInventoriesEntriesMoveTo: 'Move inventory entries to:',
  txtInventoriesEntriesToMove: 'Entries to move',
  txtInventoriesEntriesMoveToOther: 'Move inventory entries to other inventories',
  txtInventoriesEntriesMoveConfirmation: 'Are you sure you want to move selected entries to the selected inventory / area?',
  txtInventoriesEntriesEdit: 'Inventory entries for item',
  txtInventoriesEntriesCount: 'Entries',
  txtInventoriesEdit: 'Edit Inventory',
  tplInventoriesEditTitle: 'Inventory by {a}',
  tplInventoriesEditTitleOngoing: 'Ongoing inventory by {a}',
  txtInventoriesMergeItems: 'Merge items',
  txtInventoriesFullBottles: 'Full Items',
  txtInventoriesCostUnit: 'Inventory cost per unit',
  txtInventoriesPartialsBottles: 'Open Items',
  txtInventoriesPartialsBottle: 'Open Item',
  txtInventoriesTotalBottles: 'Total Items',
  txtInventoriesTotalUom: 'Total (UM)',
  txtInventoriesScanOrder: 'Scan Order',
  txtInventoriesSubmitAll: 'Submit all at once',
  tplInventoriesSubmitAllWithSingleArchivedItem: 'This area contains "{a}" that was archived since your last inventory count. The previous counts for this area, excluding this item, have been submitted.',
  txtInventoriesSearchPrevious: 'Search in previous inventory',
  txtInventoriesSearchAddQuick: 'Quick search and add item',
  txtInventoriesArchivedWarning: 'Adding inventory to an archived inventory item will automatically restore it',
  txtInventoriesScaleInputInfo: 'Input grams will be converted to milliliters',
  txtInventoriesSearchVenue: 'Search in venue items',
  txtInventoriesTotalBottlesCost: 'Total Items($)',
  txtInventoriesDownloadInventoryReport: 'Download inventory report by item XLS',
  txtInventoriesDownloadInventoryReportByLocation: 'Download inventory report by area XLS',
  txtInventoriesDownloadSpreadsheetByLocation: 'Download items by area XLS',
  txtInventoryBarcodesPDF: 'Download inventory barcodes PDF',
  txtInventoryBarcodes: 'Inventory barcodes',
  txtInventoryReportSummaryPDF: 'Download inventory report by item PDF',
  txtInventoriesEmailReports: 'Email Reports (Inventory/Consumption/Variance)',
  txtInventoryEmailSentMessage: 'Your email is scheduled to be sent to all recipients',
  txtInventoryEmailResendMessage: 'Your report is being generated and will send when complete. This may take a few minutes.',
  txtInventorySubmittedValue: 'Submitted',
  txtInventorySubmitAsZero: 'Submit as 0',
  txtInventoriesEntries: 'Entries',
  txtInventoriesLock: 'Lock',
  txtInventoriesLockConfirmationTitle: 'Lock inventory',
  txtInventoriesLockConfirmationDescription: 'Please confirm you want to lock this inventory count. All other unlocked counts dated before this one will also be locked.',
  txtInventoriesUnlock: 'Unlock',
  txtInventoriesUnlockConfirmationTitle: 'Unlock inventory',
  txtInventoriesUnlockDisabledTooltip: 'You can only unlock up to 10 inventories at a time. In order to unlock this inventory, you must first unlock the more recent inventories.',
  txtInventoriesUnlockConfirmationDescription: `Unlocking inventories applies your current POS mapping to the past and has a significant impact on your historical data.

Please confirm you want to unlock this inventory count. All other inventory counts from the date of this count to now will also be unlocked.

Doing so you acknowledge that this will impact your historical data.`,
  txtInventoriesLockedDescription: 'The inventory count is locked and cannot be edited.',
  txtInventoriesLocked: 'Locked',
  txtInventoriesUnlocked: 'Unlocked',
  txtInventorieLockStatus: 'Lock Status',
  txtInventoryDetailsPopupCountsByVariation: 'Counts by Item Variation',
  txtInventoryDetailsPopupConvertedDefaultVariation: 'Summary - Converted to Default Variation',
  tplInventoryDetailsScheduledLock: 'This inventory will be locked at {a}',
  txtInventoryMarkAsCompleted: 'Mark as completed',
  txtInventoryOpen: 'Go to Inventory',
  txtInventoryLoadAll: 'Load all inventories',
  txtInventoryScheduledNextChange: 'Change next inventory date',
  tplInventoryScheduledNext: 'Next inventory scheduled for {a}',
  txtInventoryUnusualDuration: 'The inventory was started more than 48 hours ago',

  txtIndependentInventory: 'Partial Inventory',
  txtIndependentInventories: 'Partial Inventories',
  txtIndependentInventoriesAdd: 'Add partial inventory',
  txtIndependentInventoriesGroupsManage: 'Manage groups',
  txtIndependentInventoriesGroups: 'Groups',
  txtIndependentInventoriesGroup: 'Group',
  txtIndependentInventoriesGroupMissingMessage: 'Please create or select a group to see the partial inventories for it',
  tplIndependentInventoriesEditTitle: 'Partial inventory by {a}',
  txtIndependentInventoriesGroupsAdd: 'Add group',
  txtIndependentInventoriesGroupsUpdate: 'Update group',
  txtIndependentInventoriesGroupsArchive: 'Archive group',
  txtIndependentInventoriesGroupsArchiveDescription: 'Are you sure you want to archive "{a}" group?',

  //view Variance
  txtVarianceTitle: 'Variance',
  txtVarianceNotificationUnmapped: 'Fix unmapped items',
  txtVarianceSelectInterval: 'Select a Variance period',
  txtVarianceStatsVarianceCost: 'Variance Cost',
  txtVarianceStatsVarianceRetail: 'Variance Retail',
  txtVarianceGroups: 'Variance Groups',
  txtVarianceGroupsManage: 'Manage Variance Groups',
  txtVarianceEmailOverviewPDF: 'Download Variance Overview PDF',
  txtVarianceEmailOverview: 'Variance overview',
  txtVarianceDownloadMultiVenueInventoryXLS: 'Multi Venue Inventory Spreadsheet',
  txtVarianceGroupsViewActive: 'View Variance Groups',
  txtVarianceGroup: 'Variance Group',
  txtVarianceGroupsNew: 'New Variance Group',
  txtVarianceStatsShrinkagePercentage: 'Shrinkage Percentage',
  txtVarianceUnmappedModalTitle: 'Unmapped POS Items',
  tplVarianceUnmappedMessage: 'You have {a} unmapped POS Items, representing ${b} ({c}) of the total sales for the selected interval. ',
  txtVarianceUnmappedForceMapMessage1: 'To view the variance you must have over 80% of your sales mapped. ',
  txtVarianceUnmappedForceMapMessage2: 'Please map or archive the following POS Items to view your variance.',
  txtVarianceUploadSalesManually: 'Upload sales manually',
  txtVarianceImproveYourBeverageCost: `Improve your beverage cost by reducing shrinkage from over-pouring, spillage, and theft.
  The variance page compares your POS data with your consumption data to let you know exactly how much money you are losing - down to the ounce.`,
  txtVarianceStartReducing: 'Start reducing your variance today!',
  tplVarianceDashboardSelectedInventory: 'This data is based on the last two inventories in the selected range. ({a})',
  txtVarianceDetails: 'Variance Details',
  tplVarianceConsumptionUM: 'Consumption ({a})',
  tplVarianceSalesUM: 'Sales ({a})',
  tplVarianceUM: 'Variance ({a})',

  //view POSItems
  txtPOSItemsTitle: 'POS Items',
  txtPOSItem: 'POS Item',
  txtPOSItemsCopyOnlyIfFound: 'Only copy if there is a match',
  txtPOSItemsNewPOSItem: 'New POS Item',
  txtPOSItemsDownloadSpreadsheetPOSItems: 'Export POS Items as Spreadsheet',
  txtPOSItemsCostAlert: 'Cost Alert',
  txtPOSItemsCostPercentageHistory: 'Cost Percentage History',
  txtPOSItemsCostPercentageHistoryTargetValue: 'Alert Percentage',
  txtPOSItemsCostPercentageHistoryNotification: 'Notification',
  txtPOSItemsCostPercentageHistoryCostPerIngredient: 'Cost per Ingredient',
  txtPOSItemsCostAlertClear: 'Clear Cost Alert',
  txtPOSItemsAboveCostAlert: 'Above Cost Alert',
  txtPOSItemsCostAlertRelative: 'Relative Cost Alert',
  txtPOSItemsSearch: 'Search POS Item',
  txtPOSItemsImportIngredientsFrom: 'Import ingredients from another POS item',
  txtPOSItemsImportIngredientsDirectly: 'Import ingredients',
  txtPOSItemsIngredientsBatchAddConfirmation: 'The selected ingredients will be added shortly to the selected POS items.',
  txtPOSItemsImportIngredientsInfo: 'Add one or more ingredients to the selected POS items. When ready, press Submit to save the changes.',
  txtPOSItemsImportIngredientsConfirm: 'The following ingredients will be imported to all selected POS items:',
  txtPOSItemsImportIngredientsFromSearch: 'Search for the POS item from which to import the ingredients into the selected ones',
  txtPOSItemsServingSize: 'Serving Size',
  txtPOSItemsServingSizes: 'Serving Sizes',
  txtPOSItemsServingSizeName: 'Serving size name',
  txtPOSItemsServingSizeNew: 'New serving size',
  txtPOSItemsAvailalbeServingSizes: "Available serving sizes based on item's unit of measurement.",
  tplPOSItemsNotificationUnmapped: 'You have {a} unmapped items.',
  txtPOSItemsAllItemsMapped: 'Congratulations! All of your POS items are mapped.',
  tplPOSItemsNotificationSelectedFilter: 'Displaying items filtered by "{a}" filter.',
  txtPOSItemsNotificationUpdateUnmapped: 'Click here to update.',
  txtPOSItemsNotificationClearFilter: 'Clear filter',
  txtPOSItemsSuggestedPrice: 'Suggested Price',
  txtPOSItemsSearchIngredient: 'Search for ingredient',
  txtPOSItemsSuggestedCostPercentage: 'Suggested Cost Percentage',
  txtPOSItemsMostSoldLast30Days: 'Most Sold Last 30 Days',
  txtPOSItemsByPOSCode: 'By POS Code',
  txtPOSItemsByPOSItem: 'By POS Item',
  txtPOSItemsLast30SalesCost: 'Last 30 Days Sales Cost',
  txtPOSItemsLast30Total: 'Last 30 Days Total',
  txtPOSItemsLast30Profit: 'Last 30 Days Profit',
  txtPOSItemsLast30QuantitSold: 'Last 30 Days Quantity sold',
  txtPOSItemRecipePDFMultiple: 'Recipe book for multiple pos items',
  txtPOSItemIntegrationSetup: 'POS Integration Setup Instructions',
  txtPOSItemIntegrationNeedsSuperUser: 'This integration needs a member from the WISK Team to complete it.',
  txtPOSItemsFreshGlance: 'A fresh glance at your POS',
  txtPOSItemsFreshGlanceExplanation:
    'How are you interacting with the items in your POS, and what sorts of trends and insights can be gathered? With true POS integration WISK lets you quickly see your data like never before.',
  txtPOSItemsTryIntegration: "Try WISK's POS integration today!",
  txtPOSItemsGo: 'Go to POS items page',
  txtPOSEditClearData: 'Clear previous data',
  txtPOSItemIngredientCost: 'Ingredient Cost',
  txtPOSItemIngredientCostBreakdown: 'Ingredient Cost Breakdown',
  txtPOSItemTotalPOSItemCost: 'Total POS Item Cost',
  txtPOSItemCostPercentage: 'POS Item Cost Percentage',
  txtPOSItemProfitPercentage: 'Profit Percentage',
  txtPOSItemCostMoreThanAlert: 'Cost is above alert level.',
  txtPOSItemCostLessThanAlert: 'Cost is below alert level.',
  txtPOSItemCostAlertEmpty: 'Set an alert to track cost increases',
  txtPOSItemPercentageOfTotalCost: '% of total cost',
  txtPOSItemCloneAsDraft: 'Clone as draft',
  txtPOSItemCloneAsDraftConfirmationMessage: 'Please confirm cloning the selected item as draft',
  txtPOSItemDraftPOSItem: 'Draft POS Item',
  txtPOSItemDraftPOSItemConvert: 'Convert to draft POS item',
  txtPOSItemDraftPOSItemRevert: 'Revert to normal POS item',
  txtPOSItemNewDraftPOSItem: 'New draft POS Item',
  txtPOSItemDraftPOSItems: 'Cost calculator (Draft POS Items)',
  txtPOSItemSearchBasedOn: 'Search in',
  txtPOSItemSearchPOSItems: 'POS Items',
  txtPOSItemSearchDraftPOSItems: 'Draft POS Items',
  txtPOSItemsNeedReview: 'Need review',
  txtPOSItemShowIngredients: 'Show all ingredients',
  txtPOSItemHistory: 'History',
  txtPOSItemRecipeCostIngredients: 'Recipe Cost Ingredients',
  tplPOSItemsNeedReviewTitleChanged: 'The title has changed from {a} to {b}. Please review and confirm the ingredients are still correct.',

  txtPOSModifiers: 'Modifiers',
  txtPOSModifier: 'POS Modifier',
  txtPOSModifierNew: 'New POS modifier',
  txtPOSModifierApplyChange: 'Save',
  txtPOSModifierMappingType: 'Mapping type',
  txtPOSModifierMultiplier: 'Multiplier',
  txtPOSModifierMappingSubtype: 'Operation',
  groupPOSModifierTypes: {
    not_mapped: 'Not mapped',
    label: 'Label',
    ingredient_change: 'Ingredient change',
    measurement_change: 'Measurement change',
    //subtypes
    add: 'Add',
    subtract: 'Subtract',
    remove: 'Remove',
    multiplier: 'Multiplier',
    base_change: 'Base change'
  },

  //view subrecipes
  txtSubrecipesTitle: 'Batches',
  txtSubrecipeTitle: 'Batch',
  txtSubrecipeGetRecipePDF: 'Download recipe book',
  txtSubrecipeRecipe: 'Recipe book',
  tplSubrecipeRecipePDFFor: 'Recipe book for {a}',
  txtSubrecipeRecipePDFMultiple: 'Recipe book for multiple batches',
  txtSubrecipesNew: 'Create new batch',
  txtSubrecipesEdit: 'Edit batch',
  txtSubrecipesAsItem: 'Used as inventory item',
  txtSubrecipesLinkedItem: 'Linked inventory item',
  txtSubrecipesLinkedSubrecipe: 'Linked batch',
  txtSubrecipesCopyToVenues: 'Copy to venues',
  txtSubrecipesCopyToVenuesConfirmMessage:
    "The selected batches will be copied to all selected venues. If the batch doesn't exist in a selected venue, it's created. If the batch already exists, the recipe won't be updated.",
  txtSubrecipesRealTimeStockNotAvailable: 'Real time stock for batch inventory items is not available',

  //view ShoppingCart
  txtShoppingCartDownloadSpreadsheetAllDistributors: 'Download Shopping Cart Spreadsheet for all Distributors',
  txtShoppingCartClear: 'Clear shopping cart',
  txtShoppingCartSAQOrderModalTitle: 'SAQ Order',

  //view CartBuilder
  txtCartBuilderOrderGuideItems: 'Order guide items',
  txtCartBuilderOrderGuideItem: 'Order guide item',
  txtCartBuilderInOrderGuide: 'In order guide',
  txtCartBuilderNotInOrderGuide: 'Not in order guide',
  txtCartBuilderOrderGuideItemsSet: 'Mark as order guide items',
  txtCartBuilderOrderGuideItemsClear: 'Remove from order guide items',
  txtCartBuilderPrefill: 'Prefill',
  txtCartBuilderWarningInventoryStarted: 'There is currently an inventory in progress. WISK cannot suggest amounts to order until the inventory is submitted and the Stock count is updated',
  txtCartBuilderPrefillYourPar: 'Based on your par',
  txtCartBuilderPrefillWiskPar: 'Based on WISK par',
  txtCartBuilderFilterYourPar: 'With suggested order - using your par',
  txtCartBuilderFilterWiskPar: 'With suggested order - using WISK par',
  txtCartBuilderPrefillYourParFullBottles: 'Using your par - full items',
  txtCartBuilderPrefillYourParRealTimeStock: 'Using your par - real-time stock',
  txtCartBuilderViewRealTimeStock: 'Real-time stock',
  txtCartBuilderClearDistributorCart: 'Clear Distributor Cart',
  txtCartBuilderAddToCart: 'Add to cart',
  tplCartBuilderAddBottleTo: 'Add item to {a}',
  txtCartBuilderPrepareYourOrders: 'Prepare your orders in minutes!',
  txtCartBuilderPrepareYourOrdersExplanation:
    'The ordering module will allow you to place orders to all of your suppliers with accuracy and ease. Manage all your distributors, and distributor contact information so you can send orders via email or SMS.',
  txtCartBuilderStartOrdering: 'Start ordering items with WISK!',
  txtCartBuilderStartOrderingBottles: 'Start ordering items with WISK!',

  //view OrderPage
  txtOrderPageCartBuilderTitle: 'Step 1: Cart Builder',
  tplOrderPageShoppingCartTitleCount: 'Step 2: Your cart ({a} items)',
  txtOrderPageShoppingCartTitleEmpty: 'Step 2: Your cart (empty)',
  tplOrderPagePurchaseOrdersTitleCount: 'Step 3: Purchase Orders ({a})',
  txtOrderPageIntakesTitle: 'Step 4: Invoices',

  //view PurchaseOrders
  txtPurchaseOrder: 'Purchase Order',
  txtPurchaseOrdersNew: 'New purchase order',
  txtPurchaseOrdersConvertIntake: 'Generate invoice',
  txtPurchaseOrdersMarkSent: 'Mark as sent',
  txtPurchaseOrdersLinkToInvoice: 'Link to invoice',
  txtPurchaseOrdersMarkReceived: 'Mark as received',
  txtPurchaseOrdersMarkReceivedNoIntake: 'Mark as received without invoice',
  txtPurchaseOrdersAddItemToOrder: 'Add item to order',
  txtPurchaseOrdersAddOrder: 'Add order',
  txtPurchaseOrdersSelectDistributorOrPartnerVenue: 'Add from distributor or partner venue',
  txtPurchaseOrdersSelectDistributor: 'Select Distributor',
  txtPurchaseOrdersSelectPartnerVenue: 'Select partner venue',
  tplPurchaseOrdersFloatingSelectedCount: 'order(s) selected of {a}',
  txtPurchaseOrdersAddWiskItem: 'Add WISK Item',
  txtPurchaseOrdersPricePerUnit: 'Price per unit',
  txtPurchaseOrdersSAQInfo: 'Copy the SAQ order to Clipboard and then Open the SAQ portal',
  txtPurchaseOrdersSAQOpen: 'Open SAQ Portal',
  txtPurchaseOrdersSAQOK: 'Order placed',
  txtPurchaseOrdersSAQCancel: 'Not yet',
  txtPurchaseOrdersGeneratedSuccessTitle: 'Purchase order generated',
  txtPurchaseOrdersGeneratedSuccessMessage: 'Purchase order generated successfully',
  txtPurchaseOrdersViewOrder: 'View order',
  txtPurchaseOrdersClearDeliveryStatus: 'Clear delivery status',
  txtPurchaseOrdersDownloadSpreadsheet: 'Download Spreadsheet',
  txtPurchaseOrdersEmail: 'Email order',
  txtPurchaseOrdersSend: 'Send order (Email/SMS)',
  txtPurchaseOrdersEmailSMS: 'Email / SMS order to distributor',
  txtPurchaseOrdersSendForApproval: 'Send for approval',
  txtPurchaseOrdersApprove: 'Approve',
  txtPurchaseOrdersDecline: 'Decline',
  tplPurchaseOrdersStatusDeclined: 'Declined by {a} on {b}',
  tplPurchaseOrdersStatusRequested: 'Requested by {a} on {b}',
  tplPurchaseOrdersStatusApproved: 'Approved by {a} on {b}',
  groupPurchaseOrdersStatus: {
    approved: 'Approved',
    declined: 'Declined',
    sent_for_approval: 'Requested',
    pending_approval: 'Pending approval'
  },
  groupPurchaseOrdersSentStatus: {
    not_sent: 'Not sent',
    sent: 'Sent',
    sending: 'Sending'
  },
  //TODO: check if this will be needed
  groupPurchaseOrdersApprovalStatus: {
    not_received: 'Not received',
    pending_approval: 'Pending approval',
    approved: 'Approved',
    requested: 'Requested',
    canceled: 'Canceled',
    in_preparation: 'In Preparation',
    declined: 'Declined',
    received: 'Received',
    received_manual_intake: 'Received with manual invoice'
  },
  txtPurchaseOrdersEmailSentStatus: 'Email sent status',
  txtPurchaseOrdersEmailSentText: 'Email Sent',
  txtPurchaseOrdersSMSSentStatus: 'SMS sent status',
  txtPurchaseOrdersDeclinePromptMessage: 'Decline reason',
  txtPurchaseOrdersApprovalStatus: 'Approval status',
  txtPurchaseOrdersApprovalStatusResetMessge: 'The purchase order status will be set back to pending approval upon modification of the order.',
  txtPurchaseOrdersEmailSent: 'The purchase order email was sent to the distributor.',
  txtPurchaseOrdersSendSAQ: 'Send to SAQ',
  txtPurchaseOrdersNotSent: 'Not sent to distributor',
  txtPurchaseOrdersNotReceived: 'Not received yet',
  txtPurchaseOrdersNotes: 'Notes to send to the distributor',
  txtPurchaseOrdersNotesModalTitle: 'Send notes to distributor',
  txtPurchaseOrdersNotesSend: 'Send',
  txtPurchaseOrdersEmailPressEnter: 'Press enter to add a new email address',
  txtPurchaseOrdersPhonePressEnter: 'Press enter to add a new phone number',
  txtPurchaseOrdersAddItemPlaceHolderDisabled: 'Search partner venue using the button above',
  tplPurchaseOrdersEmailSubject: 'Order from {a} to {b}',
  tplPurchaseOrdersConvertIntakeTitle: 'New invoice to {a}',
  txtPurchaseOrdersConvertIntakeMessage: 'Selected order will be marked as received and converted to invoice',
  txtPurchaseOrdersClonedMessage: 'Cloned successfully, opening cloned purchase order',

  //view DashBoard
  txtDashBoardNewVenue: 'Need to add another venue? Click here to book a call with the team',
  txtDashBoardFillOnboarding: 'Fill your venue onboarding items',
  txtDashBoardVenueSetupProgress: 'Venue Setup Progress',
  txtDashBoardVenueSetupProgressInfo: 'Complete your checklist to take control of your costs.',
  txtDashBoardVenueSetupAssignedDistributors: 'Assigned',
  txtDashBoardVenueSetupAddedItemCosts: 'Added',
  txtDashBoardUpdateMissingPOSItems: 'Update missing POS items',
  txtDashBoardVarianceBy: 'Variance by',
  txtDashBoardRefreshDashboard: 'Refresh dashboard data',
  txtDashBoardRefreshDashboardMessage: 'We have triggered a refresh of your dashboard data. Please check again in a few minutes.',
  txtDashBoardUpdateMissingPrices: 'Update missing prices',
  txtDashBoardUpdateMissingDistributors: 'Update missing distributors',
  txtDashBoardUpdateMissingParLevels: 'Update missing Par Levels',
  txtDashBoardInventoryDurationMinutes: 'Inventory duration in minutes',
  txtDashBoardInventoryDurationTime: 'Inventory duration over time',
  txtDashBoardInventoryValueOverTime: 'Inventory value over time',
  txtDashBoardInventoryWeeklyIntakesOverTime: 'Inventory & Weekly Invoices over time',
  txtDashboardInventoryPercentChange: 'Percent change',
  txtDashBoardStockInformationBasedOn: 'Stock Information based on',
  txtDashBoardHealthOverTimeGraphTitle: 'Health over time: ',
  txtDashboardInventoryLast: 'Last Inventory',
  txtDashboardDeliveryLast: 'Last Invoice',
  txtDashboardOrderLast: 'Last Order',
  txtDashboardRecentlyAddedItems: 'Items added in last 30 days',
  txtDashboardInventoryNext: 'Next Inventory',
  txtDashboardUniqueItems: 'Unique Items',
  txtDashboardTotalItems: 'Total Items',
  txtDashboardTotalValue: 'Total Value',
  txtDashboardVenueStats: 'Venue Stats',
  tplDashboardDateBy: '{a} by {b}',
  tplDashboardDateScheduled: 'Scheduled for {a}',
  groupDashBoardHealthOverTimeGraphTypes: {
    health: 'Overall',
    prices: 'Prices',
    distributors: 'Distributors',
    par_levels: 'Par Levels',
    pos_items: 'POS Items'
  },
  txtDashBoardSavedThisMonth: 'Labor Saved in the last 30 Days',
  txtDashBoardstockPredictionBasedOn: 'Stock Prediction based on',

  txtDashboardOverview: 'Overview',
  txtDashboardOverviewLossAtCost: 'Loss at Cost',
  txtDashboardOverviewRevenuePotential: 'Variance Retail',
  txtDashboardOverviewActualCost: 'Actual Cost',
  txtDashboardOverviewIdealCost: 'Target Cost',
  txtDashboardOverviewPerformance: 'Performance',
  txtDashboardOverviewActual: 'Actual',
  txtDashboardOverviewIdeal: 'Target',
  txtDashboardOverviewUsedCost: 'Consumption Cost',
  txtDashboardOverviewTargetConsumptionCost: 'Target Consumption Cost ($)',
  txtDashboardOverviewHistoricalSale: 'Historical Sales',
  txtDashboardOverviewHistoricalPurchases: 'Historical Purchases',
  txtDashboardInventory: 'Inventory',
  txtDashboardInventoryPreviousOnHand: 'Beginning Inventory Value',
  txtDashboardInventoryCurrentOnHand: 'Ending Inventory Value',
  txtDashboardInventoryOnHandVariance: 'Inventory Variance',
  txtDashboardInventoryOverUnderstock: 'Over / Understock $',
  txtDashboardInventoryHistoricalOnHand: 'Historical Inventory Value',
  txtDashboardInventoryDeadStock: 'Dead Stock',
  txtDashboardInventorySlowMoving: 'Slow-moving',
  txtDashboardInventoryCombinedTotals: 'Combined Totals',
  txtDashboardInventoryStockEfficiency: 'Stock Efficiency Rating',
  txtDashboardInventoryTop10DeadStock: 'Top 10 Dead Stock Items',
  txtDashboardInventoryLast30Days: 'last 30 days',
  txtDashboardInventoryStockValue: 'Stock Value',
  txtDashboardInventoryOnHand: 'Inventory Value',
  txtDashboardInventoryTurnover: 'Inventory Turnover',
  txtDashboardInventoryTop10SlowMoving: 'Top 10 Slow-moving Items',
  txtDashboardInventoryUsed: 'Used',
  txtDashboardPurchases: 'Purchases',
  txtDashboardPurchasesHistorical: 'Historical Purchases',
  txtDashboardPurchasesTopProducts: 'Purchased Products by Total',
  txtDashboardPurchasesDetailedItemPurchases: 'Detailed Item Purchases',
  txtDashboardPurchasesProduct: 'Product',
  txtDashboardPurchasesTotalPurchased: 'Total Purchased',
  txtDashboardPurchasesPurchasesBy: 'Purchases By',
  txtDashboardPurchasesDollarCurrent: 'Current Period Purchases ($)',
  txtDashboardPurchasesDollarPrevious: 'Previous Period Purchases ($)',
  txtDashboardPurchasesQuantity: 'Purchases Quantity',
  txtDashboardPurchasesUnits: 'Purchases Units',
  txtDashboardVariance: 'Variance',
  txtDashboardVarianceRetailVariances: 'Retail Variances',
  txtDashboardVarianceByFamily: 'Variance By Family',
  txtDashboardVarianceByCategory: 'Variance By Category',
  txtDashboardVarianceFamilyVariancesActual: 'Actual',
  txtDashboardVarianceFamilyVariancesPotential: 'Potential',
  txtDashboardVarianceTopLosses: 'Top 10 by Losses',
  txtDashboardVarianceTopUnderUsage: 'Top 10 by Under Usage',
  txtDashboardVarianceDetailedReport: 'Detailed Variance Report',
  txtDashboardVariancePeriodEnding: 'Period Ending',
  txtDashboardVarianceTrend: 'Variance Trend',
  txtDashboardSalesSale: 'Sales',
  txtDashboardSalesPercentage: 'Sales (%)',
  txtDashboardSalesPotentialProfit: 'Potential Profit',
  txtDashboardSalesIdealCOGS: 'Ideal COGS',
  txtDashboardSalesShrinkage: 'Shrinkage',
  txtDashboardSalesBreakdownByFamily: 'Sales Breakdown by Family',
  txtDashboardSalesOverallTopBySale: 'Overall - Top Sales',
  txtDashboardSalesOverallTopByProfit: 'Overall - Top Profits',
  txtDashboardInventoryNotFound: 'Not Enough Data',
  txtDashboardInventoryRequired: 'You must complete at least 2 inventory counts to generate and view dashboard data.',
  txtDashboardInventoryNotFoundDesc: 'No inventory found with the given id.',
  txtDashboardRecalculate: 'click here to recalculate',
  txtDashboardObsolete: 'Variance for this period was generated at {a} and not up to date',
  txtDashboardRecalculating: 'Your data will be recalculated.',

  //component OnlineItemSearch
  txtOnlineItemSearchCatalog: 'Search online catalog',
  txtOnlineItemSearchDistributor: 'Select item from distributor',
  tplOnlineItemSearchDistributor: "Search {a}'s items",
  txtOnlineItemSearchPartnerVenue: 'Search partner venue',
  tplOnlineItemSearchPartnerVenue: "Search {a}'s items",
  tplOnlineItemSearchMinChars: 'Please input {a} or more characters',

  //component ItemOperations
  txtItemOperationsSearchMode: 'Search mode for item',
  txtItemOperationsSearchLocal: 'Search your local items',
  txtItemOperationsSearchWisk: 'Search WISK for item',
  txtItemOperationsMeasurementQuantity: 'Quantity',
  txtItemOperationsMeasurementAlias: 'Alias',
  txtItemOperationsBarcodePrint: 'Print Barcode',
  txtItemOperationsMeasurementType: 'Measurement Type',
  txtItemOperationsCloneSuffix: ' - (clone)',
  txtItemOperationsParLevelSwitchToCases: 'switch to par level per case',
  txtItemOperationsParLevelSwitchToUnits: 'switch to par level per unit',
  txtItemOperationsInventoryAreas: 'Inventory areas',
  txtItemOperationsAdditionalFields: 'Additional Fields',
  txtItemOperationsCaseSizeCreateVariantConfirm: 'Create new item variation?',
  txtItemOperationsCaseSizeChangeCancel: 'Create new item variation',
  txtItemOperationsCaseSizeChangeOK: 'Change current case size',
  txtItemOperationsCaseSizeChangeMessage: `Please confirm that you wish to change the case size of this item variation.
  Changing the case size will only apply to invoices and inventory counts moving forward.

  If the case size was incorrect in the past, you will need to edit your previous invoices/inventory counts.

  If the item can be ordered/received in multiple case sizes (6, 12, 24, etc.), we recommend creating a new item variation instead of changing the current case size.`,
  tplItemCostDiscountDisplay: 'Original: {a}, Discount: {b}',
  tplItemCostDiscountDisplayWithUM: 'Original: {a}/{c}, Discount: {b}',

  //component BarcodePrinterSetup
  txtBarcodePrinterConfig: 'Barcode printer config',
  txtBarcodePrinterNone: 'No barcode printer selected',
  txtBarcodePrinterName: 'Barcode printer',
  txtBarcodePrinterSoftware: 'Printer Software',
  txtBarcodePrinterLabelSize: 'Label size',

  //component ItemsMerge
  txtBottlesMergeStartingBottles: 'Items to be merged',
  txtBottlesVariantMergeStartingBottles: 'Variations to be merged',
  tplBottlesMergeBottlesList: 'Items to merge or group with {a}',
  txtBottlesMergeStartingBottlesGroup: 'Items to be grouped',
  txtBottlesVariantMergeStartingBottlesGroup: 'Variations to be grouped',
  txtBottlesMergeBottlesAdd: 'Add to the list of items to be merged or grouped',
  txtBottlesMergeBottlesSearch: 'Search for items',
  txtBottlesMergeTitle: 'Merge items',
  txtBottlesVariantMergeTitle: 'Merge item variations',
  txtBottlesGroupTitle: 'Group items',
  txtBottlesMergeOrGroup: 'Merge or group',
  txtBottlesMergeRevert: 'Ungroup',
  txtBottlesMeasurementLockedInfo: 'Item measurement is locked while the dependent data is being recalculated.',
  txtBottlesMergeInfoResulted: 'Resulted item',
  txtBottlesVariantMergeInfoResulted: 'Resulted variation',
  txtBottlesMergeInfoHowTo2: ' to use that value in the resulted item ',
  txtBottlesMergeInfoHowTo1: 'Choose item and click on the button',
  txtBottlesVariantMergeInfoHowTo2: ' to use that value in the resulted variation ',
  txtBottlesVariantMergeInfoHowTo1: 'Choose variation and click on the button',
  txtBottlesMergeWaitingMessage: 'The selected items are being merged. Please check in a few moments.',
  txtBottlesMergeGroupWaitingMessage: 'The selected items are being grouped. Please check in a few moments.',
  txtBottlesMergeVolumeValidationMessage: 'The measurement type and quantity of the items to be merged must match',
  txtBottlesGroupVolumeValidationMessage: 'The measurement type of the items to be grouped must match',
  txtBottlesMergeTooManySelectedMessage: 'A max of 5 items can be merged or grouped',
  txtBottlesMergeRangeSelectedMessage: 'Please select 2 to 5 items to merge',
  txtBottlesGroupRangeSelectedMessage: 'Please select at least 2 items to group',
  txtBottlesGroupOpenResulted: 'This item was grouped with another item and is no longer editable. Click here to see the resulted item variation',
  txtBottlesMergedArchived: 'This item was archived after being merged with another and is no longer editable',
  txtBottlesVariantGroupDisabled: 'Grouping is not available for variations',
  groupBottlesAlgoPerpetualStatus: {
    pos_not_integrated: 'POS not integrated',
    no_recent_inventory: 'No recent inventory',
    not_enough_pos_items_mapped: 'Not enough POS items mapped'
  },
  //component ItemSwap
  txtItemSwap: 'Swap Item',
  tplItemSwapTitle: 'Swap {a}',
  txtItemSwapThisWillBeReplaced: 'This item will be replaced in the selected POS items and batches',
  txtItemSwapThisWillBeReplacement: 'This item will be used in the selected POS items and batches',
  tplItemSwapConfirmMessage: 'Are you sure you want to replace {a} with {b} in the selected items?',
  txtItemSwapSuccessNotify: 'Items are being swapped. This may take a few moments.',
  txtItemSwapFindReplacement: 'Search for replacement item',
  txtItemSwapClearTooltip: 'Search for another item',

  //component ItemDetails
  txtItemDetailsInventoryHistory: 'Inventories / Invoices / Depletions',
  txtItemDetailsSoldSinceInventory: 'Sold since last inventory',

  //component ItemStatsGraph
  txtItemStatsGraphWeeklySales: 'Weekly Sales',
  txtItemStatsGraphWeeklyIntake: 'Weekly Invoice',

  //component VenueOperations
  txtVenueOperationsName: 'Venue name',
  txtVenueOperationsType: 'I will primarily use WISK for:',
  txtVenueOperationsTypeBarInventory: 'Bar Inventory',
  txtVenueOperationsTypeRestaurantInventory: 'Restaurant Inventory',
  txtVenueOperationsBusinessDayStartHour: 'Business day starting hour',
  txtVenueOperationsInfo: 'Venue info',
  txtVenueOperationsChoosePlan: 'Choose Plan',
  txtVenueOperationsSelectPlan: 'Select Plan',
  txtVenueOperationsSelectedPlan: 'Selected Plan',
  txtVenueOperationsUpgradeNow: 'Upgrade Now',
  txtVenueUpgradeNowTooltip: 'Get more out of WISK by upgrading to a higher plan',
  txtVenueOperationsChangePlan: 'Change Plan',
  txtVenueOperationsChangePlanReminderSelected: 'You have selected this plan in the previous step',
  txtVenueOperationsChangeSubscriptionPlatform: 'Convert Subscription from Apple to Credit Card',
  txtVenueOperationsPlan: 'Plan',
  txtVenueOperationsDefaultUMVolume: 'Default UM Volume',
  txtVenueOperationsDefaultUMWeight: 'Default UM Weight',
  txtVenueOperationsAccountingIntegration: 'Accounting integration',
  txtVenueOperationsAccountingSetup: 'Setup accounting integration',
  txtVenueOperationsResetLocalVenueData: 'Reload local venue data',
  txtVenueOperationsResyncVenueData: 'Resync venue data',
  txtVenueOperationsResyncAreasData: 'Resync areas data',
  txtVenueOperationsAccountingStatusExpiredMessage: 'Your accounting integraton expired, please do the set up again',
  txtVenueOperationsAccountingSetupDone: 'Completed',
  txtVenueOperationsAccountingSetupNone: 'Not completed',
  txtVenueOperationsSendInvoiceEmail: 'Send invoice by email',
  txtVenueOperationsPlanUpgradeNeeded: 'Plan upgrade necessary',
  txtVenueOperationsCurrentPlan: 'Keep using this plan',
  txtVenueOperationsFintechLabel: 'Fintech Label',
  txtVenueOperationsBilled: 'Billed',
  tplVenueOperationsStartTrialButton: 'Start {a} Day Free Trial',
  tplVenueOperationsTrialDaysLeft: 'Trial days left: {a}',
  txtVenueOperationsPlanPriceMonthly: 'Price monthly',
  txtVenueOperationsPlanPriceYearly: 'Price yearly',
  txtVenueOperationsGoogleAddress: 'Google address',
  txtVenueOperationsPOSType: 'POS Type',
  txtVenueOperationsOnboardingType: 'Onboarding type',
  txtVenueOperationsVenueType: 'Venue type',
  txtVenueOperationsPaymentMethod: 'Payment method',
  txtVenueOperationsPaymentInfo: 'Payment info',
  txtVenueOperationsExternalLinks: 'External links',
  txtVenueOperationsSalesRep: 'Sales Rep',
  txtVenueOperationsPartialMeasuringType: 'Partial measuring type',
  txtVenueOperationsActive: 'Active',
  txtVenueOperationsInventoryHours: 'Inventory hours before WISK',
  txtVenueOperationsInventoryLockDaysAfterApproving: 'Days to lock inventory after approving',
  txtVenueOperationsInventoryLockDaysAfterUnlocking: 'Days to lock inventory after unlocking',
  txtVenueOperationsIdealStockDays: 'Ideal stock days',
  txtVenueOperationsTesting: 'Venue used for testing purposes',
  txtVenueOperationsTransfers: 'Transfers',
  txtVenueOperationsSignature: 'Signature',
  txtVenueOperationsPriceRetail: 'Price retail',
  txtVenueOperationsLiveSales: 'Live sales',
  txtVenueOperationsLineaProContinuousScan: 'LineaPro continuous scans',
  txtVenueOperationsPriceFee: 'Price fee',
  txtVenueOperationsCopyItemsLabel: 'Copy data to new venue',
  txtVenueOperationsCopyItemsNone: 'None',
  txtVenueOperationsCopyItems: 'Wisk Items',
  txtVenueOperationsCopyPOSItems: 'Wisk Items and POS mappings',
  txtVenueOperationsSubscriptionStatus: 'Subscription status',
  txtVenueOperationsVariance: 'Variance',
  txtVenueOperationsWeightedAveragePricing: 'Weighted Average Pricing',
  txtVenueOperationsRequireIntakePhoto: 'Require invoice photo',
  txtVenueStatsCentralItemsList: 'Use a shared WISK Items list',
  txtVenueStatsCentralPOSItemsList: 'Use a shared POS Items list',
  tplVenueOperationsCentralItemsList: 'Populate this venue with a shared WISK Items list from {a}',
  tplVenueOperationsCentralPOSItemsList: 'Populate this venue with a shared POS Items list from {a}',
  tplVenueOperationsCopyAllList: 'Populate this venue with a copy of WISK Items, Batches and POS Items from {a}',
  tplVenueOperationsCopyBatchesList: 'Populate this venue with a copy of only Items and Batches from {a}',
  tplVenueOperationsCopyItemsList: 'Populate this venue with a copy of only Items from {a}',
  txtVenueOperationsCentralVenue: 'Central Venue',
  txtVenueOperationsCouponApply: 'Apply coupon',
  txtVenueOperationsPauseCouponApply: 'Subscription PAUSED',
  txtVenueOperationsCancelSubscription: 'Cancel Subscription',
  txtVenueOperationsAdminFields: 'Super User fields',
  txtVenueOperationsPopulateData: 'Venue data initialization',
  txtVenueOperationsPopulateDataCopy: 'Copy data',
  txtVenueOperationsPopulateDataShare: 'Share data',
  txtVenueOperationsPopulateDataNew: 'New data',
  txtVenuesGoToStripe: 'Go to Stripe',
  txtVenuesStripeId: 'Stripe Id',
  txtVenuesPOSIntegrationStatus: 'POS Integration Status',
  txtVenuesPOS: 'POS',
  txtVenuesRoles: 'Roles',
  txtVenuesPartnerVenues: 'Partner Venues',
  txtVenuesPartnerVenue: 'Partner Venue',
  txtVenuesPartnerVenueCannotChangeNotAdmin: 'Change is disabled because the current user is not admin of selected venue',
  txtVenuesNewRole: 'Add Role',
  txtVenuesNewRoleFromThis: 'New Role based on this',
  txtVenuesNewRoleFromThisInfo: 'The new Role will contain all the permissions from this one',
  txtVenuesRolesPermissions: 'Roles > Permissions',
  txtVenuesPermissions: 'Permissions',
  txtVenuesAddPermission: 'Add Permission',
  txtVenuesAddRoleValidation: 'This role name is already in use',
  txtRoleArchiveError: 'Please remove the role from the following users, and try again:',
  tplRoleRenameValidationLabel: 'Please enter new name for the role {a}',
  txtRoleRenameValidationMessage: 'The selected role name is already in use',
  txtVenuesAddPermissionDisabledAllAdded: 'This role already contains all the permissions available',
  txtVenuesPOSBrandOther: 'My POS is not in the list',
  txtVenuesPOSSelectBrand: 'Select POS Brand',
  txtVenuesNew: 'New Venue',
  txtVenuesWeeklySalesAverage: 'Weekly Sales Average',
  txtVenuesDaysSinceLastSale: 'Time since last sale',
  txtVenuesLastSaleCheck: 'Last time WISK checked for new sales',
  txtVenuesDaysSinceLastSaleImported: 'Last sale imported',
  txtVenuesScore: 'Score',
  txtVenuesHealthScore: 'Health Score',
  txtVenuesPOSItemsScore: 'POS Items Score',
  txtVenuesPricesScore: 'Prices Score',
  txtVenuesDistributorsScore: 'Distributors Score',
  txtVenuesParLevelsScore: 'Par Levels Score',
  txtVenuesVenueBottlesCount: 'Items Count',
  txtVenuesInventoriesCount: 'Inventories count',
  txtVenuesFirstInventoryDate: 'First Inventory Date',
  txtVenuesWeeklyAverageInventories: 'Weekly average inventories',
  txtVenuesLastInventoryValue: 'Last inventory value',
  txtVenuesDaysSinceLastInventory: 'Last inventory date',
  txtVenuesDaysSinceLastIntake: 'Last invoice date',
  txtVenuesIntakesCount: 'Invoices count',
  txtVenuesIntakesWeeklyAverage: 'Invoices weekly average',
  txtVenuesOverStockValue: 'Over stock value',
  txtVenuesOverStockPercentage: 'Over stock percentage',
  txtVenuesUsers: 'Venues > Users',
  txtVenuesUserAdd: 'Add to venues',
  txtVenuesCopy: 'Copy data to other venue',
  txtVenuesClone: 'Create a new test venue based on this one (only for WISK Admin)',
  txtVenuesCopyAnonymize: 'Anonymize',
  txtVenuesCopyWithInventories: 'With inventories',
  txtVenuesCopyWithPOSItems: 'With POS items',
  txtVenuesCopyWithSales: 'With sales',
  txtVenuesCopyWithCustomFields: 'With custom fields',
  txtVenuesCopyAssignUsers: 'Assign users',
  txtVenuesSelect: 'Select Venue',
  txtUsersVenues: 'Users > Venues',

  //Venue POS type operation
  txtPOSIntegrations: 'POS Integrations',
  txtPOSIntegration: 'POS Integration',
  txtPOSIntegrationNickname: 'Nickname',
  txtPOSIntegrationAdd: 'Add POS Integration',
  txtPOSIntegrationEdit: 'Edit POS Integration',
  txtPOSIntegrationArchiveDescription: 'Are you sure you want to deactivate this POS integration?',
  txtPOSIntegrationSuccessfull: 'Integrated successfully',
  txtPOSIntegrationInactiveItems: 'Inactive integrations',
  txtPOSIntegrationModifiersNew: 'Use new modifiers',
  txtPOSIntegrationCreateNew: 'Create new',
  txtPOSIntegrationRestoreInactive: 'Restore Inactive',
  txtPOSIntegrationActiveItems: 'Active integrations',
  txtPOSIntegrationLogs: 'Logs',
  txtPOSIntegrationOauthDescription: 'You should save your new POS integration to continue setup.',
  txtPOSIntegrationClearDataConfirmation: 'Are you sure you want to clear this integration data?',
  txtPOSIntegrationClearData: 'Clear previous data',
  txtPOSIntegrationCategoriesAutoExclude: 'Auto exclude',
  txtPOSExtraSerial: 'Serial',
  txtPOSExtraAPIKey: 'API Key',
  txtPOSExtraGenerateAPIKey: 'Generate an API key',
  txtPOSExtraPasteAPIKey: 'or paste an existing one',
  txtPOSExtraKey: 'Key',
  txtPOSExtraDataName: 'Data Name',
  txtPOSExtraDispensaryName: 'Dispensary name',
  txtPOSExtraShopName: 'Shop Name',
  txtPOSExtraToken: 'Token',
  txtPOSExtraAPIToken: 'API Token',
  txtPOSExtraServer: 'Server',
  txtPOSExtraAuthorization: 'Authorization',
  txtPOSExtraOutlet: 'Outlet',
  txtPOSExtraRestaurantId: 'Restaurant ID',
  txtPOSExtraClientId: 'Client ID',
  txtPOSExtraClientSecret: 'Client Secret',
  txtPOSExtraApiSecret: 'API Secret',
  txtPOSExtraIncludeModifiers: 'Include modifiers',
  txtPOSExtraIncludeModifiersWithPriceOfZero: 'Include modifiers with price of 0',
  txtPOSExtraIncludeTextModifiers: 'Include text modifiers',
  txtPOSExtraIncludeAllPOSItemsEvenIfNotSold: 'Include all POS Items even if not sold',
  txtPOSExtraIncludeAllPOSItems: 'Include all POS Items',
  txtPOSExtraIncludeSubmodifiers: 'Include submodifiers',
  txtPOSExtraModifiersAsSeparateItems: 'Import Modifiers as separate POS Items',
  txtPOSExtraUsePOSCode: 'Use POS Code instead of menu ID',
  txtPOSExtraCompanyId: 'Company ID',
  txtPOSExtraLocationId: 'Location ID',
  txtPOSExtraCg: 'CG',
  txtPOSExtraCmid: 'MID',
  txtPOSExtraNetworkId: 'Network ID',
  txtPOSExtraAuthToken: 'Auth Token',
  txtPOSExtraAccessKey: 'Access Key',
  txtPOSExtraUsername: 'Username',
  txtPOSExtraSecret: 'Secret',
  txtPOSExtraMerchantId: 'Merchant ID',
  txtPOSExtraBucket: 'Bucket',
  txtPOSExtraFolder: 'Folder',
  txtPOSIntegrationStatusExpiredMessage: 'Your POS integraton expired, please do the set up again',
  txtPOSExtraVeloceApiFindLocation: 'Find Location ID',
  txtPOSExtraAldeloAppStoreToken: 'Aldelo AppStore Token',
  txtPOSExtraAldeloAppStoreSubscription: 'Aldelo AppStore Subscription',
  txtPOSExtraRPowerApiSelectStore: 'Select store',
  txtPOSExtraHeartlandLocationKey: 'Heartland Location Key',
  txtPOSExtraMobileBytesLocationKey: 'MobileBytes Location Key',
  txtPOSExtraPluColumn: 'PLU Number column letter (A, B,..)',
  txtPOSExtraPriceAsModifier: 'Use price as modifier',
  txtPOSExtraTitleColumn: 'Title column letter (A, B,..)',
  txtPOSExtraQtyColumn: 'Quantity column letter (A, B,..)',
  txtPOSExtraNetColumn: 'Net Sales column letter (A, B,..)',
  txtPOSExtraImportSalesByRevenueCenter: 'Import sales by revenue center',
  txtPOSExtraImportSalesByLegacyPluNumber: 'Use legacy PLU number',
  txtPOSExtraHostAddress: 'Host address',
  txtPOSExtraGoFunVenueId: 'Venue ID in GoFun system',
  txtPOSExtraIncludeGroupIdIntoPluNumber: 'Include Group ID into PLU number',
  txtPOSExtraImportSpecialRequests: 'Import special requests',

  //component VenueSwitch
  txtVenueSwitchTitle: 'Switch to a different venue',
  txtVenueSwitchLabel: 'Switch to:',

  //component WiskItemsFloatingActions
  txtWiskItemsFloatingActionsMerge: 'Merge items',
  txtWiskItemsFloatingActionsGroup: 'Group items',

  txtGridGroupExpandDoubleClick: 'Double click to expand all',

  //component WiskGrid
  txtWiskFilterContains: 'Contains',
  txtWiskFilterNotContains: 'Not Contains',
  txtWiskFilterIsEmpty: 'Is Empty',
  txtWiskFilterIsNotEmpty: 'Is Not Empty',
  txtWiskFilterLessThan: 'Less Than',
  txtWiskFilterLessThanDate: 'Before',
  txtWiskFilterLessThanOrEqual: 'Less Than or Equal',
  txtWiskFilterGreaterThan: 'Greater Than',
  txtWiskFilterGreaterThanDate: 'After',
  txtWiskFilterGreaterThanOrEqual: 'Greater Than or Equal',
  txtWiskFilterInRange: 'In Range',
  txtWiskFilterNotEquals: 'Not Equals',
  txtWiskFilterNotSet: 'Not Set',
  txtWiskFilterHasValue: 'Has Value',
  txtWiskFilterEquals: 'Equals',
  txtWiskFilterNotEqualsDate: 'Not',
  txtWiskFilterEqualsDate: 'Exact',
  txtWiskExportSettings: 'Export Settings',
  txtWiskFilterIsOneOf: 'Is one of',
  txtWiskFilterIsNotOneOf: 'Is not one of',
  tplWiskExportPageCount: 'Page {a} of {b}',

  //component WiskItemsGrid
  txtWiskItemsGridGroupBy: 'Group by',
  tplWiskItemsGridCustomizeTable: 'Customize "{a}" report',
  txtWiskItemsGridGroupSuggested: 'Suggested reports',
  txtWiskItemsGridGroupMyViews: 'Your reports',
  txtWiskItemsClearParLevel: 'Clear Par Level',
  txtWiskGridAddViewLabel: 'Report Name',
  tplWiskGridRenameViewMessage: 'Please enter new name for the report {a}',
  tplWiskGridRemoveViewMessage: 'Are you sure you want to remove this report: "{a}"?',
  txtWiskGridCloneViewTitle: 'Clone current report',
  txtWiskGridAddViewTitle: 'Add report',
  txtWiskGridViewsManage: 'Select report',
  txtWiskGridGrouping: 'Grouping',
  tplWiskGridRowSelected: '{a} row selected',
  tplWiskGridRowsSelected: '{a} rows selected',
  txtWiskGridAddViewValidationExists: 'This name is already used for this grid',
  txtWiskGridGroupingPanelDragMessage: 'Drag to group and reorder',
  txtWiskGridGroupingPanelDragGroupDisabledMessage: 'Row group is always sorted first and independently from inner rows',
  txtWiskGridViewModificationDisabledMessageSystem: 'Changes to system reports only last until you refresh the browser. To keep a custom version of this report, click "Clone current report".',
  txtWiskGridViewModificationDisabledMessageUser: 'You cannot edit reports with your current user role.',
  txtWiskGridClonedSuccess: 'Report cloned successfully',
  txtWiskGridGroupRemove: 'Remove from group',
  txtWiskGridGroupCollapse: 'Collapse group',
  txtWiskGridGroupExpand: 'Expand group',
  txtWiskGridFilterEmptyOperationMessage: 'No field selected',
  txtWiskGridFilterOperationValue: 'Value',
  txtWiskGridGroupCollapseChildren: 'Collapse subgroups',
  txtWiskGridGroupExpandChildren: 'Expand subgroups',
  txtWiskGridGroupCollapseAll: 'Collapse all',
  txtWiskGridGroupExpandAll: 'Expand all',
  txtWeightedAveragePricingInfo: 'Your venue uses weighted average for calculating costs.',
  txtWiskGridRefreshData: 'Refresh Grid Data',

  //component ServingSizes
  txtServingSizesTitle: 'Serving sizes',
  txtServingSizesAddAlias: 'Add alias',
  txtServingSizesInputAlias: 'Input alias',
  txtServingSizesShowArchived: 'Show archived',
  txtServingSizesAddServingSize: 'Add serving size',

  //component VarianceDetails
  txtVarianceDetailsBeverageCostOverTime: 'Beverage Cost over time',
  txtVarianceDetailsCostPercentageOverTime: 'Cost percentage over time',
  txtVarianceDetailsSoldConsumed: 'Sold / Consumed',
  txtVarianceIdentifyLosses: 'Identify losses',
  tplVarianceDetailsConsumption: 'Consumption: <span style="font-weight: 700;">{a}</span> units ({b} {c})',
  tplVarianceDetailsDeliveries: 'Invoices:  <span style="font-weight: 700;">{a}</span> units ({b} {c})',
  tplVarianceDetailsInventoryStart: 'Start inventory from {a}:  <span style="font-weight: 700;">{b}</span> units ({c} {d})',
  tplVarianceDetailsInventoryEnd: 'End inventory from {a}:  <span style="font-weight: 700;">{b}</span> units ({c} {d})',
  tplVarianceDetailsDepletion: 'Depletion: <span style="font-weight: 700;">{a}</span> units ({b} {c})',

  //component StockGrid
  txtStockGridCurrentStock: 'Current stock',
  txtStockGridDailyConsumption: 'Daily consumption',
  txtStockGridWeeklyConsumption: 'Weekly consumption',
  txtStockGridStockDays: 'Stock days',
  txtStockGridStockWeeks: 'Stock weeks',
  txtStockGridRecommendedStock: 'Recommended stock',
  tplStockGridStock: '{a} stock',
  tplStockGridStockCost: '{a} stock cost',

  //component PosItemsGrid
  txtPosItemsGridAveragePrice: 'Average Price',
  txtPosItemsGridPosCategoryMenu: 'Pos Menu Category',
  txtPosItemsGridPosCategorySale: 'Pos Sale Category',
  txtPosItemsGridQuantitySold: 'Quantity sold',
  txtPosItemsGridLast30DaysActualCostPercentage: 'Last 30 Days Actual Cost Percentage',
  txtPosItemsGridLast30DaysAveragePrice: 'Last 30 Days Average Price',
  txtPosItemsLastSoldDate: 'Last Sale Date',
  txtPosItemsGridModifier: 'Modifier',
  txtPosItemsGridStatus: 'Status',
  txtPosItemsGridStatusSales: 'Status based on sales',
  groupPosItemsGridStatus: {
    included: 'Included',
    excluded: 'Excluded',
    archived: 'Archived',
    deleted: 'Deleted'
  },
  groupPosItemsGridStatusSales: {
    sold: 'Not sold recently',
    recently_sold: 'Sold recently',
    never_sold: 'Never Sold'
  },

  txtPOSItemEdit: 'Edit POS Item',

  //component SalesGrid
  txtSalesGridSoldPOSItems: 'POS Items Sold',
  txtSalesGridSoldUnits: 'Items Sold',
  txtSalesGridTotals: 'Totals',
  txtSalesGridUnwrapped: 'Unwrapped',
  txtSalesGridTotalSales: 'Total Sales',
  txtSalesGridTotalSalesCost: 'Total Cost',
  txtSalesGridRevenueCenter: 'Revenue Center',
  txtSalesGridRevenueCenters: 'Revenue Centers',
  txtSalesGridAggregationOverall: 'Overall',
  txtSalesGridAggregationByDay: 'By Day',
  txtSalesGridAggregationByWeek: 'By Week',
  txtSalesGridAggregationByLocation: 'By Area',
  txtSalesGridAggregationBusinessDay: 'Business Day',
  txtSalesGridAggregationBusinessWeek: 'Business Week',
  txtSalesGridMostSold: 'Most Sold Last 30 Days ($)',
  txtSalesGo: 'Go to sales page',
  txtSalesFilterByPOSIntegration: 'Filter by POS integrations',

  //component POSItemEdit
  txtPOSItemEditNonBeverageCost: 'Other costs (ex: labour/garnish)',
  txtPOSItemEditNotEditableMessage:
    'This POS Item was imported from your POS. To modify the title or price, please make the changes directly in your POS system. WISK will pull the updated info on the next sales refresh.',

  //component ItemOperationErrorHandler
  txtItemErrorFound: 'The following errors occurred while updating the selected item(s)',
  txtItemErrorUsedInPOSItemsEdit: 'Edit POS item',
  txtItemErrorUsedInSubrecipesEdit: 'Edit batch',
  txtItemErrorRemoveFromShoppingCart: 'Remove from Shopping Cart',
  txtItemErrorUsedInShoppingCart: 'Used in Shopping Cart',
  txtItemErrorUsedInPOSItems: 'Used in POS Items',
  txtItemErrorUsedInSubrecipes: 'Used in Batches',
  txtItemErrorUsedInLastInventory: 'Used in Last Inventory',
  txtItemErrorCantArchive: 'The operation cannot be completed because of the following reasons',
  txtItemErrorCanArchive: 'Please retry the archive',
  txtItemErrorRemoveFromRetryList: 'Keep item, do not archive',

  //cell info ParSuggestedInfo
  txtParSuggestedInfoSetYourPar: 'Set your par level to match WISK par',
  txtParSuggestedInfoIdealStock: 'Ideal stock',
  txtParSuggestedInfoUnderStockBy: 'Under stock by',
  txtParSuggestedInfoNotEnoughData: 'There is not enough data, we need at least two inventories in the last 6 weeks to compute the WISK par (Consumption).',
  txtParSuggestedSalesInfoNotEnoughData: 'There is not enough data to compute the WISK Par (Sales). The Item must be mapped to at least one POS Item and have 6 weeks of sales data.',
  txtParSuggestedInfoInvalidData: 'Invalid Data',
  txtParSuggestedInfoOverStockBy: 'Over stock by',
  tplParSuggestedInfoStockEnoughForDays: 'Stock enough for {a} days / {b} weeks',
  txtParSuggestedInfoRecommendedStock: 'Recommended stock',
  txtParSuggestedInfoDailyConsumption: 'Daily consumption',
  txtParSuggestedInfoWeeklyConsumption: 'Weekly consumption',
  txtParSuggestedSalesInfoDailyConsumption: 'Daily sales',
  txtParSuggestedSalesInfoWeeklyConsumption: 'Weekly sales',

  //cell CellOrderUnitsEditor
  txtCellOrderUnitsEditorRemoveItem: 'Remove item from cart',

  //cell info FamilyInfo
  txtFamilyInfo: 'Family cannot be changed directly, please use the category selector.',

  //cell info CostUnitInfo
  tplCostUnitInfoIntakes: 'Invoices for {a}',
  txtCostUnitInfoNoIntakesFound: 'No invoices found. Update the Unit Cost manually if needed.',
  txtCostUnitInfoIntakesId: 'Invoice Id',
  txtCostUnitInfoUnitCost: 'Unit cost',

  // component MovementEdit
  txtMovementInvoice: 'Invoice',
  txtMovementErrorRemoveFromRetryList: 'Remove from list',
  txtMovementBatchErrorMessage: `
    To proceed with the batch operation, please resolve all errors by either:

      1. Opening each invoice from the grid and fixing errors.

      2. Removing the invoice from the list.

    Ensure all lines in the grid display "No error" before clicking "Retry".
    `,
  txtMovementEditPriceWithoutTax: 'Cost without tax',
  txtMovementEditPricePerUnitOrCase: 'Cost per UM',
  tplMovementEditDiscountPerUnitOrCase: 'Disc. / {a}',
  txtMovementEditInvoiceNumber: 'Invoice number',
  txtMovementEditPartnerVenue: 'Partner venue',
  txtMovementEditInvoiceId: 'Invoice Id',
  txtMovementEditInvoiceTotal: 'Invoice total',
  txtMovementEditInvoiceTotalPaper: 'Scanned total',
  txtMovementEditInvoiceDifference: 'Difference',
  txtMovementEditInvoiceTotalOnPaper: 'Total on paper',
  txtMovementEditLocationFrom: 'From area',
  txtMovementEditLocationTo: 'To area',
  txtMovementCostPerUnit: 'Cost per unit',
  txtMovementEditViewInvoiceImages: 'View attached images',
  txtMovementEditViewInvoiceImagesSideBySide: 'Side by side image view',
  txtMovementEditViewInvoiceImagesSideBySideExit: 'Exit side by side view',
  txtMovementEditEmailPDF: 'Download images in a PDF format',
  txtMovementEditViewIntake: 'View invoice',
  txtMovementEditSubmitIssue: 'Submit issue',
  groupMovementEditIssues: {
    missing_pages: 'Missing pages',
    unreadable: 'Unreadable',
    missing_information: 'Missing information',
    check_for_multiple_invoices: 'Check for multiple invoices'
  },
  txtMovementEditSubmitIssueConfirmationText: 'Are you sure you want to submit issue as {a}?',
  txtMovementEditAddSearchNoResults: 'No results in your venue items, try searching online',
  tplMovementEditPerUnitOrCase: 'per {a}',
  txtMovementEditNoPhotosMessage: 'No photos were taken for this invoice',
  txtMovementEditNotPublicDistributor: 'The selected distributor is a private distributor.',
  txtMovementSource: 'Source',
  txtMovementDepletion: 'Depletion',
  txtMovementDepletionDollar: 'Depletion ($)',
  txtMovementDepletionUnit: 'Depletion (units)',
  txtMovementDepletions: 'Depletions',
  txtMovementDepletionReason: 'Depletion Reason',
  txtMovementAdjustmentReasons: 'Depletion Reasons',
  txtMovementExtraLineReasons: 'Charge / Credit Reasons',
  txtMovementItemAdd: 'Add Item',
  txtMovementExtraLineAddCharge: 'Add Charge',
  txtMovementLockedDescription: 'This invoice is in a locked inventory period and cannot be edited.',
  txtMovementLinkToPurchaseOrder: 'Link to purchase order',

  txtMovementAccountingSend: 'Send to accounting',
  txtMovementAccountingResend: 'Resend to accounting',
  txtMovementAccountingSendConfirmation: 'Please confirm all invoice details are correct before sending to your accounting software.',
  txtMovementAccountingResendConfirmation:
    'This invoice has previously been sent to accounting, are you sure you want to send it again? This will create a new expense/bill in your accounting software instead of updating the one that was created originally. You may need to delete the resulting duplicate in your accounting software.',
  txtMovementAccountingSentNotEditable: 'This invoice has been sent to your accounting software in its current state. If you make additional changes you will need to re-send it.',
  txtMovementAccountingSentChanged: 'Changes have been made to this invoice since it was last sent to accounting. Please re-send this invoice to your accounting software to make sure the latest changes are reflected',
  txtMovementAccountingSent: 'Sent to accounting',
  txtMovementAccountingQuickbooksClass: 'Quickbooks class',
  txtMovementAccountingQuickbooksDesktop: 'Get Quickbooks Desktop file',
  txtMovementAccountingQuickbooksDesktopExplain: `This action will mark the selected invoice as sent to accounting and generate a Quickbooks Desktop file for you to download.

  Please confirm all invoice details are correct before generating the file. You cannot make any changes to the invoice once the file is generated.`,
  txtMovementAccountingQuickbooksDesktopExplainMultiple: `This action will mark the selected invoices as sent to accounting and generate a Quickbooks Desktop file for you to download.

  Please confirm all invoice details are correct before generating the file. You cannot make any changes to the invoice once the file is generated.`,
  txtMovementAccountingSentAlready: 'Already sent to accounting',
  txtMovementTransferOutSentAlready: 'Already sent',
  txtMovementTransferOutSent: 'Transfer out sent',
  txtMovementExtraLineAddCredit: 'Add Credit',
  tplMovementExtraLineReasonNew: 'New {a} reason',
  tplMovementsAnalytics: '{a} analytics',
  txtMovementExtraLineReasonNew: 'New adjustment reason',
  tplMovementAdjustmentReasonTitle: 'Depletion reason: {a}',
  txtMovementAdjustmentReasonNew: 'New depletion reason',
  txtMovementEditConvertedSuccessMessage: 'Invoice generated successfully',
  txtMovementEditValidationUnitsOrCases: 'Please enter the number of cases or individual units you are receiving.',
  txtMovementsSearchPlaceholder: 'Search by Distributor name, person, operation type',
  txtMovementsDownloadWithHeader: 'XLS with header details',
  txtMovementsValidationTotalsMatch: 'Total on paper and the computed total does not match',
  txtMovementsTotalsMatch: 'Totals do not match',
  txtMovementsLineMeasurement: 'Quantity',
  txtMovementsItemMeasurement: 'Item measurement',
  txtMovementsClonedMessage: 'Cloned successfully, opening cloned intake',
  tplMovementsFloatingSelectedCount: 'lines selected of {a}',
  txtMovementItemWarningRemoveFromStock: 'Anything you add above will remove the items from stock',
  txtMovementAccessPurchaseOrdersInOncePlace: 'Access all your purchase orders in once place',
  txtMovementAccessPurchaseOrdersInOncePlaceExplanation: `The days of sifting through emails to figure out what you ordered from a certain supplier,
  or what you paid at any given time, are over. Access all your history of purchase orders from
  all your suppliers on this page. Sort by supplier, by category, by product, to easily find any past record of a purchase order.`,
  txtMovementTakeControlOfYourOrders: 'Take control of your orders!',

  groupMovementTypes: {
    intake: 'Invoices',
    return: 'Returns',
    transfer: 'Transfer between areas',
    transfer_in: 'Transfer In',
    transfer_out: 'Transfer to Partner Venue',
    adjustment: 'Depletion'
  },
  txtMovementFlatAggregateOn: 'Aggregate on',
  txtMovementsSummary: 'Movements Summary',
  txtMovementFlatAggregateOnGLAccountItemVariation: 'GL Account + Item Variation',
  txtMovementFlatAggregateOnInvoiceNumberGLAccount: 'Invoice Number + GL Account',
  txtMovementFlatAggregateOnGLAccountYearMonth: 'GL Account + Year + Month',
  txtMovementFlatAggregateOnFamily: 'Family',
  txtMovementFlatAggregateOnFamilyCategory: 'Family + Category',
  txtMovementFlatAggregateOnItemVariation: 'Item Variation',
  txtMovementFlatAggregateOnDistributor: 'Distributor',
  txtMovementFlatAggregatedReports: 'Aggregated Reports',
  txtMovementFlatAggregatedReportGLAccountNotAssigned: 'Not assigned',
  txtMovementFlatSelectedRowMovements: 'Movements for selected row',

  //scan invoice
  txtScannedInvoice: 'Draft Invoice',
  txtScannedInvoiceLineNeedsAttentionResolve: 'Clears the notes and the needs attention flag from this line.',
  txtScannedInvoiceLineNeedsAttentionResolveConfirmMessage: 'Please confirm you want to clear the needs attention flag from this line.',
  txtScannedInvoiceLineRemoveConfirmationTitle: 'Remove invoice line',
  txtScannedInvoiceLineRemoveConfirmMessage: 'Are you sure you want to remove this line?',
  txtScannedInvoiceLineBatchRemoveConfirmMessage: 'Are you sure you want to remove these lines?',
  txtScannedInvoiceLineBatchRemove: 'Remove selected lines',
  txtScannedInvoicePricePerUnit: 'Cost per unit',
  txtScannedInvoicePricePerUnitPrevious: 'Previous cost per unit',
  tplScannedInvoicePricePerUnitIncreased: 'The cost per unit has increased by',
  tplScannedInvoicePricePerUnitDecreased: 'The cost per unit has decreased by',
  tplScannedInvoicePreviousPricePerUnitWarning: 'It was previously {a} per unit.',
  txtScannedInvoices: 'Draft Invoices / Distributor invoices',
  txtScannedInvoicesShort: 'Draft Invoices',
  txtScannedInvoicesLast: 'Last draft invoice',
  txtScannedInvoicesUpload: 'Upload Invoices',
  txtScannedInvoiceUpload: 'Upload Invoice',
  txtScannedInvoicesReuploadDone: 'Invoice re-uploaded successfully. Please wait for the invoice to be processed.',
  txtScannedInvoicesReupload: 'Reupload invoice',
  txtScannedInvoicesWiskReviewed: 'Mark as reviewed by WISK',
  txtScannedInvoicesView: 'View scanned invoice',
  txtScannedInvoicesViewItems: 'View items',
  txtScannedInvoicesViewImage: 'Original invoice',
  txtScannedInvoicesViewOCR: 'Text Result',
  txtScannedInvoicesNewNanonetsModel: 'Instant Learning Model',
  txtScannedInvoicesOldNanonetsModel: 'Old Model',
  txtScannedInvoicesLocateLine: 'Locate invoice line',
  txtScannedInvoicesTotalItems: 'Total items',
  txtScannedInvoicesUnmappedItems: 'Unmapped items',
  txtScannedInvoicesAllItems: 'View all',
  txtScannedInvoicesMissingInfoFixConfirm:
    'Please check the notes left by the Invoicing Team and either fill in the requested missing information or add it to the note. When finished, click "OK" in this window to send the invoice back to the team to finish processing.',
  txtScannedInvoicesStatusResetConfirm: 'Please confirm you want to clear the status of this invoice and send it back to the Invoicing Team for processing.',
  txtScannedInvoicesStatusResetInfo: 'This action will clear the status of the invoice and send it back to the Invoicing Team for processing.',
  txtScannedInvoicesReprocess: 'Reprocess',
  txtScannedInvoicesNeedAttentionItems: 'Needs attention',
  txtScannedInvoicesNumberOfPages: 'Number of pages',
  txtScannedInvoicesViewItemsWithError: 'With error',
  txtScannedInvoicesViewExtraLines: 'Charges / credits',
  txtScannedInvoicesScanDate: 'Upload date',
  txtScannedInvoicesGridViewByInvoiceDate: 'By Invoice Date',
  txtScannedInvoicesInvoiceDate: 'Invoice date',
  txtScannedInvoicesConvert: 'Convert to invoice',
  txtScannedInvoicesPDFSplitShort: 'Split PDF',
  txtScannedInvoicesPDFSplit: 'Manage invoice pages',
  txtScannedInvoicesPDFMerge: 'Merge invoice pages',
  txtScannedInvoicesPDFSplitDone: 'The pdf file was split successfully. The current draft invoice is being archived and for each pdf resulted from the split a new invoice will be created',
  txtScannedInvoicesPDFSplitHere: 'Split here',
  txtScannedInvoicesPDFSplitNoListMessage: 'Nothing to split or submit, please add more files or click Reset to start over',
  txtScannedInvoicesPDFSplitReset: 'Reset and remove uploaded files',
  txtScannedInvoicesPDFSplitDragDropInfo: 'Drag and drop pages to reorder or move them to another invoice',
  txtScannedInvoicesPDFSplitRemovePage: 'Remove page',
  txtScannedInvoicesPDFSplitZoom: 'View larger',
  txtScannedInvoicesPDFSplitPageNumber: 'Page number in new invoice',
  txtScannedInvoicesPDFSplitPageNumberOld: 'Page number in old invoice',
  txtScannedInvoicesPDFSplitMessage: 'The current invoice will be archived and for each pdf resulted from the split a new invoice will be created',
  txtScannedInvoicesTotalsAlmostMatchTooltip: "The totals don't match, but are close enough that you can convert the draft invoice",
  txtScannedInvoicesFoundLocal: 'Your item database has {a} similar items',
  txtScannedInvoicesFoundOnline: "WISK's item database has {a} similar items",
  txtScannedInvoicesNeedsActionTab: 'Client Review Needed!',
  txtScannedInvoicesFintechDistributor: 'Fintech distributor',
  tplConfirmScannedInvoicesConvertTotalsAlmostMatch: `Are you ready to convert this draft to a WISK invoice?

  Please note the totals don't match by 0.01. This may be due to rounding differences or bad input of data. Make sure it's okay to convert the draft invoice even though the totals don't match.`,
  confirmScannedInvoicesConvert: 'Are you ready to convert this draft to a WISK invoice?',
  confirmScannedInvoicesReimport: 'Please confirm you want to re-import the selected invoices. This action will reset them to the "Processing" status.',
  tplConfirmScannedInvoicesMove: 'Please confirm you want to move the selected invoices to one of your other venues. This action will archive them in the current venue and import them to {a}.',
  confirmScannedInvoicesDistributorChange: 'Please confirm the distributor change. This action will reset the draft invoice to the "Processing" status and all changes will be lost.',
  confirmScannedInvoicesReprocess: 'This action will reset the draft invoice to the "Processing" status and all changes will be lost.',
  txtScannedInvoicesReimportSuccess: 'Invoices re-imported successfully',
  txtScannedInvoicesMoveToVenue: 'Move to another venue',
  txtScannedInvoicesAddLineItem: 'Add new line item',
  txtScannedInvoicesReImport: 'Re-import selected invoices',
  txtScannedInvoicesValidationMissingItemId: 'Add or map to a WISK item',
  txtScannedInvoicesDistributorNotSaved: 'Please select a distributor from the list or create one if not found',
  txtScannedInvoicesDistributorNotSavedMessage: 'Please select a distributor in order to start mapping items',
  txtScannedInvoicesCodeOverwrite: 'This action will replace the distributor and distributor code of the selected item variation',
  txtScannedInvoicesCodeOverwriteOK2: "Replace the selected item's distributor and distributor code with those from draft invoice line",
  txtScannedInvoicesCodeOverwriteOKNot2: 'Create a new item variation and add to the selected item',
  txtScannedInvoicesItemNotFoundWillBeCreated: 'Create new item using the Actions Menu',
  confirmScannedInvoicesDistributorCodeMismatchWishWIthoutItemsEdgeCase:
    'The distributor code of the selected item variation does not match the distributor code of the draft invoice line. This action will replace the distributor code of the draft invoice line with the distributor code of the selected item variation.',
  tplScannedInvoicesEdit: 'Invoice scanned on {a}',
  groupScannedInvoiceStatuses: {
    inbox: 'Processing',
    in_processing: 'Processing',
    to_review: 'Ready for Review',
    converted_to_invoice: 'Converted to Invoice',
    reviewed_by_wisk: 'Reviewed by WISK',
    select_distributor: 'Select distributor',
    no_invoice_lines: 'No invoice lines detected'
  },
  tplScannedInvoicesEmailAddress: 'Send your invoices to the following email address: {a}',
  groupScannedInvoicesGLAccountGridLineTypes: {
    line_item: 'Line Item',
    credit: 'Credit',
    charge: 'Charge'
  },
  groupScannedInvoicesAPIErrors: {
    duplicate_invoice: 'Duplicate invoice',
    duplicate_scanned_invoice: 'Duplicate scanned invoice',
    lines_dont_match: 'Lines do not match',
    missing_date: 'Missing date',
    missing_extra_line_info: 'Missing charges/credits info',
    missing_invoice_number: 'Missing invoice number',
    missing_supplier: 'Missing supplier',
    totals_almost_dont_match: 'Totals almost do not match',
    totals_dont_match: 'Totals do not match'
  },
  txtScannedInvoicesWarningsHeader: 'The following errors are preventing this draft to be converted to an invoice',
  txtScannedInvoicesUploaderMode: 'Select Upload Mode',
  txtScannedInvoicesUploaderModeProcessAsMultiple: 'Process as multiple invoices',
  txtScannedInvoicesUploaderModeProcessAsSingle: 'Process as a single invoice',
  tplScannedInvoicesUploaderModeDuplicateFileWarning: 'The file "{a}" is already in the list and will not be added again',
  txtScannedInvoicesUploaderSaveAsMultiple: 'Save as multiple invoices',
  txtScannedInvoicesUploaderSaveAsSingle: 'Save as a single invoice',
  txtScannedInvoicesImportedTitle: 'Imported title',
  txtScannedInvoicesItemTitleCode: 'Item title / code',
  txtScannedInvoicesItemMissing: 'No item selected',
  txtScannedInvoicesItemTitle: 'Item title',
  txtScannedInvoicesLineInsertAbove: 'Insert line above',
  txtScannedInvoicesLineInsertBelow: 'Insert line below',
  txtScannedInvoicesItemSearchDialog: 'Select item',
  txtScannedInvoicesMissingInformation: 'Missing information',
  txtScannedInvoicesItemSearchDialogAddOnlineItem: 'Add to venue',
  txtScannedInvoicesItemSearchDialogAddOnlineItemStatus: 'Not in venue, found online',
  txtScannedInvoicesItemSearchBy: 'Search',
  txtScannedInvoicesItemSearchByName: 'By item name',
  txtScannedInvoicesItemSearchByCode: 'By item code',
  txtScannedInvoiceDateInFutureTitle: 'Selected Date is in the Future',
  tplScannedInvoiceDateInFutureDescription: 'The selected date of {a} is in the future! Most invoices are dated in the recent past. Please recheck the date format and confirm you want to set this date.',

  //Distributors
  txtDistributorName: 'Distributor Name',
  txtDistributorsWisk: 'Wisk Distributors',
  txtDistributorWisk: 'Wisk Distributor',
  txtDistributorWiskNew: 'New Wisk Distributor',
  txtDistributorWiskInstantLearning: 'Use instant learning',
  txtDistributorWiskInstantLearningNot: 'Do not use instant learning',
  txtDistributorSearchAdd: 'Find or add distributor',
  txtDistributorSearch: 'Find distributor',
  txtDistributorPublincLink: 'Link to WISK Distributor',
  tplDistributorPublincLinked: 'Linked to WISK distributor: {a}',
  txtDistributorPublincLinkedNot: 'Not linked to WISK distributor',
  txtDistributorPublincLinkNot: 'Unlink from WISK distributor',
  txtDistributorPublincLinkSelected: 'Link to selected WISK distributor',
  txtDistributorTitleHelpTextPublic: 'Type to search for a distributor, or add one if not found',
  txtDistributorFindHelpTextPublic: 'Type to search for a distributor',
  txtDistributorTitleHelpTextPrivate: 'Add private distributor',
  txtDistributorNew: 'Add Distributor',
  txtDistributorContacts: 'Contacts',
  txtDistributorItemsOnlineSearchMinChars: 'Please enter at least 3 characters for online search',
  txtDistributorItemsAddToVenueSelected: 'Add selected to venue',
  tplDistributorItems: 'Items for {a}',
  tplDistributorItemsInVenue: '{a} items in your venue',
  tplDistributorItemsInWisk: "Showing {a} items from WISK's database for this distributor",
  txtDistributorAdd: 'Add distributor',
  txtDistributorContactValidationPhoneOrEmail: 'At least phone or email is needed for a contact.',
  txtDistributorViewItems: 'Search items',
  txtDistributorManage: 'Manage distributor',
  txtDistributorsManage: 'Manage distributors',
  tplDistributorsPortalCredentialsSaved: 'Portal credentials saved for user {a}',
  txtDistributorsExcludePriceOrders: 'Exclude price from purchase orders',
  txtDistributorsAllowAutoOrdering: 'Automatic purchase orders',
  txtDistributorsClearPortalCredentials: 'Clear portal credentials',
  txtDistributorsSavePortalCredentials: 'Save portal credentials',
  txtDistributorsPortalCredentials: 'Portal credentials',
  txtDistributorsUploadDownloadExisting: 'Existing distributors',
  txtDistributorsUploadTemplateLink: 'Empty template',
  txtDistributorsUploadMessage: 'Download one of the files below and populate it with your latest data. Distributors found will be updated, new distributors will be added, based on the data in the uploaded file.',
  txtDistributorsImport: 'Import distributors from Excel file',
  txtDistributorsArchiveError: "The distributor can't be archived because there are items assigned to it. Please change or remove the distributor from the following items and try again:",
  txtDistributorCreatedAt: 'Created at',
  txtDistributorReviewed: 'Reviewed',
  txtDistributorExcluded: 'Excluded',
  txtDistributorSupplyIntegration: 'Supply integration',
  txtDistributorNotifyNewDistributors: 'Notify new distributors',
  txtDistributorOpenLastDraftInvoice: 'Open Last Draft Invoice',

  // Families and Categories
  txtFamiliesCategoriesNewFamily: 'New Family',
  txtFamiliesCategoriesNewCategory: 'New Category',
  txtFamiliesCategoriesFamilyName: 'Family name',
  txtFamiliesCategoriesPartial: 'Partial',
  txtFamiliesCategoriesTargetBeverageCost: 'Target cost %',
  txtFamiliesCategoriesTargetVariancePercentage: 'Target variance %',
  tplFamiliesCategoriesFamilyRename: 'Rename family {a}',
  txtFamiliesCategoriesEditFamily: 'Edit Family',
  txtFamiliesCategoriesArchiveFamily: 'Archive Family',
  txtFamiliesCategoriesArchiveFamilynotAllowed: 'Cannot archive family with active categories',
  txtFamiliesCategoriesAddCategory: 'Add Category',
  tplFamiliesCategoriesBottlesUsingCategory: 'The following items are using category {a}',
  txtFamiliesCategoriesBottlesUsingCategoryMessage: 'Please change the category or archive the items in order to be able to archive the category.',
  txtFamiliesCategoriesArchiveError: "The category can't be archived because there are items using it. Please change or remove the category from the following items and try again:",
  tplFamiliesCategoriesFloatingSelectedCount: 'categories selected of {a}',

  //Validations
  txtValidationEscapeToReset: 'Press Escape key in order to return to the previous value',
  txtValidationRequired: 'Value is required',
  tplValidationCustomFieldLabel: 'A custom field with the same name already exists for {a}',
  txtValidationRequiredItemSelected: 'A value from the list must be selected',
  tplValidationTooHigh: '{a} cannot be higher than the {b}',
  txtValidationEmail: 'Please input a valid email address',
  txtValidationURL: 'Please input a valid URL',
  txtValidationPhone: 'Please input a valid phone number',
  txtValidationDateRange: 'Please select a date range',

  //component Image uploader
  txtImageUploaderTitle: 'Uploader',
  txtImageUploaderLoadFromURL: 'Load from URL',
  txtImageUploaderLoadFromURLText: 'Input a file URL (image, pdf)',
  txtImageUploaderDropZoneText: 'Click to browse files from your device, or instead drag and drop, or paste here',
  txtImageUploaderDropZoneTextSmall: 'drop files here to upload',
  txtImageUploaderDropZoneTextMultiple: 'Drop or paste files, or click to browse from device, multiple selection enabled',
  txtImageUploaderImageIsBroken: 'Image link is broken',
  txtImageUploaderCheckingPreview: 'Checking image link',
  txtImageUploaderSelectedImage: 'Selected Image',
  txtImageUploaderSelectFile: 'Select file',
  txtImageUploaderAddFiles: 'Click here to add more files',
  txtImageUploaderCurrentImege: 'Current Image',
  txtImageUploaderUploadedImeges: 'Uploaded Images',
  txtImageUploaderEmpty: 'empty',
  txtImageUploaderSearchOrPaste: 'Search on Google, or paste your target image link',

  //Consumption
  txtConsumptionBottles: 'Consumption (units)',
  txtConsumptionOpen: 'Go to Consumption',
  txtConsumptionFlowMeterUnits: 'Consumption Flow Meter (units)',
  txtConsumptionDollars: 'Consumption ($)',
  txtConsumptionInventoryFrom: 'Start Inventory (units)',
  txtConsumptionInventoryTo: 'End Inventory (units)',
  txtConsumptionInventoryFromDollars: 'Start Inventory ($)',
  txtConsumptionInventoryToDollars: 'End Inventory ($)',
  txtConsumptionMovementsDollars: 'Invoice ($)',
  tplConsumptionMovementsForItem: 'Invoice for {a} at {b}',
  tplConsumptionByLocationForItem: 'Consumption by area for {a}',
  txtConsumptionExpandBatches: 'Expand batches',
  txtConsumptionByCategoryResendEmail: 'Send by email',
  txtConsumptionByCategoryResendEmailForAllVenues: 'Send by email for all venues',
  txtConsumptionStayOnTop: 'Stay on top of your bottles',
  txtConsumptionStayOnTopExplanation: "WISK's Venue Items features lets you stay on top of what you have on hand at a quick glance, giving you an overview of your entire beverage operations.",
  txtConsumptionStartTracking: 'Start tracking your consumption!',

  //Sales
  txtSalesTotalCost: 'Total Cost',
  txtSalesTotal: 'Total Sales',
  txtSalesTotalProfit: 'Total Profit',
  txtSalesDiscount: 'Discount',
  txtSalesTotalWithoutDiscount: 'Total Without Discount',
  txtSalesCostlWithoutDiscount: 'Cost Without Discount',
  txtSalesCostPercentage: 'Cost Percentage',
  txtSalesByItemSpreadsheetMonthly: 'Download spreadsheet - monthly',
  txtSalesByItemSpreadsheetWeekly: 'Download spreadsheet - weekly',
  txtSalesRefreshImport: 'Reimport sales data',
  txtSalesRefreshImportMessage: 'We have triggered a reimport of your sales data. Please check again in a few minutes.',
  txtSalesRefreshImportIntegrationRequirement: 'You need to have at least one active POS integration to import sales.',
  txtSalesRefreshImportInventoryRequirement: 'You need to have at least one inventory to use inventory selector.',
  txtSalesRefreshImportNotSupported: 'Reimport sales data is not supported for this integration.',
  txtSalesReportYouUnderstand: 'Sales reports you can understand',
  txtSalesReportYouUnderstandExplanation:
    'WISK give you the ability to see sales data in a completely new way. Not only can you see similar sales data to your POS, but you can get down to the ingredient level. Knowing that you sold 22 mojitos is nice, but understanding that you sold 16 ounces of a specific rum is powerful. Now you can look at sales from a profit angle, not just a revenue angle!',
  txtSalesReportTryIt: 'Try WISK Sales Reports today!',
  tplSalesRefreshImportMessageMinImportDate: "You can't import sales older than {a} days for this POS type.",

  //upload sales manually
  txtSalesUploadTitle: 'Upload sales manually',
  txtSalesUploadKeepSales: 'Keep existing sales for the selected interval',
  txtSalesUploadTemplateLink: 'Please download this file, populate it, then upload it back to us',
  txtSalesUploadErrors: 'The following errors occurred, please fix the lines with errors and upload the file again.',
  tplSalesLastUploadWasAtDate: 'The last manual sales upload was for the period of: {formatted}',
  tplSalesUploadConfirmTitle: 'Confirm sales upload from {from} to {to}',
  txtSalesUploadMessageKeepExisting: 'The sales data in the template will be added to your existing sales data for the selected period.',
  txtSalesUploadMessageReplaceExisting: `The sales upload will replace any existing sales data for the selected period.
  To keep your existing sales data, click "Cancel" and check the "Keep existing sales for the selected interval" checkbox.`,

  //Stripe
  txtStripeAccountInformation: 'Account information',
  txtStripeBillingInformation: 'Billing information',
  txtStripeBillingAddress: 'Billing address',
  txtStripeCustomerBalance: 'Customer balance',
  txtStripeCards: 'Cards',
  txtStripeInvoices: 'Invoices',
  txtStripeBankAccounts: 'Bank accounts',
  txtStripePayments: 'Payments',
  txtStripePaymentRetry: 'Retry payment',
  txtStripePaymentsRetry: 'Retry failed payments',
  txtStripeSubscriptions: 'Subscriptions',
  txtStripeEmailTo: 'Send email to',
  txtStripeEmailCC: 'CC',
  txtStripeAddressLine1: 'Address line 1',
  txtStripeAddressLine2: 'Address line 2',
  txtStripeAddressPostalCode: 'Postal code',
  txtStripeAddressInputManually: 'Input address manually',
  txtStripeInvoicePrefix: 'Invoice prefix',
  txtStripePaid: 'Paid',
  txtStripeCardAdd: 'Add card',
  txtStripeCardExpireMonth: 'Exp. month',
  txtStripeCardExpireYear: 'Exp. year',
  txtStripeCardTrialEnds: 'Trial Ends',
  txtStripeCardAddMessage: 'Credit card',
  tplStripeCardSubscribeTo: 'Subscribe to {a}',
  txtStripeCardSubscribeTo: 'Subscribe to',
  txtStripeDelinquentAddCardTitle: 'Add new card to reactivate account',
  txtStripeDelinquentStatusText: 'Reactivate account',
  txtStripeDelinquentRetryPayments: 'Retry failed payments in order to reactivate account',
  tplDelinquentWarning: 'Your account has {a} invoices on file amounting to {b}. We have limited access to your account until a valid form of payment is added.',

  tplStripeCardPlanPriceYearly: '{a} {b}/mo paid yearly',
  tplStripeCardPlanPriceMonthly: '{a} {b}/mo paid monthly',
  tplStripeCardPlanPriceDueIn: '{a} {b} due in {days} days',
  tplStripeCardPlanPriceDueOn: '{a} {b} due on {c}',
  tplStripeCardPlanPriceDueNow: '{a} {b} due now',
  tplStripeCardPlanPriceSavePerYear: 'save {a} {b} per year',

  txtStripeCardEdit: 'Modify or remove card',
  txtStripeCardRemove: 'Remove card',
  txtStripeCardRemoveNotAllowedDefault: "Default card can't be removed",
  txtStripeCardRemoveNotAllowedLast: "Last card can't be removed",
  txtStripeCardDefault: 'Set as default',
  txtStripeCardholderName: 'Cardholder name',
  txtStripeCardExplainFreeTrialCard: "Your card won't be charged until the free trial ends.",
  txtStripeCardWeRemindYou: "We'll remind you a few days before.",
  tplStripeCardSubscriptionFreeTrial: "You can cancel anytime. Take the next {a} days to use WISK for FREE, and see if it's right for you.",

  txtSubscriptionRestrictedFeature: 'Restricted Feature',

  //Locations
  txtLocationsNew: 'New area',
  txtLocationsDefault: 'Default Area for Invoices and Returns',
  txtGenericDragSort: 'Drag to change sort order',
  txtLocationsMessageNoSaveSort: "Can't save sort order with archived areas visible",

  //custom fields
  txtCustomField: 'Custom Field',
  txtCustomFields: 'Custom Fields',
  txtCustomFieldsActive: 'Active custom fields',
  txtCustomFieldsView: 'View custom fields',
  txtCustomFieldAdd: 'Add custom field',
  txtCustomFieldEdit: 'Modify custom field',
  txtCustomFieldDefaultValue: 'Default value',
  txtCustomFieldType: 'Field type',
  txtCustomFieldAddValue: 'Add list item',
  txtCustomFieldAddAttachments: 'Add attachments',
  txtCustomFieldDropdownItems: 'Values',

  //pdf viewer
  txtPDFViewerZoomIn: 'Zoom in',
  txtPDFViewerZoomOut: 'Zoom out',
  txtPDFViewerZoomPresets: 'Zoom presets',
  txtPDFViewerSelectText: 'Select text',
  txtPDFViewerPan: 'Pan / drag / scroll to zoom',
  txtPDFViewerRotateClockwise: 'Rotate clockwise',
  txtPDFViewerRotateCounterClockwise: 'Rotate counter clockwise',
  txtPDFViewerDownload: 'Download PDF',
  txtPDFViewerPages: 'Pages',
  txtPDFViewerPageUp: 'Page up',
  txtPDFViewerPageDown: 'Page down',

  //allergens
  txtAllergens: 'Allergens',
  txtAllergensNew: 'Add allergen',
  txtAllergensTitle: 'Modify allergen title',
  txtAllergensManage: 'Manage Allergens',

  //gl accounts
  txtGLAccount: 'GL Account',
  txtGLAccounts: 'GL Accounts',
  txtGLAccountsManage: 'Manage GL Accounts',
  txtGLAccountsApply: 'Apply GL Accounts',
  txtGLAccountsAppliedMessage: 'GL Accounts applied successfully.',
  txtGLAccountEdit: 'Edit GL Account',
  tplGLAccountAccountingMappingTotalRemaindedPercentWarning: 'Remaining: {a}% out of 100%',
  tplGLAccountAccountingMappingMaxPercentWarning: 'Available: {a}%',
  txtGLAccountNew: 'New GL Account',
  txtGLAccountName: 'GL Account Name',
  txtGLAccountNumber: 'GL Account Number',
  txtAccountingDistributor: 'Accounting distributor',
  txtAccountingAccount: 'Accounting account',
  txtAccountingAccounts: 'Accounting accounts',

  //transfer request
  txtVenueTransferRequestNew: 'New venue transfer request',
  txtVenueTransferRequestsViewReport: 'View active transfer requests',
  txtVenueTransferRequestsViewReportTitle: 'Active transfer requests',
  txtVenueTransferRequestEnabled: 'Venue transfers',
  txtVenueActiveTransferEmail: 'Download venue transfers (PDF)',
  txtVenueActiveTransfer: 'Venue transfers',
  txtVenueTransferDetailsEmail: 'Download transfers details (PDF)',
  txtVenueTransferDetails: 'Venue transfer details',
  txtVenueTransferDetailsEmailByVenue: 'Download transfers details by venue (PDF)',
  txtVenueTransferRequestAccept: 'Accept, start preparation',
  txtVenueTransferRequestDecline: 'Decline',
  txtVenueTransferRequestsReportGroupById: 'By transfer request id',
  txtVenueTransferRequestsReportGroupByItem: 'By item',
  txtVenueTransferRequestSend: 'Send items',
  tplVenueTransferRequestTitle: 'Venue transfer request to {a} - {b}',
  txtVenueTransferRequestId: 'Venue transfer request id',
  groupTransferRequestStatuses: {
    draft: 'Draft',
    requested: 'Requested',
    in_preparation: 'In preparation',
    sent: 'Sent',
    received: 'Received',
    declined: 'Declined',
    canceled: 'Canceled'
  },

  //Taxes
  txtTax: 'Tax rate',
  txtTaxes: 'Taxes',
  txtTaxesManage: 'Manage taxes',
  txtTaxesNew: 'New tax',
  txtTaxesEdit: 'Edit tax',
  txtTaxesTitle: 'Tax name',
  txtTaxesTitleAccounting: 'Tax name in accounting',
  txtTaxesRate: 'Rate',
  txtTaxesRateComputed: 'Computed rate',
  txtTaxesInner: 'Compounded taxes',
  txtTaxesScheme: 'Tax Scheme',
  txtTaxesSingle: 'Single tax',
  txtTaxesCompounded: 'Compounded taxes',
  txtTaxesMessageSaveFirst: 'Please name your tax rate and save before you continue',
  groupTaxSchemes: {
    no_taxes: 'No tax',
    taxes_included: 'Taxes included',
    taxes_excluded: 'Taxes excluded'
  },

  //Cost Changes
  txtCostChangesNewCostUnit: 'New cost per unit',
  txtCostChangesCostDifference: 'Difference amount',
  txtCostChangesCostDifferencePercentage: 'Difference percentage',
  txtCostChangesCostTotalChange: 'Total change',
  txtCostChangesShowUnchangedCost: 'Include unchanged prices',
  txtCostChangesCount: 'Cost changes',
  txtCostChangesGo: 'Go to cost changes page',

  //Download manager
  txtDownloadManagerDownloaded: 'Downloaded at',
  txtDownloadManagerEmailed: 'Emailed at',
  txtDownloadManagerRequestedAt: 'Requested at',
  txtDownloadManagerRetryTitle: 'Retry generating report',
  txtDownloadManagerRetryDescription: 'Are you sure you want to retry generating this report?',

  //churned venue
  txtChurnedVenueTitle: 'No Active Subscription Found',
  txtChurnedVenueDescription: 'This account no longer has an active subscription, and your access has been restricted.',
  txtChurnedVenueDeleteNotice: 'If the account has been inactive for more than 60 days, all data has been deleted.',
  txtChurnedVenueLink: 'To continue using WISK, please schedule a call with our team.',
  txtChurnedVenuePricing: 'Please visit {a} for our latest pricing information.',
  txtChurnedVenueAccessRestricted: 'Access has been restricted',

  //component PlanSelector
  txtPlanSelectorGet2monthsFree: 'Get 2 months free!',
  txtPlanSelectorMonthlySaving: 'Monthly savings',
  txtPlanSelectorYearlySaving: 'Yearly savings',
  txtPlanSelectorCurrentPlan: 'Current plan',
  txtPlanSelectorPerMonth: 'per month',
  txtPlanSelectorPerVenue: 'per venue',
  txtPlanSelectorYouCanSave: 'You can save',
  txtPlanSelectorScheduleMyMeeting: 'Schedule my meeting',
  txtPlanSelectorConfirmSubscription: 'Confirm subscription',
  txtPlanSelectorDiscountMessageForYearlyPlan: 'monthly, if you select a yearly plan.',
  txtPlanSelectorChargeConfirmationOn: 'You will be charged {a} / {b} until you cancel or change your subscription.',
  txtPlanSelectorChargeConfirmationOnPromote: 'By clicking "Confirm subscription", your card on file will be charged immediately {a} / {b} until you cancel or change your subscription.',
  txtPlanSelectorChargeLong: 'until you cancel or change your subscription',

  //component pdf viewer
  txtPDFViewerZoomControlTooltipScroll: 'Zoom in and out using the mouse wheel',
  txtPDFViewerZoomControlTooltipDropdown: 'Control zoom using predefined zoom presets',

  //component plan feature ad
  txtPlanFeatureAdQuestion1: 'Interested in this feature?',
  txtPlanFeatureAdQuestion2: 'Want to learn more?',
  txtPlanFeatureAdBookDemo: 'Book a demo',
  txtPlanFeatureAdUpgradeNow: 'Upgrade now',
  txtPlanFeatureAdUpgradeCompletedNavigateNow: "Your subscription has been upgraded, and you now have access to the selected feature. Would you like to go to the feature's page?",

  //select plan popup
  txtBookDemoTitle: 'Book a demo to get started',
  txtBookDemoButtonPrices: 'View our plans',
  txtBookDemoButtonMeeting: 'Book my demo',

  //Organizations
  txtOrganizations: 'Organizations',
  txtOrganizationsEdit: 'Edit organization',
  txtOrganizationsNew: 'New organization',
  txtOrganizationsAddVenue: 'Add venue',
  txtOrganizationsArchiveConfirm: 'Please confirm you want to archive the selected organization.',
  txtOrganizationsRemoveVenuesFromOrganizationMessage: 'Please confirm you want to remove the selected venues from the organization.',
  txtOrganizationsRemoveVenuesFromOrganizationTitle: 'Clear organization from  selected venues',
  txtOrganizationsAddVenues: 'Add selected venues to organization',
  txtOrganizationsTotalVenues: 'Total venues',
  txtOrganizationsRemoveVenueFromOrganizationTitle: 'Remove from organization',
  txtOrganizationsFilterNoVenue: 'Without venues using Wisk',
  txtOrganizationsFilterHasVenue: 'With venues using Wisk',
  txtOrganizationsFilterArchived: 'Archived',

  //chat
  txtDraftInvoiceChatSend: 'Send',
  txtDraftInvoiceChatSendDone: 'Send and mark as resolved',
  tplDraftInvoiceChatSendMessageTooltip: 'To submit press {a}',
  txtDraftInvoiceChatMessageAdd: 'Add Message',
  txtDraftInvoiceChatReply: 'Reply',
  txtDraftInvoiceChatConversationStart: 'Start a conversation',
  txtDraftInvoiceChatConversationTitle: 'Conversation about draft invoice line',
  txtDraftInvoiceChatConversationStartInfo: 'Starting a conversation will mark the draft invoice line as "Needs attention"',
  txtDraftInvoiceChatConversationMarkDone: 'Mark as done',
  tplDraftInvoiceChatConversationTooltip: `{a} messages. Last message on {b}:
   {c}`,
  tplDraftInvoiceChatConversationTooltipShort: '{a} messages. Last message on {b}',
  txtDraftInvoiceChatConversationTooltip: 'Click to start a conversation',
  txtDraftInvoiceChatConversationTooltipRestart: 'Click to restart the conversation',
  txtDraftInvoiceChatConversationMarkDoneInfo: 'Marking the conversation as done will end the conversation and remove the "Needs attention" status from the draft invoice line',

  //Reports
  txtReportsAnalytics: 'Reports / Analytics',
  txtReportsDashboardTitle: 'Report Center',
  txtReportsAnalyticsDashboardVisible: 'Visible in reports dashboard',
  txtReportsAnalyticsCardEmptyInfo: 'No extra reports, click on the header of this card to go to the default page',
  tplReportsAnalyticsGoToPage: 'Go to {a} page',

  // groupFeatureAds_Batches: {
  //   title: 'Batches',
  //   subTitle: 'Stay in budget without sacrificing flavor.',
  //   text: 'Get real-time recipe costing down to the ingredient level. That means you can see the cost of that garlic inside your aioli sauce and make adjustments accordingly.'
  // },
  // groupFeatureAds_MultiVenue: {
  //   title: 'Multi-venue',
  //   subTitle: 'Easily manage multiple locations in real-time.',
  //   text: 'With WISK, you can easily manage stock and inventory at multiple venues. You can also place orders to distributors from the central stockroom, which makes ordering easier and more efficient.'
  // },
  // groupFeatureAds_Reports: {
  //   title: 'Reports',
  //   subTitle: 'Understand your performance right now, not at the end of the year.',
  //   text: "With just a few clicks you can get insights into your restaurant's performance that would have taken hours to compile on your own. No need to spend hours formatting a spreadsheet."
  // },
  groupFeatureAds_consumption: {
    title: 'Consumption',
    subTitle: 'Know exactly how many ingredients you are using and where',
    text: "Know exactly how much of each ingredient you're using, which will help you make better decisions about what to order and how much to prepare"
  },
  groupFeatureAds_deliveries_analytics: {
    title: 'Movements Analytics',
    subTitle: 'Get real-time insights into the movements of your inventory',
    text: "Make better decisions about your inventory and operations. With a complete history of movements, you'll be able to easily see where things are transferred or returned and how much money you're actually spending on orders."
  },
  groupFeatureAds_deliveries_draft_invoices: {
    title: 'Draft Invoices',
    subTitle: 'Scan invoices and your costs are updated in all your recipes automatically',
    text: "WISK extracts line item data from scanned invoices, in minutes and syncs it with your accounting software with one click of a button. Stop wasting time on data entry and start focusing on what you're good at - running your business!"
  },
  groupFeatureAds_deliveries: {
    title: 'Invoices',
    subTitle: 'Stay organized and in control of your spending',
    text: 'Track out what you ordered from any supplier, or what you paid at any given time, in one place. Access all your invoice history from all your suppliers on this page. Sort by supplier, by category, by product, or by cost. Read more'
  },
  // groupFeatureAds_deliveries_returns: {
  //   title: 'Returns',
  //   subTitle: 'Track your returns without headaches',
  //   text: "You can easily keep track of your purchase orders, prices and returns in one place. With the ability to sort by supplier category or even individual product lines from any given order; you'll be able make sure that all necessary information is at hand when needed!"
  // },
  groupFeatureAds_pos_integration_pos_items: {
    title: 'POS Items',
    subTitle: 'POS integration + recipe management = a profitable venue',
    text: 'Make smarter decisions with real-time insights from your POS. Keep your food costs in check with recipe costing and cost alerts, and find new opportunities to increase profitability.'
  },
  groupFeatureAds_pos_integration_sales: {
    title: 'Sales',
    subTitle: 'Understand your sales and see where your profits come from',
    text: "Easily see what's selling, what's not, and where to focus your efforts so you can run a profitable restaurant. You can even see sales at an ingredient level so you can make informed decisions about your menu."
  },
  groupFeatureAds_pos_integration_variance: {
    title: 'Variance',
    subTitle: 'See the difference between what was sold and what was actually consumed',
    text: 'We help you identify where your money is going - down to the gram. With our detailed reports, you can pinpoint losses due to waste, spillage, and theft.'
  },
  groupFeatureAds_purchase_orders_cart: {
    title: 'Orders',
    subTitle: 'Automate ordering, reduce waste & over-ordering',
    text: 'Prepare purchase orders based on your sales (or your pars!) and email and text it directly to your distributors.'
  },
  groupFeatureAds_purchase_orders: {
    title: 'Purchase Orders',
    subTitle: 'Manage all of your purchase orders in one place',
    text: "Easily manage and find purchase orders, sorted by supplier, category and product so that you're sure not missing anything important."
  },
  groupFeatureAds_transfers: {
    title: 'Transfers',
    subTitle: 'Reduce variance and consumption errors when you have multiple areas',
    text: "Transfers provide you with consumption / usage for specific areas in your venue. If you're selling products from more than one area and move inventory between areas, this is an important feature for you to track your consumption & variance."
  },
  groupCouchbaseStats: {
    inventories: 'Inventories',
    invoices: 'Invoices',
    sales: 'POS Items',
    core: 'Core',
    indexes: 'Indexes'
  },
  groupItemCreationSource: {
    manual: 'User created',
    inventory: 'Created from inventory',
    purchase_order: 'Created from purchase order',
    distributor_invoice: 'Created from distributor invoice',
    draft_invoice: 'Created from draft invoice',
    invoice: 'Created from invoice',
    batch_import: 'Created from batch import',
    copy_from_venue: 'Copied from another venue'
  },
  translate(translationKey, replacements = {}) {
    let translated = ''
    if (translationKey.indexOf('.') > 4) {
      let split = translationKey.split('.'),
        groupObject = this[split[0]]

      translated = (groupObject && groupObject[split[1]]) || ''
    } else {
      translated = this[translationKey] || ''
    }

    Object.keys(replacements).forEach(key => {
      translated = translated.replace(new RegExp(key, 'g'), replacements[key])
    })
    return translated
  }
}

export default translations
