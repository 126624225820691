<template>
  <component :is="tag" :class="classList">
    <img v-if="imgSrc && !imgBottom" :src="imgSrc" :class="imgClassList" :alt="imgAlt" :height="imgHeight" :width="imgWidth" />
    <component :is="headerTag" :class="headerClassList" v-if="header || $slots['header']">
      <slot name="header">
        {{ header }}
      </slot>
    </component>
    <slot v-if="noBody"></slot>
    <component :is="bodyTag" v-else :class="bodyClassList">
      <component :is="titleTag" v-if="title" class="card-title">{{ title }}</component>
      <component :is="subTitleTag" v-if="subTitle" :class="subTitleClasses">{{ subTitle }}</component>
      <slot></slot>
    </component>
    <slot name="footer" v-if="!!$slots['footer']"></slot>
    <component :is="footerTag" v-else-if="footer" :class="footerClassList">{{ footer }}</component>
    <img v-if="imgSrc && imgBottom" :src="imgSrc" :class="imgClassList" :alt="imgAlt" :height="imgHeight" :width="imgWidth" />
  </component>
</template>

<script>
import bCardHeader from './BCardHeader'
import bCardBody from './BCardBody'

export default {
  name: 'BCard',
  components: { bCardHeader, bCardBody },
  props: {
    align: { type: String, default: '' },
    bgVariant: { type: String, default: '' },
    bodyBgVariant: { type: String, default: '' },
    bodyBorderVariant: { type: String, default: '' },
    bodyTag: { type: String, default: 'bCardBody' },
    bodyTextVariant: { type: String, default: '' },
    bodyClass: { type: [String, Array], default: '' },
    borderVariant: { type: String, default: '' },
    footer: { type: String, default: '' },
    footerBgVariant: { type: String, default: '' },
    footerBorderVariant: { type: String, default: '' },
    footerClass: { type: [String, Array], default: '' },
    footerTag: { type: String, default: 'div' },
    footerTextVariant: { type: String, default: '' },
    header: { type: String, default: '' },
    headerBgVariant: { type: String, default: '' },
    headerBorderVariant: { type: String, default: '' },
    headerClass: { type: [String, Array], default: '' },
    headerTag: { type: String, default: 'bCardHeader' },
    headerTextVariant: { type: String, default: '' },
    imgSrc: { type: String, default: '' },
    imgAlt: { type: String, default: '' },
    imgBottom: { type: Boolean, default: false },
    imgEnd: { type: Boolean, default: false },
    imgHeight: { type: [String, Number], default: '' },
    imgLeft: { type: Boolean, default: false },
    imgRight: { type: Boolean, default: false },
    imgStart: { type: Boolean, default: false },
    imgTop: { type: Boolean, default: false },
    imgWidth: { type: [String, Number], default: '' },
    noBody: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    subTitle: { type: String, default: '' },
    subTitleTag: { type: String, default: 'h6' },
    subTitleTextVariant: { type: String, default: 'muted' },
    tag: { type: String, default: 'div' },
    textVariant: { type: String, default: '' },
    title: { type: String, default: '' },
    titleTag: { type: String, default: 'h4' }
  },
  computed: {
    classList() {
      const classList = ['card']
      if (this.bgVariant) classList.push(`bg-${this.bgVariant}`)
      if (this.borderVariant) classList.push(`border-${this.borderVariant}`)
      if (this.textVariant) classList.push(`text-${this.textVariant}`)
      if (this.imgEnd || this.imgRight) classList.push('flex-row-reverse')
      if (this.imgLeft || this.imgStart) classList.push('flex-row')
      return classList
    },
    bodyClassList() {
      const bodyClassList = Array.isArray(this.bodyClass) ? this.bodyClass : String(this.bodyClass).split(' ')
      if (this.bodyTag !== 'bCardBody') bodyClassList.push('card-body')
      if (this.bodyBgVariant) bodyClassList.push(`bg-${this.bodyBgVariant}`)
      if (this.bodyBorderVariant) bodyClassList.push(`border-${this.bodyBorderVariant}`)
      if (this.bodyTextVariant) bodyClassList.push(`text-${this.bodyTextVariant}`)
      if (this.overlay) bodyClassList.push('card-img-overlay')
      return bodyClassList
    },
    footerClassList() {
      const footerClassList = Array.isArray(this.footerClass) ? this.footerClass : String(this.footerClass).split(' ')
      footerClassList.push('card-footer')
      if (this.footerBgVariant) footerClassList.push(`bg-${this.footerBgVariant}`)
      if (this.footerBorderVariant) footerClassList.push(`border-${this.footerBorderVariant}`)
      if (this.footerTextVariant) footerClassList.push(`text-${this.footerTextVariant}`)
      return footerClassList
    },
    headerClassList() {
      const headerClassList = Array.isArray(this.headerClass) ? this.headerClass : String(this.headerClass).split(' ')
      if (this.headerTag !== 'bCardHeader') headerClassList.push('card-header')
      if (this.headerBgVariant) headerClassList.push(`bg-${this.headerBgVariant}`)
      if (this.headerBorderVariant) headerClassList.push(`border-${this.headerBorderVariant}`)
      if (this.headerTextVariant) headerClassList.push(`text-${this.headerTextVariant}`)
      return headerClassList
    },
    imgClassList() {
      if (this.imgBottom) return 'card-img-bottom'
      if (this.imgTop) return 'card-img-top'
      if (this.imgLeft || this.imgStart) return 'card-img-left'
      if (this.imgEnd || this.imgRight) return 'card-img-right'
      return 'card-img'
    },
    subTitleClasses() {
      return ['card-subtitle', 'mb-2', `text-${this.subTitleTextVariant}`]
    }
  }
}
</script>
