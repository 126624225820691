<template>
  <wiskInputGroup class="pb-2" style="" @errorCountChanged="setValidState" :key="key">
    <b-row>
      <b-col cols="12" md="4" class="px-1">
        <wiskInput infoTooltipKey="b212816a-abc7-4740-a420-cb7de6d8e423" style="" required :label="translations.txtGenericName" v-model="operation.value.name" />
      </b-col>
      <b-col cols="12" md="3" class="px-1">
        <wiskInput infoTooltipKey="5db92068-61f3-425c-97f0-52b26e90970c" style="" :validations="[validations.oneOrOther]" :label="translations.txtGenericEmail" v-model="operation.value.email" ref="txtEmail" />
      </b-col>
      <b-col cols="12" md="3" class="px-1">
        <wiskInput infoTooltipKey="d659b752-3dbb-41e9-82af-a03c551da993" style="" :validations="[validations.oneOrOther]" :label="translations.txtGenericPhone" v-model="operation.value.phone" ref="txtPhone" />
      </b-col>
      <b-col cols="12" md="2" class="px-1 text-center">
        <b-button :disabled="!valid" style="height: 40px;" size="sm" class="text-primary mx-auto"
          variant="link" @click="emitAddOperation" v-tooltip="translations.txtGenericAdd" ref="buttonAdd">
          <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
          <icon style="margin-top: -10px;" name="wisk-check" :scale="1.2" />
        </b-button>
      </b-col>
    </b-row>
  </wiskInputGroup>
</template>

<script>
import { mapState } from 'vuex'
import merge from 'lodash.merge'
import { guid, isValidEmail, isValidPhoneNumber } from '@/modules/utils'
import wiskInput from '@/components/common/WiskInput'

const contact = { name: '', email: '', phone: '' }

export default {
  name: 'DistributorContactAdd',
  emits: ['addContact'],
  components: { wiskInput },
  data() {
    return {
      valid: false,
      key: 1,
      operation: {
        type: 'contact_add',
        value: merge({}, contact, { uuid: guid() })
      }
    }
  },
  computed: {
    ...mapState(['translations']),
    validations() {
      return {
        oneOrOther: {
          type: 'oneOrOther',
          message: this.translations.txtDistributorContactValidationPhoneOrEmail,
          validator: () => {
            let valid = isValidEmail(this.operation.value.email) || isValidPhoneNumber(this.operation.value.phone)

            if (this.$refs.txtEmail && this.$refs.txtPhone) {
              this.$refs.txtEmail.isValid = valid
              this.$refs.txtPhone.isValid = valid
            }

            return valid
          }
        },
        email: { type: 'email', validator: isValidEmail, message: this.translations.txtValidationEmail }
      }
    }
  },
  methods: {
    setValidState(errorCount) {
      this.valid = !errorCount
      if (this.valid && this.$refs.buttonAdd) {
        this.$refs.buttonAdd.classList && this.$refs.buttonAdd.classList.add('attention')
      }
    },
    emitAddOperation() {
      this.$emit('addContact', this.operation)

      setTimeout(() => {
        this.operation.value = merge({}, contact, { uuid: guid() })
        this.key++
      }, 0)
    }
  }
}
</script>

<style lang="scss">

</style>
