<template>
  <div class="">
    <gateKeeper v-if="computedActionsLength === 1 && computedActions[0]" :feature="computedActions[0].planFeature" reactOnClickEvent containerClass="d-block"
      :limitedItemsCount="computedActions[0].planFeatureLimitedItemsCount">

      <b-button @click="computedActions[0].action" :variant="mainActionVariant" :class="dropdownClass" :size="size" preventDoubleClick class=""
        :disabled="computedActions[0].disabled" :title="computedActions[0].tooltip" style="pointer-events: auto;">
        <icon :name="computedActions[0].icon" v-if="computedActions[0].icon" :scale=".9" class="me-2" />
        <span class="">
          {{ computedActions[0].title }}
          <icon v-if="computedActions[0].tooltip" class="text-gray-400 ms-1" name="wisk-information-button" :scale=".5" tabindex="-1" />
        </span>
      </b-button>

    </gateKeeper>

    <b-dropdown :variant="variant" :no-caret="hasButtonSlot" toggle-class="text-decoration-none" :text="title" :class="dropdownClass" :size="size" v-if="computedActionsLength > 1" right>

      <template v-if="hasButtonSlot" v-slot:button-content>
        <slot name="button-content"></slot>
      </template>

      <template v-if="computedMainActions.length" v-slot:main-btn>
        <b-button v-for="item in computedMainActions" :key="item.key" @click="item.action" :variant="variant" :size="size" :disabled="item.disabled || item.loading" :title="item.tooltip">
          <wiskLoading v-if="item.loading" loading />
          <icon :name="item.icon" v-if="item.icon" :scale=".9" class="me-2" />
          <span class="">
            {{ item.title }}
            <icon v-if="item.tooltip" class="text-gray-400" name="wisk-information-button" :scale=".5" tabindex="-1" />
          </span>
        </b-button>
      </template>

      <div v-for="action in computedActions" :key="action.key">
        <gateKeeper v-if="action.action" :feature="action.planFeature" reactOnClickEvent :limitedItemsCount="action.planFeatureLimitedItemsCount" :title="action.tooltip">

          <b-dropdown-item @click="action.action(action.extra)" :variant="action.variant" class="w-100" :disabled="action.disabled || action.loading">
            <wiskLoading v-if="action.loading" loading />
            <icon :name="action.icon" v-if="action.icon" :scale=".9" class="me-2" />
            <span class="">
              {{ action.title }}
              <icon v-if="action.tooltip" class="text-gray-400" name="wisk-information-button" :scale=".5" tabindex="-1" />
            </span>
            <p class="m-0 text-small-info text-gray-500" v-if="action.subtitle">{{ action.subtitle }}</p>
          </b-dropdown-item>

        </gateKeeper>

        <div class="dropdown-divider" v-if="action.isDivider" />

        <b-dropdown-item v-if="action.children && action.children.length" group>
          <header class="dropdown-header">{{ action.title }}</header>
          <div>
            <!-- TODO: add hide logic to children when need comes -->
            <gateKeeper v-for="childAction in action.children" :key="childAction.key" :feature="childAction.planFeature"
              reactOnClickEvent :limitedItemsCount="childAction.planFeatureLimitedItemsCount" :title="childAction.tooltip">

              <b-dropdown-item @click="childAction.action(childAction.extra)" :variant="childAction.variant" class="w-100" :disabled="childAction.disabled || childAction.loading">
                <wiskLoading v-if="childAction.loading" loading />
                <icon :name="childAction.icon" v-if="childAction.icon" :scale=".9" class="me-2" />
                <span class="">
                  {{ childAction.title }}
                  <icon v-if="childAction.tooltip" class="text-gray-400" name="wisk-information-button" :scale=".5" tabindex="-1" />
                </span>
                <p class="m-0 text-small-info text-gray-500" v-if="action.subtitle">{{ action.subtitle }}</p>
              </b-dropdown-item>

            </gateKeeper>
          </div>

        </b-dropdown-item>
      </div>

    </b-dropdown>

  </div>
</template>

<script>

export default {
  name: 'WiskActions',
  components: {},
  props: {
    hasButtonSlot: Boolean,
    actions: { type: Array, default: () => [] },
    title: { type: String, default: 'Actions' },
    variant: { type: String, default: 'outline-primary' },
    size: { type: String, default: 'sm' },
    dropdownClass: { type: String, default: 'float-end me-2' }
  },
  computed: {
    mainActionVariant() {
      if (this.computedActionsLength === 1 && this.computedActions[0]) {
        if (this.computedActions[0].disabled) {
          return 'outline-disabled'
        }
        if (this.computedActions[0].variant) {
          return 'outline-' + this.computedActions[0].variant
        }
        return this.variant
      }
      return ''
    },
    computedActionsLength() {
      return this.actions.filter(a => a && !a.hide && !a.isDivider).length
    },
    computedActions() {
      return this.actions.filter(a => a && !a.hide && !a.main)
    },
    computedMainActions() {
      return this.actions.filter(a => a && !a.hide && a.main)
    }
  }
}
</script>
