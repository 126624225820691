export default {
  name: 'wisk-columns',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M31.842,1.6H3.173A3.177,3.177,0,0,0,0,4.76V30.224A3.18,3.18,0,0,0,3.173,33.4H31.842A3.167,3.167,0,0,0,35,30.224V4.76A3.163,3.163,0,0,0,31.842,1.6ZM3.173,30.224V11.138h12.74V30.224Zm28.669,0H19.1V11.138h12.74Z'
    }
  ]
}
