<template>
  <b-container fluid class="">
    <b-row>
      <b-col>
        <p class="my-4"> {{ translations.txtPurchaseOrdersSAQInfo }} </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-copy size="sm" variant="primary" class="me-1" :label="translations.txtGenericCopyClipboard" :copyText="saqOrderText" @success="saqCopySuccessCallback" @error="saqCopyErrorCallback" />
        <b-button size="sm" class="me-1" variant="primary" target="_blank" rel="noreferrer" href="https://gca.saq.qc.ca/login">
          {{ translations.txtPurchaseOrdersSAQOpen }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <textarea class="form-control" ref="saqOrderTextArea" id="saq" readonly :value="saqOrderText" :rows="20" :max-rows="1000"></textarea>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SAQForm',
  props: {
    saqOrderText: String
  },
  computed: { ...mapState(['translations']) },
  methods: {
    saqCopySuccessCallback() { },
    saqCopyErrorCallback() {
      console.warn('SAQ copy error')
    }
  },
  watch: {
    saqOrderText() {
      if (this.saqOrderText) {
        setTimeout(() => {
          if (this.$refs.saqOrderTextArea) {
            this.$refs.saqOrderTextArea.focus()
            this.$refs.saqOrderTextArea.select()
          }
        }, 0)
      }
    }
  }
}
</script>

<style>

</style>
