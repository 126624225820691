<template>
  <component :is="tag" role="radiogroup" :class="classList" v-bind="$attrs">
    <slot name="first" />
    <b-form-radio v-for="(opt, index) in options" :key="index" :value="opt[valueField]" :disabled="opt[disabledField]" :name="name">
      {{ opt[textField] }}
    </b-form-radio>
    <slot />
  </component>
</template>

<script>
import { toRaw } from 'vue'
import bFormRadio from './BFormRadio'

export default {
  name: 'BFormRadioGroup',
  components: { 'b-form-radio': bFormRadio },
  emits: ['update:modelValue', 'change'],
  props: {
    tag: { type: String, default: 'div' },
    buttons: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    size: { type: String },
    name: { type: String },
    buttonVariant: { type: String },
    modelValue: { type: undefined, default: 0 },
    options: { type: Array, default: () => [] },
    textField: { type: String, default: 'text' },
    valueField: { type: String, default: 'value' },
    disabledField: { type: String, default: 'disabled' }
  },
  data() {
    return {
      registeredOptions: []
    }
  },
  provide() {
    return {
      getRadioGroup: () => ({
        buttonVariant: this.buttonVariant,
        size: this.size,
        buttons: this.buttons,
        selected: this.selected,
        groupAttrs: this.$attrs,
        emitValue: this.emitValue,
        register: this.register,
        unregister: this.unregister
      })
    }
  },
  computed: {
    classList() {
      const classList = []
      if (this.buttons) {
        classList.push('btn-group-toggle')
        classList.push(this.vertical ? 'btn-group-vertical' : 'btn-group')
      } else {
        classList.push('d-flex')
        classList.push(this.vertical ? 'flex-column' : 'flex-row')
      }
      return classList
    },
    selected() {
      let selected = this.registeredOptions.find(t => t.value === this.modelValue)
      return selected ? selected.value : null
    }
  },
  methods: {
    register($option) {
      let option = toRaw($option)
      if (!this.registeredOptions.find(t => t.id === option.id)) {
        this.registeredOptions.push(option)
      }
      return this.registeredOptions.findIndex(t => t.id === option.id)
    },
    unregister($option) {
      let option = toRaw($option)
      this.registeredOptions = this.registeredOptions.slice().filter(t => t.id !== option.id)
    },
    emitValue(value) {
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
    }
  }
}
</script>
