<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemDetails', action: null })" :title="modalTitle" size="lg"
    hideOK hideFooter extraLarge>
    <itemStats :item="item" v-if="item" :selectedTab="editAction.selectedTab || 0" />
  </wiskModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import itemStats from '@/components/bottles/ItemStats'

export default {
  name: 'ItemDetails',
  components: { itemStats },
  props: { editAction: Object },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations', 'bottlesById']),
    modalTitle() {
      if (this.item) {
        return this.item.title + this.item.titleSuffix
      }

      return ''
    },
    item() {
      if (this.editAction && this.editAction.itemId) {
        return this.bottlesById[this.editAction.itemId]
      }
      if (this.editAction && this.editAction.item_id) {
        return this.bottlesById[this.editAction.item_id]
      }

      return null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  }
}
</script>
