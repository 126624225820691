<template>
  <b-row v-if="enoughData" class="interval-by-id-selector mb-1">
    <b-col sm="12" :md="useSubmitButton ? 5 : 6" style="min-width: 215px;">
      <wiskSelect infoTooltipKey="5de5aade-95e2-4539-8bd2-189d290770f8" :label="translations.txtGenericFrom" :modelValue="selectedItemFrom" required @update:modelValue="onFromChange"
        :items="dataFrom" :multiselectOptions="multiselectOptions" class="mb-0 mt-1" size="sm">
        <template v-slot:afterList>
          <b-button v-if="!inventoriesHighLevelLoadAllComplete" variant="primary" class="m-1" @click="getInventories">
            {{ translations.txtInventoryLoadAll }}
          </b-button>
        </template>
      </wiskSelect>
    </b-col>
    <b-col sm="12" :md="useSubmitButton ? 5 : 6" style="min-width: 215px;">
      <wiskSelect infoTooltipKey="1154e6a7-0042-4a45-823c-c9e065d63fe4" :label="translations.txtGenericTo" :modelValue="selectedItemTo" required @update:modelValue="onToChange"
        :items="dataTo" :multiselectOptions="multiselectOptions" class="mb-0 mt-1" size="sm">
        <template v-slot:afterList>
          <b-button v-if="!inventoriesHighLevelLoadAllComplete" variant="primary" class="m-1" @click="getInventories">
            {{ translations.txtInventoryLoadAll }}
          </b-button>
        </template>
      </wiskSelect>
    </b-col>
    <b-col sm="12" md="2" v-if="useSubmitButton" class="ps-0">
      <b-button @click="emitChange" class="mb-0 mt-1" variant="outline-primary" size="sm">
        {{ translations.txtGenericRunReport }}
      </b-button>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col>
      <span> {{ translations.txtGenericNotEnoughData }} </span>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import merge from 'lodash.merge'
import { formatDate, compareNumbers } from '@/modules/utils'

export default {
  name: 'IntervalByIdSelector',
  emits: ['changedFrom', 'changedTo'],
  components: {},
  props: {
    inputData: {
      type: Array,
      validator: data => {
        if (Array.isArray(data) && (!data.length || (data.length && data[0] && data[0].id && data[0].date))) {
          return true
        }
        return false
      }
    },
    useSubmitButton: Boolean,
    inputFrom: Number,
    inputTo: Number,
    allowLockedInventories: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedItemFrom: null,
      format: 'ff',
      selectedItemTo: null,
      enoughData: false,
      localData: []
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations', 'venue', 'inventoriesHighLevelLoadAllComplete']),
    multiselectOptions() {
      return {
        groupValues: 'data',
        groupLabel: 'month',
        trackBy: 'id',
        selectedLabel: '',
        selectLabel: '',
        deselectLabel: '',
        style: {},
        maxHeight: 450,
        label: 'title',
        allowEmpty: false,
        multiple: false
      }
    },
    dataFrom() {
      let data = [...this.localData].slice(1),
        lastLockedInventoryItem = data.find(i => i.date?.getTime() === this.venue.last_locked_inventory_date?.getTime())

      if (lastLockedInventoryItem) {
        lastLockedInventoryItem.$isDisabled = false
      }

      return this.groupByDate(data)
    },
    dataTo() {
      const data = this.localData.slice(0, -1)
      return this.groupByDate(data)
    }
  },
  methods: {
    ...mapActions(['getInventories']),
    groupByDate(data) {
      let months = []

      data.forEach(item => {
        let month = formatDate(item.date, { format: 'LLLL yyyy' }),
          found = months.find(d => d.month === month)

        if (!found) {
          // prettier-ignore
          months.push({
            month,
            date: DateTime.fromJSDate(item.date).startOf('month').toJSDate(),
            data: []
          })
          found = months.find(d => d.month === month)
        }

        found.data.push(merge({}, item, { title: formatDate(item.date, { format: this.format }) }))
        found.data.sort((a, b) => compareNumbers(b.date, a.date))
      })
      months.sort((a, b) => compareNumbers(b.date, a.date))
      return months
    },
    onFromChange(fromId) {
      const from = this.localData.find(z => z.id === fromId)
      this.selectedItemFrom = merge({}, from, { title: formatDate(from.date, { format: this.format }) })

      if (from && this.selectedItemTo && from.date >= this.selectedItemTo.date) {
        let index = this.localData.findIndex(d => d.id === from.id),
          item = this.localData[index - 1]

        this.selectedItemTo = merge({}, item, { title: formatDate(item.date, { format: this.format }) })
      }
    },
    onToChange(toId) {
      const to = this.localData.find(z => z.id === toId)
      this.selectedItemTo = merge({}, to, { title: formatDate(to.date, { format: this.format }) })

      if (to && this.selectedItemFrom && to.date <= this.selectedItemFrom.date) {
        let index = this.localData.findIndex(d => d.id === to.id),
          item = this.localData[index + 1]

        this.selectedItemFrom = merge({}, item, { title: formatDate(item.date, { format: this.format }) })
      }
    },
    handleInputTo() {
      if (this.inputTo && (!this.selectedItemTo || (this.selectedItemTo && this.selectedItemTo.id !== this.inputTo))) {
        let found = this.localData.find(z => z.id === this.inputTo)
        if (found) {
          this.selectedItemTo = merge({}, found, { title: formatDate(found.date, { format: this.format }) })
        }
      }
    },
    handleInputFrom() {
      if (this.inputFrom && (!this.selectedItemFrom || (this.selectedItemFrom && this.selectedItemFrom.id !== this.inputFrom))) {
        let found = this.localData.find(z => z.id === this.inputFrom)
        if (found) {
          this.selectedItemFrom = merge({}, found, { title: formatDate(found.date, { format: this.format }) })
        }
      }
    },
    emitChange() {
      this.$emit('changedFrom', this.selectedItemFrom)
      this.$emit('changedTo', this.selectedItemTo)
    }
  },
  watch: {
    selectedItemFrom() {
      if (!this.useSubmitButton) {
        this.$emit('changedFrom', this.selectedItemFrom)
      }
    },
    selectedItemTo() {
      if (!this.useSubmitButton) {
        this.$emit('changedTo', this.selectedItemTo)
      }
    },
    inputData: {
      handler(inputData) {
        this.localData = merge([], inputData || [])
          .filter(z => !z.archived)
          .sort((a, b) => compareNumbers(b.date, a.date))

        if (this.localData && this.localData.length > 1) {
          if (!this.selectedItemFrom && !this.selectedItemTo) {
            const defaultInventoryIndex = this.localData.findIndex(z => z.id === parseInt(this.$route.query.inventory, 10)),
              toIndex = defaultInventoryIndex >= 0 ? defaultInventoryIndex : 0,
              checkLockedInventory = this.allowLockedInventories || (this.localData[toIndex + 1] && !this.localData[toIndex + 1].is_locked && this.localData[toIndex] && !this.localData[toIndex].is_locked)
            if (checkLockedInventory) {
              this.selectedItemFrom = merge({}, this.localData[toIndex + 1], { title: formatDate(this.localData[toIndex + 1].date, { format: this.format }) })
              this.selectedItemTo = merge({}, this.localData[toIndex], { title: formatDate(this.localData[toIndex].date, { format: this.format }) })
            }
          }
          setTimeout(() => {
            this.handleInputFrom()
            this.handleInputTo()

            if (this.useSubmitButton) {
              this.emitChange()
            }
          }, 100)
          this.enoughData = true
        } else {
          this.enoughData = false
        }
      },
      immediate: true
    },
    inputFrom: {
      handler: 'handleInputFrom',
      immediate: true
    },
    inputTo: {
      handler: 'handleInputTo',
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.interval-by-id-selector {
  .multiselect__content-wrapper {
    min-width: 270px;
    border: 1px solid #c2cfd6;
    box-shadow: -1px 10px 44px -20px #333;
  }
}
</style>
