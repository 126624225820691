<template>
  <!-- <div> -->
  <wiskModal :title="translations.txtUsersRefer" size="lg" :visible="!!editAction" @ok="ok" :okDisabled="!valid" :okText="translations.txtGenericSend"
    @hide="setGlobalAction({ type: 'referUsers', action: null })" preventOKClose>

    <h4>
      {{ translations.txtUsersReferInfo }}
    </h4>

    <wiskInputGroup style="height: 450px;" class="mt-4">
      <b-row>
        <b-col>
          <wiskInput infoTooltipKey="de18b6b8-0c15-4dc7-841d-2268b807b405" :label="translations.txtGenericNotes" inputType="text" multiline v-model="notes"
          helperText="We'll send this message to the referred users along with the invitation. You can leave it empty."/>
        </b-col>
      </b-row>
      <b-row v-for="(contactInfo, index) in contactInfoList" :key="index">

        <b-col cols="4">
          <wiskInput infoTooltipKey="8b38895e-3242-46a6-95f6-b5e7a8a71ed9" :label="translations.txtGenericName" inputType="text" required
            @update:modelValue="onChange($event, index, 'name')" :modelValue="contactInfo.name" />
        </b-col>
        <b-col cols="3">
          <wiskInput infoTooltipKey="af9282be-b2b1-461b-8796-bb35c2eafbb1" :label="translations.txtGenericEmail" inputType="text"
            :validations="[{ type: 'email', validator: value => isValidEmail(value), message: translations.txtValidationEmail }]"
            @update:modelValue="onChange($event, index, 'email')" :modelValue="contactInfoList.email" />
        </b-col>
        <b-col cols="3">
          <wiskInput infoTooltipKey="9cfbfbee-499e-4cea-9791-de76ccb07b0b" :label="translations.txtGenericPhone" inputType="text"
            :validations="[{ type: 'phone', validator: value => isValidPhoneNumber(value), message: translations.txtValidationPhone }]"
            @update:modelValue="onChange($event, index, 'phone')" :modelValue="contactInfoList.phone" />
        </b-col>
        <b-col cols="2">
          <b-button variant="link" size="md" title="Remove" class="px-0" @click="contactInfoList.splice(index, 1)" v-if="index">
            <icon class="text-danger" name="wisk-minus" :scale="1" />
          </b-button>
        </b-col>
      </b-row>

      <b-button variant="link" size="lg" title="Add" class="px-0" @click="contactInfoList.push({ ...emptyContactInfo })">
        <icon class="" name="wisk-plus" :scale="1.6" />
      </b-button>

      <wiskLoading :loading="loading" />
    </wiskInputGroup>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { isValidEmail, isValidPhoneNumber } from '@/modules/utils'

const emptyContactInfo = { phone: '', email: '', name: '' }

export default {
  name: 'ReferUsers',
  props: {
    editAction: Object
  },
  data() {
    return {
      loading: false,
      isValidEmail,
      isValidPhoneNumber,
      emptyContactInfo,
      notes: '',
      contactInfoList: [{ ...emptyContactInfo }],
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations']),
    valid() {
      return !!this.contactInfoList.length && this.contactInfoList.some(contactInfo => isValidEmail(contactInfo.email) || isValidPhoneNumber(contactInfo.phone))
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onChange(value = '', index = 0, type = 'email') {
      this.contactInfoList[index] = { ...this.contactInfoList[index], [type]: value }
    },
    ok() {
      //we can add deduplication and more serious validation here if needed
      let formData = {
        subject: 'Refer Users',
        body: 'Invited users below:'
      },
        validCopy = this.contactInfoList.filter(contactInfo => isValidEmail(contactInfo.email) || isValidPhoneNumber(contactInfo.phone))

      formData.body += '<br /> \n <br /> \n'

      validCopy.forEach(contactInfo => {
        formData.body += `<br /> \n ${contactInfo.name} - ${contactInfo.email} - ${contactInfo.phone}`
      })

      formData.body += '<br /> \n'
      formData.body += 'Notes:  <br /> \n'
      formData.body += this.notes

      this.loading = true

      api
        .emailSupport(formData)
        .then(() => {
          this.notify({
            message: this.translations.txtUsersReferInviteSuccess,
            type: 'success'
          })
        })
        .finally(() => {
          this.setGlobalAction({ type: 'referUsers', action: null })
        })
    }
  },
  mounted() { },
  watch: { }
}
</script>

<style lang="scss">

</style>
