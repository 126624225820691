<template>
  <b-container>
    <b-row>
      <b-col>
        <img src="/img/ads/guy-tablet.webp" alt="Ad Image" style="width: 600px;" class="d-block mx-auto"/>
      </b-col>

      <b-col>
        <h1 class="mb-3 mt-2"> Congratulations on completing your inventory with WISK! 🎉 </h1>
        <h4 class="mb-3"> Turn Your Network into Cash and Help Others Simplify Their Inventory Management! </h4>
        <p class="fs-5">
          You've just experienced how easy inventory, ordering, and invoice management can be—now it's time to spread the word and get rewarded. Know someone who would benefit from WISK? Refer them to our system, and we'll send $500 straight to your bank account (no WISK credits, real cash!).
        </p>
        <p class="fs-5">
          Your friend will thank you for making their inventory as simple as yours. It's a win-win! They'll streamline their operations, and you'll pocket $500 once they sign up for Pro/Yearly plans! 💰
          <br />
          <small>(Essentials and Quarterly plans get a lower bonus.)</small>
        </p>
        <p class="fs-5">
          So go ahead, whether it's a friend, another restaurant, or even your grandma's favorite café, start sharing the love and get rewarded!
        </p>

        <b-button size="lg" variant="primary" @click="$emit('ok')" class="mx-auto mt-4 mb-2 d-block">
          Refer a Friend
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

export default {
  name: 'Refer',
  emits: ['ok']
}
</script>
