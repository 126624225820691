<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemsUpload', action: null })" :title="translations.txtVenueBottlesUploadItems"
    size="lg" :okDisabled="!file" @ok="save" :okText="translations.txtGenericUpload" preventOKClose>
    <div v-if="editAction" class="wisk-items-upload" style="min-height: 550px;">
      <b-container fluid class="p-4">
        <b-row>
          <b-col class="mb-5">
            <p>
              {{ translations.txtVenueBottlesUploadMessage }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button class="" style="" variant="outline-success" v-tooltip="translations.txtVenueBottlesUploadDownloadExistingItems" @click="downloadVenueItems" size="md">
              <icon name="wisk-excel" :scale="0.7" style="margin-top: -2;" />
              <span> {{ translations.txtVenueBottlesUploadDownloadExistingItems }} </span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-5">
            <b-form-group label="" class="">
              <b-input-group>
                <fileInput accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="wisk-items-upload-input" @reset="file = null" v-model="file" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="invalidRowMessages.length > 0">
          <b-row>
            <b-col class="text-bold">
              <h5> {{ translations.txtVenueBottlesUploadErrorMessage }} </h5>
            </b-col>
          </b-row>
          <b-row v-for="(row, index) in invalidRowMessages" :key="index">
            <b-col class="text-danger">
              {{ row }}
            </b-col>
          </b-row>
        </b-row>
      </b-container>

      <wiskLoading :loading="loading" />
    </div>

  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/api'
import fileInput from '@/components/common/FileInput'

export default {
  name: 'ItemsUploader',
  components: { fileInput },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      file: null,
      invalidRowMessages: []
    }
  },
  computed: {
    ...mapState(['translations']),
    mode() {
      return (this.editAction && this.editAction.mode) || 'items'
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    save() {
      let formData = new FormData()
      this.loading = true
      formData.append('data', this.file)

      api
        .uploadItems(formData, this.mode)
        .then(response => {
          if (response && response.invalid_rows_messages && response.invalid_rows_messages.length) {
            this.invalidRowMessages = response.invalid_rows_messages
          } else {
            this.setGlobalAction({ type: 'itemsUpload', action: null })
          }
        })
        .finally(() => {
          this.loading = false
          this.file = null

          if (this.$refs.fileInput && this.$refs.fileInput.reset) {
            this.$refs.fileInput.reset()
          }
        })
    },
    downloadVenueItems() {
      this.loading = true
      api.downloadBlob('bottles/export', 'Venue_items.xlsx').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
