<template>
  <div class="date-range-selector" :class="{ 'has-shortcuts': shortcuts, 'material-design-input has-value': !shortcuts, 'custom-range': selectedShortcut === 'custom', 'plain-text': showPlainText }">
    <span v-if="showPlainText" class="text-truncate d-inline-block" :class="[`size-${size}`]" v-tooltip="formattedValue">
      {{ plainText }}
    </span>

    <div v-if="!showPlainText">
      <div class="text-start pe-0" :class="{ 'form-control': !shortcuts }" :style="{ paddingBottom: shortcuts ? '' : '2px !important' }">
        <slot name="prepend"></slot>
        <wiskSelect :infoTooltipKey="infoTooltipKey" v-if="shortcuts" :items="shortcutsList" @update:modelValue="shortcutSelected" :modelValue="selectedShortcut" class="mb-0"
          :label="inputLabel" :multiselectOptions="{ allowEmpty: clearable, maxHeight: 500 }" :size="size" />

        <span class="custom-date-range-formatted text-truncate d-inline-block" :title="formattedValue" v-if="formattedValue"> {{ formattedValue }} </span>

        <b-button variant="link" @click.stop="modalOpen = true" v-if="selectedShortcut === 'custom'" :disabled="disabled" class="custom-range-edit btn-icon d-inline-block">
          <icon name="wisk-edit" class="text-primary" :scale="0.7" />
        </b-button>
      </div>

      <label v-if="!shortcuts && (label || infoTooltipVisible)">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" style="pointer-events: auto;" />
        {{ label }}

        <slot name="label-append"></slot>
      </label>

      <wiskModal :title="translations.txtGenericDateRange" v-model="modalOpen" hideHeaderExtra @ok="ok" :okDisabled="!dateComputedEnd || !dateComputedStart">
        <b-row class="mx-auto date-range-selector-modal" :key="modalOpen">
          <b-col class="px-1">
            <wiskInputGroup :legend="translations.txtGenericStartDate" class="px-0 date-selectors" fieldsetClass="text-center">
              <datePicker v-model="localDateStart" :popover="popoverSettings" :firstDayOfWeek="2" :min-date="notBefore" mode="dateTime" is24hr :rules="startRules" :timezone="timezone" hide-time-header class="wisk-datepicker" :key="keyMinModal" />
              <!-- :max-date="dateComputedEnd" -->
            </wiskInputGroup>
          </b-col>
          <b-col class="px-1">
            <wiskInputGroup :legend="translations.txtGenericEndDate" class="px-0 date-selectors" fieldsetClass="text-center">
              <datePicker v-model="localDateEnd" :popover="popoverSettings" :firstDayOfWeek="2" :max-date="notAfter" mode="dateTime" is24hr :rules="endRules" :timezone="timezone" hide-time-header class="wisk-datepicker" :key="keyMaxModal" />
              <!-- :min-date="dateComputedStart"  -->
            </wiskInputGroup>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6 class="text-muted d-inline-block mb-0" :class="rangeOverflowAttentionClass"> {{ formattedValue }}</h6>
            <div v-if="validator && validator([dateComputedStart, dateComputedEnd])" class="mt-2">
              <icon name="wisk-warning" class="text-danger" :scale="0.7" />
              {{ validator([dateComputedStart, dateComputedEnd]) }}
            </div>
          </b-col>
        </b-row>
      </wiskModal>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'
import { DateTime } from 'luxon'
import { DatePicker as datePicker } from 'v-calendar'
import { mapState } from 'vuex'
import { formatDate, getDateRangeShortcuts } from '@/modules/utils'

export default {
  name: 'DateRangeSelector',
  emits: ['update:modelValue', 'change'],
  components: { datePicker },
  // prettier-ignore
  props: {
    modelValue: [Array, Object],
    initialShortcut: { type: String },
    notBefore: {
      type: Date,
      default: () => DateTime.fromJSDate(new Date()).minus({ 'years': 10 }).toJSDate()
    },
    clearable: Boolean,
    required: Boolean,
    notAfter: {
      type: Date,
      default: () => DateTime.fromJSDate(new Date()).plus({ years: 10 }).toJSDate()
    },
    showPlainText: Boolean,
    size: { type: String, default: 'md' },
    label: { type: String, default: '' },
    infoTooltipKey: { type: String, default: '8d0c6893-40dc-41ba-bd83-b7281e5d4837' },
    missingEndLabel: { type: String, default: ' - ' },
    missingStartLabel: { type: String, default: ' - ' },
    disabled: Boolean,
    shortcuts: { type: Boolean, default: true },
    validator: Function
  },
  data() {
    return {
      infoTooltipVisible: false,
      popoverSettings: { placement: 'bottom', visibility: 'focus', positionFixed: true },
      localDateStart: null,
      localDateEnd: null,
      modalOpen: false,
      selectedRange: [],
      selectedShortcut: null,
      venueBusinessDayStartHour: 0,
      rangeOverflowAttentionClass: '',
      keyMinModal: 0,
      keyMaxModal: 0
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'user']),
    startRules() {
      const rules = { hours: {}, minutes: { interval: 5 } },
        start = this.localDateStart && DateTime.fromJSDate(this.localDateStart),
        min = this.notBefore && DateTime.fromJSDate(this.notBefore),
        end = this.dateComputedEnd && DateTime.fromJSDate(this.dateComputedEnd)

      if (start && min && start.hasSame(min, 'day')) {
        rules.hours.min = min.hour
        rules.minutes.min = start.hasSame(min, 'hour') ? min.minute : null
      }
      if (start && end && start.hasSame(end, 'day')) {
        rules.hours.max = end.hour
        rules.minutes.max = start.hasSame(end, 'hour') ? end.minute : null
      }
      return rules
    },
    endRules() {
      const rules = { hours: {}, minutes: { interval: 5 } },
        end = this.localDateEnd && DateTime.fromJSDate(this.localDateEnd),
        max = this.notAfter && DateTime.fromJSDate(this.notAfter),
        start = this.dateComputedStart && DateTime.fromJSDate(this.dateComputedStart)

      if (end && max && end.hasSame(max, 'day')) {
        rules.hours.max = max.hour
        rules.minutes.max = end.hasSame(max, 'hour') ? max.minute : null
      }
      if (end && start && end.hasSame(start, 'day')) {
        rules.hours.min = start.hour
        rules.minutes.min = end.hasSame(start, 'hour') ? start.minute : null
      }
      return rules
    },
    plainText() {
      if (this.showPlainText) {
        let found = this.shortcutsList.find(s => s?.id === this.selectedShortcut),
          label = this.label ? this.label + ': ' : ''

        return `${label}${found?.title}`// + (this.formattedValue ? ` (${this.formattedValue})` : '')

      }
      return ''
    },
    formattedValue() {
      if (this.localDateStart || this.localDateEnd) {
        return `${formatDate(this.localDateStart, { defaultIfEmpty: this.missingStartLabel })} - ${formatDate(this.localDateEnd, { defaultIfEmpty: this.missingEndLabel })}`
      }
      return ''
    },
    dateComputedStart() {
      if (this.localDateStart && this.localDateStart.getTime) {
        if (this.notBefore && this.notBefore > this.localDateStart) {
          return this.notBefore
        }
        return this.localDateStart
      }

      return null
    },
    dateComputedEnd() {
      if (this.localDateEnd && this.localDateEnd.getTime) {
        if (this.notAfter && this.notAfter < this.localDateEnd) {
          return this.notAfter
        }
        return this.localDateEnd
      }

      return null
    },
    shortcutsList() {
      if (this.shortcuts) {
        return [
          {
            title: this.translations.txtGenericToday,
            id: 'today',
            $isDisabled: this.validateRange([this.shortcutsComputed.today[0], this.shortcutsComputed.today[0]])
          },
          {
            title: this.translations.txtGenericYesterday,
            id: 'yesterday',
            $isDisabled: this.validateRange([this.shortcutsComputed.yesterday[0], this.shortcutsComputed.yesterday[0]])
          },
          {
            title: this.translations.txtGenericLastWeek,
            id: 'lastWeek',
            $isDisabled: this.validateRange(this.shortcutsComputed.lastWeek)
          },
          {
            title: this.translations.txtGenericLast7Days,
            id: 'last7Days',
            $isDisabled: this.validateRange(this.shortcutsComputed.last7Days)
          },
          {
            title: this.translations.txtGenericLast30Days,
            id: 'last30Days',
            $isDisabled: this.validateRange(this.shortcutsComputed.last30Days)
          },
          {
            title: this.translations.txtGenericLast30DaysLast6Months,
            id: 'last6Months',
            $isDisabled: this.validateRange(this.shortcutsComputed.last6Months)
          },
          {
            title: this.translations.txtGenericCurrentMonth,
            id: 'currentMonth',
            $isDisabled: this.validateRange(this.shortcutsComputed.currentMonth)
          },
          {
            title: this.translations.txtGenericLastMonth,
            id: 'lastMonth',
            $isDisabled: this.validateRange(this.shortcutsComputed.lastMonth)
          },
          {
            title: this.translations.txtGenericCurrentQuarter,
            id: 'currentQuarter',
            $isDisabled: this.validateRange(this.shortcutsComputed.currentQuarter)
          },
          {
            title: this.translations.txtGenericLastQuarter,
            id: 'lastQuarter',
            $isDisabled: this.validateRange(this.shortcutsComputed.lastQuarter)
          },
          {
            title: this.translations.txtGenericCurrentYear,
            id: 'currentYear',
            $isDisabled: this.validateRange(this.shortcutsComputed.currentYear)
          },
          {
            title: this.translations.txtGenericLastYear,
            id: 'lastYear',
            $isDisabled: this.validateRange(this.shortcutsComputed.lastYear)
          },
          {
            title: this.translations.txtGenericCustomRange,
            id: 'custom'
          }
        ]
      }
      return [
        {
          title: this.translations.txtGenericCustomRange,
          id: 'custom'
        }
      ]
    },
    shortcutsComputed() {
      if (this.shortcuts) {
        return getDateRangeShortcuts(this.venueBusinessDayStartHour)
      }
      return null
    },
    inputLabel() {
      return this.label || this.translations.txtGenericDateRange
    },
    timezone() {
      if (this.venue.timezone && (this.user?.is_wisk_user || this.user?.god_mode)) {
        return this.venue.timezone
      }
      return undefined
    }
  },
  methods: {
    validateRange(dateRange) {
      return (this.notBefore && this.notBefore > dateRange[0]) || (this.notAfter && this.notAfter < dateRange[1])
    },
    ok() {
      if ((this.dateComputedStart && this.dateComputedEnd) || !this.required) {
        this.selectedRange = [this.dateComputedStart, this.dateComputedEnd]
        this.$emit('update:modelValue', this.selectedRange)
        this.$emit('change', { id: this.selectedShortcut, range: this.selectedRange })
      }
    },
    shortcutSelected(item) {
      if (item === 'custom') {
        this.modalOpen = true
        const start = DateTime.fromJSDate(new Date()).minus({ 'days': 30 }).startOf('day').plus({ hours: this.venueBusinessDayStartHour }).toJSDate()
        if (start > this.notBefore) {
          this.selectedRange = [start, new Date()]
        } else {
          this.selectedRange = []
        }
      } else {
        this.selectedRange = this.shortcutsComputed[item] || []
        this.$emit('update:modelValue', this.selectedRange)
        this.$emit('change', { id: item, range: this.selectedRange })
      }
      this.localDateStart = this.selectedRange[0]
      this.localDateEnd = this.selectedRange[1]
      this.selectedShortcut = item
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.modelValue?.id && this.shortcutsComputed[this.modelValue?.id]) {
        //shortcutSelected can't be called here because it will emit the change event
        this.selectedRange = this.shortcutsComputed[this.modelValue?.id] || []
        this.selectedShortcut = this.modelValue?.id
      } else if (this.initialShortcut && this.shortcutsComputed[this.initialShortcut]) {
        this.shortcutSelected(this.initialShortcut)
      }

      if (this.shortcutsList.length === 1) {
        this.selectedShortcut = this.shortcutsList[0] && this.shortcutsList[0].id
      }

      this.infoTooltipVisible = !!this.infoTooltipKey

      if (this.autofocus) {
        this.focus()
      }
    }, 100)
  },
  watch: {
    localDateStart() {
      if (this.localDateStart && this.localDateEnd && this.localDateStart > this.localDateEnd) {
        this.localDateEnd = this.localDateStart
        this.rangeOverflowAttentionClass = 'highlight'
        this.keyMaxModal++

        setTimeout(() => {
          this.rangeOverflowAttentionClass = ''
        }, 500)
      }
    },
    localDateEnd() {
      if (this.localDateEnd && this.localDateStart && this.localDateEnd < this.localDateStart) {
        this.localDateStart = this.localDateEnd
        this.rangeOverflowAttentionClass = 'highlight'
        this.keyMinModal++

        setTimeout(() => {
          this.rangeOverflowAttentionClass = ''
        }, 500)
      }
    },
    venue: {
      immediate: true,
      handler(venue) {
        if (venue && venue.business_day_starting_hour) {
          this.venueBusinessDayStartHour = venue.business_day_starting_hour
        }
      }
    },
    modalOpen(modalOpen) {
      if (modalOpen && !this.localDateStart && !this.localDateEnd && this.modelValue?.length) {
        this.localDateStart = this.modelValue[0]
        this.localDateEnd = this.modelValue[1]
      }

      if (!modalOpen) {
        this.localDateStart = this.selectedRange[0]
        this.localDateEnd = this.selectedRange[1]
      }
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler(modelValue) {
        let dateRange = Array.isArray(modelValue) ? modelValue : modelValue?.range

        if (!isEqual(dateRange, this.selectedRange) && (!modelValue?.id || modelValue?.id === 'custom')) {
          if (dateRange?.length) {
            if (dateRange[0]) {
              if (dateRange[0].getTime) {
                this.localDateStart = dateRange[0]
              } else if (typeof dateRange[0] === 'string') {
                this.localDateStart = new Date(dateRange[0])
              }
            }
            if (dateRange[1]) {
              if (dateRange[1].getTime) {
                this.localDateEnd = dateRange[1]
              } else if (typeof dateRange[1] === 'string') {
                this.localDateEnd = new Date(dateRange[1])
              }
            }
          }
          this.selectedRange = [this.localDateStart, this.localDateEnd]
        }
      }
    }
  }
}
</script>

<style lang="scss">
.date-range-selector-modal {
  .wisk-time-picker {
    max-width: 170px;
    float: right;
  }
}

.date-range-selector {
  &.plain-text {

    .size-xs {
      font-size: 10px;
      }

      .size-sm {
        font-size: 11.25px;
      }

      .size-md {
        font-size: 12.5px;
      }

      .size-lg {
        font-size: 13.75px;
      }

      .size-xl {
        font-size: 15px;
      }
            }

  .modal>.modal-dialog {
    max-width: 640px;
  }

  .wisk-modal-container .wisk-modal .modal-dialog .modal-body {
    overflow: unset;
  }

  .date-selectors {
    .custom-date-picker-wrapper {
      input {
        height: 33px;
        border: 1px solid #0973e5;
        border-radius: 4px;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 12px;
        color: #181818;
        font-weight: 600;
      }
    }
  }

  .custom-range-edit {
    margin-top: -5px;
  }

  .custom-date-range-formatted {
    font-size: 1em;
  }

  &.has-shortcuts {
    .custom-date-range-formatted {
      font-size: 80%;
    }
  }

  .form-control {
    .custom-date-range-formatted {
      width: calc(100% - 20px);

      +.btn.btn-icon {
        position: absolute;
        right: 0;
        top: 6px;
        border: 0;
      }
    }
  }
}
</style>
