<template>
  <div class="fill-width-height wisk-date-picker material-design-input" :class="{ 'has-value': hasValue, 'no-value': !hasValue, 'active': active }" style="z-index: 0">
    <div class="fill-width-height">
      <input v-bind="$attrs" ref="datepicker" class="form-control date-picker-input" :class="{ active: !disabled }" :value="formattedValue" @focus="onFocus" @blur="onBlur" placeholder=" " readonly />

      <label style="z-index: 1;">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />

        {{ label }}

        <slot name="label-append"></slot>
      </label>

      <div @click.stop="modalOpen = !disabled && true" class="fill-width-height form-control plain-text-span" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;" :style="{ cursor: disabled && 'unset' }">
      </div>

      <b-button v-if="!disabled && !required && !!formattedValue" variant="link" @click.stop="clear" style="position: absolute; top: 0; right: 0; border-color: transparent; height: 100%;">
        <icon name="wisk-exit" class="text-primary" :scale="0.6" />
      </b-button>
    </div>
    <portal to="cell-date-picker" v-if="modalOpen">
      <wiskModal :title="label" v-model="modalOpen" hideHeaderExtra hideFooterExtra @ok="ok" bodyClass="p-0" size="sm" @cancel="cancel">
        <datePicker v-model="localDate" :firstDayOfWeek="2" :popover="popoverSettings" class="material-design-input date-picker-component w-100 border-0 wisk-datepicker" :inputProps="{ placeholder: 'Select date' }"
          :min-date="min" :max-date="max" :rules="timeRules" is24hr mode="dateTime" :timezone="timezone" hide-time-header />
      </wiskModal>

    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
// import datePicker from 'v-calendar/lib/components/date-picker.umd'
import { DatePicker as datePicker } from 'v-calendar'
import { formatDate } from '@/modules/utils'

export default {
  name: 'DatePickerInput',
  emits: ['blur', 'focus', 'input', 'modalOpen', 'modalClose'],
  components: { datePicker },
  props: {
    className: String,
    disabled: Boolean,
    required: Boolean,
    min: Date,
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    max: Date,
    startWithModalOpen: Boolean,
    label: String,
    prefix: String,
    suffix: String
  },
  data() {
    return {
      popoverSettings: { placement: 'bottom', visibility: 'focus', positionFixed: true },
      active: false,
      localDate: null,
      oldValue: null,
      infoTooltipVisible: false,
      modalOpen: false
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'user']),
    timeRules() {
      const rules = { hours: {}, minutes: { interval: 5 } },
        value = this.localDate && DateTime.fromJSDate(this.localDate),
        min = this.min && DateTime.fromJSDate(this.min),
        max = this.max && DateTime.fromJSDate(this.max)

      if (value && min && value.hasSame(min, 'day')) {
        rules.hours.min = min.hour
        rules.minutes.min = value.hasSame(min, 'hour') ? min.minute : null
      }

      if (value && max && value.hasSame(max, 'day')) {
        rules.hours.max = max.hour
        rules.minutes.max = value.hasSame(max, 'hour') ? max.minute : null
      }

      return rules
    },
    hasValue() {
      return !!this.oldValue
    },
    formattedValue() {
      return (this.oldValue && formatDate(this.oldValue)) || ''
    },
    dateComputed() {
      if (this.localDate?.getTime) {
        return this.localDate
      }

      return null
    },
    timezone() {
      if (this.venue.timezone && (this.user?.is_wisk_user || this.user?.god_mode)) {
        return this.venue.timezone
      }
      return undefined
    }
  },
  methods: {
    ok() {
      if (this.dateComputed?.getTime) {
        this.$emit('input', new Date(this.dateComputed.getTime()))
        this.oldValue = new Date(this.dateComputed.getTime())
      }
    },
    cancel() {
      this.localDate = new Date(this.oldValue.getTime())
    },
    clear() {
      this.$emit('input', null)
      this.localDate = null
      this.oldValue = null
    },
    onFocus(event) {
      this.active = true
      this.$emit('focus', event)
    },
    onBlur(event) {
      this.active = false
      this.$emit('blur', event)
    },
    setValue(payload) {
      let value = null

      if (payload?.length && payload[payload.length - 1] === 'Z') {
        value = new Date(payload)
      } else {
        value = payload
      }

      if (value?.getTime) {
        this.localDate = value

        setTimeout(() => {
          if (this.dateComputed?.getTime) {
            this.oldValue = new Date(this.dateComputed.getTime())
          }
        }, 500)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  watch: {
    modalOpen(modalOpen) {
      this.$emit(modalOpen ? 'modalOpen' : 'modalClose')

      if (!modalOpen && this.oldValue?.getTime) {
        this.localDate = new Date(this.oldValue.getTime())
      }
    },
    startWithModalOpen: {
      immediate: true,
      handler(startWithModalOpen) {
        if (startWithModalOpen) {
          this.modalOpen = true
        }
      }
    }
  }
}
</script>

<style lang="scss">
.date-picker-input {
  width: 100%;
  background-color: #fff !important;
  min-height: 33px;
  //min-width: 215px;
  padding-right: 25px;
}

.date-picker-component {
  input {
    border: 0;
    height: 100%;
  }
}

.b-form-spinbutton {
  height: 100%;

  .btn {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
