import merge from 'lodash.merge'
import { getRandom, arrayToObjectById } from '@/modules/utils'
import valueGettersByType from '@/components/timeline/valueGettersByType'

export default (timelineAPIResult, state, setGlobalAction) => {
  let copy = timelineAPIResult.map(r => merge({}, r)),
    values = copy
      .map(r => r.values)
      .flat(1)
      .map(v => ({ ...v.value, key: v.key })),
    valuesByKey = arrayToObjectById(values, 'key')

  copy.forEach(item => {
    let message = item.message,
      inGuid = false,
      guid = '',
      plainText = '',
      elements = []

    for (let i = 0; i < message.length; i++) {
      let char = message[i]
      if (char === '}') {
        if (valuesByKey[guid]) {
          elements.push(valueGettersByType(state, valuesByKey[guid] && valuesByKey[guid].type, valuesByKey[guid], setGlobalAction))
        } else {
          console.warn('!!! not guid !!!', guid, message)
          elements.push({ key: getRandom(), text: guid })
        }
        inGuid = false
        guid = ''
      }
      if (inGuid) {
        guid += char
      } else if (char !== '{' && char !== '}') {
        plainText += char
      }
      if (char === '{') {
        elements.push({ key: getRandom(), text: plainText })
        plainText = ''
        inGuid = true
      }
    }
    item.date = new Date(item.date)
    item.elements = elements
    item.text = elements.map(e => e.text).join('')
    item.id = getRandom()
  })

  return copy
}
