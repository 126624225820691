export default {
  name: 'wisk-areas',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M32.6,0H2.4C1,0,0,1,0,2.4v30.3C0,34,1,35,2.4,35h30.3c1.3,0,2.4-1.1,2.4-2.4V2.4C35,1,33.9,0,32.6,0z M12.7,25.3 c-0.3,0.7-0.6,1.4-0.9,2c-0.8,1.3-1.6,2.7-2.4,4c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0-0.1c-0.9-1.5-1.9-3-2.8-4.5 c-0.4-0.6-0.7-1.2-0.9-1.9c-0.2-0.7-0.2-1.4,0.1-2c0.4-1.1,1.4-2,2.5-2.4c2.1-0.6,4.1,0.6,4.7,2.6C13,23.9,13,24.6,12.7,25.3z  M16.8,16.8H1.4V2.4c0-0.5,0.4-1,1-1h14.4V16.8z M33.6,32.6c0,0.5-0.4,1-1,1H18.2V18.2h15.4V32.6z M33.6,16.8H18.2V1.4h14.4 c0.5,0,1,0.4,1,1V16.8z'
    },
    { d: 'M11.4,24.1c0,1.2-1,2.1-2.2,2.1c-1.2,0-2.2-1-2.2-2.1c0-1.2,0.9-2.2,2.2-2.2C10.4,21.9,11.4,22.8,11.4,24.1z' }
  ]
}
