<template>
  <component :is="tag" :class="classList" role="alert" v-show="(!dismissible && show) || (dismissible && visible)">
    <slot></slot>
    <button type="button" class="close btn-close" aria-label="Close" @click="dismiss" v-if="dismissible">
      <span aria-hidden="true">&times;</span>
    </button>
  </component>
</template>

<script>
import confetti from 'canvas-confetti'

export default {
  name: 'BAlert',
  emits: ['closed'],
  props: {
    tag: { type: String, default: 'div' },
    show: { type: Boolean, default: false },
    tada: { type: Boolean, default: false },
    dismissible: { type: Boolean, default: false },
    variant: { type: String, default: 'info' }
  },
  data() {
    return {
      visible: true
    }
  },
  computed: {
    classList() {
      const classList = ['alert']
      if (this.variant) classList.push(`alert-${this.variant}`)
      if (this.dismissible) classList.push('alert-dismissible')
      if (this.dismissible && this.visible) classList.push('show')
      return classList
    }
  },
  methods: {
    dismiss() {
      this.visible = false
      this.$emit('closed')
    }
  },
  mounted() {
    if (this.variant === 'congratulation' && this.tada) {
      let canvas = document.createElement('canvas')
      canvas.classList.add('alert-confetti-canvas')
      this.$el.append(canvas)
      canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true })
      canvas.confetti({
        particleCount: 100,
        spread: 70,
        angle: 20,
        origin: { x: 0 },
      })
      canvas.confetti({
        particleCount: 100,
        spread: 70,
        angle: 160,
        origin: { x: 1 },
      })
    }
  }
}
</script>

<style lang="css">
.alert.alert-congratulation {
  color: #fff;
  background-image: url(/img/congratulations-alert-banner.svg);
  background-size: 160px 45px;
}

.alert .alert-confetti-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0 0;
}
</style>
