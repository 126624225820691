export default {
  name: 'wisk-switch-venue',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,25.5c0,0.4-0.2,0.8-0.4,1.1l-7.4,7.3c-0.3,0.3-0.6,0.4-1,0.4c-0.8,0-1.5-0.7-1.5-1.5c0-0.4,0.2-0.8,0.4-1.1l4.8-4.8 H4.4c-0.8,0-1.5-0.7-1.5-1.5S3.6,24,4.4,24c0,0,0,0,0,0h25.5l-4.8-4.8c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0c0,0,0,0,0,0 l7.4,7.3C34.8,24.7,35,25.1,35,25.5z'
    },
    {
      d: 'M32.1,9.5c0,0.8-0.7,1.5-1.5,1.5H5.1l4.8,4.8c0.6,0.6,0.6,1.5,0,2.1c-0.3,0.3-0.7,0.4-1,0.4c-0.4,0-0.8-0.2-1-0.4l-7.4-7.3 C0.2,10.3,0,9.9,0,9.5c0-0.4,0.2-0.8,0.4-1.1l7.4-7.3c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1c0,0,0,0,0,0L5.1,8h25.5 C31.4,8,32.1,8.7,32.1,9.5C32.1,9.5,32.1,9.5,32.1,9.5z'
    }
  ]
}
