<template>
  <component :is="tag" v-if="value" style="padding: 0 0.25em">
    <span v-if="localLongPosition === 'left'" :title="currency.name" class="text-uppercase pe-1">
      {{ currency.code }}
    </span>
    <span v-if="currency.position === 'left'">{{ currency.symbol }}</span>
    <span>{{ formatted }}</span>
    <span v-if="currency.position === 'right'">{{ currency.symbol }}</span>
    <span v-if="localLongPosition === 'right'" :title="currency.name" class="text-uppercase ps-1">
      {{ currency.code }}
    </span>
  </component>
  <component :is="tag" v-else-if="placeholder">{{ placeholder }}</component>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatNumber } from '@/modules/utils'

export default {
  name: 'PlanDisplayPrice',
  props: {
    value: { type: Number, default: 0 },
    placeholder: String,
    tag: { type: String, default: 'span' },
    long: { type: String, default: 'hide' },
    decimals: { type: Number, default: 2 }
  },
  computed: {
    ...mapState(['options']),
    ...mapGetters(['venue']),
    currency() {
      let currencies = this.options.currencies,
        currency = currencies.find(c => c.code === this.venue.subscription.currency)
      return currency || { code: this.venue.subscription.currency }
    },
    localLongPosition() {
      if (this.long === 'left' || this.long === 'right') {
        return this.long
      }
      if (this.long === '') {
        return this.currency.position
      }
      return null
    },
    formatted() {
      return formatNumber(this.value, { decimals: this.decimals })
    }
  }
}
</script>
