export default {
  name: 'wisk-purchase-orders',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M31.7747,11.0316H29.25a8.0888,8.0888,0,0,0,.18-1.7136,8.7178,8.7178,0,0,0-17.4355,0,8.0888,8.0888,0,0,0,.18,1.7136H7.6957l-.992-4.0131A3.2314,3.2314,0,0,0,3.5773,4.5834H1.6083a1.6084,1.6084,0,0,0,0,3.2167h1.969L7.8009,24.6945a3.228,3.228,0,0,0,3.1264,2.45H29.3548a3.2024,3.2024,0,0,0,3.1263-2.45l2.42-9.6648A3.2147,3.2147,0,0,0,31.7747,11.0316ZM20.7122,2.7948A6.5269,6.5269,0,0,1,27.2354,9.318a5.8315,5.8315,0,0,1-.24,1.7136,6.5076,6.5076,0,0,1-12.5657,0,5.8315,5.8315,0,0,1-.24-1.7136A6.527,6.527,0,0,1,20.7122,2.7948Zm8.6426,21.1181H10.9273l-2.42-9.6648h5.02a8.7078,8.7078,0,0,0,14.3693,0h3.8779Z'
    },
    { d: 'M11.278,34.4a2.4174,2.4174,0,1,0-2.4174-2.4174A2.4175,2.4175,0,0,0,11.278,34.4Z' },
    { d: 'M29.0058,34.4a2.4174,2.4174,0,1,0-2.4174-2.4174A2.4176,2.4176,0,0,0,29.0058,34.4Z' },
    {
      d: 'M25.2815,7.4844l-3.5623,3.9981-1.4429,1.6233a.8151.8151,0,0,1-1.2025.03l-1.5932-1.6534-1.3077-1.3678a.8263.8263,0,0,1-.0451-1.0972.6487.6487,0,0,1,.9921-.0451l2.42,2.51.1052.1052.09-.1052,4.5543-5.11a.6581.6581,0,0,1,.992,0A.8637.8637,0,0,1,25.2815,7.4844Z'
    }
  ]
}
