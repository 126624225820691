<template>
  <label v-if="isButton" :class="btnClassList" :for="id">
    <input :id="id" type="radio" :name="name" :value="value" :disabled="disabled" :checked="isSelected" @input.stop="emitValue">
    <span>
      <slot />
    </span>
  </label>
  <div v-else :class="radioClassList" class="form-check pe-2">
    <input :id="id" type="radio" :name="name" class="form-check-input" :value="value" :disabled="disabled" :checked="isSelected" @input.stop="emitValue">
    <label class="form-check-label" :for="id">
      <span>
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
import { guid } from '@/modules/utils'

export default {
  name: 'BFormRadio',
  props: {
    value: { type: undefined },
    disabled: { type: Boolean, default: false },
    name: String
  },
  inject: ['getRadioGroup'],
  data() {
    return {
      index: -1,
      id: guid()
    }
  },
  computed: {
    btnClassList() {
      const { size, buttonVariant } = this.getRadioGroup ? this.getRadioGroup() : {}
      const classList = ['btn']
      if (size) classList.push(`btn-${size}`)
      if (buttonVariant) classList.push(`btn-${buttonVariant}`)
      if (this.isSelected) classList.push('active')
      return classList
    },
    radioClassList() {
      const { size } = this.getRadioGroup ? this.getRadioGroup() : {}
      const classList = ['custom-control', 'custom-radio', 'custom-control-inline']
      if (size) classList.push(`b-custom-control-${size}`)
      return classList
    },
    isButton() {
      const { buttons = false } = this.getRadioGroup ? this.getRadioGroup() : {}
      return buttons
    },
    isSelected() {
      const { selected = null } = this.getRadioGroup ? this.getRadioGroup() : {}
      return selected === this.value
    }
  },
  methods: {
    emitValue() {
      const group = this.getRadioGroup ? this.getRadioGroup() : {}
      if (group.emitValue) {
        group.emitValue(this.value)
      }
    }
  },
  mounted() {
    const group = this.getRadioGroup ? this.getRadioGroup() : {}
    if (group.register) {
      this.index = group.register({ id: this.id, value: this.value })
    }
  },
  beforeUnmount() {
    const group = this.getRadioGroup ? this.getRadioGroup() : {}
    if (group.unregister) {
      group.unregister({ id: this.id, value: this.value })
    }
  }
}
</script>

<style>
.custom-control-input {
  margin-right: .5rem;
}
</style>
