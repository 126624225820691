<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'ingredientsBatchAdd', action: null })" size="lg" :title="translations.txtPOSItemsImportIngredientsDirectly"
    :okText="translations.txtGenericSubmit" @ok="submit" :okDisabled="!ingredients?.length">
    <b-container class="mb-4" style="min-height: 300px;">
      <b-alert variant="info" v-if="!ingredients?.length" show>
        {{ translations.txtPOSItemsImportIngredientsInfo }}
      </b-alert>

      <itemViewMini v-for="(ingredient, index) in ingredients" :key="index" :item="ingredient.item" imgSize="tinyThumb" :editable="false" style="min-height: auto;">
        <div class="text-center">
          {{ ingredient.description }}
        </div>
        <b-button variant="link" @click="remove(index)" class="bt-delete-image position-absolute" style="top: 0; right: 0;" v-tooltip="translations.txtGenericRemove">
          <icon name="wisk-trash" class="text-danger" :scale="0.9" />
        </b-button>
      </itemViewMini>
    </b-container>
    <addIngredient @add="operations.push($event)" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import addIngredient from '@/components/common/IngredientAdd'
import itemViewMini from '@/components/bottles/ItemViewMini'

export default {
  name: 'IngredientsBatchAdd',
  components: { addIngredient, itemViewMini },
  props: { editAction: Object },
  data() {
    return {
      searchQuery: '',
      operations: [],
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'subrecipesById']),
    ingredients() {
      return this.operations.map(operation => {
        let type = operation?.value?.ingredient?.type,
          item = type === 'item' ? this.bottlesById[operation?.value?.ingredient?.value?.item_id] : this.subrecipesById[operation?.value?.ingredient?.value?.subrecipe_id],
          title = item?.title || '',
          image = item?.image || '23C5FF4D-78D6-416B-BE8E-39FC2EAE0153.png',
          servingSize = operation?.value?.serving_size,
          measurementText = `${servingSize?.measurement?.quantity?.toFixed(2)} ${servingSize?.measurement?.unit_of_measurement}`,
          description = servingSize?.title ? `${servingSize.title} (${measurementText})` : measurementText

        return { item: { title, image, titleSuffix: item.titleSuffix || '' }, description }
      })
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    remove(index) {
      this.operations.splice(index, 1)
    },
    submit() {
      this.editAction.onChange(this.operations)
    }
  }
}
</script>

<style lang="scss"></style>
