<template>
  <div class="fill-width-height checkbox-input-container" style="cursor: pointer;" :class="className">
    <div class="fill-width-height" style="display: flex;" :class="computedClass">
      <div @click.stop="onChange" @keypress.space="onChange" class="cursor-pointer checkbox-input" :title="tooltip">
        <icon class="align-self-center wisk-input-checkbox-icon" :class="[className, { 'is-checked': checked }, { 'disabled': disabled }]" :name="valueIcon" :style="{ opacity: readonly || disabled ? '0.8' : '1' }" tabindex="0" :scale="scale" />
      </div>

      <label @click.stop="onChange" :title="tooltip" :for="id" class="checkbox-input-label me-1 ms-1" v-if="label">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" class="mx-0" />
        <span class="ms-1">
          {{ label }}
        </span>

        <slot name="label-append"></slot>
      </label>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxInput',
  emits: ['input', 'blur'],
  props: {
    readonly: Boolean,
    disabled: Boolean,
    label: String,
    className: null,
    inputClass: null,
    scale: { type: Number, default: 1 },
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    valueChecked: String,
    valueUnchecked: String,
    prefix: String,
    suffix: String,
    id: String
  },
  data() {
    return {
      infoTooltipVisible: false,
      checked: false
    }
  },
  computed: {
    valueIcon() {
      if (this.checked) {
        return 'wisk-check-box'
      }
      return 'wisk-empty-box'
    },
    computedClass() {
      let label = (this.label && this.label.replace(/ /gi, '_')) || ''
      return `wisk-checkbox-input-label-${label}`
    },
    tooltip() {
      return this.label || ''
    }
  },
  methods: {
    onChange() {
      if (!this.readonly && !this.disabled) {
        this.checked = !this.checked

        if (this.valueUnchecked && this.valueChecked) {
          this.$emit('input', this.checked ? this.valueChecked : this.valueUnchecked)
          this.$emit('blur', { value: this.checked ? this.valueChecked : this.valueUnchecked })
        } else {
          this.$emit('input', this.checked)
          this.$emit('blur', { value: this.checked })
        }
      }
    },
    setValue(value) {
      this.checked = !!value
    }
  },
  mounted() {
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  watch: {}
}
</script>

<style lang="scss">
.checkbox-input-container {
  padding: 4px;

  .checkbox-input {
    :focus {
      outline: 2px solid #71b5ff;
    }

    .wisk-input-checkbox-icon {
      color: var(--bs-border-color);

      &.is-checked {
        color: var(--primary);
      }

      &:hover {
        color: var(--primary);
      }

      &.disabled {
        color: var(--bs-border-color) !important;
      }
    }
  }

  &.is-invalid {
    .checkbox-input {
      .wisk-input-checkbox-icon {
        color: var(--bs-danger);
      }
    }
  }

  .checkbox-input-label {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
