<template>
  <div style="z-index: 0; position: relative;" :class="{
    'text-danger ': previousPricePerUnit < pricePerUnit,
    'text-success': previousPricePerUnit > pricePerUnit,
  }">
    <template v-if="showCostChangeWarning">
      {{ translations.translate(pricePerUnit > previousPricePerUnit ? 'tplScannedInvoicePricePerUnitIncreased' : 'tplScannedInvoicePricePerUnitDecreased') }}
      <span class="text-bold">{{ toFixed(changePercentage, 2) }}%</span>.
      {{ translations.translate('tplScannedInvoicePreviousPricePerUnitWarning', { '{a}': currencyFormat(previousPricePerUnit) }) }}
    </template>
    <costChangeInfo :params="costChangeInfoParams" :style="{ position: 'relative !important', display: 'inline-block', marginLeft: '5px', marginRight: '5px' }" />
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { currencyFormat, toFixed } from '@/modules/utils'
import costChangeInfo from '../cells/infoHelpers/CostChangeInfo.vue'

export default {
  name: 'ItemCostChangeWarning',
  components: { costChangeInfo },
  props: {
    previousPricePerUnit: Number,
    pricePerUnit: Number,
    totalChange: Number

  },
  data() {
    return {
      currencyFormat,
      toFixed
    }
  },
  computed: {
    ...mapState(['translations', 'venue']),
    costChangeInfoParams() {
      return {
        value: {
          costChangeInfo: {
            previous_cost_per_unit: this.previousPricePerUnit,
            new_cost_per_unit: this.pricePerUnit,
            change_per_unit: this.pricePerUnit - this.previousPricePerUnit,
            change_percentage: ((this.pricePerUnit - this.previousPricePerUnit) * 100) / this.previousPricePerUnit,
            total_change: this.totalChange
          }
        },
        translate: this.translations.translate
      }
    },
    changePercentage() {
      return ((this.pricePerUnit - this.previousPricePerUnit) * 100) / this.previousPricePerUnit
    },
    showCostChangeWarning() {
      const costChangeWarningThreshold = 0.01,
        priceChange = (this.pricePerUnit - this.previousPricePerUnit) / this.previousPricePerUnit

      return Math.abs(priceChange) > costChangeWarningThreshold
    }
  }
}
</script>

<style lang="scss"></style>
