<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'familyEdit', action: null })" :size="selectedEditorTab ? 'lg' : 'md'" :title="modalTitle" :hideFooter="!!(family && family.id)">

    <template v-slot:modal-header-extra>
      <b-form-radio-group v-if="editAction.id" size="sm" buttons v-model="selectedEditorTab" button-variant="outline-primary">
        <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
        <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
      </b-form-radio-group>
    </template>

    <wiskInputGroup v-if="selectedEditorTab === 0" @errorCountChanged="setValidState" style="min-height: 500px;" @operationsChange="onChange" class="family-edit">
      <wiskInput infoTooltipKey="82f683d1-a4d3-4ff3-85de-0171b2c3dee7" :label="translations.txtGenericTitle" operation="title" :modelValue="localFamily.title" @operation="onSingleChange"
        required :triggerInputOnLoad="triggerInputOnLoad" autofocus />

      <wiskInput infoTooltipKey="4dc3d6a0-b23e-4085-87fd-3bc9fa57a496" :label="translations.txtFamiliesCategoriesTargetBeverageCost" operation="target_beverage_cost" @operation="onSingleChange"
        :modelValue="localFamily.target_beverage_cost" inputType="number" :transformerOut="z => z / 100" :transformerIn="z => z * 100" suffix="%" />

      <wiskInput infoTooltipKey="c8316391-46cf-44e2-8cff-c1432e283b43" :label="translations.txtFamiliesCategoriesTargetVariancePercentage" operation="target_variance_percent" @operation="onSingleChange"
        :modelValue="localFamily.target_variance_percent" inputType="number" :transformerOut="z => z / 100" :transformerIn="z => z * 100" suffix="%" />

      <wiskSelect infoTooltipKey="79ef2296-d5d9-4c18-aaaf-b433ab410bc5" :items="activeGLAccounts" @operation="onSingleChange" :modelValue="localFamily.gl_account_id" operation="gl_account_id" :label="translations.txtGLAccount"
        :addNewItem="addGLAccountConfig" v-if="currentPermissionsByType.gl_account_assign && venue.accounting_enabled" operationEmpty="gl_account_id_clear" fixedWrapper />

      <wiskSelect infoTooltipKey="821d7181-f929-4e1d-8428-cc4a6b1b955c" :items="activeTaxes" @operation="onSingleChange" :modelValue="localFamily.tax_rate_id" operation="tax_rate_id" :label="translations.txtTax"
        :addNewItem="addTaxConfig" operationEmpty="tax_rate_id_clear" v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" fixedWrapper />
    </wiskInputGroup>

    <timeline v-if="selectedEditorTab === 1" :filters="[{ type: 'family', id: editAction.id }]" parentGridName="FamilyEditTimeline" gridAutoHeight />

    <wiskLoading :loading="loading" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import merge from 'lodash.merge'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import wiskInput from '@/components/common/WiskInput'
import timeline from '@/components/timeline/Timeline'

export default {
  name: 'FamilyEdit',
  components: { editorButtons, wiskInput, timeline },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      triggerInputOnLoad: false,
      valid: true,
      loading: false,
      localFamily: {},
      operations: [],
      selectedEditorTab: 0
    }
  },
  computed: {
    ...mapGetters(['activeGLAccounts', 'activeTaxes']),
    ...mapState(['currentPermissionsByType', 'familiesById', 'translations', 'venue']),
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackItemInjected(glAccount)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    modalTitle() {
      if (this.family) {
        return this.family.title ? `${this.family.title} (${this.translations.txtGenericFamily})` : this.translations.txtFamiliesCategoriesNewFamily
      }
      return ''
    },
    family() {
      if (this.editAction) {
        if (this.editAction.id) {
          return this.familiesById[this.editAction.id]
        }

        return merge({}, this.editAction)
      }

      return null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setFamily']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onChange(operations) {
      this.operations = [...operations]
    },
    onSingleChange(operation) {
      if (this.family && this.family.id) {
        this.save(operation)
      }
    },
    save(operation) {
      if (this.family && (this.valid || operation)) {
        this.loading = true

        this.setFamily({ id: this.family.id, operation: operation || this.operations })
          .then(updated => {
            this.operations = []
            this.loading = false

            if (typeof this.editAction.onChange === 'function') {
              this.editAction.onChange(merge({}, updated))
            }

            if (!operation) {
              this.setGlobalAction({ type: 'familyEdit', action: null })
            }
          })
          .catch(() => {
            this.loading = false
            this.operations = []
          })
      }
    }
  },
  watch: {
    family: {
      handler() {
        this.localFamily = merge({}, this.family)

        if (this.family && !this.family.id) {
          this.triggerInputOnLoad = true
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
