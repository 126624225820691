<template>
  <div>
    <wiskModal :visible="!!(editAction && editAction.id && !editAction.successMode)" @hide="setGlobalAction({ type: 'purchaseOrderEdit', action: null })"
      extraLarge :title="detailsDialogTitle" size="lg" hideFooter id="purchase-order-edit" :warning="!!(purchaseOrder && purchaseOrder.archived)">

      <template v-slot:modal-title>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <h5 class="modal-title me-2 float-start" style=""> {{ detailsDialogTitle }} </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button variant="link" size="sm" class="py-0" @click="clone">
                  {{ translations.txtGenericClone }}
                  <icon name="wisk-clone" class="text-primary" :scale="0.6" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>

      <template v-slot:modal-header-extra>
        <gateKeeper feature="purchase_orders" class="" noMessageButton>
          <div class="float-end w-100">
            <b-form-radio-group class="float-start" buttons v-model="selectedEditorTab" button-variant="outline-primary">
              <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
              <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
            </b-form-radio-group>

            <wiskActions :title="translations.txtGenericActions" :actions="pageActions" />

            <b-button v-if="currentPermissionsByType.purchase_order_email" variant="primary" :disabled="readonly" @click="readonly ? () => {} : sendToDistributorVisible = true" class="me-2 float-end" size="sm">
              {{ translations.txtPurchaseOrdersEmailSMS }}
            </b-button>
          </div>
        </gateKeeper>
      </template>

      <gateKeeper feature="purchase_orders" class="">

        <div class="w-100 min-h-440">
          <div v-if="selectedEditorTab === 0">
            <wiskInputGroup @operationsChange="updatePurchaseOrder($event, true)">
              <b-row>
                <b-col>
                  <wiskInput infoTooltipKey="e5d59877-9588-460a-9f5e-b62f07f8006d" class="" inputClass="" :readonly="readonly"
                    :showPlainText="readonly" :modelValue="purchaseOrder.started_at" operation="date"
                    :label="translations.txtGenericDate" inputType="datepicker" />
                </b-col>
                <b-col>
                  <wiskInput infoTooltipKey="abffd720-c1ae-410f-9f69-1b8411831d87" class="" inputClass="" readonly showPlainText v-if="purchaseOrder.message_status"
                    :modelValue="translations.groupPurchaseOrdersSentStatus[purchaseOrder.message_status.status]" :label="translations.txtPurchaseOrdersEmailSentStatus" />
                </b-col>
                <b-col>
                  <wiskInput infoTooltipKey="501feeb7-c740-4f3a-b90a-a406cdc37fb1" class="" inputClass="" readonly showPlainText v-if="purchaseOrder.sms_message_status"
                    :modelValue="translations.groupPurchaseOrdersSentStatus[purchaseOrder.sms_message_status.status]" :label="translations.txtPurchaseOrdersSMSSentStatus" />
                </b-col>
                <b-col>
                  <wiskInput infoTooltipKey="9c91f32d-2f66-4b4f-a8b5-cdec3cc88325" class="" inputClass="" readonly showPlainText v-if="purchaseOrder.delivery_status"
                    :modelValue="translations[purchaseOrder.delivery_status.status]" :label="translations.txtGenericReceivedStatus"
                    :fitChildren="purchaseOrder.delivery_status.movement_id || purchaseOrder.delivery_status.status === 'received_manual_intake'">
                    <b-button v-if="purchaseOrder.delivery_status.movement_id" size="sm" class="me-2" variant="link" @click="viewMovement">
                      {{ translations.txtMovementEditViewIntake }}
                    </b-button>
                    <b-button v-if="purchaseOrder.delivery_status.status === 'received_manual_intake'" size="sm" class="me-2" variant="link" @click="clearDeliveryStatus">
                      {{ translations.txtPurchaseOrdersClearDeliveryStatus }}
                    </b-button>
                  </wiskInput>
                </b-col>
                <b-col>
                  <wiskInput infoTooltipKey="ea50781f-a7d0-452f-aa17-001c8c36d02d" class="" inputClass="" readonly showPlainText inputType="number" :decimals="2" :prefix="currency" :modelValue="total"
                    :label="translations.txtGenericTotal" />
                </b-col>
              </b-row>
            </wiskInputGroup>
            <b-row class="mb-3">
              <b-col cols="12">
                <wiskItemsGrid class="p-0" :columns="columns" ref="itemsGrid" :items="purchaseOrderItems" @gridApi="gridReady" :key="gridKey" :header="{}"
                  :gridStyle="{ height: '400px' }" :excel="{ fileName: 'PurchaseOrderItems' }" parentGridName="PurchaseOrderItems" bottomAggregationRow trackBy="item_distributor_id" />
              </b-col>
            </b-row>
            <div class="pb-4">
              <purchaseOrderAddItem :incommingItem="onlineItem" :distributor="purchaseOrder.to.distributor" @addItem="addItem" :purchaseOrderId="purchaseOrder.id"
                v-if="!readonly" id="purchase-order-add-item" :key="addItemKey" @searchQuery="searchOnlineQuery = $event" @onlineCatalogButton="showOnlineCatalogButton = $event" />

              <b-button @click="onlineCatalogVisible = true" class="me-2" variant="primary" v-if="onlineCatalogButtonAvailable && showOnlineCatalogButton" size="sm">
                {{ onlineItemSearchTitle }}
              </b-button>
            </div>

            <customFields target="purchase_order" :item="purchaseOrder" @operation="updatePurchaseOrder($event, true)" />
            <wiskLoading :loading="loading" />
          </div>
          <div v-if="selectedEditorTab === 1">
            <timeline v-if="purchaseOrder && purchaseOrder.id" :filters="timelineFilters" parentGridName="PurchaseOrderEditTimeline" />
            <wiskLoading v-else :loading="loading" />
          </div>
        </div>
      </gateKeeper>
    </wiskModal>

    <wiskModal :visible="!!(editAction && editAction.id && editAction.successMode)" @hide="setGlobalAction({ type: 'purchaseOrderEdit', action: null })"
      :title="detailsDialogTitle" size="lg" hideFooter>
      <template v-slot:modal-header-extra>
        <b-button @click="setGlobalAction({ type: 'distributorEdit', action: distributor })" class="me-2" variant="primary" v-if="distributor">
          {{ translations.txtDistributorManage }}
        </b-button>
      </template>
      <b-row class="mb-3">
        <b-col>
          {{ translations.txtPurchaseOrdersGeneratedSuccessMessage }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="me-2" variant="primary" @click="openEditMode">
            {{ translations.txtPurchaseOrdersViewOrder }}
          </b-button>
          <b-button class="me-2" variant="primary" @click="sendToDistributorVisible = true">
            {{ translations.txtPurchaseOrdersSend }}
          </b-button>
          <b-button class="me-2" variant="primary" v-if="isSAQ" @click="SAQVisible = true; orderSAQ()">
            {{ translations.txtPurchaseOrdersSendSAQ }}
          </b-button>
          <b-button class="me-2" variant="primary" @click="getSpreadsheet">
            {{ translations.txtPurchaseOrdersDownloadSpreadsheet }}
          </b-button>
        </b-col>
      </b-row>
    </wiskModal>

    <wiskModal v-model="SAQVisible" size="lg" hideFooter :title="translations.txtShoppingCartSAQOrderModalTitle">
      <saqForm :saqOrderText="saqOrderText" v-if="SAQVisible" />
    </wiskModal>

    <wiskModal v-model="sendToDistributorVisible" size="lg" :okText="translations.txtPurchaseOrdersNotesSend" preventOKClose @ok="sendToDistributor"
       :okDisabled="!sendToDistributorFormValid">

      <template v-slot:modal-header>
        <div class="text-start w-100">
          <h5>
            {{ translations.txtPurchaseOrdersNotesModalTitle }}
          </h5>
          {{ translations.txtPurchaseOrder }}: {{ purchaseOrder.id }}, &nbsp; &nbsp;
          {{ translations.txtGenericDistributor }}: <span class="text-bold"> {{ purchaseOrder?.to?.distributor?.title }} </span>

          <icon v-if="distributor?.notes" name="wisk-information-button" class="pt-1 text-primary" v-tooltip="distributor?.notes"/>

          <button type="button" @click.stop="sendToDistributorVisible = false" aria-label="Close" class="close btn-close position-absolute">
            <icon name="wisk-exit" :scale="0.64" />
          </button>
        </div>
      </template>

      <purchaseOrderSend v-model="sendToDistributorForm" :purchaseOrder="purchaseOrder" v-if="sendToDistributorVisible" :key="sendToDistributorVisible"
        @validStateChanged="$event => sendToDistributorFormValid = $event" />
      <wiskLoading v-if="sendToDistributorLoading" loading />
    </wiskModal>

    <wiskModal v-model="onlineCatalogVisible" size="lg" hideFooter :title="onlineItemSearchTitle" extraLarge>
      <onlineItemSearch v-model="onlineItem" v-if="purchaseOrder && onlineCatalogVisible" :startQuery="searchOnlineQuery"
        :distributor="purchaseOrder.to.distributor" :partnerVenue="purchaseOrder.to.partner_venue" />
    </wiskModal>

    <wiskModal :visible="editAction && !editAction.id" size="lg" :okText="translations.txtPurchaseOrdersAddOrder" @ok="addPurchaseOrder"
      :okDisabled="!addPurchaseOrderValid" :title="translations.txtPurchaseOrdersNew" @hide="setGlobalAction({ type: 'purchaseOrderEdit', action: null })">
      <b-container fluid class="">
        <gateKeeper feature="purchase_orders" class="">
          <b-row class="w-100">
            <b-col>
              <wiskInputGroup style="height: 600px;" @errorCountChanged="setAddPurchaseOrderValidState" v-if="newOrderOperation && !newOrderFromPartnerVenue" :key="1">
                <wiskSelect infoTooltipKey="5cc9625d-185c-47e0-ac24-094fd4deca4b" :multiselectOptions="{ allowEmpty: false }" :label="translations.txtPurchaseOrdersSelectDistributor" :addNewItem="addDistributorConfig"
                  @update:modelValue="newOrderOperation.value.value = $event" trackBy="id" :items="activeDistributors" required />
              </wiskInputGroup>
              <wiskInputGroup style="height: 600px;" @errorCountChanged="setAddPurchaseOrderValidState" v-if="newOrderOperation && newOrderFromPartnerVenue" :key="2">
                <wiskSelect infoTooltipKey="4115ed27-bfd4-4ce1-aa42-2543c1e63f82" :multiselectOptions="{ allowEmpty: false }" :label="translations.txtPurchaseOrdersSelectPartnerVenue"
                  @update:modelValue="newOrderOperation.value.value = $event" trackBy="id" :items="partnerVenues" required />
              </wiskInputGroup>
            </b-col>
          </b-row>
        </gateKeeper>
        <wiskLoading :loading="loading" />
      </b-container>
    </wiskModal>

    <wiskModal :visible="invoiceLinkModalVisible" size="lg" hideFooter :title="translations.txtPurchaseOrdersLinkToInvoice" @hide="invoiceLinkModalVisible = false">
      <template v-slot:modal-header-extra>
        <b-button @click="setDeliveryeStatus()" variant="secondary" class="float-end">
          {{ translations.txtPurchaseOrdersMarkReceivedNoIntake }}
        </b-button>
        <b-button v-if="currentPermissionsByType.invoice_add" @click="intakeFromOrder(); invoiceLinkModalVisible = false" variant="primary" class="float-end me-2">
          {{ translations.txtPurchaseOrdersConvertIntake }}
        </b-button>
      </template>
      <wiskGrid :rowData="distributorMovements" :gridStyle="{ height: '500px' }" :columnDefs="invoicesColumns" parentGridName="LinkInvoicesGrid" />
    </wiskModal>

    <confirm ref="confirmDialog" />
    <confirm ref="saqConfirmDialog" :okText="translations.txtPurchaseOrdersSAQOK" :cancelText="translations.txtPurchaseOrdersSAQCancel" hideHeader />

    <purchaseOrderToIntake v-if="purchaseOrder?.id && intakeFromOrderVisible" @done="intakeFromOrderVisible = false" :purchaseOrder="purchaseOrder" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import scroller from 'vue-scrollto'
import get from 'lodash.get'
import api from '@/api'
import { compareNumbers, prepareVariantIdAsItem, formatDate } from '@/modules/utils'
import saqForm from '@/components/orders/SAQForm'
import wiskModal from '@/components/common/WiskModal'
import purchaseOrderAddItem from '@/components/orders/PurchaseOrderAddItem'
import purchaseOrderToIntake from '@/components/orders/PurchaseOrderToIntake'
import timeline from '@/components/timeline/Timeline'
import purchaseOrderSend from '@/components/orders/PurchaseOrderSend'
import onlineItemSearch from '@/components/common/OnlineItemSearch'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import wiskInput from '@/components/common/WiskInput'
import wiskSelect from '@/components/common/WiskSelect'
import customFields from '@/components/customFields/CustomFieldsRender'
import { getGridColumns as getMovementsGridColumns } from '@/components/movements/gridOptions'
import getGridColumns from './editorGridOptions'

export default {
  name: 'PurchaseOrderEdit',
  emits: ['gridApi'],
  components: {
    wiskItemsGrid,
    purchaseOrderAddItem,
    onlineItemSearch,
    wiskModal,
    saqForm,
    purchaseOrderSend,
    timeline,
    purchaseOrderToIntake,
    wiskSelect,
    wiskInput,
    customFields,
  },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      newOrderFromOptions: [],
      SAQVisible: false,
      saqOrderText: '',
      loading: false,
      newOrderVisible: false,
      onlineCatalogVisible: false,
      newItem: null,
      sendToDistributorForm: { recipients: [], body: '', subject: '' },
      sendToDistributorFormValid: false,
      sendToDistributorVisible: false,
      sendToDistributorLoading: false,
      onlineItem: null,
      intakeFromOrderVisible: false,
      newOrderFromPartnerVenue: false,
      addPurchaseOrderValid: false,
      columns: {},
      purchaseOrder: { items: [], to: {}, delivery_status: {} },
      scrollerOptions: {
        container: '#purchase-order-edit',
        easing: 'ease-in',
        offset: -60
      },
      newOrderOperation: null,
      selectedEditorTab: 0,
      showOnlineCatalogButton: false,
      searchOnlineQuery: '',
      gridKey: 1,
      addItemKey: 1,
      downloadSpreadsheetLoading: false,
      invoiceLinkModalVisible: false
    }
  },
  computed: {
    ...mapGetters(['orderFormats', 'partnerVenues', 'venue', 'currency']),
    ...mapState(['translations', 'itemVariantsById', 'distributorsById', 'itemPricesById', 'currentPermissionsByType', 'user', 'orders', 'movements', 'distributors']),
    distributorMovements() {
      return this.movements.filter(m => m.operation?.type === 'intake' && this.purchaseOrder?.to?.distributor?.id && this.purchaseOrder?.to?.distributor?.id === m.operation?.distributor_id && !m.purchase_order)
    },
    invoicesColumns() {
      const columns = getMovementsGridColumns({
        translations: this.translations,
        distributorsById: this.distributorsById,
        save: () => { },
        customFields: [],
        venue: this.venue
      }).filter(col => !['sentToAccounting', 'transferOutSent', 'photos', 'more', 'operation', 'description', 'person', 'CostChanges'].includes(col.colId))

      columns.push({
        colId: 'openMore',
        pinned: 'right',
        cellClass: ['cell-more-details pe-0'],
        cellRenderer: 'CellMoreDetails',
        maxWidth: 50,
        cellRendererParams: {
          onClick: id => {
            this.setGlobalAction({ type: 'movementEdit', action: { id } })
          }
        },
        valueGetter: (params) => ({
          id: params.data.id
        })
      },
      {
        colId: 'selectButton',
        pinned: 'right',
        cellClass: ['cell-more-details ps-0'],
        cellRenderer: 'CellMoreDetails',
        maxWidth: 70,
        cellRendererParams: {
          title: this.translations.txtGenericSelect,
          buttonVariant: 'primary',
          hideIcon: true,
          onClick: id => {
            this.setDeliveryeStatus(id)
          }
        },
        valueGetter: (params) => ({
          id: params.data.id
        })
      })

      return columns
    },
    timelineFilters() {
      if (this.purchaseOrder) {
        return [{ type: 'purchase_order', id: this.purchaseOrder.id }]
      }
      return []
    },
    readonly() {
      return this.checkReadonly(this.purchaseOrder)
    },
    pageActions() {
      if (this.readonly) {
        return [
          { key: 70, title: this.translations.txtDistributorManage, hide: !this.distributor, action: this.manageDistributor },
          { key: 80, title: this.translations.txtCustomFieldAdd, action: this.openAddCustomField },
          { key: 85, title: this.translations.txtCustomFieldsView, action: this.openCustomFields }
        ]
      }

      let linkedDistributor = !!this.purchaseOrder?.to?.distributor?.link_with_venue_id,
        canArchive = !linkedDistributor || this.purchaseOrder.delivery_status.type === 'draft' || this.purchaseOrder.delivery_status.type === 'not_sent',
        canCancel = linkedDistributor && this.purchaseOrder.delivery_status.type === 'requested',
        actions = [
          { key: 50, title: this.translations.txtPurchaseOrdersDownloadSpreadsheet, action: this.getSpreadsheet, loading: this.downloadSpreadsheetLoading },
          { key: 60, title: this.translations.txtPurchaseOrdersSendSAQ, hide: !this.isSAQ, action: this.orderSAQ },
          { key: 70, title: this.translations.txtDistributorManage, hide: !this.distributor, action: this.manageDistributor },
          { key: 80, title: this.translations.txtCustomFieldAdd, action: this.openAddCustomField },
          { key: 85, title: this.translations.txtCustomFieldsView, action: this.openCustomFields },
          { key: 90, isDivider: true, hide: !canCancel && !canArchive },
          { key: 100, title: this.translations.txtGenericCancel, icon: 'wisk-delete', variant: 'danger', hide: !canCancel, action: this.cancelTransferRequest },
          { key: 110, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: !canArchive || this.purchaseOrder.archived, action: this.toggleArchived },
          { key: 120, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !canArchive || !this.purchaseOrder.archived, action: this.toggleArchived }
        ]

      if (this.currentPermissionsByType.purchase_order_receive_from) {
        actions.push({ key: 3, title: this.translations.txtPurchaseOrdersMarkReceived, action: this.openInvoiceLinkModal })
      }

      actions.unshift({ key: 4, title: this.translations.txtPurchaseOrdersMarkSent, action: this.setMessageStatus })

      actions.sort((l, r) => compareNumbers(l.key, r.key))
      return actions
    },
    activeDistributors() {
      return this.distributors.filter(d => !d.archived)
    },
    addDistributorConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
    purchaseOrderItems() {
      return [...this.purchaseOrder.items]
    },
    distributor() {
      if (this.purchaseOrder?.to) {
        if (this.purchaseOrder.to.distributor) {
          return this.distributorsById[this.purchaseOrder.to.distributor.id] || null
        }
      }
      return null
    },
    toTitle() {
      if (this.purchaseOrder?.to) {
        if (this.purchaseOrder.to.distributor) {
          return this.purchaseOrder.to.distributor.title
        }
        if (this.purchaseOrder.to.partner_venue) {
          return this.purchaseOrder.to.partner_venue.title
        }
      }

      return ''
    },
    onlineItemSearchTitle() {
      if (this.purchaseOrder?.to) {
        if (this.purchaseOrder.to.distributor) {
          return this.translations.translate('tplOnlineItemSearchDistributor', { '{a}': this.purchaseOrder.to.distributor.title })
        }
        if (this.purchaseOrder.to.partner_venue) {
          return this.translations.translate('tplOnlineItemSearchPartnerVenue', { '{a}': this.purchaseOrder.to.partner_venue.title })
        }
      }

      return this.translations.txtOnlineItemSearchCatalog
    },
    isSAQ() {
      return this.purchaseOrder?.to?.distributor?.saq
    },
    selectedOrderToPartnerVenue() {
      return !!this.purchaseOrder?.to?.partner_venue?.id
    },
    selectedOrderToDistributor() {
      return !!this.purchaseOrder?.to?.distributor?.id
    },
    onlineCatalogButtonAvailable() {
      return !!(this.selectedOrderToPartnerVenue || (this.selectedOrderToDistributor && this.purchaseOrder?.to?.distributor?.link_with_venue_id))
    },
    total() {
      const reducer = (accumulator, currentValue) => accumulator + currentValue.purchase_order_info.total
      let total = this.purchaseOrder.items.reduce(reducer, 0)
      return total
    },
    detailsDialogTitle() {
      if (this.purchaseOrder && this.purchaseOrder.id) {
        let to = this.purchaseOrder.to,
          title = to?.distributor?.title || to?.partner_venue?.title || '',
          archived = this.purchaseOrder.archived ? `(${this.translations.txtGenericArchived})` : '',
          venue = this.purchaseOrder.venue || { title: '' }

        return `Order ${this.purchaseOrder.id} ${venue.title} to ${title} ${archived}`
      }
      return ''
    },
    confirmMessages() {
      return {
        archive: {
          title: this.purchaseOrder.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle,
          message: this.purchaseOrder.archived ? this.translations.confirmRestorePurchaseOrderText : this.translations.confirmArchivePurchaseOrderText
        },
        send: {
          title: this.translations.confirmPurchaseOrderSendTitle,
          message: this.translations.confirmPurchaseOrderSendMessage
        },
        intake: {
          title: this.translations.confirmPurchaseOrderConvertIntakeTitle,
          message: this.translations.confirmPurchaseOrderConvertIntakeMessage
        },
        message_status: {
          title: this.translations.confirmPurchaseOrderMarkAsSentTitle,
          message: this.translations.confirmPurchaseOrderMarkAsSentMessage
        },
        delivery_status: {
          title: this.translations.txtPurchaseOrdersMarkReceivedNoIntake,
          message: this.translations.confirmPurchaseOrdersMarkReceivedNoIntake
        }
      }
    }
  },
  methods: {
    ...mapActions(['setPurchaseOrder', 'setGlobalAction', 'notify', 'getPurchaseOrders']),
    formatDate,
    gridReady(z) {
      this.$emit('gridApi', z)
    },
    checkReadonly(purchaseOrder) {
      return get(purchaseOrder, 'editable') === false
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'purchase_order' } })
    },
    openAddCustomField() {
      this.setGlobalAction({ type: 'customFieldEdit', action: { target: 'purchase_order' } })
    },
    manageDistributor() {
      this.setGlobalAction({ type: 'distributorEdit', action: this.distributor })
    },
    openEditMode() {
      let id = this.editAction.id

      this.setGlobalAction({ type: 'purchaseOrderEdit', action: null })
      setTimeout(() => {
        this.setGlobalAction({ type: 'purchaseOrderEdit', action: { id } })
      }, 500)
    },
    getSpreadsheet() {
      this.downloadSpreadsheetLoading = true
      api.downloadBlob('purchase-orders/spreadsheet', 'PurchaseOrders.xlsx', { purchase_order_ids: [this.purchaseOrder.id] })
        .then(() => {
          this.downloadSpreadsheetLoading = false
        })
        .catch(() => {
          this.downloadSpreadsheetLoading = false
        })
    },
    orderSAQ() {
      this.SAQVisible = true
      api.orderFromSAQ(this.purchaseOrder.id).then(result => {
        this.saqOrderText = result.join('\n')
      })

      if (this.user && !this.user.god_mode) {
        this.$intercom.trackEvent('saq_order')
      }
    },
    setAddPurchaseOrderValidState(errorCount) {
      this.addPurchaseOrderValid = !errorCount
    },
    intakeFromOrder() {
      this.intakeFromOrderVisible = true
    },
    toggleArchived() {
      if (this.$refs.confirmDialog && this.purchaseOrder) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updatePurchaseOrder(
              {
                type: 'archive',
                value: !this.purchaseOrder.archived
              },
              true
            )
          },
          message: this.purchaseOrder.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.purchaseOrder.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    cancelTransferRequest() {
      if (this.$refs.confirmDialog && this.purchaseOrder) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updatePurchaseOrder(
              {
                type: 'cancel',
                value: true
              },
              true
            )
          },
          message: this.translations.confirmPurchaseOrderCancelTransferRequestMessage,
          title: this.translations.confirmPurchaseOrderCancelTransferRequestTitle
        })
      }
    },
    openInvoiceLinkModal() {
      this.invoiceLinkModalVisible = true
    },
    setDeliveryeStatus(movementId) {
      if (movementId) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updatePurchaseOrder({ type: 'delivery_status', value: { type: 'received', movement_id: movementId } }, true)
          },
          message: this.translations.confirmPurchaseOrdersLinkToInvoice,
          title: this.translations.txtPurchaseOrdersLinkToInvoice
        })
      } else {
        this.updatePurchaseOrder({ type: 'delivery_status', value: { status: 'received_manual_intake' } })
      }
    },
    clearDeliveryStatus() {
      this.updatePurchaseOrder({ type: 'delivery_status', value: { status: 'not_received' } }, true)
    },
    setMessageStatus() {
      this.updatePurchaseOrder({ type: 'message_status', value: { status: 'sent' } })
    },
    decline() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          callback: value => {
            this.updatePurchaseOrder({ type: 'decline', value }, true)
          },
          message: this.translations.txtPurchaseOrdersDeclinePromptMessage,
          title: this.translations.txtPurchaseOrdersDecline
        })
      }
    },
    sendToDistributor() {
      this.sendToDistributorLoading = true
      this.sendToDistributorFormValid = false
      this.updatePurchaseOrder({ type: 'send', value: this.sendToDistributorForm }, true).then(() => {
        this.sendToDistributorVisible = false
      }).finally(() => {
        this.sendToDistributorLoading = false
        this.sendToDistributorFormValid = true
      })
    },
    updatePurchaseOrder(operation, skipConfirm) {
      if (skipConfirm) {
        return this.setPurchaseOrder({ id: this.purchaseOrder.id, operation }).then(() => {
          this.getPurchaseOrder()
          if (operation.type === 'send') {
            this.notify({
              message: this.translations.txtPurchaseOrdersEmailSent,
              type: 'success'
            })
          }
          this.invoiceLinkModalVisible = false
        })
      } else if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.setPurchaseOrder({ id: this.purchaseOrder.id, operation }).then(() => {
              this.getPurchaseOrder()
              this.invoiceLinkModalVisible = false
              //this.setGlobalAction({ type: 'purchaseOrderEdit', action: null })
            })
          },
          message: this.confirmMessages[operation.type].message,
          title: this.confirmMessages[operation.type].title
        })
      }
      this.sendToDistributorForm = { recipients: [], body: '', subject: '' }
    },
    viewMovement() {
      this.setGlobalAction({ type: 'movementEdit', action: { id: this.purchaseOrder.delivery_status.movement_id }, resetBeforeOpen: true })
    },
    clone() {
      this.loading = true

      api.clonePurchaseOrder(this.purchaseOrder.id).then(result => {
        this.getPurchaseOrders()
        this.setGlobalAction({ type: 'purchaseOrderEdit', action: { id: result.id } })

        this.notify({
          message: this.translations.txtPurchaseOrdersClonedMessage,
          type: 'info'
        })
        this.loading = false
      })
    },
    addItem(item) {
      this.setPurchaseOrder({ id: this.purchaseOrder.id, operation: { type: 'item_add', value: item } }).then(() => {
        this.getPurchaseOrder()
        setTimeout(() => {
          this.onlineItem = null
        }, 0)
      })
    },
    addPurchaseOrder() {
      this.loading = true
      this.setPurchaseOrder({
        id: 0,
        operation: [{ type: 'date', value: new Date().toISOString() }, this.newOrderOperation]
      })
        .then(result => {
          if (result && result.id) {
            this.getPurchaseOrder()
            this.setGlobalAction({ type: 'purchaseOrderEdit', action: { id: result.id } })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPurchaseOrder() {
      if (this.editAction?.id) {
        api.getPurchaseOrder(this.editAction.id).then(order => {
          let copy = { ...order }

          if (order?.items) {
            for (let j = 0; j < copy.items.length; j++) {
              let orderInfo = { ...copy.items[j] },
                bottle = prepareVariantIdAsItem(copy.items[j].item_distributor_id, this.$store.state) || {}

              orderInfo.order.case_size = bottle.case_size

              copy.items[j] = bottle
              copy.items[j].purchase_order_info = orderInfo
              copy.items[j].purchase_order_info.editable = !this.checkReadonly(order)
            }
          }

          this.purchaseOrder = copy
        })
      }
    }
  },
  mounted() {
    this.newOrderFromOptions = [
      { text: this.translations.txtGenericDistributor, value: false },
      { text: this.translations.txtGenericPartnerVenue, value: true }
    ]
    this.columns = getGridColumns({
      updatePurchaseOrder: this.updatePurchaseOrder,
      translations: this.translations,
      orderFormats: this.orderFormats,
      itemPricesById: this.itemPricesById
    })
  },
  watch: {
    orders() {
      if (this.editAction.id) {
        this.getPurchaseOrder()
      }
    },
    editAction: {
      deep: true,
      immediate: true,
      handler() {
        this.gridKey++
        this.addItemKey++
        this.showOnlineCatalogButton = false
        this.selectedEditorTab = 0

        if (this.editAction) {
          if (this.editAction.id) {
            this.getPurchaseOrder()
          } else {
            this.newOrderOperation = {
              type: 'to',
              value: {
                type: 'distributor_id',
                value: 0
              }
            }
            if (this.newOrderFromPartnerVenue) {
              this.newOrderOperation.value.type = 'partner_venue_id'
            }
          }
        }
      }
    },
    itemVariantsById() {
      this.getPurchaseOrder()
    },
    SAQVisible(SAQVisible) {
      if (!SAQVisible && this.$refs.saqConfirmDialog) {
        this.$refs.saqConfirmDialog.confirm({
          callback: () => {
            this.setPurchaseOrder({ id: this.purchaseOrder.id, operation: { type: 'archive', value: true } }).then(() => {
              this.getPurchaseOrder()
              this.setGlobalAction({ type: 'purchaseOrderEdit', action: null })
            })
          },
          message: this.translations.confirmPurchaseOrderSAQArchiveMessage,
          title: this.translations.confirmPurchaseOrderSAQArchiveTitle
        })
      }
    },
    onlineItem() {
      if (this.onlineItem) {
        this.onlineCatalogVisible = false
        //scroll to add item if not visible
        scroller.scrollTo('#purchase-order-add-item', 200, this.scrollerOptions)
      }
    }
  }
}
</script>
