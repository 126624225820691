<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'helpViewer', action: null })"
    extraLarge :title="modalTitle" size="lg" hideFooter>

    <iframe :src="url" style="border:none; width: 100%; height: 100%;"></iframe>

  </wiskModal>
</template>

<script>
import { /*mapState, mapGetters,*/ mapActions } from 'vuex'

export default {
  name: 'HelpViewer',
  components: {},
  props: { editAction: Object },
  data() {
    return {}
  },
  computed: {
    modalTitle() {
      return ''
    },
    url() {
      if (this.editAction) {
        return `https://help.wisk.ai/${this.editAction.id}`
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  watch: {}
}
</script>

<style lang="scss">

</style>
