export default {
  name: 'wisk-redo',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M24.019,5.746A13.776,13.776,0,0,0,5.57,10.3a13.631,13.631,0,0,0,4.728,19,17.151,17.151,0,0,0,7.814,1.82,13.63,13.63,0,0,0,9.8-4.072,1.7,1.7,0,0,1,2.41,2.409,17.031,17.031,0,0,1-12.178,5.07,20.511,20.511,0,0,1-9.479-2.238l-.023-.012-.022-.013A16.781,16.781,0,0,1,.363,14.244,17.074,17.074,0,0,1,31.52,8.373l.064-5.228a1.7,1.7,0,1,1,3.407.042l-.127,10.392a1.745,1.745,0,0,1-2.184,1.667L22.469,12.588a1.7,1.7,0,0,1,.858-3.3l5.654,1.472A13.547,13.547,0,0,0,24.019,5.746Z'
    }
  ]
}
