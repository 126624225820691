export default {
  name: 'wisk-pos-items',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.2,23.2c-0.2,0-0.3,0-0.5,0h-1.5c0-0.1,0-0.2,0-0.3c-0.1-1.2-0.3-2.4-0.8-3.5c-1.8-5.1-6.2-8.8-11.5-9.7 c-0.2,0-0.3-0.1-0.3-0.3c-0.4-1.2-1.7-1.8-2.8-1.4c-0.6,0.2-1.1,0.7-1.4,1.3c0,0.2-0.2,0.3-0.3,0.3c-5.4,0.9-9.9,4.6-11.6,9.8 C3,20.6,2.8,21.8,2.7,23c0,0-0.1,0-0.1,0c-0.6,0-1.1,0-1.7,0C0.5,23,0.1,23.3,0,23.7c0,0.2,0,0.4,0.1,0.6c0.3,0.8,0.7,1.7,1,2.5 c0.3,0.7,0.4,0.8,1.2,0.8l30.4,0.1h0.2c0.3,0,0.7-0.2,0.8-0.5c0.4-0.9,0.8-1.9,1.2-2.8c0.2-0.4,0-0.9-0.4-1.1 C34.4,23.2,34.3,23.2,34.2,23.2z M5.1,23c0.6-6.9,6.7-11.9,13.6-11.3c6,0.6,10.8,5.4,11.3,11.4L5.1,23z'
    },
    {
      d: 'M26.9,19.1c0,0.5-0.3,0.9-0.8,1.1c-0.5,0.2-1.1,0-1.4-0.5c-0.6-1-1.4-1.9-2.4-2.6c-0.5-0.3-1.1-0.6-1.7-0.9 c-0.2-0.1-0.4-0.1-0.6-0.2c-0.6-0.2-0.9-0.9-0.6-1.5c0.2-0.5,0.8-0.8,1.4-0.7c2.5,0.8,4.5,2.4,5.8,4.6 C26.8,18.7,26.8,18.9,26.9,19.1z'
    }
  ]
}
