<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'categoryEdit', action: null })" :title="modalTitle" :size="selectedEditorTab ? 'lg' : 'md'" :hideFooter="!!(category && category.id)"
    :warning="!!(category && category.archived)">

    <template v-slot:modal-header-extra>
      <div class="d-flex align-items-center justify-content-between">
        <b-form-radio-group v-if="editAction.id" size="sm" buttons v-model="selectedEditorTab" button-variant="outline-primary">
          <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
          <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
        </b-form-radio-group>
        <wiskActions :title="translations.txtGenericActions" :actions="pageActions" dropdownClass="" v-if="category && category.id" />
      </div>
    </template>

    <categoryOperations v-model="operations" v-if="selectedEditorTab === 0 && category" :category="category" @validStateChanged="$event => valid = $event" @operation="save" />

    <timeline v-if="selectedEditorTab === 1" :filters="[{ type: 'category', id: editAction.id }]" parentGridName="CategoryEditTimeline" gridAutoHeight />

    <wiskModal v-model="retryArchiveVisible" size="lg" hideHeaderExtra :title="translations.txtGenericErrors"
      :okText="translations.txtGenericRetry" @ok="toggleArchived">
      <h5 class="mb-4 text-danger">
        {{ translations.txtFamiliesCategoriesArchiveError }}
      </h5>
      <wiskItemsGrid class="category-operation-error-handler" :columns="itemsBlockingArchiveColumns"
        :items="itemsBlockingArchive" parentGridName="ItemsBlockingCategoryArchive" gridAutoHeight />
    </wiskModal>

    <wiskLoading :loading="loading" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { compareNumbers } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import categoryOperations from '@/components/families/CategoryOperations'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import timeline from '@/components/timeline/Timeline'

export default {
  name: 'CategoryEdit',
  components: { editorButtons, categoryOperations, wiskItemsGrid, timeline },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      operations: [],
      valid: false,
      retryArchiveVisible: false,
      itemsBlockingArchive: [],
      itemsBlockingArchiveColumns: { image: { hide: false }, title: { hide: false }, measurement: {}, category: { hide: false } },
      loading: false,
      selectedEditorTab: 0
    }
  },
  computed: {
    ...mapState(['translations', 'categoriesById', 'bottlesById']),
    pageActions() {
      return [
        { key: 15, title: this.translations.txtTaxes, variant: 'primary', action: this.taxes },
        { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.category.archived, action: this.toggleArchived },
        { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.category.archived, action: this.toggleArchived }
      ]
    },
    modalTitle() {
      if (this.category) {
        let archived = this.category.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.category.id ? `${this.category.title} (${this.translations.txtGenericCategory})` + archived : this.translations.txtFamiliesCategoriesNewCategory
      }
      return ''
    },
    category() {
      if (this.editAction) {
        if (this.editAction.id) {
          return this.categoriesById[this.editAction.id]
        }

        return merge({}, this.editAction)
      }

      return null
    }
  },
  methods: {
    ...mapActions(['setCategory', 'setGlobalAction']),
    toggleArchived() {
      this.save({ type: 'archive', value: !this.category.archived })
    },
    taxes() {
      this.setGlobalAction({ type: 'taxes', action: {} })
    },
    save(operations) {
      if (this.valid || operations) {
        this.loading = true

        this.setCategory({ id: this.category.id, operation: operations || this.operations })
          .then(updated => {
            this.loading = false

            if (typeof this.editAction.onChange === 'function') {
              this.editAction.onChange(merge({}, updated))
            }

            if (this.category && !this.category.id) {
              this.setGlobalAction({ type: 'categoryEdit', action: null })
            }
          })
          .catch(error => {
            let usedBy = get(error, 'data.info.used_by')
            if (usedBy) {
              this.itemsBlockingArchive = usedBy
                .map(itemId => {
                  let item = this.bottlesById[itemId]

                  return { ...item, category: this.categoriesById[item.category_id] }
                })
                .sort((a, b) => compareNumbers(a.item_id, b.item_id))

              this.retryArchiveVisible = true
            } else {
              this.operations = []
            }
            this.loading = false
          })
      }
    }
  },
  watch: {
    bottlesById() {
      if (this.retryArchiveVisible && this.itemsBlockingArchive && this.itemsBlockingArchive.length) {
        this.itemsBlockingArchive = this.itemsBlockingArchive.map(item => {
          item = this.bottlesById[item.item_id]

          return { ...item, category: this.categoriesById[item.category_id] }
        })
      }
    }
  }
}
</script>
<style lang="scss"></style>
