<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <b-container fluid v-else>
    <b-row class="cell-order-units-editor" :class="colIdClass">
      <b-col cols="12">
        <b-input-group>
          <template v-slot:prepend>
            <div class="input-group-prepend">
              <div class="input-group-text px-1 py-2">
                <small>{{ orderFormatDisplay }}</small>
              </div>
            </div>
          </template>
          <input type="text" ref="value" @keydown.left.stop="() => { }" @keydown.right.stop="() => { }" @keyup.enter="onChange" class="form-control form-control-ml" :class="{ 'disabled-info-style': disabledReason && disabled }"
            :value="inputValueDisplay" :disabled="disabled" @focus="onFocus" @blur="onChange" />

          <template v-slot:append>
            <div class="input-group-append" v-if="inputValue">
              <div class="input-group-text bt-remove-units px-2 py-2" :title="translate('txtCellOrderUnitsEditorRemoveItem')" @click="updateValue(0)">
                <icon name="wisk-exit" :scale="0.6" />
              </div>
            </div>
          </template>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button @click="openStockInfo(id)" variant="link" size="sm" class="text-danger in-grid-cell-visible-on-hover" v-if="isOverstock">
          {{ isDeadstock ? translate('txtFilterDeadStock') : translate('txtParSuggestedInfoOverStockBy') }}
          {{ formattedWiskCaseUnit }}
        </b-button>
        <b-button @click="openStockInfo(id)" variant="link" size="sm" class="text-danger in-grid-cell-visible-on-hover" v-if="isUnderstock">
          {{ translate('txtFilterUnderStock') }}
          {{ formattedWiskCaseUnit }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import isFinite from 'lodash.isfinite'
import get from 'lodash.get'
import { wiskCaseUnit } from '@/modules/utils'

export default {
  name: 'CellOrderUnitsEditor',
  emits: ['blur'],
  data() {
    return {
      editing: false
    }
  },
  computed: {
    id() {
      return get(this.params, 'value.id')
    },
    openStockInfo() {
      return this.params.openStockInfo
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    caseSize() {
      return get(this.params, 'value.case_size')
    },
    disabled() {
      return get(this.params, 'value.disabled')
    },
    disabledReason() {
      return get(this.params, 'value.disabledReason')
    },
    orderFormat() {
      return get(this.params, 'value.order_format', '')
    },
    stock() {
      return get(this.params, 'value.stock_prediction.stock', {})
    },
    isUnderstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'under'
    },
    isOverstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'over'
    },
    isDeadstock() {
      return get(this.params, 'value.stock_prediction.stock.type') === 'over' && get(this.params, 'value.stock_prediction.stock_enough_for_days.type') === 'infinite'
    },
    formattedWiskCaseUnit() {
      return wiskCaseUnit(get(this.params, 'value.stock_prediction.stock.units', 0), get(this.params, 'value.case_size', 1))
    },
    inputValue() {
      if (!get(this.params, 'value.cart.units')) {
        return 0
      }
      let units = get(this.params, 'value.cart.units', 0)
      if (this.orderFormat === 'case') {
        return Math.ceil(units / get(this.params, 'value.case_size', 1))
      }
      return units
    },
    inputValueDisplay() {
      return this.inputValue || (this.disabled && this.disabledReason) || ''
    },
    orderFormatDisplay() {
      if (this.params.group) {
        return ''
      }
      return this.inputValue === 1 ? this.orderFormat : this.orderFormat + 's'
    }
  },
  methods: {
    refresh() {
      return this.editing
    },
    onChange(event) {
      this.updateValue(event.target.value)
    },
    updateValue(value) {
      value = parseFloat(value)

      if (!isFinite(value)) {
        return
      }
      let units
      if (this.orderFormat === 'case') {
        units = value * this.caseSize
      } else {
        units = value
      }
      if (typeof this.params.onChange === 'function') {
        this.params.onChange({
          //item_id: this.id,
          item_distributor_id: get(this.params, 'value.item_distributor_id', get(this.params, 'value.item_distributor_ids', [])[0]),
          units: parseFloat(units)
        })
      }

      setTimeout(() => {
        this.$emit('blur')
        this.editing = false
      }, 0)
    },
    onFocus() {
      this.editing = true
    }
  },
  beforeUnmount() {
    if (this.$refs.value && this.$refs.value.$el && this.$refs.value.$el.blur) {
      this.$refs.value.$el.blur()
    }
  }
}
</script>

<style lang="scss">
.cell-order-units-editor {
  .disabled-info-style {
    font-size: xx-small;
    font-weight: 500;
    opacity: 0.6;
    text-align: left !important;
    padding-left: 3px;
    padding-right: 3px;
  }

  .input-group {
    .input-group-text {
      color: var(--primary);
      background-color: white;
      border-color: var(--primary);
      height: 30px;
    }

    .input-group-text.bt-remove-units {
      cursor: pointer;
      //color: transparent;
    }

    .form-control {
      border: 1px solid var(--primary) !important;
      height: 30px;
      text-align: right;
      min-width: 30px;
    }
  }

  &:hover {
    .input-group {
      .input-group-text.bt-remove-units {
        color: var(--danger);
      }
    }
  }
}
</style>
