export default {
  name: 'wisk-inventories-alt',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.053,16.586H26.718a.188.188,0,0,0,.015-.089V2.119A1.95,1.95,0,0,0,24.786.187H10.244A1.937,1.937,0,0,0,8.312,2.119V16.586H1.932A1.95,1.95,0,0,0,0,18.533V32.9a1.94,1.94,0,0,0,1.932,1.947H33.053A1.944,1.944,0,0,0,35,32.9V18.533A1.953,1.953,0,0,0,33.053,16.586Zm-5.332,2.457v3.8H23.858v-3.8ZM15.59,2.628h3.864v3.8H15.59Zm-5.436-.509c0-.045.045-.105.09-.105h3.5V7a1.272,1.272,0,0,0,1.273,1.272h5A1.271,1.271,0,0,0,21.3,7V2.014h3.49a.113.113,0,0,1,.1.105V16.5a.1.1,0,0,1-.1.089H10.244a.1.1,0,0,1-.09-.089ZM7.279,19.043h3.863v3.8H7.279Zm9.3,13.853a.1.1,0,0,1-.105.1H1.932a.1.1,0,0,1-.09-.1V18.533c0-.045.045-.105.09-.105h3.5v4.988a1.272,1.272,0,0,0,1.273,1.273h5a1.272,1.272,0,0,0,1.273-1.273V18.428h3.489a.113.113,0,0,1,.105.105Zm16.579,0a.1.1,0,0,1-.105.1H18.526a.1.1,0,0,1-.105-.1V18.533a.113.113,0,0,1,.105-.105h3.489v4.988a1.272,1.272,0,0,0,1.273,1.273h5a1.272,1.272,0,0,0,1.273-1.273V18.428h3.489a.113.113,0,0,1,.105.105Z'
    }
  ]
}
