export default {
  name: 'wisk-bars',
  width: 35,
  height: 35,
  paths: [
    { d: 'M32.6,7.2H2.4C1.1,7.3,0,6.2,0,4.9s1-2.5,2.4-2.5h0.1h30.1c1.3,0,2.5,1,2.5,2.4C35.1,6.1,34,7.2,32.6,7.2L32.6,7.2z' },
    { d: 'M32.7,19.9H2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4h30.2c1.3,0,2.4,1.1,2.4,2.4S34,19.9,32.7,19.9L32.7,19.9z' },
    { d: 'M32.6,32.6H2.4c-1.3,0-2.5-1-2.5-2.4c0-1.3,1-2.5,2.4-2.5h0.1h30.1c1.3,0,2.5,1,2.5,2.4C35.1,31.5,34,32.6,32.6,32.6 L32.6,32.6z' }
  ]
}
