export default {
  name: 'wisk-home',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.592,12.947c-2.419-1.891-4.838-3.766-7.243-5.656L20.134,2.437a4.05,4.05,0,0,0-5.232.015c-.438.333-.877.681-1.315,1.028q-6.1,4.742-12.218,9.5A3.225,3.225,0,0,0,.038,15.215a13.459,13.459,0,0,0-.03,1.572A2.557,2.557,0,0,0,2.76,19.539H3.833V30.064a3.425,3.425,0,0,0,.318,1.6,3.2,3.2,0,0,0,3.024,1.845c2.147,0,4.31-.03,6.457,0A1.541,1.541,0,0,0,15.3,31.848c-.03-2.737,0-5.474,0-8.226V23.26h4.416v.393c0,2.721.015,5.428-.015,8.135a1.559,1.559,0,0,0,1.739,1.724c1.164-.045,2.344,0,3.508,0h2.918A3.188,3.188,0,0,0,30.84,31.7a3.433,3.433,0,0,0,.333-1.6V19.524c.529,0,1.013.031,1.512,0a2.386,2.386,0,0,0,2.147-1.693A9.936,9.936,0,0,0,35,15.805,3.345,3.345,0,0,0,33.592,12.947ZM32.171,16.7a3.062,3.062,0,0,1-.363.015h-1.89a1.46,1.46,0,0,0-1.588,1.6V30.049c0,.605-.045.665-.65.665H22.554V22a1.421,1.421,0,0,0-1.044-1.5,2.126,2.126,0,0,0-.62-.075H14.1a1.471,1.471,0,0,0-1.648,1.648V30.7H12.12c-1.618.015-3.251.015-4.869.015-.484,0-.575-.106-.575-.589q0-5.9-.015-11.825a1.42,1.42,0,0,0-1.043-1.528A2.815,2.815,0,0,0,5,16.727c-.681-.015-1.361-.015-2.042-.015-.015,0-.03-.015-.09-.03,0-.379-.016-.772.015-1.15,0-.09.121-.2.2-.257Q9.883,9.946,16.7,4.645a1.276,1.276,0,0,1,1.708.06q6.169,4.809,12.34,9.648c.378.3.756.605,1.149.892a.606.606,0,0,1,.272.56C32.156,16.092,32.171,16.379,32.171,16.7Z'
    }
  ]
}
