<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemSwap', action: null })" :title="modalTitle" size="lg"
    hideHeaderExtra :okDisabled="!replacementItem" :okText="translations.txtItemSwap" preventOKClose @ok="swapItem">
    <b-container fluid class="item-swap-edit" style="min-height: 500px;">
      <b-row no-gutters>
        <b-col cols="5">
          <itemViewMini slotBelow :item="itemComputed" class="bg-danger-subtle" imgSize="tinyThumb">
            <div class="text-center">
              <small>
                {{ translations.txtItemSwapThisWillBeReplaced }}
              </small>
            </div>
          </itemViewMini>
        </b-col>
        <b-col cols="2" class="text-center pt-4">
          <icon name="wisk-chevron-right" :scale="2" :class="{ 'text-secondary': !replacementItem, 'text-primary': !!replacementItem }" />
        </b-col>
        <b-col cols="5">
          <wiskInput v-if="!replacementItem" infoTooltipKey="29f6fe99-d242-499f-a2c7-e87e0222c2c4" class="mt-2" :label="translations.txtItemSwapFindReplacement" :modelValue="replacementItem?.title || ''" operation="title"
            required autocomplete @autocompleteInput="replacementItemAutocompleteInput" @autocompleteSelected="replacementItemAutocompleteSelected" @clear="replacementItem = null"
            :autocompleteItems="autocompleteItems" :autocompleteMinChars="2" :autocompleteItemFormatter="addSuffix" autofocus
            autocompleteDisplayKey="title" :autocompleteListHeight="250" />

          <itemViewMini v-if="replacementItem" slotBelow :item="replacementItem" class="bg-success-subtle position-relative" imgSize="tinyThumb">
            <div class="text-center ">
              <small>
                {{ translations.txtItemSwapThisWillBeReplacement }}
              </small>
              <b-button variant="link" size="sm" @click="replacementItem = null" style="position: absolute; top: -45px; right: -5px;" v-tooltip="translations.txtItemSwapClearTooltip">
                <icon name="wisk-delete" class="text-danger" :scale="1" />
              </b-button>
            </div>
          </itemViewMini>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <itemUsageGrid :item="itemComputed" selectable @selectedRowsChanged="selectedRowsChanged"/>
        </b-col>
      </b-row>
    </b-container>
    <confirm ref="confirmDialog" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/api'
import { objectFilter } from '@/modules/utils'
import itemViewMini from '@/components/bottles/ItemViewMini'
import itemUsageGrid from '@/components/bottles/ItemUsageGrid'

export default {
  name: 'ItemSwap',
  components: { itemViewMini, itemUsageGrid },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      autocompleteItems: [],
      replacementItem: null,
      selectedRows: []
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'bottlesById']),
    modalTitle() {
      if (this.itemComputed) {
        return this.translations.translate('tplItemSwapTitle', { '{a}': this.itemComputed.title + this.itemComputed.titleSuffix })
      }

      return ''
    },
    itemComputed() {
      if (this.editAction?.itemId) {
        return this.bottlesById[this.editAction.itemId]
      }
      if (this.editAction?.item_id) {
        return this.bottlesById[this.editAction.item_id]
      }

      return null
    },
    apiPayload() {
      return {
        from_item_id: this.itemComputed.item_id,
        to_item_id: this.replacementItem.item_id,
        targets: {
          pos_item_ids: this.selectedRows.filter(r => r.type === 'pos_item').map(r => r.id),
          subrecipe_ids: this.selectedRows.filter(r => r.type === 'subrecipe').map(r => r.id)
        }
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    replacementItemAutocompleteInput(value) {
      this.autocompleteItems = this.bottles.filter(b => b && b.item_id !== this.itemComputed.item_id && !b.archived && this.itemComputed.measurement.type === b.measurement.type && objectFilter({ payload: b, query: value }))
    },
    replacementItemAutocompleteSelected(replacementItem) {
      if (replacementItem) {
        this.autocompleteItems = []
        this.replacementItem = replacementItem
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    selectedRowsChanged(selectedRows) {
      this.selectedRows = selectedRows
    },
    swapItem() {
      if (this.$refs.confirmDialog && this.apiPayload?.to_item_id) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.swapItem(this.apiPayload).then(() => {
              this.setGlobalAction({ type: 'itemSwap', action: null })
              this.notify({ type: 'success', message: this.translations.txtItemSwapSuccessNotify })
            })
          },
          message: this.translations.translate('tplItemSwapConfirmMessage', { '{a}': this.itemComputed.title + this.itemComputed.titleSuffix, '{b}': this.replacementItem.title + this.replacementItem.titleSuffix })
        })
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
