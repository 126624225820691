export default {
  name: 'wisk-arrow-right',
  width: 35,
  height: 35,
  paths: [
    { d: 'M34.4,19c0.2-0.2,0.3-0.4,0.5-0.7C34.7,18.6,34.6,18.8,34.4,19z' },
    {
      d: 'M35,17.5c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.7v0L19.5,33.7c-0.4,0.4-1,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6 c-0.8-0.8-0.8-2.2,0-3l11.2-11.1H2.1c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h25.6L16.5,4.3c-0.8-0.8-0.8-2.2,0-3 c0.8-0.8,2.2-0.9,3,0L34.4,16v0c0.2,0.2,0.4,0.4,0.5,0.7C34.9,16.9,35,17.2,35,17.5z'
    },
    { d: 'M34.4,19c0.2-0.2,0.4-0.4,0.5-0.7C34.7,18.6,34.6,18.8,34.4,19z' },
    { d: 'M34.8,16.7c-0.1-0.3-0.3-0.5-0.5-0.7C34.6,16.2,34.7,16.4,34.8,16.7z' }
  ]
}
