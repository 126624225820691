<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'families', action: null })"
    extraLarge size="lg" hideOK hideFooter :title="translations.txtGenericFamiliesAndCategories">
    <familiesGrid gridAutoHeight />
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import familiesGrid from '@/components/families/FamiliesGrid'

export default {
  name: 'FamiliesEdit',
  components: { familiesGrid },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  watch: {}
}
</script>

<style lang="scss">

</style>
