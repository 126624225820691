<template>
  <b-card class="mb-2 wisk-grid-filter" no-body ref="filterElement">

    <b-button @click="$emit('remove', filter.id)" class="m-0 p-0 wisk-grid-filter-remove" style="" size="sm" variant="link"
      type="link" v-tooltip="translations.txtGenericRemove" v-if="!filter.disabled">
      <icon class="text-danger" name="wisk-delete" :scale="0.8" style="background: white; border-radius: 50%; border: none;" />
    </b-button>

    <b-card-body class="py-3 px-2">
      <b-row style="">
        <b-col cols="12" class="">
          <wiskSelect size="sm" :disabled="filter.disabled" :items="columnsComputed" :modelValue="selectedColumn" @change="selectColumn" trackBy="colId" :label="translations.txtGenericField" />
        </b-col>
        <b-col class="">
          <wiskSelect size="sm" :disabled="filter.disabled" :items="filterOperations" :modelValue="selectedFilterOperation" @change="selectOperation" :label="translations.txtFilterBy"
            :emptyListMessage="translations.txtWiskGridFilterEmptyOperationMessage" />
        </b-col>
      </b-row>
      <b-row class="mt-0">
        <!-- {{ inputType }}   -->
        <b-col>
          <genericInput componentClass="mb-0" :disabled="filter.disabled" v-model="filterValue" :inputType="inputType" triggerInputOnLoad :key="inputType" size="sm" @change="onDateRangeValueChange"
            :multiselectOptions="multiselectOptions" :decimals="2" :multiple="inputType === 'wiskSelect'" :items="listItems" :allowAddNewItem="false" initialShortcut="currentYear"
            :trackBy="trackBy" v-show="selectedFilterOperation && !selectedFilterOperation.hideInput" decimalsAsNeeded showZero :min="numberRangeMin" :max="numberRangeMax"
            :label="translations.txtWiskGridFilterOperationValue" v-if="filterVisible" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import { checkElementIsVisibleAfterScroll } from '@/modules/utils'
import { getNumber } from '@/components/grids/options/filters'

export default {
  name: 'WiskGridFilter',
  emits: ['change', 'remove', 'mounted'],
  components: {},
  props: { filter: Object, columns: Array, getValuesForColId: Function },
  data() {
    return {
      selectedFilterOperation: null,
      selectedColumn: null,
      filterValue: null,
      unMounted: false,
      debounceId: null,
      inputType: 'text',
      filterOperations: [],
      multiselectOptions: {},
      listItems: [],
      trackBy: null,
      mounted: false,
      numberRangeMin: null,
      numberRangeMax: null,
      filterVisible: false,
      observerCleanup: null,
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    operationTypes() {
      return {
        text: [
          { id: 'contains', title: this.translations.txtWiskFilterContains },
          { id: 'notContains', title: this.translations.txtWiskFilterNotContains },
          { id: 'isEmpty', title: this.translations.txtWiskFilterIsEmpty, hideInput: true },
          { id: 'isNotEmpty', title: this.translations.txtWiskFilterIsNotEmpty, hideInput: true }
        ],
        number: [
          { id: 'hasValue', title: this.translations.txtWiskFilterHasValue, hideInput: true },
          { id: 'notSet', title: this.translations.txtWiskFilterNotSet, hideInput: true },
          { id: 'equals', title: this.translations.txtWiskFilterEquals },
          { id: 'notEqual', title: this.translations.txtWiskFilterNotEquals },
          { id: 'lessThan', title: this.translations.txtWiskFilterLessThan },
          { id: 'lessThanOrEqual', title: this.translations.txtWiskFilterLessThanOrEqual },
          { id: 'greaterThan', title: this.translations.txtWiskFilterGreaterThan },
          { id: 'greaterThanOrEqual', title: this.translations.txtWiskFilterGreaterThanOrEqual },
          { id: 'inRange', title: this.translations.txtWiskFilterInRange },
        ],
        date: [
          { id: 'range', title: this.translations.txtGenericDateRange },
          { id: 'equals', title: this.translations.txtWiskFilterEqualsDate },
          { id: 'notEqual', title: this.translations.txtWiskFilterNotEqualsDate },
          { id: 'lessThan', title: this.translations.txtWiskFilterLessThanDate },
          { id: 'greaterThan', title: this.translations.txtWiskFilterGreaterThanDate },
        ],
        bool: [{ id: 'equals', title: this.translations.txtWiskFilterEquals }],
        list: [
          { id: 'inList', title: this.translations.txtWiskFilterIsOneOf },
          { id: 'notInList', title: this.translations.txtWiskFilterIsNotOneOf },
          { id: 'isEmpty', title: this.translations.txtWiskFilterIsEmpty, hideInput: true },
          { id: 'isNotEmpty', title: this.translations.txtWiskFilterIsNotEmpty, hideInput: true }
        ]
      }
    },
    columnsComputed() {
      if (this.columns?.length) {
        return this.columns.filter(c => c.dataType && c.colId).map(c => ({ ...c }))
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    selectColumn(column) {
      this.selectedColumn = column
      this.emitChange()
    },
    selectOperation(operation) {
      this.selectedFilterOperation = { ...operation }
      this.emitChange()
    },
    onDateRangeValueChange(value) {
      if (this.selectedFilterOperation?.id === 'range') {
        this.filterValue = value

        this.emitChange()
      }
    },
    emitChange() {
      if (this.selectedColumn?.dataType && this.selectedFilterOperation?.id && (this.selectedFilterOperation?.id !== 'range' || Array.isArray(this.filterValue?.range))) {
        let copy = cloneDeep(this.filter)

        copy.colId = this.selectedColumn.colId
        copy.operation = this.selectedFilterOperation.id
        copy.dataType = this.selectedColumn.dataType
        copy.filterValue = cloneDeep(this.filterValue)
        this.$emit('change', copy)
      }
    },
    //called from outside!!!
    applyFilter(filter) {
      if (filter && !this.unMounted) {
        if (filter.colId && (!this.selectedColumn || this.selectedColumn.colId !== filter.colId)) {
          //TODO: not all columns are filterable, we need to go over those mising and see if they should be added, for example WiskId column

          this.selectedColumn = this.columnsComputed.find(c => c.colId === filter.colId)
        }

        setTimeout(() => {
          if (this.filterOperations) {
            this.selectedFilterOperation = this.filterOperations.find(o => o.id === filter.operation) || {}
          }
          this.filterValue = filter.filterValue
        }, 100)
      }
    },
    initFilterComponents() {
      let selectedColumn = this.selectedColumn

      if (selectedColumn) {
        this.filterValue = null
        this.trackBy = null
        this.selectedFilterOperation = null
        this.multiselectOptions = {}
        this.listItems = []
        this.filterOperations = []

        if (selectedColumn?.dataType && this.operationTypes[selectedColumn.dataType] && this.operationTypes[selectedColumn.dataType][0]) {
          this.filterOperations = [...this.operationTypes[selectedColumn.dataType]]
          this.selectedFilterOperation = { ...this.operationTypes[selectedColumn.dataType][0] }
        }
        this.inputType = 'text'

        if (this.selectedColumn) {
          this.inputType = this.selectedColumn.dataType || 'text'

          if (this.selectedColumn.dataType === 'bool') {
            this.filterValue = false
            this.inputType = 'checkbox'
          }

          if (this.selectedColumn.dataType === 'number') {
            this.filterValue = 0
          }

          if (this.selectedColumn.dataType === 'list') {
            this.filterValue = []
            this.inputType = 'wiskSelect'
            if (this.selectedColumn.colDef) {
              let params = this.selectedColumn.colDef.cellRendererParams || this.selectedColumn.colDef.cellEditorParams

              if (params) {
                this.trackBy = params.trackBy

                if (params.getFilterItems) {
                  this.listItems = params.getFilterItems()
                } else if (params.getItems) {
                  this.listItems = params.getItems()
                } else if (params.items) {
                  this.listItems = params.items
                } else {
                  this.filterOperations = this.filterOperations.filter(o => !o.hideIfNoItems)
                  this.selectedFilterOperation = this.filterOperations[0]
                }
                this.multiselectOptions = params.multiselectOptionsFilter || params.multiselectOptions || {}
              }
            }
          }

          if (this.selectedColumn.dataType === 'date' && this.selectedFilterOperation?.id !== 'range') {
            this.inputType = 'datepicker'
          }
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      const target = this.$refs.filterElement?.$el,
        id = 1

      if (target) {
        this.observerCleanup = checkElementIsVisibleAfterScroll(target, id,
          (id, visible) => {
            this.filterVisible = visible
          }
        )
      }
    }, 0)
  },
  beforeUnmount() {
    this.unMounted = true
    clearTimeout(this.debounceId)
    this.observerCleanup && this.observerCleanup()
  },
  watch: {
    filterVisible(filterVisible) {
      console.log('filterVisible', filterVisible)
    },
    filterValue: {
      handler() {
        if (this.mounted) {
          console.log('this.filterValue', this.filterValue)
          clearTimeout(this.debounceId)
          this.debounceId = setTimeout(() => {
            if (this.filterValue !== null) {
              this.emitChange()
            }
          }, 500)
        }
      }
    },
    columns: {
      immediate: true,
      handler(columns) {
        if (columns?.length) {
          setTimeout(() => {
            this.mounted = true
            this.$emit('mounted', this.filter.id)
          }, 0)
        }
      }
    },
    selectedColumn: 'initFilterComponents',
    selectedFilterOperation(selectedFilterOperation, previous) {
      console.log('selectedFilterOperation, previous', selectedFilterOperation, previous)
      if (selectedFilterOperation?.id === 'range') {
        this.inputType = 'dateRange'
      } else if (this.selectedColumn?.dataType === 'date' && selectedFilterOperation?.id !== 'range') {
        this.inputType = 'datepicker'
      } else if (previous?.id === 'range' && selectedFilterOperation?.id !== 'range') {
        this.initFilterComponents()
      } else if (selectedFilterOperation?.id === 'inRange') {
        let values = this.getValuesForColId(this.selectedColumn.colId).map(c => getNumber(c)),
          min = Math.min(...values) || 0,
          max = Math.max(...values) || 1000000

        this.numberRangeMin = min
        this.numberRangeMax = max
        this.filterValue = this.filter.filterValue || [min, max]
        this.inputType = 'numberRange'
        this.key++
      } else if (previous?.id === 'inRange' && selectedFilterOperation?.id !== 'inRange') {
        this.initFilterComponents()
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-grid-filter {
  position: relative;
  background-color: #ebebeb;
  border-radius: 3.7785px;
  box-shadow: none;
  border: none;

  .wisk-grid-filter-remove {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
    background-color: transparent !important;
  }

  .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }

  .date-range-selector.has-shortcuts .custom-date-range-formatted {
    font-size: 75%;
  }

  .date-range-selector.has-shortcuts.custom-range .custom-date-range-formatted {
    font-size: 66%;
  }

  .btn.custom-range-edit {
    width: 20px !important;
    height: 20px !important;

  }
}
</style>
