<template>
  <component :is="editAction ? 'wiskModal' : 'b-container'" fluid :visible="!!editAction" @hide="setGlobalAction({ type: 'glAccounts', action: null })" size="lg" :title="translations.txtGLAccounts" hideFooter>
    <wiskGrid :rowData="rowData" :columnDefs="columns" resetRows parentGridName="GLAccounts" :gridStyle="{ height: '600px' }"
      :excel="{ fileName: 'GLAccounts' }" :header="{ hideSearch: true }" v-bind="$attrs">
      <template v-slot:additional-header-controls>
        <wiskInput infoTooltipKey="76d8aa81-2a84-48b5-a80d-4d6676c9e90c" :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" />
        <b-button size="sm" @click="addGLAccount" variant="primary" v-if="currentPermissionsByType.gl_account_manage">
          {{ translations.txtGLAccountNew }}
        </b-button>
        <b-button size="sm" @click="applyGLAccounts" variant="outline-primary" v-tooltip="'Button visible only for Wisk Admin'" v-if="user && user.god_mode">
          {{ translations.txtGLAccountsApply }}
        </b-button>
      </template>

    </wiskGrid>

    <confirm ref="confirmApplyGLAccounts" />
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/api'

export default {
  name: 'GLAccounts',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      showArchived: false
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'glAccounts', 'currentPermissionsByType']),
    rowData() {
      if (this.showArchived) {
        return this.glAccounts
      }
      return this.glAccounts.filter(a => a && !a.archived)
    },
    columns() {
      return [
        { headerName: this.translations.txtGLAccountNumber, sortOrder: 1000, colId: 'accountNumber', field: 'account_number' },
        { headerName: this.translations.txtGLAccountName, sortOrder: 2000, colId: 'accountName', field: 'account_name' },
        {
          colId: 'more',
          sortOrder: 2700,
          headerName: this.translations.columnDetails,
          minWidth: 100,
          width: 100,
          remove: !this.currentPermissionsByType.gl_account_manage,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true,
            onClick: id => {
              this.setGlobalAction({ type: 'glAccountEdit', action: { id } })
            }
          },
          valueGetter: params => ({
            id: (params.data && params.data.id) || 0,
            group: !!params.node.group
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    addGLAccount() {
      this.setGlobalAction({ type: 'glAccountEdit', action: { id: 0 } })
    },
    applyGLAccounts() {
      this.$refs.confirmApplyGLAccounts.confirm({
        callback: () => {
          api.applyGLAccount().then(() => {
            this.notify({
              message: this.translations.txtGLAccountsAppliedMessage,
              type: 'success'
            })
          })
        },
        message: this.translations.confirmApplyGLAccounts,
        title: this.translations.txtGLAccountsApply
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
