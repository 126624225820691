<template>
  <numberBase :params="params" :key="key" :suffix="suffix" @editing="editing = $event">
    <component v-if="infoComponentType && canShowInfoComponent && !editing" :is="infoComponentType" :params="params" />
    <component v-if="secondaryInfoComponentType && (canShowInfoComponent || secondaryInfoComponentType === 'extraText') && !editing" :is="secondaryInfoComponentType" :params="params" />
  </numberBase>
</template>

<script>
import numberBase from './CellNumberBase'
import stock from './infoHelpers/StockInfo'
import inventoryDetails from './infoHelpers/InventoryDetailsInfo'
import pendingPurchase from './infoHelpers/PendingPurchaseInfo'
import parSuggested from './infoHelpers/ParSuggestedInfo'
import extraText from './infoHelpers/ExtraText'
import orderButton from './infoHelpers/OrderButton'

export default {
  name: 'CellUnits',
  components: { numberBase, stock, pendingPurchase, parSuggested, orderButton, extraText, inventoryDetails },
  data() {
    return {
      key: '',
      canShowInfoComponent: false,
      editing: false
    }
  },
  computed: {
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    value() {
      return this.params?.value?.input_value
    },
    orderFormat() {
      return this.params?.value?.order_format
    },
    caseSize() {
      return this.params?.value?.case_size
    },
    suffix() {
      let suffix = this.params?.value?.suffix || this.params?.suffix

      if (suffix) {
        suffix = ' ' + suffix
      }

      return suffix
    },
    infoComponentType() {
      return this.params.infoComponentType
    },
    secondaryInfoComponentType() {
      return this.params.secondaryInfoComponentType
    },
    forceInfoComponent() {
      return !!this.params.forceInfoComponent
    }
  },
  methods: {
    refresh() {
      return false
    }
  },
  mounted() {
    if (this.forceInfoComponent || (window.CobrowseIO && window.CobrowseIO.currentSession)) {
      this.canShowInfoComponent = true
    } else if (this.$el) {
      setTimeout(() => {
        this.$el.addEventListener('mouseenter', () => {
          this.canShowInfoComponent = true
        })
      }, 500)
    }
  }
}
</script>
