<template>
  <div class="percentage-bar mt-2">
    <div class="percentage-bar-fill" :style="`width: ${value}; background-color: ${color};`"></div>
    <span class="percentage-text">{{ value }}</span>
  </div>
</template>

<script>
import { percentageFormat } from '@/modules/utils.js'

export default {
  name: 'CellCostPercentage',
  computed: {
    color() {
      const hue = ((100 - (this.params.value * 100)) * 120 / 100).toFixed(0)
      return `hsl(${hue}, 80%, 50%)`
    },
    value() {
      return percentageFormat(this.params.value)
    }
  }
}
</script>

<style scoped lang="scss">
.percentage-bar {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #d7d7d7;
  border-radius: 4px;
  overflow: hidden;
}
.percentage-bar-fill {
  height: 100%;
  transition: width 0.5s ease-out;
}
.percentage-text {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-weight: bold;
}
</style>
