export default {
  name: 'wisk-search',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.846,32.248a1.684,1.684,0,0,0-.447-.694l-8.773-8.789a13.934,13.934,0,0,0,2.96-8.634A13.686,13.686,0,0,0,27.46,8.58a14.584,14.584,0,0,0-3.083-4.548A14.392,14.392,0,0,0,19.828.948a13.921,13.921,0,0,0-5.535-1.11A14.055,14.055,0,0,0,8.742.948,14.558,14.558,0,0,0,4.194,4.032,14.21,14.21,0,0,0,1.11,8.58,13.946,13.946,0,0,0,0,14.131a13.921,13.921,0,0,0,1.11,5.535,14.392,14.392,0,0,0,3.084,4.549A14.572,14.572,0,0,0,8.742,27.3a13.682,13.682,0,0,0,5.551,1.126A13.661,13.661,0,0,0,19.828,27.3a13.859,13.859,0,0,0,2.76-1.572l8.819,8.835a2.066,2.066,0,0,0,1.527.6A1.943,1.943,0,0,0,34,34.869a2.125,2.125,0,0,0,.724-.756A2.064,2.064,0,0,0,35,33.05,2.4,2.4,0,0,0,34.846,32.248ZM24.608,18.464a11.45,11.45,0,0,1-5.982,6,11.119,11.119,0,0,1-8.666,0,11.365,11.365,0,0,1-6.013-6,11.309,11.309,0,0,1,0-8.666A11.385,11.385,0,0,1,6.368,6.221,11.6,11.6,0,0,1,9.96,3.816a10.489,10.489,0,0,1,4.333-.894,10.593,10.593,0,0,1,4.333.894A11.353,11.353,0,0,1,24.608,9.8a10.947,10.947,0,0,1,0,8.666Z'
    }
  ]
}
