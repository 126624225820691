import isFinite from 'lodash.isfinite'
import { compareNumbers, stringFilter } from '@/modules/utils'
import { agCustomCellFilterFormatter } from '@/components/grids/options/wiskGrid'

const getNumber = cellValue => {
  if (isFinite(cellValue)) {
    return cellValue
  }
  if (cellValue && isFinite(cellValue.input_value)) {
    return cellValue.input_value
  }
  return 0
}

export default {
  date: {
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (compareDate, cellValue) => compareNumbers((cellValue && cellValue.input_value && new Date(cellValue.input_value)) || (cellValue && new Date(cellValue)) || 0, compareDate)
    }
  },
  number: {
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'equals',
          displayName: 'Equals',
          test: (filterValue, cellValue) => getNumber(cellValue) === filterValue
        },
        {
          displayKey: 'notEqual',
          displayName: 'Not Equals',
          test: (filterValue, cellValue) => getNumber(cellValue) !== filterValue
        },
        {
          displayKey: 'lessThan',
          displayName: 'Less Than',
          test: (filterValue, cellValue) => getNumber(cellValue) < filterValue
        },
        {
          displayKey: 'lessThanOrEqual',
          displayName: 'Less Than or Equal',
          test: (filterValue, cellValue) => getNumber(cellValue) <= filterValue
        },
        {
          displayKey: 'greaterThan',
          displayName: 'Greater Than',
          test: (filterValue, cellValue) => getNumber(cellValue) > filterValue
        },
        {
          displayKey: 'greaterThanOrEqual',
          displayName: 'Greater Than or Equal',
          test: (filterValue, cellValue) => getNumber(cellValue) >= filterValue
        }
        /// inRange will not work with current ag grid api
      ]
    }
  },
  text: {
    filter: 'agTextColumnFilter',
    filterParams: {
      textFormatter: agCustomCellFilterFormatter,
      textCustomComparator: stringFilter
    }
  }
}
