<template>
  <b-button v-tooltip="translations.txtGenericCopyClipboard" @click="copy" :disabled="disabled || !copyText || copied">
    <slot :copied="copied">
      <div class="d-inline-flex align-items-center">
        <span v-if="label" class="me-1">{{ label }}</span>
        <div class="b-copy-btn">
          <transition name="fade">
            <icon v-if="copied" class="copy-btn-icon text-success" name="wisk-check" :scale="iconScale" />
            <icon v-else-if="error" class="copy-btn-icon text-danger" name="wisk-warning" :scale="iconScale" />
            <icon v-else class="copy-btn-icon" name="wisk-copy" :scale="iconScale" />
          </transition>
        </div>
      </div>
    </slot>
  </b-button>
</template>

<script>
import { mapState } from 'vuex'
import { toClipboard } from '@soerenmartius/vue3-clipboard'

export default {
  name: 'BCopyBtn',
  emits: ['error', 'success'],
  props: {
    copyText: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    iconScale: { type: Number, default: 1 },
    timeout: { type: Number, default: 2000 },
    label: { type: String, default: '' }
  },
  data() {
    return {
      copied: false,
      error: false,
      timer: null
    }
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    copy() {
      toClipboard(this.copyText)
        .then(() => {
          this.$emit('success')
          this.error = false
          this.copied = true
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.copied = false
          }, this.timeout)
        },
          () => {
            this.$emit('error')
            this.copied = false
            this.error = true
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
              this.error = false
            }, this.timeout)
          })
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  }
}
</script>
<style lang="css">
.b-copy-btn {
  position: relative;
  min-width: 16px;
  min-height: 6px;
}

.copy-btn-icon {
  position: absolute;
  margin: auto;
  inset: 0;
}
</style>
