import NProgress from 'nprogress'
const debug = 0

const calculatePercentage = (loaded, total) => Math.floor(loaded * 1.0) / total

const useProgressBar = config => location.pathname !== '/public/dashboard/printable' && (config.progressBar === undefined || config.progressBar)

export function loadProgressBar(options, axiosInstance) {
  let requestsCounter = 0

  const update = e => {
    if (debug) {
      console.log(`Axiox progress bar updated: ${calculatePercentage(e.loaded, e.total)}`, e.loaded, e.total)
    }
    NProgress.inc(calculatePercentage(e.loaded, e.total))
  }

  const setupStartProgress = () => {
    axiosInstance.interceptors.request.use(config => {
      if (useProgressBar(config)) {
        config.onDownloadProgress = update
        config.onUploadProgress = update
        requestsCounter++
        NProgress.start()
        if (debug) {
          console.log('Axiox progress bar started due to request.')
        }
      }
      return config
    })
  }

  const setupStopProgress = () => {
    const responseFunc = response => {
      if (useProgressBar(response.config)) {
        if (--requestsCounter === 0) {
          NProgress.inc(0.3 + 0.5 * Math.random()).set(1)
          if (debug) {
            console.log('Axiox progress bar stopped due to all requests being completed.')
          }
        }
      }
      return response
    }

    const errorFunc = error => {
      if (useProgressBar(error.config)) {
        if (--requestsCounter === 0) {
          NProgress.done()
          if (debug) {
            console.log('Axiox progress bar stopped due to an error.')
          }
        }
      }
      return Promise.reject(error)
    }

    axiosInstance.interceptors.response.use(responseFunc, errorFunc)
  }

  if (debug) {
    console.log('Axiox progress bar NProgress configuration:', options)
  }
  NProgress.configure(options)
  setupStartProgress()
  setupStopProgress()
}
