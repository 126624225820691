<template>
  <wiskGrid :gridStyle="gridStyle" :rowData="glAccountGridRowData" :columnDefs="glAccountGridColumns" :header="{}"
    :gridOptions="{ getRowHeight: () => 60 }" parentGridName="InvoiceGLAccountsGrid" trackBy="gridId" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { guid } from '@/modules/utils'
import { getInvoiceGLAccountsGridColumns } from '@/components/movements/gridOptions'

export default {
  name: 'InvoiceGLAccountsGrid',
  components: {},
  props: {
    movement: Object,
    lines: { type: Array, default: () => [] },
    extraLines: { type: Array, default: () => [] },
    gridStyle: { type: Object, default: () => ({ height: 'calc(100vh - 390px)' }) }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'glAccountsById', 'movementExtraLineReasonsById']),
    glAccountGridColumns() {
      return getInvoiceGLAccountsGridColumns({ translations: this.translations, glAccountsById: this.glAccountsById })
    },
    glAccountGridRowData() {
      let rowData = []

      if (this.lines?.length || this.extraLines?.length) {
        rowData = [
          ...this.lines.map(i => ({
            ...i,
            scan_invoice_line_type: 'line_item',
            gridId: guid()
          })),
          ...this.extraLines.map(e => ({
            ...e,
            price: { type: 'unit', value: e.price },
            measurement: { value: e.quantity, quantity: e.quantity, type: 'unit' },
            scan_invoice_line_type: e.type,
            gridId: guid()
          }))
        ]
      } else if (this.movement) {
        if (this.movement.items) {
          this.movement.items.forEach(line => {
            let item = this.bottlesById[line.item_id] || {},
              row = {
                ...line,
                title: item.title,
                measurement: line.measurements[0],
                scan_invoice_line_type: 'line_item',
                gridId: guid()
              }
            rowData.push(row)
          })
        }
        if (this.movement.extra_lines) {
          this.movement.extra_lines.forEach(line => {
            if (line && line.reason_id) {
              let reason = this.movementExtraLineReasonsById[line.reason_id]
              if (reason) {
                let row = {
                  ...reason,
                  ...line,
                  price: { type: 'unit', value: line.price },
                  measurement: { value: line.quantity, quantity: line.quantity, type: 'unit' },
                  total: line.quantity * line.price,
                  scan_invoice_line_type: reason.type,
                  gridId: guid()
                }
                rowData.push(row)
              }
            }
          })
        }
      }
      return rowData
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  }
}
</script>

<style lang="scss"></style>
