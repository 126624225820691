export default {
  name: 'wisk-target',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 16.73,1.01 C 15.17,0.94 13.58,1.10 11.98,1.52 7.29,2.78 3.30,6.58 1.72,11.29 c -0.98,2.94 -0.96,6.53 0.06,9.57 1.78,5.29 6.80,9.36 12.32,10.00 1.09,0.12 2.79,0.14 3.25,0.03 l 0.32,-0.08 -0.51,-1.78 -0.51,-1.78 h -0.76 c -1.81,-0.00 -4.00,-0.59 -5.65,-1.52 -1.32,-0.74 -3.27,-2.69 -4.01,-4.00 -2.02,-3.59 -2.02,-7.87 0,-11.47 0.73,-1.31 2.68,-3.26 4.01,-4.00 2.32,-1.31 5.05,-1.79 7.62,-1.33 2.61,0.45 4.65,1.55 6.38,3.41 1.84,1.98 2.77,4.24 2.94,7.11 l 0.07,1.26 1.76,0.50 c 0.97,0.28 1.79,0.48 1.83,0.44 0.15,-0.14 0.02,-3.43 -0.16,-4.43 C 29.34,6.19 23.45,1.36 16.73,1.01 Z M 16.02,7.53 c -2.15,-0.02 -4.25,0.79 -5.86,2.37 -1.04,1.02 -1.66,1.99 -2.15,3.38 -0.32,0.91 -0.35,1.14 -0.34,2.75 0.00,1.56 0.04,1.85 0.33,2.67 0.47,1.31 1.03,2.21 2.03,3.21 1.24,1.24 2.98,2.10 4.75,2.34 0.20,0.02 0.52,0.05 0.71,0.07 l 0.33,0.01 -0.18,-0.59 c -0.10,-0.32 -0.36,-1.19 -0.58,-1.92 l -0.39,-1.33 -0.66,-0.27 c -2.47,-1.02 -3.46,-4.05 -2.14,-6.54 1.17,-2.20 3.93,-3.01 6.29,-1.85 0.92,0.45 1.43,0.97 1.99,2.01 l 0.42,0.80 1.85,0.57 1.85,0.57 0.04,-0.36 C 24.40,14.90 24.01,13.28 23.61,12.41 22.88,10.82 21.39,9.24 19.87,8.48 18.63,7.85 17.32,7.54 16.02,7.53 Z m 0.21,8.06 c -0.40,-0.00 -0.49,0.15 -0.63,0.41 -0.17,0.31 -0.08,0.66 1.60,6.44 0.98,3.36 1.87,6.26 1.96,6.45 0.09,0.18 0.32,0.37 0.50,0.42 0.62,0.15 0.92,-0.15 1.87,-2.02 0.49,-0.96 0.92,-1.80 0.95,-1.87 0.02,-0.06 1.25,1.06 2.72,2.52 1.47,1.46 2.84,2.74 3.04,2.84 0.87,0.44 2.04,0.04 2.44,-0.84 0.54,-1.20 0.36,-1.52 -2.79,-4.68 -1.45,-1.46 -2.62,-2.68 -2.59,-2.70 0.03,-0.02 0.85,-0.45 1.83,-0.93 0.97,-0.48 1.86,-1.01 1.97,-1.16 0.25,-0.37 0.25,-0.79 -0.02,-1.07 -0.13,-0.13 -2.75,-0.97 -6.47,-2.08 -4.19,-1.24 -5.75,-1.71 -6.43,-1.72 z'
    }
  ]
}
