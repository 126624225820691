function loadIcons() {
  const iconModules = import.meta.glob('./wisk/*.js', { eager: true }),
    icons = Object.values(iconModules).reduce((acc, module) => {
      const icon = module.default
      if (icon?.name) {
        acc[icon.name] = icon
      }
      return acc
    }, {})

  return icons
}

const icons = loadIcons()
export default icons
