<template>
  <div class="fill-width-height attachments-input-container material-design-input combined-control-input" :class="{ 'has-value': !!(attachments && attachments.length) }">
    <div class="form-control fill-width-height attachments-input ps-1 pb-0">
      <div v-for="item in attachments" :key="item.url" class="float-start me-1 mb-1 middle-center">
        <imageView :src="item.url" v-if="getIconFromFileURL(item.url).icon === 'file-image'" filterDisplay="tinyThumbLandscape" class="" @click="openViewer(item)" />
        <icon v-else :name="getIconFromFileURL(item.url).icon" class="my-1 mx-2" :scale="1" :style="{ color: getIconFromFileURL(item.url).color }" @click="openViewer(item)" />
      </div>
    </div>
    <!-- .info-icon hides it when in the grid -->
    <b-button v-if="!disabled" class="info-icon bt-add-image-plus" v-show="!attachments.length" variant="link" @click="openUploader" v-tooltip="translations.txtCustomFieldAddAttachments">
      <icon name="wisk-plus" class="" :scale="0.9" />
    </b-button>

    <!-- TODO: investigate why this closes itself on mousleave even if it is not supposed to do so - this is a bug in floating-vue component -->
    <v-menu :triggers="['click']" :distance="5" v-model:shown="popupVisible" :autoHide="false" popperClass="" class="mt-n1 bt-toggle-attachments-popup">
      <b-button v-show="attachments.length" :id="buttonId" variant="link" class="">
        <icon name="wisk-caret-down" class="" :scale="1.19" v-show="!popupVisible" />
        <icon name="wisk-caret-up" class="" :scale="1.19" v-show="popupVisible" />
      </b-button>

      <template v-slot:popper>
        <div class="overflow-hidden">
          <h5 class="bg-body px-2 pb-1 pt-2">
            <b-row>
              <b-col cols="10" class="">
                {{ label }}
              </b-col>
              <b-col cols="2" class="">
                <b-button v-if="!disabled" variant="link" @click="openUploader" class="px-3 py-0" v-tooltip="translations.txtGenericAdd">
                  <icon name="wisk-plus" class="" :scale="1.0" />
                </b-button>
              </b-col>
            </b-row>
          </h5>
          <div class="p-2 attachments-input-popup">
            <draggable class="draggable-attachments-list" v-model="tempAttachments" v-bind="dragOptions" @start="drag = true" @end="saveSortOrder" itemKey="url">
              <template #item="{ element: item, index }">
                <div class="draggable-imge-element">
                  <imageView :src="item.url" v-if="getIconFromFileURL(item.url).icon === 'file-image'" filterDisplay="thumb" class="h-75" @click="openViewer(item)" />
                  <icon v-else :name="getIconFromFileURL(item.url).icon" class="h-75" :scale="1.5" :style="{ color: getIconFromFileURL(item.url).color }" @click="openViewer(item)" />
                  <small v-if="item.title" class="text-truncate w-100 d-inline-block" :title="item.title">
                    {{ item.title }}
                  </small>
                  <b-button v-if="!disabled" variant="link" @click="remove(index)" class="bt-delete-image">
                    <icon name="wisk-trash" class="text-danger" :scale="0.7" />
                  </b-button>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </v-menu>

    <label>
      <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />

      {{ label }}

      <slot name="label-append"></slot>
    </label>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import { guid, compareNumbers, getIconFromFileURL } from '@/modules/utils'
import imageView from '@/components/common/ImageView'

export default {
  name: 'AttachmentsInput',
  emits: ['input'],
  components: { imageView, draggable },
  props: {
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    className: null,
    inputClass: null,
    prefix: String,
    suffix: String,
    label: String,
    disabled: Boolean
  },
  data() {
    return {
      drag: false,
      getIconFromFileURL,
      popupVisible: false,
      menuOpen: false,
      infoTooltipVisible: false,
      attachments: [],
      tempAttachments: []
    }
  },
  computed: {
    ...mapState(['translations']),
    buttonId() {
      return 'z' + guid()
    },
    dragOptions() {
      return {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    openViewer(item) {
      this.setGlobalAction({ type: 'mediaViewer', action: { type: this.getMediaType(item.url), url: item.url, fileName: item.title } })
      console.log('item', item)
    },
    setValue(value) {
      if (Array.isArray(value)) {
        this.attachments = value
          .filter(v => !!v)
          .map(v => ({ ...v, url: v.url || v.image_url, type: v.image_url ? 'image' : v.type }))
          .sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
      }
    },
    remove(index) {
      this.tempAttachments.splice(index, 1)
      this.save()
    },
    getMediaType(url) {
      let fileExtension = url ? url.split('.').pop().toLowerCase() : null

      switch (fileExtension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return 'image'

        case 'pdf':
          return 'pdf'

        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'xls':
        case 'xlsx':
          return 'office'

        default:
          return fileExtension
      }
    },
    saveSortOrder() {
      this.drag = false

      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        for (let i = 0; i < this.tempAttachments.length; i++) {
          this.tempAttachments[i] = { ...this.tempAttachments[i], sort_order: i }
        }
        this.save()
      }, 300)
    },
    save() {
      this.$emit('input', this.tempAttachments)
    },
    onUploadComplete(attachments) {
      let temp = [...this.tempAttachments, ...attachments]

      for (let i = 0; i < temp.length; i++) {
        let element = { ...temp[i] }
        if (element && element.filename) {
          temp[i] = { url: element.filename, title: element.title }
          temp[i].type = this.getMediaType(element.filename)
        }
        temp[i].sort_order = i
      }
      this.tempAttachments = temp.map(v => ({ ...v }))
      this.save()
    },
    openUploader() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          fileTypesAccept: '.pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .gif',
          useGoogle: true,
          save: this.onUploadComplete,
          multiple: true,
          fullFileInfo: true
        }
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.tempAttachments = this.attachments.map(v => ({ ...v }))
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  watch: {
    popupVisible() {
      if (this.popupVisible) {
        this.tempAttachments = this.attachments.map(v => ({ ...v }))
      }
    }
  }
}
</script>

<style lang="scss">
.attachments-input-container {
  .bt-add-image-plus {
    position: absolute;
    top: 5px;
    right: 6px;
    border-color: transparent;
    padding: 0;
  }

  .bt-toggle-attachments-popup {
    position: absolute;
    top: 5px;
    right: 5px;
    border-color: transparent;
    padding: 0;
  }
}

.attachments-input {
  min-height: 33px;
  overflow: hidden;
  max-height: 33px;
  background: transparent;
}

.attachments-input-popup {
  width: 415px;
  min-height: 90px;
}

.draggable-attachments-list {
  display: flex;
  gap: 0.7rem;

  .draggable-imge-element {
    height: 60px;
    width: 55px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    .bt-delete-image {
      position: absolute;
      top: 0;
      right: 2px;
      border-color: transparent;
      padding: 0;
      display: none;
    }

    &:hover {
      .bt-delete-image {
        display: block;
      }
    }
  }
}

.bt-toggle-attachments-popup {
  max-width: 32px;
}
</style>
