export default {
  name: 'wisk-transfers',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M31.7,17.3l-3.8-9.5l0,0L26.8,5c-0.4-0.9-1.4-1.3-2.2-0.9L12.6,8.8c-0.9,0.4-1.3,1.4-1,2.2l1.3,3.2h0l3.7,9.1 c0.2,0.6,0.9,0.9,1.5,0.6l12.9-5.2C31.7,18.6,32,17.9,31.7,17.3z M21,7.8l1.3,3.1l-3.2,1.3l-1.3-3.1L21,7.8z M30,17.5l-11.9,4.8 c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-4.7-11.8c0,0,0-0.1,0-0.1l2.9-1.2l1.6,4.1c0.2,0.6,0.9,0.9,1.5,0.6l4.1-1.7c0.6-0.2,0.8-0.9,0.6-1.5 l-1.6-4.1l2.9-1.2c0,0,0.1,0,0.1,0.1l4.7,11.8C30.1,17.4,30,17.5,30,17.5L30,17.5z'
    },
    {
      d: 'M34.9,20.5c-0.2-0.6-0.9-0.9-1.6-0.7c0,0,0,0,0,0l-15.5,6.1c-0.6-0.8-1.5-1.2-2.5-1.3L8.9,8.3C8.4,7.2,7.3,6.4,6.1,6.4H1.2 C0.5,6.4,0,7,0,7.7s0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.9c0.2,0,0.4,0.1,0.5,0.3L13,25.5c-0.7,0.6-1.1,1.5-1.1,2.4 c0,1.8,1.5,3.3,3.3,3.3c1.7,0,3.1-1.3,3.3-2.9l15.7-6.2C34.9,21.8,35.2,21.1,34.9,20.5C34.9,20.5,34.9,20.5,34.9,20.5z M15.2,29.3 c-0.8,0-1.4-0.7-1.4-1.5s0.7-1.4,1.5-1.4c0.8,0,1.4,0.7,1.4,1.5C16.7,28.7,16,29.3,15.2,29.3L15.2,29.3z'
    }
  ]
}
