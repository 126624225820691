<template>
  <div>
    <!-- <portal to="global-header">
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" class="d-flex justify-content-end w-100 mb-1 mb-md-0" dropdownClass="flex-grow-1 flex-md-grow-0" />
    </portal> -->
    <portal to="global-action" v-if="!inModal">
      <wiskActions dropdownClass="btn-block" :title="translations.txtGenericActions" :actions="pageActions" />
    </portal>
    <wiskGrid :rowData="distributors" :columnDefs="columns" :gridOptions="options" parentGridName="Distributors" showFoundArchived :customFilter="customFilterSelected" :defaultFilter="defaultFilter" @requestFilter="requestFilter"
      :excel="{ fileName: 'Distributors' }" :header="{}" :gridStyle="{ height: 'calc(100vh - 145px)' }" v-bind="$attrs">
      <template v-slot:additional-header-controls>
        <wiskActions dropdownClass="btn-block" :title="translations.txtGenericActions" :actions="pageActions" v-if="inModal" />

        <wiskGridCustomFilterDropdown :customFilters="customFilters" v-model="customFilterSelected" class="float-end" />
      </template>
    </wiskGrid>
    <wiskModal v-model="importDialogVisible" size="lg" hideFooter :title="translations.txtDistributorsImport">
      <distributorsUploader @ready="importDialogVisible = false" />
    </wiskModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { setupCustomFieldsAsColumns } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import wiskGrid from '@/components/grids/WiskGrid'
import distributorsUploader from '@/components/distributors/DistributorsUploader'
import { getDistributorsGridColumns } from './gridOptions'

export default {
  name: 'DistributorsGrid',
  components: { wiskGrid, distributorsUploader },
  props: {
    inModal: { type: Boolean, default: false }
  },
  data() {
    return {
      customFilterSelected: null,
      importDialogVisible: false,
      columns: [],
      options: {
        getRowHeight: (params) => {
          const length = (params?.data?.contacts && params.data.contacts.length) || 1
          return length > 1 ? length * 27 : 50
        }
      },
      defaultFilter: null
    }
  },
  computed: {
    ...mapState(['translations', 'customFieldsByTarget', 'distributors']),
    customFilters() {
      return [{ predicate: d => d.archived, name: 'archived', label: 'Archived' }]
    },
    pageActions() {
      let actions = [
        { key: 10, title: this.translations.txtDistributorNew, action: this.newDistributor },
        // { key: 20, title: this.translations.txtGLAccountsManage, action: this.glAccounts },
        { key: 30, title: this.translations.txtDistributorsImport, action: this.openDistributorsUploader }
      ]

      return actions
    }
  },
  methods: {
    ...mapActions(['setDistributor', 'setGlobalAction']),
    // glAccounts() {
    //   this.setGlobalAction({ type: 'glAccounts', action: {} })
    // },
    requestFilter(name) {
      this.customFilterSelected = this.customFilters.find(filter => filter.name === name)
    },
    newDistributor() {
      this.setGlobalAction({ type: 'distributorEdit', action: { id: 0 } })
    },
    openDistributorsUploader() {
      this.importDialogVisible = true
    },
    save({ value, id, type, previousValue }) {
      this.setDistributor({ id, operation: { type, value, from: previousValue } })
    },
    createColumns() {
      let columns = getDistributorsGridColumns(this.translations, this.save, this.setGlobalAction),
        customFieldsColumns = setupCustomFieldsAsColumns({
          customFields: (this.customFieldsByTarget && this.customFieldsByTarget.distributor) || [],
          translate: this.translations.translate,
          save: this.save,
          agGridFilters,
          columns
        })
      this.columns = [...columns, ...customFieldsColumns]
    }
  },
  mounted() {
    this.defaultFilter = { predicate: item => !item.archived, name: 'active', label: this.translations.txtFilterActive, hidesArchivedItems: true }
  },
  watch: {
    'customFieldsByTarget.distributor': {
      handler: 'createColumns',
      immediate: true
    }
  }
}
</script>
<style lang="scss"></style>
