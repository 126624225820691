<template>
  <wiskConfirm v-if="editAction" ref="thisConfirmDialog" v-bind="editAction.props" @hide="setGlobalAction({ type: 'confirmDialog', action: null })" key="999" />
</template>

<script>
import { mapActions } from 'vuex'
import wiskConfirm from '@/components/common/WiskConfirmDialog'

export default {
  name: 'WiskConfirmDialogAction',
  components: { wiskConfirm },
  props: { editAction: Object },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapActions(['setGlobalAction'])
  },
  mounted() {
    if (this.editAction && this.editAction.type && this.$refs.thisConfirmDialog && typeof this.$refs.thisConfirmDialog[this.editAction.type] === 'function') {
      this.$refs.thisConfirmDialog[this.editAction.type](this.editAction.payload)
    }
  }
}
</script>

<style lang="scss">

</style>
