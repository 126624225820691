export default {
  name: 'wisk-stop',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M35,17.229A17.5,17.5,0,1,0,18.222,34.987,17.486,17.486,0,0,0,35,17.229Zm-8.164,1.58a1.472,1.472,0,0,1-1.472,1.472H9.653a1.473,1.473,0,0,1-1.472-1.472V16.186a1.473,1.473,0,0,1,1.472-1.472H25.36a1.472,1.472,0,0,1,1.472,1.472Z'
    }
  ]
}
