<template>
  <div id="wisk-draft-invoice-region-map-container" ref="container" :key="refreshKey">
    <div class="region-map-component" v-for="region in regions" :key="region.id" :style="styleMap[region.id]" @click="$emit('select', region.id)" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getPDFRegionPosition } from '@/modules/utils'

export default {
  name: 'RegionMap',
  components: {},
  emits: ['select'],
  props: {
    originalDocumentSize: { type: Object, validator: z => z && z.height && z.width, default: () => ({ width: 1, height: 1 }) },
    regions: { type: Array },
    rotation: Number,
    refreshKey: Number
  },
  data() {
    return {
      scale: 0,
      styleMap: {}
    }
  },
  computed: {
    ...mapState(['windowResizeKey'])
  },
  methods: {
    getStyle(region) {
      return getPDFRegionPosition(region, this.scale, this.rotation)
    },
    init(first) {
      let container = this.$refs.container

      if (container) {
        let box = container.getBoundingClientRect(),
          rotated = this.rotation === 90 || this.rotation === 270,
          height = box.height,
          width = box.width,
          containerWidth = width,
          containerHeight = height,
          originalDocumentHeight = rotated ? this.originalDocumentSize.width : this.originalDocumentSize.height,
          originalDocumentWidth = rotated ? this.originalDocumentSize.height : this.originalDocumentSize.width

        this.scale = (originalDocumentHeight / containerHeight + originalDocumentWidth / containerWidth) / 2

        this.regions.forEach(region => {
          this.styleMap[region.id] = this.getStyle(region)
        })

        if (first) {
          setTimeout(() => {
            this.init()
          }, 0)
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.init('mounted')
    }, 0)
  },
  watch: {
    rotation: 'init',
    windowResizeKey: 'init',
    refreshKey: 'init',
    originalDocumentSize: 'init'
  }
}
</script>

<style lang="scss">
#wisk-draft-invoice-region-map-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .region-map-component {
    position: absolute;
    z-index: 1;
    pointer-events: initial;
    // outline: 1px solid var(--blue-300);

    &:hover {
      opacity: 0.3;
      background-color: var(--primary);
      cursor: pointer;
    }
  }
}
</style>
