<template>
  <div>
    <div class="wisk-item-measurement-container" v-if="!inline" :id="domId">
      <wiskInput :infoTooltipKey="infoTooltipKey" fitChildren :fitChildrenCols="disabledLocal ? 0 : 2" :label="label || translations.txtGenericMeasurement"
        :modelValue="formattedValue" showPlainText disabled :key="key" class="px-0" :class="[inputClass, 'light-control', 'combined-control-input']" :tooltipOverride="tooltip">
        <b-button type="button" variant="link" class="float-end h-100" size="sm" @click="modalVisible = true" v-if="!disabledLocal">
          <icon name="wisk-edit" scale=".7"></icon>&nbsp;
          <!-- {{ translations.txtGenericEdit }} -->
        </b-button>
      </wiskInput>

      <wiskModal v-model="modalVisible" size="md" @ok="setOperation" @hide="reset" :okDisabled="!valid" :okText="translations.txtGenericSave" hideFooterExtra>
        <template v-slot:modal-title>
          <b-row>
            <b-col>
              <h5 class="modal-title mb-2 me-3">
                {{ label || translations.txtGenericMeasurement }}

                <infoTooltip v-if="!!infoTooltipKey" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
              </h5>
            </b-col>
          </b-row>
        </template>
        <wiskInputGroup @errorCountChanged="setValidState" class="px-5 pt-2" :disabled="disabledLocal" style="min-height: 500px;">
          <b-row no-gutters>
            <b-col cols="12" class="">
              <wiskInput infoTooltipKey="74461288-d572-4fea-a1ae-4035e4fb5b73" :label="translations.txtItemOperationsMeasurementQuantity" v-model="quantity" inputType="number" :decimals="4" required
                :triggerInputOnLoad="triggerInputOnLoad" :disabled="disabledLocal" decimalsAsNeeded :minDecimals="2" />
            </b-col>
            <b-col cols="12" class="">
              <wiskSelect infoTooltipKey="3a47e0e3-961f-47d5-ad49-a34903f37b98" v-model="selectedUnitOfMeasurement" @change="selectedUnitOfMeasurementType = $event.type" :items="computedMeasurements"
                :multiselectOptions="multiselectOptionsMeasurementsComputed" :label="translations.txtGenericUnitOfMeasurement" required :triggerInputOnLoad="triggerInputOnLoad" :disabled="disabledLocal" />
            </b-col>
            <b-col cols="12" class="" v-if="showAlias">
              <wiskInput infoTooltipKey="b58fd8b6-c4af-4b0b-9d59-08765538546e" :label="translations.txtItemOperationsMeasurementAlias" v-model="alias" :triggerInputOnLoad="triggerInputOnLoad" :disabled="disabledLocal" />
            </b-col>
          </b-row>
        </wiskInputGroup>
      </wiskModal>
    </div>
    <wiskInputGroup v-if="inline" @errorCountChanged="setValidState" class="h-auto px-" :disabled="disabledLocal" :legend="showLabelIfInline ? (label || translations.txtGenericMeasurement) : ''">
      <b-row no-gutters>
        <b-col :cols="horizontal ? ((showAlias && 4) || 6) : 12" class="px-0">
          <wiskInput :infoTooltipKey="infoTooltipKey" :label="quantityLabel || label" v-model="quantity" inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" required
            :triggerInputOnLoad="triggerInputOnLoad" :disabled="disabledLocal" @blur="setOperation" :class="{ 'border-radius-right-none': horizontal }" @update:modelValue="emitUpdateModel" />
        </b-col>
        <b-col :cols="horizontal ? ((showAlias && 4) || 6) : 12" class="px-0">
          <wiskSelect infoTooltipKey="e1e03d45-8def-4f6b-8598-769f3f5880a9" v-model="selectedUnitOfMeasurement" :items="computedMeasurements" :multiselectOptions="{ ...multiselectOptionsMeasurementsComputed }" required
            :class="{ 'border-radius-left-none border-start-none': horizontal, 'border-radius-right-none': horizontal && showAlias, 'border-end-none': horizontal && showAlias }" :triggerInputOnLoad="triggerInputOnLoad"
            :disabled="disabledLocal" @change="setOperation" :label="umLabel" />
        </b-col>
        <b-col :cols="horizontal ? 4 : 12" class="px-0" v-if="showAlias">
          <wiskInput infoTooltipKey="b58fd8b6-c4af-4b0b-9d59-08765538546e" :label="translations.txtItemOperationsMeasurementAlias" v-model="alias" :triggerInputOnLoad="triggerInputOnLoad"
            :disabled="disabledLocal" :class="{ 'border-radius-left-none': horizontal }" @blur="setOperation" />
        </b-col>
      </b-row>
    </wiskInputGroup>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { arrayToObjectById, guid, round } from '@/modules/utils'

export default {
  name: 'Measurement',
  emits: ['update:modelValue', 'operation', 'hide'],
  components: {},
  props: {
    modelValue: Object,
    label: String,
    quantityLabel: String,
    umLabel: String,
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    infoTooltipKey: { type: String, default: 'measurementComponent' },
    infoTooltipParams: { type: [Object, null], default: () => null },
    caseSize: Number,
    preferredType: String,
    inputClass: String,
    operation: { type: String, default: 'measurement' },
    disabled: Boolean,
    showAlias: Boolean,
    extraUnitsOfMeasurement: { type: Array, default: () => [] },
    disableTypeChange: Boolean,
    startOpened: Boolean,
    showLabelIfInline: Boolean,
    triggerInputOnLoad: Boolean,
    triggerInputOnSet: Boolean,
    horizontal: Boolean,
    inline: Boolean,
    emitFast: Boolean,
    tooltip: { type: String, default: '' },
    multiselectOptionsMeasurements: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      valid: true,
      oldValue: null,
      modalVisible: false,
      multiselectOptionsMeasurementsLocal: {
        maxHeight: 250,
        groupValues: 'units_of_measurements',
        groupLabel: 'title',
        multiple: false
      },
      selectedUnitOfMeasurement: '',
      selectedUnitOfMeasurementType: '',
      quantity: 0,
      key: 1,
      alias: '',
      disabledLocal: false,
      inputGroupParent: null,
      valueWatcherTimeoutId: null
    }
  },
  computed: {
    ...mapGetters(['measurements', 'measurementsByType']),
    ...mapState(['translations']),
    multiselectOptionsMeasurementsComputed() {
      return { ...this.multiselectOptionsMeasurementsLocal, ...this.multiselectOptionsMeasurements }
    },
    domId() {
      return 'z-' + (this.label || 'measurement-component').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    computedMeasurements() {
      let measurements = this.measurements

      if (this.disableTypeChange) {
        if (this.preferredType && this.measurementsByType[this.preferredType]) {
          measurements = [this.measurementsByType[this.preferredType]]
        } else if (this.modelValue && this.modelValue.type && this.measurementsByType[this.modelValue.type]) {
          measurements = [this.measurementsByType[this.modelValue.type]]
        }
      }
      measurements = [...measurements, ...this.extraUnitsOfMeasurement]

      return Object.values(arrayToObjectById(measurements))
    },
    computedValue() {
      return {
        quantity: this.quantity,
        alias: this.alias,
        case_size: this.caseSize || null,
        unit_of_measurement: this.selectedUnitOfMeasurement,
        type: this.selectedUnitOfMeasurementType
      }
    },
    formattedValue() {
      if (this.alias) {
        return `${this.alias} (${round(this.quantity, 4) || '-'} ${this.selectedUnitOfMeasurement})`
      }
      return `${round(this.quantity, 4) || '-'} ${this.selectedUnitOfMeasurement}`
    }
  },
  methods: {
    setValidState(errorCount) {
      this.valid = !errorCount

      if (this.inputGroupParent && this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.valid })
      }
    },
    emitUpdateModel() {
      if (this.emitFast) {
        this.$emit('update:modelValue', { ...this.computedValue })
      }
    },
    setOperation(event) {
      setTimeout(() => {
        let operation = { type: this.operation, value: { ...this.computedValue }, from: this.oldValue }

        if (event?.type) {
          this.selectedUnitOfMeasurementType = event.type
        }

        if (this.inputGroupParent?.setOperation) {
          this.inputGroupParent.setOperation({
            operation,
            operationTypes: { set: this.operation }
          })
        }

        this.$emit('update:modelValue', { ...this.computedValue })
        this.$emit('operation', operation)
      }, 0)
    },
    reset() {
      if (this.oldValue) {
        this.selectedUnitOfMeasurement = this.oldValue.unit_of_measurement
        this.quantity = this.oldValue.quantity
        this.alias = this.oldValue.alias
        this.key++
      }
      this.$emit('hide')
    }
  },
  mounted() {
    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }

    if (this.inputGroupParent) {
      this.disabledLocal = !!this.inputGroupParent.disabled

      if (this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: false })
      }
    }
    this.disabledLocal = this.disabledLocal || this.disabled

    this.oldValue = this.modelValue

    setTimeout(() => {
      if (this.triggerInputOnLoad) {
        this.setOperation()
      }
    }, 0)
  },
  beforeUnmount() {
    if (this.inputGroupParent && this.inputGroupParent.setValidState) {
      this.inputGroupParent.setValidState({ name: this.domId, hasError: false })
    }
  },
  watch: {
    startOpened: {
      immediate: true,
      handler(startOpened) {
        if (startOpened) {
          this.modalVisible = startOpened
        }
      }
    },
    disabled() {
      this.disabledLocal = this.disabled
    },
    modelValue: {
      immediate: true,
      handler(modelValue) {
        if (modelValue) {
          this.selectedUnitOfMeasurement = modelValue.unit_of_measurement
          this.selectedUnitOfMeasurementType = modelValue.type
          this.quantity = modelValue.quantity
          this.alias = modelValue.alias

          // this.oldValue = { ...this.computedValue, ...modelValue }

          setTimeout(() => {
            if (this.triggerInputOnSet) {
              this.setOperation()
            }
          }, 0)
        }
      }
    },
    modalVisible(modalVisible) {
      if (!modalVisible) {
        this.$emit('hide')
      }
    }
  }
}
</script>

<style lang="scss"></style>
