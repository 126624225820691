<template>
  <textBase :params="params" :key="key" @editing="editing = $event" :suffix="suffix">
    <component v-if="infoComponentType && (canShowInfoComponent || infoComponentType === 'extraText') && !editing" :is="infoComponentType" :params="params" />
    <component v-if="secondaryInfoComponentType && (canShowInfoComponent || secondaryInfoComponentType === 'extraText') && !editing" :is="secondaryInfoComponentType" :params="params" />
    <component v-if="tertiaryInfoComponentType && (canShowInfoComponent || tertiaryInfoComponentType === 'extraText' || tertiaryInfoComponentType === 'costChangeInfo') && !editing" :is="tertiaryInfoComponentType" :params="params" />
  </textBase>
</template>

<script>
import textBase from './CellTextBase'
import extraButtons from './infoHelpers/ExtraButtons'
import extraText from './infoHelpers/ExtraText'
import familyInfo from './infoHelpers/FamilyInfo'
import inventoryDetails from './infoHelpers/InventoryDetailsInfo'
import costChangeInfo from './infoHelpers/CostChangeInfo'

export default {
  name: 'CellText',
  components: { textBase, familyInfo, extraButtons, extraText, inventoryDetails, costChangeInfo },
  data() {
    return {
      key: 1,
      editing: false,
      canShowInfoComponent: false
    }
  },
  computed: {
    infoComponentType() {
      return this.params.infoComponentType
    },
    secondaryInfoComponentType() {
      return this.params.secondaryInfoComponentType
    },
    tertiaryInfoComponentType() {
      return this.params.tertiaryInfoComponentType
    },
    forceInfoComponent() {
      return !!this.params.forceInfoComponent
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    suffix() {
      return this.params.suffix || this.params.value.suffix || ''
    }
  },
  methods: {
    refresh() {
      return this.editing
    }
  },
  mounted() {
    if (this.forceInfoComponent || (window.CobrowseIO && window.CobrowseIO.currentSession)) {
      this.canShowInfoComponent = true
    } else if (this.$el) {
      setTimeout(() => {
        this.$el.addEventListener('mouseenter', () => {
          this.canShowInfoComponent = true
        })
      }, 500)
    }
  }
}
</script>
