export default {
  name: 'wisk-profile',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M31.4,24.1c-2.7-3.1-7.2-5.4-13.9-5.4c-5.2,0-9.1,1.4-11.8,3.5c-0.8,0.6-1.5,1.2-2.1,1.9C1.4,26.6,0.1,29.7,0,33 c0,0.1,0,0.2,0,0.4c0,0.9,0.7,1.6,1.6,1.6c0,0,0,0,0,0h31.8c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0C34.9,30,33.6,26.7,31.4,24.1z  M3.6,31.6c0.4-1.9,1.3-3.7,2.5-5.1c0.5-0.6,1-1.1,1.6-1.5c2.4-1.8,5.7-2.8,9.7-2.8c5,0,8.8,1.4,11.3,4.2c1.3,1.5,2.1,3.2,2.5,5.1 H3.6z'
    },
    {
      d: 'M17.4,0c-4.5,0-8.1,3.7-8.1,8.2c0,4.5,3.7,8.1,8.2,8.1c4.5,0,8.1-3.7,8.1-8.1C25.6,3.7,22,0,17.4,0C17.5,0,17.4,0,17.4,0z  M17.4,12.9c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7C22.2,10.8,20,12.9,17.4,12.9 C17.4,12.9,17.4,12.9,17.4,12.9z'
    }
  ]
}
