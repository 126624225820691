export default {
  name: 'wisk-verynice',
  width: 14,
  height: 16,
  paths: [
    {
      d: 'M5.00165 4.49147C5.40666 4.42984 5.81167 4.36823 6.21667 4.30659C6.76084 4.22377 7.305 4.14087 7.84918 4.05812C8.15305 4.01191 8.45687 3.96536 8.76088 3.92016C8.87362 3.90339 8.90354 3.87293 8.92051 3.76025C9.0022 3.2179 9.08493 2.67571 9.16752 2.13349C9.2498 1.59326 9.33242 1.05308 9.41476 0.512864C9.43959 0.350006 9.46391 0.18707 9.48879 0.0242212C9.49011 0.0156103 9.4945 0.00746811 9.50742 0C9.52908 0.141952 9.5508 0.283895 9.57238 0.425856C9.65873 0.993921 9.74499 1.562 9.83139 2.13006C9.91385 2.67229 9.9969 3.21443 10.0786 3.75678C10.0963 3.87415 10.1248 3.90302 10.2411 3.92059C10.8112 4.00671 11.3811 4.09412 11.9511 4.18078C12.4933 4.26321 13.0355 4.34522 13.5778 4.42753C13.7182 4.44885 13.8585 4.47057 14 4.50533C13.9127 4.51925 13.8254 4.53365 13.738 4.54701C13.0846 4.64679 12.4313 4.74656 11.7778 4.84595C11.2635 4.92419 10.749 5.00215 10.2345 5.07958C10.1261 5.09591 10.0958 5.12725 10.0792 5.23716C9.99278 5.80927 9.90521 6.3812 9.8183 6.95324C9.732 7.52131 9.64605 8.08944 9.55984 8.65753C9.54254 8.77156 9.52475 8.88553 9.49403 9C9.48032 8.9142 9.46607 8.82847 9.45296 8.74257C9.33265 7.9541 9.21223 7.16565 9.09247 6.37709C9.03336 5.98779 8.97533 5.59832 8.91743 5.20884C8.90703 5.13887 8.86581 5.09556 8.78575 5.08286C8.61909 5.05641 8.45207 5.03225 8.28524 5.0069C7.74304 4.92449 7.20084 4.84207 6.65867 4.75947C6.1443 4.6811 5.62996 4.60253 5.11561 4.52396C5.07702 4.51807 5.03854 4.5114 5 4.5051L5.00165 4.49147Z'
    },
    {
      d: 'M0.00164783 11.4915C0.406655 11.4298 0.811665 11.3682 1.21667 11.3066C1.76084 11.2238 2.305 11.1409 2.84918 11.0581C3.15305 11.0119 3.45687 10.9654 3.76088 10.9202C3.87362 10.9034 3.90354 10.8729 3.92051 10.7603C4.0022 10.2179 4.08493 9.67571 4.16752 9.13349C4.2498 8.59326 4.33242 8.05308 4.41476 7.51286C4.43959 7.35001 4.46391 7.18707 4.48879 7.02422C4.49011 7.01561 4.4945 7.00747 4.50742 7C4.52908 7.14195 4.5508 7.28389 4.57238 7.42586C4.65873 7.99392 4.74499 8.562 4.83139 9.13006C4.91385 9.67229 4.9969 10.2144 5.07864 10.7568C5.09633 10.8742 5.12481 10.903 5.24109 10.9206C5.81117 11.0067 6.38105 11.0941 6.95105 11.1808C7.49326 11.2632 8.03553 11.3452 8.57775 11.4275C8.71817 11.4488 8.85852 11.4706 9 11.5053C8.91266 11.5192 8.82539 11.5337 8.73796 11.547C8.08461 11.6468 7.43126 11.7466 6.77785 11.8459C6.26346 11.9242 5.74903 12.0021 5.23452 12.0796C5.12606 12.0959 5.09582 12.1272 5.07921 12.2372C4.99278 12.8093 4.90521 13.3812 4.8183 13.9532C4.732 14.5213 4.64605 15.0894 4.55984 15.6575C4.54254 15.7716 4.52475 15.8855 4.49403 16C4.48032 15.9142 4.46607 15.8285 4.45296 15.7426C4.33265 14.9541 4.21223 14.1656 4.09247 13.3771C4.03336 12.9878 3.97533 12.5983 3.91743 12.2088C3.90703 12.1389 3.86581 12.0956 3.78575 12.0829C3.61909 12.0564 3.45207 12.0323 3.28524 12.0069C2.74304 11.9245 2.20084 11.8421 1.65867 11.7595C1.1443 11.6811 0.629956 11.6025 0.115614 11.524C0.0770157 11.5181 0.0385357 11.5114 0 11.5051L0.00164783 11.4915Z'
    }
  ]
}
