export default {
  name: 'wisk-grouping',
  width: 35,
  height: 35,
  paths: [
    { d: 'M1.7,0H8c0.9,0,1.6,0.7,1.6,1.6V8c0,0.9-0.7,1.6-1.6,1.6H1.7C0.8,9.5,0.1,8.8,0.1,8V1.6C0.1,0.7,0.8,0,1.7,0z' },
    { d: 'M14.3,0h19c0.9,0,1.6,0.7,1.6,1.6V8c0,0.9-0.7,1.6-1.6,1.6h-19c-0.9,0-1.6-0.7-1.6-1.6V1.6C12.8,0.7,13.5,0,14.3,0z' },
    { d: 'M1.7,12.7H8c0.9,0,1.6,0.7,1.6,1.6v6.4c0,0.9-0.7,1.6-1.6,1.6H1.7c-0.9,0-1.6-0.7-1.6-1.6v-6.4C0.1,13.4,0.8,12.7,1.7,12.7 z' },
    { d: 'M1.7,25.5H8c0.9,0,1.6,0.7,1.6,1.6v6.4C9.6,34.3,8.9,35,8,35H1.7c-0.9,0-1.6-0.7-1.6-1.6V27C0.1,26.2,0.8,25.5,1.7,25.5z' },
    { d: 'M14.3,12.7h19c0.9,0,1.6,0.7,1.6,1.6v6.4c0,0.9-0.7,1.6-1.6,1.6h-19c-0.9,0-1.6-0.7-1.6-1.6v-6.4 C12.8,13.4,13.5,12.7,14.3,12.7z' },
    { d: 'M14.3,25.5h19c0.9,0,1.6,0.7,1.6,1.6v6.4c0,0.9-0.7,1.6-1.6,1.6h-19c-0.9,0-1.6-0.7-1.6-1.6V27 C12.8,26.2,13.5,25.5,14.3,25.5z' },
    { d: 'M9.4,23.8L9.4,23.8L9.4,23.8C9.4,23.8,9.4,23.8,9.4,23.8z' }
  ]
}
