export default {
  name: 'wisk-sales',
  width: 35,
  height: 35,
  paths: [
    { d: 'M17.5007,35a17.5,17.5,0,1,1,0-35,1.6048,1.6048,0,1,1,0,3.21A14.29,14.29,0,1,0,31.79,17.5a1.6048,1.6048,0,1,1,3.21,0A17.5191,17.5191,0,0,1,17.5007,35Z' },
    {
      d: 'M34.5373,2.4841,28.3066,8.6822l3.6083-.0168h.0168a1.2587,1.2587,0,0,1,0,2.5174l-6.6627.05h-.0168a1.3184,1.3184,0,0,1-.8895-.3693,1.255,1.255,0,0,1-.3692-.9062l.05-6.713a1.2427,1.2427,0,0,1,1.2587-1.2419,1.2678,1.2678,0,0,1,1.2586,1.2587l-.0168,3.6418L32.7584.722A1.2509,1.2509,0,0,1,34.5373.7052,1.2661,1.2661,0,0,1,34.5373,2.4841Z'
    },
    {
      d: 'M21.3228,23.0817a4.9067,4.9067,0,0,1-2.7316,1.3064v1.2677a.7786.7786,0,0,1-.7785.7785h-.6346a.7786.7786,0,0,1-.7785-.7785V24.4607a8.5855,8.5855,0,0,1-2.22-.4558,6.7946,6.7946,0,0,1-1.1716-.5375.7931.7931,0,0,1-.34-1.01l.2818-.6259a.7671.7671,0,0,1,1.0592-.3714,6.5385,6.5385,0,0,0,3.1942.8267,2.9878,2.9878,0,0,0,1.7077-.4112,1.3268,1.3268,0,0,0,.5929-1.1594.9545.9545,0,0,0-.3287-.7481,2.422,2.422,0,0,0-.8307-.4657q-.5028-.1734-1.4335-.4112a15.5114,15.5114,0,0,1-2.2295-.6937,3.882,3.882,0,0,1-1.5062-1.1247,3.0584,3.0584,0,0,1-.6309-2.0181,3.43,3.43,0,0,1,1.05-2.512,4.97,4.97,0,0,1,2.75-1.341V10.1345a.7785.7785,0,0,1,.7785-.7785h.6346a.7785.7785,0,0,1,.7785.7785v1.2116a8.9789,8.9789,0,0,1,1.9917.5037,5.51,5.51,0,0,1,.9492.4619.7893.7893,0,0,1,.3091.9958l-.2906.6453a.77.77,0,0,1-1.0723.3644,5.8215,5.8215,0,0,0-2.8731-.8159,2.6816,2.6816,0,0,0-1.58.4211,1.35,1.35,0,0,0-.5929,1.1693,1.1034,1.1034,0,0,0,.3469.839,2.3817,2.3817,0,0,0,.8769.5119q.5277.1833,1.48.42a16.9937,16.9937,0,0,1,2.1734.6771,3.7071,3.7071,0,0,1,1.47,1.0768,2.976,2.976,0,0,1,.6111,1.9736A3.3866,3.3866,0,0,1,21.3228,23.0817Z'
    }
  ]
}
