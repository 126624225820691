<template>
  <gateKeeper feature="timeline" nagInLimit class="mb-4">
    <div class="w-100" style="">
      <wiskGrid :gridStyle="gridStyle" :rowData="timeline" :columnDefs="columns" :gridOptions="gridOptions" resetRows v-bind="$attrs"
        :excel="{ fileName: excelFileName }" :header="filters && !showSearch ? null : {}" :parentGridName="parentGridName">

        <template v-slot:additional-header-controls>
          <div v-if="showFilters" style="min-width: 200px">
            <wiskSelect :class="{ 'mb-0': !initialDateRangeShortcut }" infoTooltipKey="2c78f76a-6506-49c6-a1cb-11d6b56fa227" :label="translations.txtGenericFilters" v-model="localFilters" multiple
              :items="timelineFilterTypes" />
          </div>
          <div v-if="showDateRangeSelector" style="min-width: 200px">
            <dateRangeSelector shortcuts v-model="localDateRange" :initialShortcut="initialDateRangeShortcut" />
          </div>
        </template>
      </wiskGrid>

      <wiskLoading :loading="loading" />
    </div>
  </gateKeeper>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'

import api from '@/api'
import { formatDate } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import wiskGrid from '@/components/grids/WiskGrid'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import wiskLoading from '@/components/common/WiskLoading'
import timelineDataMapper from '@/components/timeline/timelineDataMapper'

export default {
  name: 'Timeline',
  components: { wiskLoading, wiskGrid, dateRangeSelector },
  props: {
    filters: Array,
    parentGridName: { type: String, required: true },
    showFilters: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: true },
    showDateRangeSelector: { type: Boolean, default: false },
    initialDateRangeShortcut: String,
    gridStyle: {
      type: Object,
      default: () => ({ minHeight: 'calc(100vh - 252px)' })
    },
    preload: Array
  },
  data() {
    return {
      timeline: [],
      localFilters: [],
      localDateRange: [],
      loading: false,
      debounceId: null,
      gridOptions: {
        getRowHeight: () => 40
      }
    }
  },
  computed: {
    ...mapState(['translations', 'firestoreInitialLoadComplete', 'currentPlanFeaturesByType', 'posItems', 'user']),
    ...mapGetters(['timelineFilterTypes']),
    searchPayloadComputed() {
      if (this.filters && this.filters.length) {
        return { filters: this.filters }
      }
      let filter = { filters: this.localFilters.map(f => ({ type: f })) }

      if (this.dateRangeISOComputed.length) {
        filter.interval = {
          start: this.dateRangeISOComputed[0],
          end: this.dateRangeISOComputed[1]
        }
      }

      return filter
    },
    excelFileName() {
      if (this.searchPayloadComputed && this.searchPayloadComputed.filters) {
        return `Timeline ${this.searchPayloadComputed.filters.map(f => f && f.type).join('-')}`
      }
      return 'Timeline'
    },
    dateRangeISOComputed() {
      if (this.localDateRange && this.localDateRange.length) {
        return this.localDateRange.map(d => d && d.toISOString && d.toISOString())
      }
      return []
    },
    columns() {
      let columns = [
        {
          headerName: this.translations.txtGenericYear,
          colId: 'year',
          wiskExpandFirst: true,
          enableRowGroup: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          hide: true,
          keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value }),
          valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
        },
        {
          headerName: this.translations.txtGenericMonth,
          colId: 'month',
          wiskExpandFirst: true,
          enableRowGroup: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          hide: true,
          keyCreator: params => JSON.stringify({ colId: 'month', sort: params.value + 1, title: this.translations[`txtGenericMonth${params.value}`] }),
          valueFormatter: params => this.translations[`txtGenericMonth${params.value}`],
          cellRendererParams: { useValueFormatter: true },
          valueGetter: params => (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0
        },
        {
          headerName: this.translations.txtGenericWeek,
          colId: 'week',
          wiskExpandFirst: true,
          enableRowGroup: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          hide: true,
          keyCreator: params => JSON.stringify(params.value),
          valueFormatter: params => params.value.title,
          valueGetter: params => {
            let week = (params.data && params.data.date && DateTime.fromJSDate(params.data.date).week) || 0,
              year = (week && params.data.date.getYear()) || 0,
              sort = parseInt(year.toString() + week.toString(), 10)

            return {
              colId: 'week',
              sort,
              title: this.translations.txtGenericWeek + ' ' + week
            }
          }
        },
        {
          headerName: this.translations.txtGenericChange,
          cellRenderer: 'CellDynamicLinkInText',
          sortOrder: 2,
          ...agGridFilters.text,
          maxWidth: 3000,
          colId: 'message',
          valueGetter: params => ({
            elements: (params.data && params.data.elements) || [],
            input_value: (params.data && params.data.text) || ''
          })
        },
        {
          headerName: this.translations.txtGenericDate,
          sortOrder: 3,
          colId: 'date',
          width: 100,
          ...agGridFilters.date,
          field: 'date',
          sort: 'desc',
          cellRendererParams: { useValueFormatter: true },
          valueFormatter: params => formatDate(params.value)
        },
        {
          headerName: this.translations.txtGenericUser,
          width: 100,
          enableRowGroup: true,
          sortOrder: 4,
          colId: 'doneBY',
          valueGetter: params => {
            const doneBy = params?.data?.values?.find?.(i => i.value.type === 'extended_user')?.value
            if (doneBy) {
              return doneBy.is_super_user ? (this.user.god_mode || doneBy.full_name === 'System Update' ? doneBy.full_name : 'Wisk Team') : doneBy.full_name
            }
            return ''
          }
        }
      ]

      return columns
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setOnDemandState']),
    refreshData() {
      clearInterval(this.debounceId)
      this.debounceId = setTimeout(() => {
        if (this.currentPlanFeaturesByType.timeline && this.currentPlanFeaturesByType.timeline.enabled) {
          api.getFilteredTimeline(this.searchPayloadComputed).then(result => {
            this.timeline = timelineDataMapper(result, this.$store.state, this.setGlobalAction)
          })
        }
      }, 600)
    }
  },
  beforeUnmount() {
    clearTimeout(this.debounceId)
  },
  watch: {
    firestoreInitialLoadComplete: 'refreshData',
    searchPayloadComputed: {
      handler: 'refreshData',
      immediate: true
    }
  }
}
</script>
