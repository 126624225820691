export default {
  name: 'wisk-file',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M30.24,10.7134,20.2435.6548a.5.5,0,0,0-.07-.0611,1.5163,1.5163,0,0,0-1.2126-.6019H8.4238A4.2441,4.2441,0,0,0,4.1756,4.2224V30.7776a4.2441,4.2441,0,0,0,4.2482,4.2306H26.5763a4.244,4.244,0,0,0,4.2481-4.2306V11.9084A1.5173,1.5173,0,0,0,30.24,10.7134ZM20.6622,5.2179l5.1989,5.1989H20.6622Zm7.1092,25.56a1.1876,1.1876,0,0,1-1.1951,1.1775H8.4238a1.1934,1.1934,0,0,1-1.1952-1.1775V4.2224A1.1934,1.1934,0,0,1,8.4238,3.0449h9.351v8.82a1.4482,1.4482,0,0,0,1.4392,1.4481h8.5574Z'
    }
  ]
}
