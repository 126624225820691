import get from 'lodash.get'

//can't import from utils because of circular dependency
const setValueAtPath = (obj, path, value) => {
  if (typeof path === 'string') {
    path = path.split('.')
  }
  if (path.length > 1) {
    const [head, ...rest] = path
    // If the current segment points to a primitive value, replace it with an object
    if (!obj[head] || typeof obj[head] !== 'object' || Array.isArray(obj[head])) {
      obj[head] = {}
    }
    setValueAtPath(obj[head], rest, value)
  } else {
    // This handles setting the value when the path is fully traversed
    obj[path[0]] = value
  }
}

const setPreferences = (key, value) => {
  try {
    let json = localStorage.getItem('WISK'),
      preferences = JSON.parse(json || '{}') || {}

    preferences.version = window.localPreferencesVersion

    if (value === null) {
      delete preferences[key]
    } else {
      setValueAtPath(preferences, key, value)
    }

    localStorage.setItem('WISK', JSON.stringify(preferences))
  } catch (e) {
    if (e && e.name === 'QuotaExceededError') {
      localStorage.clear()
    }
    console.error('setPreferences failed', e)
  }
}

const clearPreferences = () => {
  try {
    let preferences = { version: window.localPreferencesVersion }

    localStorage.setItem('WISK', JSON.stringify(preferences))
  } catch (e) {
    console.error('setPreferences failed', e)
  }
}

const getPreferences = (key, defaultValue) => {
  try {
    let json = localStorage.getItem('WISK'),
      preferences = JSON.parse(json || '{}') || {}
    return get(preferences, key, defaultValue || null)
  } catch (e) {
    console.error('getPreferences failed', e)
    return null
  }
}

const getPreferencesVersion = () => getPreferences('version', 0)

export { getPreferences, setPreferences, getPreferencesVersion, clearPreferences }
