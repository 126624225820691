<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'mediaViewer', action: null })" hideHeaderExtra
    centered size="lg" hideFooter :fullScreen="fullScreen" bodyClass="p-0" :forceRefreshOnResize="editAction.type === 'pdf'">

    <template v-slot:modal-title>
      <h5 class="modal-title"> {{ modalTitle }} </h5>
      <b-button variant="link" @click="download" class="p-0">
        <icon name="wisk-save" :scale=".7" />
        {{ translations.txtGenericDownload }}
      </b-button>
      <!-- <b-form-radio-group v-if="mediaType === 'office'" style="margin-left: 5px;" size="sm" buttons v-model="selectedOfficeViewer" name="selectedOfficeViewer" button-variant="outline-primary">
        <b-form-radio value="office"> Microsoft Office </b-form-radio>
        <b-form-radio value="gDocs"> Google Docs </b-form-radio>
      </b-form-radio-group> -->
    </template>

    <div class="w-100" style="height: calc(100vh - 200px); overflow: hidden;" v-if="mounted && mediaType && (base64data || url)">
      <component v-if="hasValidMediaViewer" class="" :is="`${mediaType}Viewer`" :url="url" :base64data="base64data" :selectedOfficeViewer="selectedOfficeViewer" />
      <p v-else class="text-center p-2" :title="mediaType">No preview</p>
    </div>

    <wiskLoading :loading="loading" />

  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import api from '@/api'
import pdfViewer from '@/components/viewers/pdf/PDFViewerFull'
import officeViewer from '@/components/viewers/office/OfficeViewer'
import imageViewer from '@/components/viewers/image/ImageViewer'

export default {
  name: 'MediaViewer',
  components: { pdfViewer, officeViewer, imageViewer },
  props: { editAction: Object },
  data() {
    return {
      selectedOfficeViewer: 'office', //'gDocs',
      loading: false,
      mounted: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    mediaType() {
      return (this.editAction?.type) || this.getMediaType(this.editAction?.url) || null
    },
    hasValidMediaViewer() {
      return (['image', 'pdf', 'office']).includes(this.mediaType)
    },
    fullScreen() {
      return (this.editAction && this.editAction.fullScreen) || null
    },
    base64data() {
      return (this.editAction && this.editAction.base64data) || null
    },
    url() {
      let url = this.editAction.url || null
      if (url && !url.startsWith('http')) {
        // url = 'https://wisk.s3.amazonaws.com/shared/images/bottles/full/' + url
        url = 'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + url
      }
      return url
    },
    modalTitle() {
      return (this.editAction?.modalTitle) || this.translations.txtGenericViewer + ': ' + this.fileName
    },
    fileName() {
      let fileName = ''
      if (this.editAction) {
        fileName = this.editAction.fileName || fileName

        if (!fileName) {
          let split = this.editAction.url.split('/')
          if (split.length) {
            fileName = split[split.length - 1]
          }
        }
      }
      return fileName || 'file_name_missing'
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getMediaType(url) {
      let fileExtension = url ? url.split('.').pop().toLowerCase() : null

      switch (fileExtension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return 'image'

        case 'pdf':
          return 'pdf'

        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'xls':
        case 'xlsx':
          return 'office'

        default:
          return fileExtension
      }
    },
    download() {
      // api.downloadBlob(this.url, this.fileName)
      window.open(this.url, '_blank')
    }
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true
    }, 100)
  },
  watch: {}
}
</script>

<style lang="scss"></style>
