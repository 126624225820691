<template>
  <div ref="observable" class="mt-1 shadow-sm item-view-mini">
    <div class="container-fluid" v-if="visible" :class="[{ 'bg-warning': itemComputed?.archived }, `item-id-${itemComputed?.item_id}`]">
      <b-row class="pt-1 pb-2" v-if="itemComputed">
        <b-col cols="2" class="px- ">
          <imageView :src="itemComputed.image" :filterDisplay="imgSize" />
        </b-col>
        <b-col class="ms-">
          <span> {{ itemComputed.title + itemComputed.titleSuffix }} </span>
          <b-button v-if="editable" variant="link" class="" size="sm" @click="setGlobalAction({ type: 'itemEdit', action: { item_id: itemComputed.item_id, variantId: itemComputed.item_distributor_id } })">
            <icon name="wisk-edit" scale=".6"></icon>
          </b-button>
        </b-col>
        <b-col :cols="slotBelow ? 12 : 6" class="ps-0">
          <slot></slot>
        </b-col>
      </b-row>
      <b-row v-else>
        <wiskLoading loading />
      </b-row>
    </div>

    <div v-else class="container-fluid" :style="{ height }"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { prepareVariantIdAsItem } from '@/modules/utils'
import imageView from '@/components/common/ImageView'

export default {
  name: 'ItemViewMini',
  components: { imageView },
  props: {
    item: Object,
    itemId: Number,
    variantId: Number,
    slotBelow: Boolean,
    preventDisconnectOnFirstLoad: Boolean,
    editable: { type: Boolean, default: true },
    imgSize: { type: String, default: 'thumb' }
  },
  data() {
    return {
      observer: null,
      visible: false,
      height: null
    }
  },
  computed: {
    ...mapState(['bottlesById']),
    itemComputed() {
      if (this.variantId) {
        return prepareVariantIdAsItem(this.variantId, this.$store.state)
      }
      if (this.itemId) {
        return this.bottlesById[this.itemId]
      }
      return this.item || null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onVisibilityChange(observerEntries) {
      this.visible = observerEntries[0].isIntersecting
      this.height = observerEntries[0].target.clientHeight + 'px'

      if (this.visible && !this.preventDisconnectOnFirstLoad) {
        this.observer.disconnect()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.observable) {
        this.observer = new IntersectionObserver(this.onVisibilityChange, {})
        this.observer.observe(this.$refs.observable)
      }
    }, 0)
  },
  beforeUnmount() {
    if (this.observer?.disconnect) {
      this.observer.disconnect()
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.item-view-mini {
  border-radius: 5px;
  min-height: 75px;

  &:nth-of-type(odd) {
    background-color: var(--bs-info);
  }
}
</style>
