export default {
  name: 'wisk-long-arrow-down',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M26.622,26.469l-7.964,8.062a1.664,1.664,0,0,1-2.316,0L8.378,26.469a1.611,1.611,0,0,1,0-2.283,1.631,1.631,0,0,1,2.3,0l5.213,5.261V1.619a1.619,1.619,0,0,1,3.238,0V29.447l5.2-5.261a1.631,1.631,0,0,1,2.3,0A1.611,1.611,0,0,1,26.622,26.469Z'
    }
  ]
}
