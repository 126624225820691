<template>
  <div>
    <wiskInputGroup style="padding: 0; display: flex;" @errorCountChanged="setValidState" :key="key"
      v-bind="$attrs">
      <wiskInput infoTooltipKey="d769672c-e1bb-4b79-b8f6-1cc9a0f4f142" autocompleteOpenAbove style="flex: 5; padding-right: 5px;" autofocus :modelValue="selectedBottle ? selectedBottle.title : ''"
        :label="translations.txtPurchaseOrdersAddWiskItem"
        :placeholder="autocompletePlaceHolder" autocomplete @autocompleteInput="onAutocompleteInput" @clear="onAutocompleteSelected(null)"
        @autocompleteSelected="onAutocompleteSelected" :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" :autocompleteItemExists="!!selectedBottle"
        autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" required />

      <wiskInput infoTooltipKey="ec0da93c-ff6e-4d7c-89a7-15c8023fdb40" style="flex: 3; padding-right: 5px;" :label="translations.txtGenericQuantity" required
        v-model="localValue.measurement.quantity" inputType="number" />

      <div class="d-none">
        <wiskInput infoTooltipKey="2890b49d-32c5-4bd7-a24b-9642f09486f6" label="should be hidden" required v-model="localValue.item_distributor_id" inputType="number" triggerInputOnLoad />
      </div>

      <wiskSelect infoTooltipKey="3fce90d5-1582-406b-9ec8-847cc1255ee2" style="flex: 3; padding-right: 5px;" :label="translations.txtGenericOrderFormat" :modelValue="localValue.measurement.type" @update:modelValue="formatChanged"
        :items="orderFormats" :multiselectOptions="multiselectOptionsOrderFormat" />

      <priceEditor :item="selectedBottle" :caseSize="(selectedBottle && selectedBottle.case_size) || 1" v-model="localValue.price" inline style="flex: 6; padding-right: 5px;" horizontal
        @valid="priceEditorValid = $event" />

      <b-button :disabled="!validComputed" style="flex: 2; padding: 0; height: 35px; height: 40px" size="sm" class="text-primary" variant="link" @click="emitAddOperation"
        v-tooltip="translations.txtGenericAdd" ref="buttonAdd">
        <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
        <icon style="margin-top: -10px;" name="wisk-check" :scale="1.2" />
      </b-button>
    </wiskInputGroup>
    <b-row>
      <b-col>
        <b-button v-if="newBottleVisible" variant="link" @click="createNewItem">
          {{ translations.txtVenueBottlesNotFoundCreate }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import merge from 'lodash.merge'
import { mapGetters, mapState, mapActions } from 'vuex'
import { objectFilter, prepareVariantAsItem } from '@/modules/utils'
import wiskInput from '@/components/common/WiskInput'
import wiskSelect from '@/components/common/WiskSelect'
import priceEditor from '@/components/bottles/ItemPrice'

const newItem = {
  item_id: 0,
  item_distributor_id: 0,
  measurement: {
    type: 'unit',
    quantity: 0
  },
  item_distributor_measurement: {},
  price: {}
}

export default {
  name: 'VenueTransferAddItem',
  emits: ['addItem', 'onlineCatalogButton'],
  components: { wiskSelect, wiskInput, priceEditor },
  props: { incommingItem: Object, venueTransferId: Number },
  data() {
    return {
      key: 0,
      valid: true,
      priceEditorValid: true,
      autocompleteItems: [],
      query: '',
      newBottleVisible: false,
      selectedBottle: null,
      localValue: { ...newItem },
      multiselectOptionsOrderFormat: {
        showLabels: false,
        allowEmpty: false,
        searchable: false,
        openDirection: 'top',
        placeholder: 'Order Format'
      }
    }
  },
  computed: {
    ...mapGetters(['orderFormats', 'currency']),
    ...mapState(['itemVariants', 'distributorsById', 'translations', 'distributorPricesById', 'venueTransfersById', 'itemVariantPricesById']),
    autocompletePlaceHolder() {
      return this.translations.txtGenericBottleSearchPlaceholder
    },
    validComputed() {
      return this.valid && this.priceEditorValid
    },
    venueTransfer() {
      if (this.venueTransferId && this.venueTransfersById) {
        return this.venueTransfersById[this.venueTransferId]
      }
      return null
    },
    itemIds() {
      let venueTransfer = this.venueTransfer,
        ids = []

      if (venueTransfer && venueTransfer.items) {
        ids = venueTransfer.items.map(i => i.item_distributor_id)
      }

      return ids
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    createNewItem() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          creationSource: { type: 'venue_transfer', id: this.venueTransferId },
          title: this.query,
          onChange: item => {
            this.onAutocompleteSelected(item)
          }
        }
      })
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    onAutocompleteInput(value) {
      this.query = value

      this.autocompleteItems = this.itemVariants
        .map(v => ({ ...prepareVariantAsItem(v, this.$store.state) }))
        .filter(b => !b.archived && b.variant && !b.variant.archived && objectFilter({ payload: b, query: value }) && !this.itemIds.find(id => b.item_distributor_id === id))

      this.newBottleVisible = !this.autocompleteItems.length
    },
    onAutocompleteSelected(bottle) {
      //TODO: make this not occupy this much space
      if (bottle) {
        this.selectedBottle = bottle
        this.localValue.item_id = bottle.item_id
        this.localValue.item_distributor_id = bottle.item_distributor_id
        this.localValue.measurement.type = bottle.order_format
        this.localValue.item_distributor_measurement = bottle.measurement

        let priceInfo = this.itemVariantPricesById[bottle.item_distributor_id] || { price: { type: 'unit', value: 0 } }
        this.localValue.price = priceInfo.price

        this.localValue.measurement.case_size = bottle.case_size

        this.localValue = merge({}, this.localValue)
        this.autocompleteItems = []
      } else {
        this.selectedBottle = null
        this.localValue.item_distributor_id = 0
        this.localValue.measurement.quantity = 0
        this.localValue.price.value = 0
        this.localValue.measurement.case_size = 0

        this.localValue = merge({}, this.localValue)
        this.autocompleteItems = []
      }
      this.$emit('onlineCatalogButton', false)
    },
    formatChanged(value) {
      this.localValue.measurement.type = value
      this.localValue = merge({}, this.localValue)
    },
    emitAddOperation() {
      this.$emit('addItem', merge({}, this.localValue))
      this.localValue = merge({}, newItem)
      this.selectedBottle = null
      this.key++
    }
  },
  watch: {}
}
</script>

<style></style>
