export default {
  name: 'wisk-zoom-in',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.7,32.1a1.6118,1.6118,0,0,0-.4-.7l-8.7-8.7a15.7155,15.7155,0,0,0,1.8-3.1,14.587,14.587,0,0,0,1.1-5.5,14.9852,14.9852,0,0,0-1.1-5.5,13.94,13.94,0,0,0-3.1-4.5A15.7035,15.7035,0,0,0,19.8,1a16.7524,16.7524,0,0,0-5.5-1A14.587,14.587,0,0,0,8.8,1.1,13.94,13.94,0,0,0,4.3,4.2a13.656,13.656,0,0,0-3,4.5A14.587,14.587,0,0,0,.2,14.2a14.9852,14.9852,0,0,0,1.1,5.5,14.77,14.77,0,0,0,3,4.5,15.7035,15.7035,0,0,0,4.5,3.1,14.3,14.3,0,0,0,11,0,9.1865,9.1865,0,0,0,2.7-1.6l8.7,8.7a1.7937,1.7937,0,0,0,.7.5,1.1284,1.1284,0,0,0,.8.1,1.6894,1.6894,0,0,0,1-.3,1.3874,1.3874,0,0,0,.7-.8,1.93,1.93,0,0,0,.3-1A1.1042,1.1042,0,0,0,34.7,32.1ZM24.5,18.4A8.7885,8.7885,0,0,1,22.1,22a12.111,12.111,0,0,1-3.5,2.4,10.7222,10.7222,0,0,1-8.6,0A12.111,12.111,0,0,1,6.5,22a11.3492,11.3492,0,0,1-2.4-3.6,10.7215,10.7215,0,0,1,0-8.6A11.5773,11.5773,0,0,1,10,3.9a10.7215,10.7215,0,0,1,8.6,0,12.111,12.111,0,0,1,3.5,2.4,12.111,12.111,0,0,1,2.4,3.5,10.6991,10.6991,0,0,1,.9,4.3A10.3564,10.3564,0,0,1,24.5,18.4Z'
    },
    { d: 'M21.7,14.4a1.3237,1.3237,0,0,1-1.3,1.3H15.7v4.7a1.3,1.3,0,0,1-2.6,0h0V15.7H8.3A1.3509,1.3509,0,0,1,8.2,13H13V8.4a1.3,1.3,0,1,1,2.6,0h0v4.7h4.7a1.3474,1.3474,0,0,1,1.4,1.3Z' }
  ]
}
