<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'posItemSearchDialog', action: null })" size="md" hideHeaderExtra class="" hideFooter :title="translations.txtPOSItemsSearch">
    <b-container fluid class="" style="min-height: 500px;">

      <p class="mb-4" v-if="editAction.message"> {{ editAction.message }} </p>

      <div class="mb-4 d-flex align-items-center">
        <span>{{ translations.txtPOSItemSearchBasedOn }}</span>
        <b-form-radio-group class="ms-2" size="sm" buttons v-model="searchBasedOn" button-variant="outline-primary">
          <b-form-radio value="pos"> {{ translations.txtPOSItemSearchPOSItems }} </b-form-radio>
          <b-form-radio value="draft"> {{ translations.txtPOSItemSearchDraftPOSItems }} </b-form-radio>
        </b-form-radio-group>
      </div>

      <wiskInput ref="autocomplete" infoTooltipKey="32d7bfbe-c876-420d-b1b4-54e1ca1e5be6" autofocus :label="translations.txtGenericTypeToSearch" @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected"
        :autocompleteMinChars="1" autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" autocomplete v-model="autocompleteValue"
        @clear="onAutocompleteSelected(null)" :autocompleteItemExists="!!selectedItem">

        <template v-slot:autocompleteItemTemplate="{ autocompleteItem }">
          <imageView :src="autocompleteItem.image" v-if="autocompleteItem.image" style="position: absolute; left: 10px; top: 10px;" filterDisplay="tinyThumb" />
          <div class="fill-width-height" :style="{ 'padding-left': autocompleteItem.image ? '40px' : '', 'min-height': autocompleteItem.image ? '40px' : '' }">
            <div class="fill-width-height">
              {{ autocompleteItem.title }} {{ autocompleteItem.is_draft_pos_item ? `(${translations.txtPOSItemDraftPOSItem})` : '' }}
            </div>
          </div>
        </template>
      </wiskInput>
    </b-container>

  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { objectFilter, guid } from '@/modules/utils'
import imageView from '@/components/common/ImageView'

export default {
  name: 'POSItemSearchDialog',
  components: { imageView },
  props: { editAction: Object },
  data() {
    return {
      valid: true,
      autocompleteValue: '',
      autocompleteItems: [],
      activeBottles: [],
      autocompleteInputTimeoutId: null,
      selectedItem: null,
      onDemandStateId: guid(),
      searchBasedOn: 'pos'
    }
  },
  computed: {
    ...mapState(['translations', 'posItems', 'draftPOSItems']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setOnDemandState']),
    onAutocompleteInput(value) {
      clearTimeout(this.autocompleteInputTimeoutId)
      this.autocompleteInputTimeoutId = setTimeout(() => {
        const allPOSItems = this.searchBasedOn === 'pos' ? this.posItems : this.draftPOSItems
        this.autocompleteValue = value
        this.autocompleteItems = allPOSItems.filter(b => this.filter(b, value)).map(p => ({ ...p, title: p.title + ` (${p.ingredients.length} ${this.translations.txtGenericIngredients.toLowerCase()})` }))
      }, 400)
    },
    filter(posItem, query) {
      if (this.editAction && this.editAction.customFilter) {
        return this.editAction.customFilter(posItem, query)
      }
      return objectFilter({ payload: posItem, query, stopAtLevel: 1 })
    },
    onAutocompleteSelected(item) {
      this.selectedItem = item

      setTimeout(() => {
        if (this.editAction && this.editAction.onChange) {
          this.editAction.onChange(item)
        }
        this.setGlobalAction({ type: 'posItemSearchDialog', action: null })
      }, 500)
    }
  },
  // created() {
  //   if (!this.posItems || (this.posItems && this.posItems.length === 0)) this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', payload: { all: true } })
  // },
  // beforeUnmount() {
  //   this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', REMOVE: true })
  // },
  watch: {
    searchBasedOn() {
      this.onAutocompleteInput(this.autocompleteValue)
      this.$refs.autocomplete.focus()
    }
  }
}
</script>

<style lang="scss"></style>
