// alternative names: wisk-understockanalysis
export default {
  name: 'wisk-overstock',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M33.8,18.061H30.508V5.11a1.12,1.12,0,0,0-1.179-1.142H21.543a1.111,1.111,0,0,0-1.2,1.2V7.917H13.719A1.138,1.138,0,0,0,12.446,9.19v1.74c0,.113-.018.225-.018.356h-6.5c-.954,0-1.385.431-1.385,1.385v5.372H1.16a1.161,1.161,0,0,0,0,2.321h.431a.889.889,0,0,0,.224-.019H4.548v7.711A1.14,1.14,0,0,0,5.8,29.329c2.546.019,5.11.019,7.655,0a1.107,1.107,0,0,0,1.235-1.235c.019-.955,0-1.909,0-2.883v-.355h5.653v4.96a1.121,1.121,0,0,0,1.216,1.216H29.31a1.139,1.139,0,0,0,1.2-1.2V20.345a1.219,1.219,0,0,1,.243-.019h3.07a1.134,1.134,0,1,0-.019-2.265Zm-21.374,9h-5.6V20.345h5.6Zm0-9.021h-5.6V13.57h5.6Zm2.3-7.843h5.6v7.843h-5.6Zm5.615,12.166c0,.262-.169.206-.3.206H14.73V20.345h5.615Zm7.88,6.4h-5.6V20.345h5.6Zm0-10.724h-5.6V6.233h5.6Z'
    }
  ]
}
