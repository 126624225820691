<template>
  <b-row v-if="currentViewers?.length">
    <b-col cols="12">
      <small>Viewing this draft invoice:</small>
    </b-col>
    <b-col>
      <b-badge v-for="item in currentViewers" :key="item.id" class="ms-1 px-1" :style="{ backgroundColor: `${getColorFromString(item.full_name || '')} !important` }">
        <small>{{ user.is_wisk_user || !item.is_wisk_user ? item.full_name : 'Wisk Team' }}</small>
      </b-badge>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import api from '@/api'
import { getColorFromString } from '@/modules/utils.js'

export default {
  name: 'DraftInvoiceEditCurrentViewers',
  props: {
    draftInvoiceId: Number,
  },
  data() {
    return {
      pingTimeoutId: null,
      currentViewers: []
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    getColorFromString,
    ping() {
      api.draftInvoicePing(this.draftInvoiceId).then(response => {
        let users = response?.active_users || []
        this.currentViewers = users.filter(u => u && u.user_id !== this.user.id && u.timestamp && new Date(u.timestamp) > DateTime.fromJSDate(new Date()).minus({ minutes: 1 }).toJSDate())
      })
    }
  },
  mounted() {
    if (this.draftInvoiceId) {
      this.ping()
      clearInterval(this.pingTimeoutId)
      this.pingTimeoutId = setInterval(() => {
        if (this.draftInvoiceId) {
          this.ping()
        }
      }, 15000)
    }
  },
  beforeUnmount() {
    clearInterval(this.pingTimeoutId)
  }
}
</script>

<style>

</style>
