export default {
  name: 'wisk-zoom-out',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.6825,32.1031a1.8069,1.8069,0,0,0-.4435-.68l-8.6763-8.7058a13.6,13.6,0,0,0,1.8183-3.0743,13.87,13.87,0,0,0,1.0934-5.4837A13.87,13.87,0,0,0,27.381,8.6759a14.3276,14.3276,0,0,0-3.06-4.5079,14.4973,14.4973,0,0,0-4.5081-3.0595A13.6912,13.6912,0,0,0,14.315,0,13.7728,13.7728,0,0,0,8.8315,1.1085a14.4892,14.4892,0,0,0-4.5081,3.06,14.1551,14.1551,0,0,0-3.06,4.5079A13.8653,13.8653,0,0,0,.17,14.1594a13.8649,13.8649,0,0,0,1.0937,5.4837,14.3164,14.3164,0,0,0,3.06,4.5079,14.4846,14.4846,0,0,0,4.5081,3.06,13.6693,13.6693,0,0,0,5.4835,1.1085,13.6912,13.6912,0,0,0,5.4982-1.1085,15.0378,15.0378,0,0,0,2.7346-1.552l8.7351,8.7353a2.1005,2.1005,0,0,0,.6946.4581,2.1436,2.1436,0,0,0,.813.1479,1.9731,1.9731,0,0,0,1.0494-.2955,1.7511,1.7511,0,0,0,.7094-.7539,1.9227,1.9227,0,0,0,.2807-1.0493A2.1811,2.1811,0,0,0,34.6825,32.1031ZM24.5429,18.446a11.4472,11.4472,0,0,1-2.3942,3.5619,11.5875,11.5875,0,0,1-3.5328,2.3946,10.7982,10.7982,0,0,1-4.3009.8571,10.7818,10.7818,0,0,1-4.2866-.8571,11.83,11.83,0,0,1-3.5472-2.3946A11.4552,11.4552,0,0,1,4.0866,18.446a10.9734,10.9734,0,0,1,0-8.5728A11.1588,11.1588,0,0,1,6.4812,6.3407a11.5544,11.5544,0,0,1,3.5472-2.3944,10.8382,10.8382,0,0,1,8.5875,0,11.3222,11.3222,0,0,1,3.5328,2.3944,11.151,11.151,0,0,1,2.3942,3.5325,10.447,10.447,0,0,1,.887,4.2862A10.4492,10.4492,0,0,1,24.5429,18.446Z'
    },
    { d: 'M21.7348,14.44a1.3249,1.3249,0,0,1-1.3305,1.33H8.314a1.3377,1.3377,0,1,1,0-2.6753h12.09A1.3376,1.3376,0,0,1,21.7348,14.44Z' }
  ]
}
