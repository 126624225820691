export default {
  name: 'wisk-hide',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.582,16.619a29.855,29.855,0,0,0-8.475-6.363l5.778-5.831a1.016,1.016,0,0,0-.014-1.445,1.028,1.028,0,0,0-1.444,0L24.105,9.371A18.856,18.856,0,0,0,18.831,8.1c-.449-.041-.912-.069-1.362-.069a17.568,17.568,0,0,0-6.063,1.131A28.954,28.954,0,0,0,.355,16.606a1.222,1.222,0,0,0,.068,1.785,29.844,29.844,0,0,0,8.285,6.267c.054.028.122.055.177.082L3.108,30.572a1.016,1.016,0,0,0,.013,1.444.988.988,0,0,0,.722.3,1.011,1.011,0,0,0,.723-.3l6.308-6.376a17.551,17.551,0,0,0,6.214,1.321h.054a18.454,18.454,0,0,0,6.526-1.131,29.243,29.243,0,0,0,10.942-7.4A1.22,1.22,0,0,0,34.582,16.619ZM10.4,23.214c-.259-.109-.5-.232-.763-.368a28.165,28.165,0,0,1-7.262-5.368A26.185,26.185,0,0,1,12.1,11.087a15.429,15.429,0,0,1,5.369-1.008c.395,0,.79.027,1.185.055a17.267,17.267,0,0,1,3.856.831l-1.635,1.662a5.58,5.58,0,0,0-3.147-.926,5.857,5.857,0,0,0-4.905,9.061Zm8.68-8.775-4.442,4.5a3.377,3.377,0,0,1,3.2-4.769A3.427,3.427,0,0,1,19.077,14.439Zm3.883,9.47a16.224,16.224,0,0,1-5.845,1.008,15,15,0,0,1-4.66-.872l1.785-1.8L16,20.476,20.63,15.8l1.758-1.785L24.582,11.8c.122.055.258.109.381.177a27.867,27.867,0,0,1,7.657,5.559A26.515,26.515,0,0,1,22.96,23.909Z'
    }
  ]
}
