<template>
  <wiskModal class="mobile-navigation-container" :visible="!!editAction" @hide="setGlobalAction({ type: 'mobileNavigation', action: null })" size="lg" hideFooter fullScreen hideHeaderExtra>

    <template v-slot:modal-title>
      <sidebarNavLink :item="{ id: 'venueSwitch', name: venue.title, action: { type: 'venueSwitch', action: { editAction: true } }, icon: 'wisk-switch-venue', className: 'venue-switch-link' }"
        @handleAction="handleAction" v-if="venue" style="z-index: 99999;" />
    </template>

    <b-container class="mt-5">
      <ul v-if="venue.subscription.status !== 'churned' || user.god_mode" class="sidebar-nav top">
        <li class="sidebar-nav-item" v-for="item in nav.top" :key="item.id" :class="[item.className, { 'has-active-child': getActiveClass(item) }]">
          <sidebarNavLink :item="item" @handleAction="expandItem(item.id)" v-if="item.dropdown" :icon="expandedItems[item.id] ? 'wisk-caret-up' : 'wisk-caret-down'" />
          <sidebarNavLink :item="item" @handleAction="handleAction" v-else />

          <ul v-if="item && item.children && item.children.length" class="sidebar-nav children children-link-common-selector">
            <li class="sidebar-nav-item" v-for="child in item.children" :key="child.id" :class="[child.className]">
              <sidebarNavLink :item="child" @handleAction="handleAction" />
            </li>
          </ul>
        </li>
      </ul>
      <div v-else class="sidebar-nav top text-center">
        <icon class="mb-2" name="wisk-lock"></icon>
        <p>{{ translations.txtChurnedVenueAccessRestricted }}</p>
        <router-link v-if="$route.name !== 'churned' || user.god_mode" to="churned">{{ translations.txtGenericMoreInfo }}</router-link>
      </div>

      <ul class="sidebar-nav bottom">
        <li class="sidebar-nav-item" v-for="item in nav.bottom" :key="item.id" :class="[item.className]">
          <sidebarNavLink :item="item" :bottom="true" @handleAction="handleAction" />
          <ul v-if="item && item.children && item.children.length" class="sidebar-nav bottom-children">
            <li class="sidebar-nav-item" v-for="child in item.children" :key="child.id" :class="[child.className]">
              <sidebarNavLink :item="child" @handleAction="handleAction" />
            </li>
          </ul>
        </li>
      </ul>
    </b-container>

  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import { checkUserViewPermissions } from '@/modules/utils'
import getNavItems from '@/nav'
import sidebarNavLink from './SidebarNavLink'

export default {
  name: 'MobileNavigation',
  components: { sidebarNavLink },
  props: { editAction: Object },
  data() {
    return {
      nav: {},
      expandedItems: {}
    }
  },
  computed: {
    ...mapState(['translations', 'pageViewPermissions', 'user', 'venue'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    expandItem(id) {
      this.expandedItems[id] = !this.expandedItems[id]
      Object.keys(this.expandedItems).forEach(key => {
        if (key !== id) {
          this.expandedItems[key] = false
        }
      })
      this.expandedItems = { ...this.expandedItems }
    },
    handleAction(action) {
      this.setGlobalAction(action)
    },
    getActiveClass(navItem) {
      const paths = (Array.isArray(navItem.children) ? navItem.children : []).map(c => c.url)
      return !!paths.find(path => this.$route.path.startsWith(path)) || this.expandedItems[navItem.id]
    }
  },
  watch: {
    $route: {
      handler() {
        this.setGlobalAction({ type: 'mobileNavigation', action: null })
      },
      deep: false
    },
    user: {
      handler() {
        if (this.user) {
          this.nav = checkUserViewPermissions(getNavItems(this.translations, merge({}, this.venue)), this.user, this.venue, this.pageViewPermissions)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.mobile-navigation-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 15;

  .venue-switch-link {
    margin-left: 30px;

    i {
      margin-right: 15px;
    }
  }

  ul.sidebar-nav.top {
    .sidebar-nav-item {
      .children {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-out;
        transition: padding-left 0.5s ease-out;
        padding-left: 15px;
      }

      .children-link-common-selector {
        &.active+.children {
          max-height: 1000px;
          transition: max-height 0.8s ease-in;
          transition: padding-left 0.8s ease-in;
        }
      }

      &.has-active-child {
        .children {
          max-height: 1000px;
          transition: max-height 0.8s ease-in;
          transition: padding-left 0.8s ease-in;
        }
      }
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;

    .bottom-children {
      position: absolute;
      bottom: 55px;
    }
  }

  .sidebar-nav-item {
    list-style-type: none;

    &:hover {
      width: 200px;
      overflow: visible;
      display: block;

      .nav-link {
        span {
          display: inline !important;
        }

        i {
          color: #fff;
        }
      }

      .bottom-children {
        .sidebar-nav-item {
          display: block;
          width: 200px;
          overflow: visible;

          i {
            color: #4078c7;
          }

          span {
            display: inline !important;
          }
        }
      }
    }

    .nav-link {
      width: 100%;
      padding: 0.75rem 1rem;
      text-decoration: none;
      background: transparent;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;

      span {
        display: inline;
      }

      &.active {
        font-weight: 700;

        i {
          color: #4078c7;
          transform: scale(1.2);
        }
      }

      &.has-extra {
        padding-bottom: 25px;

        .nav-link-extra {
          position: absolute;
          display: block;
          bottom: 5px;
          left: 50px;
          font-size: 10px;
          //text-align: center;
          width: 150px;
        }
      }

      &:hover:not(.has-children) {
        span {
          display: inline !important;
        }
      }

      i {
        display: inline-block;
        width: 20px;
        margin: 0 0.5rem 0 0;
        font-size: 14px;
        color: #536c79;
        text-align: center;
      }
    }

    .bottom-children {
      .sidebar-nav-item {
        display: none;
      }

      &:hover {
        .sidebar-nav-item {
          display: block;
          width: 200px;
          overflow: visible;

          span {
            display: inline !important;
          }
        }
      }
    }
  }

  .sidebar-nav-item {
    .nav-link {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
</style>
