<template>
  <fieldset class="">
    <legend class="" style=""> {{ translations.txtVenueBottlesVariantInventoriableAs }} </legend>

    <b-table-simple bordered small style="" class="m-0 text-capitalize">
      <thead>
        <tr>
          <th scope="row"> {{ translations.txtVenueBottlesVariant }} </th>
          <th scope="row"> {{ translations.txtGenericUnits }} </th>
          <th scope="row"> {{ translations.txtGenericCases }} </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="variant in itemVariants" :key="variant.id">
          <td> {{ variant.title }} </td>
          <td class="text-center" >
            <wiskInput infoTooltipKey="6f0a1843-ac11-4205-870c-6f4f48d374fc" class="d-inline-block mb-0 mt-1 p-0" style="min-height: 0;" :modelValue="variant.inventoriable" operation="inventoriable"
              @operation="onChange(variant.id, $event)" inputType="checkbox" triggerInputOnLoad />
          </td>
          <td class="text-center" >
            <wiskInput infoTooltipKey="2a63fec0-5bc1-4fb9-8fa7-166c1bf8614c" class="d-inline-block mb-0 mt-1 p-0" style="min-height: 0;" :modelValue="variant.inventoriable_as_cases"
              operation="inventoriable_as_cases" triggerInputOnLoad @operation="onChange(variant.id, $event)" inputType="checkbox" :disabled="variant.case_size < 2"
              v-tooltip="variant.case_size < 2 ? translations.txtVenueBottlesVariantInventoriableAsCasesDisabledReason : ''"/>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <wiskLoading :loading="loading" />
  </fieldset>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ItemInventoriable',
  components: {},
  props: { itemId: { type: Number, required: true } },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['bottlesById', 'itemVariantsById', 'translations']),
    ...mapGetters([]),
    item() {
      return this.bottlesById[this.itemId]
    },
    itemVariants() {
      if (this.item?.item_distributor_ids) {
        let variants = this.item.item_distributor_ids.map(variantId => this.itemVariantsById[variantId]),
          defaultVariant = variants.find(variant => variant.is_default)

        if (defaultVariant) {
          variants = variants.filter(variant => variant.id !== defaultVariant.id)
          variants.unshift(defaultVariant)
        }
        return variants
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setItemVariant']),
    onChange(id, operation) {
      this.loading = true
      this.setItemVariant({ id, operation }).finally(() => {
        this.loading = false
      })
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss">
</style>
