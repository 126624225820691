<template>
  <component :is="manage ? 'fieldset' : 'div'" :style="manage && 'position: relative; padding-left: 31px; padding-right: 31px;'" class="pb-2">
    <legend v-if="manage" class="mb-0 d-flex align-items-center justify-content-between" style="">
      {{ translations.txtPOSIntegrations }}
      <b-form-radio-group buttons v-model="posIntegrationsTab" button-variant="outline-primary" size="sm" style="z-index: 1" class="ms-3">
        <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
        <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
      </b-form-radio-group>
    </legend>

    <b-row v-if="posIntegrationsTab === 0">
      <b-col v-if="manage" cols="12" class="mb-3" style="margin-top: -25px;">
        <b-input-group class="justify-content-end align-items-center">
          <b-button size="sm" variant="link" class="me-2" @click="setGlobalAction({ type: 'POSEdit', action: {} })">
            {{ translations.txtPOSIntegrationAdd }}
          </b-button>
          <b-form-radio-group size="sm" v-model="showArchivedPOSIntegrations">
            <b-form-radio value="active"> {{ translations.txtPOSIntegrationActiveItems }} </b-form-radio>
            <b-form-radio value="archived"> {{ translations.txtPOSIntegrationInactiveItems }} </b-form-radio>
          </b-form-radio-group>
        </b-input-group>
      </b-col>
      <b-col cols="12" class="venue-settings-col">
        <b-table-simple bordered small>
          <thead>
          <tr>
            <th> {{ translations.txtGenericType }} </th>
            <th> {{ translations.txtGenericStatus }} </th>
            <th> {{ translations.txtGenericLastSalesCheck }} </th>
            <th> {{ translations.txtVenuesDaysSinceLastSaleImported }} </th>
            <th v-if="manage || sales"></th>
          </tr>
          </thead>
          <tbody>
          <posIntegrationRow v-for="pos in posIntegrations.filter(i => showArchivedPOSIntegrations === 'archived' ? i.is_archived : !i.is_archived)"
            :key="pos.id" :data="pos" :manage="manage" :sales="sales" @retry="retry" @clear="clear" @importSales="importSales" />
          </tbody>
        </b-table-simple>
      </b-col>
      <b-col v-if="user.god_mode" cols="12" class="venue-settings-col">
        <wiskInput infoTooltipKey="58b511a6-fe3e-4734-9cee-e23701b0989c" :label="translations.txtPOSIntegrationModifiersNew" :modelValue="venue.use_new_pos_modifiers" inputType="checkbox"
          operation="use_new_pos_modifiers" class="" @operation="$emit('operation', $event)" />
      </b-col>

    </b-row>
    <b-row v-else>
      <b-col cols="12" class="venue-settings-col">
        <timeline :filters="[{ type: 'pos_integration' }]" parentGridName="POSIntegrationTimeline" :gridStyle="{ height: '500px' }" />
      </b-col>
    </b-row>
    <confirm ref="confirmDialog" style="text-align: left" />
  </component>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import api from '@/api/index'
import timeline from '@/components/timeline/Timeline'
import posIntegrationRow from '@/components/venues/VenuePosIntegrationRow'

export default {
  name: 'VenuePOSIntegrations',
  components: { posIntegrationRow, timeline },
  emits: ['operation'],
  props: {
    manage: { type: Boolean, default: true },
    sales: { type: Boolean, default: false },
    parentConfirmRef: Object
  },
  data() {
    return {
      posIntegrationsTab: 0,
      showArchivedPOSIntegrations: 'active',
    }
  },
  computed: {
    ...mapState(['translations', 'posIntegrations', 'venue', 'user'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    retry(data) {
      api.updatePosIntegration(data.id, {
        operations: [{
          type: 'pos_integration_status',
          value: { type: 'retry' }
        }]
      })
    },
    clear(data) {
      this.$refs.confirmDialog.confirm({
        callback: () => {
          api.clearPreviousDataByPOSIntegration(data.id)
        },
        message: this.translations.txtPOSIntegrationClearDataConfirmation,
        title: this.translations.txtPOSIntegrationClearData
      })
    },
    importSales(data) {
      api.salesImport(data.id, false).then(() => {
        this.parentConfirmRef.confirm({
          callback: () => {},
          hideOK: true,
          message: this.translations.txtSalesRefreshImportMessage,
          title: this.translations.txtSalesRefreshImport
        })
      })
    }
  }
}
</script>
