export default {
  name: 'wisk-pdf',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M29.5307,6.6339l-.0983-.0984L23.5143.653,23.1332.2746A.943.943,0,0,0,22.4665,0H8.069A2.8671,2.8671,0,0,0,5.1933,2.8593V32.1421A2.8668,2.8668,0,0,0,8.069,35H26.931a2.8668,2.8668,0,0,0,2.8757-2.8579V7.2964A.9366.9366,0,0,0,29.5307,6.6339ZM23.1865,2.056l4.5068,4.48H24.334A1.1491,1.1491,0,0,1,23.1865,5.388Zm5.3948,30.0861a1.6434,1.6434,0,0,1-1.65,1.6325H8.069a1.6434,1.6434,0,0,1-1.65-1.6325V2.8593a1.6445,1.6445,0,0,1,1.65-1.6339H21.8805V5.388A2.4558,2.4558,0,0,0,24.334,7.84h4.2473Z'
    },
    {
      d: 'M12.157,22.26a1.9471,1.9471,0,0,1-1.3-.511,1.4747,1.4747,0,0,1-.4861-1.11A2.0963,2.0963,0,0,1,11.5325,18.98a7.39,7.39,0,0,1,1.8508-.7073l.176-.0493q.57-.1611,1.147-.2973c.3731-.7607.6974-1.5544,1.0165-2.3353l.0353-.0868c.1657-.4055.333-.815.4893-1.2284a8.2386,8.2386,0,0,1-1.286-2.8416,2.2134,2.2134,0,0,1,.6058-2.3352,1.6144,1.6144,0,0,1,2.2012.1033,2.662,2.662,0,0,1,.6,2.0977,10.89,10.89,0,0,1-.5995,2.7777c.0354.05.0707.0989.1062.1481h0c.3031.42.6237.8317.9812,1.2878.445.5678.8976,1.1455,1.379,1.6925.0713-.0026.1427-.005.2141-.0067a9.6929,9.6929,0,0,1,3.1519.2944,2.1618,2.1618,0,0,1,1.3447.9645,1.51,1.51,0,0,1,.0412,1.3106,1.6853,1.6853,0,0,1-1.0932.9444,3.3722,3.3722,0,0,1-2.9958-.96,12.516,12.516,0,0,1-1.2425-1.1546A25.7578,25.7578,0,0,0,15.63,19.12a11.8919,11.8919,0,0,1-1.2527,1.9043,3.2436,3.2436,0,0,1-2.0768,1.23Q12.2294,22.26,12.157,22.26Zm1.5908-2.6708a6.2591,6.2591,0,0,0-1.5114.5616c-.3379.2031-.4978.4267-.4995.5139a.1441.1441,0,0,0,.0539.0871.58.58,0,0,0,.3944.1409A1.8584,1.8584,0,0,0,13.32,20.16Q13.545,19.8839,13.7478,19.5893Zm7.853-1.0272q.0759.0637.1531.1257c.736.591,1.3357.8286,1.7815.7073a.3165.3165,0,0,0,.2-.1722.1356.1356,0,0,0,.001-.13c-.0292-.0558-.1386-.1684-.5431-.295A6.07,6.07,0,0,0,21.6008,18.5621Zm-4.4064-2.9628q-.0862.2136-.1715.4215l-.0355.0867c-.2008.4916-.4037.988-.62,1.4811q1.0782-.1845,2.1675-.2815c-.2564-.3163-.5062-.6352-.7537-.951C17.5756,16.0935,17.3813,15.8448,17.1944,15.5993Zm-.572-5.5206a.3005.3005,0,0,0-.1861.0741c-.2556.211-.1955.7209-.1368,1.006a5.4708,5.4708,0,0,0,.5011,1.37,7.9,7.9,0,0,0,.2051-1.3169,1.3792,1.3792,0,0,0-.2175-1.0582A.2266.2266,0,0,0,16.6224,10.0787Z'
    },
    {
      d: 'M11.5912,24.94h2.2682a1.8731,1.8731,0,0,1,1.2961.4161,1.7047,1.7047,0,0,1,0,2.3381,1.8628,1.8628,0,0,1-1.2961.42H12.5412v2.0178h-.95Zm2.15,2.4449q.9795,0,.9794-.8543a.8062.8062,0,0,0-.2429-.6443,1.1032,1.1032,0,0,0-.7365-.21h-1.2v1.7085Z'
    },
    {
      d: 'M16.01,24.94h1.9147a2.7252,2.7252,0,0,1,1.9774.6812,2.5368,2.5368,0,0,1,.7032,1.9109,2.55,2.55,0,0,1-.7032,1.9148,2.7161,2.7161,0,0,1-1.9774.6849H16.01Zm1.8558,4.4185a1.5982,1.5982,0,0,0,1.79-1.8264,1.5949,1.5949,0,0,0-1.79-1.8189H16.96v3.6453Z'
    },
    { d: 'M21.1206,30.1321V24.94h3.4023v.7584h-2.467v1.414h2.32v.7585h-2.32v2.2609Z' }
  ]
}
