<template>
  <wiskInputGroup @errorCountChanged="setValidState" v-if="localMapping" class="px-0">
    <b-row class="gl-account-mapping-wrapper">
      <b-col class="pe-1" cols="7">
        <wiskSelect v-if="accountingAccounts && accountingAccounts.length" :items="accountingAccounts" trackBy="id" required
          :label="translations.txtAccountingAccount" @change="onChange(localMapping, localMapping.id)" infoTooltipKey="d927dccd-14a0-4f3d-9333-88f2122271f9"
          v-model="localMapping.accounting_account_id" class="accounting-account-selector" fixedWrapper />
      </b-col>
      <b-col class="px-1" cols="4">
        <wiskInput infoTooltipKey="glAccountAccountingMappingMaxPercent" :infoTooltipParams="{ '{a}': round(availablePercentage * 100) }" :label="translations.txtGenericPercentage" required :decimals="2" :max="availablePercentage * 100"
          v-model="localMapping.percent" @change="onChange(localMapping, localMapping.id)" inputType="number" :transformerOut="percentTransformerOut" :transformerIn="percentTransformerIn"
          suffix="%" :helperText="glAccount.accounting_mappings.length > 1 ? translations.translate('tplGLAccountAccountingMappingMaxPercentWarning', { '{a}': round(availablePercentage * 100) }) : ''" />
      </b-col>
      <b-col class="ps-1 bt-add" cols="1" v-if="!localMapping.id">
        <b-button :disabled="!valid" size="sm" class="text-success" variant="link" @click="onChange(localMapping, true)" v-tooltip="translations.txtGenericAdd">
          <icon class="" name="wisk-check" :scale="0.8"></icon>
        </b-button>
      </b-col>
      <b-col class="ps-1 bt-delete" cols="1" v-if="localMapping.id">
        <b-button size="sm" class="text-danger" variant="link" @click="remove" v-tooltip="translations.txtGenericRemove">
          <icon class="" name="wisk-trash" :scale="0.8"></icon>
        </b-button>
      </b-col>
    </b-row>
  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { round } from '@/modules/utils'

export default {
  name: 'GLAccountMapping',
  emits: ['change'],
  components: {},
  props: { glAccount: Object, mapping: Object, accountingAccounts: Array },
  data() {
    return {
      round,
      valid: true,
      saving: false,
      localMapping: { id: 0, percent: 0, accounting_account_id: 0 }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    availablePercentage() {
      if (this.glAccount && this.glAccount.accounting_mappings && this.glAccount.accounting_mappings.length) {
        return round(1 - this.glAccount.accounting_mappings
          .filter(z => z.id !== this.localMapping.id)
          .map(z => z.percent || 0).reduce((a, b) => a + b, 0), 2)
      }
      return 1
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    percentTransformerIn(z) {
      return round(z * 100)
    },
    percentTransformerOut(z) {
      return round(z / 100, 2)
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    remove() {
      this.onChange({ ...this.localMapping, REMOVE: true }, this.localMapping.id)
    },
    onChange(localMapping, canSave) {
      console.log('onChange', localMapping)
      if (canSave && !this.saving && this.valid) {
        this.saving = true
        this.$emit('change', localMapping)

        setTimeout(() => {
          this.saving = false
        }, 1000)
      }
    }
  },
  created() { },
  watch: {
    mapping: {
      immediate: true,
      deep: true,
      handler() {
        this.localMapping = { ...this.mapping }
      }
    }
  }
}
</script>

<style lang="scss">
.gl-account-mapping-wrapper {
  .bt-delete {
    opacity: 0;
  }

  &:hover {
    .bt-delete {
      opacity: 1;
    }
  }
}
</style>
