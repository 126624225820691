// alternative names: wisk-depletions, wisk-transfers
export default {
  name: 'wisk-invoices',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M27.9,0l-2.5,3.2L22.9,0h-2.9l-2.5,3.2L15.1,0h-2.8L9.7,3.2L7.3,0H4.2v35h3.2l2.4-3.1l2.4,3.1h3l2.4-3.1L20,35H23l2.4-3.1 l2.4,3.1h3V0L27.9,0z M29.4,33.6h-0.8L26.6,31c-0.3-0.4-0.7-0.6-1.2-0.6h0c-0.5,0-0.9,0.2-1.1,0.6l-1.9,2.5h-1.6L18.8,31 c-0.5-0.6-1.5-0.7-2.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-1.9,2.5h-1.6l-1.9-2.5c-0.3-0.4-0.7-0.6-1.1-0.6h0c-0.5,0-0.9,0.2-1.2,0.6 l-1.9,2.5h-1V1.5h0.9l2,2.7c0.3,0.4,0.7,0.6,1.2,0.6h0c0.5,0,0.9-0.2,1.1-0.6l2-2.7h1.4l2,2.7c0.5,0.6,1.5,0.7,2.2,0.2 c0.1-0.1,0.1-0.1,0.2-0.2l2-2.7h1.4l2,2.6c0.3,0.4,0.7,0.6,1.2,0.6h0c0.5,0,0.9-0.2,1.2-0.6l2-2.7h0.7L29.4,33.6z'
    },
    { d: 'M10.2,11h14.6c0.7,0,1.3,0.6,1.3,1.3l0,0c0,0.7-0.6,1.3-1.3,1.3H10.2c-0.7,0-1.3-0.6-1.3-1.3l0,0C8.9,11.6,9.5,11,10.2,11z ' },
    { d: 'M10.2,15.8h14.6c0.7,0,1.3,0.6,1.3,1.3l0,0c0,0.7-0.6,1.3-1.3,1.3H10.2c-0.7,0-1.3-0.6-1.3-1.3l0,0 C8.9,16.4,9.5,15.8,10.2,15.8z' },
    { d: 'M10.2,20.6h14.6c0.7,0,1.3,0.6,1.3,1.3l0,0c0,0.7-0.6,1.3-1.3,1.3H10.2c-0.7,0-1.3-0.6-1.3-1.3l0,0 C8.9,21.2,9.5,20.6,10.2,20.6z' }
  ]
}
