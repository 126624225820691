export default {
  name: 'wisk-scissors',
  width: 500,
  height: 500,
  paths: [
    {
      d: 'M356.038,349.44c-2.427,0-4.824,0.128-7.199,0.338l-27.862-48.259l-43.318,75.029l7.923,13.724    c-6.87,11.923-10.824,25.729-10.824,40.45c0,44.818,36.462,81.28,81.28,81.28c44.818,0,81.28-36.462,81.28-81.28    C437.318,385.904,400.855,349.44,356.038,349.44z M356.038,474.486c-24.133,0-43.766-19.633-43.766-43.766    c0-24.133,19.633-43.766,43.766-43.766c24.133,0,43.766,19.633,43.766,43.766C399.804,454.853,380.169,474.486,356.038,474.486z'
    },
    {
      d: 'M365.106,0L163.162,349.778c-2.373-0.21-4.771-0.338-7.199-0.338c-44.818,0-81.28,36.462-81.28,81.28    c0,44.818,36.462,81.28,81.28,81.28c44.818,0,81.28-36.462,81.28-81.28c0-14.719-3.954-28.527-10.824-40.449l166.15-287.781    C413.288,66.606,400.991,20.718,365.106,0z M155.964,474.486c-24.133,0-43.766-19.633-43.766-43.766    c0-24.133,19.633-43.766,43.766-43.766s43.766,19.633,43.766,43.766C199.73,454.853,180.096,474.486,155.964,474.486z'
    },
    {
      d: 'M146.896,0c-35.886,20.718-48.183,66.606-27.464,102.49l71.593,124.002l43.318-75.03L146.896,0z'
    }
  ]
}
