<template>
  <b-card class="mb-2 wisk-grid-filter-info" no-body>
    <b-card-body class="py-2 px-1">
      <b-row style="">
        <b-col cols="12" class="">
          <wiskSelect class="mb-0" size="sm" showPlainText realPlainText readonly disabled :items="columns" :modelValue="selectedColumn" @change="selectColumn" trackBy="colId" :label="translations.txtGenericField" />

          <wiskSelect class="mb-0" size="sm" showPlainText realPlainText readonly disabled :items="filterOperations" :modelValue="selectedFilterOperation" @change="selectOperation" :label="translations.txtFilterBy"
            :emptyListMessage="translations.txtWiskGridFilterEmptyOperationMessage" />

          <genericInput class="mb-0" componentClass="mb-0" disabled v-model="filterValue" :inputType="inputType" triggerInputOnLoad :key="inputType" size="sm" showPlainText realPlainText readonly @change="onDateRangeValueChange"
            :multiselectOptions="multiselectOptions" :decimals="2" :multiple="inputType === 'wiskSelect'" :items="listItems" :allowAddNewItem="false" initialShortcut="currentYear" :min="0" :max="1000"
            :trackBy="trackBy" v-show="selectedFilterOperation && !selectedFilterOperation.hideInput" decimalsAsNeeded showZero :label="translations.txtWiskGridFilterOperationValue" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'WiskGridFilterInfo',
  components: {},
  props: { filter: Object, columns: Array },
  data() {
    return {
      selectedFilterOperation: null,
      selectedColumn: null,
      filterValue: null,
      inputType: 'text',
      filterOperations: [],
      multiselectOptions: {},
      listItems: [],
      trackBy: null,
      unMounted: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    operationTypes() {
      return {
        text: [
          { id: 'contains', title: this.translations.txtWiskFilterContains },
          { id: 'notContains', title: this.translations.txtWiskFilterNotContains },
          { id: 'isEmpty', title: this.translations.txtWiskFilterIsEmpty, hideInput: true },
          { id: 'isNotEmpty', title: this.translations.txtWiskFilterIsNotEmpty, hideInput: true }
        ],
        number: [
          { id: 'hasValue', title: this.translations.txtWiskFilterHasValue, hideInput: true },
          { id: 'notSet', title: this.translations.txtWiskFilterNotSet, hideInput: true },
          { id: 'equals', title: this.translations.txtWiskFilterEquals },
          { id: 'notEqual', title: this.translations.txtWiskFilterNotEquals },
          { id: 'lessThan', title: this.translations.txtWiskFilterLessThan },
          { id: 'lessThanOrEqual', title: this.translations.txtWiskFilterLessThanOrEqual },
          { id: 'greaterThan', title: this.translations.txtWiskFilterGreaterThan },
          { id: 'greaterThanOrEqual', title: this.translations.txtWiskFilterGreaterThanOrEqual },
          { id: 'inRange', title: this.translations.txtWiskFilterInRange },
        ],
        date: [
          { id: 'range', title: this.translations.txtGenericDateRange },
          { id: 'equals', title: this.translations.txtWiskFilterEqualsDate },
          { id: 'notEqual', title: this.translations.txtWiskFilterNotEqualsDate },
          { id: 'lessThan', title: this.translations.txtWiskFilterLessThanDate },
          { id: 'greaterThan', title: this.translations.txtWiskFilterGreaterThanDate },
        ],
        bool: [{ id: 'equals', title: this.translations.txtWiskFilterEquals }],
        list: [
          { id: 'inList', title: this.translations.txtWiskFilterIsOneOf },
          { id: 'notInList', title: this.translations.txtWiskFilterIsNotOneOf },
          { id: 'isEmpty', title: this.translations.txtWiskFilterIsEmpty, hideInput: true },
          { id: 'isNotEmpty', title: this.translations.txtWiskFilterIsNotEmpty, hideInput: true }
        ]
      }
    },
    columnsComputed() {
      if (this.columns?.length) {
        return this.columns.filter(c => c.dataType && c.colId).map(c => ({ ...c }))
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    selectColumn(column) {
      this.selectedColumn = column
    },
    selectOperation(operation) {
      this.selectedFilterOperation = { ...operation }
    },
    onDateRangeValueChange(value) {
      if (this.selectedFilterOperation?.id === 'range') {
        this.filterValue = value
      }
    },
    initFilterInfo() {
      if (this.filter && !this.unMounted) {
        let copy = cloneDeep(this.filter)

        if (this.filter.colId && (!this.selectedColumn || this.selectedColumn.colId !== this.filter.colId)) {
          this.selectedColumn = this.columns.find(c => c.colId === this.filter.colId)
        }

        setTimeout(() => {
          if (this.filterOperations) {
            this.selectedFilterOperation = this.filterOperations.find(o => o.id === this.filter.operation) || {}
          }
          this.filterValue = copy.filterValue
        }, 10)
      }
    },
    initFilterComponents() {
      let selectedColumn = this.selectedColumn

      if (selectedColumn) {
        this.filterValue = null
        this.trackBy = null
        this.selectedFilterOperation = null
        this.multiselectOptions = {}
        this.listItems = []
        this.filterOperations = []

        if (selectedColumn?.dataType && this.operationTypes[selectedColumn.dataType] && this.operationTypes[selectedColumn.dataType][0]) {
          this.filterOperations = [...this.operationTypes[selectedColumn.dataType]]
          this.selectedFilterOperation = { ...this.operationTypes[selectedColumn.dataType][0] }
        }
        this.inputType = 'text'

        if (this.selectedColumn) {
          this.inputType = this.selectedColumn.dataType || 'text'

          if (this.selectedColumn.dataType === 'bool') {
            this.filterValue = false
            this.inputType = 'checkbox'
          }

          if (this.selectedColumn.dataType === 'number') {
            this.filterValue = 0
          }

          if (this.selectedColumn.dataType === 'list') {
            this.filterValue = []
            this.inputType = 'wiskSelect'
            if (this.selectedColumn.colDef) {
              let params = this.selectedColumn.colDef.cellRendererParams || this.selectedColumn.colDef.cellEditorParams

              if (params) {
                this.trackBy = params.trackBy

                if (params.getFilterItems) {
                  this.listItems = params.getFilterItems()
                } else if (params.getItems) {
                  this.listItems = params.getItems()
                } else if (params.items) {
                  this.listItems = params.items
                } else {
                  this.filterOperations = this.filterOperations.filter(o => !o.hideIfNoItems)
                  this.selectedFilterOperation = this.filterOperations[0]
                }
                this.multiselectOptions = params.multiselectOptionsFilter || params.multiselectOptions || {}
              }
            }
          }

          if (this.selectedColumn.dataType === 'date' && this.selectedFilterOperation?.id !== 'range') {
            this.inputType = 'datepicker'
          }
        }
      }
    }
  },
  mounted() {
    this.initFilterInfo()
  },
  beforeUnmount() {
    this.unMounted = true
  },
  watch: {
    selectedColumn: 'initFilterComponents',
    selectedFilterOperation(selectedFilterOperation, previous) {
      if (selectedFilterOperation?.id === 'range') {
        this.inputType = 'dateRange'
      } else if (this.selectedColumn?.dataType === 'date' && selectedFilterOperation?.id !== 'range') {
        this.inputType = 'datepicker'
      } else if (previous?.id === 'range' && selectedFilterOperation?.id !== 'range') {
        this.initFilterComponents()
      } else if (selectedFilterOperation?.id === 'inRange') {
        this.inputType = 'numberRange'
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-grid-filter {
  position: relative;
  background-color: #ebebeb;
  border-radius: 3.7785px;
  box-shadow: none;
  border: none;

  .wisk-grid-filter-remove {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
    background-color: transparent !important;
  }

  .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }

  .date-range-selector.has-shortcuts .custom-date-range-formatted {
    font-size: 75%;
  }

  .date-range-selector.has-shortcuts.custom-range .custom-date-range-formatted {
    font-size: 66%;
  }

  .btn.custom-range-edit {
    width: 20px !important;
    height: 20px !important;

  }
}
</style>
