<template>
  <wiskInputGroup @errorCountChanged="setValidState" v-if="localTax" class="px-0">
    <b-row class="tax-child-wrapper">
      <b-col class="pe-1" cols="4">
        <wiskInput infoTooltipKey="a4f2cf9c-44eb-461f-b6f9-3d76f0b76361" :label="translations.txtTaxesTitle" required
          v-model="localTax.title" @blur="onChange(localTax, localTax.id)" />
      </b-col>
      <b-col class="px-1" cols="4">
        <wiskInput infoTooltipKey="613b309f-6121-4e10-9325-606b1e015863" :label="translations.txtTaxesRate" required :decimals="3" :minDecimals="2" decimalsAsNeeded
          v-model="localTax.rate" @blur="onChange(localTax, localTax.id)" inputType="number" :transformerOut="z => z / 100" :transformerIn="z => z * 100" suffix="%" />
      </b-col>
      <b-col class="px-1" cols="3">
        <wiskSelect infoTooltipKey="acd173b2-4291-4966-b394-0c439e44e2c9" :label="translations.txtGenericPriority" :items="priorities" selectLabel="&nbsp;"
          v-model="localTax.priority" @change="onChange(localTax, localTax.id)" required fixedWrapper />
      </b-col>
      <b-col class="ps-1 bt-add" cols="1" v-if="!localTax.id">
        <b-button :disabled="!valid" size="sm" class="text-success" variant="link" @click="onChange(localTax, true)" v-tooltip="translations.txtGenericAdd">
          <icon class="" name="wisk-check" :scale="0.8"></icon>
        </b-button>
      </b-col>
      <b-col class="ps-1 bt-delete" cols="1" v-if="localTax.id">
        <b-button size="sm" class="text-danger" variant="link" @click="onChange({ ...localTax, REMOVE: true }, localTax.id)" v-tooltip="translations.txtGenericRemove">
          <icon class="" name="wisk-trash" :scale="0.8"></icon>
        </b-button>
      </b-col>
    </b-row>
  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { prepareItemsForWiskInput } from '@/modules/utils'

export default {
  name: 'TaxChild',
  emits: ['change'],
  components: {},
  props: { tax: Object, parentTax: Object },
  data() {
    return {
      valid: true,
      saving: false,
      localTax: { title: '', rate: 0, priority: 1 }
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    priorities() {
      if (this.parentTax && this.parentTax.compound_taxes && this.parentTax.compound_taxes.length) {
        return prepareItemsForWiskInput(Array(this.parentTax.compound_taxes.length).fill(1).map((x, y) => x + y))
      }
      return []
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onChange(localTax, canSave) {
      if (!this.saving && this.valid && canSave) {
        this.saving = true
        this.$emit('change', localTax)

        setTimeout(() => {
          this.saving = false
        }, 1000)
      }
    }
  },
  created() { },
  watch: {
    tax: {
      immediate: true,
      deep: true,
      handler() {
        this.localTax = { ...this.tax }
      }
    }
  }
}
</script>

<style lang="scss">
.tax-child-wrapper {
  .bt-delete {
    opacity: 0;
  }

  &:hover {
    .bt-delete {
      opacity: 1;
    }
  }
}
</style>
