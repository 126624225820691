<template>
  <tr>
    <td>
      {{ `${data.type} - ${data.title}` }}
    </td>
    <td class="d-flex align-items-center">
      {{ data.status.type }}
      <icon v-if="data.status.type === 'called_off' && data.status.message" name="wisk-information-button" class="text-primary ms-1" v-tooltip="data.status.message" :scale="0.7" />
    </td>
    <td :title="lastSalesCheck.formatted">
      {{ lastSalesCheck.relative }}
    </td>
    <td :title="lastImportedSale.formatted">
      {{ lastImportedSale.relative }}
    </td>
    <td v-if="manage" style="border-left: 0;">
      <b-button v-if="['expired', 'failed', 'called_off'].includes(data.status.type) && !data.is_archived" type="button" variant="link" class="position-relative p-1" size="sm" @click="$emit('retry', data)" :disabled="loading">
        <wiskLoading v-if="loading" loading />
        <icon name="wisk-redo" scale=".7"></icon>&nbsp;
        {{ translations.txtGenericRetry }}
      </b-button>
      <b-button v-if="user.god_mode" type="button" variant="link" class="position-relative p-1" size="sm" @click="$emit('clear', data)">
        Clear data
      </b-button>
      <b-button type="button" variant="link" class="p-1 ms-1" size="sm" @click="openEdit">
        <icon name="wisk-edit" scale=".7"></icon>&nbsp;
        {{ translations.txtGenericEdit }}
      </b-button>
      <b-button v-if="data.console_log_url && user.god_mode" type="button" variant="link" class="p-1 ms-1" size="sm" :href="data.console_log_url" target="_blank">
        <icon name="wisk-new-tab" scale=".7"></icon>&nbsp;
        {{ translations.txtPOSIntegrationLogs }}
      </b-button>
    </td>
    <td v-else-if="sales" style="border-left: 0;">
      <b-button type="button" variant="link" class="position-relative p-1" size="sm" @click="$emit('importSales', data)">
        <icon name="wisk-sync" scale="0.7"></icon>
        {{ translations.txtGenericImportedNow }}
      </b-button>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import { formatDate } from '@/modules/utils'

// possible pos integration statuses
// initializing
// healthy
// expired
// failed
// called_off
// retry

export default {
  name: 'VenuePosIntegrationRow',
  emits: ['importSales', 'retry', 'clear'],
  components: {},
  props: {
    data: Object,
    manage: { type: Boolean, default: true },
    sales: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations', 'user']),
    lastSalesCheck() {
      return {
        relative: DateTime.fromISO(this.data.last_import_try).toRelative() || '-',
        formatted: formatDate(this.data.last_import_try) || '-'
      }
    },
    lastImportedSale() {
      return {
        relative: DateTime.fromISO(this.data.last_imported_date).toRelative() || '-',
        formatted: formatDate(this.data.last_imported_date) || '-'
      }
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    formatDate,
    //called from outside
    openEdit() {
      this.setGlobalAction({
        type: 'POSEdit',
        action: {
          id: this.data.id,
          data: this.data,
          onChange: () => { }
        }
      })
    },
  }
}
</script>

<style lang="scss"></style>
