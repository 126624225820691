<template>
  <div class="fill-width-height wisk-time-picker material-design-input has-value">
    <div class="form-control">
      <datePicker v-if="localDate" ref="datePicker" :modelValue="localDate" hide-time-header @update:modelValue="onInput" :popover="popoverSettings" v-bind="options" class="w-100 p-2 wisk-datepicker" :timezone="timezone" />
    </div>

    <label> {{ label }} </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
// import datePicker from 'v-calendar/lib/components/date-picker.umd'
import { DatePicker as datePicker } from 'v-calendar'

export default {
  name: 'TimePicker',
  emits: ['input'],
  components: { datePicker },
  props: {
    className: String,
    inputClass: String,
    disabled: Boolean,
    value: String,
    label: String,
    prefix: String,
    suffix: String,
    timePickerOptions: Object
  },
  data() {
    return {
      popoverSettings: { placement: 'bottom', visibility: 'focus', positionFixed: true },
      localValue: '',
      localDate: null
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'user']),
    hoursComputed() {
      if (this.localValue) {
        let split = this.localValue.split(':') || [],
          hours = parseInt(split[0], 10) || 0

        return hours
      }
      return 0
    },
    minutesComputed() {
      if (this.localValue) {
        let split = this.localValue.split(':') || [],
          minutes = parseInt(split[1], 10) || 0

        return minutes
      }
      return 0
    },
    options() {
      return { disabled: this.disabled, rules: { minutes: { interval: 5 } }, mode: 'time', is24hr: true, ...this.timePickerOptions }
    },
    timezone() {
      if (this.venue.timezone && (this.user?.is_wisk_user || this.user?.god_mode)) {
        return this.venue.timezone
      }
      return undefined
    }
  },
  methods: {
    onInput(date) {
      if (!this.disabled) {
        this.localDate = date
        this.localValue = DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE)

        this.$emit('input', this.localValue)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (
        this.$refs.datePicker &&
        this.$refs.datePicker.$el &&
        (this.disabled || (this.timePickerOptions && (this.timePickerOptions.hoursDisabled || this.timePickerOptions.minutesDisabled)))
      ) {
        let dropdowns = this.$refs.datePicker.$el.querySelectorAll('select') || []

        if (dropdowns[0]) {
          dropdowns[0].disabled = (this.timePickerOptions && this.timePickerOptions.hoursDisabled) || this.disabled
        }

        if (dropdowns[1]) {
          dropdowns[1].disabled = (this.timePickerOptions && this.timePickerOptions.minutesDisabled) || this.disabled
        }
      }
    }, 0)
  },
  watch: {
    value: {
      handler(value) {
        let hours = 0,
          minutes = 0

        if (value) {
          if (value.indexOf(':') > 0) {
            let arr = value.split(':')

            hours = parseInt(arr[0], 10) || 0
            minutes = parseInt(arr[1], 10) || 0

            this.localValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
          } else {
            hours = parseInt(value || 0, 10) || 0
            this.localValue = `${hours.toString().padStart(2, '0')}:00`
          }
        }

        let localDate = DateTime.fromJSDate(new Date()).startOf('day').plus({ hours, minutes }).toJSDate()
        if (!this.localDate || (this.localDate.toJSON() !== localDate.toJSON())) {
          this.localDate = localDate
        }
      },
      immediate: false
    }
  }
}
</script>

<style lang="scss">
.wisk-time-picker {
  max-width: 180px;

  .vc-date {
    display: none !important;
  }

  .form-control {
    padding: 0 !important;

    .vc-container {
      box-sizing: content-box !important;
      padding: 0 !important;
      border: none !important;
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
    }
  }

  .vc-time-picker {
    padding: 0 !important;
    padding-left: 5px !important;
  }
}

.wisk-input {
  .wisk-time-picker {
    max-width: 100%;

    .multiselect {
      width: 100%;
    }
  }
}
</style>
