<template>
  <wiskModal :visible="!!editAction" :extraLarge="totalItemsCount > 10" :size="totalItemsCount || hasDraftInvoice ? 'lg' : 'md'" @ok="save"
    :okText="translations.txtGenericSubmit" @hide="setGlobalAction({ type: 'pdfSplitEdit', action: null })" :okDisabled="okDisabled" preventOKClose>
    <template v-slot:modal-title>
      <h5 class="modal-title"> {{ hasDraftInvoice ? translations.txtScannedInvoicesPDFSplit : translations.txtScannedInvoicesUpload }} </h5>
      <h6 v-if="draftInvoice"> {{ translations.translate('tplScannedInvoicesEdit', { '{a}': formatDate(draftInvoice.insert_date) }) }}</h6>
    </template>

    <template v-slot:modal-header-extra>
      <b-input-group v-if="hasChange && hasDraftInvoice" class="d-inline-block" style="max-width: 150px;">
        <b-button variant="outline-primary" size="sm" @click="reset(!!draftInvoiceId)"> {{ translations.txtGenericReset }} </b-button>

        <template #append v-if="fileURLs?.length && hasDraftInvoice && !draftInvoiceId">
          <b-dropdown variant="outline-primary" size="sm" right class="input-group-append" toggleClass="" style="border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;">
            <b-dropdown-item @click="reset(true)"> {{ translations.txtScannedInvoicesPDFSplitReset }} </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-input-group>

      <infoTooltip v-if="hasDraftInvoice" helpKey="scannedInvoicesPDFManagerMessage" :scale="1.2" class="float-end" />
      <infoTooltip v-if="!hasDraftInvoice && totalItemsCount" helpKey="scannedInvoicesUploadMessage" :scale="1.2" class="float-end" />

      <a href="https://help.wisk.ai/en/articles/5018215-scanning-uploading-invoices#h_1e865c5400" target="_blank" class="me-1 mx-md-3 me-md-0 me-md-2 float-end" v-tooltip="translations.txtMenuHelpCenter">
        <icon name="wisk-help-page" />
        <span class="ms-1 d-none d-sm-inline">{{ translations.txtMenuHelpPage }}</span>
      </a>
    </template>

    <b-container class="pdf-split-editor" :style="{ height: noDraftInvoiceNoItems ? '400px' : '', cursor: noDraftInvoiceNoItems ? 'pointer' : 'default' }"
      :key="key" @dragleave="onDragLeave" @dragenter="onDragEnter" @drop="draggingFilesForUpload = false" @dragover.prevent>
      <draggableList v-for="list in lists" :key="list.id" :list="list" :listCount="lists.length" @split="splitListAtIndex" @updateList="updateList" :distributorChangeDisabled="!!draftInvoiceId"/>

      <b-row v-if="!loading && noList && hasDraftInvoice">
        <b-col>
          <b-alert variant="warning" show>
            {{ translations.txtScannedInvoicesPDFSplitNoListMessage }}
          </b-alert>
        </b-col>
      </b-row>

      <wiskLoading v-if="loading" loading />

      <div v-if="draggingFilesForUpload" class="drop-zone-overlay">
        <div class="drop-zone-overlay-inner-border">
          <div class="drop-zone-text"> {{ translations.txtGenericUpload }} </div>
        </div>
      </div>

      <div v-if="noDraftInvoiceNoItems" class="drop-zone-overlay ">
        <div class="drop-zone-inner-border">
          <div class="drop-zone-text"> {{ translations.txtImageUploaderDropZoneText }} </div>
        </div>
      </div>

      <small v-if="hasDraftInvoice || totalItemsCount" class="text-muted upload-help-text-small">
        {{ translations.txtImageUploaderDropZoneTextSmall }}
      </small>

      <fileInput accept="image/*, .pdf" multiple class="wisk-invoice-upload-input" ref="fileInput" v-model="files" :key="fileInputKey" title="" :allowOnlyDrop="!noDraftInvoiceNoItems"
        :style="{ 'pointer-events': draggingFilesForUpload || noDraftInvoiceNoItems ? 'auto' : 'none', cursor: noDraftInvoiceNoItems && !loading ? 'pointer' : '' }" />
    </b-container>

    <confirm ref="promptFileURLDialog" size="md" autofocus promptForTextHelpText="https://example.com/image.jpg" />

    <template v-slot:modal-footer-left-side>
      <b-row style="max-height: 40px;" noGutters>
        <b-col class="">
          <b-button variant="link" @click="promptFileURL">
            {{ translations.txtImageUploaderLoadFromURL }}
          </b-button>
        </b-col>
        <b-col v-if="hasDraftInvoice || totalItemsCount" cols="7" class="position-relative">
          <fileInput accept="image/*, .pdf" multiple class="border-0 cursor-pointer" style="height: 35px;" ref="fileInput" v-model="files" :key="fileInputKey" showButtonOnly
            :buttonText="translations.txtImageUploaderAddFiles" size="md" variant="link" />
        </b-col>
      </b-row>

    </template>
  </wiskModal>
</template>

<script>
import { markRaw } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { guid, loadPDF, formatDate } from '@/modules/utils'
import fileInput from '@/components/common/FileInput'
import draggableList from './DraftInvoicePageManagerDraggableList'

//the new changes are so if we have a draft invoice passed in but not in an array, that locks it in single mode, no split, only one invoice can be created, only one distributor can be selected
//this is for case where we need to fix a draft invocie by reuploading it, but we don't want to allow split or distributor change, because this is a fix of selected invoice, not a branching out flow

export default {
  name: 'DraftInvoicePageManager',
  emits: ['operation', 'done'],
  components: { draggableList, fileInput },
  props: { editAction: Object },
  data() {
    return {
      files: [],
      fileURLs: [],
      lists: [],
      draggedItem: null,
      pages: [],
      key: 1,
      draggingFilesForUpload: false,
      fileInputKey: 1,
      loading: false,
      hasChange: false,
      draftInvoiceId: null,
      distributorId: null,
      formatDate
    }
  },
  computed: {
    ...mapState(['draftInvoicesById', 'translations']),
    ...mapGetters([]),
    noList() {
      return !this.lists.length || !this.lists[0].items.length
    },
    draftInvoice() {
      return this.draftInvoicesById[this.draftInvoiceId]
    },
    okDisabled() {
      return this.loading || this.noList || this.lists.some(l => !l.distributorId) || !this.hasChange || (this.lists.length > 1 && this.draftInvoiceId)
    },
    totalItemsCount() {
      return this.lists.reduce((acc, list) => acc + list.items.length, 0)
    },
    hasDraftInvoice() {
      return !!(this.editAction?.draftInvoiceIds?.length || this.editAction?.draftInvoices?.length || this.editAction?.draftInvoice?.id)
    },
    noDraftInvoiceNoItems() {
      return !this.hasDraftInvoice && !this.totalItemsCount
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onPaste(event) {
      if (event.clipboardData && event.clipboardData.items && event.clipboardData.items.length) {
        let length = event.clipboardData.items.length,
          temp = []

        for (let i = 0; i < length; i++) {
          let item = event.clipboardData.items[i]

          if (item.type.includes('image') || item.type.includes('pdf')) {
            temp.push(item.getAsFile())
          }
        }
        this.files = temp
      }
    },
    onDragLeave(e) {
      if (!e?.relatedTarget || !e.relatedTarget.classList.contains('wisk-invoice-upload-input')) {
        this.draggingFilesForUpload = false
      }
    },
    onDragEnter(e) {
      if (e.dataTransfer.types.includes('Files')) {
        this.draggingFilesForUpload = true
      }
    },
    reset(clearUploads) {
      if (clearUploads) {
        this.fileURLs = []
      }

      this.hasChange = false
      this.initLists()
    },
    initLists() {
      let all = []

      this.lists = []

      if (this.editAction?.draftInvoice?.id) {
        all.push(this.addListFromDraftInvoice(this.editAction.draftInvoice))
        this.draftInvoiceId = this.editAction.draftInvoice.id //force single mode
        this.distributorId = this.editAction.draftInvoice.distributor?.id
      }

      if (this.editAction?.draftInvoices?.length) {
        this.editAction?.draftInvoices.forEach(draftInvoice => {
          all.push(this.addListFromDraftInvoice(draftInvoice))
        })
      }
      if (this.editAction?.draftInvoiceIds?.length) {
        this.editAction?.draftInvoiceIds.forEach(draftInvoiceId => {
          if (this.draftInvoicesById[draftInvoiceId]) {
            all.push(this.addListFromDraftInvoice(this.draftInvoicesById[draftInvoiceId]))
          }
        })
      }
      if (this.fileURLs?.length) {
        this.fileURLs.forEach(fileURL => {
          all.push(this.addListFromUpload(fileURL.split('/').pop()))
        })
      }
      if (this.editAction?.merge) {
        Promise.all(all).then(() => {
          this.mergeAll()
        })
      }
    },
    mergeAll() {
      let all = []

      this.lists.forEach(list => {
        all.push(...list.items)
      })

      this.lists = [{ id: guid(), key: guid(), items: all }]
      this.hasChange = true
    },
    promptFileURL() {
      if (this.$refs.promptFileURLDialog) {
        this.$refs.promptFileURLDialog.prompt({
          callback: url => {
            if (url && url.startsWith('http')) {
              api
                .uploadMediaURL(url)
                .then((response) => {
                  this.onUpload(response, 'file-from-clipboard.txt')
                })
                .finally(() => {
                  this.loading = false
                })
            }
          },
          required: true,
          message: this.translations.txtImageUploaderLoadFromURLText,
          title: this.translations.txtImageUploaderLoadFromURL
        })
      }
    },
    save() {
      let payload = this.lists.map(l => {
        let list = {}
        list.distributor_id = l.distributorId

        this.loading = true

        list.pages = l.items.map(item => ({
          original_page_number: item.pdfPage?.pageNumber,
          file_url: item.fileURL,
          draft_invoice_id: item.draftInvoiceId,
          source: item.source,
          type: item.type,
          file_name: item.fileName
        }))

        return list
      })

      api.draftInvoiceFilesManage(payload)
        .then((result) => {
          if (this.editAction.onChange) {
            this.editAction.onChange(result)
          }
        }).finally(() => {
          this.setGlobalAction({ type: 'pdfSplitEdit', action: null })
        })
    },
    updateList(list) {
      let index = this.lists.findIndex(l => l.id === list.id)

      if (index > -1) {
        if (!list.items.length && !this.draftInvoiceId) {
          this.lists = this.lists.filter(l => l.id !== list.id)
        } else {
          this.lists = [
            ...this.lists.slice(0, index),
            { ...list },
            ...this.lists.slice(index + 1)
          ]
        }
      }
      this.hasChange = true
    },
    splitListAtIndex({ listId, index }) {
      let foundIndex = this.lists.findIndex(l => l.id === listId),
        found = this.lists[foundIndex]

      let newList = {
        id: guid(),
        key: guid(),
        items: found.items.slice(index),
        distributorId: found.distributorId
      }

      found.items = found.items.slice(0, index)

      this.lists.splice(foundIndex + 1, 0, newList)
      found.key = guid()

      this.hasChange = true
    },
    getPages(fileURL) {
      return new Promise((resolve, reject) => {
        let pages = []

        if (fileURL) {
          if (fileURL.endsWith('.pdf')) {
            loadPDF(fileURL).then(pdf => {
              let all = []

              for (let i = 0; i < pdf.numPages; i++) {
                let pagePromise = pdf.getPage(i + 1).then(p => {
                  pages[i] = { pdfPage: markRaw(p), type: 'pdf', id: guid() }
                })
                all.push(pagePromise)
              }

              Promise.all(all)
                .then(() => resolve(pages))
                .catch(reject)

            }).catch(reject)
          } else {
            pages.push({ fileURL, fileName: fileURL.split('/').pop(), type: 'image', id: guid() })
            resolve(pages)
          }
        } else {
          reject()
        }
      })
    },
    addListFromUpload(fileName) {
      return new Promise((resolve, reject) => {
        let fileURL = 'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + fileName
        this.fileURLs.push(fileURL)

        this.loading = true
        try {
          this.getPages(fileURL).then(pages => {
            if (this.draftInvoiceId && this.lists[0]) {
              this.lists[0].items = [...this.lists[0].items, ...pages.map(p => ({ ...p, source: 'upload', fileURL, fileName }))]
            } else {
              this.lists.push({
                id: guid(),
                key: guid(),
                items: pages.map(p => ({
                  ...p,
                  source: 'upload',
                  fileURL,
                  fileName
                }))
              })
            }
            this.loading = false
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    addListFromDraftInvoice(draftInvoice) {
      return new Promise((resolve, reject) => {
        this.loading = true

        try {
          this.getPages(draftInvoice.scans[0]).then(pages => {
            this.lists.push({
              id: guid(),
              key: guid(),
              distributorId: draftInvoice.distributor?.id,
              items: pages.map(p => ({
                ...p,
                source: 'draft_invoice',
                fileURL: draftInvoice.scans[0],
                fileName: draftInvoice.scans[0].split('/').pop(),
                draftInvoiceId: draftInvoice.id
              }))
            })
            this.loading = false
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    onUpload(response) {
      this.addListFromUpload(response.filename)
      this.hasChange = true
    }
  },
  mounted() {
    document.addEventListener('paste', this.onPaste, true)
  },
  beforeUnmount() {
    document.removeEventListener('paste', this.onPaste, true)
  },
  watch: {
    editAction: {
      immediate: true,
      handler: 'initLists'
    },
    files(files) {
      if (files && files.length) {
        let all = []

        this.loading = true
        this.draggingFilesForUpload = false

        files.forEach(file => {
          let formData = new FormData()
          formData.append('data', file)

          all.push(api.uploadMedia(formData).then(response => {
            this.onUpload(response, file.name)
          }))
        })

        Promise.all(all).finally(() => {
          this.files = []
          this.fileInputKey++
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss">
.pdf-split-editor {
  height: calc(100vh - 250px);

  .wisk-invoice-upload-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute !important;
    opacity: 0;
  }

  .upload-help-text-small {
    position: absolute;
    display: block;
    bottom: 10px;
    left: 30px;
    pointer-events: none;
  }

  .drop-zone-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;

    .drop-zone-overlay-inner-border {
      display: flex;
      border: 5px dashed white;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      height: 100%;
      align-items: center;
      justify-content: center;

      .drop-zone-text {
        font-size: 36px;
        color: white;
      }
    }

    .drop-zone-inner-border {

      display: flex;
      border: 2px dashed #ccc;

      width: 100%;
      height: 100%;
      padding: 35px;
      align-items: center;
      justify-content: center;

      .drop-zone-text {
        font-size: 18px;
        color: darkgray;
      }
    }

  }

}
</style>
