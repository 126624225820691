<template>
  <b-row class="scanned-invoice-item px-2" :class="{ 'collapsed-scanned-invoice-item': !expanded }" :key="key" noGutters style="">
    <b-col v-if="expanded">
      <wiskInputGroup :disabled="localDisabled" v-if="!selectedItem?.item_distributor_id" @errorCountChanged="setValidStateItemCreate" class="h-auto px-0" @operationsChange="operationsChange">
        <wiskInput infoTooltipKey="36226e6e-edaa-447f-b56c-108976c897a2" v-if="distributor" class="d-none" :modelValue="distributor.id" triggerInputOnLoad triggerInputOnSet operation="distributor_id" />

        <wiskSelect infoTooltipKey="1395aae6-ac22-488b-9dba-e6e54f2f9c17" :modelValue="localItem.barcodes" triggerInputOnLoad triggerInputOnSet operation="barcode_add" class="d-none"
          operationEmpty="barcode_delete" taggable multiple valueName="barcodes" v-if="localItem.barcodes" />

        <b-row class="mt-1 pt-1">
          <b-col md="7" cols="12" class="px-1 px-2-m">
            <wiskInput infoTooltipKey="64d2e057-5fd2-48a6-ac77-d0e1fd163ace" :label="translations.txtScannedInvoicesItemTitle" :validations="[validations.missingItemIdOrText]"
              @autocompleteSelected="onAutocompleteSelected({ ...$event, fromOnlineSearch: true })" ref="titleComponent" operation="title" @blur="onTitleChange"
              :suffix="selectedItem?.titleSuffix || ''" fitChildren :fitChildrenTight="!itemCreationForbiddenForWiskUser"
              :fitChildrenCols="itemCreationForbiddenForWiskUser ? 4 : 6"
              required @clear="onAutocompleteSelected(null)" :autocompleteItemExists="!!selectedItem" @autocompleteInput="onAutocompleteInput" :autocompleteWidth="350"
              :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" :autocomplete="!localDisabled"
              :preventClearAutocomplete="localDisabled" selectOnFocus v-model="autocompleteValue" triggerInputOnLoad>

              <template v-slot:autocompleteItemTemplate="{ autocompleteItem }">
                <imageView :src="autocompleteItem.image" v-if="autocompleteItem.image" style="position: absolute; left: 10px; top: 10px;" filterDisplay="tinyThumb" />
                <div class="fill-width-height" :style="{ 'padding-left': autocompleteItem.image ? '40px' : '', 'min-height': autocompleteItem.image ? '40px' : '' }">
                  <div class="fill-width-height">
                    {{ addSuffix(autocompleteItem.title, autocompleteItem) }}
                  </div>
                  <div class="fill-width-height" v-if="autocompleteItem.distributor && autocompleteItem.distributor.title">
                    <small>
                      <span style=""> {{ translations.txtGenericDistributor }} </span>
                      <span style="font-weight: bold;"> {{ autocompleteItem.distributor.title }} </span>
                    </small>
                    <small v-if="autocompleteItem.distributor_code">
                      ,
                      <span style=""> {{ translations.txtGenericCode }} </span>
                      <span style="font-weight: bold;"> {{ autocompleteItem.distributor_code }} </span>
                    </small>
                  </div>
                </div>
              </template>
              <wiskInput infoTooltipKey="6d86e303-d487-4d75-872c-c14865104c58" v-model="localItem.distributor_code" :label="translations.txtGenericCode" triggerInputOnLoad
                operation="distributor_code" @autocompleteSelected="onAutocompleteSelected({ ...$event, fromOnlineSearch: true })" @blur="onItemCodeInputBlur"
                @autocompleteInput="onAutocompleteInputItemCode" autocomplete preventClearAutocomplete :autocompleteItems="autocompleteItems" :autocompleteMinChars="1" :autocompleteItemExists="itemCodeAutocompleteItemSelected"
                :fitChildren="!itemCreationForbiddenForWiskUser" :fitChildrenTight="!itemCreationForbiddenForWiskUser" :fitChildrenCols="7" selectOnFocus :autocompleteWidth="300">

                <template v-slot:autocompleteItemTemplate="{ autocompleteItem }">
                  <div class="fill-width-height">
                    <div class="fill-width-height">
                      {{ translations.txtGenericCode }}: {{ autocompleteItem.item_code || autocompleteItem.distributor_code }}
                    </div>
                    <div class="fill-width-height" v-if="autocompleteItem.distributor && autocompleteItem.distributor.title">
                      <small>
                        <span style=""> {{ addSuffix(autocompleteItem.title, autocompleteItem) }} </span>
                        <span style="font-weight: bold;"> {{ autocompleteItem.distributor.title }} </span>
                      </small>
                    </div>
                  </div>
                </template>

                <div v-if="!itemCreationForbiddenForWiskUser" class="form-control-child wisk-input-append-plain-component">
                  <b-button type="button" variant="link" class="float-end h-100 middle-center px-0" size="sm" :disabled="localDisabled" @click="openSearchDialog">
                    <icon name="wisk-search" class="me-1" scale=".7" />
                    {{ translations.txtItemSearchAdd }}

                    <infoTooltip helpKey="draftInvoiceAdvancedSearchInfo" style="min-width: 0;" />
                  </b-button>
                </div>
              </wiskInput>
            </wiskInput>
          </b-col>
          <b-col md="3" cols="12" class="px-1 px-2-m">
            <measurement horizontal inline operation="measurement" v-model="localItem.measurement" :label="translations.txtVenueBottlesMeasurement" triggerInputOnLoad
              @operation="emitChange('measurement input')" />
          </b-col>
          <b-col md="2" cols="12" class="px-1 px-2-m">
            <wiskInput infoTooltipKey="963139e6-ab40-40a5-8439-96c0934c6b98" operation="case_size" triggerInputOnLoad @operation="localItem.case_size = $event.value; emitChange('case_size input')"
              inputType="number" :label="translations.txtGenericCaseSize" :modelValue="localItem.case_size" id="draft-invoice-item-case-size"/>
          </b-col>
        </b-row>
      </wiskInputGroup>

      <b-row v-if="selectedItem?.item_distributor_id" class="mt-1 pt-1">
        <b-col md="7" cols="12" class="ps-3 pe-0 px-2-m">

          <b-button class="bt-edit-scanned-invoice-wisk-item" key="1" v-if="selectedItem.item_id" size="sm" variant="link" v-tooltip="translations.txtVenueBottlesEdit"
            @click="setGlobalAction({ type: 'itemEdit', action: { item_id: selectedItem.item_id, variantId: selectedItem.item_distributor_id } })">
            <icon class="text-primary" name="wisk-edit" :scale="0.8"></icon>
          </b-button>

          <wiskInput infoTooltipKey="929d56e0-fd43-4758-a75f-5584ac94da02" :modelValue="autocompleteValue" :label="translations.txtScannedInvoicesItemTitle"
            ref="titleComponent" operation="title" showPlainText2 fitChildren fitChildrenTight :fitChildrenCols="5"
            @clear="onAutocompleteSelected(null)" disabled
            triggerInputOnLoad :preventClearAutocomplete="localDisabled" :class="{ 'bg-warning': (selectedItem && selectedItem.archived) }">

            <wiskInput infoTooltipKey="555cd127-9ee0-4c67-9ce2-28a7862162fd" :modelValue="selectedItem.distributor_code" disabled :label="translations.txtGenericCode"
              fitChildren fitChildrenTight :fitChildrenCols="2">
              <div class="form-control-child wisk-input-append-plain-component">
                <b-button type="button" variant="link" class="float-end h-100 middle-center text-secondary  text-danger-hover" size="sm" v-tooltip="translations.txtGenericClear"
                  @click="onAutocompleteSelected(null)" style="z-index: 1;" :disabled="localDisabled">
                  <icon name="wisk-exit" scale=".4" />
                </b-button>
              </div>
            </wiskInput>
          </wiskInput>
        </b-col>
        <b-col md="3" cols="12" class="px-0 px-2-m">
          <measurement :modelValue="lineItem.item_measurement" disabled :label="translations.txtVenueBottlesMeasurement" />
        </b-col>
        <b-col md="2" cols="12" class="px-0 px-2-m">
          <wiskInput infoTooltipKey="33a7d8e8-a627-41cb-8e2c-ef03a3a30717" :modelValue="selectedItem.case_size" showPlainText :label="translations.txtGenericCaseSize"
            id="draft-invoice-item-case-size" />
        </b-col>

      </b-row>
    </b-col>
    <b-col v-if="!expanded">
      <b-container fluid>
        <span v-if="selectedItem"> {{ selectedItem.title }} {{ selectedItem.titleSuffix }} </span>

        <b-button class="bt-edit-scanned-invoice-wisk-item" key="1" v-if="selectedItem && selectedItem.item_id" size="sm" variant="link" v-tooltip="translations.txtVenueBottlesEdit"
          @click.stop="setGlobalAction({ type: 'itemEdit', action: { item_id: selectedItem.item_id, variantId: selectedItem.item_distributor_id } })">
          <icon class="text-primary" name="wisk-edit" :scale="0.8"></icon>
        </b-button>

      </b-container>
    </b-col>

    <wiskModal v-if="confirmDialogOverwriteVariantVisible" size="lg" :title="translations.confirmItemsGridFloatingActionTitle" :centered="true"
      v-model="confirmDialogOverwriteVariantVisible" hideOK hideCancel hideHeaderExtra hideFooterExtra>

      <h5 class="my-2"> {{ translations.txtScannedInvoicesCodeOverwrite }} </h5>

      <p class="mb-0 mt-1">
        Draft invoice line distributor: <span :class="{ 'text-danger': selectedItemCandidate?.distributor?.title && selectedItemCandidate?.distributor?.title !== distributor?.title }">{{ distributor?.title }}</span>
      </p>
      <p class="mb-0 mt-1">
        Selected item distributor: <span :class="{ 'text-danger': selectedItemCandidate?.distributor?.title && selectedItemCandidate?.distributor?.title !== distributor?.title }">{{ selectedItemCandidate?.distributor?.title }}</span>
      </p>
      <p class="mb-0 mt-1">
        Draft invoice line code: <span :class="{ 'text-danger': selectedItemCandidate?.distributor_code && selectedItemCandidate?.distributor_code !== localItem.item_code }">{{ localItem.item_code }}</span>
      </p>
      <p class="mb-0 mt-1">
        Selected item code: <span :class="{ 'text-danger': selectedItemCandidate?.distributor_code && selectedItemCandidate?.distributor_code !== localItem.item_code }">{{ selectedItemCandidate?.distributor_code }}</span>
      </p>

      <template v-slot:modal-footer>
        <b-row class="w-100 text-center">
          <b-col v-for="confirmOverwriteVariantAction in confirmOverwriteVariantActions" :key="confirmOverwriteVariantAction.key">
            <b-button :variant="confirmOverwriteVariantAction.variant" size="md" @click="confirmOverwriteVariantAction.action" class="w-75">
              {{ confirmOverwriteVariantAction.title }}
            </b-button>
          </b-col>
        </b-row>
      </template>

    </wiskModal>

    <confirm ref="confirmDialog" autofocus />
  </b-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
// import { detailedDiff } from 'deep-object-diff'
import merge from 'lodash.merge'
import { stringFilter, objectFilter, prepareVariantIdAsItem, currencyFormat } from '@/modules/utils'
import imageView from '@/components/common/ImageView'
import measurement from '@/components/bottles/Measurement'

const newItem = { item_id: 0, title: '', measurement: null, case_size: 1, item_code: '', distributor_code: '' }

export default {
  name: 'DraftInvoiceItem',
  emits: ['selectedItem'],
  components: { measurement, imageView },
  props: { lineItem: Object, disabled: Boolean, expanded: Boolean },
  data() {
    return {
      key: 1,
      debug: 0,
      itemCodeAutocompleteItemSelected: false,
      confirmOverwriteVariantActions: [],
      confirmDialogOverwriteVariantVisible: false,
      localDisabled: false,
      autocompleteValue: '',
      itemCreateFormValid: true,
      itemCreateOperations: [],
      initDone: false,
      selectedItem: null,
      selectedItemCandidate: null,
      localItem: merge({}, newItem),
      initDoneTimeoutId: null,
      emitChangeTimeoutId: null,
      autocompleteItems: [],
      draftInvoice: null,
      taxSelectorDisabled: false,
      distributor: null
    }
  },
  computed: {
    ...mapState(['translations', 'draftInvoicesById', 'bottlesById', 'distributorsById', 'itemVariantsById', 'currentPermissionsByType', 'categoriesById', 'familiesById', 'venue', 'user']),
    ...mapGetters(['currency', 'activeGLAccounts', 'activeTaxes', 'itemVariantsAsItems']),
    itemCreationForbiddenForWiskUser() {
      return this.venue?.draft_invoices_settings?.type === 'wisk_without_items' && this.user.is_wisk_user && !this.user.god_mode
    },
    validations() {
      return {
        missingItemIdOrText: {
          type: 'missingItemIdOrText',
          message: this.translations.txtScannedInvoicesValidationMissingItemId,
          validator: () => !!this.selectedItem || !!this.autocompleteValue
        }
      }
    },
    measurementTypes() {
      let arr = [{ id: 'unit', title: 'unit' }],
        item = this.selectedItem || { ...newItem }

      if (item && item.case_size && item.case_size > 1) {
        arr.push({ id: 'case', title: 'case' })
      }
      if (item && item.measurement && (item.measurement.type === 'weight' || item.measurement.type === 'volume')) {
        arr.push({ id: 'manual', title: 'manual' })
      }

      return arr
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateBottle', 'setItemVariant']),
    currencyFormat,
    openSearchDialog() {
      this.setGlobalAction({
        type: 'draftInvoiceItemSearch',
        action: {
          query: this.autocompleteValue,
          caseSize: this.localItem.case_size,
          distributorCode: this.localItem.distributor_code,
          draftInvoiceId: this.draftInvoice?.id || null,
          distributorId: this.distributor?.id || null,
          measurement: this.localItem.measurement,
          onChange: item => {
            if (item?.item_distributor_id) {
              this.onAutocompleteSelected(item)
            }
          }
        }
      })
    },
    operationsChange(operations) {
      if (this.initDone) {
        this.itemCreateOperations = operations

        if (this.debug && this.itemCreateOperations.length) {
          console.log('operationsChange', this.itemCreateOperations)
        }
      }
    },
    setValidStateItemCreate(errorCount) {
      this.itemCreateFormValid = !errorCount
    },
    onAutocompleteInput(value) {
      if (this.itemCreationForbiddenForWiskUser) {
        this.autocompleteItems = this.itemVariantsAsItems.filter(b => {
          let distributorCode = b?.distributor_code,
            localItemCode = this.localItem?.distributor_code,
            ok = !b.archived && b.variant && !b.variant.archived && b.distributor_id === this.distributor?.id && objectFilter({ payload: b, query: value })

          if (ok && !distributorCode && localItemCode) {
            ok = false
          }

          if (ok && distributorCode && localItemCode && distributorCode !== localItemCode) {
            ok = false
          }

          return ok
        })
      } else {
        this.autocompleteItems = this.itemVariantsAsItems.filter(b => !b.archived && b.variant && !b.variant.archived && objectFilter({ payload: b, query: value }))
        this.autocompleteItems.sort((a, b) => (b.distributor_id === this.distributor?.id) - (a.distributor_id === this.distributor?.id))
      }
    },
    addCodeToVariant(item) {
      if (item?.item_distributor_id) {
        this.setItemVariant({
          id: item.item_distributor_id,
          operation: [
            { type: 'item_code', value: this.localItem.item_code },
            { type: 'distributor_id', value: this.distributor?.id }
          ]
        }).then((updated) => {
          this.onAutocompleteSelected(updated)
          this.confirmDialogOverwriteVariantVisible = false
        })
      } else {
        this.confirmDialogOverwriteVariantVisible = false
      }
    },
    onAutocompleteSelected(item, skipEmit = false) {
      const isOnlineSearch = item?.fromOnlineSearch && !item.fromOnlineImport && !item.createdNow,
        hasDistributorCode = item?.distributor_code,
        hasLocalItemCode = this.localItem?.item_code,
        differentDistributor = item?.distributor_id && item.distributor_id !== this.distributor?.id

      if (this.debug) {
        console.log('onAutocompleteSelected', item)
        console.log('onAutocompleteSelected this.localItem', this.localItem)
        console.log('hasDistributorCode', hasDistributorCode)
        console.log('differentDistributor', differentDistributor)
      }

      if (isOnlineSearch) {
        if (!hasDistributorCode && hasLocalItemCode) {
          skipEmit = true
          this.addCodeToVariant(item)
        } else if ((hasDistributorCode && hasLocalItemCode && this.localItem.distributor_code !== item.distributor_code) || differentDistributor) {
          skipEmit = true

          if (this.itemCreationForbiddenForWiskUser) {
            if (this.$refs.confirmDialog) {
              this.$refs.confirmDialog.confirm({
                callback: () => {
                  this.selectedItem = item
                  this.emitChange('onAutocompleteSelected')
                },
                cancelCallback: () => {
                  this.key++
                },
                message: this.translations.confirmScannedInvoicesDistributorCodeMismatchWishWIthoutItemsEdgeCase
              })
            }
          } else {
            this.confirmOverwriteVariantActions = [
              { title: this.translations.txtScannedInvoicesCodeOverwriteOK2, variant: 'primary', action: () => this.addCodeToVariant(item) },
              {
                title: this.translations.txtScannedInvoicesCodeOverwriteOKNot2, variant: 'secondary', action: () => {
                  this.addVariant(item.item_id)
                  this.confirmDialogOverwriteVariantVisible = false
                }
              }
            ]
            this.selectedItemCandidate = item
            this.selectedItemCandidate.distributor = this.selectedItemCandidate.distributor || this.distributorsById[this.selectedItemCandidate?.distributor_id]
            this.confirmDialogOverwriteVariantVisible = true

            this.itemCodeAutocompleteItemSelected = true
            setTimeout(() => {
              this.itemCodeAutocompleteItemSelected = false
            }, 0)
          }

        } else {
          this.selectedItem = item
        }
      } else {
        this.selectedItem = item

        if (this.selectedItem) {
          this.selectedItem.item_id = this.selectedItem.item_id || 0
          this.selectedItem.item_distributor_id = this.selectedItem.item_distributor_id || 0
          this.selectedItem.case_size = this.selectedItem.case_size || 0
          this.selectedItem.title = this.selectedItem.title || ''
          this.selectedItem.distributor_code = this.selectedItem.distributor_code || this.localItem.distributor_code || ''

          if (this.selectedItem.item_id && this.bottlesById[this.selectedItem.item_id]) {
            this.selectedItem.title = this.bottlesById[this.selectedItem.item_id].title
          }
          this.autocompleteValue = this.selectedItem.title
        } else {
          this.autocompleteValue = this.lineItem.title || ''
        }
      }

      this.autocompleteItems = []

      if (!skipEmit) {
        setTimeout(() => {
          this.emitChange('onAutocompleteSelected')
        }, 0)
      }
    },
    onItemCodeInputBlur() {
      if (this.lineItem.item_code !== this.localItem.distributor_code) {
        this.emitChange('onItemCodeInputBlur')
      }
    },
    emitChange(caller) {
      if (this.debug) {
        console.log('emitChange', 1, caller)
      }

      clearTimeout(this.emitChangeTimeoutId)
      this.emitChangeTimeoutId = setTimeout(() => {
        this.$emit('selectedItem', this.selectedItem || this.localItem)

        if (this.debug) {
          console.log('emitChange', 2, caller)
        }
      }, 400)
    },
    onTitleChange() {
      if (this.localItem.title !== this.autocompleteValue) {
        this.localItem.title = this.autocompleteValue
        this.emitChange('onTitleChange')
      }
    },
    onAutocompleteInputItemCode(value) {
      if (this.itemCreationForbiddenForWiskUser) {
        this.autocompleteItems = this.itemVariantsAsItems.filter(b => {
          let distributorCode = b?.distributor_code,
            localItemCode = this.localItem?.distributor_code,
            ok = !b.archived && b.variant && !b.variant.archived && b.distributor_id === this.distributor?.id && objectFilter({ payload: b, query: value })

          if (ok && !distributorCode && localItemCode) {
            ok = false
          }

          return ok
        })
      } else {
        this.autocompleteItems = this.itemVariantsAsItems.filter(b => !b.archived && b.variant && !b.variant.archived && stringFilter('contains', b.distributor_code, value))
        this.autocompleteItems.sort((a, b) => (b.distributor_id === this.distributor?.id) - (a.distributor_id === this.distributor?.id))
      }
    },
    addVariant(itemId = (this.selectedItem && this.selectedItem.item_id) || 0) {
      const handler = () => {
        this.setGlobalAction({
          type: 'itemVariantEdit',
          action: {
            id: 0,
            itemId,
            itemVariant: {
              item_code: this.localItem.distributor_code,
              title: this.localItem.title,
              distributor_id: (this.distributor && this.distributor.id) || null,
              measurement: this.localItem.measurement
            },
            onChange: result => {
              setTimeout(() => {
                if (this.itemVariantsById[result.id]) {
                  this.onAutocompleteSelected(prepareVariantIdAsItem(result.id, this.$store.state))
                } else {
                  setTimeout(() => {
                    this.onAutocompleteSelected(prepareVariantIdAsItem(result.id, this.$store.state))
                  }, 2000)
                }
              }, 2000)
            }
          }
        })
      }

      if (itemId) {
        handler()
      } else {
        this.setGlobalAction({
          type: 'itemSearchDialog',
          action: {
            splitItemsByVariant: false,
            onChange: item => {
              itemId = item.item_id
              handler()
            }
          }
        })
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    createItem() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          preventNewItemPreview: true,
          title: this.autocompleteValue,
          creationSource: { type: 'draft_invoice', id: this.lineItem.draft_invoice_id },
          distributorId: (this.distributor && this.distributor.id) || null,
          distributorCode: this.localItem.distributor_code,
          caseSize: this.localItem.case_size,
          measurement: this.localItem.measurement,
          onChange: result => {
            setTimeout(() => {
              let found = this.itemVariantsById[result.item_distributor_ids[0]]

              if (found) {
                this.onAutocompleteSelected(prepareVariantIdAsItem(result.item_distributor_ids[0], this.$store.state))
              } else {
                setTimeout(() => {
                  found = this.itemVariantsById[result.item_distributor_ids[0]]

                  if (found) {
                    this.onAutocompleteSelected(prepareVariantIdAsItem(result.item_distributor_ids[0], this.$store.state))
                  } else {
                    this.onAutocompleteSelected({ ...result, item_distributor_id: result.item_distributor_ids[0].id })
                  }
                }, 2000)
              }
            }, 1000)

          }
        }
      })
    },
    refreshSelectedItem() {
      if (this.selectedItem && this.selectedItem.item_distributor_id) {
        let found = prepareVariantIdAsItem(this.selectedItem.item_distributor_id, this.$store.state)

        // let diff = detailedDiff(found.measurement, this.localItem.measurement)
        // console.log('diff', diff)

        //TODO:check if this equality check is correct or needed at all
        if (found && (!isEqual(found.measurement, this.localItem.measurement) || found.case_size !== this.localItem.case_size || found.distributor_code !== this.localItem.distributor_code || found.title !== this.localItem.title)) {
          this.selectedItem = found
          this.localItem = merge({}, this.localItem, this.selectedItem)
          this.emitChange('refreshSelectedItem')
        }
      }
    }
  },
  created() {
    if (this.lineItem && this.lineItem.draft_invoice_id && this.draftInvoicesById && this.draftInvoicesById[this.lineItem.draft_invoice_id]) {
      this.draftInvoice = { ...this.draftInvoicesById[this.lineItem.draft_invoice_id] }

      this.distributor = this.draftInvoice.distributor
      this.taxSelectorDisabled = this.draftInvoice.taxes_scheme === 'no_taxes'
    }

    if (this.debug) {
      console.log('this.lineItem', this.lineItem)
    }
    this.initDoneTimeoutId = setTimeout(() => {
      this.initDone = true
    }, 5000)
  },
  beforeUnmount() {
    clearTimeout(this.initDoneTimeoutId)
    clearTimeout(this.emitChangeTimeoutId)
  },
  watch: {
    lineItem: {
      handler() {
        setTimeout(() => {
          if (this.lineItem && this.lineItem.item_distributor_id) {
            let found = this.itemVariantsById[this.lineItem.item_distributor_id]

            if (found) {
              this.onAutocompleteSelected(prepareVariantIdAsItem(this.lineItem.item_distributor_id, this.$store.state))
            }
          } else {
            this.localItem = {
              measurement: this.lineItem.item_measurement,
              item_distributor_id: this.lineItem.item_distributor_id || 0,
              title: this.lineItem.title,
              case_size: this.lineItem.case_size || 0,
              distributor_code: this.lineItem.item_code || '',
              item_code: this.lineItem.item_code || '',
            }

            this.autocompleteValue = this.lineItem.title || ''
            this.onAutocompleteSelected(null, true)
          }
        }, 0)
      },
      immediate: true
    },
    bottlesById: 'refreshSelectedItem',
    itemVariantsById: 'refreshSelectedItem',
    disabled: {
      immediate: true,
      handler(disabled) {
        this.localDisabled = !!disabled
      }
    }
  }
}
</script>

<style lang="scss">
.scanned-invoice-item {
  position: relative;

  .bt-edit-scanned-invoice-wisk-item {
    visibility: hidden;
    position: absolute;
    top: 6px;
    z-index: 10;
    left: -10px;
  }

  &.collapsed-scanned-invoice-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    >.col>.container-fluid {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .bt-edit-scanned-invoice-wisk-item {
      visibility: hidden;
      position: initial;
    }
  }
}
</style>
