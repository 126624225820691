<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'planFeatureAd', action: null })" size="lg" hideFooter headerClass="border-bottom-0" extraLarge
    contentClass="plan-feature-ad">
    <template v-slot:modal-header>
      <div class="plan-feature-advertisement-header w-100">
        <h5>
          <icon class="" name="wisk-lock" />
          {{ translations.txtSubscriptionRestrictedFeature }}
        </h5>

        <button type="button" @click.stop="setGlobalAction({ type: 'planFeatureAd', action: null })" aria-label="Close" class="close btn-close position-absolute">
          <icon class="text-secondary" name="wisk-exit" :scale="0.64" />
        </button>
      </div>
    </template>
    <b-row>
      <b-col md="5" cols="6" class="p-5" v-if="selectedAd">
        <b-row>
          <b-col>
            <h2>
              <icon name="wisk-verynice" :scale="2.8" class="" style="position: relative; top: -10px; color: #D1C4F7;" />
              <span>{{ selectedAd.title }}</span>
              <icon :name="selectedAd.icon" :scale="1.9" class="text-primary ms-2" />
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="ps-5 pe-3 ">
            <h4>
              <span>{{ selectedAd.subTitle }}</span>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="ps-5  pe-3 mt-4" style="font-size: 14px;">
            {{ selectedAd.text }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="ps-5  pe-3 mt-5" style="font-size: 14px;">
            {{ translations.txtPlanFeatureAdQuestion1 }}

            <b-button variant="upgrade" class="ms-4" @click="callPlanSelector">
              <icon name="wisk-verynice" class="me-1" />

              {{ translations.txtPlanFeatureAdUpgradeNow }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="7" cols="6" class="">
        <b-row>
          <b-col class="text-center" style="">
            <img v-if="imageUrl" :src="imageUrl" class="plan-feature-ad-image" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 mb-5 pe-5 text-end" style="font-size: 14px;">
            {{ translations.txtPlanFeatureAdQuestion2 }}

            <b-button variant="outline-primary" class="ms-4" @click="embeddedFormVisible = true">
              {{ translations.txtPlanFeatureAdBookDemo }}
            </b-button>

            <b-button variant="success" class="ms-4" :to="urlBackup" v-if="user.god_mode && urlBackup" v-tooltip="'Button visible only for Wisk Admin'">
              {{ translations.txtGenericBypassThis }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <embeddedForm :reclaimDataId="$upgradeMeetingReclaimDataId" :visible="embeddedFormVisible" @hide="embeddedFormVisible = false" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { cloudImage } from '@/modules/utils'
import featureAds from '@/components/gateKeeper/featureAds'
import embeddedForm from '@/components/common/EmbeddedForm'

export default {
  name: 'PlanFeatureAd',
  components: { embeddedForm },
  props: { editAction: Object },
  data() {
    return {
      embeddedFormVisible: false,
    }
  },
  computed: {
    ...mapState(['translations', 'user']),
    ...mapGetters([]),
    urlBackup() {
      return (this.editAction && this.editAction.urlBackup) || null
    },
    feature() {
      return (this.editAction && this.editAction.feature) || null
    },
    subFeature() {
      return (this.editAction && this.editAction.subFeature) || null
    },
    featureAdId() {
      if (this.subFeature) {
        return this.feature + '_' + this.subFeature
      }
      return this.feature
    },
    selectedAd() {
      return { ...featureAds[this.featureAdId], ...this.translations['groupFeatureAds_' + this.featureAdId] }
    },
    imageUrl() {
      if (this.selectedAd && this.selectedAd.img) {
        return cloudImage(this.selectedAd.img, 'xLarge')
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    callPlanSelector() {
      this.setGlobalAction({
        type: 'planSelectorEdit',
        action: {
          venue: this.venue,
          feature: this.feature,
          onChange: () => {
            if (this.selectedAd && this.selectedAd.routeName) {
              this.setGlobalAction({
                type: 'confirmDialog',
                action: {
                  type: 'confirm',
                  payload: {
                    callback: () => {
                      this.setGlobalAction({ type: 'planSelectorEdit', action: null })
                      this.setGlobalAction({ type: 'planFeatureAd', action: null })

                      setTimeout(() => {
                        this.$router.push({ name: this.selectedAd.routeName }).catch(() => { })
                      }, 500)
                    },
                    message: this.translations.txtPlanFeatureAdUpgradeCompletedNavigateNow
                  }
                }
              })
            }
          }
        }
      })
    },
  }
}
</script>

<style lang="scss">
.plan-feature-ad {
  background: #faf9ff;
}

.plan-feature-advertisement-header {
  color: var(--bs-purple-dark);
  text-align: center;
  width: 100%;
  margin: 0;

  .close {
    color: var(--bs-purple-dark);
  }
}

.plan-feature-ad-image {
  width: 650px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
</style>
