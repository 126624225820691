<template>
  <div class="inventory-item-measurement">
    <slot></slot>

    <b-row v-if="item && modelValue && modelValue.measurement">
      <b-col :cols="hideButtons ? 12 : 8" class="">
        <div v-if="!hideTypeSelector" class="mb-2 mt-1">
          <b-form-radio-group size="sm" buttons v-model="localMeasurement.type" button-variant="outline-primary" style="z-index: 1;">
            <b-form-radio size="sm" class="py-0" value="unit"> {{ translations.txtGenericUnit }} </b-form-radio>
            <b-form-radio size="sm" class="py-0" value="case" v-if="item.case_size && item.inventoriable_as_cases"> {{ translations.txtGenericCase }} </b-form-radio>
            <b-form-radio size="sm" class="py-0" value="slider" v-if="item.measurement.type === 'volume'"> {{ translations.txtGenericVisual }} </b-form-radio>
            <b-form-radio size="sm" class="py-0" value="scale" v-if="item.measurement.type === 'volume'"> {{ translations.txtGenericScale }} </b-form-radio>
            <b-form-radio size="sm" class="py-0" value="manual" v-if="item.measurement.type === 'weight' || item.measurement.type === 'volume'"> {{ translations.txtGenericManual }} </b-form-radio>
            <b-form-radio size="sm" class="py-0 text-capitalize" value="partial" v-if="['unit', 'each'].includes(item.measurement.type) && item.measurement.quantity > 1"> {{ translations.txtGenericPartial }} </b-form-radio>
          </b-form-radio-group>
        </div>

        <div :class="{ 'pt-4': hideTypeSelector, 'pt-3': !hideTypeSelector }">
          <inventoryEntryInput :inputType="localMeasurement.type" @itemFieldChange="$emit('itemFieldChange', $event)" :emitFast="emitFast" :disabled="!editable" v-bind="inventoryEntryInputAttrs"
            @valid="valid = $event" :key="localMeasurement.type" @update:modelValue="onInput" :infoMeasurement="infoMeasurement" :modelValue="localMeasurement" :item="item" />
        </div>
      </b-col>

      <b-col cols="4" class="position-relative inventory-item-measurement-buttons align-items-end justify-content-end flex-column px-1" v-if="!hideButtons">
        <b-button v-if="!entryHidden" size="sm" variant="link" @click="submitAsZero" class="text-primary p-1">
          {{ translations.txtInventorySubmitAsZero }}
        </b-button>
        <div class="d-flex align-items-center justify-content-end w-100">
          <b-button v-if="!entryHidden" size="sm" variant="link" @click="submit" class="text-primary m-0 p-1" v-tooltip="translations.txtGenericSubmit" :disabled="!valid">
            <icon scale="1.2" name="wisk-check"></icon>
          </b-button>
          <b-button v-if="showOpenEditButton" size="sm" variant="link" @click="open" class="m-0 p-1" v-tooltip="translations.txtInventoriesEditDetails">
            <icon scale="0.8" name="wisk-more-info"></icon>
          </b-button>
          <b-button v-if="showHideToggleButton" size="sm" variant="link" @click="$emit('toggleHidden')" class=" m-0 p-1" v-tooltip="translations.txtGenericHide">
            <icon v-if="!entryHidden" scale="0.8" name="wisk-hide"></icon>
            <icon v-if="entryHidden" scale="0.8" name="wisk-show"></icon>
          </b-button>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import isEqual from 'lodash.isequal'
import get from 'lodash.get'
import { prepareVariantIdAsItem } from '@/modules/utils'
import inventoryEntryInput from '@/components/inventories/InventoryEntryInput'

export default {
  name: 'InventoryItemMeasurement',
  emits: ['update:modelValue', 'submit', 'open', 'toggleHidden', 'itemFieldChange'],
  components: { inventoryEntryInput },
  props: {
    hideButtons: Boolean,
    emitFast: Boolean,
    inventoryEntryInputAttrs: Object,
    entryHidden: Boolean,
    showOpenEditButton: Boolean,
    showHideToggleButton: Boolean,
    hideTypeSelector: Boolean,
    variantId: { type: Number, required: true },
    modelValue: { type: Object, required: true },
    editable: { type: Boolean, default: true }
  },
  data() {
    return {
      localMeasurement: null,
      infoMeasurement: null,
      valid: false,
      item: null
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onInput(measurement) {
      this.localMeasurement = { ...measurement }
      this.$emit('update:modelValue', { ...this.modelValue, measurement: { ...this.modelValue.measurement, measurement } })
    },
    submit() {
      this.$emit('submit', { ...this.modelValue, measurement: { ...this.modelValue.measurement, measurement: this.localMeasurement } })
    },
    open() {
      this.$emit('open', { ...this.modelValue, measurement: { ...this.modelValue.measurement, measurement: this.localMeasurement } })
    },
    submitAsZero() {
      this.onInput({ ...this.localMeasurement, quantity: 0 })
      this.submit()
    }
  },
  watch: {
    bottlesById: {
      handler() {
        this.item = prepareVariantIdAsItem(this.variantId, this.$store.state)
      }
    },
    variantId: {
      immediate: true,
      handler() {
        this.item = prepareVariantIdAsItem(this.variantId, this.$store.state)
      }
    },
    modelValue: {
      immediate: true,
      handler(modelValue) {
        if (!isEqual(get(modelValue, 'measurement.measurement'), this.localMeasurement)) {
          this.localMeasurement = { ...get(modelValue, 'measurement.measurement') }
          this.infoMeasurement = { ...get(modelValue, 'measurement.item_distributor_measurement', get(modelValue, 'measurement.item_measurement')) }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.inventory-item-measurement {
  .inventory-item-measurement-buttons:not(.persist) {
    display: none;
  }

  &:hover {
    .inventory-item-measurement-buttons {
      display: flex;
    }
  }
}
</style>
