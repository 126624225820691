<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else>
    <a v-if="url" :href="url" target="_blank" rel="noreferrer"> {{ displayText || host }}</a>
  </div>
</template>

<script>
export default {
  name: 'CellExternalLink',
  components: {},
  data() {
    return {
      linkInfo: {}
    }
  },
  computed: {
    url() {
      return this.params.value && this.params.value.input_value
    },
    host() {
      if (this.url) {
        return new URL(this.url).host
      }
      return ''
    },
    displayText() {
      return this.params.displayText
    }
  }
}
</script>
