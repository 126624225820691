export default {
  name: 'wisk-sync',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M23.9,28.9c0,0.5-0.2,0.9-0.5,1.2l-4.3,4.3c-0.7,0.7-1.8,0.7-2.4,0c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2 l1.3-1.3c-0.5,0-1,0.1-1.5,0.1C7.4,30.7,0,24.5,0,17c0-4.4,2.4-8.4,6.7-11C7,5.8,7.5,5.7,7.9,5.8c0.4,0.1,0.8,0.4,1,0.7 c0.5,0.8,0.2,1.8-0.5,2.3c0,0,0,0,0,0c-3.2,2-5.1,5-5.1,8.2c0,5.7,6,10.4,13.3,10.4c0.5,0,1.1,0,1.6-0.1l-1.5-1.5 c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2c0.7-0.7,1.7-0.7,2.4,0c0,0,0,0,0,0l4.3,4.3C23.7,28,23.9,28.4,23.9,28.9z'
    },
    {
      d: 'M35,18c0,4.4-2.4,8.4-6.7,11c-0.3,0.2-0.6,0.2-0.9,0.2c-0.1,0-0.3,0-0.4,0c-0.4-0.1-0.8-0.4-1-0.7 c-0.5-0.8-0.2-1.8,0.5-2.3c0,0,0,0,0,0c3.2-2,5.1-5,5.1-8.2c0-5.7-5.9-10.4-13.3-10.4c-0.5,0-1.1,0-1.6,0.1l1.5,1.5 c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5l-4.3-4.3 c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2l4.3-4.3c0.7-0.6,1.8-0.6,2.4,0c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2 L17,4.4c0.5,0,1-0.1,1.5-0.1C27.6,4.3,35,10.4,35,18z'
    }
  ]
}
