export default {
  name: 'wisk-variance',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M22.164,15.646,19.757,18.83l10.861.019A2.39,2.39,0,0,1,33,21.237v3.174A2.394,2.394,0,0,1,30.6,26.8l-16.85-.038L8.206,34.078A2.31,2.31,0,0,1,6.362,35a2.269,2.269,0,0,1-1.378-.466l-.5-.378a2.334,2.334,0,0,1-.456-3.2L7.226,26.73H4.392A2.384,2.384,0,0,1,2,24.352l.02-3.183a2.383,2.383,0,0,1,2.387-2.378l8.833.019,2.4-3.183-11.259-.02A2.384,2.384,0,0,1,2,13.22l.02-3.174A2.383,2.383,0,0,1,4.411,7.668l17.248.039L26.8.9A2.3,2.3,0,0,1,30.016.466l.5.388a2.286,2.286,0,0,1,.447,3.193L28.172,7.726h2.446A2.384,2.384,0,0,1,33,10.114v3.174a2.392,2.392,0,0,1-2.4,2.377Z'
    }
  ]
}
