<template>
  <div class="dual-range-input-container" :title="plainTextValue">
    <p v-if="showPlainText" class="plain-text-span" :class="className">
      {{ label ? label + ': ' : '' }} {{ plainTextValue }}
    </p>

    <component :is="label ? 'fieldset' : 'div'" v-else>
      <legend v-if="label">{{ label }}</legend>

      <double-range-slider v-if="mounted" :key="key" :min="selectedRange[0]" :max="selectedRange[1]" :min-threshold="minComputed" :max-threshold="maxComputed" @update:min="onchangeMin" @update:max="onchangeMax" />

      <div class="w-100 text-muted text-center" style="margin-top: -10px;">
        <small class="ms-3 float-start"> {{ translations.txtGenericMin }}: {{ min }} </small>

        <span class="text-bold text-primary"> {{ selectedRange[0] }} - {{ selectedRange[1] }} </span>

        <small class="me-3 float-end"> {{ translations.txtGenericMax }}: {{ max }} </small>
      </div>
    </component>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import equal from 'fast-deep-equal/es6'

//TODO: implement disabled state

export default {
  name: 'NumberRangeSelector',
  emits: ['change', 'update:modelValue', 'input'],
  components: {},
  props: {
    infoTooltipKey: { type: String, default: '79cd770c-88a9-485f-8634-d0f685e346b2' },
    showPlainText: Boolean,
    disabled: Boolean,
    label: { type: String, default: '' },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    modelValue: {
      type: Array,
      default: () => [0, 0]
    },
    prefix: String,
    suffix: String,
    className: String
  },
  data() {
    return {
      infoTooltipVisible: false,
      selectedRange: [0, 0],
      timeoutId: null,
      mounted: false,
      minValue: 0,
      maxValue: 0,
      key: 0
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    plainTextValue() {
      return `${this.selectedRange[0]} - ${this.selectedRange[1]}`
    },
    minComputed() {
      return Math.ceil(this.min)
    },
    maxComputed() {
      return Math.floor(this.max)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValue(value) {
      if (Array.isArray(value)) {
        this.selectedRange = value.length === 2 ? [...value] : [0, 0]
      }
    },
    onchangeMin(value) {
      this.selectedRange[0] = parseInt(value, 10)
      this.$emit('update:modelValue', [...this.selectedRange])
      this.emitChange()
    },
    onchangeMax(value) {
      this.selectedRange[1] = parseInt(value, 10)
      this.$emit('update:modelValue', [...this.selectedRange])
      this.emitChange()
    },
    emitChange() {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.$emit('input', [...this.selectedRange])
        this.$emit('change', [...this.selectedRange])
      }, 300)
    }
  },
  mounted() {
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
      this.mounted = true
    }, 100)
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId)
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        if (Array.isArray(value) && !equal(value, this.selectedRange)) {
          this.selectedRange = value.length === 2 ? [...value] : [0, 0]
        }
      }
    },
    selectedRange() {
      this.minValue = this.selectedRange[0]
      this.maxValue = this.selectedRange[1]
    },
    min() {
      this.key++
    },
    max() {
      this.key++
    }
  }
}
</script>

<style lang="scss">
// TODO: set up for all sizes, now it is made to fit in grid filter panel

.dual-range-input-container {
  .form-control.plain-text-span {
    border: none;
      text-align: left;
      padding: 0 !important;
    }

  .slider {
    // scale: 0.8;
    top: -5px;

    .slider-line {
      background-color: var(--secondary);
      // height: 6px;
      // border-radius: 3px;

      span {
        background-color: var(--primary);
      }
    }

    .slider-touch-left,
    .slider-touch-right {
      scale: 0.8;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    padding-top: 8px;
    background-color: white;

    legend {
      padding: 0px 7px;
      border-radius: 3px;
      margin-top: -19px;
      margin-bottom: 0;
      margin-left: 0.5rem;
      font-weight: normal;
      background-color: white;
      color: var(--bs-gray-400);
      font-size: 11px;
      border: none;
    }
  }

  label {
    opacity: 1;
    background-color: white;
    color: var(--bs-gray-400);
    z-index: 9;
    padding: 0 5px;
    border-radius: 4px;
    transform: scale(0.9) translateY(-70px) translateX(10px);
    transform-origin: 0 0;
    white-space: nowrap;
  }
}
</style>
