<template>
  <wiskModal :title="title" :visible="visible" hideOK hideFooter @hide="$emit('hide')" size="lg">
    <b-container style="width: 600px; height: 500px; max-width: 100%;" v-if="options">
      <b-row>
        <b-col md="auto" align-self="center"> {{ label }} </b-col>
        <b-col>
          <multiselect
            :key="key"
            :options="options"
            :modelValue="modelValue"
            :trackBy="trackBy"
            @update:modelValue="onChange"
            :multiple="false"
            :allow-empty="false"
            :placeholder="translations.txtGenericTypeToSearch"
            deselectLabel=""
            :internal-search="false"
            @search-change="$event => searchQuery = $event"
            selectLabel="Select"
            :optionsLimit="10000"
            :searchable="true"
            :customLabel="customLabel"
            ref="multiselect"
            v-bind="multiselectOptions"
            :max-height="450">
            <template v-slot:noResult>
              <span>{{ translations.txtGenericSearchReturnNothing }}</span>
            </template>
            <template v-slot:afterList>
              <li class="multiselect__element" style="padding: 5px 10px;">
                <b-button v-if="addNewItem" variant="primary" @click="addNewItem.action('WiskModalMultiSelect', searchQuery, modelValue, callbackItemInjected)">
                  {{ addNewItem.label }}
                </b-button>
              </li>
            </template>
            <template v-slot:option="props">
              <slot name="option-template" :props="props"></slot>
            </template>
          </multiselect>
        </b-col>
      </b-row>

      <slot></slot>

    </b-container>
  </wiskModal>
</template>

<script>
import multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import { stringFilter } from '@/modules/utils'

export default {
  name: 'WiskModalMultiSelect',
  emits: ['update:modelValue', 'hide'],
  components: { multiselect },
  props: {
    modelValue: null,
    autofocus: Boolean,
    items: { type: Array, default: () => [] },
    trackBy: { type: String, default: 'id' },
    itemLabel: { type: String, default: 'title' },
    label: { type: String },
    multiselectOptions: { type: Object },
    title: { type: String },
    visible: Boolean,
    addNewItem: Object
  },
  data() {
    return {
      key: 1,
      searchQuery: '',
      extraItem: null
    }
  },
  computed: {
    ...mapState(['translations']),
    options() {
      let items = this.items

      if (this.extraItem) {
        items.push(this.extraItem)
      }

      if (this.searchQuery) {
        return items.filter(i => stringFilter('contains', `${this.customLabel(i)} ${i[this.trackBy]}`, this.searchQuery))
      }

      return items
    }
  },
  methods: {
    callbackItemInjected(newItem) {
      this.extraItem = newItem
      setTimeout(() => {
        this.onChange(newItem)
      }, 10)
    },
    onChange(item) {
      this.$emit('update:modelValue', item)
    },
    customLabel(option) {
      return option[this.itemLabel]
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.multiselect && this.autofocus) {
        this.$refs.multiselect.activate()

        if (this.$refs.multiselect && this.$refs.multiselect.$el && this.$refs.multiselect.$el.querySelector('.multiselect__input')) {
          this.$refs.multiselect.$el.querySelector('.multiselect__input').focus()
        }
      }
    }, 500)
  },
  watch: {
    items() {
      this.key++
    }
  }
}
</script>

<style lang="scss"></style>
