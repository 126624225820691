<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemMergeGroupPrepare', action: null })" :title="translations.txtBottlesMergeOrGroup" size="md"
    hideHeaderExtra :okDisabled="!selectedItems.length" hideFooter>
    <b-container fluid class="item-merge-group-prepare" style="min-height: 500px;">
      <b-row>
        <b-col>
          <itemViewMini slotBelow :item="itemComputed" class="bg-info"></itemViewMini>
        </b-col>
      </b-row>
      <b-row class="my-2 py-4">
        <b-col>
          <wiskInput infoTooltipKey="ee3c1c54-be0c-4eb0-8ef2-bf1878280c1a" class="" :label="translations.txtBottlesMergeBottlesSearch" :modelValue="(selectedItem && selectedItem.title) || ''"
            autocomplete @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected" @clear="selectedItem = null"
            :autocompleteItems="autocompleteItems" :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" operation="title"
            autocompleteDisplayKey="title" :autocompleteListHeight="250" ref="searchAutocompleteInput" />
        </b-col>
        <b-col cols="2">
          <b-button :disabled="!selectedItem" style="" size="sm" class="text-primary" variant="link" @click="addSelectedItemToList"
            v-tooltip="translations.txtBottlesMergeBottlesAdd">
            <icon style="" name="wisk-check" :scale="1.2" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-if="selectedItems.length > 4">
        <b-col class="text-center text-danger text-bold">
          {{ translations.txtBottlesMergeTooManySelectedMessage }}
        </b-col>
      </b-row>

      <h5 v-if="itemComputed && itemComputed.title">
        {{ translations.translate('tplBottlesMergeBottlesList', { '{a}': addSuffix(itemComputed.title, itemComputed) }) }}
      </h5>

      <div style="height: 360px; overflow-y: auto; overflow-x: hidden; border: 1px solid silver; border-radius: 4px;" class="px-2 mb-4">
        <b-row v-for="item in selectedItems" :key="item.item_id">
          <b-col>
            <itemViewMini :item="item" class="">
              <b-button @click="remove(item)" class="p-0 m-0 border-0 float-end" style="" variant="link" type="link"
                v-tooltip="translations.txtGenericRemove">
                <icon class="text-danger" name="wisk-exit" :scale="1" />
              </b-button>
            </itemViewMini>
          </b-col>
        </b-row>
      </div>

      <b-row class="mb-2">
        <b-col class="text-center">
          <b-button class="" style="width: 100px;" variant="primary" size="md" @click="moveToItemMerge(false)" :disabled="!itemsMeasurementsMatchStrict || !combinedItems || combinedItems.length < 2 || combinedItems.length > 5"
            v-tooltip="itemsMeasurementsMatchStrict ? '' : translations.txtBottlesMergeVolumeValidationMessage">
            {{ translations.txtBottlesMergeTitle }}
          </b-button>
        </b-col>
        <b-col class="text-center">
          <b-button class="" style="width: 100px;" variant="primary" size="md" @click="moveToItemMerge(true)" :disabled="!itemsMeasurementsMatch || !combinedItems || combinedItems.length < 2 || combinedItems.length > 5"
            v-tooltip="itemsMeasurementsMatch ? '' : translations.txtBottlesGroupVolumeValidationMessage">
            {{ translations.txtBottlesGroupTitle }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { objectFilter, getBaseValueFromMeasurement } from '@/modules/utils'
import itemViewMini from '@/components/bottles/ItemViewMini'

export default {
  name: 'ItemMergeGroup',
  components: { itemViewMini },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      autocompleteItems: [],
      selectedItems: [],
      selectedItem: null
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'bottlesById']),
    combinedItems() {
      return [...this.selectedItems, this.itemComputed]
    },
    combinedItemsIds() {
      let items = [...this.selectedItems, this.itemComputed],
        ids = {}

      for (let i = 0; i < items.length; i++) {
        if (items[i] && items[i].item_id) {
          ids[items[i].item_id] = true
        }
      }
      return ids
    },
    itemComputed() {
      if (this.editAction && this.editAction.itemId) {
        return this.bottlesById[this.editAction.itemId]
      }
      if (this.editAction && this.editAction.item_id) {
        return this.bottlesById[this.editAction.item_id]
      }

      return null
    },
    itemsMeasurementsMatch() {
      let items = [...this.selectedItems, this.itemComputed]
      return [...new Set(items.filter(i => i && i.measurement).map(item => item.measurement.type))].length === 1
    },
    itemsMeasurementsMatchStrict() {
      let items = [...this.selectedItems, this.itemComputed]
      return [...new Set(items.filter(i => i && i.measurement).map(item => item.measurement.type + getBaseValueFromMeasurement(item.measurement)))].length === 1
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onAutocompleteInput(value) {
      this.autocompleteItems = this.bottles.filter(b => !b.archived && !this.combinedItemsIds[b.item_id] && objectFilter({ payload: b, query: value }))
    },
    onAutocompleteSelected(item) {
      if (item) {
        this.selectedItem = item
        this.autocompleteItems = []
      }
    },
    addSelectedItemToList() {
      this.selectedItems.push(this.selectedItem)
      this.selectedItem = null

      if (this.$refs.searchAutocompleteInput && this.$refs.searchAutocompleteInput.clearAutocomplete) {
        this.$refs.searchAutocompleteInput.clearAutocomplete()
      }
    },
    remove(item) {
      if (item) {
        this.selectedItems = this.selectedItems.filter(i => i && i.item_id !== item.item_id)
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    moveToItemMerge(group = false) {
      let items = [...this.selectedItems, this.itemComputed]
      this.setGlobalAction({ type: 'itemsMerge', preventMultiple: true, action: { group, itemIds: items.map(i => i.item_id) } })
      this.setGlobalAction({ type: 'itemMergeGroupPrepare', action: null })
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
