export default {
  name: 'wisk-warning',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M34.675,29.338,19.6,3.235a2.427,2.427,0,0,0-4.2,0L.327,29.338a2.424,2.424,0,0,0,2.1,3.636h30.15A2.429,2.429,0,0,0,34.675,29.338Zm-17.128.031a1.78,1.78,0,1,1,1.779-1.78A1.782,1.782,0,0,1,17.547,29.369Zm1.506-6.222a1.469,1.469,0,0,1-2.936.016l-.32-10.42a1.472,1.472,0,0,1,1.445-1.522h.624a1.448,1.448,0,0,1,1.476,1.506Z'
    }
  ]
}
