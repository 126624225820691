export default {
  name: 'wisk-filters',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M12.489,11.8,3.335,2.6A1.522,1.522,0,0,1,4.414,0H30.586a1.522,1.522,0,0,1,1.079,2.6l-9.154,9.2a1.521,1.521,0,0,0-.443,1.074v20.6a1.523,1.523,0,0,1-2.5,1.167l-6.09-5.1a1.522,1.522,0,0,1-.545-1.167v-15.5A1.521,1.521,0,0,0,12.489,11.8Z'
    },
    { d: 'M29.885,3.663a.037.037,0,0,1,.03.01v-.01Zm0,20.13h.03v-.01C29.905,23.783,29.9,23.793,29.885,23.793ZM9.8,23.753v.04h.03C9.805,23.793,9.8,23.773,9.8,23.753Zm0-20.09V3.7c0-.02.01-.04.03-.04Z' },
    { d: 'M9.8,3.663V3.7c0-.02.01-.04.03-.04Z' },
    { d: 'M29.885,3.663a.037.037,0,0,1,.03.01v-.01Z' },
    { d: 'M9.8,23.753v.04h.03C9.805,23.793,9.8,23.773,9.8,23.753Z' },
    { d: 'M29.885,23.793h.03v-.01C29.905,23.783,29.9,23.793,29.885,23.793Z' }
  ]
}
