<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'customFields', action: null })" size="lg"
    hideFooter :title="modalTitle">
    <template v-slot:modal-header-extra>
      <b-button style="" class="text-primary m-0 float-end" variant="link" @click="setGlobalAction({ type: 'customFieldEdit', action: { target: editAction.target } })" :title="translations.txtCustomFieldAdd">
        <icon style="margin-top: -5px;" name="wisk-plus" :scale="1.2" />

        <span class="ms-2"> {{ translations.txtCustomFieldAdd }} </span>
      </b-button>

    </template>
    <gateKeeper feature="custom_fields" :limitedItemsCount="activeCustomFields.length" class="pb-5">
      <wiskGrid class="custom-fields-grid" :rowData="customFieldsByTarget[editAction.target]" :columnDefs="columns" :gridOptions="{ floatingFiltersHeight: 0 }" resetRows gridAutoHeight parentGridName="CustomFields"
        :excel="{ fileName: 'CustomFields' }" :gridStyle="{ height: '100%' }" />
    </gateKeeper>

    <small>
      {{ stats }}
    </small>
  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { arrayToObjectById } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import wiskGrid from '@/components/grids/WiskGrid'

export default {
  name: 'CustomFields',
  components: { wiskGrid },
  props: {
    editAction: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['customFieldTypes']),
    ...mapState(['customFieldsByTarget', 'translations', 'customFields']),
    activeCustomFields() {
      return this.customFields.filter(f => !f.archived && f.item_type !== 'movement')
    },
    customFieldTypesById() {
      return arrayToObjectById(this.customFieldTypes, 'id') || {}
    },
    targets() {
      return {
        item: this.translations.txtGenericWiskItem,
        movement: this.translations.groupMovementTypes.intake,
        pos_item: this.translations.txtGenericPOSItem,
        subrecipe: this.translations.txtSubrecipeTitle,
        distributor: this.translations.txtGenericDistributor,
        inventory: this.translations.txtMenuInventory,
        independent_inventory: this.translations.txtIndependentInventory,
        venue: this.translations.txtGenericVenue,
        draft_invoice: this.translations.txtScannedInvoice,
        purchase_order: this.translations.txtPurchaseOrder,
      }
    },
    modalTitle() {
      return `${this.translations.txtCustomFields} - ${this.targets[this.editAction.target]}`
    },
    stats() {
      if (this.customFieldsByTarget) {
        let result = `${this.translations.txtCustomFieldsActive}: `
        Object.keys(this.customFieldsByTarget).forEach(key => {
          let count = this.customFieldsByTarget[key].filter(f => !f.archived).length
          result += `${this.targets[key] || key}: ${count}, `
        })
        return result.substring(0, result.length - 2)
      }

      return ''
    },
    columns() {
      return [
        {
          colId: 'label',
          sortOrder: 400,
          headerName: this.translations.txtGenericLabel,
          cellRenderer: 'CellText',
          minWidth: 190,
          ...agGridFilters.text,
          cellRendererParams: {
            translate: this.translations.translate,
            readonly: true
          },
          valueGetter: params => ({
            id: params.data && params.data.uuid,
            input_value: params.data && params.data.label
          })
        },
        {
          colId: 'type',
          sortOrder: 401,
          headerName: this.translations.txtCustomFieldType,
          cellRenderer: 'CellText',
          minWidth: 190,
          cellRendererParams: {
            translate: this.translations.translate,
            readonly: true
          },
          valueGetter: params => ({
            id: params.data && params.data.uuid,
            input_value:
              (params.data &&
                params.data.type_definition &&
                params.data.type_definition.type &&
                this.customFieldTypesById[params.data.type_definition.type] &&
                this.customFieldTypesById[params.data.type_definition.type].title) ||
              ''
          })
        },
        {
          colId: 'more',
          sortOrder: 2700,
          headerName: this.translations.columnDetails,
          minWidth: 70,
          width: 70,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true,
            onClick: data => {
              this.setGlobalAction({ type: 'customFieldEdit', action: { target: data.item_type, id: data.id } })
            }
          },
          valueGetter: params => ({
            id: params.data,
            group: !!params.node.group
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction'])
  }
}
</script>

<style lang="scss"></style>
