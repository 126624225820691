<template>
  <div ref="container" class="fill-width-height wisk-pdf-viewer-thumbnail" :style="{ '--scale-factor': scaleFactor }"
    :class="`pdf-page-${pdfPage?.pageNumber}`" :data-page-number="pdfPage?.pageNumber">
    <canvas :id="`pdf-canvas-thumbnail-${randomId}`" style="outline: 1px solid silver;" @click="$emit('click', $event)"></canvas>

    <div v-if="hasOverlaySlot" ref="slotContainer" style="position: absolute; top: 0; right: 0; left: 0; bottom: 0;">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { guid } from '@/modules/utils'

export default {
  name: 'PDFThumbnail',
  emits: ['click'],
  props: {
    pdfPage: Object,
    width: { type: Number, default: 61 },
    height: { type: Number, default: 80 },
    hasOverlaySlot: { type: Boolean, default: false }
  },
  data() {
    return {
      rendering: false,
      randomId: guid(),
      scaleFactor: 1
    }
  },
  methods: {
    render() {
      if (this.pdfPage && !this.rendering) {
        this.rendering = true

        let box = this.$refs.container.getBoundingClientRect(),
          scale = 1,
          viewport = this.pdfPage.getViewport({ scale }),
          largest = box.width >= box.height ? 'width' : 'height'

        scale = box[largest] / viewport[largest]
        viewport = this.pdfPage.getViewport({ scale })

        this.scaleFactor = viewport.scale

        let canvasId = `pdf-canvas-thumbnail-${this.randomId}`,
          canvas = document.getElementById(canvasId)

        if (canvas) {
          let context = canvas.getContext('2d'),
            slotContainer = this.hasOverlaySlot && this.$refs.slotContainer

          canvas.height = viewport.height
          canvas.width = viewport.width

          if (this.$refs.container) {
            this.$refs.container.style.height = `${viewport.height}px`
            this.$refs.container.style.width = `${viewport.width}px`
          }

          if (slotContainer) {
            slotContainer.style.height = `${viewport.height}px`
            slotContainer.style.width = `${viewport.width}px`
          }

          let renderContext = {
            canvasContext: context,
            viewport
          }
          this.pdfPage.render(renderContext).promise.then(() => {
            this.rendering = false
          }).catch(error => {
            if (error.name !== 'RenderingCancelledException') {
              console.error(error)
            }
          })
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.container) {
        this.$refs.container.style.height = `${this.height}px`
        this.$refs.container.style.width = `${this.width}px`

        this.render()
      }
    }, 0)
  }
}
</script>

<style lang="scss">
.wisk-pdf-viewer-thumbnail {
  position: relative;
  margin: 0.25rem;

  canvas {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &.active {
    >canvas {
      outline: 3px solid #1c82ef !important;
    }
  }
}
</style>
