<template>
  <b-container fluid class="px-4 pt-4">
    <b-row>
      <b-col class="mb-5">
        <p>
          {{ translations.txtDistributorsUploadMessage }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <b-button class="" style="" variant="outline-success" v-tooltip="translations.txtDistributorsUploadDownloadExisting" @click="downloadDistributors" size="md">
          <icon name="wisk-excel" :scale="0.7" style="margin-top: -2;" />
          <span> {{ translations.txtDistributorsUploadDownloadExisting }} </span>
        </b-button>
      </b-col>
      <b-col class="text-center">
        <b-button class="" style="" variant="outline-success" v-tooltip="translations.txtDistributorsUploadTemplateLink"
          target="_blank" rel="noreferrer" size="md" href="https://wiskai-report-templates.s3.ca-central-1.amazonaws.com/WISK_distributor_upload_TEMPLATE.xlsx">
          <icon name="wisk-excel" :scale="0.7" style="margin-top: -2;" />
          <span> {{ translations.txtDistributorsUploadTemplateLink }} </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pt-5">
        <b-form-group label="" class="">
          <b-input-group>
            <fileInput accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="wisk-distributors-upload-input" @reset="file = null" v-model="file" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <b-button @click="save" class="mt-4" variant="primary" :disabled="!file" size="lg">
          {{ translations.txtGenericUpload }}
        </b-button>
      </b-col>
    </b-row>
    <wiskLoading :loading="loading" />
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/api'
import fileInput from '@/components/common/FileInput'

export default {
  name: 'DistributorsUploader',
  emits: ['ready'],
  components: { fileInput },
  props: {},
  data() {
    return {
      loading: false,
      file: null
    }
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    ...mapActions([]),
    save() {
      let formData = new FormData()
      this.loading = true
      formData.append('distributors', this.file)

      api.uploadDistributors(formData).finally(() => {
        this.$emit('ready')
        this.loading = false
      })
    },
    downloadDistributors() {
      this.loading = true
      api.downloadBlob('distributors/export', 'Distributors.xlsx').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
