import isFinite from 'lodash.isfinite'
import isString from 'lodash.isstring'
import { stringFilter, getDateRangeShortcuts } from '@/modules/utils'

const getText = cellValue => {
  if (isString(cellValue)) {
    return cellValue
  }

  if (cellValue) {
    if (isString(cellValue.search_value)) {
      return cellValue.search_value
    }
    if (isString(cellValue.input_value)) {
      return cellValue.input_value
    }
    if (cellValue.input_value && cellValue.input_value.title) {
      return cellValue.input_value.title
    }
    return (cellValue.input_value && cellValue.input_value.toString()) || ''
  }
  return (cellValue && cellValue.toString()) || ''
}

export const getNumber = (cellValue /*, caller*/) => {
  if (typeof cellValue === 'string') {
    cellValue = parseFloat(cellValue)
  }
  if (isFinite(cellValue)) {
    return cellValue
  }
  if (cellValue && isFinite(cellValue.input_value)) {
    return cellValue.input_value
  }
  return 0
}

const checkIsSet = cellValue => {
  if (typeof cellValue === 'string') {
    cellValue = parseFloat(cellValue)
  }
  if (isFinite(cellValue)) {
    return true
  }
  if (cellValue && isFinite(cellValue.input_value)) {
    return true
  }
  return false
}

const getBool = cellValue => {
  if (!cellValue) {
    return false
  }
  if (cellValue) {
    if (typeof cellValue === 'boolean') {
      return cellValue
    }
    if (typeof cellValue === 'number') {
      return !!cellValue
    }
    if (typeof cellValue === 'string') {
      return cellValue.toLowerCase() === 'true'
    }
    if (cellValue.input_value) {
      return getBool(cellValue.input_value)
    }
  }
  return false
}
const getDateValue = cellValue => {
  if (!cellValue) {
    return 0
  }
  if (cellValue) {
    if (cellValue.getTime) {
      return cellValue.getTime()
    }
    if (cellValue.toMillis) {
      return cellValue.toMillis()
    }
    if (typeof cellValue === 'string' || typeof cellValue === 'number') {
      return new Date(cellValue).getTime() || 0
    }
    if (cellValue.input_value) {
      return getDateValue(cellValue.input_value)
    }
  }
  return 0
}

const getListItemValue = (cellValue, trackBy) => {
  if (cellValue) {
    if (cellValue.input_value) {
      if (Array.isArray(cellValue.input_value)) {
        return cellValue.input_value.length ? cellValue.input_value : null
      }
      return cellValue.input_value[trackBy]
    }

    if (typeof cellValue === 'string' || typeof cellValue === 'number') {
      return cellValue
    }
  }
  return null
}

export const gridFilters = {
  bool: {
    equals: (filterValue, cellValue) => getBool(filterValue) === getBool(cellValue)
  },
  date: {
    equals: (filterValue, cellValue) => getDateValue(filterValue) === getDateValue(cellValue),
    notEqual: (filterValue, cellValue) => getDateValue(filterValue) !== getDateValue(cellValue),
    lessThan: (filterValue, cellValue) => getDateValue(cellValue) < getDateValue(filterValue),
    greaterThan: (filterValue, cellValue) => getDateValue(cellValue) > getDateValue(filterValue),
    range: (filterValue, cellValue) => {
      let dateRangeShortcuts = getDateRangeShortcuts(window?.WiskGlobals?.venueBusinessDayStartHour),
        range = dateRangeShortcuts[filterValue?.id] || filterValue?.range,
        date = getDateValue(cellValue)

      if (range) {
        return date >= range[0] && date <= range[1]
      }

      return true
    }
  },
  number: {
    hasValue: (filterValue, cellValue) => !!getNumber(cellValue, 'cellValue'),
    notSet: (filterValue, cellValue) => !checkIsSet(cellValue, 'cellValue'),
    equals: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') === getNumber(filterValue, 'filterValue'),
    notEqual: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') !== getNumber(filterValue, 'filterValue'),
    lessThan: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') < getNumber(filterValue, 'filterValue'),
    lessThanOrEqual: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') <= getNumber(filterValue, 'filterValue'),
    greaterThan: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') > getNumber(filterValue, 'filterValue'),
    greaterThanOrEqual: (filterValue, cellValue) => getNumber(cellValue, 'cellValue') >= getNumber(filterValue, 'filterValue'),
    inRange: (filterValue, cellValue) => {
      let range = filterValue
      if (Array.isArray(range) && range.length === 2) {
        return getNumber(cellValue, 'cellValue') >= getNumber(range[0], 'range[0]') && getNumber(cellValue, 'cellValue') <= getNumber(range[1], 'range[1]')
      }
      return true
    }
  },
  text: {
    contains: (filterValue, cellValue) => stringFilter('contains', getText(cellValue), filterValue),
    notContains: (filterValue, cellValue) => stringFilter('notContains', getText(cellValue), filterValue),
    isEmpty: (filterValue, cellValue) => !getText(cellValue),
    isNotEmpty: (filterValue, cellValue) => !!getText(cellValue)
  },
  list: {
    inList: (filterValue, cellValue, trackBy) => {
      if (filterValue?.length) {
        let value = getListItemValue(cellValue, trackBy),
          found = filterValue.find(i => i === value)

        if (Array.isArray(value) && value.length) {
          if (value.length) {
            return !!value.filter(v => filterValue.includes(v)).length
          }
          return false
        }

        return !!found
      }
      return true
    },
    notInList: (filterValue, cellValue, trackBy) => {
      if (filterValue && filterValue.length) {
        let value = getListItemValue(cellValue, trackBy),
          found = filterValue.find(i => i === value)

        if (Array.isArray(value) && value.length) {
          if (value.length) {
            return !value.filter(v => filterValue.includes(v)).length
          }
          return true
        }

        return !found
      }
      return true
    },
    isEmpty: (filterValue, cellValue, trackBy) => !getListItemValue(cellValue, trackBy),
    isNotEmpty: (filterValue, cellValue, trackBy) => !!getListItemValue(cellValue, trackBy)
  }
}
