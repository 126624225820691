<template>
  <component :is="tag" role="group" :class="classList">
    <slot name="prepend" v-if="!!$slots['prepend']"></slot>
    <div v-else-if="prepend" class="input-group-prepend">
      <div class="input-group-text">{{ prepend }}</div>
    </div>
    <slot></slot>
    <slot name="append" v-if="!!$slots['append']"></slot>
    <div v-else-if="append" class="input-group-append">
      <div class="input-group-text">{{ append }}</div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'BInputGroup',
  props: {
    tag: { type: String, default: 'div' },
    append: { type: String, default: '' },
    prepend: { type: String, default: '' },
    size: { type: String, default: '' }
  },
  computed: {
    classList() {
      const classList = ['input-group']
      if (this.size) classList.push(`input-group-${this.size}`)
      return classList
    }
  }
}
</script>

<style lang="scss">
.input-group {
  .input-group-prepend {
    .input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group-append {
    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
