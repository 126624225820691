<template>
  <wiskModal :visible="!!editAction" @hide="hide" size="lg" hideHeaderExtra :title="translations.txtItemErrorFound"
    :okText="translations.txtGenericRetry" @ok="retryOperation">
    <div class="item-operation-error-handler">
      <b-row class="mb-4" v-if="items && items.length">
        <b-col>
          <h5> {{ translations.txtItemErrorCantArchive }} </h5>
        </b-col>
      </b-row>

      <b-row v-for="item in items" :key="item.item_id" class="mb-4">
        <b-col cols="1">
          <imageView :src="item.image" />
        </b-col>
        <b-col cols="5" class="p-4"> {{ item.title }} {{ item.titleSuffix }} </b-col>
        <b-col cols="6" class="p-4">
          <b-button size="sm" class="text-secondary" variant="link" @click="removeFromRetryList(item)" v-tooltip="translations.txtItemErrorRemoveFromRetryList">
            <icon class="" name="wisk-undo"></icon>
          </b-button>
          <b-button size="sm" class="text-warning" variant="link" @click="setGlobalAction({ type: 'itemSwap', action: { itemId: item.item_id } })" v-tooltip="translations.txtItemSwap">
            <icon class="" name="wisk-retweet" :scale="1.32"></icon>
          </b-button>
          <b-button size="sm" class="text-danger" variant="link" @click="forceArchive(item)" v-tooltip="translations.txtVenueBottlesForceArchive">
            <icon class="" name="wisk-trash" :scale="1"></icon>
          </b-button>
        </b-col>
        <b-col style="min-height: 120px; ">
          <wiskGrid v-if="item.used_by && item.used_by.length" parentGridName="itemArchiveErrorHandlerGrid" :columnDefs="columnDefs" gridAutoHeight resetRows :rowData="item.used_by" trackBy="id" />
          <p v-else> {{ translations.txtItemErrorCanArchive }} </p>

        </b-col>
      </b-row>

      <confirm ref="confirmDialogForceArchive" />

    </div>

  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import { guid } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import imageView from '@/components/common/ImageView'

export default {
  name: 'ItemOperationErrorHandler',
  components: { wiskGrid, imageView },
  props: {
    editAction: Object
  },
  data() {
    return {
      items: [],
      columnDefs: [],
      itemsToKeep: [],
      onDemandStateId: guid()
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'posItemsById', 'subrecipesById']),
    operation() {
      return (this.editAction && this.editAction.operation) || null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'addShoppingCart', 'updateBottle', 'setOnDemandState']),
    computeItems() {
      let items = (this.editAction && this.editAction.items) || []
      items = items.filter(item => !this.itemsToKeep.includes(item.item_id))

      items.forEach(item => {
        let found = this.bottlesById[item.item_id] || {},
          reasonsMap = {
            pos_item: 'txtItemErrorUsedInPOSItems',
            subrecipe: 'txtItemErrorUsedInSubrecipes',
            shopping_cart: 'txtItemErrorUsedInShoppingCart',
            inventory: 'txtItemErrorUsedInLastInventory'
          }
        item.image = found.image || ''
        item.title = found.title || ''
        item.titleSuffix = found.titleSuffix || ''
        item.used_by = (item.used_by || []).map(i => {
          let subrecipe = (i.type === 'subrecipe' && this.subrecipesById && this.subrecipesById[i.id]) || {},
            posItem = (i.type === 'pos_item' && this.posItemsById && this.posItemsById[i.id]) || {}

          return {
            ...i,
            item_id: found.item_id,
            title: subrecipe.title || posItem.title || '',
            reason: (reasonsMap[i.type] && this.translations[reasonsMap[i.type]]) || ''
          }
        })
        if (!get(found, 'used_by.pos_items.length')) {
          item.used_by = item.used_by.filter(i => i.type !== 'pos_item')
        }
        if (!get(found, 'used_by.subrecipes.length')) {
          item.used_by = item.used_by.filter(i => i.type !== 'subrecipe')
        }
      })
      return items
    },
    retryOperation() {
      if (this.operation && this.editAction && this.editAction.retry) {
        if (this.items.length) {
          this.editAction.retry(
            this.operation,
            this.items.map(item => item && this.bottlesById[item.item_id])
          )
        }
      }
      if (this.editAction.complete) {
        this.editAction.complete()
      }
    },
    hide() {
      if (this.editAction && this.editAction.complete) {
        this.editAction.complete()
      }
      this.setGlobalAction({ type: 'itemOperationErrorHandler', action: null })
    },
    openEditPOSItem(item) {
      this.setGlobalAction({ type: 'posItemEdit', action: { id: item.id } })
    },
    openEditSubrecipe(item) {
      this.setGlobalAction({ type: 'subrecipeEdit', action: { id: item.id } })
    },
    forceArchive(item) {
      if (this.$refs.confirmDialogForceArchive && item) {
        this.$refs.confirmDialogForceArchive.confirm({
          callback: () => {
            this.updateBottle({ id: item.item_id, operation: { type: 'forced_archive', value: true } }).then(() => {
              this.removeFromRetryList(item)
            })
          },
          message: this.translations.confirmForceArchiveText,
          title: this.translations.confirmArchiveTitle
        })
      }
    },
    removeFromShoppingCart(item) {
      if (item && item.item_id) {
        let found = this.bottlesById[item.item_id] || { variants: [] }
        found.item_distributor_ids.forEach(id => {
          this.addShoppingCart({
            item_distributor_id: id,
            units: 0
          })
        })
      }
    },
    removeFromRetryList(item) {
      this.itemsToKeep.push(item.item_id)
      this.items = this.computeItems()

      if (!this.items.length) {
        this.hide()
      }
    }
  },
  // created() {
  //   let items = (this.editAction && this.editAction.items) || [],
  //     posItemIds = items.filter(item => item && item.used_by && item.used_by.find(u => u.type === 'pos_item')).flatMap(item => item.used_by).map(u => u.id)

  //   this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', payload: { ids: posItemIds } })
  // },
  // beforeUnmount() {
  //   this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', REMOVE: true })
  // },
  mounted() {
    this.columnDefs = [
      {
        headerName: this.translations.txtGenericReason,
        field: 'reason',
        width: 500,
        colId: 'reason',
        tooltipField: 'reason'
      },
      {
        headerName: this.translations.txtGenericTitle,
        field: 'title',
        width: 500,
        colId: 'title',
        tooltipField: 'title'
      },
      {
        headerName: this.translations.txtGenericEdit,
        colId: 'more',
        width: 200,
        cellClass: ['cell-more-details'],
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          getHideButton: (id, value) => value && value.type === 'inventory',
          getTitle: (id, value) => {
            let map = {
              pos_item: this.translations.txtItemErrorUsedInPOSItemsEdit,
              subrecipe: this.translations.txtItemErrorUsedInSubrecipesEdit,
              shopping_cart: this.translations.txtItemErrorRemoveFromShoppingCart
            }
            return value && value.type && map[value.type]
          },
          onClick: (id, value) => {
            let handlersMap = {
              pos_item: this.openEditPOSItem,
              subrecipe: this.openEditSubrecipe,
              shopping_cart: this.removeFromShoppingCart
            }
            if (value && value.type && value.data && handlersMap[value.type]) {
              handlersMap[value.type](value.data)
            }
          },
          iconClass: 'd-none',
          buttonClass: 'text-start',
          icon: 'wisk-edit'
        },
        valueGetter: params => ({
          id: get(params, 'data.id'),
          type: get(params, 'data.type'),
          data: get(params, 'data', {}),
          group: !!params.node.group
        })
      }
    ]

    setTimeout(() => {
      this.items = this.computeItems()
    }, 500)
  },
  watch: {
    bottlesById() {
      this.items = this.computeItems()
    }
  }
}
</script>

<style lang="scss"></style>
