export default {
  name: 'wisk-rearrange',
  width: 35,
  height: 35,
  paths: [
    { d: 'M1.1,6.4h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1H1.1c-0.6,0-1.1-0.5-1.1-1.1V7.5C0,6.9,0.5,6.4,1.1,6.4z' },
    { d: 'M1.1,19.1h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1H1.1c-0.6,0-1.1-0.5-1.1-1.1v-7.3C0,19.6,0.5,19.1,1.1,19.1 z' },
    { d: 'M13.8,6.4h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1h-7.3c-0.6,0-1.1-0.5-1.1-1.1V7.5 C12.7,6.9,13.2,6.4,13.8,6.4z' },
    { d: 'M13.8,19.1h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1h-7.3c-0.6,0-1.1-0.5-1.1-1.1v-7.3 C12.7,19.6,13.2,19.1,13.8,19.1z' },
    { d: 'M26.6,6.4h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1h-7.3c-0.6,0-1.1-0.5-1.1-1.1V7.5 C25.4,6.9,25.9,6.4,26.6,6.4z' },
    { d: 'M26.6,19.1h7.3c0.6,0,1.1,0.5,1.1,1.1v7.3c0,0.6-0.5,1.1-1.1,1.1h-7.3c-0.6,0-1.1-0.5-1.1-1.1v-7.3 C25.4,19.6,25.9,19.1,26.6,19.1z' }
  ]
}
