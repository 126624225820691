<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'posModifierEdit', action: null })" class="pos-modifier-editor" :okText="translations.txtGenericSave" @ok="save"
    :title="`${modifier?.title} (${translations.groupPOSModifierTypes[modifier?.mapping?.type]})`" hideHeaderExtra :hideFooter="!!modifier?.id">

  <wiskInputGroup v-if="modifier" style="min-height: 450px;">
    <wiskInput v-model="modifier.title" :label="translations.txtGenericTitle" readonly/>

    <b-row>
      <b-col>
        <wiskSelect v-model="mappingType" :items="posModifierMappingTypes" :label="translations.txtPOSModifierMappingType" @change="onTypeChange" required/>
      </b-col>
      <b-col>
        <wiskSelect v-if="subTypes?.length" v-model="mappingSubtype" :items="subTypes" :label="translations.txtPOSModifierMappingSubtype" @change="onSubTypeChange" required/>
      </b-col>
    </b-row>
    <b-row v-if="mappingSubtype === 'multiplier'">
      <b-col>
      </b-col>
      <b-col>
        <wiskInput v-model="multiplier" :label="translations.txtPOSModifierMultiplier" inputType="number" autofocus triggerINputOnSet/>
      </b-col>
    </b-row>
    <b-row v-if="mappingSubtype === 'multiplier' && modifier?.mapping?.value?.value !== multiplier">
      <b-col>
        <b-button :disabled="!multiplier" size="sm" class="text-primary" variant="link" @click="save" block>
          <icon style="margin-right: 5px; margin-bottom: 1px;" name="wisk-check" :scale="0.9" />
          <span style="font-size: 16px;"> {{ translations.txtPOSModifierApplyChange }} </span>
        </b-button>
      </b-col>
    </b-row>

    <b-alert v-if="mappingFields?.measurement_change?.serving_size && mappingSubtype === 'base_change'" variant="secondary" show class="text-center">
      {{ ingredientDescription }}

      <b-button variant="link" @click="mappingFields.measurement_change.serving_size = null" class="position-absolute" style="top: 0; right: 0;" v-tooltip="translations.txtGenericRemove">
        <icon name="wisk-trash" class="text-danger" :scale="0.9" />
      </b-button>
    </b-alert>

    <itemViewMini v-if="ingredientItemComputed" :item="ingredientItemComputed" >
      <div class="w-100 pt-4 text-center">
        {{ ingredientDescription }}
      </div>

      <b-button variant="link" @click="removeIngredient" class="position-absolute" style="top: 0; right: 0;" v-tooltip="translations.txtGenericRemove">
        <icon name="wisk-trash" class="text-danger" :scale="0.9" />
      </b-button>
    </itemViewMini>

    <addIngredient v-if="ingredientAddVisible" vertical @add="onIngredientChange" :hideIngredient="mappingSubtype === 'base_change'" :hideServingSize="mappingSubtype === 'remove'"
      :btAddLabel="translations.txtPOSModifierApplyChange"/>
  </wiskInputGroup>

  </wiskModal>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import api from '@/api'
import { /*getPOSItemIngredientDescription, */getMeasurementDisplayString } from '@/modules/utils'
import addIngredient from '@/components/common/IngredientAdd'
import itemViewMini from '@/components/bottles/ItemViewMini'

export default {
  name: 'POSModifierEdit',
  components: { addIngredient, itemViewMini },
  props: {
    editAction: Object
  },
  data() {
    return {
      modifier: null,
      mappingType: null,
      mappingSubtype: null,
      multiplier: 1,
      mappingFields: {
        ingredient_change: { serving_size: null, ingredient: null },
        measurement_change: { serving_size: null }
      },
      subTypes: []
    }
  },
  computed: {
    ...mapState(['translations', 'posModifiersById', 'bottlesById', 'subrecipesById']),
    ...mapGetters(['posModifierMappingTypes']),
    mappingComputed() {
      return {
        ...this.modifier?.mapping,
        type: this.mappingType,
        value: {
          type: this.mappingSubtype,
          value: this.mappingSubtype === 'multiplier' && this.mappingType === 'measurement_change' ? this.multiplier : { ...this.mappingFields[this.mappingType] }
        }
      }
    },
    ingredientDescription() {
      let ingredientItem = this.ingredientItemComputed,
        type = ingredientItem?.item_id ? 'item' : 'subrecipe',
        servingSize = this.mappingFields[this.mappingType]?.serving_size,
        ingredient = { serving_size: servingSize }

      ingredient.item = type === 'item' ? ingredientItem : null
      ingredient.subrecipe = type === 'subrecipe' ? ingredientItem : null

      if (servingSize) {
        return getMeasurementDisplayString(servingSize?.measurement)
      }
      return ''
    },
    ingredientItemComputed() {
      let ingredient = this.mappingFields[this.mappingType]?.ingredient?.value,
        id = ingredient?.item_id || ingredient?.subrecipe_id,
        found = ingredient?.item_id ? this.bottlesById[id] : this.subrecipesById[id]


      return found ? { ...found, titleSuffix: found?.titleSuffix || '' } : null
    },
    ingredientAddVisible() {
      return !this.ingredientItemComputed && !this.mappingFields?.measurement_change?.serving_size && (this.mappingType === 'ingredient_change' || (this.mappingType === 'measurement_change' && this.mappingSubtype === 'base_change' ))
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    onTypeChange({ id, subTypes }) {
      this.removeIngredient()

      if (subTypes?.length) {
        this.subTypes = subTypes
        this.mappingSubtype = subTypes[0].id
      }

      if (this.modifier?.id && !this.mappingFields[id]) {
        this.removeIngredient()
        this.save()
      } else {
        //
      }
    },
    onSubTypeChange() {
      this.removeIngredient()
    },
    onIngredientChange({ value }) {
      this.mappingFields.ingredient_change = value || {}
      this.mappingFields.measurement_change.serving_size = value.serving_size

      this.save()
    },
    removeIngredient() {
      this.mappingFields.ingredient_change = { serving_size: null, ingredient: null }
      this.mappingFields.measurement_change = { serving_size: null }
    },
    save() {
      api.updatePOSModifier(this.modifier?.id, { type: 'mapping', value: this.mappingComputed })
    }
  },
  created() {},
  watch: {
    editAction: {
      immediate: true,
      deep: true,
      handler(editAction) {
        if (editAction?.id) {
          this.modifier = this.posModifiersById[editAction.id]
        } else {
          this.modifier = { id: 0, title: this.translations.txtPOSModifierNew, mapping: { type: 'not_mapped' } }
        }
      }
    },
    posModifiersById() {
      if (this.modifier?.id && !isEqual(this.modifier, this.posModifiersById[this.modifier.id])) {
        this.modifier = this.posModifiersById[this.modifier.id]
      }
    },
    multiplier(multiplier) {
      console.log('multiplier', multiplier)
    },
    modifier: {
      immediate: true,
      deep: true,
      handler() {
        console.log('this.modifier', this.modifier)
        this.mappingType = this.modifier?.mapping?.type
        this.mappingSubtype = this.modifier?.mapping?.value?.type
        this.subTypes = this.posModifierMappingTypes.find(({ id }) => id === this.mappingType)?.subTypes || []

        this.mappingFields.ingredient_change = this.modifier?.mapping?.value?.value || {}
        this.mappingFields.measurement_change.serving_size = this.modifier?.mapping?.value?.value?.serving_size
        this.multiplier = this.modifier?.mapping?.value?.value || 1
        console.log('this.mappingFields.measurement_change.serving_size', this.mappingFields.measurement_change.serving_size)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
