export default {
  name: 'wisk-reports-analytics',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 20.740234 0 C 15.760234 0.01 10.77125 -0.000234375 5.78125 0.009765625 C 4.39125 0.009765625 4.3320312 0.080703125 4.3320312 1.4707031 L 4.3320312 17.480469 C 4.3320312 22.860469 4.3220313 28.260391 4.3320312 33.650391 C 4.3320312 34.860391 4.4701562 34.980469 5.6601562 34.980469 C 13.580156 34.980469 21.531172 34.97 29.451172 35 C 30.311172 35 30.691875 34.799375 30.671875 33.859375 C 30.661875 25.829375 30.670625 17.809297 30.640625 9.7792969 A 1.915 1.915 0 0 0 30.130859 8.5898438 C 27.500859 5.9098438 24.821172 3.2791406 22.201172 0.61914062 A 1.809 1.809 0 0 0 20.740234 0 z M 5.9414062 1.5292969 L 20.431641 1.5292969 L 20.431641 2.5 C 20.431641 4.66 20.460156 6.8302344 20.410156 8.9902344 C 20.400156 9.8802344 20.740859 10.230937 21.630859 10.210938 C 23.770859 10.180938 25.901016 10.210938 28.041016 10.210938 L 29.111328 10.210938 L 29.111328 33.480469 L 5.9414062 33.480469 L 5.9414062 1.5292969 z M 21.980469 2.890625 C 23.890469 4.800625 25.841484 6.7696875 27.771484 8.6796875 L 21.980469 8.6796875 L 21.980469 2.890625 z M 8.7714844 14.746094 L 8.7714844 28.996094 L 26.232422 28.996094 L 26.232422 14.746094 L 8.7714844 14.746094 z M 10.222656 16.234375 L 15.146484 16.234375 L 15.146484 21.054688 L 10.222656 21.054688 L 10.222656 16.234375 z M 16.695312 16.234375 L 24.751953 16.234375 L 24.751953 21.054688 L 16.695312 21.054688 L 16.695312 16.234375 z M 10.222656 22.666016 L 15.146484 22.666016 L 15.146484 27.496094 L 10.222656 27.496094 L 10.222656 22.666016 z M 16.695312 22.666016 L 24.751953 22.666016 L 24.751953 27.496094 L 16.695312 27.496094 L 16.695312 22.666016 z '
    }
  ]
}
