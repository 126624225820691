export default {
  name: 'wisk-compress',
  width: 35,
  height: 35,
  paths: [
    {
      d: 'M 24.511719 0.033203125 A 1.4161 1.4161 0 0 0 23.134766 1.4238281 L 23.048828 10.542969 A 1.459 1.459 0 0 0 24.464844 11.972656 L 33.583984 11.970703 A 1.4159 1.4159 0 0 0 34.589844 9.5585938 A 1.4111 1.4111 0 0 0 33.583984 9.1386719 L 25.894531 9.140625 L 25.966797 1.4511719 A 1.4161 1.4161 0 0 0 24.511719 0.033203125 z M 10.525391 0.04296875 A 1.4160166 1.4160166 0 0 0 9.0761719 1.4648438 L 9.1132812 9.15625 L 1.4238281 9.1191406 A 1.4116 1.4116 0 0 0 0.4140625 9.5332031 A 1.4159 1.4159 0 0 0 1.4101562 11.951172 L 10.529297 11.994141 A 1.4586 1.4586 0 0 0 11.951172 10.572266 L 11.908203 1.4511719 A 1.4160166 1.4160166 0 0 0 10.525391 0.04296875 z M 24.414062 22.994141 A 1.4586 1.4586 0 0 0 22.984375 24.410156 L 22.986328 33.53125 A 1.4161 1.4161 0 0 0 25.818359 33.529297 L 25.816406 25.839844 L 33.507812 25.912109 A 1.4123 1.4123 0 0 0 34.517578 25.501953 A 1.4159 1.4159 0 0 0 33.533203 23.080078 L 24.414062 22.994141 z M 10.529297 23.015625 L 1.4101562 23.060547 A 1.4159 1.4159 0 0 0 0.41601562 25.476562 A 1.4124 1.4124 0 0 0 1.4238281 25.892578 L 9.1132812 25.853516 L 9.0761719 33.544922 A 1.4160166 1.4160166 0 0 0 11.908203 33.558594 L 11.951172 24.4375 A 1.4587 1.4587 0 0 0 10.529297 23.015625 z '
    }
  ]
}
